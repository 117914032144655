import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Typography } from '@material-ui/core';
import SecondaryButton from 'modules/common/SecondaryButton';
import RenewIcon from '@material-ui/icons/Autorenew';
import formatPrice from 'utils/formatPrice';

const HistoryTableAmountField = ({
  classes,
  label,
  dataLabel,
  tableItem,
  className,
  onReuseDetailsClick,
}) => {
  const { t } = useTranslation('common');
  const amount = formatPrice(tableItem[dataLabel], tableItem.currency);
  return (
    <div className={classNames([classes.container, className])}>
      <div className={classes.amountContainer}>
        <Typography className={classes.label}>{label}</Typography>
        <Typography className={classes.amountText}>
          {amount
            .split('.')
            .map((text, index) =>
              index === 1 ? <span className={classes.amountSmallText}>{`.${text}`}</span> : text,
            )}
        </Typography>
      </div>
      <SecondaryButton
        content={<RenewIcon className={classes.icon} />}
        label={t('buttons.reuseDetails')}
        className={classes.reuseButton}
        onClick={() =>
          onReuseDetailsClick(
            tableItem.fromAccount._id,
            tableItem.toAccount._id,
            tableItem.amount,
            tableItem.toAccount.currency,
            tableItem.currency,
          )
        }
      />
    </div>
  );
};

HistoryTableAmountField.propTypes = {
  label: PropTypes.string.isRequired,
  dataLabel: PropTypes.string.isRequired,
  tableItem: PropTypes.object,
  className: PropTypes.string,
  onReuseDetailsClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(HistoryTableAmountField);
