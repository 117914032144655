import isEqual from 'lodash/isEqual';
import { equalPasswords } from 'utils/validation/formValidationRules';

const validatePasswords = (formData) => {
  if (formData.password && formData.confirmPassword) {
    if (isEqual(formData.oldPassword, formData.password)) {
      return {
        password: { key: 'common:errors.form.samePassword' },
      };
    } else {
      return equalPasswords(formData);
    }
  }
  return {};
};

export default validatePasswords;
