import React, { useMemo } from 'react';
import { useTheme } from '@material-ui/core';
import TPModal from 'components/TP-UI/TPModal';
import { TMD_SUCCESS_MODAL } from '../../constants';
import TPCardAvatar from 'components/TP-UI/TPCard/components/TPCardAvatar';
import TPTypography from 'components/TP-UI/TPTypography';
import TPGrid from 'components/TP-UI/TPGrid';
import InfoTip from 'modules/common/InfoTip';
import PropTypes from 'prop-types';
import TPImage from 'components/TP-UI/TPImage';

import useStyles from './styles';

const TMDSuccessModal = ({ onContinue, onCancel }) => {
  const classes = useStyles();
  const buttons = useMemo(
    () => [
      { label: 'Continue', primary: true, onClick: onContinue },
      { label: 'Cancel', secondary: true, onClick: onCancel },
    ],
    [onCancel, onContinue],
  );
  const { tmd } = useTheme();
  const { src, ...avatarStyles } = tmd.successModal.avatar;

  return (
    <TPModal
      className={classes.modal}
      modalName={TMD_SUCCESS_MODAL}
      title="Appropriateness Test"
      titleEndAdornment={
        <InfoTip
          iconSize="medium"
          tooltipContent="Trading Forex/CFDs is highly risky. This questionnaire will help determine your suitability to trade these types of financial instruments."
        />
      }
      buttons={buttons}>
      <TPGrid justifyContent="center" container spacing={2}>
        <TPGrid item>
          <TPCardAvatar size="80px" primary>
            <TPImage src={src} style={avatarStyles} />
          </TPCardAvatar>
        </TPGrid>
        <TPGrid item>
          <TPTypography align="center" variant="h5" gutterBottom>
            Thank you
          </TPTypography>
          <TPTypography>
            Thank you for completing the Appropriateness test. Please answer some additional
            questions that will help us evaluate your understanding.
          </TPTypography>
        </TPGrid>
      </TPGrid>
    </TPModal>
  );
};

TMDSuccessModal.propTypes = {
  onContinue: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default TMDSuccessModal;
