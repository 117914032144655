import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import omit from 'lodash/omit';
import assign from 'lodash/assign';

import { equalPasswords } from 'utils/validation/formValidationRules';
import ResetPassword from '../components/ResetPassword';
import * as actionCreators from '../actions';
import { RESET_PASSWORD_FORM } from '../constants';
import * as appSelectors from 'selectors';
import * as apiSelectors from 'modules/api/selectors';

class ResetPasswordContainer extends PureComponent {
  handleSubmit = (data) => {
    const {
      actions: { resetPasswordRequest },
      resetToken,
    } = this.props;
    data = omit(data, ['confirmPassword']);

    resetPasswordRequest(assign(data, { resetToken }));
  };

  validate = (data) => {
    return equalPasswords(data);
  };

  render() {
    const { isPending } = this.props;
    const props = {
      isPending,
      form: RESET_PASSWORD_FORM,
      validate: this.validate,
      onSubmit: this.handleSubmit,
    };
    return <ResetPassword {...props} />;
  }
}

ResetPasswordContainer.propTypes = {
  actions: PropTypes.shape({
    resetPasswordRequest: PropTypes.func.isRequired,
  }).isRequired,
  resetToken: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    resetToken: appSelectors.getToken(ownProps),
    isPending: apiSelectors.createPendingSelector(actionCreators.resetPasswordRequest)(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer);
