import * as actions from '../actions';
import { handleActions } from 'redux-actions';
import { VPS_MAX_LOTS } from '../constants';

const initialState = {
  lots: 0,
  isCompleted: false,
};

const vpsReducer = handleActions(
  {
    [actions.getVpsLotsSuccess]: (state, action) => {
      let { lots, isCompleted } = action.response.data;
      return {
        ...state,
        lots: Math.min(lots ?? 0, VPS_MAX_LOTS),
        isCompleted: isCompleted ?? false,
      };
    },
  },
  initialState,
);

export default vpsReducer;
