import { handleActions } from 'redux-actions';

import { cleanupBirthdays, getBirthdaysFail, getBirthdaysSuccess } from '../actions';

const defaultState = {
  items: [],
};

export default handleActions(
  {
    [getBirthdaysSuccess](state, action) {
      return !action.payload.persistStoreData
        ? { items: action.response.data.birthdays, totalNumber: action.response.data.totalNumber }
        : state;
    },
    [getBirthdaysFail](state) {
      return state.totalNumber !== undefined
        ? state
        : {
            ...defaultState,
            totalNumber: 0,
          };
    },
    [cleanupBirthdays]() {
      return defaultState;
    },
  },
  defaultState,
);
