import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    form: {
      width: '100%',
    },
    control: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    countryList: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(1),
    },
    symbolsList: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(1),
    },
  }),
  { name: 'Autocompletes' },
);

export default useStyles;
