import { handleActions } from 'redux-actions';
import { verifyEmailFail, verifyEmailSuccess } from '../actions';

const defaultState = {
  verificationError: null,
  canResend: false,
};

export default handleActions(
  {
    [verifyEmailSuccess]: (state) => {
      return {
        ...state,
        verificationError: null,
        canResend: false,
      };
    },
    [verifyEmailFail]: (state, action) => {
      const errorData = action?.error?.data;
      return {
        ...state,
        verificationError: errorData?.message || {
          key: 'backend:somethingWrongContactHelp',
        },
        canResend: !errorData || errorData?.index !== '558',
      };
    },
  },
  defaultState,
);
