export const colors = {
  blue: {
    darkest: '#01020C',
    darker: '#050821',
    dark: '#080C34',
    main: '#0D1350',
    light: '#40397D',
    lighter: '#6E63AD',
    lightest: '#9F91DF',
  },
  purple: {
    darkest: '#0D0517',
    darker: '#260F45',
    dark: '#411973',
    main: '#742CCE',
    light: '#C1A0EB',
    lighter: '#E0CFF5',
    lightest: '#F1EBFB',
  },
  gray: {
    darkest: '#303242',
    darker: '#515581',
    dark: '#727699',
    main: '#9EA1B9',
    light: '#CCCEDA',
    lighter: '#DFE0E8',
    lightest: '#F4F5F7',
  },
  green: {
    darkest: '#1A3D24',
    darker: '#2B663C',
    dark: '#3D8F54',
    main: '#51B56D',
    light: '#8BCE9E',
    lighter: '#C5E6CE',
    lightest: '#ECF7EF',
  },
  red: {
    darkest: '#780101',
    darker: '#C70101',
    dark: '#FD1C1C',
    main: '#FE6B6B',
    light: '#FE9C9C',
    lighter: '#FFBDBD',
    lightest: '#FFDEDE',
  },
  white: {
    darkest: '#DADFEC',
    darker: '#EAEAEF',
    dark: '#FBFBFB',
    main: '#FFFFFF',
  },
  orange: {
    darkest: '#714000',
    darker: '#8E5100',
    dark: '#C67100',
    main: '#ff9100',
    light: '#FFB655',
    lighter: '#FFCE8E',
    lightest: '#FFE7C6',
  },
  yellow: {
    darkest: '#BF9900',
    darker: '#CCA300',
    dark: '#E6B800',
    main: '#FFCC00',
    light: '#FFEFB0',
    lighter: '#FFF7D9',
    lightest: '#FFFAE6',
  },
};
