import palette from './palette';
import { fontSize } from '../font';

const OverridesMuiStepIcon = {
  root: {
    width: 30,
    height: 30,
    color: palette.background.main,
    '&$completed': {
      color: palette.primary.lightest,
    },
    '&$active': {
      color: palette.primary.lightest,
    },
  },
  text: {
    transform: 'translateY(5%)',
    fontSize: fontSize._14,
    fill: palette.neutral.main,
  },
  active: {
    '& $text': {
      fill: palette.primary.text,
    },
  },
  completed: {
    '& $text': {
      fill: palette.primary.text,
    },
  },
};

export default OverridesMuiStepIcon;
