import React from 'react';
import { useTranslation } from 'react-i18next';
import PagePlaceholder from 'modules/common/PagePlaceholder';

import { useTheme } from '@material-ui/core';

const HiddenAccountsMessage = () => {
  const { t } = useTranslation('accounts');
  const { images } = useTheme();

  return (
    <PagePlaceholder
      image={images.common.searchPlaceholderImg}
      imageAlt="no accounts"
      message={t('page.hiddenAccountsWarninig')}
    />
  );
};

export default HiddenAccountsMessage;
