import api from 'modules/api/index';
import omit from 'lodash/omit';
import getFormData from './getFormData';
import { ACCOUNT_TYPES } from '../../userManagement/pages/UserDetails/constants';

export const loadUsersRequest = (payload) => {
  return api.get('/users', payload);
};

export const getUpdatedUserRequest = (id) => {
  return api.get(`/users/${id}`);
};

export const getUserCxdRequest = (id) => {
  return api.get(`/users/${id}/cxd`);
};

export const getVerificationUserRequest = (id) => {
  return api.get(`/users/${id}/fullData`);
};

export const getApplicationLogRequest = (id) => {
  return api.get(`/users/${id}/application-log`);
};

export const addEmployeeRequest = (payload) => {
  return api.post('/users/addEmployee', payload);
};

export const addClientRequest = (payload) => {
  if (!payload.accountDetails.leverage) {
    payload.accountDetails.leverage = 500;
  }
  delete payload.isIBClient;

  const formData = getFormData(payload);
  const headers = {
    'Content-Type': 'multipart/form-data',
  };

  return api.post('/users/addClient', formData, headers);
};

export const getUserRequest = (id) => {
  return api.get(`/users/${id}/fullData`);
};

export const getUserPhoneNumberRequest = (id) => api.get(`/users/${id}/phoneNumber`);

export const updateUserDetailsRequest = (payload) => {
  delete payload.isAppleRegister;

  let payloadForBackend = omit(payload, 'formName');
  const { _id } = payloadForBackend;
  const formData = getFormData(payloadForBackend);
  let headers = {
    'Content-Type': 'multipart/form-data',
  };
  return api.patch(`/users/${_id}`, formData, headers);
};

export const getUserFullAccountsRequest = (id) => {
  return api.get(`/users/${id}/accounts`);
};

export const getUserLiveAccountsRequest = (payload) => {
  const { userId, ...params } = payload;
  // needs to request live accounts by default
  if (!params.type) {
    params.type = ACCOUNT_TYPES.LIVE;
  }

  return api.get(`/users/${userId}/accounts`, { params: params });
};

export const getUserRejectedAccountsRequest = (payload) => {
  const { userId, ...params } = payload;
  params.type = ACCOUNT_TYPES.LIVE;
  params.status = 'rejected';

  return api.get(`/users/${userId}/accounts`, { params: params });
};

export const getUserDemoAccountsRequest = (payload) => {
  const { userId, ...params } = payload;
  const requestParams = { ...params, type: ACCOUNT_TYPES.DEMO };

  return api.get(`/users/${userId}/accounts`, { params: requestParams });
};

export const getTradingHistoryRequest = (id) => {
  return api.get(`/users/${id}/trading-history`);
};

export const getUserEmailsRequest = (payload) => {
  const { userId, ...params } = payload;
  return api.get(`/users/${userId}/emails`, { params });
};

export const getUserPaymentsRequest = (payload) => {
  const { userId, ...params } = payload;
  return api.get(`/users/${userId}/payments`, { params });
};

export const getUserPaypalRefundablePaymentsRequest = (payload) => {
  const { userId, ...params } = payload;
  return api.get(`/users/${userId}/paypal-refundable-payments`, { params });
};

export const getUserPaypalEmailsRequest = (userId) => {
  return api.get(`/users/${userId}/paypal-emails`);
};

export const sendEmailRequest = (payload) => {
  return api.post(`/users/contactByEmail`, payload);
};

export const addCommentRequest = (payload) => {
  return api.post(`/comments`, payload);
};

export const changePasswordAdminRequest = (payload) => {
  return api.post(`/auth/change-password-admin`, payload);
};

export const sendMigrationEmailRequest = (payload) => {
  return api.post(`/migration/send-migration-email`, payload);
};

export const userMigrationRequest = (payload) => {
  return api.post(`/migration/migrate`, payload);
};

export const acceptMigrationRequest = (payload) => {
  return api.post('/migration/accept-migration-request', payload);
};

export const createAccountAdminRequest = (payload) => {
  const { userId } = payload;
  return api.post(`/users/${userId}/account`, payload);
};

export const deleteDemoAccountRequest = (id) => {
  return api.delete(`/account/${id}`);
};

export const createAffiliateRequest = (payload) => {
  const { userId } = payload;
  return api.post(`/users/${userId}/register-affiliate`, payload);
};

export const getOperationSubscriptionRequest = (payload) => {
  const { userId } = payload;
  return api.get(`/users/${userId}/operation-subscribe`, payload);
};

export const getOperationNotificationsRequest = (payload) => {
  const { ...params } = payload;
  return api.get(`/users/operation-notification`, { params });
};

export const markAsReadOperationNotificationRequest = (payload) => {
  return api.post(`/users/operation-notification/mark-read`, payload);
};

export const createOperationSubscriptionRequest = (payload) => {
  const { userId } = payload;
  return api.post(`/users/${userId}/operation-subscribe`, payload);
};

export const removeOperationSubscriptionRequest = (payload) => {
  const { userId } = payload;
  return api.post(`/users/${userId}/operation-subscribe/remove`, payload);
};

export const setAllowCopyAccountRequest = (payload) => {
  const { userId } = payload;
  return api.post(`/users/${userId}/allow-copy-account`, payload);
};

export const setFreshchatIdRequest = (payload) => {
  const { userId } = payload;
  return api.post(`/users/${userId}/freshchat-id`, payload);
};

export const createIBClientRequest = (payload) => {
  const { userId, managerId } = payload;
  return api.put(`/users/ib-client/${userId}`, { managerId });
};

export const addIBClientRequest = (payload) => {
  return api.post(`/users/ib-client/`, payload);
};

export const addIBPortalClientRequest = (payload) => {
  return api.post(`/users/ib-portal-clients`, payload);
};

export const setAllowProRequest = (payload) => {
  const { userId } = payload;
  return api.post(`/users/${userId}/allow-pro`, payload);
};

export const getFullEmployeesRequest = () => {
  return api.get(`/users/employees`, { params: { asc: false, skip: 0 } });
};

export const getEmployeesRequest = (payload) => {
  return api.get(`/users/employees`, { params: payload });
};

export const editEmployeesPermissionsRequest = ({ userId, allowSendFromUser }) => {
  return api.patch(`/users/employees/${userId}/permissions`, { allowSendFromUser });
};

export const editEmployeesPasswordRequest = ({ userId, password }) => {
  return api.patch(`/users/employees/${userId}/password`, { password });
};

export const editEmployeesAssignedCxdRequest = ({ userId, assignedCxds }) => {
  return api.patch(`/users/employees/${userId}/assign-cxd`, { assignedCxds });
};

export const editEmployeesDisableMfaRequest = ({ userId }) => {
  return api.patch(`/users/employees/${userId}/disable-mfa`);
};

export const editEmployeesRoleRequest = ({ userId, role }) => {
  return api.patch(`/users/employees/${userId}/role`, { role });
};

export const getEmailSendersRequest = (payload) => {
  return api.get(`/users/email-senders`, { params: payload });
};

export const getBlockedUsersRequest = (payload) => {
  return api.get(`/users/lock`, { params: payload });
};

export const getBirthdaysRequest = (payload) => {
  return api.get(`/users/birthdays`, { params: payload });
};

export const lockUserRequest = ({ userId }) => {
  return api.post(`/users/${userId}/lock`);
};

export const unlockUserRequest = ({ userId }) => {
  return api.delete(`/users/${userId}/lock`);
};

export const setUserPartnerRequest = ({ userId, isPartner }) => {
  return api.post(`/users/${userId}/partner`, { isPartner });
};

export const getTradingStatusRequest = ({ userId }) => {
  return api.get(`users/${userId}/trading-status`);
};

export const editTradingStatusRequest = ({ userId, ...payload }) => {
  return api.patch(`/users/${userId}/trading-status`, { params: payload });
};

export const getAccountsWithActiveTradesRequest = (userId) => {
  return api.get(`users/${userId}/accounts-with-active-trades`);
};

export const getAffiliateDataRequest = ({ userId, email }) => {
  const query = email ? `?email=${email}` : '';
  return api.get(`/users/${userId}/affiliate${query}`);
};

export const createCellxpertTransferRequest = ({ account, data }) => {
  const { userId, _id } = account;
  const { amount, email } = data;
  return api.post(`/users/${userId}/affiliate/transfer/${_id}`, {
    amount: parseFloat(amount),
    email,
  });
};

export const getSimilarUsersRequest = (id) => {
  return api.get(`/users/${id}/similar`);
};

export const getUserCycleRequest = (id) => {
  return api.get(`/users/${id}/user-cycle`);
};

export const getUserEmailFeedRequest = ({ userId }) => {
  return api.get(`/users/${userId}/email-feed`);
};

export const getCountryUsersRequest = (payload) => {
  return api.get(`/users/countries`, { params: payload });
};

export const getCountryUsersCompareRequest = (payload) => {
  return api.get(`/users/countries-compare`, { params: payload });
};

export const getCountryUsersCompareExportRequest = (payload) => {
  return api.get(`/users/countries-compare`, { params: payload });
};

export const getMarketingChannelDataRequest = (payload) => {
  return api.get(`/users/marketing`, { params: payload });
};

export const getMarketingChannelSourcesRequest = () => {
  return api.get(`/users/marketing/source`);
};

export const getMarketingExportRequest = (payload) => {
  return api.get(`/users/marketing/export`, { params: payload });
};

export const getNetDepositDataRequest = (payload) => {
  return api.get(`/users/net-deposit`, { params: payload });
};

export const setProStepRequest = (payload) => {
  return api.post(`/users/set-pro-step`, payload);
};

export const setWholesaleUserRequest = (payload) => {
  const { userId } = payload;
  return api.post(`/users/${userId}/wholesale`, payload);
};

export const setTestUserRequest = (payload) => {
  const { userId } = payload;
  return api.post(`/users/${userId}/test`, payload);
};

export const setEcpUserRequest = (payload) => {
  const { userId } = payload;
  return api.post(`/users/${userId}/ecp`, payload);
};

export const cellxpertPasswordResetRequest = (token) => {
  return api.post(`/users/ib-password-reset`, { token });
};

export const getFusionPlusUsersRequest = (payload) => {
  return api.get(`/users/fusion-plus/users`, { params: payload });
};

export const editFusionPlusUsersRequest = (data) => {
  const { _id } = data;
  delete data._id;
  return api.put(`/users/fusion-plus/users/${_id}`, data);
};

export const deleteFusionPlusUsersRequest = (data) => {
  const { _id } = data;
  return api.delete(`/users/fusion-plus/users/${_id}`);
};

export const chargeFusionPlusUsersRequest = (data) => {
  const { _id } = data;
  return api.post(`/users/fusion-plus/users/${_id}/charge`);
};

export const downloadFusionPlusUsersRequest = () => {
  return api.get(`/users/fusion-plus/download`);
};

export const uploadFusionPlusUsersRequest = (data) => {
  return api.post(`/users/fusion-plus/csv-charge`, data);
};

export const restrictUserLeverageRequest = (payload) => {
  const { userId } = payload;
  return api.post(`/users/${userId}/restrict-leverage`, payload);
};

export const createMailchimpUserRequest = ({ userId }) => {
  return api.post(`/users/${userId}/mailchimp`);
};

export const addLogACallCommentRequest = (payload) => {
  return api.post(`/users/callComments`, payload);
};

export const getLogACallCommentRequest = (payload) => {
  return api.get(`/users/callComments`, { params: payload });
};

export const removeUserRequest = (id) => {
  return api.delete(`/users/${id}`);
};

export const setRecentTrsVisibilityRequest = (payload) => {
  return api.post(`/users/set-recent-trs-visibility`, payload);
};

export const createMigrateRequest = (payload) => {
  return api.post(`/clients/migration`, payload);
};

export const getUserTotalBalanceRequest = (userId) => {
  return api.get(`/users/${userId}/performance/totalBalance`);
};

export const updateKycStatusRequest = (payload) => {
  const { userId, status } = payload;
  return api.put(`/users/${userId}/kyc-status`, { status });
};

export const setSwapFreeUserRequest = ({ userId, swapFreeUser }) => {
  return api.post(`/users/${userId}/swapFreeUser`, { swapFreeUser });
};

export const getUnablePaypalRefundRequestsRequest = ({ userId }) => {
  return api.get(`/users/${userId}/unable-paypal-refund-requests`);
};

export const getClientSaleRequest = ({ userId }) => {
  return api.get(`/users/${userId}/client-sale`);
};

export const changeClientSaleStatusRequest = ({ userId, isApproved }) => {
  return api.patch(`/users/${userId}/change-client-sale-status`, { isApproved });
};

export const updateClientSaleProofRequest = (payload) => {
  const { _id } = payload;
  const formData = getFormData(payload);
  let headers = {
    'Content-Type': 'multipart/form-data',
  };
  return api.patch(`/users/${_id}/update-client-sale-proof`, formData, headers);
};

export const allowTransactionalEmailsRequest = ({ userId, ...rest }) => {
  return api.post(`/users/${userId}/allow-transactional-emails`, rest);
};
