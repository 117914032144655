import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    container: {
      color: theme.palette.text.primary,
      fontSize: theme.font.fontSize._16,
    },
    list: {
      margin: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: 0,
      paddingBottom: 0,
      '& > li p': {
        margin: 0,
      },
    },
    hideMarker: {
      listStyleType: 'none',
    },
    subTitle: {
      fontWeight: theme.font.fontWeight.bold,
    },
    title: {
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
    content: {
      fontWeight: theme.font.fontWeight.regular,
    },
  }),
  {
    name: 'LpoaTerms',
  },
);
