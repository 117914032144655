import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    scrollToTop: {
      width: '100%',
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,
      zIndex: 1000,
    },
    button: {
      width: '100%',
      ...theme.scrollToTop.button.root,
      '&:hover': {
        ...theme.scrollToTop.button.hover,
      },
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'TPScrollToTopButton' },
);
