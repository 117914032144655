import { call, put, takeEvery, delay, select } from 'redux-saga/effects';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import unset from 'lodash/unset';
import * as notificationsActions from 'modules/notifications/actions';

import { apiCallCounterDec, apiCallCounterInc } from '../actions';
import * as calls from '../calls';
import { setAuth } from 'modules/api';
import { getToken, getIsAuthenticated } from 'modules/auth/selectors';
import { push } from 'modules/reduxNavigation/actions';
import { getLocation } from 'modules/reduxNavigation/selectors';
import { Routes } from 'constants/routeConstants';
import { logout } from 'modules/auth/actions';
import { LOGOUT_REASON } from 'modules/auth/constants';
import { REQUIRED_VERIFICATION_MODAL } from 'modules/auth/pages/SignIn/constants';
import { openModal } from 'components/TP-UI/TPModal/actions';

const successPostfix = '_SUCCESS';
const failPostfix = '_FAIL';
const startPostfix = '_REQUEST';
const startPostfixRegex = new RegExp(`w*${startPostfix}$`); //find startPostfix at the END of the string
const requestPendingDelay = 10; //ms
const requestPollingDelay = 5000;

const CUSTOM_ERROR_SERVER_STATUSES = [404, 500, 501, 502, 503];

function createFailAction(action, error = null) {
  const { payload, type } = action;
  const { response, status, data } = error || {};
  if (!data) {
    error = { data: { message: { key: 'backend:somethingWrongContactHelp' } } };
  } else if (!data?.message && status && CUSTOM_ERROR_SERVER_STATUSES.includes(status)) {
    error.data = { message: { key: 'backend:somethingWrong' } };
  }
  return {
    type: type.replace(startPostfixRegex, failPostfix),
    payload,
    response,
    error,
  };
}

function createSuccessAction(action, response) {
  const { type, payload } = action;

  return {
    type: type.replace(startPostfixRegex, successPostfix),
    payload,
    response,
  };
}

function* checkMfa(error) {
  if (get(error, 'data.index') === '442') {
    const location = yield select(getLocation);
    if (location.pathname.indexOf(Routes.AUTH_MFA_INPUT) > -1) {
      return;
    }
    yield put(push(Routes.AUTH_MFA_INPUT));
  }
}

function* isUserBlocked(error) {
  if (get(error, 'data.index') === '469') {
    const location = yield select(getLocation);
    if (location.pathname.indexOf(Routes.SIGNIN) > -1) {
      return;
    }

    yield put(logout({ reason: LOGOUT_REASON.USER_BLOCKED }));
  }
}

function* isPasswordChangeRequired(error) {
  if (get(error, 'data.index') === '460') {
    // redirect if current location is not password change page
    const location = yield select(getLocation);
    if (location.pathname.indexOf(Routes.AUTH_FORCE_PASSWORD_CHANGE) > -1) {
      return;
    }

    yield put(push(Routes.AUTH_FORCE_PASSWORD_CHANGE));
  }
}

function* isMfaRequired(error) {
  if (error?.data?.index === '446') {
    // redirect if current location is not required mfa page
    const location = yield select(getLocation);
    if (location.pathname.includes(Routes.AUTH_MFA_REQUIRED)) {
      return;
    }

    yield put(push(Routes.AUTH_MFA_REQUIRED));
    const errorMessage = get(error, 'data.message', 'Error');
    yield put(notificationsActions.showNotificationError(errorMessage));
  }
}

function* isVerificationRequired(error) {
  if (get(error, 'data.index') === '556') {
    const location = yield select(getLocation);
    if (location.pathname.indexOf(Routes.SIGNIN) === -1) {
      yield put(push(Routes.SIGNIN));
    }
    yield put(openModal(REQUIRED_VERIFICATION_MODAL));
  }
}

function* handleError(error, action) {
  if (error?.status === 400) {
    yield* isUserBlocked(error);
    yield* isPasswordChangeRequired(error);
    yield* checkMfa(error);
    yield* isMfaRequired(error);
    yield* isVerificationRequired(error);
  }
  yield put(createFailAction(action, error));
}

function* sendRequest(action) {
  yield put(apiCallCounterInc(action.type));
  let callMethod = calls[camelCase(action.type)];
  if (!callMethod) {
    throw new Error(`no api method for action ${action.type}`);
  }
  let isAuthenticated = yield select(getIsAuthenticated);

  const token = get(action, 'payload.customAuthToken');

  if (token) {
    unset(action, 'payload.customAuthToken');
    setAuth(token);
  } else if (isAuthenticated) {
    const token = yield select(getToken);
    setAuth(token);
  }

  try {
    let response = yield call(callMethod, action.payload);
    if (response.data.isLongOperation && response.data.longOperationId) {
      const longOperationId = response.data.longOperationId;
      while (true) {
        const interval = response.data.interval || requestPollingDelay;
        yield delay(interval);
        try {
          response = yield call(calls.getLongOperationRequest, longOperationId);
        } catch (error) {
          if (error.status === 404 || error.status === 401) {
            return;
          }
          yield handleError(error, action);
        }
        if (!response.data.completed) {
          continue;
        }
        if (response.data.statusCode === 200) {
          yield put(createSuccessAction(action, { data: response.data.result }));
          return;
        }
        yield handleError(
          {
            status: response.data.statusCode,
            data: response.data.result,
          },
          action,
        );
        return;
      }
    }
    yield put(createSuccessAction(action, response));
  } catch (error) {
    yield handleError(error, action);
  }
}

function* requestEnded(action) {
  yield delay(requestPendingDelay);

  let type = action.type.replace(successPostfix, startPostfix).replace(failPostfix, startPostfix);
  yield put(apiCallCounterDec(type));
}

let isApiCallAction = (action) => {
  return action.type.endsWith(startPostfix);
};

let isApiCallEndedAction = (action) => {
  return action.type.endsWith(successPostfix) || action.type.endsWith(failPostfix);
};

function* apiCallsSaga() {
  yield takeEvery(isApiCallAction, sendRequest);
  yield takeEvery(isApiCallEndedAction, requestEnded);
}

export default apiCallsSaga;
