import { combineReducers } from 'redux';

import requests from '../pages/Requests/reducers';
import similarUsers from '../pages/ViewDetails/reducers';
import applicationLog from '../pages/ViewDetails/reducers/applicationLog';
import idVerification from '../pages/ViewDetails/reducers/idVerification';

const reducer = combineReducers({
  requests,
  similarUsers,
  applicationLog,
  idVerification,
});

export default reducer;
