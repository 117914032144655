import React from 'react';
import classnames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';

import useStyles from './styles';

const TPCardAvatar = ({ primary, className, children, size, ...props }) => {
  const classes = useStyles();

  return (
    <Avatar
      className={classnames(classes.root, { [classes.primary]: primary }, className)}
      style={{ height: size, width: size }}
      {...props}>
      {children}
    </Avatar>
  );
};

TPCardAvatar.propTypes = {
  primary: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.string,
};

export default TPCardAvatar;
