import React, { useMemo } from 'react';
import TPModal from 'components/TP-UI/TPModal';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { checkNonLatin, nonEmptyString, required } from 'utils/validation/fieldValidationRules';
import TPTextField from 'components/TP-UI/TPTextField';
import { MAX_PRESET_FILTER_COUNT } from '../../constants';
import PropTypes from 'prop-types';
import TPGrid from 'components/TP-UI/TPGrid';
import { duplicatedPresetName, maxFiltersCount } from '../../helpers/validators';
import FormError from 'modules/common/FormError';
import { REDUX_FORM_DEFAULT_CONFIG } from 'constants/form';

const TPPresetFiltersCreateModal = ({
  modalName,
  handleSubmit,
  onCancel,
  form,
  filtersList = [],
  error,
  loading,
  submitFailed,
  translationConfig,
}) => {
  const { t } = useTranslation('common');

  const validateDuplicatedPresetName = useMemo(
    () => duplicatedPresetName(filtersList.map(({ label }) => label)),
    [filtersList],
  );

  const validateMaxPresetCount = useMemo(
    () => maxFiltersCount(MAX_PRESET_FILTER_COUNT, filtersList.length),
    [filtersList],
  );

  const buttons = useMemo(
    () => [
      {
        primary: true,
        type: 'submit',
        label: t('buttons.create'),
        onClick: handleSubmit,
      },
      { label: t('buttons.cancel'), secondary: true, onClick: onCancel },
    ],
    [t, handleSubmit, onCancel],
  );

  return (
    <TPModal
      modalName={modalName}
      title={
        translationConfig.createModalTitle
          ? translationConfig.createModalTitle
          : t('presetFilters.create')
      }
      buttons={buttons}
      onClose={onCancel}
      onSubmit={handleSubmit}
      form={form}
      loading={loading}>
      <TPGrid container spacing={3}>
        {submitFailed && error && (
          <TPGrid item xs={12}>
            <FormError error={error} />
          </TPGrid>
        )}

        <TPGrid item xs={12}>
          <Field
            name="presetName"
            label={
              translationConfig.presetNamePlaceholder
                ? translationConfig.presetNamePlaceholder
                : t('presetFilters.placeHolder')
            }
            placeholder={
              translationConfig.presetNamePlaceholder
                ? translationConfig.presetNamePlaceholder
                : t('presetFilters.placeHolder')
            }
            component={TPTextField}
            fullWidth
            required
            validate={[
              required,
              checkNonLatin,
              nonEmptyString,
              validateDuplicatedPresetName,
              validateMaxPresetCount,
            ]}
          />
        </TPGrid>
      </TPGrid>
    </TPModal>
  );
};

TPPresetFiltersCreateModal.propTypes = {
  modalName: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  form: PropTypes.string,
  filtersList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      filters: PropTypes.object,
      _id: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  translationConfig: PropTypes.object,
};

const TPPresetFiltersCreateReduxModal = reduxForm({
  ...REDUX_FORM_DEFAULT_CONFIG,
  enableReinitialize: true,
})(TPPresetFiltersCreateModal);

export default TPPresetFiltersCreateReduxModal;
