import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getElement } from '../utils';
import { firstVisitFulfilled } from 'modules/auth/actions';
import { STEPS } from '../constants';
import Step from '../components/Step';
import OnboardingModal from '../components/OnboardingModal';
import { getMenuItems } from '../../menu/selectors';

const OnboardingContainer = ({ open }) => {
  const dispatch = useDispatch();
  const [stepIndex, setStepIndex] = useState(null);
  const [step, setStep] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [welcome, setWelcome] = useState(true);
  const [finished, setFinished] = useState(false);
  const availableMenuItems = useSelector(getMenuItems);
  const [allSteps, setAllSteps] = useState([]);

  useLayoutEffect(() => {
    const steps = STEPS.filter((step) =>
      step.isMenuItem === false
        ? Boolean(getElement('#' + step.targetId))
        : availableMenuItems?.some((item) => item.id === step.targetId),
    ).map((item, index) => ({ ...item, id: index + 1 }));

    setAllSteps(steps);
  }, [availableMenuItems]);

  useEffect(() => {
    if (stepIndex !== null) {
      const { targetId } = allSteps[stepIndex];
      setStep(allSteps[stepIndex]);
      setAnchorEl(null);
      // hack to redraw step
      setTimeout(() => {
        const element = getElement('#' + targetId);
        if (element) {
          element.scrollIntoView(false);
        }
        setAnchorEl(element);
      }, 0);
    }
  }, [allSteps, stepIndex]);

  const handleStart = useCallback(() => {
    setWelcome(false);
    setStepIndex(0);
  }, []);

  const handleSkip = useCallback(() => {
    setFinished(true);
    setAnchorEl(null);
    dispatch(firstVisitFulfilled());
  }, [dispatch]);

  const handleNext = useCallback(() => {
    setStepIndex(stepIndex + 1);
  }, [stepIndex]);

  const handleBack = useCallback(() => {
    setStepIndex(stepIndex - 1);
  }, [stepIndex]);

  const handleFinish = useCallback(() => {
    setFinished(true);
    setAnchorEl(null);
    dispatch(firstVisitFulfilled());
  }, [dispatch]);

  if (!open || finished) return null;

  return welcome ? (
    <OnboardingModal open={true} onSkip={handleSkip} onStart={handleStart} />
  ) : (
    <Step
      stepData={step}
      allSteps={allSteps.length}
      anchorEl={anchorEl}
      onSkip={handleSkip}
      onNext={handleNext}
      onBack={handleBack}
      onFinish={handleFinish}
    />
  );
};

OnboardingContainer.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default OnboardingContainer;
