import React from 'react';
import classnames from 'classnames';

import useStyles from './styles';

const TPCardContent = ({ divider, className, children }) => {
  const classes = useStyles();
  return (
    <div className={classnames(classes.root, { [classes.divider]: divider }, className)}>
      {children}
    </div>
  );
};

export default TPCardContent;
