import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    linkContainer: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      padding: `${theme.spacing(1.25)}px ${theme.spacing(2)}px`,
      ...theme.menu.item.linkContainer,
    },
    menuRoot: {
      zIndex: 1000,
    },
    menuListWithHeader: {
      paddingTop: 0,
    },
    menuListHeader: {
      padding: `0 ${theme.spacing(2)}px`,
      ...theme.menu.header,
    },
    noGutters: {
      padding: 0,
    },
    iconContainer: {
      ...theme.menu.item.icon,
    },
    text: {
      ...theme.menu.item.text,
    },
    readonly: {
      pointerEvents: 'none',
    },
  }),
  { name: 'TPMenu' },
);
