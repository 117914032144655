import makeStyles from '@material-ui/core/styles/makeStyles';

const GAP_BETWEEN_ITEMS = 3;

const getColumnFlexValue = (theme, columns, gap) =>
  `0 1 calc(${100 / columns}% - ${theme.spacing(gap)}px)`;

export default makeStyles(
  (theme) => ({
    descriptionList: {
      margin: 0,
      flex: 1, // stretch to container
      display: 'flex',
      flexWrap: 'wrap',
      gap: `${theme.spacing(2)}px ${theme.spacing(GAP_BETWEEN_ITEMS)}px`,
      marginBottom: 0,
    },
    boldText: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    fullWidth: {
      width: '100%',
    },
    columns1: {
      '& > *': {
        flex: getColumnFlexValue(theme, 1, GAP_BETWEEN_ITEMS),
      },
    },
    columns2: {
      '& > *': {
        flex: getColumnFlexValue(theme, 2, GAP_BETWEEN_ITEMS),
      },
      '& > $fullWidth': {
        flex: getColumnFlexValue(theme, 1, GAP_BETWEEN_ITEMS),
      },
    },
    columns3: {
      '& > *': {
        flex: getColumnFlexValue(theme, 3, GAP_BETWEEN_ITEMS),
      },
      '& > $fullWidth': {
        flex: getColumnFlexValue(theme, 1, GAP_BETWEEN_ITEMS),
      },
    },
    columns4: {
      '& > *': {
        flex: getColumnFlexValue(theme, 4, GAP_BETWEEN_ITEMS),
      },
      '& > $fullWidth': {
        flex: getColumnFlexValue(theme, 1, GAP_BETWEEN_ITEMS),
      },
    },
    smallSize: {
      gap: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    },
    wordBreakAll: {
      wordBreak: 'break-all',
    },
    // "Rows" variant
    listItem: {
      display: 'inline-grid',
      overflowWrap: 'break-word',
      wordBreak: 'break-word',
    },
    list: {
      width: '100%',
    },
    summary: {
      ...theme.descriptionList.summary,
    },
    divider: {
      margin: 0,
    },
  }),
  { name: 'TPDescriptionList' },
);
