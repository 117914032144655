import makeStyles from '@material-ui/core/styles/makeStyles';
import { BOTTOM_BAR_HEIGHT } from 'modules/menu/constants/index';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: BOTTOM_BAR_HEIGHT,
      maxWidth: '500px',
    },
    drawerContent: {
      margin: theme.spacing(3),
    },
  }),
  { name: 'BottomNavigations' },
);

export default useStyles;
