import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VolumeUsersView from '../views/VolumeUsersView';
import { getTopUserVolumesRequest } from '../actions';
import { getUserVolumes } from '../selectors';
import { DASHBOARD_USER_VOLUMES_HEADER_FORM, INITIAL_VOLUMES_FILTERS_VALUES } from '../constants';
import { getFormValues } from 'redux-form';
import { mapUserVolumesParamsToRequestPayload } from '../utils/mappers';
import { createPendingSelector } from 'modules/api/selectors';
import downloadFile from 'utils/downloadFile';
import fmDate from 'utils/fmDate';
import generateUserVolumesReport from '../utils/generateUserVolumesReport';
import { getAppURI } from 'components/App/selectors';
import { hasOnlyEmptyValues } from 'utils/hasOnlyEmptyValues';
import NoDataAvailable from 'modules/common/NoDataAvailable';
import TPLoader from 'components/TP-UI/TPLoader';

const VolumeUsersContainer = () => {
  const dispatch = useDispatch();
  const pending = useSelector(createPendingSelector(getTopUserVolumesRequest));
  const volumeUsers = useSelector(getUserVolumes);
  const { size = 0, dateFrom, dateTo } =
    useSelector(getFormValues(DASHBOARD_USER_VOLUMES_HEADER_FORM)) || {};
  const appURI = useSelector(getAppURI);
  const [filterApplied, setFilterApplied] = useState(
    !hasOnlyEmptyValues(INITIAL_VOLUMES_FILTERS_VALUES),
  );

  useEffect(() => {
    dispatch(
      getTopUserVolumesRequest(
        mapUserVolumesParamsToRequestPayload(INITIAL_VOLUMES_FILTERS_VALUES),
      ),
    );
  }, [dispatch]);

  const handleChange = useCallback(
    (data) => {
      setFilterApplied(!hasOnlyEmptyValues(data));
      dispatch(getTopUserVolumesRequest(mapUserVolumesParamsToRequestPayload(data)));
    },
    [dispatch],
  );

  const handleDownloadCSV = useCallback(() => {
    downloadFile(
      generateUserVolumesReport({ items: volumeUsers, appURI, size, dateFrom, dateTo }),
      `user_volumes_top_${size}_${fmDate().format('YYYY-MM-DD')}.csv`,
    );
  }, [volumeUsers, size, dateFrom, dateTo, appURI]);

  return !volumeUsers ? (
    <TPLoader />
  ) : volumeUsers.length === 0 && !filterApplied && !pending ? (
    <NoDataAvailable />
  ) : (
    <VolumeUsersView
      loading={pending}
      items={volumeUsers}
      form={DASHBOARD_USER_VOLUMES_HEADER_FORM}
      size={size}
      onChange={handleChange}
      onDownloadCSV={handleDownloadCSV}
    />
  );
};

export default VolumeUsersContainer;
