import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    menuHeader: {
      padding: `${theme.spacing(2)}px 0`,
      ...theme.pageHeader.root,
    },
  }),
  { name: 'Header' },
);

export default useStyles;
