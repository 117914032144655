import React from 'react';

import TableRow from '../components/TableRow';

const TableRowContainer = ({
  tableFields,
  generateTableItemId,
  tableItem,
  isExpandable,
  ExpandedRowContent,
  isExpanded,
  onExpand,
  onClick,
  rowIndex,
  getRowClassName,
}) => {
  return (
    <TableRow
      tableItem={tableItem}
      tableFields={tableFields}
      generateTableItemId={generateTableItemId}
      isExpandable={isExpandable}
      isExpanded={isExpanded}
      onExpand={onExpand}
      ExpandedRowContent={ExpandedRowContent}
      onClick={onClick}
      rowIndex={rowIndex}
      getRowClassName={getRowClassName}
    />
  );
};

export default TableRowContainer;
