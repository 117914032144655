import React from 'react';

import useStyles from './styles';

const TPWizardBackdrop = () => {
  const classes = useStyles();
  return <div className={classes.root} />;
};

export default TPWizardBackdrop;
