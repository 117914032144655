import { createSelector } from 'reselect';
import get from 'lodash/get';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';

import { DOCUMENT_TYPES, DOCUMENT_TYPES_ADDITIONAL_IDENTITY } from '../constants';
import { getDocumentTypesThirdStepSecondStep } from 'helpers';

export const getVerificationUser = (state) =>
  get(state, 'profile.verification.verificationUser', {});

export const getPersonalDetails = createSelector(getVerificationUser, (user) =>
  get(user, 'profile', {}),
);
export const getSettings = createSelector(getVerificationUser, (user) =>
  get(user, 'accountSettings', {}),
);
export const getIdentity = createSelector(getVerificationUser, (user) =>
  get(user, 'identity', { photoType: 'PASSPORT' }),
);
export const getCompletedSteps = createSelector(getVerificationUser, (user) =>
  get(user, 'verification.activeStep', 0),
);
export const getVerificationStatus = createSelector(getVerificationUser, (user) =>
  get(user, 'verification.status', ''),
);
export const getUserSubsub = createSelector(getVerificationUser, (user) =>
  get(user, 'verification.sumsub', {}),
);

export const getActiveStep = (state) => get(state, 'profile.verification.stepper.activeStep', 0);
export const getVerificationFirstStepFiles = (state) =>
  get(state, 'profile.verification.verificationUser.identity.photoId', 0);
export const getKeepCurrentStep = (state) =>
  get(state, 'profile.verification.stepper.keepCurrentStep', false);

export const getSettingsVerification = createSelector(
  getSettings,
  getVerificationUser,
  (verification, user) => {
    const settings = pick(verification, [
      'accountType',
      'leverage',
      'secretAnswer',
      'secretQuestion',
      'currency',
      'platform',
    ]);

    if (user.additionalEmail) {
      settings.additionalEmail = user.additionalEmail;
    }

    if (!isEmpty(settings)) {
      settings.conditions = true;
    }

    return settings;
  },
);

export const getPersonalDetailsVerification = createSelector(getPersonalDetails, (verification) => {
  const personalDetails = pick(verification, [
    'firstName',
    'lastName',
    'dateOfBirth',
    'addressLine1',
    'addressLine2',
    'country',
    'city',
    'state',
    'zipCode',
    'phoneCountry',
    'phoneNumber',
    'whoReferred',
    'familyName',
    'givenName',
  ]);

  return personalDetails;
});

export const getClassicVerification = createSelector(getIdentity, (verification) => {
  const identity = pick(verification, [
    'photoId',
    'photoIdType',
    'photoIdSide',
    'photoIdComment',
    'proof',
    'proofType',
    'proofSide',
    'proofComment',
  ]);

  if (identity.photoIdType || !identity.photoType) {
    identity.photoType = identity.photoIdType || DOCUMENT_TYPES[0].value;
  }

  if (identity.photoId) {
    identity.photo = identity.photoId;
  }

  (identity.photo || identity.photoId || []).forEach((file, i) => {
    file.documentSide = get(verification, `photoIdSide.${i}`, i ? 'BACK_SIDE' : 'FRONT_SIDE');
  });

  return identity;
});

export const getClassicVerificationSecondStep = createSelector(getIdentity, (verification) => {
  const identity = pick(verification, [
    'proof',
    'proofType',
    'photo',
    'photoType',
    'proofSide',
    'photoIdType',
  ]);

  let lostTypes = DOCUMENT_TYPES.filter((type) => type.value !== identity.photoIdType);

  if (identity.proofType) {
    identity.photoType = identity.proofType;
  }
  if (identity.proof) {
    identity.photo = identity.proof;
  }
  if (!identity.photoType || identity.photoType === identity.photoIdType) {
    identity.photoType = lostTypes[0].value;
  }

  (identity.proof || []).forEach((file, i) => {
    file.documentSide = get(verification, `proofSide.${i}`, i ? 'BACK_SIDE' : 'FRONT_SIDE');
  });

  return identity;
});

export const getLostDocumentTypes = createSelector(getIdentity, (verification) => {
  const identity = pick(verification, ['photoIdType']);
  const types = getDocumentTypesThirdStepSecondStep();

  if (!identity.photoIdType) {
    identity.proofType = DOCUMENT_TYPES_ADDITIONAL_IDENTITY[0].value;
  }

  return types.filter((type) => type.value !== identity.photoIdType);
});

export const getUsedDocumentType = createSelector(getIdentity, (verification) => {
  const identity = pick(verification, ['photoIdType']);

  if (!identity.photoIdType) {
    identity.proofType = DOCUMENT_TYPES_ADDITIONAL_IDENTITY[0].value;
  }

  return !identity.photoIdType ? DOCUMENT_TYPES_ADDITIONAL_IDENTITY[0].value : identity.photoIdType;
});

export const getSelectedPhotoIdDocumentType = createSelector(getIdentity, (verification) => {
  const identity = pick(verification, ['photoIdType']);

  if (!identity.photoIdType) {
    identity.proofType = DOCUMENT_TYPES[0].value;
  }

  return DOCUMENT_TYPES.filter((type) => type.value === identity.photoIdType).label;
});
