import { handleActions } from 'redux-actions';
import get from 'lodash/get';

import { getReferralsFail, getReferralsRequest, getReferralsSuccess } from '../actions';

const defaultState = {
  totalNumber: null,
  referrals: null,
};

export default handleActions(
  {
    [getReferralsRequest]: () => defaultState,
    [getReferralsSuccess]: (_state, action) => {
      const referrals = get(action, 'response.data.referrals.rows', []);
      const totalNumber = get(action, 'response.data.referrals.count', 0);

      return { referrals, totalNumber };
    },
    [getReferralsFail]: () => ({
      totalNumber: 0,
      referrals: [],
    }),
  },
  defaultState,
);
