import createAction from 'redux-actions/es/createAction';

export const getPresetFiltersRequest = createAction('GET_PRESET_FILTERS_REQUEST');
export const getPresetFiltersSuccess = createAction('GET_PRESET_FILTERS_SUCCESS');
export const getPresetFiltersFail = createAction('GET_PRESET_FILTERS_FAIL');

export const createPresetFiltersRequest = createAction('CREATE_PRESET_FILTERS_REQUEST');
export const createPresetFiltersSuccess = createAction('CREATE_PRESET_FILTERS_SUCCESS');
export const createPresetFiltersFail = createAction('CREATE_PRESET_FILTERS_FAIL');

export const savePresetFiltersRequest = createAction('SAVE_PRESET_FILTERS_REQUEST');
export const savePresetFiltersSuccess = createAction('SAVE_PRESET_FILTERS_SUCCESS');
export const savePresetFiltersFail = createAction('SAVE_PRESET_FILTERS_FAIL');

export const deletePresetFiltersRequest = createAction('DELETE_PRESET_FILTERS_REQUEST');
export const deletePresetFiltersSuccess = createAction('DELETE_PRESET_FILTERS_SUCCESS');
export const deletePresetFiltersFail = createAction('DELETE_PRESET_FILTERS_FAIL');

export const cleanupPresetFilters = createAction('CLEANUP_PRESET_FILTERS');
