import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { getTranslation } from 'utils/compositeTranslationHandler';
import { useTranslation } from 'react-i18next';
import ClearInputIcon from 'modules/common/ClearInputIcon';

import styles from './styles';

const isChecked = (value) =>
  typeof value === 'boolean' ? value : Boolean(value) && value !== 'false';
const isBlank = (value) => value === null || value === '';

const CheckboxInput = ({
  classes,
  input,
  label,
  meta: { error, submitFailed },
  errorMessage,
  clearable,
  onClear = () => null,
  ...others
}) => {
  const { name, value, onChange } = input;
  const { t } = useTranslation('common');

  return (
    <>
      <FormControlLabel
        classes={{
          root: !clearable ? classes.formControlLabel : classes.formControlLabelWithClearIcon,
          label: classes.label,
          disabled: classes.disabled,
        }}
        control={
          <Checkbox
            name={name}
            checked={isChecked(value)}
            onChange={onChange}
            disabled={others.disabled}
            classes={{
              root: classes.checkbox,
              checked: classes.checkboxChecked,
            }}
            icon={<CheckBoxOutlineBlankIcon className={classes.icon} />}
            checkedIcon={<CheckBoxIcon className={classes.icon} />}
            {...others}
          />
        }
        label={label}
      />
      {clearable && !isBlank(value) && <ClearInputIcon name={name} onClickHandler={onClear} />}
      <div className={classes.errorMessage}>
        {error && submitFailed && <div>{errorMessage || getTranslation(t, error)}</div>}
      </div>
    </>
  );
};

CheckboxInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    checkbox: PropTypes.string.isRequired,
    checkboxChecked: PropTypes.string.isRequired,
    disabled: PropTypes.string,
  }).isRequired,
};

CheckboxInput.defaultProps = {
  label: '',
};

export default withStyles(styles)(CheckboxInput);
