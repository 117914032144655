import React from 'react';
import { useTranslation } from 'react-i18next';
import { STATUS_GROUP_NAME, STATUS_LABEL } from '../../constants';
import { Status as PaymentStatus } from '../../../../../constants/paymentStatus';
import TPTypography from 'components/TP-UI/TPTypography';
import { QUIZ_ANSWER_STATUSES } from 'constants/quizAnswerStatuses';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import { REFERRAL_STATUS } from 'modules/referFriend/constants';

const StatusLabel = ({ value, statusGroup, size, labels }) => {
  const { t } = useTranslation('common');
  const statusTextColor = statusGroup === STATUS_GROUP_NAME.PENDING ? 'secondary' : 'primary';
  const statusTextVariant = size === 'small' ? 'body2' : 'body1';
  const statusLabel =
    Object.values({ ...QUIZ_ANSWER_STATUSES, ...PaymentStatus, ...REFERRAL_STATUS })
      .map((item) => item.toLowerCase())
      .indexOf(value.toLowerCase()) === -1
      ? (labels && labels[value]) || STATUS_LABEL[value] || value
      : t(`constants.statuses.${value}`);

  return (
    <TPTypography component="span" variant={statusTextVariant} color={statusTextColor}>
      {capitalize(statusLabel)}
    </TPTypography>
  );
};

StatusLabel.propTypes = {
  value: PropTypes.string.isRequired,
  statusGroup: PropTypes.oneOf(Object.values(STATUS_GROUP_NAME)),
  size: PropTypes.oneOf(['medium', 'small']),
  labels: PropTypes.object,
};

export default StatusLabel;
