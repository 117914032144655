import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const TPStepContent = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.content}>{children}</div>;
};

TPStepContent.propTypes = {
  /**
   * The children prop here represents the custom content that will be displayed for each step.
   */
  children: PropTypes.node.isRequired,
};

export default TPStepContent;
