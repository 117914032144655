import get from 'lodash/get';
import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';
import map from 'lodash/map';
import assign from 'lodash/assign';

import { ADD_ACCOUNT_FORM, FUND_DEMO_ACCOUNT_FORM } from '../constants';
import { ACCOUNT_SERVERS, ACCOUNT_STATUSES, MT4_PLATFORM } from 'constants/index';
import formatPrice from 'utils/formatPrice';
import { checkAccountExpirationPeriod } from 'utils/checkExpirationPeriod';
import { getDefaultAccountId } from 'selectors';
import {
  sortFilterDefaultAccounts,
  sortFilterDepositableAccounts,
  sortFilterTransferableAccounts,
  sortFilterWithdrawableAccounts,
} from '../helpers';
import { isMetatraderPlatform } from 'helpers/checkMetatraderPlatform';

export const getActiveAccountsTab = (state) => state.accounts.activeAccountsTab;
export const getIsRequestAccountHidden = (state) => state.accounts.requestAccountDialogHidden;
export const getActiveDialog = (state) => state.accounts.activeDialog;

export const getLiveAccounts = (state) =>
  [...state.accounts.live, ...state.accounts.rebate].filter(
    (account) => !get(account, 'isArchived.status', false),
  );
export const getApprovedLiveAccounts = (state) =>
  [...state.accounts.live, ...state.accounts.rebate].filter(
    (account) =>
      !get(account, 'isArchived.status', false) &&
      get(account, 'status') === ACCOUNT_STATUSES.APPROVED,
  );
export const getLiveAndAtwAccounts = (state) =>
  [...state.accounts.live, ...state.accounts.rebate, ...state.accounts.atw].filter(
    (account) => !get(account, 'isArchived.status', false),
  );
export const getRebateAccounts = (state) => state.accounts.rebate;
export const getDemoAccounts = (state) => state.accounts.demo;
export const getMTLiveAccounts = createSelector(getLiveAndAtwAccounts, (accounts) => {
  return accounts.filter((account) => isMetatraderPlatform(account.platform));
});
export const showHidden = (state) => state.accounts.showHidden;
export const hideAccountId = (state) => get(state, 'accounts.hideAccount.accountId');
export const hideAccountIsHidden = (state) => get(state, 'accounts.hideAccount.isHidden');

export const openedAccountId = (state) => state.accounts.openedAccountId;

export const getRebateAccountsLength = createSelector(getRebateAccounts, (rebateAccounts) => {
  return rebateAccounts ? rebateAccounts.length : 0;
});

export const getAccountsState = (state) => state.accounts;
export const getAllAccounts = createSelector(getAccountsState, (state) => state.accounts);

export const getAccountsByActiveTab = createSelector(
  getActiveAccountsTab,
  getAccountsState,
  (activeAccountsTab, accounts) => {
    return accounts[activeAccountsTab];
  },
);

export const getZeroBalanceLiveAccounts = createSelector(getAccountsState, (accounts) => {
  return accounts.live.filter((acc) => parseFloat(acc.balance) <= 0);
});

export const getZeroBalanceLiveAccountsMt4Logins = createSelector(
  getZeroBalanceLiveAccounts,
  (accounts) => {
    return accounts.map(({ platform, mt4login }) => ({ platform, mt4login }));
  },
);

export const getLiveAccountsCount = createSelector(getLiveAccounts, (accounts) => {
  return accounts.length;
});

export const getDemoAccountsCount = createSelector(getDemoAccounts, (accounts) => {
  return accounts.length;
});

export const getNonExpiredDemoAccountsCount = createSelector(getDemoAccounts, (accounts) => {
  let nonExpDemoAccounts = [];
  accounts.forEach((account) => {
    let { isLive, createdAt } = account;
    if (!checkAccountExpirationPeriod(isLive, createdAt)) {
      nonExpDemoAccounts.push(account);
    }
  });

  return nonExpDemoAccounts.length;
});

export const getAccounts = createSelector(getAccountsState, (accounts) => {
  return [...accounts.live, ...accounts.rebate, ...accounts.demo];
});

export const getIsLive = (state) => {
  const selector = formValueSelector(ADD_ACCOUNT_FORM);
  let accountServer = selector(state, 'accountServer');

  return accountServer === ACCOUNT_SERVERS.LIVE;
};

export const getIsMt4Live = (state) => {
  const selector = formValueSelector(ADD_ACCOUNT_FORM);
  const accountServer = selector(state, 'accountServer');
  const platform = selector(state, 'platform');

  return accountServer === ACCOUNT_SERVERS.LIVE && platform === MT4_PLATFORM;
};

export const filterSortAccounts = (accounts = [], transactionType) => {
  let filteredAndSortedAccounts;

  if (transactionType === 'deposit') {
    filteredAndSortedAccounts = sortFilterDepositableAccounts(accounts);
  } else if (transactionType === 'transfer') {
    filteredAndSortedAccounts = sortFilterTransferableAccounts(accounts);
  } else if (transactionType === 'withdraw') {
    filteredAndSortedAccounts = sortFilterWithdrawableAccounts(accounts);
  } else {
    filteredAndSortedAccounts = sortFilterDefaultAccounts(accounts);
  }

  return filteredAndSortedAccounts;
};

const _getLiveAccountsAsOptions = createSelector(
  getLiveAccounts,
  (_, transactionType) => transactionType,
  (liveAccounts, transactionType) => {
    const filteredAndSortedAccounts = filterSortAccounts(liveAccounts, transactionType);

    return map(filteredAndSortedAccounts, (liveAccount) => {
      return assign(liveAccount, {
        value: liveAccount._id,
        label: `${liveAccount.accountNickname} (${liveAccount.mt4login}) ${formatPrice(
          liveAccount.balance,
          liveAccount.currency,
        )}`,
      });
    });
  },
);

export const getLiveDepositableAccountsAsOptions = (state) =>
  _getLiveAccountsAsOptions(state, 'deposit');
export const getLiveTransferableAccountsAsOptions = (state) =>
  _getLiveAccountsAsOptions(state, 'transfer');
export const getLiveWithdrawableAccountsAsOptions = (state) =>
  _getLiveAccountsAsOptions(state, 'withdraw');
export const getLiveAccountsAsOptions = (state) => _getLiveAccountsAsOptions(state);

export const getDemoAccountsAsOptions = createSelector(getDemoAccounts, (accounts) => {
  return map(accounts, (account) => {
    return assign(account, {
      value: account._id,
      label: account.accountNickname,
    });
  });
});

export const getDefaultAccount = createSelector(
  getLiveAccountsAsOptions,
  getDefaultAccountId,
  (accounts, defaultAccount) => {
    if (accounts.length > 0 && defaultAccount) {
      return { accountId: defaultAccount };
    }

    return accounts.length === 1 ? { accountId: accounts[0]._id } : undefined;
  },
);

export const getFundAccount = (state) => {
  const selector = formValueSelector(FUND_DEMO_ACCOUNT_FORM);
  return selector(state, 'accountId');
};

export const getFundDemoAccountCurrency = (state) => {
  const accountId = getFundAccount(state);
  const accounts = getDemoAccounts(state);
  if (accountId && accounts.length) {
    let account = accounts.find((a) => a._id === accountId);
    if (account) {
      return account.currency;
    }
  }
  return '';
};

export const isQuizSelected = (state) => state.accounts.isQuizSelected;
export const isUpgrade = (state) => state.accounts.isUpgrade || false;
