import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TPStepContent from 'components/TP-UI/TPStepper/TPStepContent';
import TPStepHeader from 'components/TP-UI/TPStepper/TPStepHeader';
import TPGrid from 'components/TP-UI/TPGrid';
import classnames from 'classnames';

import useStyles from './styles';

const TPStepper = ({ steps, activeStep, onStepChange }) => {
  const classes = useStyles();
  const isSmallScreenSize = !useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <TPGrid
      container
      className={classnames(classes.root, { [classes.mobileOnly]: isSmallScreenSize })}>
      <TPGrid item xs={12} sm={1} className={classes.stepperContainer}>
        <Stepper
          activeStep={activeStep}
          orientation={isSmallScreenSize ? 'horizontal' : 'vertical'}
          className={classes.stepperBase}>
          {steps.map((item, index) => (
            <Step key={item.key}>
              <StepLabel
                onClick={item.passed ? () => onStepChange(index) : undefined}
                className={classNames({
                  [classes.stepLabel]: item.passed,
                })}
              />
            </Step>
          ))}
        </Stepper>
      </TPGrid>
      <TPGrid item xs={12} sm={11}>
        {steps.map((item, index) => {
          const {
            title,
            passed,
            key,
            headerComponent: HeaderComponent,
            contentComponent: ContentComponent,
            data,
          } = item;

          const showHeader = !isSmallScreenSize || passed || index === activeStep;
          return (
            <div key={key} className={showHeader ? classes.headerWrapper : ''}>
              {showHeader && (
                <TPStepHeader
                  title={title}
                  active={index === activeStep}
                  passed={passed}
                  component={
                    <HeaderComponent
                      active={index === activeStep}
                      passed={passed}
                      stepIndex={index}
                      onStepChange={onStepChange}
                      {...data}
                    />
                  }
                />
              )}
              {index === activeStep && (
                <TPStepContent>
                  <ContentComponent onStepChange={onStepChange} {...data} stepIndex={index} />
                </TPStepContent>
              )}
            </div>
          );
        })}
      </TPGrid>
    </TPGrid>
  );
};

TPStepper.propTypes = {
  /**
   * An array containing the details of each step in the stepper.
   */
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Unique identifier for the step.
       */
      key: PropTypes.number.isRequired,
      /**
       * Optional title for the step which can be displayed on the header.
       */
      title: PropTypes.string,
      /**
       * Boolean indicating if the step has been completed or passed.
       */
      passed: PropTypes.bool,
      /**
       * React component to represent the header of the step.
       */
      headerComponent: PropTypes.elementType.isRequired,
      /**
       * React component to represent the content or body of the step.
       */
      contentComponent: PropTypes.elementType.isRequired,
    }),
  ).isRequired,
  /**
   * Current active step in the stepper (0-based index).
   */
  activeStep: PropTypes.number.isRequired,
  /**
   * Callback function to handle changes in the step, such as moving to the next or previous step.
   */
  onStepChange: PropTypes.func.isRequired,
};

export default TPStepper;
