import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Typography } from '@material-ui/core';

import CustomLink from 'modules/common/CustomLink';

const TableBoldAndNormalTextField = ({
  classes,
  label,
  dataLabelBold,
  dataLabelNormal,
  tableItem,
  route,
  className,
}) => {
  return (
    <div className={classNames([classes.item, className])}>
      <Typography className={classes.label}>{label}</Typography>
      <Typography className={classes.dataText}>
        {route ? (
          <CustomLink to={route}>
            <span className={classes.boldText}>{get(tableItem, dataLabelBold)}</span> (
            {get(tableItem, dataLabelNormal)})
          </CustomLink>
        ) : (
          <>
            <span className={classes.boldText}>{get(tableItem, dataLabelBold)}</span> (
            {get(tableItem, dataLabelNormal)})
          </>
        )}
      </Typography>
    </div>
  );
};

TableBoldAndNormalTextField.propTypes = {
  label: PropTypes.string.isRequired,
  tableItem: PropTypes.object.isRequired,
  className: PropTypes.string,
  route: PropTypes.string,
  dataLabelBold: PropTypes.string.isRequired,
  dataLabelNormal: PropTypes.string.isRequired,
};

export default withStyles(styles)(TableBoldAndNormalTextField);
