import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    spacing: {
      margin: theme.spacing(1),
    },
    root: {
      overflowX: 'hidden',
      overflowY: 'auto',
      height: '326px',
      border: `2px solid ${theme.palette.neutral.lightest}`,
      transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      background: theme.palette.background.main,
    },
    minimized: {
      width: '129px',
    },
    maximized: {
      width: '228px',
    },
  }),
  { name: 'NavMenuLists' },
);

export default useStyles;
