import { combineReducers } from 'redux';

import bankWire from './bankWire';
import redirectUrl from './redirectUrl';
import history from './history';
import accountId from './accountId';
import amount from './amount';
import currency from './currency';
import rate from './rate';
import zepto from './zepto';
import currencyCloud from './currencyCloud';
import cryptoDay1 from './cryptoDay1';
import bankTransfer from './bankTransfer';
import finmo from './finmo';
import monoova from './monoova';

const reducer = combineReducers({
  bankWire,
  redirectUrl,
  history,
  amount,
  currency,
  accountId,
  rate,
  zepto,
  currencyCloud,
  cryptoDay1,
  bankTransfer,
  finmo,
  monoova,
});

export default reducer;
