import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getTranslation } from 'utils/compositeTranslationHandler';
import Collapse from '@material-ui/core/Collapse';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import TPTypography from 'components/TP-UI/TPTypography';
import TPNavMenuListItem from '../TPNavMenuListItem';
import useStyles from './styles';

const TPNavMenuListAccordion = ({
  id,
  selectedRoute,
  label,
  iconComponent: IconComponent,
  expanded = false,
  selected = false,
  items,
  onClick,
  onNestedItemClick,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  return (
    <>
      <MenuItem
        id={id}
        key={id}
        className={classNames(classes.menuItem, {
          [classes.selectedMenuItem]: selected,
        })}
        onClick={handleClick}>
        <div className={classes.menuItemLabel}>
          <ListItemIcon className={classes.iconContainer}>
            <IconComponent />
          </ListItemIcon>
          <TPTypography className={classes.label}>{label}</TPTypography>
        </div>
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </MenuItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <MenuList
          className={classNames(classes.nestedList, {
            [classes.selectedNestedList]: selected,
          })}>
          {items.map((item) => (
            <TPNavMenuListItem
              id={item.id}
              key={item.id}
              label={getTranslation(t, item.label)}
              selected={selectedRoute.includes(item.path)}
              iconComponent={item.iconComponent}
              path={item.path}
              labelText={item.labelText}
              className={classNames(classes.nestedItem, {
                [classes.nestedItemInSelectedMenu]: selected,
                [classes.selectedNestedItem]: selectedRoute.includes(item.path),
              })}
              onClick={onNestedItemClick}
            />
          ))}
        </MenuList>
      </Collapse>
    </>
  );
};

TPNavMenuListAccordion.propTypes = {
  id: PropTypes.string,
  selectedRoute: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  iconComponent: PropTypes.elementType.isRequired,
  expanded: PropTypes.bool,
  selected: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      id: PropTypes.string,
      iconComponent: PropTypes.elementType,
      path: PropTypes.string,
    }),
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  onNestedItemClick: PropTypes.func,
};

export default TPNavMenuListAccordion;
