import config from 'config';
// script is based on original onw provided by "Hotjar" with a small modification to avoid having multiple instances
export function loadHotjar() {
  (function(h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function() {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: config.HOTJAR.ID, hjsv: config.HOTJAR.SV };
    h._scriptPath = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    if (!document.querySelector('script[src*="' + h._scriptPath + '"]')) {
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = true;
      r.src = h._scriptPath;
      a.appendChild(r);
    }
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
}
