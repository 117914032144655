import { handleActions } from 'redux-actions';
import get from 'lodash/get';
import {
  currencyCloudWithdrawalRequiredFieldsSuccess,
  currencyCloudWithdrawalRequiredFieldsFail,
  currencyCloudWithdrawalConversionRateSuccess,
  currencyCloudWithdrawalConversionRateFail,
} from '../actions';

const defaultState = { fields: [], conversionRate: 0 };

export default handleActions(
  {
    [currencyCloudWithdrawalRequiredFieldsSuccess](state, action) {
      return {
        ...state,
        fields: get(action, 'response.data', []),
      };
    },
    [currencyCloudWithdrawalConversionRateSuccess](state, action) {
      return {
        ...state,
        conversionRate: get(action, 'response.data.conversionRate', 0),
      };
    },
    [currencyCloudWithdrawalConversionRateFail](state) {
      return {
        ...state,
        conversionRate: 0,
      };
    },
    [currencyCloudWithdrawalRequiredFieldsFail]() {
      return defaultState;
    },
  },
  defaultState,
);
