import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';

import rootReducer from '../rootReducer';
import rootSaga, { createSagaInjector } from '../rootSaga';
import config from 'config';

export const history = createBrowserHistory({ basename: config.HUB_PREFIX });

const sagaMiddleware = createSagaMiddleware();

export function configureStore() {
  let store = createStore(
    rootReducer(history),
    composeWithDevTools(applyMiddleware(sagaMiddleware)),
  );
  if (process.env.NODE_ENV === 'production') {
    store = createStore(rootReducer(history), applyMiddleware(sagaMiddleware));
  }
  store.injectSaga = createSagaInjector(sagaMiddleware.run);
  sagaMiddleware.run(rootSaga);
  return store;
}
