/**
 * fields format:
 * name - header column name
 * field - key prop of item
 * format - function for formating value
 * renderItem - function for formating value
 */

const generateCSV = (items, fields, topHeader) => {
  const headers = fields.map((f) => f.name).join(',');
  const csv = items.map((item) =>
    fields
      .map((f) => {
        const val = item[f.field];
        return `"${f.format ? f.format(val) : f.renderItem ? f.renderItem(item) : val}"`;
      })
      .join(','),
  );

  return [...(topHeader ? [topHeader, []] : []), headers, ...csv].join('\n');
};

export default generateCSV;
