import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { required, checkNonLatin } from 'utils/validation/fieldValidationRules';
import TPGrid from 'components/TP-UI/TPGrid';
import TPButton from 'components/TP-UI/TPButton';
import TPTextField from 'components/TP-UI/TPTextField';
import { REDUX_FORM_DEFAULT_CONFIG } from 'constants/form';
import FormError from 'modules/common/FormError';
import {
  CONFIRMATION_PASSWORD_VALIDATORS,
  PASSWORD_VALIDATORS,
} from 'utils/validation/fieldsValidators';

import useStyles from './styles';

const ForcePasswordChangeForm = ({ handleSubmit, error, submitFailed, isPending }) => {
  const classes = useStyles();
  const { t } = useTranslation('auth');

  return (
    <form className={classes.form} onSubmit={handleSubmit} noValidate>
      <TPGrid container spacing={2} direction="column">
        {error && submitFailed ? (
          <TPGrid item xs={12}>
            <FormError error={error} />
          </TPGrid>
        ) : null}
        <TPGrid item container spacing={1} direction="column">
          <TPGrid item xs={12}>
            <Field
              name="oldPassword"
              label={t('common:labels.oldPassword')}
              placeholder={t('common:labels.oldPassword')}
              confidential
              fullWidth
              autocomplete="current-password"
              component={TPTextField}
              required
              validate={[checkNonLatin, required]}
            />
          </TPGrid>
          <TPGrid item xs={12}>
            <Field
              name="password"
              label={t('common:labels.newPassword')}
              placeholder={t('common:labels.newPassword')}
              confidential
              fullWidth
              autocomplete="new-password"
              component={TPTextField}
              required
              hint={t('tipText')}
              validate={[required, ...PASSWORD_VALIDATORS]}
            />
          </TPGrid>
          <TPGrid item xs={12}>
            <Field
              name="confirmPassword"
              label={t('common:labels.confirmPassword')}
              placeholder={t('common:labels.confirmPassword')}
              confidential
              fullWidth
              autocomplete="new-password"
              component={TPTextField}
              required
              validate={[required, ...CONFIRMATION_PASSWORD_VALIDATORS]}
            />
          </TPGrid>
        </TPGrid>
        <TPGrid item xs={12}>
          <TPButton fullWidth primary size="large" type="submit" loading={isPending}>
            {t('forcePasswordChange.updatePassword')}
          </TPButton>
        </TPGrid>
      </TPGrid>
    </form>
  );
};

ForcePasswordChangeForm.propTypes = {
  form: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
};

export default reduxForm({ ...REDUX_FORM_DEFAULT_CONFIG })(ForcePasswordChangeForm);
