import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    modalContainer: {
      width: (props) => (props.columnsCount === 1 ? '400px' : '600px'),
    },
    columnsContainer: {
      columnCount: (props) => props.columnsCount,
      columnGap: theme.spacing(1),
    },
  }),
  { name: 'TPColumnsModal' },
);
