import { combineActions, handleActions } from 'redux-actions';
import { getMailchimpStatusSuccess } from '../actions';

const defaultState = [];

export default handleActions(
  {
    [combineActions(getMailchimpStatusSuccess)]: (state, action) => {
      return action.response.data;
    },
  },
  defaultState,
);
