import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import TPModal from 'components/TP-UI/TPModal';
import TPTypography from 'components/TP-UI/TPTypography';
import { closeModal } from 'components/TP-UI/TPModal/actions';
import { ACCOUNT_PLANS } from 'constants/accountPlans';
import { selectAccountPlanRequest } from 'modules/accounts/pages/MyAccounts/actions';

import verificationModalNames from '../../constants/verificationModalNames';
import ConfirmContinueAsRetailClientForm from './ConfirmContinueAsRetailClientForm';
import QuizModalContent from '../QuizModalContent';
import { useTheme } from '@material-ui/core';

const ProQuizBlockedModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('quiz');
  const { quiz } = useTheme();
  const imageConfig = quiz.proQuizBlockedModal.avatar;

  const handleSubmit = () => {
    dispatch(selectAccountPlanRequest(ACCOUNT_PLANS.RETAIL));
    dispatch(closeModal(verificationModalNames.PRO_QUIZ_BLOCKED_MODAL));
  };

  return (
    <TPModal
      modalName={verificationModalNames.PRO_QUIZ_BLOCKED_MODAL}
      title={t('pro.page.title')}
      hideButtons>
      <QuizModalContent
        imageConfig={imageConfig}
        text={
          <>
            <TPTypography gutterBottom>{t('proQuizBlockedModal.description_1')}</TPTypography>
            <TPTypography>{t('proQuizBlockedModal.description_2')}</TPTypography>
          </>
        }
        title={t('proQuizBlockedModal.title')}>
        <ConfirmContinueAsRetailClientForm onSubmit={handleSubmit} />
      </QuizModalContent>
    </TPModal>
  );
};

export default ProQuizBlockedModal;
