import { CURRENCY } from 'constants/index';

export const WITHDRAW_TRANSFER_FORM = 'WITHDRAW_TRANSFER_FORM';
export const WITHDRAW_UNIONPAY_NAMES = 'WITHDRAW_UNIONPAY_NAMES';
export const DEFAULT_CURRENCY = 'USD';
export const WITHDRAW_SEARCH_FORM = 'WITHDRAW_SEARCH_FORM';
export const WITHDRAW_SKRILL_FORM = 'WITHDRAW_SKRILL_FORM';
export const WITHDRAW_NETELLER_FORM = 'WITHDRAW_NETELLER_FORM';
export const WITHDRAW_PAYPAL_FORM = 'WITHDRAW_PAYPAL_FORM';
export const WITHDRAW_PERFECT_MONEY_FORM = 'WITHDRAW_PERFECT_MONEY_FORM';
export const WITHDRAW_MIFINITY_FORM = 'WITHDRAW_MIFINITY_FORM';
export const WITHDRAW_INTERAC_E_TRANSFER_OUTBOUND_FORM =
  'WITHDRAW_INTERAC_E_TRANSFER_OUTBOUND_FORM';
export const WITHDRAW_JETON_FORM = 'WITHDRAW_JETON_FORM';
export const WITHDRAW_FINRAX_FORM = 'WITHDRAW_FINRAX_FORM';
export const WITHDRAW_DAY1_FORM = 'WITHDRAW_DAY1_FORM';
export const WITHDRAW_ASTROPAY_FORM = 'WITHDRAW_ASTROPAY_FORM';
export const WITHDRAW_DRAGONPAY_FORM = 'WITHDRAW_DRAGONPAY_FORM';
export const WITHDRAW_PAYID_FORM = 'WITHDRAW_PAYID_FORM';
export const WITHDRAW_HYPERWALLET_FORM = 'WITHDRAW_HYPERWALLET_FORM';
export const WITHDRAW_HYPERWALLET_USER_STATE_FORM = 'WITHDRAW_HYPERWALLET_USER_STATE_FORM';
export const WITHDRAW_HYPERWALLET_STATUS_REQUEST_CHANGE_FORM =
  'WITHDRAW_HYPERWALLET_STATUS_REQUEST_CHANGE_FORM';
export const WITHDRAW_HYPERWALLET_USER_ADDRESS_FORM = 'WITHDRAW_HYPERWALLET_USER_ADDRESS_FORM';
export const WITHDRAW_CURRENCY_CLOUD_FORM = 'WITHDRAW_CURRENCY_CLOUD_FORM';

export const MIFINITY_CURRENCIES = [
  CURRENCY.AUD,
  CURRENCY.CAD,
  CURRENCY.EUR,
  CURRENCY.GBP,
  CURRENCY.USD,
];

export const MIFINITY_EU_CURRENCIES = [
  ...MIFINITY_CURRENCIES,
  CURRENCY.CZK,
  CURRENCY.DKK,
  CURRENCY.PLN,
  CURRENCY.NOK,
  CURRENCY.SEK,
  CURRENCY.CHF,
];

export const JETON_TARGET_CURRENCIES = [
  CURRENCY.AUD,
  CURRENCY.CAD,
  CURRENCY.EUR,
  CURRENCY.GBP,
  CURRENCY.USD,
  CURRENCY.JPY,
];

// this should match server side WITHDRAWAL_METHODS record
export const WITHDRAW_METHOD = {
  currencyCloudLocal: 'Currency Cloud Local',
  bankwire: 'Bankwire',
  hyperwallet: 'Bank Transfer',
  currencyCloud: 'Currency Cloud',
  localBank: 'Local Bank',
  payid: 'PayID',
  unionpay: 'Unionpay/Local Bank',
  skrill: 'Skrill',
  neteller: 'Neteller',
  paypal: 'Paypal',
  perfectMoney: 'Perfect Money',
  mifinity: 'Mifinity',
  jeton: 'Jeton',
  interacETransferOutbound: 'Interac e-Transfer Outbound',
  crypto: 'Crypto',
  cryptoDay1: 'Crypto Day1',
  astropay: 'Astropay',
  dragonpay: 'Dragonpay',
};

export const WITHDRAW_METHOD_LABEL = {
  [WITHDRAW_METHOD.unionpay]: 'UnionPay/Local Bank Transfer',
  [WITHDRAW_METHOD.bankwire]: 'Bank Wire/Transfer',
  [WITHDRAW_METHOD.hyperwallet]: 'Bank Transfer',
  [WITHDRAW_METHOD.localBank]: 'Local Bank Transfer',
  [WITHDRAW_METHOD.skrill]: 'Skrill',
  [WITHDRAW_METHOD.neteller]: 'Neteller',
  [WITHDRAW_METHOD.paypal]: 'PayPal',
  [WITHDRAW_METHOD.perfectMoney]: 'Perfect Money',
  [WITHDRAW_METHOD.mifinity]: 'MiFinity',
  [WITHDRAW_METHOD.interacETransferOutbound]: 'Interac\u00AEe-Transfer',
  [WITHDRAW_METHOD.crypto]: 'Crypto',
  [WITHDRAW_METHOD.cryptoDay1]: 'Crypto',
  [WITHDRAW_METHOD.astropay]: 'Astropay',
  [WITHDRAW_METHOD.dragonpay]: 'Dragonpay',
  [WITHDRAW_METHOD.jeton]: 'Jeton',
  [WITHDRAW_METHOD.payid]: 'PayID',
  [WITHDRAW_METHOD.currencyCloud]: 'Bank Transfer',
  [WITHDRAW_METHOD.currencyCloudLocal]: 'Local Bank Transfer',
};

export const MIN_WITHDRAWAL_COMMON = 0.01;

export const MIN_WITHDRAWAlS = {
  usd: 10,
  gbp: 10,
  eur: 10,
  aud: 10,
  cad: 15,
  hkd: 100,
  jpy: 1120,
  nzd: 15,
  sgd: 15,
  thb: 355,
  huf: 500,
  czk: 10,
  dkk: 10,
  pln: 10,
  nok: 10,
  sek: 10,
  chf: 10,
};

export const MIN_MIFINITY_WITHDRAWALS = {
  ...MIN_WITHDRAWAlS,
  aud: 15,
  jpy: 1500,
  nzd: 20,
  czk: 250,
  dkk: 75,
  pln: 50,
  nok: 100,
  sek: 100,
  thb: 300,
};

export const MAX_WITHDRAWAlS = {
  usd: 9999,
  gbp: 9999,
  eur: 9999,
  aud: 9999,
  cad: 9999,
  hkd: 9999,
  jpy: 9999,
  nzd: 9999,
  sgd: 9999,
  huf: 9999,
  czk: 9999,
  dkk: 9999,
  pln: 9999,
  nok: 9999,
  sek: 9999,
  chf: 9999,
};

export const MAX_MIFINITY_WITHDRAWALS = { ...MAX_WITHDRAWAlS, gbp: 8500, chf: 9500, thb: 500000 };

export const MIN_INTERNATIONAL_WITHDRAWAlS = {
  usd: 35,
  gbp: 30,
  eur: 35,
  aud: 50,
  cad: 50,
  hkd: 280,
  jpy: 4000,
  nzd: 55,
  sgd: 50,
  thb: 355,
  huf: 500,
  czk: 35,
  dkk: 35,
  pln: 35,
  nok: 35,
  sek: 35,
  chf: 35,
};

export const WITHDRAW_TRANSFER_MODAL = 'WITHDRAW_TRANSFER_MODAL';

export const WITHDRAW_RESULT_MODAL_SUCCESS = 'WITHDRAW_RESULT_MODAL_SUCCESS';
export const WITHDRAW_RESULT_MODAL_ERROR = 'WITHDRAW_RESULT_MODAL_ERROR';

export const CLIENT_CONFIRM_WITHDRAW_MODAL = 'CLIENT_CONFIRM_WITHDRAW_MODAL';

export const SAVE_BANK_DETAILS_MODAL = 'OPEN_SAVE_BANK_DETAILS_MODAL';

export const HIDE_NETELLER_FOR_COUNTRIES = new Set([
  'Aland Islands',
  'Gibraltar',
  'Lichtenstein',
  'Mayotte',
  'Svalbard and Jan Mayen',
  'United Kingdom',
  'United Kingdom of Great Britain and Northern Ireland',
  'Guyana',
  'Japan',
  'Canada',
]);

export const HIDE_MIFINITY_FOR_COUNTRIES = new Set([
  'Afghanistan',
  'Belarus',
  'Congo (DRC)',
  "Cote d'Ivoire",
  'Cuba',
  'Iran',
  'Iraq',
  'Liberia',
  'Libyan Arab Jamahiriya',
  'Myanmar',
  'Nicaragua',
  'North Korea',
  'South Sudan',
  'Sudan',
  'Syrian Arab Republic',
  'United States',
  'United States Minor Outlying Islands',
  'Yemen',
  'Zimbabwe',
]);

export const HIDE_SKRILL_FOR_COUNTRIES = new Set([
  'Aland Islands',
  'Gibraltar',
  'Lichtenstein',
  'Mayotte',
  'Svalbard and Jan Mayen',
  'United Kingdom',
  'United Kingdom of Great Britain and Northern Ireland',
  'Guyana',
  'Japan',
]);

export const HIDE_PAYPAL_FOR_COUNTRIES = new Set([
  'Algeria',
  'Angola',
  'Anguilla',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Azerbaijan Republic',
  'Bahamas',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Brazil',
  'British Virgin Islands',
  'Brunei',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Chad',
  'China',
  'Colombia',
  'Comoros',
  'Congo, Democratic Republic of',
  'Congo, Republic of',
  'Cook Islands',
  'Costa Rica',
  'Côte d’Ivoire',
  'Democratic Republic of Congo',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'El Salvador',
  'Eritrea',
  'Ethiopia',
  'Falkland Islands',
  'Gabon Republic',
  'Gambia',
  'Grenada',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'India',
  'Jamaica',
  'Japan',
  'Kiribati',
  'Kyrgyzstan',
  'Laos',
  'Macedonia',
  'Madagascar',
  'Maldives',
  'Mali',
  'Marshall Islands',
  'Mauritania',
  'Mayotte',
  'Micronesia, Federated States of',
  'Mongolia',
  'Monserrat',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands Antilles',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Pitcairn Islands',
  'Puerto Rico',
  'Rwanda',
  'Saint Helena',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Sierra Leone',
  'Solomon Islands',
  'Somalia',
  'South Georgia and the South Sandwich Islands',
  'Sri Lanka',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Swaziland',
  'Tajikistan',
  'Tanzania',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'USA',
  'Uganda',
  'Ukraine',
  'Vanuatu',
  'Venezuela',
  'Virgin Islands (British)',
  'Virgin Islands (USA)',
  'Wallis and Futuna Islands',
  'Yemen',
  'Zambia',
  'Zimbabwe',
]);

export const INTERAC_ETO_ALLOWED_COUNTRIES = new Set(['Canada']);

export const HIDE_ASTROPAY_FOR_COUNTRIES = new Set(['Brazil']);

export const UNIONPAY_ALLOWED_COUNTRIES = new Set(['China', 'Taiwan']);
export const HIDE_FINRAX_FOR_COUNTRIES = new Set([
  'Afghanistan',
  'Albania',
  'Barbados',
  'Belarus',
  'Botswana',
  'Cambodia',
  'Cayman Islands',
  'Cuba',
  'Ghana',
  'Haiti',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Jamaica',
  'Mali',
  'Mauritius',
  'Mongolia',
  'Myanmar',
  'Nicaragua',
  'Pakistan',
  'Panama',
  'Senegal',
  'South Sudan',
  'Bahamas',
  'Trinidad and Tobago',
  'Uganda',
  'Vanuatu',
  'Yemen',
  'Zimbabwe',
]);
export const HIDE_BANKWIRE_FOR_COUNTRIES = new Set([
  'Russian Federation',
  'Austria',
  'Belgium',
  'Bulgaria',
  'Canada',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Hungary',
  'Iceland',
  'Ireland',
  'Italy',
  'Latvia',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Netherlands',
  'Norway',
  'Poland',
  'Portugal',
  'Romania',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
]);
export const DRAGONPAY_ALLOWED_COUNTRIES = new Set(['Philippines']);
export const PAYID_ALLOWED_COUNTRIES = new Set(['Australia']);

export const HYPERWALLET_HIDE_FOR_USERS = new Set(['me@albaneselorenzo.com']);
export const BANKWIRE_SHOW_FOR_USERS = new Set([
  'me@albaneselorenzo.com',
  'justinshaw@live.ca',
  '95115887@segam.com',
  'julius@vlvt.ai',
  'spitzeka@libero.it',
  'broker@lsq.ai',
]);
export const CURRENCY_CLOUD_HIDE_FOR_USERS = new Set([
  'justinshaw@live.ca',
  '95115887@segam.com',
  'julius@vlvt.ai',
  'spitzeka@libero.it',
  'broker@lsq.ai',
]);

export const HYPERWALLET_ALLOWED_COUNTRIES = {}; // Empty for now. May be returned back in the future.

export const CURRENCY_CLOUD_ALLOWED_COUNTRIES = new Set(['Canada']);

export const HYPERWALLET_IBAN_FIELD = 'bankAccountId';

export const HYPERWALLET_ALLOWED_IBAN_COUNTRIES = [
  { value: 'AT', label: 'Austria' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czech Republic' },
  { value: 'HR', label: 'Croatia' },
  { value: 'DK', label: 'Denmark' },
  { value: 'EE', label: 'Estonia' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'DE', label: 'Germany' },
  { value: 'GR', label: 'Greece' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IT', label: 'Italy' },
  { value: 'LV', label: 'Latvia' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'MT', label: 'Malta' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'NO', label: 'Norway' },
  { value: 'PL', label: 'Poland' },
  { value: 'PT', label: 'Portugal' },
  { value: 'RO', label: 'Romania' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SK', label: 'Slovakia' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'ES', label: 'Spain' },
  { value: 'SE', label: 'Sweden' },
  { value: 'TH', label: 'Thailand' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'GB', label: 'United Kingdom of Great Britain and Northern Ireland' },
];

export const PAYID_TYPES = {
  EMAIL: 'Email',
  PHONE_NUMBER: 'PhoneNumber',
};

export const PAYID_WITHDRAWAL_TYPES_VALUES = [
  { value: PAYID_TYPES.EMAIL, label: 'Email' },
  { value: PAYID_TYPES.PHONE_NUMBER, label: 'Phone Number' },
];

export const FORBIDDEN_CURRENCY_CLOUD_WITHDRAWAL_FOREIGN_COUNTRIES = [
  // Need to provide "purpose_code" to make a payment. See https://support.currencycloud.com/hc/en-gb/articles/360017430000-Payment-Purpose-Codes
  'Bahrain',
  'Belarus',
  'Cuba',
  'Kazakhstan',
  'Libya',
  'Myanmar',
  'Sudan',
  'Syrian Arab Republic',
  // Need to provide "purpose_code" to make a payment. See https://support.currencycloud.com/hc/en-gb/articles/360017430000-Payment-Purpose-Codes
  'United Arab Emirates',
  // Need to implement:
  // 1. "Verify Beneficiary" functionality to make payment (See https://developer.currencycloud.com/guides/integration-guides/verifying-beneficiary-account/)
  // 2. There are 2 different options for "Regular" UK payment in GBP: we need to select only one (This API: https://developer.currencycloud.com/api-reference/#get-beneficiary-requirements)
  'United Kingdom of Great Britain and Northern Ireland',
  'Venezuela (Bolivarian Republic of)',
];

// We don't allow any payments to/from blocked countries but this is exceptions list for Currency Cloud withdrawals
export const BLOCKED_COUNTRIES_ALLOWED_CURRENCY_CLOUD = ['United States of America'];

// We need to hide Perfect Moeny PSP from AU and EN clients as it's not supported for them until Fusion Markets agrees about it
export const PERFECT_MONEY_ALLOWED_COUNTRIES = new Set([]);
