import { createSelector } from 'reselect';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

import { USER_ROLE } from 'constants/userRoles';
import { VerificationStatuses } from 'constants/verificationStatuses';
import { LOCATION_FIELDS } from 'modules/user/constants';
import { getFormattedCountries } from 'modules/geolocation/selectors';
import { getQuizProgressAccessKey } from '../../VerificationModalsManager/utils/getQuizProgressAccessKey';
import {
  AUSTRALIA,
  SEA_COUNTRIES,
  THAILAND,
  SEYCHELLES,
  CURRENCIES_BY_HUB,
  FUSION_MARKETS,
  LEVERAGES,
  FUSION_MARKETS_X,
  FUSION_MARKETS_EU,
  MANAGED_COUNTRIES,
  TIME_ZONES,
  TRADING_VIEW_PLATFORM,
  CTRADER_PLATFORM,
  ACCOUNT_TYPES,
  EU_CURRENCIES_COUNTRIES,
  TRADING_SERVERS_FOR_STAFF_MAPPER,
  ALLOW_EU_CURRENCIES_FOR_EMAILS,
} from 'constants/index';
import getDocuments from 'helpers/documents';
import { getCurrencyByCountry as getCurrencyByCountryUtil } from 'utils/getCurrencyByCountry';
import { TRADING_PLATFORMS } from 'constants/index';
import config from '../../../config';
import { ACCOUNTS_LIMIT, DEMO_ACCOUNTS_LIMIT } from 'modules/accounts/pages/MyAccounts/constants';
import { getMt4LiveCountries } from 'modules/mt4LiveCountries/selectors';
import { ACCOUNT_PLANS } from '../../../constants/accountPlans';
import { UPLOAD_EVIDENCE_STATUSES } from 'modules/evidenceUploadPortal/constants';
import isStateRestricted from 'utils/isStateRestricted';
import { getHub } from 'components/App/selectors';
import { getAccountTypes } from 'helpers/index';
import { getUser as getSelectedClient } from '../../user/selectors';
import * as hubs from '../helpers/hubs';
import { getMarketingSiteUrl } from 'helpers/getMarketingSiteUrl';
import { userStateForHyperwalletValid } from 'modules/payments/pages/Withdraw2/helpers/userStateForHyperWalletValid';
import { userAddressLineForHyperwalletValid } from 'modules/payments/pages/Withdraw2/helpers/userAddressLineForHyperWalletValid';
import { REDUCE_FEES_FORBIDDEN_COUNTRIES } from '../../reduceFees/constants';

const ALLOW_ZEPTO = new Set(get(config, 'ZEPTO.ALLOW_ZEPTO'));
const ALLOW_IM = new Set(get(config, 'TWILIO.ALLOW_IM'));
const defaultUser = {};

export const getIsAuthenticated = (state) => get(state, 'auth.isAuthenticated');
export const getToken = (state) => state.auth.token;
export const getRefreshToken = (state) => state.auth.refreshToken;
export const getUser = (state) => state.auth.user || defaultUser;
export const getIsImpersonation = (state) => get(state, 'auth.isImpersonation', false);

export const accountPlanSelector = createSelector(getUser, (user) => user.selectedPlan);

export const getUserProStatus2 = createSelector(getUser, (user) => get(user, 'isPro', false));
export const targetUserQuizSelector = createSelector(getUser, (user) => {
  const { selectedPlan, ...userData } = user;

  const quizProgressAccessKey = getQuizProgressAccessKey(selectedPlan);

  if (!quizProgressAccessKey) return null;

  return userData[quizProgressAccessKey];
});

export const getIsRetailQuizPassed = createSelector(getUser, (user) => {
  return get(user, 'retailQuizProgress.isPassed', false);
});
export const getIsProSigned = createSelector(getUser, (user) => !!user.proSign);

export const getIsProQuizPassed = createSelector(getUser, (user) => {
  return get(user, 'proQuizProgress.isPassed', false);
});

export const getUserProStatus = createSelector(getUser, (user) => get(user, 'isPro', false));
export const getIsUserWholeSale = createSelector(getUser, (user) => get(user, 'wholesale', false));
export const getIsFirstVisit = (state) => state.auth.isFirstVisit;

export const getUserEvidenceStatementStatus = createSelector(getUser, (user) =>
  get(user, 'tradingStatementEvidene.status', UPLOAD_EVIDENCE_STATUSES.INITIAL),
);

export const getUserId = createSelector(getUser, (user) => user._id);

export const getUserPaymentSettings = createSelector(getUser, (user) => user.payments);

export const getUserStatus = createSelector(getUser, (user) =>
  get(user, 'verification.status', ''),
);

export const getUserTMDData = createSelector(getUser, (user) =>
  get(user, 'verification.TMDAccepted', null),
);

export const getUserHub = createSelector(getToken, (token) => {
  if (!token) {
    return;
  }
  try {
    const { hub } = JSON.parse(atob(token.split('.')[1]));
    return hub;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
});

export const getUserLanguage = createSelector(getUserHub, (hub) => {
  if (hubs.isAuHub(hub)) {
    return '/au';
  }

  if (hubs.isEnHub(hub)) {
    return '/en';
  }

  if (hubs.isEuHub(hub)) {
    return '/eu';
  }
});

export const isAuHub = createSelector(getUserHub, (hub) => {
  return hubs.isAuHub(hub);
});

export const isEnHub = createSelector(getUserHub, (hub) => {
  return hubs.isEnHub(hub);
});

export const getMailchimpUser = (state) => state.profile.profile.profileData;
export const getMailchimpUserInterests = (state) => state.profile.profile.profileData.interests;

export const isBannedCountry = createSelector(getUser, getFormattedCountries, (user, countries) => {
  if (!user || isEmpty(user) || !user?.profile || !countries || isEmpty(countries)) {
    // checking for view before initialization
    // while user profile or country list not uploaded user should not see any messages about blocking
    return false;
  }

  if (user?.ECPUser) {
    return false;
  }

  const { profile } = user;
  const userCountry = countries?.find(({ name }) => name === profile.country);
  return userCountry?.bannedCountry || isStateRestricted(profile, countries);
});

export const getUserCountry = createSelector(getUser, (user) => get(user, 'profile.country', ''));

export const getUserEmail = createSelector(getUser, (user) => user.email);

export const getUserCurrenciesByCountry = createSelector(
  getUserHub,
  getUserCountry,
  getUserEmail,
  (hub, userCountry, userEmail) => {
    if (hub) {
      if (
        hub === FUSION_MARKETS_X &&
        (EU_CURRENCIES_COUNTRIES.includes(userCountry) ||
          ALLOW_EU_CURRENCIES_FOR_EMAILS.has(userEmail))
      ) {
        return CURRENCIES_BY_HUB.FUSION_MARKETS_X_EU;
      }
      return CURRENCIES_BY_HUB[hub];
    } else {
      return [];
    }
  },
);

export const getUserCurrencies = createSelector(getUserHub, (hub) => {
  if (hub) {
    return CURRENCIES_BY_HUB[hub];
  } else {
    return [];
  }
});

export const getUserDocuments = createSelector(getUserHub, getHub, (userHub, unauthorizedHub) =>
  getDocuments(userHub, unauthorizedHub),
);

export const getUserIsVerified = createSelector(
  getUserStatus,
  (status) =>
    status === VerificationStatuses.APPROVED ||
    status === VerificationStatuses.ACTIVE ||
    status === VerificationStatuses.DORMANT ||
    status === VerificationStatuses.FUNDED,
);

export const getUserIsPosted = createSelector(
  getUserStatus,
  (status) =>
    status === VerificationStatuses.POSTED || status === VerificationStatuses.AUTOMATIC_APPROVEMENT,
);

export const getUserIsRejected = createSelector(
  getUserStatus,
  (status) => status === VerificationStatuses.REJECTED,
);

export const getUserIsLead2 = createSelector(
  getUserStatus,
  (status) => status === VerificationStatuses.EDITING.LEAD2,
);

export const getUserIsLead3 = createSelector(
  getUserStatus,
  (status) => status === VerificationStatuses.EDITING.LEAD3,
);

export const getUserIsLead4 = createSelector(
  getUserStatus,
  (status) => status === VerificationStatuses.EDITING.LEAD4,
);

export const getUserIsNew = createSelector(
  getUserStatus,
  (status) => status === VerificationStatuses.NEW,
);

export const getIsSalesTeamMember = createSelector(
  getUserEmail,
  (email) => config.SALES_TEAM_USERS && config.SALES_TEAM_USERS.has(email),
);

export const getIsSalesReportAllowed = createSelector(
  getUserEmail,
  (email) => config.SALES_REPORTS_USERS && config.SALES_REPORTS_USERS.has(email),
);

export const getUserVerificationProgress = createSelector(
  getUser,
  (user) => get(user, 'verification.activeStep', 0) * 25,
);

export const getUserName = createSelector(getUser, (user) => get(user, 'profile.firstName', ''));
export const getUserLastName = createSelector(getUser, (user) => get(user, 'profile.lastName', ''));
export const getUserAllowedAccountCreation = createSelector(getUser, (user) =>
  get(user, 'allowAccountCreation', true),
);

export const getUserFullName = createSelector(getUser, (user) => {
  const firstName = get(user, 'profile.firstName', undefined);
  const lastName = get(user, 'profile.lastName', undefined);
  return firstName && lastName && `${firstName} ${lastName}`;
});

export const getUserPhoneInfo = createSelector(
  getUser,
  getFormattedCountries,
  (user, countries) => {
    const phoneCountry = get(user, 'profile.phoneCountry', undefined);
    const phoneCountryInfo = countries.find((country) => country.name === phoneCountry);
    return (
      phoneCountry &&
      phoneCountryInfo && {
        phoneNumber: get(user, 'profile.phoneNumber', ''),
        phoneCountry: phoneCountryInfo?.value || phoneCountryInfo?.name || '',
      }
    );
  },
);

export const getUserVerificationStatus = createSelector(getUser, (user) =>
  get(user, 'verification.status', ''),
);

export const getUserRole = createSelector(getUser, (user) => user.role);

export const getUserQuizProgress = createSelector(getUser, (user) => ({
  retailQuizProgress: user.retailQuizProgress,
  proQuizProgress: user.proQuizProgress,
}));

export const getUserPassedQuiz = createSelector(
  getUserQuizProgress,
  ({ retailQuizProgress, proQuizProgress }) =>
    get(retailQuizProgress, 'isPassed', false) || get(proQuizProgress, 'isPassed', false),
);

export const getIsUserQuizRequired = createSelector(
  getUserLanguage,
  (userLanguage) => userLanguage === '/au',
);

export const getIsAdminRole = createSelector(getUserRole, (role) => role === USER_ROLE.ADMIN);

export const getIsManagerRole = createSelector(getUserRole, (role) => role === USER_ROLE.MANAGER);

export const getIsClientRole = createSelector(getUserRole, (role) => role === USER_ROLE.CLIENT);

export const getIsInternalUser = createSelector(
  getIsAdminRole,
  getIsManagerRole,
  (isAdmin, isManager) => {
    return isAdmin || isManager;
  },
);

export const getIsCountryManager = createSelector(getUser, (user) => {
  const role = get(user, 'role', undefined);
  const managedCountries = get(user, 'managedCountries', []);

  return (
    role === USER_ROLE.MANAGER &&
    managedCountries.length === 1 &&
    MANAGED_COUNTRIES.includes(managedCountries[0])
  );
});

export const getIsCountryAdmin = createSelector(getUser, (user) => {
  const role = get(user, 'role', undefined);
  const managedCountries = get(user, 'managedCountries', []);

  return (
    role === USER_ROLE.ADMIN &&
    managedCountries.length === 1 &&
    MANAGED_COUNTRIES.includes(managedCountries[0])
  );
});

export const getIsGlobalAdmin = createSelector(getUser, (user) => {
  const role = get(user, 'role', undefined);
  const managedCountries = get(user, 'managedCountries', []);

  return role === USER_ROLE.ADMIN && managedCountries.length === 0;
});

export const getManagedCountries = createSelector(getUser, (user) => {
  return get(user, 'managedCountries', []);
});

export const getUserProfile = createSelector(getUser, (user) => {
  return user && user.profile ? user.profile : {};
});

export const getUserSignature = createSelector(getUserProfile, ({ signature = null }) => signature);

export const getUserPermissions = createSelector(getUser, (user) => {
  return user && user.permissions ? user.permissions : {};
});

export const getUserLocation = createSelector(getUserProfile, (profile) => {
  let location = '';
  location = LOCATION_FIELDS.reduce((prevField, curField, index) => {
    if (index === 0) {
      return prevField + `${profile[curField]}`;
    }
    return profile[curField] ? prevField + `, ${profile[curField]}` : prevField;
  }, location);
  return location;
});

export const isAustralian = (state) =>
  isEqual(get(state, 'auth.user.profile.country', ''), AUSTRALIA);

export const isSEA = (state) =>
  includes(SEA_COUNTRIES, get(state, 'auth.user.profile.country', ''));

export const isFirstLoginSinceRejection = createSelector(getUser, (user) =>
  get(user, 'verification.firstLoginSinceRejection', false),
);

export const getWasRejected = createSelector(getUser, (user) =>
  get(user, 'verification.wasRejected', false),
);
export const isThai = (state) => isEqual(get(state, 'auth.user.profile.country', ''), THAILAND);
export const isSeychelles = (state) =>
  isEqual(get(state, 'auth.user.profile.country', ''), SEYCHELLES);

export const getMfaSettings = createSelector(getUser, (user) => {
  return user && user.mfa ? user.mfa : {};
});

export const getCurrencyByCountry = (state) => {
  const hub = getUserHub(state);
  const country = getUserCountry(state);

  return getCurrencyByCountryUtil(hub, country);
};

export const swapFreeUser = createSelector(getUser, (user) => user.swapFreeUser || false);

const getUserPlatforms = (platforms, user) => {
  return platforms.filter((platform) => {
    return user?.swapFreeUser
      ? !(platform.value === TRADING_VIEW_PLATFORM || platform.value === CTRADER_PLATFORM)
      : true;
  });
};

const getInitialTradingPlatforms = (isStaff, isLive, hub = FUSION_MARKETS_X) => {
  if (isStaff) {
    const servers = TRADING_SERVERS_FOR_STAFF_MAPPER[hub];

    return isLive ? servers.LIVE : servers.DEMO;
  } else {
    return TRADING_PLATFORMS;
  }
};

export const getCurrentClient = createSelector(
  getIsInternalUser,
  getUser,
  getSelectedClient,
  (isStaff, user, selectedClient) => {
    return isStaff ? selectedClient : user;
  },
);

const getClient = (_, client) => client;

export const getUserLiveTradingPlatforms = createSelector(
  getIsInternalUser,
  getUserHub,
  getClient,
  (isStaff, hub, client) => {
    const platforms = getInitialTradingPlatforms(isStaff, true, hub);

    return getUserPlatforms(platforms, client);
  },
);

export const getUserDemoTradingPlatforms = createSelector(
  getIsInternalUser,
  getUserHub,
  (isStaff, hub) => {
    const platforms = getInitialTradingPlatforms(isStaff, false, hub);

    return platforms;
  },
);

export const getUserAccountTypes = createSelector(getUserHub, (hub) => {
  let accountTypes = getAccountTypes();

  //only allow swap free accounts/users within FM-EN (FDS-1723)
  if (hub !== FUSION_MARKETS_X) {
    accountTypes = accountTypes.filter(({ value }) => value !== ACCOUNT_TYPES.SWAP_FREE);
  }
  if (hub === FUSION_MARKETS_EU) {
    return accountTypes.filter(({ value }) => value === ACCOUNT_TYPES.CLASSIC);
  }
  return accountTypes || [];
});

export const getUserLiveAccountTypes = createSelector(
  getUserAccountTypes,
  getClient,
  (accountTypes, client) => {
    if (!client) {
      return accountTypes;
    }

    const swapFreeUser = get(client, 'swapFreeUser', false);

    return accountTypes.filter((type) => {
      return swapFreeUser
        ? type.value === ACCOUNT_TYPES.SWAP_FREE
        : type.value !== ACCOUNT_TYPES.SWAP_FREE;
    });
  },
);

export const getUserDemoAccountTypes = createSelector(getUserAccountTypes, (accountTypes) => {
  accountTypes = accountTypes.filter((type) => {
    return type.value !== ACCOUNT_TYPES.SWAP_FREE;
  });

  return accountTypes;
});

export const getIsUserProStatusRequested = createSelector(
  getUser,
  (user) => get(user, 'proSign', null) !== null,
);

export const getIsAllowedUpgradeToPro = createSelector(
  getUser,
  getUserHub,
  getUserCountry,
  getIsRetailQuizPassed,
  getIsProQuizPassed,
  getIsProSigned,
  (user, hub, country, isRetailQuizPassed, isProQuizPassed, isProSigned) => {
    const isAu = hub === FUSION_MARKETS;
    const isUserCountryWhitelisted = config.UPGRADE_TO_PRO_COUNTRIES.includes(country);
    const isUpgradeHidden = !(isAu && isUserCountryWhitelisted);

    const isProUser = user.isPro;
    const isWholesaleUser = user.wholesale;

    if (isUpgradeHidden || isProUser || isWholesaleUser) {
      return false;
    }

    const isProQuizPassedWithoutSign = isProQuizPassed && !isProSigned;
    const isProQuizPassedAndSignedWithoutApprovedEvidences =
      isProQuizPassed &&
      isProSigned &&
      user?.tradingStatementEvidence?.status !== UPLOAD_EVIDENCE_STATUSES.APPROVED;
    const isRetailQuizPassedAndProQuizNot = isRetailQuizPassed && !isProQuizPassed;

    return (
      isProQuizPassedWithoutSign ||
      isProQuizPassedAndSignedWithoutApprovedEvidences ||
      isRetailQuizPassedAndProQuizNot
    );
  },
);
export const isProSelected = createSelector(accountPlanSelector, (accountPlan) => {
  return accountPlan === ACCOUNT_PLANS.PRO;
});

export const showVerificationStepLeverage = createSelector(
  getUser,
  getUserHub,
  isProSelected,
  (user, hub, isProSelectedValue) => {
    const isLeverageRestricted = get(user, 'isLeverageRestricted', false);
    const isPro = get(user, 'isPro', false);
    const isProQuizPassed = get(user, 'proQuizProgress.isPassed', false);
    const isWholesale = get(user, 'wholesale', false);
    return (
      (hub === FUSION_MARKETS_X ||
        hub === FUSION_MARKETS_EU ||
        isPro ||
        isWholesale ||
        (isProSelectedValue && isProQuizPassed)) &&
      !isLeverageRestricted
    );
  },
);

export const isProUser = createSelector(getUser, getUserHub, (user, hub) => {
  const isLeverageRestricted = get(user, 'isLeverageRestricted', false);
  const isPro = get(user, 'isPro', false);
  const isWholesale = get(user, 'wholesale', false);
  return (
    (hub === FUSION_MARKETS_X || hub === FUSION_MARKETS_EU || isPro || isWholesale) &&
    !isLeverageRestricted
  );
});

export const isRetailUser = createSelector(isProUser, (proUser) => {
  return !proUser;
});

export const showLeverage = createSelector(isProUser, (proUser) => {
  return proUser;
});

export const defaultLeverage = createSelector(showLeverage, (showLeverage) => {
  if (showLeverage) {
    return LEVERAGES[0].value;
  }
  return LEVERAGES[4].value;
});

export const defaultPlatform = createSelector(getUser, (user) => {
  let platfrom = get(user, 'accountSettings.platform');

  return platfrom || TRADING_PLATFORMS[0].value;
});

export const getEmailNotificationsSettings = createSelector(getUser, (user) => {
  return get(user, 'emailNotificationsSettings', []);
});

export const showMigrateMyAccount = createSelector(getUserHub, getUserCountry, (hub, country) => {
  if (hub === FUSION_MARKETS) {
    return config.MIGRATE_ACCOUNT_COUNTRIES_HIDE.indexOf(country) < 0;
  }
  return config.MIGRATE_ACCOUNT_COUNTRIES.indexOf(country) > 0;
});

export const getUserFollowerId = createSelector(getUser, (user) => user.inviteFollowerId);

export const getIsFusionPlus = createSelector(getUser, (user) => !!get(user, 'fusionPlus.isJoin'));

export const getFusionPlusSignedAt = createSelector(getUser, (user) =>
  get(user, 'fusionPlus.createdAt'),
);

export const allowCopyAccount = createSelector(getUserHub, getUser, (hub, user) => {
  if (hub === FUSION_MARKETS_X || hub === FUSION_MARKETS_EU) {
    return true;
  }

  return get(user, 'allowCopyAccount', false);
});

export const getAllowReduceFees = createSelector(getUserHub, getUserCountry, (hub, userCountry) => {
  if (hub === FUSION_MARKETS) {
    return false;
  }

  return !REDUCE_FEES_FORBIDDEN_COUNTRIES.includes(userCountry);
});

export const getProStep = createSelector(getUser, (user) => get(user, 'proStep'));

export const isUpgradeQuiz = createSelector(getUser, (user) => get(user, 'isUpgradeToPro'));

export const getHaveAwtAccounts = createSelector(getUser, (user) =>
  get(user, 'haveAwtAccounts', false),
);

export const isEuropeanCountry = createSelector(
  getUser,
  getFormattedCountries,
  (user, countries) => {
    const profileCountry = get(user, 'profile.country', undefined);
    const countryInfo = countries.find((country) => country.name === profileCountry);
    return get(countryInfo, 'region') === 'Europe';
  },
);

export const getUserAccountsCountLimit = createSelector(getUser, (user) => {
  return get(user, 'allowUnlimitedAccounts', false) ? ACCOUNTS_LIMIT.VIP : ACCOUNTS_LIMIT.PLAIN;
});
export const getUserDemoAccountsCountLimit = createSelector(getUser, (user) => {
  return get(user, 'allowUnlimitedDemoAccounts', false)
    ? DEMO_ACCOUNTS_LIMIT.VIP
    : DEMO_ACCOUNTS_LIMIT.PLAIN;
});

export const getUserEmailAndFullName = createSelector(getUser, (user) => {
  return {
    fullName: `${get(user, 'profile.firstName', '')} ${get(user, 'profile.lastName', '')}`,
    email: get(user, 'email', undefined),
  };
});

// export const showDepositAu = createSelector(getUserEmail, (email) => {
//   return ALLOW_ZEPTO.has(email);
// });

export const showDepositAu = createSelector(
  getUserHub,
  getUserEmail,
  isAustralian,
  (hub, email, isAustralian) => {
    if (hub === FUSION_MARKETS_X || hub === FUSION_MARKETS_EU) {
      return ALLOW_ZEPTO.has(email);
    }

    return !(hub === FUSION_MARKETS_X || hub === FUSION_MARKETS_EU || !isAustralian);
  },
);

export const showDepositMonoova = createSelector(
  getUserHub,
  isAustralian,
  (hub, isUserAustralian) => {
    return hub === 'FUSION_MARKETS' && isUserAustralian;
  },
);

export const showIM = createSelector(getUserEmail, (email) => {
  return ALLOW_IM.has(email);
});

export const canManagerAddDeposit = createSelector(getUser, (user) => user.canAddDeposit);

export const allowToSetLeads = createSelector(getUser, (user) => user.allowToSetLeads);

export const getUserState = createSelector(getUser, (user) => get(user, 'profile.state', ''));

export const getIsUserStateForHyperwalletValid = createSelector(
  getUserState,
  getUserCountry,
  (userState, userCountry) => {
    return userStateForHyperwalletValid(userCountry, userState);
  },
);

export const getUserAddressLine1 = createSelector(getUser, (user) =>
  get(user, 'profile.addressLine1', ''),
);

export const getIsUserAddressLineValid = createSelector(getUser, (user) => {
  const address = get(user, 'profile.addressLine1', '');
  return !/\b(?:p\.?\s*o\.?|post\s+office)(\s+)?(?:box|[0-9]*)?\b/i.test(address);
});

export const getIsUserAddressLineForHyperwalletValid = createSelector(
  getUserAddressLine1,
  (userAddress) => {
    return userAddressLineForHyperwalletValid(userAddress);
  },
);

export const isInMT4CountriesList = createSelector(
  getMt4LiveCountries,
  getFormattedCountries,
  getUserCountry,
  (mt4LiveCountries, formattedCountries, userCountry) => {
    const countryInfo = formattedCountries.find((country) => country.name === userCountry);
    const countryCode = get(countryInfo, 'alpha2Code');

    return mt4LiveCountries.some((country) => country.countryCode === countryCode);
  },
);

export const getIsTradingDisabled = createSelector(getUser, (user) => {
  return user?.tradingStatus?.disabled;
});

export const canUserCreateAccounts = createSelector(
  isBannedCountry,
  getUserAllowedAccountCreation,
  (isBannedCountry, isAllowUserAccountCreation) => {
    return !isBannedCountry && isAllowUserAccountCreation;
  },
);

export const privateAppleEmail = createSelector(getUser, (user) => {
  return {
    relayEmail: get(user, 'isPrivateRelayEmail', null) === true,
    additionalEmail: get(user, 'additionalEmail', null),
  };
});

export const getCanAddSecurityQuestion = createSelector(getIsAdminRole, (isAdmin) => {
  return isAdmin;
});

export const getTimeZoneByHub = createSelector(getUserHub, (hub) => {
  return hub === FUSION_MARKETS_EU ? TIME_ZONES.EET : TIME_ZONES.AEST;
});

export const getMarketingSiteByUserHub = createSelector(getUserHub, (hub) =>
  getMarketingSiteUrl(hub),
);
