import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PropTypes from 'prop-types';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';

import TPButton from 'components/TP-UI/TPButton';
import TPGrid from 'components/TP-UI/TPGrid';
import TPTypography from 'components/TP-UI/TPTypography';
import { ACCOUNT_PLANS } from 'constants/accountPlans';

const BenefitsSection = (props) => {
  const { accountPlan, benefits, buttonText, onAccountPlanSelect, title } = props;

  return (
    <TPGrid container item key={title} spacing={3}>
      <TPGrid item xs={12}>
        <TPTypography variant="h5">{title}</TPTypography>
      </TPGrid>
      <TPGrid item xs={12}>
        {Array.isArray(benefits) &&
          benefits.map((item, index) => (
            <TPGrid container key={String(index)} spacing={2} wrap="nowrap">
              <TPGrid item>
                <CheckCircleOutlineIcon fontSize="small" />
              </TPGrid>
              <TPGrid item>
                <TPTypography>{item}</TPTypography>
              </TPGrid>
            </TPGrid>
          ))}
      </TPGrid>
      <TPGrid container item justifyContent="flex-end" xs={12}>
        <TPGrid item>
          <TPButton
            fullWidth={isMobileOnly}
            onClick={() => onAccountPlanSelect(accountPlan)}
            primary
            size="large">
            {buttonText}
          </TPButton>
        </TPGrid>
      </TPGrid>
    </TPGrid>
  );
};

BenefitsSection.propTypes = {
  accountPlan: PropTypes.oneOf([ACCOUNT_PLANS.PRO, ACCOUNT_PLANS.RETAIL]),
  benefits: PropTypes.arrayOf(PropTypes.string),
  buttonText: PropTypes.string,
  onAccountPlanSelect: PropTypes.func,
  title: PropTypes.string,
};

export default BenefitsSection;
