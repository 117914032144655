const styles = () => ({
  itemContainer: {
    border: '1px solid #EFF2F7',
    borderTopWidth: '0px',
    backgroundColor: '#FFFFFF',
    width: '100%',
    height: '100%',
  },
  fieldsContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    textAlign: 'left',
  },
});

export default styles;
