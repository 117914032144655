const styles = () => ({
  smallIcon: {
    fontSize: '14px',
  },

  iconBtn: {
    padding: '0px',
  },
});

export default styles;
