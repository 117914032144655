import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BottomNavigation from '../components/BottomNavigation';
import { getMenuItems } from '../selectors';

const BottomNavigationContainer = ({ location, drawerOpen, onDrawerOpen, onDrawerClose }) => {
  const menuItemsList = useSelector(getMenuItems);
  const mainMenuItems = useMemo(() => {
    return menuItemsList.filter((item) => item.mainMenuItem);
  }, [menuItemsList]);

  const selectedItem = useMemo(() => {
    const index = mainMenuItems.findIndex((item) => {
      return (
        (item.path && location.pathname.includes(item.path)) ||
        (item.items && item.items.some((i) => location.pathname.includes(i.path)))
      );
    });
    const lastMenuIndex = mainMenuItems.length;
    return index >= 0 ? index : lastMenuIndex;
  }, [location.pathname, mainMenuItems]);

  return (
    <BottomNavigation
      value={selectedItem}
      items={mainMenuItems}
      selectedRoute={location.pathname}
      drawerOpen={drawerOpen}
      onDrawerOpen={onDrawerOpen}
      onDrawerClose={onDrawerClose}
    />
  );
};

BottomNavigationContainer.propTypes = {
  location: PropTypes.object.isRequired,
  drawerOpen: PropTypes.bool,
  onDrawerOpen: PropTypes.func,
  onDrawerClose: PropTypes.func,
};

export default withRouter(BottomNavigationContainer);
