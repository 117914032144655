import { NOTIFICATION_CATEGORY } from 'constants/userNotifications';

export const MAX_DISPLAYED_ITEMS_NUMBER = 30;

export const NOTIFICATION_TYPES = {
  WHATS_NEW: 'WHATS_NEW',
  NOTIFICATION: 'NOTIFICATION',
};

export const CREATE_NOTIFICATION_MODAL = 'CREATE_NOTIFICATION_MODAL';

export const CREATE_NOTIFICATION_FORM = 'CREATE_NOTIFICATION_FORM';

export const UPDATE_NOTIFICATION_MODAL = 'UPDATE_NOTIFICATION_MODAL';

export const UPDATE_NOTIFICATION_FORM = 'UPDATE_NOTIFICATION_FORM';

export const NOTIFICATION_TYPE = [
  {
    label: 'Notification',
    value: 'NOTIFICATION',
  },
  {
    label: "What's new",
    value: 'WHATS_NEW',
  },
];

export const MODE = {
  ADD: 'ADD',
  EDIT: 'EDIT',
};

export const ROUTES_MAPPER = {
  [NOTIFICATION_TYPES.NOTIFICATION]: 'notifications',
  [NOTIFICATION_TYPES.WHATS_NEW]: 'whats-new',
};

export const NOTIFICATION_CATEGORY_LABEL = {
  [NOTIFICATION_CATEGORY.MARKET_HOURS_UPDATES]: 'Market Hours Updates',
  [NOTIFICATION_CATEGORY.NEW_FEATURES_IN_THE_HUB]: 'New Features in the hub',
  [NOTIFICATION_CATEGORY.NEW_PAYMENT_OPTIONS]: 'New Payment Options',
  [NOTIFICATION_CATEGORY.PROMOTIONS]: 'Promotions',
  [NOTIFICATION_CATEGORY.TRADE_IDEAS]: 'Trade Ideas',
  [NOTIFICATION_CATEGORY.COMPANY_NEWS]: 'Company News',
  [NOTIFICATION_CATEGORY.OPERATIONAL_UPDATES]: 'Operational Updates',
};

export const NOTIFICATION_CATEGORIES = [
  {
    value: NOTIFICATION_CATEGORY.MARKET_HOURS_UPDATES,
    label: NOTIFICATION_CATEGORY_LABEL[NOTIFICATION_CATEGORY.MARKET_HOURS_UPDATES],
  },
  {
    value: NOTIFICATION_CATEGORY.NEW_FEATURES_IN_THE_HUB,
    label: NOTIFICATION_CATEGORY_LABEL[NOTIFICATION_CATEGORY.NEW_FEATURES_IN_THE_HUB],
  },
  {
    value: NOTIFICATION_CATEGORY.NEW_PAYMENT_OPTIONS,
    label: NOTIFICATION_CATEGORY_LABEL[NOTIFICATION_CATEGORY.NEW_PAYMENT_OPTIONS],
  },
  {
    value: NOTIFICATION_CATEGORY.PROMOTIONS,
    label: NOTIFICATION_CATEGORY_LABEL[NOTIFICATION_CATEGORY.PROMOTIONS],
  },
  {
    value: NOTIFICATION_CATEGORY.TRADE_IDEAS,
    label: NOTIFICATION_CATEGORY_LABEL[NOTIFICATION_CATEGORY.TRADE_IDEAS],
  },
  {
    value: NOTIFICATION_CATEGORY.COMPANY_NEWS,
    label: NOTIFICATION_CATEGORY_LABEL[NOTIFICATION_CATEGORY.COMPANY_NEWS],
  },
  {
    value: NOTIFICATION_CATEGORY.OPERATIONAL_UPDATES,
    label: NOTIFICATION_CATEGORY_LABEL[NOTIFICATION_CATEGORY.OPERATIONAL_UPDATES],
  },
];

export const MAX_PHOTO_FILES_NUMBER = 4;
