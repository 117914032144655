import { createAction } from 'redux-actions';

export const getIMMessagesRequest = createAction('GET_I_M_MESSAGES_REQUEST');
export const getIMMessagesSuccess = createAction('GET_I_M_MESSAGES_SUCCESS');
export const getIMMessagesFail = createAction('GET_I_M_MESSAGES_FAIL');

export const getIMMessagesNewRequest = createAction('GET_I_M_MESSAGES_NEW_REQUEST');
export const getIMMessagesNewSuccess = createAction('GET_I_M_MESSAGES_NEW_SUCCESS');
export const getIMMessagesNewFail = createAction('GET_I_M_MESSAGES_NEW_FAIL');

export const getIMMessagesPollingRequest = createAction('GET_I_M_MESSAGES_POLLING_REQUEST');
export const getIMMessagesPollingSuccess = createAction('GET_I_M_MESSAGES_POLLING_SUCCESS');
export const getIMMessagesPollingFail = createAction('GET_I_M_MESSAGES_POLLING_FAIL');

export const getIMUserMessagesRequest = createAction('GET_I_M_USER_MESSAGES_REQUEST');
export const getIMUserMessagesSuccess = createAction('GET_I_M_USER_MESSAGES_SUCCESS');
export const getIMUserMessagesFail = createAction('GET_I_M_USER_MESSAGES_FAIL');

export const pinIMUserMessagesRequest = createAction('PIN_I_M_USER_MESSAGES_REQUEST');
export const pinIMUserMessagesSuccess = createAction('PIN_I_M_USER_MESSAGES_SUCCESS');
export const pinIMUserMessagesFail = createAction('PIN_I_M_USER_MESSAGES_FAIL');

export const getIMUserMessagesPollingRequest = createAction(
  'GET_I_M_USER_MESSAGES_POLLING_REQUEST',
);
export const getIMUserMessagesPollingSuccess = createAction(
  'GET_I_M_USER_MESSAGES_POLLING_SUCCESS',
);
export const getIMUserMessagesPollingFail = createAction('GET_I_M_USER_MESSAGES_POLLING_FAIL');

export const setIMUserMessagesRequest = createAction('SET_I_M_USER_MESSAGES_REQUEST');
export const setIMUserMessagesSuccess = createAction('SET_I_M_USER_MESSAGES_SUCCESS');
export const setIMUserMessagesFail = createAction('SET_I_M_USER_MESSAGES_FAIL');

export const newIMUserMessagesRequest = createAction('NEW_I_M_USER_MESSAGES_REQUEST');
export const newIMUserMessagesSuccess = createAction('NEW_I_M_USER_MESSAGES_SUCCESS');
export const newIMUserMessagesFail = createAction('NEW_I_M_USER_MESSAGES_FAIL');

export const setCurrentUser = createAction('SET_CURRENT_USER');

export const setLinkedUser = createAction('SET_LINKED_USER');

export const closeAddMessagePopup = createAction('CLOSE_ADD_I_M_MESSAGE_POPUP');
export const openAddMessagePopup = createAction('OPEN_ADD_I_M_MESSAGE_POPUP');

export const startPolling = createAction('I_M_MESSAGE_START_POLLING');
export const setPolling = createAction('I_M_MESSAGE_SET_POLLING');

export const sendIMUserHandshakeRequest = createAction('SEND_I_M_USER_HANDSHAKE_REQUEST');
export const sendIMUserHandshakeSuccess = createAction('SEND_I_M_USER_HANDSHAKE_SUCCESS');
export const sendIMUserHandshakeFail = createAction('SEND_I_M_USER_HANDSHAKE_FAIL');

//For New message module. In the current structure is a hard reuse of the existing sendIMUserHandshakeRequest action
export const sendNewIMUserHandshakeRequest = createAction('SEND_NEW_I_M_USER_HANDSHAKE_REQUEST');
export const sendNewIMUserHandshakeSuccess = createAction('SEND_NEW_I_M_USER_HANDSHAKE_SUCCESS');
export const sendNewIMUserHandshakeFail = createAction('SEND_NEW_I_M_USER_HANDSHAKE_FAIL');

export const getIMNewUserMessagesRequest = createAction('GET_I_M_NEW_USER_MESSAGES_REQUEST');
export const getIMNewUserMessagesSuccess = createAction('GET_I_M_NEW_USER_MESSAGES_SUCCESS');
export const getIMNewUserMessagesFail = createAction('GET_I_M_NEW_USER_MESSAGES_FAIL');
