import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';
import includes from 'lodash/includes';
import get from 'lodash/get';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Typography } from '@material-ui/core';
import { VerificationStatuses as VerificationStatus } from 'constants/verificationStatuses';
import { Status as PaymentStatus, DEPOSIT_DEFAULT_STATUS } from 'constants/paymentStatus';
import { TicketStatuses } from 'constants/ticketStatuses';
import { Status as vpsConstants } from 'modules/userManagement/pages/VPSUsers/constants';
import approve from 'static/images/old/approve.svg';
import pending from 'static/images/old/pending.svg';
import decline from 'static/images/old/decline.svg';
import dot from 'static/images/old/dot.svg';
import { InvitationStatuses } from 'modules/reduceFees/constants';
import Tooltip from 'modules/common/Tooltip';

const statusMapper = {
  posted: 'posted',
  approved: 'approved',
  rejected: 'rejected',
  funded: 'funded',
  active: 'active',
  dormant: 'dormant',
  sumsub: 'pending',
};

const TableStatusField = ({ classes, label, dataLabel, tableItem, tooltips = {} }) => {
  const { t } = useTranslation('common');
  let status = get(tableItem, dataLabel, '');
  if (status === DEPOSIT_DEFAULT_STATUS) {
    status = PaymentStatus.APPROVED;
  }
  const statusLabel =
    Object.values(PaymentStatus)
      .map((item) => item.toLowerCase())
      .indexOf(status.toLowerCase()) === -1
      ? status
      : t(`constants.statuses.${status}`);
  const tooltip = get(tooltips, status);

  return (
    <div className={classes.item}>
      <Typography className={classes.label}>{label}</Typography>
      <div className={classes.statusContainer}>
        {(status === VerificationStatus.APPROVED ||
          status === PaymentStatus.APPROVED ||
          status === PaymentStatus.TRUSTED ||
          status === VerificationStatus.FUNDED ||
          status === VerificationStatus.ACTIVE ||
          status === InvitationStatuses.ACCEPTED ||
          status === TicketStatuses.RESOLVED) && (
          <img className={classes.statusImage} src={approve} alt={status} />
        )}
        {(status === VerificationStatus.NEW ||
          includes(VerificationStatus.EDITING, status) ||
          status === VerificationStatus.AUTOMATIC_APPROVEMENT ||
          status === VerificationStatus.POSTED ||
          status === PaymentStatus.PENDING ||
          status === PaymentStatus.TOCONFIRM) && (
          <img className={classes.statusImage} src={pending} alt={status} />
        )}
        {(status === VerificationStatus.REJECTED ||
          status === PaymentStatus.DECLINED ||
          status === PaymentStatus.EXPIRED ||
          status === VerificationStatus.DORMANT ||
          status === 'Failed' ||
          status === vpsConstants.CANCELLED ||
          status === TicketStatuses.CLOSED) && (
          <img className={classes.statusImage} src={decline} alt={status} />
        )}
        {status === TicketStatuses.OPEN && (
          <img className={classes.dotImage} src={dot} alt={status} />
        )}
        {tooltip ? (
          <Tooltip label={statusLabel} classes={{ link: classes.dataText }} value={tooltip} />
        ) : (
          <Typography className={classes.dataText}>
            {capitalize(statusMapper[statusLabel] || statusLabel)}
          </Typography>
        )}
      </div>
    </div>
  );
};

TableStatusField.propTypes = {
  label: PropTypes.string.isRequired,
  dataLabel: PropTypes.string.isRequired,
  tableItem: PropTypes.object.isRequired,
};

export default withStyles(styles)(TableStatusField);
