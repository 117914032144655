import { handleActions } from 'redux-actions';
import get from 'lodash/get';

import {
  getTransferDetailsSuccess,
  getTransferDetailsFail,
  transferDetailsReset,
} from '../actions';

const defaultState = {
  fields: [],
};

export default handleActions(
  {
    [getTransferDetailsSuccess](state, action) {
      return {
        ...state,
        fields: get(action, 'response.data.fields', []),
      };
    },
    [getTransferDetailsFail](state) {
      return {
        ...state,
        fields: defaultState.fields,
      };
    },
    [transferDetailsReset](state) {
      return {
        ...state,
        fields: defaultState.fields,
      };
    },
  },
  defaultState,
);
