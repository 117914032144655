import { all, put, takeEvery } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import { push } from 'modules/reduxNavigation/actions';
import get from 'lodash/get';

import * as actionCreators from '../actions';
import { RESET_PASSWORD_FORM } from '../constants';
import * as notificationsActions from 'modules/notifications/actions';
import { Routes } from 'constants/routeConstants';
import { LOGOUT_REASON } from '../../../constants';

function* handleResetPasswordSuccess() {
  yield put(push(Routes.SIGNIN, { reason: LOGOUT_REASON.USER_ACTION }));
  yield put(
    notificationsActions.showNotificationInfo({
      key: 'auth:resetPassword.notifications.passwordChanged',
    }),
  );
}

function* handleResetPasswordFail(action) {
  yield put(
    stopSubmit(RESET_PASSWORD_FORM, {
      _error: get(action, 'error.data.message'),
    }),
  );
}

export default function* watchRequest() {
  yield all([
    takeEvery(actionCreators.resetPasswordSuccess, handleResetPasswordSuccess),
    takeEvery(actionCreators.resetPasswordFail, handleResetPasswordFail),
  ]);
}
