import React, { useCallback } from 'react';
import { closeModal } from 'components/TP-UI/TPModal/actions';
import { TMD_SUCCESS_MODAL } from '../../constants';
import { useDispatch } from 'react-redux';
import TMDSuccessModal from '../../components/TMDSuccessModal';
import useQuiz from 'hooks/useQuiz';

const TMDSuccessModalContainer = () => {
  const dispatch = useDispatch();
  const startQuiz = useQuiz();

  const handleContinue = useCallback(
    (e) => {
      dispatch(closeModal(TMD_SUCCESS_MODAL));
      startQuiz(e);
    },
    [dispatch, startQuiz],
  );

  const handleCancel = useCallback(() => {
    dispatch(closeModal(TMD_SUCCESS_MODAL));
  }, [dispatch]);

  return <TMDSuccessModal onContinue={handleContinue} onCancel={handleCancel} />;
};

export default TMDSuccessModalContainer;
