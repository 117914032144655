import { combineActions, handleActions } from 'redux-actions';
import * as actionCreators from '../actions';

const defaultState = {
  contacts: [],
  loading: false,
  error: null,
};

export default handleActions(
  {
    [actionCreators.getContactInfoRequest](state) {
      return {
        ...state,
        loading: true,
        error: null,
      };
    },
    [combineActions(actionCreators.getContactInfoSuccess)](state, action) {
      return {
        ...state,
        contacts: action.response.data.contactInfo,
        loading: false,
        error: null,
      };
    },
    [actionCreators.getContactInfoFail](state) {
      const errorMessageKey = 'backend:somethingWrongContactHelp';
      return {
        ...state,
        loading: false,
        error: {
          key: errorMessageKey,
        },
      };
    },
  },
  defaultState,
);
