import { useCallback, useEffect, useState } from 'react';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';

const useCurrentPageUrl = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const routeParams = useParams();
  const urlPageNumber = parseInt(routeParams?.pageNumber) || undefined;

  const [pageNumber, setPageNumber] = useState();

  const setPageNumberToUrl = useCallback(
    (value) => {
      if (value !== pageNumber) {
        history.push({
          pathname: generatePath(match.path, { pageNumber: value + 1 }),
        });
      }
    },
    [pageNumber, history, match],
  );

  useEffect(() => {
    if (urlPageNumber !== undefined && !isNaN(urlPageNumber)) {
      const newCurPage = urlPageNumber - 1;
      if (newCurPage >= 0) {
        setPageNumber(newCurPage);
      }
    }
  }, [urlPageNumber]);

  return [pageNumber, setPageNumberToUrl];
};

export default useCurrentPageUrl;
