import React, { useCallback } from 'react';

import { closeModal, openModal } from 'components/TP-UI/TPModal/actions';
import { PRE_QUIZ_MODAL } from 'modules/profileQuiz/constants';
import { accountPlanSelector } from 'modules/auth/selectors';
import { ACCOUNT_PLANS } from 'constants/accountPlans';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InfoTip from 'modules/common/InfoTip';
import { questionsSelector } from 'modules/profileQuiz/selectors';
import PreQuizModal from 'modules/profileQuiz/components/PreQuizModal';

const PreQuizModalContainer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const questions = useSelector(questionsSelector);
  const selectedPlan = useSelector(accountPlanSelector);
  const isProPlan = selectedPlan === ACCOUNT_PLANS.PRO;
  const titleTarget = isProPlan ? ACCOUNT_PLANS.PRO : ACCOUNT_PLANS.RETAIL;
  const title = t(`quiz:${titleTarget}.page.title`);
  const titleEndAdornment = (
    <InfoTip iconSize="medium" tooltipContent={t(`quiz:${titleTarget}.tooltip.description`)} />
  );

  const handleSubmit = useCallback(
    (modalName) => {
      dispatch(closeModal(PRE_QUIZ_MODAL));
      dispatch(openModal(modalName));
    },
    [dispatch],
  );

  const handleCancel = useCallback(() => {
    dispatch(closeModal(PRE_QUIZ_MODAL));
  }, [dispatch]);

  return (
    <PreQuizModal
      isProPlan={isProPlan}
      modalName={PRE_QUIZ_MODAL}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      title={title}
      titleEndAdornment={titleEndAdornment}
      questions={questions}
    />
  );
};

export default PreQuizModalContainer;
