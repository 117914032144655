import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { VARIANTS } from './config';
import classnames from 'classnames';

const TPButtonBase = ({
  primary,
  secondary,
  alternative,
  icon: Icon,
  textNoWrap,
  loading,
  disabled,
  size,
  fullWidth,
  className,
  children,
  forwardedRef,
  ...props
}) => {
  const classes = useStyles();

  const variant =
    (primary && VARIANTS.primary) ||
    (alternative && VARIANTS.alternative) ||
    (secondary && VARIANTS.secondary) ||
    {};

  const loaderSize = size && size === 'large' ? 24 : 18;

  return (
    <Button
      {...variant}
      disabled={disabled}
      size={size}
      fullWidth={fullWidth}
      disableTouchRipple
      ref={forwardedRef}
      className={classnames(className, [
        {
          [classes.loading]: loading,
          [classes.textNoWrap]: textNoWrap,
        },
      ])}
      {...props}>
      {Icon && (
        <Icon
          className={classnames({
            [classes.iconSmall]: size === 'small',
            [classes.iconDefault]: !size || size === 'medium',
            [classes.iconLarge]: size === 'large',
          })}
        />
      )}

      {children}
      {loading && (
        <CircularProgress
          size={loaderSize}
          className={classnames(classes.buttonProgress, {
            [classes.progressLargeSize]: size === 'large',
            [classes.progressPrimary]: primary,
            [classes.progressSecondary]: secondary,
            [classes.progressAlternative]: alternative,
          })}
        />
      )}
    </Button>
  );
};

TPButtonBase.propTypes = {
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  alternative: PropTypes.bool,
  icon: PropTypes.elementType,
  textNoWrap: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
};

const TPButton = React.forwardRef((props, ref) => <TPButtonBase {...props} forwardedRef={ref} />);

TPButton.muiName = Button.muiName;

export default TPButton;
