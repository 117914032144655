import {
  FUSION_MARKETS_LABEL,
  FUSION_MARKETS_PREFIX,
  FUSION_MARKETS_X_LABEL,
  FUSION_MARKETS_X_PREFIX,
  FUSION_MARKETS_EU_PREFIX,
  FUSION_MARKETS_EU_LABEL,
} from '../../../../constants';

export const HUBS = [
  { value: FUSION_MARKETS_PREFIX, label: FUSION_MARKETS_LABEL },
  { value: FUSION_MARKETS_X_PREFIX, label: FUSION_MARKETS_X_LABEL },
  { value: FUSION_MARKETS_EU_PREFIX, label: FUSION_MARKETS_EU_LABEL },
];

export const HUB_CODES = {
  au: FUSION_MARKETS_PREFIX,
  en: FUSION_MARKETS_X_PREFIX,
  eu: FUSION_MARKETS_EU_PREFIX,
};
