import TPSearchTextField from 'components/TP-UI/TPSearchTextField';
import TPDatePicker from 'components/TP-UI/TPDatePicker';
import TPSelect from 'components/TP-UI/TPSelect';

export const USER_MANAGEMENT_VPS_USERS_FORM = 'USER_MANAGEMENT_VPS_USERS_FORM';

export const Status = {
  CANCELLED: 'Cancelled',
};

export const APPROVED_PLATFORMS = ['mt4', 'mt4_2', 'ctrader', 'tradingView'];

export const DEFAULT_VPS_SORTING = { sortBy: 'vps.signTimestamp', sortAsc: false };

export const VPS_USERS_FILTER_FIELDS = [
  {
    name: 'search',
    component: TPSearchTextField,
    placeholder: 'Search',
    width: '250px',
  },
  {
    name: 'dateFrom',
    placeholder: 'From',
    component: TPDatePicker,
    returnsStartDay: true,
    width: '155px',
  },
  {
    name: 'dateTo',
    placeholder: 'To',
    component: TPDatePicker,
    width: '155px',
  },
  {
    name: 'status',
    placeholder: 'Status',
    component: TPSelect,
    options: [
      { value: 'active', label: 'Active' },
      { value: 'inactive', label: 'Inactive' },
    ],
    width: '155px',
  },
];
