import { get, last } from 'lodash';
import moment from 'moment';

export const getMyPerformance = (state) => state.myPerformance.data;

export const getAccount = (state) => state.myPerformance.account;

export const getAnalysis = (state) => state.myPerformance.analysis;

export const getAnalysisDaily = (state) => {
  const daily = state.myPerformance.daily;
  const lastDay = last(daily);
  if (!lastDay) {
    return daily;
  }
  const days = moment()
    .startOf('day')
    .diff(moment(lastDay.date), 'days');

  for (let i = 1; i < days; i++) {
    daily.push({
      ...lastDay,
      date: moment(lastDay.date)
        .add(i, 'days')
        .format('YYYY-MM-DD'),
    });
  }
  return daily;
};

export const getAnalysisMonthly = (state) => state.myPerformance.monthly;

export const getAnalysisEquties = (state) => {
  const balance = parseFloat(get(state, 'myPerformance.account.balance', 0));
  const equity = parseFloat(get(state, 'myPerformance.account.equity', 0));
  const currentDay = moment().format('YYYY-MM-DD');

  const res = state.myPerformance.equities.filter(({ date }) => {
    return date !== currentDay;
  });

  const lastDay = last(res);
  if (!lastDay) {
    return res;
  }

  const days = moment()
    .startOf('day')
    .diff(moment(lastDay.date), 'days');

  for (let i = 1; i <= days; i++) {
    res.push({
      ...lastDay,
      date: moment(lastDay.date)
        .add(i, 'days')
        .format('YYYY-MM-DD'),
      balance,
      equity,
    });
  }

  return res;
};

export const getOpenedTrades = (state) => state.myPerformance.tradesOpened;

export const getClosedTrades = (state) => state.myPerformance.tradesClosed;

export const getClosedTradesCount = (state) => state.myPerformance.tradesClosedCount;

export const getTradesCount = (state) => state.myPerformance.tradesCount;

export const getSymbolMap = (state) => state.myPerformance.symbolMap;

export const getCopySettings = (state) => state.myPerformance.copySettings;

export const getMt4login = (state) => state.myPerformance.mt4login;

export const getTradeSyncAccounts = (state) => state.myPerformance.tradeSyncAccounts;

export const getPortfolio = (state) => state.myPerformance.portfolio;

export const getSign = (state) => state.myPerformance.sign;

export const getShareLink = (state) => state.myPerformance.shareLink;

export const getOpenedPl = (state) => state.myPerformance.openedPl;

export const getPerformanceDaily = (state) => state.myPerformance.performanceDaily;

export const getPerformanceMonthly = (state) => state.myPerformance.performanceMonthly;
