import React from 'react';
import PropTypes from 'prop-types';
import TPPopover from 'components/TP-UI/TPPopover';
import NotificationsButton from '../NotificationsButton';

const NotificationsPopover = ({ onClick, notificationsCount, content }) => {
  return (
    <TPPopover content={content} placement="bottom-end" offset="0, 0">
      <NotificationsButton onClick={onClick} notificationsCount={notificationsCount} />
    </TPPopover>
  );
};

NotificationsPopover.propTypes = {
  notificationsCount: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  content: PropTypes.node,
};

export default NotificationsPopover;
