import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  () => ({
    lngIcon: {
      width: 24,
      height: 24,
    },
  }),
  { name: 'TPLanguageIcon' },
);

export default useStyles;
