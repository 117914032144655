import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

import styles from './styles';

class TooltipComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      arrowRef: null,
    };
  }

  handleArrowRef = (node) => {
    this.setState({
      arrowRef: node,
    });
  };

  render() {
    const { classes, isOpen, handleTooltipOpen, handleTooltipClose, label, value, t } = this.props;

    return (
      <Tooltip
        open={isOpen}
        onClose={handleTooltipClose}
        title={
          value ? (
            value
          ) : (
            <React.Fragment>
              {t('whyDoWeAsk.messages.secretQuestion')}
              <span className={classes.arrow} ref={this.handleArrowRef} />
            </React.Fragment>
          )
        }
        interactive
        leaveDelay={10}
        classes={{ tooltip: classes.tooltip, popper: classes.arrowPopper }}
        placement="bottom-end"
        disableTouchListener
        PopperProps={{
          popperOptions: {
            modifiers: {
              arrow: {
                enabled: Boolean(this.state.arrowRef),
                element: this.state.arrowRef,
              },
            },
          },
        }}>
        <Link onClick={handleTooltipOpen} classes={{ root: classes.link }}>
          {label}
        </Link>
      </Tooltip>
    );
  }
}

TooltipComponent.propTypes = {
  t: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleTooltipOpen: PropTypes.func.isRequired,
  handleTooltipClose: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  classes: PropTypes.shape({
    arrow: PropTypes.string.isRequired,
    arrowPopper: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default withTranslation('common')(withStyles(styles)(TooltipComponent));
