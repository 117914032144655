export const EDIT_PERSONAL_DETAILS_FORM = 'EDIT_PERSONAL_DETAILS_FORM';

export const EDIT_PERSONAL_DETAILS_MODAL = 'EDIT_PERSONAL_DETAILS_MODAL';

export const USER_LIVE_ACCOUNTS_FORM = 'USER_LIVE_ACCOUNTS_FORM';
export const USER_DEMO_ACCOUNTS_FORM = 'USER_DEMO_ACCOUNTS_FORM';
export const USER_AWT_ACCOUNTS_FORM = 'USER_AWT_ACCOUNTS_FORM';
export const USER_REBATE_ACCOUNTS_FORM = 'USER_REBATE_ACCOUNTS_FORM';

export const MIGRATE_USER_MODAL = 'MIGRATE_USER_MODAL';
export const MIGRATE_USER_FORM = 'MIGRATE_USER_FORM';

export const SEND_EMAIL_DATE_CARD_FORM = 'SEND_EMAIL_DATE_CARD_FORM';
export const SEND_EMAIL_USER_DETAILS_FORM = 'SEND_EMAIL_USER_DETAILS_FORM';

export const MIGRATION_DESTINATIONS_VALUES_FM = [
  {
    value: 'FUSION_MARKETS',
    label: 'Fusion Markets',
  },
  {
    value: 'FUSION_MARKETS_X',
    label: 'Fusion Markets X',
  },
  // temporarily removed
  // {
  //   value: 'FUSION_MARKETS_EU',
  //   label: 'Fusion Markets EU',
  // },
];

export const MIGRATION_DESTINATIONS_VALUES_FMX = [
  {
    value: 'FUSION_MARKETS',
    label: 'Fusion Markets',
  },
];

export const CREATE_ACCOUNT_ADMIN_MODAL = 'CREATE_ACCOUNT_ADMIN_MODAL';
export const CREATE_ACCOUNT_ADMIN_FORM = 'CREATE_ACCOUNT_ADMIN_FORM';

export const RESET_PASSWORD_MODAL = 'RESET_PASSWORD_MODAL';

export const USER_DETAILS_DISABLE_MFA_MODAL = 'USER_DETAILS_DISABLE_MFA_MODAL';

export const USER_DETAILS_BLOCK_USER_MODAL = 'USER_DETAILS_BLOCK_USER_MODAL';
export const USER_DETAILS_EDIT_TRADING_STATUS_MODAL = 'USER_DETAILS_EDIT_TRADING_STATUS_MODAL';
export const USER_DETAILS_CREATE_MAILCHIMP_USER_MODAL = 'USER_DETAILS_CREATE_MAILCHIMP_USER_MODAL';
export const USER_DETAILS_SET_PRO_CLIENT_MODAL = 'USER_DETAILS_SET_PRO_CLIENT_MODAL';
export const USER_DETAILS_SET_ACCOUNT_CREATION_MODAL = 'USER_DETAILS_SET_ACCOUNT_CREATION_MODAL';
export const USER_DETAILS_SET_UNLIMITED_ACCOUNTS_MODAL =
  'USER_DETAILS_SET_UNLIMITED_ACCOUNTS_MODAL';
export const USER_DETAILS_SET_UNLIMITED_DEMO_ACCOUNTS_MODAL =
  'USER_DETAILS_SET_UNLIMITED_DEMO_ACCOUNTS_MODAL';
export const USER_DETAILS_SET_MANUAL_ACCOUNT_APPROVE = 'USER_DETAILS_SET_MANUAL_ACCOUNT_APPROVE';
export const USER_DETAILS_SET_WHOLESALE_CLIENT_MODAL = 'USER_DETAILS_SET_WHOLESALE_CLIENT_MODAL';
export const USER_DETAILS_SET_ALLOW_PRO_UPGRADE_MODAL = 'USER_DETAILS_SET_ALLOW_PRO_UPGRADE_MODAL';
export const USER_DETAILS_UNSUBSCRIBE_USER_MODAL = 'USER_DETAILS_UNSUBSCRIBE_USER_MODAL';
export const USER_DETAILS_TOGGLE_USER_LEVERAGE_MODAL = 'USER_DETAILS_TOGGLE_USER_LEVERAGE_MODAL';
export const USER_DETAILS_SET_ALLOW_COPY_ACCOUNT_MODAL =
  'USER_DETAILS_SET_ALLOW_COPY_ACCOUNT_MODAL';
export const USER_DETAILS_SET_TEST_USER_MODAL = 'USER_DETAILS_SET_TEST_USER_MODAL';
export const USER_DETAILS_SET_ECP_MODAL = 'USER_DETAILS_SET_ECP_MODAL';

export const DEFAULT_PAYMENT_SETTINGS = ['bank'];
export const EDIT_ACCCOUNT_MODAL = 'EDIT_ACCCOUNT_MODAL';
export const EDIT_ACCCOUNT_FORM = 'EDIT_ACCCOUNT_FORM';

export const DELETE_ACCOUNT_MODAL = 'DELETE_ACCOUNT_MODAL';
export const LIMITLESS_ACCOUNT_MODAL = 'LIMITLESS_ACCOUNT_MODAL';
export const APPROVE_ACCOUNT_MODAL = 'APPROVE_ACCOUNT_MODAL';

export const CREATE_AFFILIATE_MODAL = 'CREATE_AFFILIATE_MODAL';
export const CREATE_AFFILIATE_FORM = 'CREATE_AFFILIATE_FORM';

export const CREATE_OPERATION_SUBSCRIPTION_MODAL = 'CREATE_OPERATION_SUBSCRIPTION_MODAL';
export const CREATE_OPERATION_SUBSCRIPTION_FORM = 'CREATE_OPERATION_SUBSCRIPTION_FORM';

export const USER_EMAIL_HISTORY_FORM = 'USER_EMAIL_HISTORY_FORM';

export const USER_PAYMENT_HISTORY_FORM = 'USER_PAYMENT_HISTORY_FORM';
export const PAYMENT_COMMENT_MODAL = 'ADD_PAYMENT_COMMENT_MODAL';

export const AFFILIATE_TRANSFER_FORM = 'AFFILIATE_TRANSFER_FORM';
export const AFFILIATE_TRANSFER_MODAL = 'AFFILIATE_TRANSFER_MODAL';

export const USER_MANAGEMENT_ADD_DEPOSIT_MODAL = 'USER_MANAGEMENT_ADD_DEPOSIT_MODAL';
export const USER_MANAGEMENT_ADD_DEPOSIT_FORM = 'USER_MANAGEMENT_ADD_DEPOSIT_FORM';
export const CONFIRM_MODAL = 'USER_MANAGEMENT_CONFIRM_MODAL';

export const ALLOW_TRANSACTIONAL_EMAILS_MODAL = 'ALLOW_TRANSACTIONAL_EMAILS_MODAL';
export const ALLOW_TRANSACTIONAL_EMAILS_FORM = 'ALLOW_TRANSACTIONAL_EMAILS_FORM';

export const DEPOSIT_METHODS = {
  bankwire: 'Bankwire',
  smartway: 'Smartway',
  rebate: 'Rebate',
  creditCard: 'CreditCard',
  manualDeposit: 'CreditCardAUD',
};
export const DEPOSIT_METHODS_VALUES = [
  { label: 'Bankwire', value: DEPOSIT_METHODS.bankwire },
  { label: 'Smartway', value: DEPOSIT_METHODS.smartway },
  { label: 'Partner Rebates', value: DEPOSIT_METHODS.rebate },
  { label: 'Credit Card', value: DEPOSIT_METHODS.creditCard },
  { label: 'Credit Card AU', value: DEPOSIT_METHODS.manualDeposit },
];

export const ACTIVITY_FEED_TYPE = {
  BOUNCE: 'bounce',
  CLICK: 'click',
  CONVERSATION: 'conversation',
  ECOMMERCE_SIGNUP: 'ecommerce_signup',
  EVENT: 'event',
  GENERIC_SIGNUP: 'generic_signup',
  LANDING_PAGE_SIGNUP: 'landing_page_signup',
  MARKETING_PERMISSION: 'marketing_permission',
  NOTE: 'note',
  OPEN: 'open',
  ORDER: 'order',
  POSTCARD_SENT: 'postcard_sent',
  SENT: 'sent',
  SIGNUP: 'signup',
  SQUATTER_SIGNUP: 'squatter_signup',
  UNSUB: 'unsub',
  WEBSITE_SIGNUP: 'website_signup',
  SURVEY_RESPONSE: 'survey_response',
};

export const ACTIVITY_FEED_TITLE = {
  [ACTIVITY_FEED_TYPE.OPEN]: 'Opened the email',
  [ACTIVITY_FEED_TYPE.SENT]: 'Was Sent the email',
  [ACTIVITY_FEED_TYPE.GENERIC_SIGNUP]: 'Added via API',
  [ACTIVITY_FEED_TYPE.UNSUB]: 'Unsubscribed',
};

export const MAILCHIMP_USER_STATUS = {
  SUBSCRIBED: 'subscribed',
  UNSUBSCRIBED: 'unsubscribed',
  CLEANED: 'cleaned',
  PENDING: 'pending',
  TRANSACTIONAL: 'transactional',
  ARCHIVED: 'archived',
};

export const OPERATION_SUB_TYPES = [
  { value: 'deposit', label: 'Deposit' },
  { value: 'withdraw', label: 'Withdraw' },
  { value: 'volume', label: 'Volume' },
  // { value: 'lot', label: 'Lot' },
];

export const CREATE_IB_MODAL = 'CREATE_IB_MODAL';

export const CREATE_IB_FORM = 'CREATE_IB_FORM';

export const CREATE_LPOA_MODAL = 'CREATE_LPOA_MODAL';
export const CREATE_LPOA_FORM = 'CREATE_LPOA_FORM';

export const ACCOUNT_PICKER_FORM = 'ACCOUNT_PICKER_FORM';

export const REMOVE_USER_MODAL = 'REMOVE_USER_MODAL';

export const USER_DETAILS_BLOCKED_STATUS = 'Blocked';
export const QUIZ_DETAILS_MODAL = 'QUIZ_DETAILS_MODAL';

export const MARK_AS_PARTNER_MODAL = 'MARK_AS_PARTNER_MODAL';

export const QUIZ_TYPE_LABEL = {
  RETAIL: 'Retail Quiz',
  PRO: 'Pro Quiz',
  AML: 'AML Quiz',
  TMD: 'TMD Quiz',
};

export const USER_ACTIONS_GROUP = {
  SALES: 'sales',
  GENERAL: 'general',
  PERMISSIONS: 'permissions',
  KYC_CHECKLIST: 'KYCChecklist',
};

export const ACCOUNT_TYPES = {
  DEMO: 'demo',
  LIVE: 'live',
  AWT: 'atw',
  REBATE: 'rebate',
};

export const TRADING_HISTORY_MODAL = 'TRADING_HISTORY_MODAL';
export const SWAP_FREE_USER_MODAL = 'SWAP_FREE_USER_MODAL';

export const USER_TRADING_STATUS = {
  DISABLED: 'Trading Disabled',
  ENABLED: 'Trading Enabled',
};

export const USER_TRADING_MESSAGE_NOTIFICATION = {
  DISABLED: 'Trading for this user has been disabled',
  ENABLED: 'Trading for this user has been enabled',
};

export const SET_PENDING_MODAL = 'SET_PENDING_MODAL';

export const UNABLE_PAYPAL_REFUND_APPROVE_REQUEST_MODAL =
  'UNABLE_PAYPAL_REFUND_APPROVE_REQUEST_MODAL';
export const UNABLE_PAYPAL_REFUND_APPROVE_REQUEST_FORM =
  'UNABLE_PAYPAL_REFUND_APPROVE_REQUEST_FORM';
export const UNABLE_PAYPAL_REFUND_DECLINE_REQUEST_MODAL =
  'UNABLE_PAYPAL_REFUND_DECLINE_REQUEST_MODAL';
export const UNABLE_PAYPAL_REFUND_DECLINE_REQUEST_FORM =
  'UNABLE_PAYPAL_REFUND_DECLINE_REQUEST_FORM';

export const UNABLE_PAYPAL_REFUND_REQUEST_STATUS = {
  APPROVED: 'Approved',
  DECLINED: 'Declined',
  PENDING: 'Pending',
  PARTIAL_APPROVED: 'Partial_Approved',
};

export const UNABLE_PAYPAL_REFUND_REQUEST_STATUS_LABELS = {
  [UNABLE_PAYPAL_REFUND_REQUEST_STATUS.APPROVED]: 'Approved',
  [UNABLE_PAYPAL_REFUND_REQUEST_STATUS.DECLINED]: 'Declined',
  [UNABLE_PAYPAL_REFUND_REQUEST_STATUS.PENDING]: 'Pending',
  [UNABLE_PAYPAL_REFUND_REQUEST_STATUS.PARTIAL_APPROVED]: 'Approved',
};

export const USER_PAYPAL_REFUNDABLE_PAYMENTS_FORM = 'USER_PAYPAL_REFUNDABLE_PAYMENTS_FORM';

export const USER_PAYPAL_REFUNDABLE_PAYMENTS_SORTING = {
  sortBy: 'createdAt',
  sortAsc: false,
};

export const SALES_PROOF_FORM = 'SALES_PROOF_FORM';
export const SALES_PROOF_MODAL = 'SALES_PROOF_MODAL';

export const TRACK_OPERATION_TYPE = {
  VOLUME: 'volume',
  DEPOSIT: 'deposit',
  WITHDRAW: 'withdraw',
};

export const TRACK_OPERATION_CURRENCY_HUB_MAPPER = {
  FUSION_MARKETS: 'AUD',
  FUSION_MARKETS_X: 'USD',
  FUSION_MARKETS_EU: 'EUR',
};
