import React, { useMemo, useState } from 'react';
import { Routes } from 'constants/routeConstants';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import TimelineIcon from '@material-ui/icons/Timeline';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import TPGrid from 'components/TP-UI/TPGrid';
import TPTypography from 'components/TP-UI/TPTypography';
import TPDrawer from 'components/TP-UI/TPDrawer';
import TPBottomNavigation from 'components/TP-UI/TPBottomNavigation';
import TPBottomNavigationAction from 'components/TP-UI/TPBottomNavigationAction';
import TPBottomNavigationActionMenu from 'components/TP-UI/TPBottomNavigationActionMenu';
import useStyles from './styles';

const BottomNavigations = () => {
  const classes = useStyles();
  const [openedDrawer, setOpenedDrawer] = useState(false);

  const items = useMemo(
    () => [
      {
        id: 'accounts',
        iconComponent: PersonOutlineIcon,
        path: Routes.MY_ACCOUNTS,
      },
      {
        id: 'payments',
        iconComponent: AccountBalanceWalletOutlinedIcon,
        path: Routes.PAYMENTS,
      },
      {
        id: 'tradeIdeas',
        iconComponent: AssessmentOutlinedIcon,
        items: [
          {
            id: 'analystViews',
            iconComponent: TimelineIcon,
            label: { key: 'menu:category.analystViews' },
            path: Routes.ANALYST_VIEWS,
          },
          {
            id: 'technicalInsight',
            iconComponent: EmojiObjectsOutlinedIcon,
            label: { key: 'menu:category.technicalInsight' },
            path: Routes.TECHNICAL_INSIGHT,
          },
        ],
      },
    ],
    [],
  );

  return (
    <TPGrid container spacing={1}>
      <TPBottomNavigation value={1} className={classes.root}>
        {items.map((item) => {
          return item?.items?.length ? (
            <TPBottomNavigationActionMenu
              selectedRoute={Routes.MY_ACCOUNTS}
              id={item.id}
              key={item.id}
              iconComponent={item.iconComponent}
              menuItems={item.items}
            />
          ) : (
            <TPBottomNavigationAction
              selectedRoute={Routes.MY_ACCOUNTS}
              id={item.id}
              key={item.id}
              iconComponent={item.iconComponent}
              path={item.path}
            />
          );
        })}
        <TPBottomNavigationAction
          expandable
          expanded={openedDrawer}
          iconComponent={MenuIcon}
          onClick={() => setOpenedDrawer(true)}
        />
      </TPBottomNavigation>
      <TPDrawer open={openedDrawer} anchor="top" onClose={() => setOpenedDrawer(false)}>
        <TPTypography variant="h1" className={classes.drawerContent}>
          Top Drawer
        </TPTypography>
      </TPDrawer>
    </TPGrid>
  );
};

export default BottomNavigations;
