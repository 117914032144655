import get from 'lodash/get';
import { all, put, takeEvery, select } from 'redux-saga/effects';
import { initialize, change, stopSubmit, reset } from 'redux-form';

import * as actionCreators from '../actions';
import { getActiveAccountsTab } from '../selectors';
import { getUserName, getUserLastName, getUserPhoneInfo, getUserId } from 'modules/auth/selectors';
import { getAccountsRequest } from '../actions';
import {
  EDIT_ACCOUNT_FORM,
  FUND_DEMO_ACCOUNT_FORM,
  MIGRATION_MODAL,
  TOGGLE_ACCOUNT_VISIBILITY_CONFIRMATION_MODAL,
  ACCEPT_INVITATION_MODAL,
  ACCEPT_INVITATION_FORM,
  UNDO_ARCHIVE_MODAL,
  UNDO_ARCHIVE_FORM,
  ADD_ACCOUNT_FORM,
  EDIT_ACCOUNT_MODAL,
  FUND_DEMO_ACCOUNT_MODAL,
} from '../constants';
import * as notificationsActions from 'modules/notifications/actions';
import * as menuActions from 'modules/menu/actions';
import * as modalActions from 'components/TP-UI/TPModal/actions';
import * as migrationActionCreators from 'modules/auth/pages/Migration/actions';
import { setCopierSettings } from 'modules/myPerformance/helpers';
import * as followersActions from 'modules/myFollowers/actions';
import { ACCOUNT_SERVERS } from 'constants/index';
import * as myFollowersActions from '../../../../myFollowers/actions';
import { JOIN_PLUS_FORM, JOIN_PLUS_MODAL } from '../../../../myFollowers/constants';
import { mt4LiveGetCountriesRequest } from './../../../../mt4LiveCountries/actions/index';
import { UPGRADE_TO_PRO_FORM } from 'modules/upgradeToPro/constants';

function* reloadAccounts() {
  let activeAccountTab = yield select(getActiveAccountsTab);
  const params = {
    isLive:
      activeAccountTab === ACCOUNT_SERVERS.LIVE ||
      activeAccountTab === ACCOUNT_SERVERS.REBATE ||
      activeAccountTab === ACCOUNT_SERVERS.ATW,
  };
  if (activeAccountTab === ACCOUNT_SERVERS.ATW) {
    params.isAWT = true;
  }
  yield put(mt4LiveGetCountriesRequest());
  yield put(getAccountsRequest(params));
}

function* hideDialog() {
  yield put(actionCreators.accountsHideDialog());
}

function* showEditAccountModal(action) {
  let initData = {
    _id: action.payload._id,
    leverage: action.payload.leverage,
    accountNickname: action.payload.accountNickname,
    accountName: action.payload.accountName,
    password: '',
    confirmPassword: '',
    isRebate: action.payload.isRebate,
    refCode: action.payload.refCode,
    platform: action.payload.platform,
  };

  yield put(initialize(EDIT_ACCOUNT_FORM, initData));
  yield put(modalActions.openModal(EDIT_ACCOUNT_MODAL));
}

function* hideEditAccountModal() {
  yield put(modalActions.closeModal(EDIT_ACCOUNT_MODAL));
}

function* showFundDemoModal(action) {
  let initData = {
    accountId: action.payload._id,
    amount: '',
  };

  yield put(initialize(FUND_DEMO_ACCOUNT_FORM, initData));
  yield put(modalActions.openModal(FUND_DEMO_ACCOUNT_MODAL));
}

function* hideFundDemoModal() {
  yield put(modalActions.closeModal(FUND_DEMO_ACCOUNT_MODAL));
}

function* createAccountSuccess(action) {
  if (action.payload.accountServer === ACCOUNT_SERVERS.LIVE) {
    yield put(
      notificationsActions.showNotificationInfo({
        key: 'accounts:page.notifications.tradingAccountCreated',
      }),
    );
  } else {
    yield put(
      notificationsActions.showNotificationInfo({
        key: 'accounts:page.notifications.demoAccountCreated',
      }),
    );
  }

  const lastName = yield select(getUserLastName);
  const firstName = yield select(getUserName);
  const phoneInfo = yield select(getUserPhoneInfo);

  if (!firstName || !lastName || !get(phoneInfo, 'phoneNumber')) {
    const userId = yield select(getUserId);
    yield put(menuActions.getUpdatedUserRequest(userId));
  }
}

function* createAccountFail(action) {
  const { message: errorMessage } = get(action, 'error.data', {});

  yield put(
    stopSubmit(ADD_ACCOUNT_FORM, {
      _error: errorMessage || {
        key: 'accounts:page.notifications.failedCreateAccount',
      },
    }),
  );
}

function* updateAccountSuccess() {
  yield put(modalActions.closeModal(EDIT_ACCOUNT_MODAL));
  yield put(
    notificationsActions.showNotificationInfo({ key: 'accounts:page.notifications.changesSaved' }),
  );
}

function* updateAccountFail(action) {
  const { message: errorMessage } = get(action, 'error.data', {});

  yield put(
    stopSubmit(EDIT_ACCOUNT_FORM, {
      _error: errorMessage || {
        key: 'accounts:page.notifications.failedUpdateAccount',
      },
    }),
  );
}

function* fundDemoAccountSuccess() {
  yield put(
    notificationsActions.showNotificationInfo({ key: 'accounts:page.notifications.changesSaved' }),
  );
  yield put(modalActions.closeModal(FUND_DEMO_ACCOUNT_MODAL));
}

function* fundDemoAccountFail(action) {
  const errorMessage = get(action, 'error.data.message');
  yield put(
    stopSubmit(FUND_DEMO_ACCOUNT_FORM, {
      _error: errorMessage,
    }),
  );
}

function* showMigrationModal() {
  yield put(modalActions.openModal(MIGRATION_MODAL));
}

function* hideAccountOpen() {
  yield put(modalActions.openModal(TOGGLE_ACCOUNT_VISIBILITY_CONFIRMATION_MODAL));
}

function* hideAccountClose() {
  yield put(modalActions.closeModal(TOGGLE_ACCOUNT_VISIBILITY_CONFIRMATION_MODAL));
}

function* hideAccountSuccess() {
  yield put(modalActions.closeModal(TOGGLE_ACCOUNT_VISIBILITY_CONFIRMATION_MODAL));
}

function* hideAccountFail(action) {
  yield put(modalActions.closeModal(TOGGLE_ACCOUNT_VISIBILITY_CONFIRMATION_MODAL));
  const errorMessage = get(action, 'error.data.message');
  yield put(notificationsActions.showNotificationError(errorMessage));
}

function* getMyFollowersJoinIdSuccess(action) {
  const settings = setCopierSettings(action.response.data.settings);

  yield put(initialize(ACCEPT_INVITATION_FORM, settings));
}

function* openAcceptInvitationModal() {
  yield put(modalActions.openModal(ACCEPT_INVITATION_MODAL));
}

function* addCopierByInviteFail(action) {
  const { message: errorMessage } = get(action, 'error.data', {});

  yield put(
    stopSubmit(ACCEPT_INVITATION_FORM, {
      _error: errorMessage,
    }),
  );
}

function* addCopierByInviteSuccess() {
  yield put(
    notificationsActions.showNotificationInfo({
      key: `myFollowers:messages.copierAdded`,
    }),
  );
  yield put(modalActions.closeModal(ACCEPT_INVITATION_MODAL));
}

function* openJoinFusionPlus() {
  yield put(modalActions.openModal(JOIN_PLUS_MODAL));
}

function* joinFusionPlusSuccess() {
  const userId = yield select(getUserId);
  yield put(menuActions.getUpdatedUserRequest(userId));

  yield put(modalActions.closeModal(JOIN_PLUS_MODAL));
}

function* joinFusionPlusFail(action) {
  const { message: errorMessage } = get(action, 'error.data', {});

  yield put(
    stopSubmit(JOIN_PLUS_FORM, {
      _error: errorMessage,
    }),
  );
}

function* joinFusionPlusInvitedSuccess() {
  const userId = yield select(getUserId);
  yield put(menuActions.getUpdatedUserRequest(userId));
  yield put(modalActions.closeModal(JOIN_PLUS_MODAL));
}

function* handleUnArchiveAccountModal(action) {
  yield put(modalActions.openModal(UNDO_ARCHIVE_MODAL));
  yield put(change(UNDO_ARCHIVE_FORM, 'accountId', action.payload));
}

function* handleUnArchiveAccountSuccess() {
  yield put(modalActions.closeModal(UNDO_ARCHIVE_MODAL));
  yield put(
    notificationsActions.showNotificationInfo({
      key: 'accounts:page.notifications.reactivateRequestSent',
    }),
  );
}

function* handleUnArchiveAccountFail(action) {
  const errorMessage = get(action, 'error.data.message', {
    key: 'accounts:page.notifications.failedReActiveAccount',
  });

  yield put(modalActions.closeModal(UNDO_ARCHIVE_MODAL));
  yield put(notificationsActions.showNotificationError(errorMessage));
}

function* handleRequestAdditionalAccountFail(action) {
  const { message: errorMessage } = get(action, 'error.data', {});

  yield put(
    stopSubmit(ADD_ACCOUNT_FORM, {
      _error: errorMessage,
    }),
  );
}

function* handleRequestAdditionalAccountSuccess() {
  yield put(change(ADD_ACCOUNT_FORM, 'requestAccountMessage', null));
  yield put(
    notificationsActions.showNotificationInfo({
      key: 'accounts:page.notifications.requestAccount',
    }),
  );
}

function* getAccountsSuccess(payload) {
  const mt5Error = payload.response.data.mt5Error;

  if (mt5Error) {
    yield put(
      notificationsActions.showNotificationError(
        ['165', '474'].includes(mt5Error.message)
          ? {
              key: `accounts:page.notifications.${
                mt5Error.isLive ? 'mt5LiveUnavailable' : 'mt5DemoUnavailable'
              }`,
            }
          : {
              key: `accounts:page.notifications.${
                mt5Error.isLive ? 'mt5LiveError' : 'mt5DemoError'
              }`,
            },
      ),
    );
  }
}

function* handleAccountsHideDialog() {
  yield put(reset(ADD_ACCOUNT_FORM));
}

function* handleProSignFail(action) {
  const { message: errorMessage } = get(action, 'error.data');
  yield put(
    stopSubmit(UPGRADE_TO_PRO_FORM, {
      _error: errorMessage || {
        key: 'backend:somethingWrongContactHelp',
      },
    }),
  );
}

export default function* watchRequest() {
  yield all([
    takeEvery(
      [
        actionCreators.accountsPageOpened,
        actionCreators.accountsTabChanged,
        actionCreators.createAccountSuccess,
        actionCreators.updateAccountSuccess,
        actionCreators.fundDemoAccountSuccess,
      ],
      reloadAccounts,
    ),
    takeEvery(actionCreators.createAccountSuccess, createAccountSuccess),
    takeEvery(actionCreators.createAccountFail, createAccountFail),
    takeEvery(actionCreators.updateAccountSuccess, updateAccountSuccess),
    takeEvery(actionCreators.updateAccountFail, updateAccountFail),
    takeEvery(actionCreators.fundDemoAccountFail, fundDemoAccountFail),
    takeEvery(actionCreators.fundDemoAccountSuccess, fundDemoAccountSuccess),
    takeEvery(actionCreators.showEditAccountModal, showEditAccountModal),
    takeEvery(actionCreators.hideEditAccountModal, hideEditAccountModal),
    takeEvery(actionCreators.showFundDemoAccountModal, showFundDemoModal),
    takeEvery(actionCreators.hideFundDemoAccountModal, hideFundDemoModal),
    takeEvery(
      [actionCreators.createAccountSuccess, actionCreators.requestAdditionalAccountSuccess],
      hideDialog,
    ),
    takeEvery([migrationActionCreators.userMigrationSuccess], showMigrationModal),
    takeEvery(actionCreators.hideAccountOpen, hideAccountOpen),
    takeEvery(actionCreators.hideAccountClose, hideAccountClose),
    takeEvery(actionCreators.hideAccountSuccess, hideAccountSuccess),
    takeEvery(actionCreators.hideAccountFail, hideAccountFail),
    takeEvery(actionCreators.openAcceptInvitationModal, openAcceptInvitationModal),
    takeEvery(followersActions.addCopierByInviteFail, addCopierByInviteFail),
    takeEvery(followersActions.addCopierByInviteSuccess, addCopierByInviteSuccess),

    takeEvery(myFollowersActions.openJoinFusionPlus, openJoinFusionPlus),
    takeEvery(myFollowersActions.joinFusionPlusSuccess, joinFusionPlusSuccess),
    takeEvery(myFollowersActions.joinFusionPlusFail, joinFusionPlusFail),
    takeEvery(myFollowersActions.joinFusionPlusInvitedSuccess, joinFusionPlusInvitedSuccess),

    takeEvery(myFollowersActions.getMyFollowersJoinIdSuccess, getMyFollowersJoinIdSuccess),
    takeEvery(actionCreators.unArchiveAccountModal, handleUnArchiveAccountModal),
    takeEvery(actionCreators.unArchiveAccountSuccess, handleUnArchiveAccountSuccess),
    takeEvery(actionCreators.unArchiveAccountFail, handleUnArchiveAccountFail),
    takeEvery(
      actionCreators.requestAdditionalAccountSuccess,
      handleRequestAdditionalAccountSuccess,
    ),
    takeEvery(actionCreators.requestAdditionalAccountFail, handleRequestAdditionalAccountFail),

    takeEvery(actionCreators.getAccountsSuccess, getAccountsSuccess),
    takeEvery(actionCreators.accountsHideDialog, handleAccountsHideDialog),
    takeEvery(actionCreators.proSignFail, handleProSignFail),
  ]);
}
