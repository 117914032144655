export const LEAD_USER_MANAGEMENT_FORM = 'LEAD_USER_MANAGEMENT_FORM';

export const INITIAL_FILTERS_VALUES = {
  search: '',
  dateRangeBy: '',
  dateFrom: '',
  dateTo: '',
  status: [],
  country: [],
  firstTrade: [],
  lastTrade: [],
  lastLogin: [],
  lastDemo: [],
  userType: [],
  volumeDate: '',
  volume: [],
  callStatus: [],
  riskStatus: [],
};

export const MY_CLIENTS_PRESET_FILTERS_TYPE = 'MY_CLIENTS';
