import { ACCOUNT_STATUSES } from 'constants/index';
import { sortBy, get } from 'lodash';

export const getLiveAccounts = (accounts = []) =>
  accounts.filter((account) => account.isLive && !account.isRebate && !account.isAWT);

export const getRebateAccounts = (accounts = []) =>
  accounts.filter((account) => account.isLive && account.isRebate);

export const getDemoAccounts = (accounts = []) => accounts.filter((account) => !account.isLive);

export const getAwtAccounts = (accounts = []) =>
  accounts.filter((account) => account.isLive && account.isAWT);

export const isAllAccountsHidden = (showHidden, accounts) => {
  if (!accounts) return false;
  return (
    (!showHidden &&
      !accounts.filter((account) => !account.isHidden).length &&
      accounts.filter((account) => account.isHidden).length) ||
    (showHidden &&
      !accounts.filter((account) => account.isHidden).length &&
      accounts.filter((account) => !account.isHidden).length)
  );
};

export const isNodeClickable = (target, currentTarget) => {
  const clickable =
    ['a', 'path', 'svg', 'input', 'textarea', 'select', 'option', 'button'].indexOf(
      target.tagName.toLowerCase(),
    ) !== -1 || target.getAttribute('data-clickable');

  return (
    clickable ||
    (target !== currentTarget ? isNodeClickable(target.parentNode, currentTarget) : false)
  );
};

const _isNotHiddenAndArchived = (isHidden, isArchived) =>
  !isHidden && !get(isArchived, 'status', false);

export const sortFilterDepositableAccounts = (accounts = []) =>
  sortBy(accounts, ['createdAt']).filter(
    ({ isHidden, isArchived, status }) =>
      _isNotHiddenAndArchived(isHidden, isArchived) && status !== ACCOUNT_STATUSES.REJECTED,
  );

export const sortFilterTransferableAccounts = (accounts = []) =>
  sortBy(accounts, ['createdAt']).filter(
    ({ isHidden, isArchived, status }) =>
      _isNotHiddenAndArchived(isHidden, isArchived) && status === ACCOUNT_STATUSES.APPROVED,
  );

export const sortFilterWithdrawableAccounts = (accounts = []) =>
  sortBy(accounts, ['createdAt']).filter(
    ({ isHidden, isArchived, status }) =>
      _isNotHiddenAndArchived(isHidden, isArchived) && status === ACCOUNT_STATUSES.APPROVED,
  );

export const sortFilterDefaultAccounts = (accounts = []) =>
  sortBy(accounts, ['createdAt']).filter(({ isHidden, isArchived }) =>
    _isNotHiddenAndArchived(isHidden, isArchived),
  );
