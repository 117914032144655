import { push } from 'modules/reduxNavigation/actions';
import { takeEvery, select, put, all, take } from 'redux-saga/effects';

import * as notificationsActions from 'modules/notifications/actions';
import { proSignSuccess, selectAccountPlanSuccess } from '../../accounts/pages/MyAccounts/actions';
import { Routes } from 'constants/routeConstants';
import { startIdVerification } from '../actions';
import verificationModalNames from '../constants/verificationModalNames';
import { targetUserQuizSelector } from '../../auth/selectors';
import { passQuizSuccess, resetQuizProgress, passQuizFail } from 'modules/profileQuiz/actions';
import { ACCOUNT_PLANS } from 'constants/accountPlans';
import { closeModal, openModal } from 'components/TP-UI/TPModal/actions';
import { getKeepCurrentStep } from '../../profile/pages/ProfileVerification/selectors';
import { classicVerificationSecondStepUpdateSuccess } from '../../profile/pages/ProfileVerification/actions';
import { PRO_QUIZ_MODAL, RETAIL_QUIZ_MODAL } from '../../profile/constants';
import { setEvidenceUploadView } from 'modules/upgradeToPro/actions';

function* startIdVerificationWorker() {
  return yield put(push(Routes.PROFILE_VERIFICATION));
}

function* completeVerificationWorker() {
  const isKeepCurrentStep = yield select(getKeepCurrentStep);

  if (!isKeepCurrentStep) {
    yield put(openModal(verificationModalNames.VERIFICATION_AWAITING_MODAL));
  }
}

function* passQuizSuccessWorker(action) {
  const { selectedPlan } = action.response.data.user;
  const targetQuizProgress = yield select(targetUserQuizSelector);
  const activeQuizModalName =
    selectedPlan === ACCOUNT_PLANS.PRO ? PRO_QUIZ_MODAL : RETAIL_QUIZ_MODAL;

  yield put(closeModal(activeQuizModalName));
  yield put(resetQuizProgress());

  if (selectedPlan === ACCOUNT_PLANS.PRO) {
    if (targetQuizProgress?.isBlocked) {
      yield put(openModal(verificationModalNames.PRO_QUIZ_BLOCKED_MODAL));

      yield take(selectAccountPlanSuccess);

      yield put(closeModal(verificationModalNames.PRO_OR_RETAIL_MODAL));

      return yield put(push(Routes.MY_ACCOUNTS));
    }
  }

  if (!targetQuizProgress?.isPassed) {
    if (targetQuizProgress.attempts === 1) {
      return yield put(openModal(verificationModalNames.TRY_AGAIN_MODAL));
    }

    return yield put(openModal(verificationModalNames.SECOND_FAILED_MODAL));
  } else {
    yield put(closeModal(activeQuizModalName));
    return yield put(openModal(verificationModalNames.SUCCESS_QUIZ_MODAL));
  }
}

function* passQuizFailWorker(action) {
  const defaultError = {
    key: 'common:errors.notifications.unexpectedError',
  };
  const errorMessage = action?.error?.data?.message ?? defaultError;
  yield put(notificationsActions.showNotificationError(errorMessage));
}

function* successProSignWorker() {
  yield put(closeModal(verificationModalNames.PRO_SIGN_MODAL));
  yield put(setEvidenceUploadView(true));
}

function* startIdVerificationWatcher() {
  yield takeEvery(startIdVerification, startIdVerificationWorker);
}

function* completeVerificationWatcher() {
  yield takeEvery(classicVerificationSecondStepUpdateSuccess, completeVerificationWorker);
}

function* passQuizSuccessWatcher() {
  yield takeEvery(passQuizSuccess, passQuizSuccessWorker);
}

function* passQuizFailWatcher() {
  yield takeEvery(passQuizFail, passQuizFailWorker);
}

function* successSignWatcher() {
  yield takeEvery(proSignSuccess, successProSignWorker);
}

function* verificationModalsRunnerWatcher() {
  yield all([
    startIdVerificationWatcher(),
    completeVerificationWatcher(),
    passQuizSuccessWatcher(),
    passQuizFailWatcher(),
    successSignWatcher(),
  ]);
}

export default verificationModalsRunnerWatcher;
