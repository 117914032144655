import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Typography } from '@material-ui/core';

import CustomLink from 'modules/common/CustomLink';
import { getFieldData } from './helpers';
import loader_squares from 'static/images/old/loader_squares_secondary.gif';
import TableSortButton from '../TableSortButton';

const TableTextField = ({
  classes,
  label,
  dataLabel,
  dataIcon,
  tableItem,
  formatFunc,
  route,
  getRedirectRoute,
  className,
  isLoading,
  sort,
  rowIndex,
}) => {
  let { fieldLabel, fieldValue } = useMemo(() => getFieldData(label, dataLabel, tableItem), [
    label,
    dataLabel,
    tableItem,
  ]);
  return (
    <div className={classNames([classes.item, className])}>
      <Typography className={classes.label}>
        {fieldLabel}
        {rowIndex === 0 && sort ? (
          <TableSortButton
            dataLabel={dataLabel}
            onSort={sort.onSort}
            isSorted={sort.isSorted}
            getSortDirection={sort.getSortDirection}
          />
        ) : null}
      </Typography>
      <div className={classes.dataText}>
        {dataIcon && get(tableItem, dataIcon) && (
          <div className={classes.dataIconContainer}>
            <img className={classes.dataIcon} alt="flag" src={get(tableItem, dataIcon)} />
          </div>
        )}
        {isLoading ? (
          <img alt="" src={loader_squares} className={classes.loader} />
        ) : (
          <>
            {route || getRedirectRoute ? (
              <CustomLink to={route || getRedirectRoute(tableItem)}>
                {formatFunc ? formatFunc(fieldValue) : get(tableItem, dataLabel)}
              </CustomLink>
            ) : formatFunc ? (
              fieldValue !== undefined && formatFunc(fieldValue)
            ) : fieldValue !== undefined && fieldLabel === 'SWIFT code' ? (
              fieldValue === 'false' ? (
                (fieldValue = (
                  <span className={classes.swiftRequiresValidation}>SWIFT requires validation</span>
                ))
              ) : (
                (fieldValue = <span>SWIFT validated</span>)
              )
            ) : (
              fieldValue !== undefined && fieldValue
            )}
          </>
        )}
      </div>
    </div>
  );
};

TableTextField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  dataLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  tableItem: PropTypes.object.isRequired,
  formatFunc: PropTypes.func,
  className: PropTypes.string,
  route: PropTypes.string,
  getRedirectRoute: PropTypes.func,
};

export default withStyles(styles)(TableTextField);
