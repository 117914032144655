import api from '..';

export const getMyPerformanceRequest = ({ accountId }) => {
  return api.get(`/performance/${accountId}/analysis`);
};

export const getSharedPerformanceRequest = ({ accountId, shareId }) => {
  return api.get(`/performance/${accountId}/share/${shareId}/analysis`);
};

export const getSharedPerformanceTradesRequest = ({ accountId, shareId }) => {
  return api.get(`/performance/${accountId}/share/${shareId}/trades`);
};

export const getMyPerformanceTradesRequest = ({ accountId, skip, limit }) => {
  return api.get(`/performance/${accountId}/trades?skip=${skip}&limit=${limit}`);
};

export const getUserPerformanceRequest = ({ userId, accountId }) => {
  return api.get(`/users/${userId}/performance/${accountId}/analysis`);
};

export const getUserPerformanceTradesRequest = ({ userId, accountId, skip, limit }) => {
  return api.get(`/users/${userId}/performance/${accountId}/trades`, { params: { skip, limit } });
};

export const getPerformanceShareLinkRequest = ({ accountId }) => {
  return api.get(`/performance/${accountId}/link`);
};
