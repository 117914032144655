import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  () => ({
    index: {
      width: '30px',
      textAlign: 'right',
    },
    volume: {
      textAlign: 'right',
    },
    country: {
      display: 'block',
    },
  }),
  { name: 'VolumeUsersView' },
);
