import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import TPIconButton from 'components/TP-UI/TPIconButton';
import TPTypography from 'components/TP-UI/TPTypography';
import CloseIcon from '@material-ui/icons/Close';
import { ALERT_ICONS, ALERT_VARIANTS } from './constants';
import useStyles from './styles';

const TPAlert = ({ variant, onClose, hideIcon = false, minWidth, maxWidth, children }) => {
  const classes = useStyles({ minWidth, maxWidth });
  const Icon = !hideIcon ? ALERT_ICONS[variant] : null;

  return (
    <div className={classnames(classes.container, classes[variant])}>
      <div className={classes.messageContent}>
        {Icon ? <Icon className={classes.messageIcon} /> : null}
        {typeof children === 'string' ? (
          <TPTypography
            variant="body2"
            className={classnames(classes.message, classes.messageText)}>
            {children}
          </TPTypography>
        ) : (
          <div className={classes.message}>{children}</div>
        )}
      </div>
      {onClose ? (
        <TPIconButton
          size="xsmall"
          aria-label="Close"
          onClick={onClose}
          className={classes.closeButton}>
          <CloseIcon className={classes.closeIcon} />
        </TPIconButton>
      ) : null}
    </div>
  );
};

TPAlert.propTypes = {
  variant: PropTypes.oneOf(Object.values(ALERT_VARIANTS)).isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
};

export default TPAlert;
