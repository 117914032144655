import { handleActions } from 'redux-actions';
import {
  riskGroupAddCountrySuccess,
  riskGroupGetCountriesFail,
  riskGroupGetCountriesSuccess,
  riskGroupRemoveCountrySuccess,
} from '../actions';

const defaultState = {
  countries: null,
};

export default handleActions(
  {
    [riskGroupGetCountriesSuccess]: (state, action) => {
      return {
        ...state,
        countries: action.response.data,
      };
    },
    [riskGroupGetCountriesFail]: (state) => {
      return { ...state, countries: state.countries || [] };
    },
    [riskGroupAddCountrySuccess]: (state, action) => {
      return {
        ...state,
        countries: action.response.data,
      };
    },
    [riskGroupRemoveCountrySuccess]: (state, action) => {
      return {
        ...state,
        countries: action.response.data,
      };
    },
  },
  defaultState,
);
