import config from 'config';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'modules/common/Page';
import Layout from '../../../../components/LayoutNew';
import MfaInputForm from '../MfaInputForm';
import TPLink from 'components/TP-UI/TPLink';
import TPTypography from 'components/TP-UI/TPTypography';
import { Routes } from 'constants/routeConstants';
import TPGrid from 'components/TP-UI/TPGrid';

const MfaInput = (props) => {
  const { t } = useTranslation('auth');
  const { CURRENT_HUB_LABEL } = config;

  return (
    <Page docTitle={t('mfa.documentTitle', { company: CURRENT_HUB_LABEL })}>
      <Layout
        title={t('mfa.title')}
        welcomeTitle={t('logInWelcome')}
        welcomeDescription={t('logInWelcomeDescription')}>
        <TPGrid container spacing={5} justifyContent="center">
          <TPGrid item xs={12}>
            <MfaInputForm {...props} />
          </TPGrid>
          <TPGrid item>
            <TPTypography variant="body1">
              <TPLink to={{ pathname: Routes.SIGNIN, state: { cleanupStore: true } }}>
                {t('backToLogIn')}
              </TPLink>
            </TPTypography>
          </TPGrid>
        </TPGrid>
      </Layout>
    </Page>
  );
};

MfaInput.propTypes = {
  form: PropTypes.string,
  isPending: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default MfaInput;
