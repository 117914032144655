import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ForgotPassword from '../components/ForgotPassword';
import * as actionCreators from '../actions';
import { FORGOT_PASSWORD_FORM } from '../constants';
import * as apiSelectors from 'modules/api/selectors';
import { getSelectHub, getSelectAvailableHubs } from '../selectors';
import SelectHubModal from '../../../components/SelectHubModal';
import { withTranslation } from 'react-i18next';

class ForgotPasswordContainer extends PureComponent {
  componentDidMount() {
    const {
      actions: { initializeForm },
    } = this.props;
    initializeForm();
  }

  handleSubmit = (data) => {
    const {
      actions: { forgotPasswordRequest },
    } = this.props;

    forgotPasswordRequest(data);
  };

  handleSuccess = () => {
    const {
      actions: { redirectToSignIn },
    } = this.props;

    redirectToSignIn();
  };

  render() {
    const { selectHub, selectAvailableHubs, isPending, t } = this.props;
    return (
      <>
        <ForgotPassword
          form={FORGOT_PASSWORD_FORM}
          onSubmit={this.handleSubmit}
          onSuccess={this.handleSuccess}
          isPending={isPending}
        />
        <SelectHubModal
          visible={selectHub && !isPending}
          onSubmit={this.handleSubmit}
          loading={isPending}
          form={FORGOT_PASSWORD_FORM}
          buttonLabel={t('auth:buttons.resetPassword')}
          hubs={selectAvailableHubs}
        />
      </>
    );
  }
}

ForgotPasswordContainer.propTypes = {
  actions: PropTypes.shape({
    forgotPasswordRequest: PropTypes.func.isRequired,
  }).isRequired,
  isPending: PropTypes.bool.isRequired,
  selectHub: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isPending: apiSelectors.createPendingSelector(actionCreators.forgotPasswordRequest)(state),
    selectHub: getSelectHub(state),
    selectAvailableHubs: getSelectAvailableHubs(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ForgotPasswordContainer));
