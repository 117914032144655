import api from '..';

import { DATE_FORMAT_YYYYMMDD } from '../../../constants';
import moment from 'moment';

export const getClientsRequest = (payload) => {
  const cleanedPayload = { ...payload };
  delete cleanedPayload.persistStoreData;
  return api.get('/clients/profiles', { params: cleanedPayload });
};

export const getFullClientsRequest = (payload) => {
  setDateFilter(payload);
  const cleanedPayload = { ...payload };
  delete cleanedPayload.persistStoreData;

  return api.get('/clients/profiles-accounts', { params: cleanedPayload });
};

export const getClientSalesRequest = (payload) => {
  const cleanedPayload = { ...payload };

  return api.get('/clients/client-sales', { params: cleanedPayload });
};

export const downloadClientSalesReportRequest = (payload) => {
  setDateFilter(payload);
  const cleanedPayload = { ...payload, columns: payload.columns.filter((item) => item) };
  return api.get('/clients/download-client-sales-report', { params: cleanedPayload });
};

export const getFullMyClientsRequest = (payload) => {
  setDateFilter(payload);
  const cleanedPayload = { ...payload };
  delete cleanedPayload.persistStoreData;

  return api.get('/clients/lead-clients-profiles-accounts', { params: cleanedPayload });
};

const setDateFilter = (payload) => {
  if (payload.dateFrom) {
    payload.dateFrom = moment(payload.dateFrom).format(DATE_FORMAT_YYYYMMDD);
  }
  if (payload.dateTo) {
    payload.dateTo = moment(payload.dateTo).format(DATE_FORMAT_YYYYMMDD);
  }
};
