import { colors } from './colors';

const palette = {
  primary: {
    darkest: colors.purple.darkest,
    darker: colors.purple.darker,
    dark: colors.purple.dark,
    main: colors.purple.main,
    light: colors.purple.light,
    lighter: colors.purple.lighter,
    lightest: colors.purple.lightest,
    // Text color for light primary element
    text: colors.gray.darker,
    // Text color for dark primary element
    contrastText: colors.white.darker,
    // Gradients
    gradient: {
      dark: 'linear-gradient(90deg, #620BCE 0%, #060086 100%)',
      main: 'linear-gradient(90deg, #742CCE 0%, #1510A1 100%)',
      light: 'linear-gradient(90deg, #8D59CD 0%, #251FA7 100%)',
    },
  },
  text: {
    primary: colors.blue.main,
    secondary: colors.gray.main,
    disabled: colors.gray.light,
  },
  success: {
    darkest: colors.green.darkest,
    darker: colors.green.darker,
    dark: colors.green.dark,
    main: colors.green.main,
    light: colors.green.light,
    lighter: colors.green.lighter,
    lightest: colors.green.lightest,
    contrastText: colors.white.darker,
  },
  error: {
    darkest: colors.red.darkest,
    darker: colors.red.darker,
    dark: colors.red.dark,
    main: colors.red.main,
    light: colors.red.light,
    lighter: colors.red.lighter,
    lightest: colors.red.lightest,
    contrastText: colors.white.darker,
  },
  warning: {
    darkest: colors.orange.darkest,
    darker: colors.orange.darker,
    dark: colors.orange.dark,
    main: colors.orange.main,
    light: colors.orange.light,
    lighter: colors.orange.lighter,
    lightest: colors.orange.lightest,
    contrastText: colors.white.main,
  },
  info: {
    main: colors.purple.lightest,
    contrastText: colors.blue.main,
  },
  divider: colors.purple.lightest,
  neutral: {
    darkest: colors.gray.darkest,
    darker: colors.gray.darker,
    dark: colors.gray.dark,
    main: colors.gray.main,
    light: colors.gray.light,
    lighter: colors.gray.lighter,
    lightest: colors.gray.lightest,
    contrastText: colors.white.darker,
  },
  attention: {
    darkest: colors.yellow.darkest,
    darker: colors.yellow.darker,
    dark: colors.yellow.dark,
    main: colors.yellow.main,
    light: colors.yellow.light,
    lighter: colors.yellow.lighter,
    lightest: colors.yellow.lightest,
    // Text color for light primary element
    text: colors.gray.darker,
    // Text color for dark primary element
    contrastText: colors.white.main,
  },
  background: {
    main: colors.white.main,
  },
  /* old code */
  inputLabel: {
    main: colors.gray.main,
  },
  note: {
    main: colors.gray.main,
  },
  picker: {
    color: colors.purple.main,
  },
  chart: {
    chart1: colors.blue.lightest,
    chart2: colors.green.main,
    chart3: colors.purple.lighter,
  },
  lots: {
    main: colors.gray.dark,
  },
};

export default palette;
