import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export const ALERT_VARIANTS = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};

export const ALERT_ICONS = {
  [ALERT_VARIANTS.SUCCESS]: CheckCircleIcon,
  [ALERT_VARIANTS.ERROR]: CancelIcon,
  [ALERT_VARIANTS.WARNING]: ErrorOutlineIcon,
  [ALERT_VARIANTS.INFO]: InfoOutlinedIcon,
};
