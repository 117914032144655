import { handleActions } from 'redux-actions';

import { cleanupBlockedUsers, getBlockedUsersFail, getBlockedUsersSuccess } from '../actions';

const defaultState = {
  items: [],
  requestPayload: null,
};

export default handleActions(
  {
    [getBlockedUsersSuccess](state, action) {
      return {
        ...state,
        items: action.response.data.blockedUsers,
        totalNumber: action.response.data.totalNumber,
        requestPayload: action.payload,
      };
    },
    [getBlockedUsersFail](state) {
      return state.totalNumber !== undefined
        ? state
        : {
            ...defaultState,
            totalNumber: 0,
          };
    },
    [cleanupBlockedUsers]() {
      return defaultState;
    },
  },
  defaultState,
);
