import { handleActions } from 'redux-actions';
import { getAccountsWithActiveTradesFail, getAccountsWithActiveTradesSuccess } from '../actions';

const defaultState = { accountsWithOpenedTrades: [], accountsWithPendingTrades: [] };

export default handleActions(
  {
    [getAccountsWithActiveTradesSuccess]: (state, action) => ({
      ...state,
      accountsWithOpenedTrades: action.response.data.accountsWithOpenedTradesList,
      accountsWithPendingTrades: action.response.data.accountsWithPendingTradesList,
    }),
    [getAccountsWithActiveTradesFail]: () => ({
      ...defaultState,
    }),
  },
  defaultState,
);
