import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    spacing: {
      margin: theme.spacing(1),
    },
    logo: {
      height: '48px',
    },
  }),
  { name: 'Headers' },
);

export default useStyles;
