import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'inline-flex',
      alignItems: 'center',
      ...theme.link.root,
      '&:hover': {
        ...theme.link.hover,
      },
      '&:active': {
        ...theme.link.active,
      },
    },
    smallSize: {
      ...theme.link.smallSize,
    },
    mediumSize: {
      ...theme.link.mediumSize,
    },
    largeSize: {
      ...theme.link.largeSize,
    },
    iconStart: {
      marginRight: theme.spacing(0.5),
    },
    iconEnd: {
      marginLeft: theme.spacing(0.5),
    },
  }),
  { name: 'TPLink' },
);

export default useStyles;
