import { createAction } from 'redux-actions';

export const getAutomatedApprovalsRequest = createAction('GET_AUTOMATED_APPROVALS_REQUEST');
export const getAutomatedApprovalsSuccess = createAction('GET_AUTOMATED_APPROVALS_SUCCESS');
export const getAutomatedApprovalsFail = createAction('GET_AUTOMATED_APPROVALS_FAIL');

export const generateAutomatedAprowalWithdrawalsRequest = createAction(
  'GENERATE_AUTOMATED_APROWAL_WITHDRAWALS_REQUEST',
);
export const generateAutomatedAprowalWithdrawalsSuccess = createAction(
  'GENERATE_AUTOMATED_APROWAL_WITHDRAWALS_SUCCESS',
);
export const generateAutomatedAprowalWithdrawalsFail = createAction(
  'GENERATE_AUTOMATED_APROWAL_WITHDRAWALS_FAIL',
);
