import React from 'react';
import TPGrid from 'components/TP-UI/TPGrid';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import TPLink from 'components/TP-UI/TPLink';
import TPTypography from 'components/TP-UI/TPTypography';
import useStyles from './styles';

const Links = () => {
  const classes = useStyles();
  return (
    <TPGrid container spacing={1} alignItems="flex-end">
      <TPTypography>Simple Link</TPTypography>
      <TPGrid item container xs={12} alignItems="center">
        <TPLink path="https://fusionmarkets.com/en" size="large" className={classes.spacing}>
          Large Size Link
        </TPLink>
        <TPLink path="https://fusionmarkets.com/en" size="medium" className={classes.spacing}>
          Medium Size Link
        </TPLink>
        <TPLink path="https://fusionmarkets.com/en" size="small" className={classes.spacing}>
          Small Size Link
        </TPLink>
      </TPGrid>
      <TPTypography>Link with Start Icon</TPTypography>
      <TPGrid item container xs={12} alignItems="center">
        <TPLink size="large" className={classes.spacing} iconComponent={InfoOutlined}>
          Large Size Link
        </TPLink>
        <TPLink size="medium" className={classes.spacing} iconComponent={InfoOutlined}>
          Medium Size Link
        </TPLink>
        <TPLink size="small" className={classes.spacing} iconComponent={InfoOutlined}>
          Small Size Link
        </TPLink>
      </TPGrid>
      <TPTypography>Link with End Icon</TPTypography>
      <TPGrid item container xs={12} alignItems="center">
        <TPLink
          size="large"
          className={classes.spacing}
          iconComponent={InfoOutlined}
          iconPosition="end">
          Large Size Link
        </TPLink>
        <TPLink
          size="medium"
          className={classes.spacing}
          iconComponent={InfoOutlined}
          iconPosition="end">
          Medium Size Link
        </TPLink>
        <TPLink
          size="small"
          className={classes.spacing}
          iconComponent={InfoOutlined}
          iconPosition="end">
          Small Size Link
        </TPLink>
      </TPGrid>
      <TPTypography>Content Link</TPTypography>
      <TPGrid item container xs={12} alignItems="center">
        <TPTypography className={classes.spacing}>
          Not a client yet? <TPLink path="/auth/sign-up">Sign Up</TPLink>
        </TPTypography>
      </TPGrid>
    </TPGrid>
  );
};

export default Links;
