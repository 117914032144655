import React, { Suspense, lazy, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getIsImpersonation } from 'modules/auth/selectors';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';
import { Routes } from 'constants/routeConstants';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import TPTypography from 'components/TP-UI/TPTypography';
import TPIconButton from 'components/TP-UI/TPIconButton';
import TPMenu from 'components/TP-UI/TPMenu';
import TPTooltip from 'components/TP-UI/TPTooltip';
import TPPageHeader from 'components/TP-UI/TPPageHeader';
import NotificationBell from 'modules/userNotifications/containers/NotificationBellContainer';
import InternalNotificationBell from 'modules/userNotifications/containers/InternalNotificationBellContainer';
import { HEADER_ITEM_ID } from '../../constants';
import useStyles from './styles';

const LanguageToggleContainer = lazy(() =>
  import('modules/common/LanguageToggle/containers/LanguageToggleContainer'),
);

const Header = ({
  breadcrumbsRoutes,
  isAuHub,
  isClient,
  mobile,
  showActions,
  userName,
  email,
  onDrawerClose,
  onLogoutClick,
  onLogoutAsCurrentUser,
  titleClassName,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation('menu');
  const isImpersonation = useSelector(getIsImpersonation);

  const menuItems = useMemo(() => {
    const items = [
      {
        id: 'logout-muneItem',
        label: t('navigation.logOut'),
        onClick: onLogoutClick,
      },
    ];

    if (isImpersonation) {
      items.unshift({
        id: 'logout-as-current-user',
        label: t('navigation.logoutAsCurrentUser'),
        onClick: onLogoutAsCurrentUser,
      });
    }

    if (isClient) {
      items.unshift(
        {
          id: 'profile-muneItem',
          label: t('category.profile'),
          path: Routes.PROFILE,
          onClick: onDrawerClose,
        },
        {
          id: 'contactUs-muneItem',
          label: t('category.contactUs'),
          path: Routes.CONTACT_US,
          onClick: onDrawerClose,
        },
      );
    }

    return items;
  }, [isClient, isImpersonation, t, onDrawerClose, onLogoutClick, onLogoutAsCurrentUser]);

  return (
    <TPPageHeader
      breadcrumbsRoutes={breadcrumbsRoutes}
      minimized={mobile}
      reservedBreadcrumbsSpace={!mobile}
      titleClassName={titleClassName}
      actions={
        !mobile || showActions ? (
          <>
            {!isAuHub && isClient ? (
              <Suspense fallback="">
                <TPTooltip
                  content={t('common:labels.changeLanguage')}
                  disabled={isTablet || isMobile}>
                  <LanguageToggleContainer />
                </TPTooltip>
              </Suspense>
            ) : null}
            {isClient ? <NotificationBell /> : <InternalNotificationBell onClick={onDrawerClose} />}
            <TPMenu
              header={
                <div className={classes.menuHeader}>
                  <TPTypography variant="body2">{userName ? userName : email}</TPTypography>
                  {userName ? (
                    <TPTypography variant="body3" color="secondary">
                      {email}
                    </TPTypography>
                  ) : null}
                </div>
              }
              menuItems={menuItems}
              placement="bottom-end">
              <TPTooltip content={t('navigation.details')} disabled={isTablet || isMobile}>
                <TPIconButton color="primary" id={HEADER_ITEM_ID.PROFILE}>
                  {userName ? userName[0].toUpperCase() : <FaceOutlinedIcon fontSize="small" />}
                </TPIconButton>
              </TPTooltip>
            </TPMenu>
          </>
        ) : null
      }
      {...props}
    />
  );
};

Header.propTypes = {
  breadcrumbsRoutes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
    }),
  ),
  isAuHub: PropTypes.bool,
  isClient: PropTypes.bool,
  mobile: PropTypes.bool,
  showActions: PropTypes.bool,
  userName: PropTypes.string,
  email: PropTypes.string.isRequired,
  onDrawerClose: PropTypes.func,
  onLogoutClick: PropTypes.func.isRequired,
  onLogoutAsCurrentUser: PropTypes.func.isRequired,
  titleClassName: PropTypes.string,
};

export default Header;
