const styles = () => ({
  formContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  jumperLabel: {
    color: '#888EB4',
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '17px',
    marginRight: 15,
  },
  jumpButton: {
    color: '#7777C1',
    fontSize: '14px',
    lineHeight: '17px',
    cursor: 'pointer',
    '&:hover': {
      color: '#2727C5',
    },
    background: 'none',
    border: 'none',
    padding: 0,
    font: 'inherit',
    outline: 'inherit',
    marginLeft: 10,
  },
  pageNumberField: {
    width: 32,
    height: 24,
    maxHeight: 34,
    marginBottom: 0,
  },
  input: {
    padding: 0,
    paddingLeft: 8,
    paddingRight: 4,
  },
  textFieldWrapper: {
    width: 'auto',
    paddingBottom: 0,
  },
});

export default styles;
