import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = '';

export default handleActions(
  {
    [actionCreators.getEconomicCalendarSuccess](state, action) {
      return { ...state, economicCalendar: action.response.data };
    },
    [actionCreators.getEconomicCalendarFail](state) {
      return { ...state, economicCalendar: defaultState };
    },
    [actionCreators.getEconomicCalendarPublicSuccess](state, action) {
      return { ...state, economicCalendarPublic: action.response.data };
    },
    [actionCreators.getEconomicCalendarPublicFail](state) {
      return { ...state, economicCalendarPublic: defaultState };
    },
    [actionCreators.getTechnicalInsightSuccess](state, action) {
      return { ...state, technicalInsight: action.response.data };
    },
    [actionCreators.getTechnicalInsightFail](state) {
      return { ...state, technicalInsight: defaultState };
    },
  },
  defaultState,
);
