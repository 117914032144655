import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      width: 'fit-content',
      padding: theme.spacing(2),
      overflow: 'visible',
    },
    clickable: {
      cursor: 'pointer',
    },
    disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
    cardLoader: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyItems: 'center',
    },
    fullWidth: {
      width: '100%',
      boxSizing: 'border-box',
    },
    stretch: {
      flex: 1,
      height: '100%',
      width: '100%',
      boxSizing: 'border-box',
    },
    selected: {
      ...theme.card.selected,
    },
  }),
  { name: 'TPCard' },
);
