import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Routes } from 'constants/routeConstants';
import TPLink from 'components/TP-UI/TPLink';
import TPFlagIcon from 'components/TP-UI/TPPhoneTextField/components/TPFlagIcon';
import TPTooltip from 'components/TP-UI/TPTooltip';
import Table from 'modules/common/Table';
import TPCard from 'components/TP-UI/TPCard';
import TPCardTitle from 'components/TP-UI/TPCard/components/TPCardTitle';
import TPCardContent from 'components/TP-UI/TPCard/components/TPCardContent';
import TPGrid from 'components/TP-UI/TPGrid';
import TPCardHeader from 'components/TP-UI/TPCard/components/TPCardHeader';
import { INITIAL_VOLUMES_FILTERS_VALUES, VOLUMES_ADDITIONAL_FIELDS } from '../../constants';
import useDashboardFilterFields from '../../hooks/useDashboardFilterFields';
import HorizontalBar from '../../components/HorizontalBar';
import { getUrlWithParams } from 'helpers/router';
import TPTypography from 'components/TP-UI/TPTypography';

import useStyles from './styles';

const VolumeUsersView = ({ form, loading, items, size, onChange, onDownloadCSV }) => {
  const classes = useStyles();
  const fields = useDashboardFilterFields({ form, additionalFilters: VOLUMES_ADDITIONAL_FIELDS });

  const filters = useMemo(
    () => ({
      fields: fields,
      form,
      initialValues: INITIAL_VOLUMES_FILTERS_VALUES,
      maxPrimaryFilters: 4,
    }),
    [form, fields],
  );

  const fileActions = useMemo(
    () => [
      {
        label: 'Download CSV',
        onClick: onDownloadCSV,
      },
    ],
    [onDownloadCSV],
  );

  return (
    <Table
      items={items}
      view="custom"
      filters={filters}
      fileActions={fileActions}
      loading={loading}
      onChange={onChange}
      scrollable={false}
      noMatchesText="No registered users for this time period"
      hidePagination>
      <TPGrid container spacing={2}>
        <TPGrid item xs={12} md={6}>
          <TPCard stretch>
            <TPCardHeader>
              <TPCardTitle>Top {size} users by volume traded</TPCardTitle>
            </TPCardHeader>
            <TPCardContent>
              <TPGrid container direction="column" spacing={2} alignItems="flex-start">
                {items?.map((item, index) => (
                  <TPGrid item container spacing={1} alignItems="center" key={index}>
                    <TPGrid item className={classes.index}>
                      <TPTypography>{`${index + 1}.`}</TPTypography>
                    </TPGrid>
                    <TPGrid item>
                      <TPTypography>
                        <TPLink
                          path={getUrlWithParams(Routes.USER_MANAGEMENT_DETAILS_ID, {
                            id: item.userId,
                          })}>
                          {`${item.name} `}
                        </TPLink>
                      </TPTypography>
                    </TPGrid>
                    <TPGrid item className={classes.volume}>
                      <TPTypography bold>${item.volume}M</TPTypography>
                    </TPGrid>
                    <TPGrid item>
                      <TPTooltip content={item.country?.name} className={classes.country}>
                        <TPFlagIcon label={item.country?.code} countryCode={item.country?.code} />
                      </TPTooltip>
                    </TPGrid>
                  </TPGrid>
                ))}
              </TPGrid>
            </TPCardContent>
          </TPCard>
        </TPGrid>
        <TPGrid item xs={12} md={6}>
          <TPCard stretch>
            <TPCardHeader>
              <TPCardTitle>Volume</TPCardTitle>
            </TPCardHeader>
            <TPCardContent>
              <HorizontalBar
                items={items}
                labelKey="name"
                dataKey="volume"
                label="Volume ($ million)"
              />
            </TPCardContent>
          </TPCard>
        </TPGrid>
      </TPGrid>
    </Table>
  );
};

VolumeUsersView.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.array,
  form: PropTypes.string,
  size: PropTypes.number,
  onChange: PropTypes.func,
  onDownloadCSV: PropTypes.func,
};

export default VolumeUsersView;
