import { all, put, takeEvery, select } from 'redux-saga/effects';
import {
  getDashboardOverviewDataRequest,
  getDashboardOverviewDataFail,
  getDashboardStatsCustomizationDataSuccess,
  getDashboardStatsCustomizationDataFail,
  saveDashboardStatsCustomizationDataSuccess,
  saveDashboardStatsCustomizationDataFail,
  getDashboardGraphDataRequest,
  getActiveUsersGraphDataFail,
  getDashboardComparisonGraphDataRequest,
  getDashboardTotalAndGraphDataFail,
} from '../actions';
import { change } from 'redux-form';
import { DASHBOARD_STATS_CUSTOMIZATION_FORM, listErrorsNotNotification } from '../constants';
import {
  getDashboardDateFrom,
  getDashboardDateTo,
  getDashboardDateFromCompare,
  getDashboardDateToCompare,
  getDashboardShouldCompareTo,
} from '../selectors';
import * as notificationsActions from '../../notifications/actions';
import get from 'lodash/get';
import { showNotificationError } from '../../notifications/actions';

function* handleDashboardStatsCustomizationSuccess(action) {
  const dateFrom = yield select(getDashboardDateFrom);
  const dateTo = yield select(getDashboardDateTo);
  const shouldCompare = yield select(getDashboardShouldCompareTo);
  const asUser = get(action, 'response.data.metrics.asUser');

  let payload = {
    dateFrom,
    dateTo,
    asUser,
  };

  if (shouldCompare) {
    const dateFromCompare = yield select(getDashboardDateFromCompare);
    const dateToCompare = yield select(getDashboardDateToCompare);

    payload.dateFromCompare = dateFromCompare;
    payload.dateToCompare = dateToCompare;

    yield put(
      getDashboardComparisonGraphDataRequest({
        dateFrom: dateFromCompare,
        dateTo: dateToCompare,
        asUser: asUser,
      }),
    );
  }

  yield put(getDashboardOverviewDataRequest(payload));
  yield put(getDashboardGraphDataRequest({ dateFrom, dateTo, asUser }));

  const { metrics } = action.response.data;
  yield all(
    Object.keys(metrics).map((key) =>
      put(change(DASHBOARD_STATS_CUSTOMIZATION_FORM, key, metrics[key])),
    ),
  );
}

function* showErrorNotification() {
  yield put(notificationsActions.showNotificationError('Something went wrong. Please try again.'));
}

function* handleGetDashboardStatsCustomizationDataFail(action) {
  const errorStatus = get(action, 'error.data.index');

  if (!listErrorsNotNotification.includes(errorStatus)) {
    const errorMessage = get(
      action,
      'error.data.message',
      'Something went wrong. Please try again.',
    );
    yield put(showNotificationError(errorMessage));
  }
}

function* watchSaga() {
  yield all([
    takeEvery(getDashboardStatsCustomizationDataSuccess, handleDashboardStatsCustomizationSuccess),
    takeEvery(saveDashboardStatsCustomizationDataSuccess, handleDashboardStatsCustomizationSuccess),
    takeEvery(getDashboardOverviewDataFail, showErrorNotification),
    takeEvery(getDashboardStatsCustomizationDataFail, handleGetDashboardStatsCustomizationDataFail),
    takeEvery(saveDashboardStatsCustomizationDataFail, showErrorNotification),
    takeEvery(getActiveUsersGraphDataFail, showErrorNotification),
    takeEvery(getDashboardTotalAndGraphDataFail, showErrorNotification),
  ]);
}

export default watchSaga;
