import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLpoaInfo } from 'modules/lpoa/selectors';
import { getLpoaRequest, signLpoaRequest } from 'modules/lpoa/actions';
import { closeModal } from 'components/TP-UI/TPModal/actions';
import * as apiSelectors from 'modules/api/selectors';
import { LPOA_DOCUMENT_MODAL, LPOA_DOCUMENT_FORM } from '../constants';
import { reset } from 'redux-form';
import * as authSelectors from 'modules/auth/selectors';
import * as accountsSelectors from 'modules/accounts/pages/MyAccounts/selectors';
import LpoaModal from '../components/LpoaModal';

const LpoaModalContainer = () => {
  const dispatch = useDispatch();

  const lpoaInfo = useSelector(getLpoaInfo);

  const signLpoaLoading = useSelector((state) =>
    apiSelectors.createPendingSelector(signLpoaRequest)(state),
  );
  const lpoaLoading = useSelector((state) =>
    apiSelectors.createPendingSelector(getLpoaRequest)(state),
  );

  const username = useSelector((state) => authSelectors.getUserName(state));
  const fullName = useSelector((state) => authSelectors.getUserFullName(state));
  const accounts = useSelector((state) => accountsSelectors.getLiveAccountsAsOptions(state));
  const authenticated = useSelector(authSelectors.getIsAuthenticated);

  useEffect(() => {
    if (authenticated) {
      dispatch(getLpoaRequest());
    }
  }, [dispatch, authenticated]);

  const handleSign = useCallback(
    (formData) => {
      dispatch(
        signLpoaRequest({
          sign: formData.sign,
          userAccount: formData.userAccount,
        }),
      );
    },
    [dispatch],
  );

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal(LPOA_DOCUMENT_MODAL));
    dispatch(reset(LPOA_DOCUMENT_FORM));
  }, [dispatch]);

  const listAccounts = useMemo(
    () =>
      accounts.map((account) => ({
        label: account.mt4login,
        value: account.mt4login,
      })),
    [accounts],
  );

  return (
    <LpoaModal
      form={LPOA_DOCUMENT_FORM}
      modalName={LPOA_DOCUMENT_MODAL}
      lpoaInfo={lpoaInfo}
      loading={lpoaLoading || signLpoaLoading}
      username={username}
      fullName={fullName}
      accounts={listAccounts}
      onSubmit={handleSign}
      onCancel={handleCloseModal}
    />
  );
};

export default LpoaModalContainer;
