import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import TPModal from 'components/TP-UI/TPModal';
import { closeModal } from 'components/TP-UI/TPModal/actions';
import TPLink from 'components/TP-UI/TPLink';
import { getUserHub, getUserPassedQuiz } from 'modules/auth/selectors';
import { Routes } from 'constants/routeConstants';
import { FUSION_MARKETS } from 'constants/index';
import verificationModalNames from '../../constants/verificationModalNames';
import InfoTip from 'modules/common/InfoTip';
import TPTypography from 'components/TP-UI/TPTypography';
import useQuiz from 'hooks/useQuiz';

const AwaitingApprovalModal = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const { t } = useTranslation('profile');
  const startQuiz = useQuiz();

  const hub = useSelector(getUserHub);
  const isQuizPassed = useSelector(getUserPassedQuiz);

  const isQuizRequired = hub === FUSION_MARKETS && !isQuizPassed;

  const handleClose = useCallback(
    (e) => {
      dispatch(closeModal(verificationModalNames.VERIFICATION_AWAITING_MODAL));
      if (e) {
        startQuiz(e);
      }
    },
    [dispatch, startQuiz],
  );

  const onFinish = useCallback(() => {
    handleClose();
    history.push(Routes.MY_ACCOUNTS);
  }, [history, handleClose]);

  const buttons = useMemo(
    () => [
      {
        label: t('common:buttons.gotIt'),
        primary: true,
        onClick: onFinish,
        // DO NOT REMOVE! id="verification-got-it-button" is a conversion tracking key
        id: 'verification-got-it-button',
      },
    ],
    [t, onFinish],
  );

  return (
    <TPModal
      title={t('verification.notes.verificationAwaiting')}
      modalName={verificationModalNames.VERIFICATION_AWAITING_MODAL}
      buttons={buttons}
      onClose={onFinish}>
      <TPTypography variant="body1">
        {isQuizRequired ? (
          <>
            <Trans i18nKey="verification.notes.smallQuiz" ns="profile">
              While our team is busy approving your account, before we allow you to trade, we need
              you to pass a small 2 minute
              <TPLink path={Routes.MY_ACCOUNTS} onClick={handleClose}>
                quiz
              </TPLink>
              so that we can assess whether these products are right for you.
            </Trans>
            <InfoTip
              text={t('common:whyDoWeAsk.title')}
              tooltipContent={t('common:whyDoWeAsk.messages.quide227')}
            />
          </>
        ) : (
          t('verification.notes.verificationProcess')
        )}
      </TPTypography>
    </TPModal>
  );
};

export default AwaitingApprovalModal;
