import { handleActions } from 'redux-actions';
import * as actions from '../actions';

const defaultState = {
  items: [],
  requestPayload: null,
};

export default handleActions(
  {
    [actions.getUserSecurityLogsRequest]: (state, action) => {
      return !state.requestPayload || action.payload.userId === state.requestPayload.userId
        ? { ...state }
        : {
            items: [],
            requestPayload: action.payload,
          };
    },
    [actions.getUserSecurityLogsSuccess]: (state, action) => {
      return {
        ...state,
        items: action.response.data.securityLogs,
        totalNumber: action.response.data.totalNumber,
        requestPayload: action.payload,
      };
    },
    [actions.cleanupUserSecurityLogs]: () => {
      return defaultState;
    },
  },
  defaultState,
);
