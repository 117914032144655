import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    wrapper: {
      ...theme.cookieBanner.root,
      position: 'fixed',
      width: '100%',
      padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
      bottom: 0,
    },
    policyText: {
      ...theme.cookieBanner.content,
    },
    policyLinkText: {
      ...theme.cookieBanner.link,
    },
    closeButton: {
      ...theme.cookieBanner.button,
    },
  }),
  { name: 'CookieBanner' },
);
