import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    layout: {
      margin: theme.spacing(1),
    },
    section: { marginTop: theme.spacing(2) },
  }),
  { name: 'Typography' },
);

export default useStyles;
