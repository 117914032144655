import { createSelector } from 'reselect';
import {
  getIsAllowedUpgradeToPro,
  allowCopyAccount,
  getIsFusionPlus,
  getIsCountryManager,
  getIsAdminRole,
  getAllowReduceFees,
  getIsAuthenticated,
  getUserRole,
  getUserHub,
  getUserEmail,
} from '../../auth/selectors';
import { getAvailableMenuItems, getAvailableRoutesByPermissions } from '../helpers';
import { getAllRoutes } from 'components/App/utils/routes';
import { MENU_ITEMS } from '../constants/menuItems';
import { cloneDeep } from 'lodash';

export const getAvailableRoutes = createSelector(
  getIsAuthenticated,
  getUserRole,
  getUserHub,
  getIsCountryManager,
  getIsAdminRole,
  getUserEmail,
  allowCopyAccount,
  getIsFusionPlus,
  getIsAllowedUpgradeToPro,
  getAllowReduceFees,
  (
    authenticated,
    userRole,
    hub,
    isCountryManager,
    isAdmin,
    email,
    allowCopyAccount,
    joinFusionPlus,
    allowUpgradeToPro,
    allowReduceFees,
  ) => {
    const routes = getAllRoutes({
      isAdmin,
      isCountryManager,
      allowCopyAccount,
      joinFusionPlus,
      allowUpgradeToPro,
      allowReduceFees,
    });

    return getAvailableRoutesByPermissions({
      authenticated,
      userRole,
      hub,
      email,
      routes,
    });
  },
);

export const getMenuItems = createSelector(getAvailableRoutes, (routes) => {
  let items = cloneDeep(MENU_ITEMS);
  return getAvailableMenuItems({
    items,
    routes,
  });
});
