import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Routes } from 'constants/routeConstants';
import { ACCOUNT_SERVERS } from 'constants/index';
import { VerificationStatuses } from 'constants/verificationStatuses';
import SortAccounts from '../SortAccounts';
import TPButton from 'components/TP-UI/TPButton';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import TPAlert from 'components/TP-UI/TPAlert';
import TPLink from 'components/TP-UI/TPLink';
import TPGrid from 'components/TP-UI/TPGrid';
import InfoTip from 'modules/common/InfoTip';
import NoAccounts from '../NoAccounts';
import HiddenAccountsMessage from '../HiddenAccountsMessage';
import { isAllAccountsHidden } from '../../helpers';
import useQuiz from 'hooks/useQuiz';

const Accounts = ({
  accountType,
  isQuizRequired = false,
  isPro = false,
  accounts = [],
  isAccountsGetPending = false,
  isLead2,
  isLead3,
  isLead4,
  wasRejected,
  userStatus,
  showHidden,
  hideAccountLoading,
  showLeverage = false,
  followerId,
  userIsVerified = false,
  hub,
  actions,
  onSortAccounts,
  onUndoArchived,
  onCreateAccount,
  onCompleteVerification,
  onMyPerformanceView,
  onAcceptInvitation,
  onFund,
  onLiveFund,
  disableAccountsCreation,
  isBannedCountry,
  userAllowedAccountCreation,
  canUserCreateAccounts,
  tradeSyncAccounts,
}) => {
  const { t } = useTranslation('accounts');

  const showHiddenAccountMessage = useMemo(
    () => !isAccountsGetPending && isAllAccountsHidden(showHidden, accounts),
    [isAccountsGetPending, showHidden, accounts],
  );

  const verificationRejected = userStatus === VerificationStatuses.REJECTED;

  const startQuiz = useQuiz();

  return (
    <>
      {!isAccountsGetPending && accounts.length ? (
        <TPGrid container spacing={3} direction="column">
          {isQuizRequired && !isPro && accountType === ACCOUNT_SERVERS.LIVE && (
            <TPGrid item>
              <TPAlert variant="info">
                <TPGrid container spacing={2} justifyContent="flex-end">
                  <TPGrid item xs={12} md>
                    <Trans i18nKey="page.note" ns="accounts">
                      Before you can start trading, you need to pass a
                      <TPLink onClick={startQuiz} path={Routes.MY_ACCOUNTS}>
                        required quick quiz
                      </TPLink>
                    </Trans>
                    {verificationRejected ? null : <>&nbsp; {t('page.nodeFund')}</>}
                  </TPGrid>
                  {verificationRejected ? null : (
                    <TPGrid item>
                      <InfoTip
                        text={t('common:whyDoWeAsk.title')}
                        tooltipContent={t('common:whyDoWeAsk.messages.quide227')}
                      />
                    </TPGrid>
                  )}
                </TPGrid>
              </TPAlert>
            </TPGrid>
          )}
          <TPGrid item container justifyContent="flex-end">
            <TPGrid item>
              <TPButton onClick={actions.toggleShowHidden} secondary>
                {showHidden ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}&nbsp;
                {t(`showHidden.${!!showHidden}`)}
              </TPButton>
            </TPGrid>
          </TPGrid>
          <TPGrid item>
            <SortAccounts
              onSort={onSortAccounts}
              showHidden={showHidden}
              accounts={accounts}
              actions={actions}
              onMyPerformanceView={onMyPerformanceView}
              hideAccountLoading={hideAccountLoading}
              isQuizRequired={isQuizRequired}
              isLead2={isLead2}
              isLead3={isLead3}
              isLead4={isLead4}
              onCreateAccount={onCreateAccount}
              onLiveFund={onLiveFund}
              showFundDemoAccountModal={onFund}
              wasRejected={wasRejected}
              userStatus={userStatus}
              showLeverage={showLeverage}
              userIsVerified={userIsVerified}
              followerId={followerId}
              onAcceptInvitation={onAcceptInvitation}
              hub={hub}
              onUndoArchived={onUndoArchived}
              canUserCreateAccounts={canUserCreateAccounts}
              tradeSyncAccounts={tradeSyncAccounts}
            />
          </TPGrid>
        </TPGrid>
      ) : null}
      {showHiddenAccountMessage ? <HiddenAccountsMessage /> : null}
      {!isAccountsGetPending && !accounts.length ? (
        <NoAccounts
          accountType={accountType}
          isQuizRequired={isQuizRequired}
          onCreateAccount={onCreateAccount}
          onCompleteVerification={onCompleteVerification}
          newUser={userStatus === VerificationStatuses.NEW}
          disableAccountsCreation={disableAccountsCreation}
          isBannedCountry={isBannedCountry}
          userAllowedAccountCreation={userAllowedAccountCreation}
        />
      ) : null}
    </>
  );
};

Accounts.propTypes = {
  accountType: PropTypes.oneOf([
    ACCOUNT_SERVERS.LIVE,
    ACCOUNT_SERVERS.DEMO,
    ACCOUNT_SERVERS.REBATE,
    ACCOUNT_SERVERS.ATW,
  ]).isRequired,
  isQuizRequired: PropTypes.bool,
  isPro: PropTypes.bool,
  accounts: PropTypes.array,
  isAccountsGetPending: PropTypes.bool,
  actions: PropTypes.object.isRequired,
  isLead2: PropTypes.bool.isRequired,
  isLead3: PropTypes.bool.isRequired,
  isLead4: PropTypes.bool.isRequired,
  wasRejected: PropTypes.bool.isRequired,
  userStatus: PropTypes.string.isRequired,
  hub: PropTypes.string.isRequired,
  showHidden: PropTypes.bool,
  hideAccountLoading: PropTypes.bool,
  showLeverage: PropTypes.bool,
  followerId: PropTypes.string,
  userIsVerified: PropTypes.bool,
  onSortAccounts: PropTypes.func.isRequired,
  onUndoArchived: PropTypes.func.isRequired,
  onCreateAccount: PropTypes.func.isRequired,
  onCompleteVerification: PropTypes.func.isRequired,
  onMyPerformanceView: PropTypes.func.isRequired,
  onAcceptInvitation: PropTypes.func.isRequired,
  onLiveFund: PropTypes.func.isRequired,
  onFund: PropTypes.func.isRequired,
  disableAccountsCreation: PropTypes.bool,
  canUserCreateAccounts: PropTypes.bool,
  tradeSyncAccounts: PropTypes.array,
};

export default Accounts;
