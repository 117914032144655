import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'modules/reduxNavigation/actions';
import { withRouter } from 'react-router-dom';

import MyAccounts from '../components/MyAccounts';
import * as selectors from '../selectors';
import * as actionCreators from '../actions';
import * as TMDActionCreators from 'modules/common/TMD/actions';
import * as authSelectors from 'modules/auth/selectors';
import * as apiSelectors from 'modules/api/selectors';
import queryString from 'query-string';
import { ACCOUNT_SERVERS } from 'constants/index';
import { getProStep, getUserHub, getIsFusionPlus } from 'modules/auth/selectors';
import * as myFollowersActions from '../../../../myFollowers/actions';
import { DEPOSIT_PATH } from 'constants/routeConstants';

class MyAccountsContainer extends PureComponent {
  componentDidMount() {
    const payload = {};
    const query = queryString.parse(this.props.location.search || '');
    const activeTab = this.props.location.state && this.props.location.state.activeTab;

    if (query.tab === ACCOUNT_SERVERS.DEMO) {
      payload.activeTab = ACCOUNT_SERVERS.DEMO;
    }
    if (activeTab) {
      payload.activeTab = activeTab;
    }
    this.props.actions.accountsPageOpened(payload);
    this.props.actions.getAccountsRequest({ isLive: false });
  }

  componentWillUnmount() {
    const {
      actions: { accountsHideDialog },
    } = this.props;
    accountsHideDialog();
  }

  handleTabChange = (tabValue) => {
    if (!this.props.isAccountsGetPending) {
      this.props.actions.accountsTabChanged(tabValue);
    }
  };

  handleLiveFund = (account) => {
    const { push } = this.props;
    push(DEPOSIT_PATH + `?accountId=${account._id}`);
  };

  handleUndoArchived = (e, accountId) => {
    const {
      actions: { unArchiveAccountModal },
    } = this.props;
    unArchiveAccountModal(accountId);
    e.preventDefault();
  };

  render() {
    const props = {
      onTabChange: this.handleTabChange,
      onLiveFund: this.handleLiveFund,
      activeDialog: this.props.activeDialog,
      demoAccounts: this.demoAccounts,
      isAccountsGetPending: this.props.isAccountsGetPending,
      selectedTab: this.props.activeAccountsTab,
      accounts: this.props.accounts,
      actions: this.props.actions,
      isQuizPassed: this.props.isQuizPassed,
      userName: this.props.userName,
      rebateAccountsLength: this.props.rebateAccountsLength,
      isLead2: this.props.isLead2,
      isLead3: this.props.isLead3,
      isLead4: this.props.isLead4,
      wasRejected: this.props.wasRejected,
      userStatus: this.props.userStatus,
      showHidden: this.props.showHidden,
      hideAccountId: this.props.hideAccountId,
      hideAccountIsHidden: this.props.hideAccountIsHidden,
      hideAccountLoading: this.props.hideAccountLoading,
      showLeverage: this.props.showLeverage,
      followerId: this.props.followerId,
      userIsVerified: this.props.userIsVerified,
      hub: this.props.hub,
      isFusionPlus: this.props.isFusionPlus,
      myFollowersActions: this.props.myFollowersActions,
      isJoinPlusPending: this.props.isJoinPlusPending,
      haveAwtAccounts: this.props.haveAwtAccounts,
      onUndoArchived: this.handleUndoArchived,
      selectedLanguage: this.props.selectedLanguage,
      userAllowedAccountCreation: this.props.userAllowedAccountCreation,
      disableAccountsCreation: this.props.userCountry === 'Japan',
      isBannedCountry: this.props.isBannedCountry,
      isTradingDisabled: this.props.isTradingDisabled,
      canUserCreateAccounts: this.props.canUserCreateAccounts,
    };
    return <MyAccounts {...props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    activeAccountsTab: selectors.getActiveAccountsTab(state),
    accounts: selectors.getAccountsByActiveTab(state),
    demoAccounts: selectors.getDemoAccounts(state),
    activeDialog: selectors.getActiveDialog(state),
    showHidden: selectors.showHidden(state),
    hideAccountId: selectors.hideAccountId(state),
    hideAccountIsHidden: selectors.hideAccountIsHidden(state),
    isAccountsGetPending: apiSelectors.createPendingSelector(actionCreators.getAccountsRequest)(
      state,
    ),
    userName: authSelectors.getUserName(state),
    rebateAccountsLength: selectors.getRebateAccountsLength(state),
    isLead2: authSelectors.getUserIsLead2(state),
    isLead3: authSelectors.getUserIsLead3(state),
    isLead4: authSelectors.getUserIsLead4(state),
    wasRejected: authSelectors.getWasRejected(state),
    userStatus: authSelectors.getUserStatus(state),
    haveAwtAccounts: authSelectors.getHaveAwtAccounts(state),
    hideAccountLoading: apiSelectors.createPendingSelector(actionCreators.hideAccountRequest)(
      state,
    ),
    showLeverage: authSelectors.showLeverage(state),
    followerId: authSelectors.getUserFollowerId(state),
    userIsVerified: authSelectors.getUserIsVerified(state),
    hub: getUserHub(state),
    proStep: getProStep(state),
    isFusionPlus: getIsFusionPlus(state),
    isJoinPlusPending: apiSelectors.createPendingSelector(myFollowersActions.joinFusionPlusRequest)(
      state,
    ),
    userAllowedAccountCreation: authSelectors.getUserAllowedAccountCreation(state),
    userCountry: authSelectors.getUserCountry(state),
    isBannedCountry: authSelectors.isBannedCountry(state),
    isTradingDisabled: authSelectors.getIsTradingDisabled(state),
    canUserCreateAccounts: authSelectors.canUserCreateAccounts(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    TMDActions: bindActionCreators(TMDActionCreators, dispatch),
    myFollowersActions: bindActionCreators(myFollowersActions, dispatch),
    push: bindActionCreators(push, dispatch),
  };
};

MyAccountsContainer.propTypes = {
  push: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  activeAccountsTab: PropTypes.string.isRequired,
  accounts: PropTypes.array.isRequired,
  activeDialog: PropTypes.string.isRequired,
  isAccountsGetPending: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  isQuizRequired: PropTypes.bool,
  isQuizPassed: PropTypes.bool,
  isLead2: PropTypes.bool.isRequired,
  isLead3: PropTypes.bool.isRequired,
  isLead4: PropTypes.bool.isRequired,
  hub: PropTypes.string.isRequired,
  proStep: PropTypes.number,
  selectedLanguage: PropTypes.string,
  userAllowedAccountCreation: PropTypes.bool,
  userCountry: PropTypes.string,
  isBannedCountry: PropTypes.bool,
  isTradingDisabled: PropTypes.bool,
  canUserCreateAccounts: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyAccountsContainer));
