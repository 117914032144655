import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import TPTypography from 'components/TP-UI/TPTypography';
import useStyles from './styles';

const TPProgressBar = ({
  primary,
  progress = 0,
  label = '',
  labelColor = 'secondary',
  labelVariant = 'subtitle2',
  thickness = 4,
  circle = false,
  diameter = 72,
  success = false,
  warning = false,
  error = false,
  ...props
}) => {
  const classes = useStyles();

  return circle ? (
    <div className={classes.circleWrapper} style={{ width: diameter }}>
      <CircularProgress
        variant="determinate"
        className={classes.circleTrack}
        size={diameter}
        thickness={thickness}
        value={100}
        {...props}
      />
      <CircularProgress
        variant="determinate"
        className={classnames(classes.circleBar, {
          [classes.circleBarSecondary]: !primary,
        })}
        size={diameter}
        thickness={thickness}
        value={progress}
        {...props}
      />
      <TPTypography
        component="p"
        variant={labelVariant}
        color={labelColor}
        className={classes.circleLabel}>
        {label}
      </TPTypography>
    </div>
  ) : (
    <>
      <LinearProgress
        variant="determinate"
        value={progress}
        className={classes.lineTrack}
        classes={{
          bar: classnames(classes.lineBar, {
            [classes.lineBarSecondary]: !primary,
            [classes.lineBarSuccess]: success,
            [classes.lineBarWarning]: warning,
            [classes.lineBarError]: error,
          }),
        }}
        style={{ height: thickness }}
        {...props}
      />
      <TPTypography
        component="p"
        variant={labelVariant}
        color={labelColor}
        className={classes.lineLabel}>
        {label}
      </TPTypography>
    </>
  );
};

TPProgressBar.propTypes = {
  /**
   * Primary variant of progress bar.
   */
  primary: PropTypes.bool,

  success: PropTypes.bool,
  warning: PropTypes.bool,
  error: PropTypes.bool,
  /**
   * Progress value in percentage to display indicator.
   */
  progress: PropTypes.number,
  /**
   * Label for progress indicator.
   */
  label: PropTypes.string,
  /**
   * Color of progress indicator.
   */
  labelColor: TPTypography.propTypes.color,
  /**
   * Variant of progress indicator.
   */
  labelVariant: TPTypography.propTypes.variant,
  /**
   * Progress line/circle width in pixels.
   */
  thickness: PropTypes.number,
  /**
   * If `true`, circle progress will be displayed.
   */
  circle: PropTypes.bool,
  /**
   * Circle size in pixels (height and width).
   */
  diameter: PropTypes.number,
};

export default TPProgressBar;
