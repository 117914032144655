import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import TPLink from 'components/TP-UI/TPLink';
import TPTypography from 'components/TP-UI/TPTypography';

const TPBreadcrumbs = ({ routes, ...props }) => (
  <Breadcrumbs separator="›" {...props}>
    {routes.map((route, index) =>
      index !== routes.length - 1 ? (
        <TPLink key={index} size="small" path={route.path}>
          {route.label}
        </TPLink>
      ) : (
        <TPTypography key={index} component="span" variant="subtitle2" color="secondary">
          {route.label}
        </TPTypography>
      ),
    )}
  </Breadcrumbs>
);

TPBreadcrumbs.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
    }),
  ).isRequired,
};

export default TPBreadcrumbs;
