import { fontSize } from '../font';
import palette from './palette';

const OverridesMuiFormLabel = {
  root: {
    color: palette.text.secondary,
    fontSize: fontSize._14,
    padding: '3px 0',

    '&$disabled': {
      opacity: '0.5',
    },

    '&$focused': {
      color: palette.text.secondary,
    },
  },
};

export default OverridesMuiFormLabel;
