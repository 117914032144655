import { createSelector } from 'reselect';

import { PAGINATION_BAR_PAGES_LIMIT, PAGINATION_FORM, DEFAULT_PAGE_LIMIT } from '../constants';
import countSkip from '../helpers/countSkip';
import fillPagesWithNumbers from '../helpers/fillPagesWithNumbers';
import { formSelector } from 'selectors';

export const getTotalNumbers = (state) => state.pagination.totalNumbers;
export const getCurrentPage = (state) => state.pagination.currentPage;
export const getCurrentTable = (state) => state.pagination.currentTable;
export const getSelectedPageSize = (state) =>
  formSelector(PAGINATION_FORM, state, 'pageSize') || DEFAULT_PAGE_LIMIT;

export const getPaginatedItemsTotalNumber = createSelector(
  getTotalNumbers,
  getCurrentTable,
  (totalNumbers, currentTable) => {
    return totalNumbers[currentTable] || 0;
  },
);

export const getTableTotalNumber = (state, tableName) => {
  const totalNumbers = getTotalNumbers(state);
  return totalNumbers[tableName] || 0;
};

export const getPagesNumber = createSelector(
  getPaginatedItemsTotalNumber,
  getSelectedPageSize,
  (totalNumber, pageSize) => {
    const pagesNumber = Math.ceil(Number.parseInt(totalNumber) / pageSize);
    return pagesNumber;
  },
);

export const getTotalNumberAsPages = createSelector(
  getPagesNumber,
  getCurrentPage,
  (pagesNumber, currentPage) => {
    const pages = [];
    if (pagesNumber > 0) {
      const bigPartSize = Math.floor(PAGINATION_BAR_PAGES_LIMIT / 2);
      const currentPageInFirstPart = currentPage < bigPartSize;
      const currentPageInLastPart = currentPage > pagesNumber - bigPartSize + 1;
      if (pagesNumber < PAGINATION_BAR_PAGES_LIMIT) {
        const firstPart = fillPagesWithNumbers(1, pagesNumber);
        pages.push(firstPart);
      } else if (currentPageInFirstPart || currentPageInLastPart) {
        const firstPart = fillPagesWithNumbers(1, bigPartSize);
        pages.push(firstPart);
        const secondPart = fillPagesWithNumbers(pagesNumber - bigPartSize + 1, pagesNumber);
        pages.push(secondPart);
      } else {
        const firstPart = [[1]];
        pages.push(firstPart);
        const thirdPart = [[pagesNumber]];
        const secondPart = fillPagesWithNumbers(
          currentPage - Math.floor(bigPartSize / 2),
          currentPage + Math.floor(bigPartSize / 2),
        );
        pages.push(secondPart);
        pages.push(thirdPart);
      }
    }
    return pages;
  },
);

export const getCurrentSkip = createSelector(
  getCurrentPage,
  getSelectedPageSize,
  (currentPage, pageSize) => {
    const skip = countSkip(currentPage, pageSize);
    return skip;
  },
);
