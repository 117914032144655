import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Accordion from '@material-ui/core/Accordion';
import TPLoader from '../TPLoader';
import useStyles from './styles';

const TPAccordion = ({
  variant = 'outlined',
  loading,
  disabled,
  fullWidth,
  onChange,
  defaultExpanded = false,
  elevation,
  className,
  children,
}) => {
  const classes = useStyles();

  return (
    <Accordion
      variant={variant === 'filled' ? 'elevation' : variant}
      defaultExpanded={defaultExpanded}
      elevation={elevation}
      className={classnames(classes.root, className, [
        {
          [classes.disabled]: disabled,
          [classes.loading]: loading,
          [classes.fullWidth]: fullWidth,
          [classes.elevation]: variant === 'elevation' && elevation === 0,
          [classes.filled]: variant === 'filled',
        },
      ])}
      classes={{
        rounded: classes.rounded,
        expanded: classes.expanded,
      }}
      onChange={onChange}>
      {children}
      {loading ? (
        <div className={classes.accordionLoader}>
          <TPLoader />
        </div>
      ) : null}
    </Accordion>
  );
};

TPAccordion.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  fullWidth: PropTypes.bool,
  variant: PropTypes.oneOf(['outlined', 'elevation', 'filled']),
  onChange: PropTypes.func,
  elevation: PropTypes.number,
};

TPAccordion.muiName = Accordion.muiName;

export default TPAccordion;
