import React, { createContext, useReducer, useCallback, useRef, useLayoutEffect } from 'react';
import TPSnackbar from 'components/TP-UI/TPSnackbar';
import { snackbarReducer, initialState } from 'components/TP-UI/TPSnackbar/reducers';
import { HIDE_SNACKBAR, UPDATE_OFFSETS } from 'components/TP-UI/TPSnackbar/actions';
import { GAP_BETWEEN_SNACKBARS } from 'components/TP-UI/TPSnackbar/context/constants';

export const SnackbarContext = createContext();

const SnackbarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(snackbarReducer, initialState);
  const snackbarRefs = useRef([]);

  const handleClose = useCallback(
    (event, reason, key) => {
      if (reason === 'clickaway') {
        return;
      }
      dispatch({ type: HIDE_SNACKBAR, key });
    },
    [dispatch],
  );

  useLayoutEffect(() => {
    let positionCumulativeHeights = { ...initialState.positionOffsets };
    let gapBetweenSnackbars = GAP_BETWEEN_SNACKBARS;
    let hasChanged = false;

    const updatedQueue = state.queue.map((snackbar, index) => {
      const positionKey = `${snackbar.anchorOrigin.vertical}-${snackbar.anchorOrigin.horizontal}`;
      const actualHeight = snackbarRefs.current[index]?.offsetHeight || 0;
      const newOffset = positionCumulativeHeights[positionKey];

      if (newOffset !== snackbar.offset) {
        hasChanged = true;
      }

      positionCumulativeHeights[positionKey] += actualHeight + gapBetweenSnackbars;

      return { ...snackbar, offset: newOffset };
    });

    if (hasChanged) {
      dispatch({ type: UPDATE_OFFSETS, updatedQueue });
    }
  }, [dispatch, state.queue]);

  return (
    <SnackbarContext.Provider value={{ dispatch }}>
      {children}
      {state.queue.map((snackbar, index) => {
        return (
          <TPSnackbar
            ref={(el) => (snackbarRefs.current[index] = el)}
            key={snackbar.key}
            {...snackbar}
            open
            onClose={(event, reason) => handleClose(event, reason, snackbar.key)}
          />
        );
      })}
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
