import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import TPTypography from '../TPTypography';
import Lightbox from 'lightbox-react';
import { getImageUrl, isCSV, isPDF } from '../helpers/file';
import TPLink from '../TPLink';

import 'lightbox-react/style.css';
import useStyles from './styles';
import downloadFile from 'utils/downloadFile';

const TPFilesPreviewModal = ({ value = 0, items = [], onClose, loadErrorMessage }) => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(value);
  const itemsLength = items.length;
  const currentItem = items[currentIndex];
  const imageUrl = useMemo(() => getImageUrl(currentItem?.url, currentItem.file), [currentItem]);
  useEffect(() => {
    setCurrentIndex(value);
  }, [value]);

  const handleDownloadFile = useCallback(
    async (e) => {
      e.preventDefault();
      if (imageUrl) {
        const response = await fetch(imageUrl);
        const data = await response.blob();
        downloadFile(
          data,
          currentItem?.originalName || currentItem?.name || currentItem?.file?.name,
        );
      }
    },
    [currentItem, imageUrl],
  );

  const handlePrevClick = useCallback(() => {
    if (imageUrl && typeof imageUrl === 'object') {
      // Revoke the data uris to avoid memory leaks
      URL.revokeObjectURL(imageUrl);
    }
    setCurrentIndex((val) => (val + itemsLength - 1) % itemsLength);
  }, [imageUrl, itemsLength]);

  const handleNextClick = useCallback(() => {
    if (imageUrl && typeof imageUrl === 'object') {
      // Revoke the data uris to avoid memory leaks
      URL.revokeObjectURL(imageUrl);
    }
    setCurrentIndex((val) => (val + 1) % itemsLength);
  }, [imageUrl, itemsLength]);

  const mainSrc = isPDF(currentItem?.url || currentItem?.file?.path || '') ? (
    <div className={classes.pdfPreview}>
      <object
        width="100%"
        height="100%"
        type="application/pdf"
        data={currentItem?.url || URL.createObjectURL(currentItem?.file)}>
        <TPTypography color="secondary" className={classes.warningMessage}>
          Unable to display PDF file.
          {currentItem?.url ? (
            <>
              Please download the PDF to view it:{' '}
              <TPLink target="_blank" path={currentItem.url}>
                Download PDF
              </TPLink>
            </>
          ) : null}
        </TPTypography>
      </object>
    </div>
  ) : isCSV(currentItem?.url || currentItem?.file?.path) ? (
    <TPTypography color="secondary" className={classes.warningMessage}>
      Unable to display CSV file.
      {currentItem?.url ? (
        <>
          Please download the CSV to view it:{' '}
          <TPLink target="_blank" path={currentItem.url}>
            Download CSV
          </TPLink>
        </>
      ) : null}
    </TPTypography>
  ) : (
    imageUrl || (
      <TPTypography color="secondary" className={classes.warningMessage}>
        Unable to display file.
      </TPTypography>
    )
  );
  const prev = items[(currentIndex + itemsLength - 1) % itemsLength];
  const next = items[(currentIndex + 1) % itemsLength];
  const styles = { overlay: { zIndex: 1900 } };

  return currentIndex >= 0 ? (
    <Lightbox
      imageCaption={currentItem?.originalName || currentItem?.file?.name || ''}
      mainSrc={mainSrc}
      enableZoom={!!imageUrl}
      prevSrc={prev?.url}
      nextSrc={next?.url}
      reactModalStyle={styles}
      onCloseRequest={onClose}
      onMovePrevRequest={handlePrevClick}
      onMoveNextRequest={handleNextClick}
      imageLoadErrorMessage={
        <TPTypography color="secondary">
          <>
            <div>{loadErrorMessage || 'Oops! Something went wrong!'}</div>
            <div>
              Please download the image to view it:{' '}
              <TPLink target="_blank" path={next?.url} onClick={handleDownloadFile}>
                Download
              </TPLink>
            </div>
          </>
        </TPTypography>
      }
    />
  ) : null;
};

TPFilesPreviewModal.propTypes = {
  value: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  loadErrorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default TPFilesPreviewModal;
