import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(0.25),
    },
    disabled: {
      opacity: 0.4,
    },
    iconFilled: {
      color: theme.palette.warning.main,
    },
    iconEmpty: {
      color: theme.palette.text.secondary,
    },
    iconHovered: {
      cursor: 'pointer',
      color: theme.palette.warning.main,
    },
  }),
  { name: 'TPRating' },
);
