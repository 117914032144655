import { combineActions, handleActions } from 'redux-actions';

import * as actionCreators from '../actions';
const defaultState = '';

export default handleActions(
  {
    [combineActions(
      actionCreators.paymentsDepositIsignthisSuccess,
      actionCreators.paymentsDepositZotapaySuccess,
      actionCreators.paymentsDepositPaymentAsiaSuccess,
      actionCreators.paymentsDepositPraxisSuccess,
    )](state, action) {
      return action.response.data.redirect_url || action.response.data.depositUrl;
    },
  },
  defaultState,
);
