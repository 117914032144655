import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    menuHeader: {
      padding: `${theme.spacing(2)}px 0`,
      borderBottom: `1px solid ${theme.palette.primary.lightest}`,
    },
  }),
  { name: 'ActionsContent' },
);

export default useStyles;
