import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    gotoContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 5,
    },
    gotoInput: {
      width: 45,
      marginLeft: theme.spacing(1),
    },
  }),
  { name: 'TPGoToPage' },
);
