import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { COLOR_TYPES, variantMapping } from './config';

import useStyles from './styles';
import classnames from 'classnames';
import TPTooltip from 'components/TP-UI/TPTooltip';

const TPTypography = ({
  variant = 'body1',
  color = 'primary',
  noWrap = false,
  truncated = false,
  lineClamp = 2,
  component,
  className,
  bold,
  children,
  ...props
}) => {
  const classes = useStyles();
  const ref = useRef();
  const [ellipsed, setEllipsed] = useState(false);
  const colorType = COLOR_TYPES[color];
  //avoid mui error since body3 is custom variant
  const variantType = variant === 'body3' || variant === 'inherit' ? null : variant;

  useEffect(() => {
    const el = ref.current;
    let observer;

    if (truncated && el) {
      if (window.ResizeObserver) {
        observer = new ResizeObserver((entries) => {
          const entry = entries[0]?.target;
          setEllipsed(entry.offsetHeight < entry.scrollHeight);
        });

        observer.observe(el);
      }
    }

    return () => {
      if (observer) {
        // Cleanup the observer by unobserving all elements
        observer.disconnect();
      }
    };
  }, [ref, truncated]);

  let Tag = React.Fragment;
  let wrapperProps = {};
  if (truncated) {
    Tag = TPTooltip;
    wrapperProps = { content: children, maxWidth: '380px', disabled: !ellipsed };
  }

  return (
    <Tag {...wrapperProps}>
      <Typography
        ref={ref}
        variant={variantType}
        color={colorType}
        noWrap={noWrap}
        component={component || variantMapping[variant]}
        className={classnames(className, {
          [classes.body3]: variant === 'body3',
          [classes.inherit]: variant === 'inherit',
          [classes.truncated]: truncated,
          [classes.bold]: bold,
        })}
        style={
          truncated
            ? {
                WebkitLineClamp: lineClamp,
              }
            : null
        }
        {...props}>
        {children}
      </Typography>
    </Tag>
  );
};

TPTypography.muiName = Typography.muiName;
TPTypography.propTypes = {
  noWrap: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'highlight']),
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'body3', //custom variant
    'inherit',
    'button',
  ]),
  component: PropTypes.elementType,
  bold: PropTypes.bool,
  children: PropTypes.node,
};

export default TPTypography;
