import React from 'react';
import PropTypes from 'prop-types';
import { isMobile, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import TPBadge from 'components/TP-UI/TPBadge';
import TPIconButton from 'components/TP-UI/TPIconButton';
import TPTooltip from 'components/TP-UI/TPTooltip';
import { HEADER_ITEM_ID } from 'modules/common/Header/constants';

const NotificationsButton = ({ onClick, notificationsCount }) => {
  const { t } = useTranslation('notificationCentre');

  return (
    <TPTooltip content={t('page.notifications')} disabled={isTablet || isMobile}>
      <TPIconButton id={HEADER_ITEM_ID.NOTIFICATION} color="primary" onClick={onClick}>
        <TPBadge variant="dot" color="error" invisible={notificationsCount === 0}>
          <NotificationsNoneIcon fontSize="small" />
        </TPBadge>
      </TPIconButton>
    </TPTooltip>
  );
};

NotificationsButton.propTypes = {
  notificationsCount: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

export default NotificationsButton;
