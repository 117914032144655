import React, { lazy, Suspense, useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Page from 'modules/common/Page';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { startIdVerification } from '../../../../../VerificationModalsManager/actions';
import { DIALOG_NAME, TOGGLE_ACCOUNT_VISIBILITY_CONFIRMATION_MODAL } from '../../constants';
import MainLayout from 'modules/common/MainLayout';
import { Routes } from 'constants/routeConstants';
import { ACCOUNT_SERVERS, FUSION_MARKETS } from 'constants/index';
import { VerificationStatuses } from 'constants/verificationStatuses';
import { JOIN_PLUS_FORM, JOIN_PLUS_MODAL } from 'modules/myFollowers/constants';
import JoinFusionPlusModal from 'modules/myFollowers/components/JoinFusionPlusModal';
import {
  getIsProQuizPassed,
  getIsRetailQuizPassed,
  getIsUserWholeSale,
  getUserProStatus,
  allowCopyAccount,
} from 'modules/auth/selectors';

import HeaderButtons from 'modules/common/HeaderButtons';
import TPTabs from 'components/TP-UI/TPTabs';
import Accounts from '../Accounts';
import TPGrid from 'components/TP-UI/TPGrid';
import TPAlert from 'components/TP-UI/TPAlert';
import { getUrlWithParams } from 'helpers/router';
import { getTradeSyncAccounts } from 'modules/myPerformance/selectors';
import { getMyFollowersAccountsRequest } from 'modules/myPerformance/actions';

const AddAccountModal = lazy(() => import('../../containers/AddAccountModalContainer'));
const EditAccountModal = lazy(() => import('../../containers/EditAccountModalContainer'));
const FundDemoAccountModal = lazy(() => import('../../containers/FundDemoAccountModalContainer'));
const MigrationModal = lazy(() => import('../MigrationModal'));
const ToggleAccountVisibilityConfirmationModal = lazy(() =>
  import('../ToggleAccountVisibilityConfirmationModal'),
);
const AcceptInvitationModal = lazy(() => import('../../containers/AcceptInvitationModalContainer'));
const UndoArchiveModal = lazy(() => import('../UndoArchiveModal'));

const MyAccounts = ({
  selectedTab,
  onTabChange,
  onLiveFund,
  accounts = [],
  activeDialog,
  isAccountsGetPending,
  actions,
  userName,
  rebateAccountsLength,
  isLead2,
  isLead3,
  isLead4,
  wasRejected,
  userStatus,
  showHidden,
  hideAccountId,
  hideAccountIsHidden,
  hideAccountLoading,
  showLeverage,
  followerId,
  userIsVerified,
  hub,
  isFusionPlus,
  isJoinPlusPending,
  myFollowersActions,
  haveAwtAccounts,
  onUndoArchived,
  userAllowedAccountCreation,
  disableAccountsCreation,
  isBannedCountry,
  isTradingDisabled,
  canUserCreateAccounts,
}) => {
  const dispatch = useDispatch();
  const { showAddAccountModal, showFundDemoAccountModal, openAcceptInvitationModal } = actions;
  const { t } = useTranslation('accounts');

  const [selectedAccount, setSelectedAccount] = useState({});
  const handleAcceptInvitation = useCallback(
    (id) => {
      const account = accounts.find((account) => account._id === id);
      setSelectedAccount(account);
      openAcceptInvitationModal();
    },
    [openAcceptInvitationModal, accounts],
  );

  const isPro = useSelector(getUserProStatus);
  const isUserWholeSale = useSelector(getIsUserWholeSale);
  const isRetailQuizPassed = useSelector(getIsRetailQuizPassed);
  const isProQuizPassed = useSelector(getIsProQuizPassed);
  const allowCopy = useSelector(allowCopyAccount);
  const tradeSyncAccounts = useSelector(getTradeSyncAccounts);

  useEffect(() => {
    if (allowCopy) {
      dispatch(getMyFollowersAccountsRequest());
    }
  }, [dispatch, allowCopy]);

  const isQuizRequired =
    hub === FUSION_MARKETS && !isUserWholeSale && !isPro && !isRetailQuizPassed && !isProQuizPassed;

  const history = useHistory();

  const handleMyPerformanceView = useCallback(
    (accountId) => {
      history.push(getUrlWithParams(Routes.MY_PERFORMANCE, { id: accountId }));
    },
    [history],
  );

  const handleCompleteVerification = useCallback(
    (event) => {
      event.preventDefault();
      dispatch(startIdVerification());
    },
    [dispatch],
  );

  const headerButtons = useMemo(
    () =>
      userAllowedAccountCreation && !isBannedCountry
        ? [
            {
              label: t('common.buttons.add_account_btn'),
              primary: true,
              onClick: showAddAccountModal,
              disabled: disableAccountsCreation,
            },
          ]
        : [],
    [userAllowedAccountCreation, t, showAddAccountModal, disableAccountsCreation, isBannedCountry],
  );

  const tabProps = {
    accountType: selectedTab,
    accounts: accounts,
    wasRejected: wasRejected,
    isQuizRequired: isQuizRequired,
    isPro: isPro,
    isAccountsGetPending: isAccountsGetPending,
    userStatus: userStatus,
    showHidden: showHidden,
    isLead2: isLead2,
    isLead3: isLead3,
    isLead4: isLead4,
    hub: hub,
    hideAccountLoading: hideAccountLoading,
    showLeverage: showLeverage,
    followerId: followerId,
    userIsVerified: userIsVerified,
    onUndoArchived: onUndoArchived,
    onLiveFund: onLiveFund,
    onCompleteVerification: handleCompleteVerification,
    onSortAccounts: actions.sortAccountRequest,
    onFund: showFundDemoAccountModal,
    onAcceptInvitation: handleAcceptInvitation,
    onCreateAccount: showAddAccountModal,
    actions: actions,
    onMyPerformanceView: handleMyPerformanceView,
    disableAccountsCreation: disableAccountsCreation,
    isBannedCountry: isBannedCountry,
    canUserCreateAccounts,
    tradeSyncAccounts: tradeSyncAccounts,
  };

  const tabs = [
    {
      label: t('common:labels.live'),
      key: ACCOUNT_SERVERS.LIVE,
      content: Accounts,
      contentProps: tabProps,
    },
    {
      label: t('common:labels.demo'),
      key: ACCOUNT_SERVERS.DEMO,
      content: Accounts,
      contentProps: tabProps,
    },
    ...(rebateAccountsLength !== 0
      ? [
          {
            label: t('common:labels.rebate'),
            key: ACCOUNT_SERVERS.REBATE,
            content: Accounts,
            contentProps: tabProps,
          },
        ]
      : []),
    ...(haveAwtAccounts
      ? [
          {
            label: t('common:labels.atw'),
            key: ACCOUNT_SERVERS.ATW,
            content: Accounts,
            contentProps: tabProps,
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (
      (userStatus === VerificationStatuses.POSTED ||
        userStatus === VerificationStatuses.AUTOMATIC_APPROVEMENT ||
        userStatus === VerificationStatuses.APPROVED ||
        userStatus === VerificationStatuses.REJECTED) &&
      followerId &&
      !isFusionPlus
    ) {
      myFollowersActions.openJoinFusionPlus();
    }
  }, [userStatus, isFusionPlus, followerId, myFollowersActions]);

  return (
    <Page docTitle={t('page.title')}>
      <MainLayout title={t('page.title')} headerContent={<HeaderButtons items={headerButtons} />}>
        <>
          <Suspense fallback="">
            <TPGrid container spacing={3} direction="column">
              {isBannedCountry && (
                <TPGrid item>
                  <TPAlert variant="info">{t('page.noteBannedRegion')}</TPAlert>
                </TPGrid>
              )}
              {isTradingDisabled && (
                <TPGrid item>
                  <TPAlert variant="info">{t('page.noteTradingDisabled')}</TPAlert>
                </TPGrid>
              )}
              <TPGrid item>
                <TPTabs
                  disabled={isAccountsGetPending}
                  value={selectedTab}
                  tabs={tabs}
                  onChange={onTabChange}
                  loading={isAccountsGetPending}
                />
              </TPGrid>
            </TPGrid>
          </Suspense>
          <Suspense fallback="">
            {/*TODO TP: Need to refactor to use redux approach*/}
            <AddAccountModal isVisible={activeDialog === DIALOG_NAME.ADD_ACCOUNT} />
            <EditAccountModal />
            <FundDemoAccountModal />
            <MigrationModal userName={userName} />
            <AcceptInvitationModal account={selectedAccount} />
            <ToggleAccountVisibilityConfirmationModal
              modalName={TOGGLE_ACCOUNT_VISIBILITY_CONFIRMATION_MODAL}
              accountId={hideAccountId}
              isHidden={hideAccountIsHidden}
              onConfirm={actions.hideAccountRequest}
              onCancel={actions.hideAccountClose}
              loading={hideAccountLoading}
            />
            <JoinFusionPlusModal
              modalName={JOIN_PLUS_MODAL}
              form={JOIN_PLUS_FORM}
              onSubmit={myFollowersActions.joinFusionPlusRequest}
              joinPending={isJoinPlusPending}
            />
            <UndoArchiveModal />
          </Suspense>
        </>
      </MainLayout>
    </Page>
  );
};

MyAccounts.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  isAccountsGetPending: PropTypes.bool.isRequired,
  activeDialog: PropTypes.string.isRequired,
  onLiveFund: PropTypes.func.isRequired,
  onTabChange: PropTypes.func.isRequired,
  accounts: PropTypes.array,
  actions: PropTypes.object.isRequired,
  userName: PropTypes.string.isRequired,
  rebateAccountsLength: PropTypes.number.isRequired,
  isLead2: PropTypes.bool.isRequired,
  isLead3: PropTypes.bool.isRequired,
  isLead4: PropTypes.bool.isRequired,
  wasRejected: PropTypes.bool.isRequired,
  userStatus: PropTypes.string.isRequired,
  hub: PropTypes.string.isRequired,
  haveAwtAccounts: PropTypes.bool,
  onUndoArchived: PropTypes.func.isRequired,
  userAllowedAccountCreation: PropTypes.bool,
  disableAccountsCreation: PropTypes.bool,
};

export default MyAccounts;
