import { handleActions } from 'redux-actions';
import {
  getDay1TransferredUserSuccess,
  postDay1TransferSuccess,
  postJoinWaitlistFail,
  postJoinWaitlistSuccess,
} from '../actions';

const defaultState = {
  joinedWaitlist: localStorage.getItem('joinedDay1Waitlist') === 'true' || false,
  transferredUser: false,
};

export default handleActions(
  {
    [postJoinWaitlistFail](state) {
      return {
        ...state,
        joinedWaitlist: false,
      };
    },
    [postJoinWaitlistSuccess](state) {
      localStorage.setItem('joinedDay1Waitlist', true);
      return {
        ...state,
        joinedWaitlist: true,
      };
    },
    [getDay1TransferredUserSuccess](state, action) {
      return { transferredUser: action.response.data.transferredUser };
    },
    [postDay1TransferSuccess]() {
      return { transferredUser: true };
    },
  },
  defaultState,
);
