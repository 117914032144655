import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }),
  { name: 'Buttons' },
);

export default useStyles;
