import { combineActions, handleActions } from 'redux-actions';
import * as actions from '../actions';

const defaultState = {
  messages: [],
  userMessages: [],
  currentUser: null,
  polling: false,
  linkedUser: null,
};

export default handleActions(
  {
    [actions.getIMUserMessagesSuccess]: (state, action) => {
      return { ...state, userMessages: action.response.data.messages };
    },
    [actions.getIMUserMessagesPollingSuccess]: (state, action) => {
      const newMessages = action.response.data.messages;
      return {
        ...state,
        userMessages: newMessages,
        polling: false,
      };
    },
    [actions.getIMMessagesPollingSuccess]: (state, action) => {
      return {
        ...state,
        messages: action.response.data.messages,
        polling: false,
      };
    },
    [actions.getIMMessagesSuccess]: (state, action) => {
      let straightMessageUser;
      if (state.linkedUser && state.linkedUser.userId._id) {
        straightMessageUser = action.response.data.messages.find(
          (mes) => mes.userId?._id === state.linkedUser?.userId?._id,
        );
      }
      return {
        ...state,
        messages: action.response.data.messages,
        currentUser: straightMessageUser ? straightMessageUser : state.currentUser,
        handshake: action.response.data.handshake,
      };
    },
    [actions.getIMMessagesNewSuccess]: (state, action) => {
      return { ...state, messages: action.response.data.messages };
    },
    [actions.setCurrentUser]: (state, action) => {
      return { ...state, currentUser: action.payload, linkedUser: null };
    },
    [actions.setLinkedUser]: (state, action) => {
      return { ...state, linkedUser: action.payload };
    },
    [actions.setPolling]: (state) => {
      return { ...state, polling: true };
    },
    [actions.pinIMUserMessagesSuccess]: (state, action) => {
      const currentUser = action.payload;
      const messages = state.messages.map((message) => {
        if (message._id === currentUser._id) {
          return { ...currentUser };
        }
        return message;
      });
      return {
        ...state,
        currentUser: { ...currentUser, pinned: action.response.data.pinned },
        messages,
      };
    },
    [combineActions(actions.getIMUserMessagesPollingFail, actions.getIMMessagesPollingFail)]: (
      state,
    ) => {
      return { ...state, polling: false };
    },
    [actions.getIMNewUserMessagesSuccess]: (state, action) => {
      return { ...state, newUserMessages: action.response.data.messages };
    },
  },
  defaultState,
);
