import React, { useCallback, useEffect, useState } from 'react';
import Quiz from 'modules/common/Quiz';
import { AML_QUIZ_FORM, AML_QUIZ_MODAL } from '../../constants';
import InfoTip from 'modules/common/InfoTip';
import { useDispatch, useSelector } from 'react-redux';
import { getAMLQuestions } from '../../selectors';
import { getAmlQuestionsRequest, sendAmlAnswersRequest } from '../../actions';
import { createPendingSelector } from 'modules/api/selectors';
import { openModal } from 'components/TP-UI/TPModal/actions';
import { store } from 'components/App';
import { reset } from 'redux-form';
import CompleteAML from '../CompleteAMLContainer';
import EmploymentStatusForm from '../../components/EmploymentStatusForm';
import AMLSuccessModal from '../../components/AMLSuccessModal';
import PropTypes from 'prop-types';
const AMLContainer = ({ withdrawForm }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [progressIndex, setProgressIndex] = useState(0);
  const questions = useSelector(getAMLQuestions);
  const dispatch = useDispatch();
  const quizLoading = useSelector(createPendingSelector(getAmlQuestionsRequest));
  const submitLoading = useSelector(createPendingSelector(sendAmlAnswersRequest));
  useEffect(() => {
    import('../../sagas').then((saga) => {
      store.injectSaga('AML', saga.default);
    });
  }, []);
  const handleNextQuestion = useCallback(
    (next) => {
      setCurrentQuestionIndex(next + 1);
      setProgressIndex(progressIndex < next + 1 ? next + 1 : progressIndex);
    },
    [progressIndex],
  );
  const handlePreviousQuestion = useCallback((next) => {
    setCurrentQuestionIndex(next - 1);
  }, []);

  const handleAMLQuizOpen = useCallback(() => {
    dispatch(getAmlQuestionsRequest());
    dispatch(reset(AML_QUIZ_FORM));
    setCurrentQuestionIndex(0);
    setProgressIndex(0);
    dispatch(openModal(AML_QUIZ_MODAL));
  }, [dispatch]);

  const handleSubmit = useCallback(
    (answers) => {
      setProgressIndex(questions.length);
      dispatch(sendAmlAnswersRequest(answers));
    },
    [dispatch, questions.length],
  );
  return (
    <>
      <Quiz
        currentQuestionIndex={currentQuestionIndex}
        form={AML_QUIZ_FORM}
        loading={quizLoading || submitLoading}
        modalName={AML_QUIZ_MODAL}
        onNext={handleNextQuestion}
        onPrevious={handlePreviousQuestion}
        onSubmit={handleSubmit}
        progressIndex={progressIndex}
        questions={questions}
        title="Questionnaire"
        questionComponent={
          questions.length && questions[currentQuestionIndex].hasNestedQuestions
            ? EmploymentStatusForm
            : null
        }
        titleEndAdornment={
          <InfoTip
            iconSize="medium"
            tooltipContent="Why do we ask this? Understanding the origin of funds helps prevent money laundering and other financial crimes. This information is necessary for compliance with regulatory requirements."
          />
        }
      />
      <CompleteAML withdrawForm={withdrawForm} onOpenQuiz={handleAMLQuizOpen} />
      <AMLSuccessModal />
    </>
  );
};

AMLContainer.propTypes = {
  withdrawForm: PropTypes.string,
};

export default AMLContainer;
