const styles = (theme) => ({
  label: {
    color: theme.tableTextField.labelColor,
    fontFamily: 'Lato, sans-serif',
    fontSize: '12px',
    lineHeight: '15px',
  },
  dataText: {
    color: theme.palette.text.main,
    fontFamily: 'Lato, sans-serif',
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '17px',
  },
  item: {
    padding: '22px 10px',
    width: 120,
    '@media (min-width:400px)': {
      width: 195,
      height: 'auto',
    },
    height: 80,
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '6px',
  },
  statusImage: {
    marginRight: '9px',
  },
  dotImage: {
    marginRight: '9px',
    width: '14px',
  },
});

export default styles;
