import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    container: {
      overflow: 'hidden',
    },
    contentContainer: {
      clear: 'both',
      overflow: 'hidden',
      position: 'relative',
      ...theme.table.contentContainer,
    },
    customContentContainer: {
      overflow: 'visible',
      border: 'none',
    },
    content: {
      minHeight: 100,
    },
    contentLoading: {
      ...theme.table.contentLoading,
    },
    scrollable: {
      overflow: 'auto',
      maxHeight: 'calc(65vh - 17px)',
      width: '100%',
      '@media (max-width:420px)': {
        maxHeight: 380, // ~5 rows
      },
    },
    tableNoDataContainer: {
      minHeight: 100,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    root: {
      display: 'table',
      width: '100%',
    },
    columnTitle: {
      whiteSpace: 'nowrap',
      ...theme.table.columnTitle,
    },
    row: {
      display: 'table-row',
      ...theme.table.row,
      '&:last-child $cell': {
        border: 'none',
      },
    },
    cell: {
      display: 'table-cell',
      verticalAlign: 'top',
      ...theme.table.cell,
    },
    cellBoldText: {
      fontWeight: theme.font.fontWeight.bold,
      '& *': {
        fontWeight: theme.font.fontWeight.bold,
      },
    },
    cellFixed: {
      position: 'sticky',
      right: 0,
      background: 'white',
    },
    tableLoader: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyItems: 'center',
    },
    pagination: {
      ...theme.table.pagination,
    },
    summary: {
      ...theme.table.summary,
    },
  }),
  { name: 'TPTable' },
);
