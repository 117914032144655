import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    container: {
      marginTop: theme.spacing(2),
    },
    description: {
      maxWidth: '336px',
    },
    image: {
      width: '100%',
      maxWidth: '377px',
      maxHeight: '305px',
    },
    imageContainer: {
      padding: theme.spacing(3, 0),
    },
  }),
  { name: 'NotFoundContent' },
);
