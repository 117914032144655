import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TPLink from 'components/TP-UI/TPLink';
import TPGrid from 'components/TP-UI/TPGrid';
import useStyles from './styles';

const TPBottomNavigationActionBase = ({
  id,
  label,
  path,
  iconComponent: IconComponent,
  value,
  selected,
  expandable,
  expanded,
  onClick,
  forwardedRef,
}) => {
  const classes = useStyles();
  const ExpandIcon = expanded ? ExpandLessIcon : ExpandMoreIcon;
  const [WrapperComponent, wrapperComponentProps] = useMemo(
    () => (path ? [TPLink, { path, className: classes.link }] : [React.Fragment, {}]),
    [path, classes.link],
  );

  return (
    <WrapperComponent {...wrapperComponentProps}>
      <BottomNavigationAction
        ref={forwardedRef}
        id={id}
        key={id}
        value={value}
        selected={selected}
        label={label}
        onClick={onClick}
        icon={
          expandable ? (
            <TPGrid container justifyContent="center" alignItems="center">
              <IconComponent color="inherit" classes={{ root: classes.iconWithExpand }} />
              <ExpandIcon color="inherit" fontSize="small" />
            </TPGrid>
          ) : (
            <IconComponent color="inherit" classes={{ root: classes.iconWithoutExpand }} />
          )
        }
      />
    </WrapperComponent>
  );
};

TPBottomNavigationActionBase.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  path: PropTypes.string,
  iconComponent: PropTypes.elementType,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selected: PropTypes.bool,
  expandable: PropTypes.bool,
  expanded: PropTypes.bool,
  onClick: PropTypes.func,
};

const TPBottomNavigationAction = React.forwardRef((props, ref) => (
  <TPBottomNavigationActionBase {...props} forwardedRef={ref} />
));

TPBottomNavigationAction.muiName = BottomNavigationAction.muiName;

export default TPBottomNavigationAction;
