import { createAction } from 'redux-actions';

export const getOperationNotificationsRequest = createAction('GET_OPERATION_NOTIFICATIONS_REQUEST');
export const getOperationNotificationsSuccess = createAction('GET_OPERATION_NOTIFICATIONS_SUCCESS');
export const getOperationNotificationsFail = createAction('GET_OPERATION_NOTIFICATIONS_FAIL');
export const cleanupOperationNotifications = createAction('CLEANUP_OPERATION_NOTIFICATIONS');

export const markAsReadOperationNotificationRequest = createAction(
  'MARK_AS_READ_OPERATION_NOTIFICATION_REQUEST',
);
export const markAsReadOperationNotificationSuccess = createAction(
  'MARK_AS_READ_OPERATION_NOTIFICATION_SUCCESS',
);
export const markAsReadOperationNotificationFail = createAction(
  'MARK_AS_READ_OPERATION_NOTIFICATION_FAIL',
);
