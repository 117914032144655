import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      zIndex: -1,
      position: 'fixed',
      inset: 0,
      '-webkit-tap-highlight-color': 'transparent',
    },
  }),
  { name: 'TPWizardBackdrop' },
);
