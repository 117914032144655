import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { required } from 'utils/validation/fieldValidationRules';
import { EMAIL_VALIDATORS } from 'utils/validation/fieldsValidators';
import TPGrid from 'components/TP-UI/TPGrid';
import TPButton from 'components/TP-UI/TPButton';
import TPTextField from 'components/TP-UI/TPTextField';
import { REDUX_FORM_DEFAULT_CONFIG } from 'constants/form';
import FormError from 'modules/common/FormError';

import useStyles from './styles';

const ForgotPasswordForm = ({ handleSubmit, error, submitFailed, isPending }) => {
  const classes = useStyles();
  const { t } = useTranslation('auth');

  return (
    <form className={classes.form} onSubmit={handleSubmit} noValidate>
      <TPGrid container spacing={2} direction="column">
        {error && submitFailed && (
          <TPGrid item xs={12}>
            <FormError error={error} />
          </TPGrid>
        )}
        <TPGrid item container spacing={1} direction="column">
          <TPGrid item xs={12}>
            <Field
              name="email"
              label={t('common:labels.email')}
              placeholder={t('common:labels.email')}
              type="text"
              component={TPTextField}
              autofocus
              fullWidth
              autocomplete="username"
              required
              validate={[required, ...EMAIL_VALIDATORS]}
            />
          </TPGrid>
        </TPGrid>
        <TPGrid item xs={12}>
          <TPButton fullWidth primary size="large" type="submit" loading={isPending}>
            {t('buttons.resetPassword')}
          </TPButton>
        </TPGrid>
      </TPGrid>
    </form>
  );
};

ForgotPasswordForm.propTypes = {
  form: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isPending: PropTypes.bool.isRequired,
};

export default reduxForm({ ...REDUX_FORM_DEFAULT_CONFIG })(ForgotPasswordForm);
