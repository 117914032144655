import { createAction } from 'redux-actions';

export const clearData = createAction('MY_PERFORMANCE_CLEAR_DATA');

export const setMt4login = createAction('SET_MT4_LOGIN');

export const getMyPerformanceRequest = createAction('GET_MY_PERFORMANCE_REQUEST');
export const getMyPerformanceSuccess = createAction('GET_MY_PERFORMANCE_SUCCESS');
export const getMyPerformanceFail = createAction('GET_MY_PERFORMANCE_FAIL');

export const getSharedPerformanceRequest = createAction('GET_SHARED_PERFORMANCE_REQUEST');
export const getSharedPerformanceSuccess = createAction('GET_SHARED_PERFORMANCE_SUCCESS');
export const getSharedPerformanceFail = createAction('GET_SHARED_PERFORMANCE_FAIL');

export const getUserPerformanceRequest = createAction('GET_USER_PERFORMANCE_REQUEST');
export const getUserPerformanceSuccess = createAction('GET_USER_PERFORMANCE_SUCCESS');
export const getUserPerformanceFail = createAction('GET_USER_PERFORMANCE_FAIL');

export const getMyPerformanceTradesRequest = createAction('GET_MY_PERFORMANCE_TRADES_REQUEST');
export const getMyPerformanceTradesSuccess = createAction('GET_MY_PERFORMANCE_TRADES_SUCCESS');
export const getMyPerformanceTradesFail = createAction('GET_MY_PERFORMANCE_TRADES_FAIL');

export const getSharedPerformanceTradesRequest = createAction(
  'GET_SHARED_PERFORMANCE_TRADES_REQUEST',
);
export const getSharedPerformanceTradesSuccess = createAction(
  'GET_SHARED_PERFORMANCE_TRADES_SUCCESS',
);
export const getSharedPerformanceTradesFail = createAction('GET_SHARED_PERFORMANCE_TRADES_FAIL');

export const getUserPerformanceTradesRequest = createAction('GET_USER_PERFORMANCE_TRADES_REQUEST');
export const getUserPerformanceTradesSuccess = createAction('GET_USER_PERFORMANCE_TRADES_SUCCESS');
export const getUserPerformanceTradesFail = createAction('GET_USER_PERFORMANCE_TRADES_FAIL');

export const getCopierAccountSettingsRequest = createAction('GET_COPIER_ACCOUNT_SETTINGS_REQUEST');
export const getCopierAccountSettingsSuccess = createAction('GET_COPIER_ACCOUNT_SETTINGS_SUCCESS');
export const getCopierAccountSettingsFail = createAction('GET_COPIER_ACCOUNT_SETTINGS_FAIL');

export const updateCopierAccountSettingsRequest = createAction(
  'UPDATE_COPIER_ACCOUNT_SETTINGS_REQUEST',
);
export const updateCopierAccountSettingsSuccess = createAction(
  'UPDATE_COPIER_ACCOUNT_SETTINGS_SUCCESS',
);
export const updateCopierAccountSettingsFail = createAction('UPDATE_COPIER_ACCOUNT_SETTINGS_FAIL');

export const createFollowerAccountRequest = createAction('CREATE_FOLLOWER_ACCOUNT_REQUEST');
export const createFollowerAccountSuccess = createAction('CREATE_FOLLOWER_ACCOUNT_SUCCESS');
export const createFollowerAccountFail = createAction('CREATE_FOLLOWER_ACCOUNT_FAIL');

export const openCopySettings = createAction('OPEN_COPY_SETTINGS');
export const saveCopySettings = createAction('SAVE_COPY_SETTINGS');
export const closeCopySettings = createAction('CLOSE_COPY_SETTINGS');

export const setSymbolMap = createAction('SET_SYMBOL_MAP');
export const addSymbolMap = createAction('ADD_SYMBOL_MAP');
export const removeSymbolMap = createAction('REMOVE_SYMBOL_MAP');

export const getMyFollowersAccountsRequest = createAction('GET_MY_FOLLOWERS_ACCOUNTS_REQUEST');
export const getMyFollowersAccountsSuccess = createAction('GET_MY_FOLLOWERS_ACCOUNTS_SUCCESS');
export const getMyFollowersAccountsFail = createAction('GET_MY_FOLLOWERS_ACCOUNTS_FAIL');

export const openAddAccountWarning = createAction('OPEN_ADD_ACCOUNT_WARNING');

export const createOwnCopierRequest = createAction('CREATE_OWN_COPIER_REQUEST');
export const createOwnCopierSuccess = createAction('CREATE_OWN_COPIER_SUCCESS');
export const createOwnCopierFail = createAction('CREATE_OWN_COPIER_FAIL');

export const openSaveCopySettingsConfirmation = createAction(
  'OPEN_SAVE_COPY_SETTINGS_CONFIRMATION',
);
export const closeSaveCopySettingsConfirmation = createAction(
  'CLOSE_SAVE_COPY_SETTINGS_CONFIRMATION',
);

export const openJoinFusionPlus = createAction('MY_PERFORMANCE_OPEN_JOIN_FUSION_PLUS');
export const closeJoinFusionPlus = createAction('MY_PERFORMANCE_CLOSE_JOIN_FUSION_PLUS');
export const saveSign = createAction('MY_PERFORMANCE_SAVE_SIGN');

export const getPerformanceShareLinkRequest = createAction('GET_PERFORMANCE_SHARE_LINK_REQUEST');
export const getPerformanceShareLinkSuccess = createAction('GET_PERFORMANCE_SHARE_LINK_SUCCESS');
export const getPerformanceShareLinkFail = createAction('GET_PERFORMANCE_SHARE_LINK_FAIL');

export const openPerformanceShareLink = createAction('OPEN_PERFORMANCE_SHARE_LINK');
export const closePerformanceShareLink = createAction('CLOSE_PERFORMANCE_SHARE_LINK');

export const openInvalidHubModal = createAction('OPEN_PERFORMANCE_INVALID_HUB');
