import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TPTypography from 'components/TP-UI/TPTypography';
import TPBreadcrumbs from 'components/TP-UI/TPBreadcrumbs';
import useStyles from './styles';

const TPPageHeader = ({
  title,
  breadcrumbsRoutes,
  reservedBreadcrumbsSpace = false,
  children,
  actions,
  minimized,
  className,
  titleClassName,
}) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.titleContainer}>
        {typeof title === 'string' ? (
          <TPTypography
            className={titleClassName}
            variant={minimized ? 'h3' : 'h1'}
            component="h1"
            truncated
            lineClamp={1}>
            {title}
          </TPTypography>
        ) : (
          title
        )}
        {reservedBreadcrumbsSpace || breadcrumbsRoutes ? (
          <div className={classes.breadcrumbsContainer}>
            {breadcrumbsRoutes ? <TPBreadcrumbs routes={breadcrumbsRoutes} /> : null}
          </div>
        ) : null}
      </div>
      <div
        className={classNames(classes.secondaryContent, {
          [classes.miniSecondaryContent]: minimized,
        })}>
        {children ? <div className={classes.childrenContainer}>{children}</div> : null}
        {actions ? <div className={classes.actionsContainer}>{actions}</div> : null}
      </div>
    </div>
  );
};

TPPageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType, PropTypes.string]).isRequired,
  reservedBreadcrumbsSpace: PropTypes.bool,
  breadcrumbsRoutes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
    }),
  ),
  children: PropTypes.node,
  actions: PropTypes.node,
  minimized: PropTypes.bool,
  titleClassName: PropTypes.string,
};

export default TPPageHeader;
