import moment from 'moment';
import * as mapSymbols from './mapSymbols';

const currentYear = moment().year();

export const SYMBOLS = mapSymbols.SYMBOLS;

export const RANGE = [
  { value: '1', label: '1H' },
  { value: '24', label: '1D' },
  { value: '168', label: '1W' },
  { value: '730', label: '1M' },
  { value: '4380', label: '6M' },
  { value: '8760', label: '1Y' },
  { value: '17520', label: '2Y' },
];

export const BALANCE_RANGE = [
  { value: '24', label: 'Today' },
  { value: '72', label: 'Last 3D' },
  { value: '168', label: 'Last 1W' },
  { value: '2190', label: 'Last 3M' },
  { value: '8760', label: 'Last 1Y' },
  { value: '17520', label: 'All Time' },
];

export const RANGE_YEARS = [...Array(5)]
  .map((_, i) => currentYear - i)
  .sort()
  .map((n) => ({ value: String(n), label: String(n) }));

export const RUNNING_PROFIT_LOSS = 'running_profit_loss';
export const RUNNING_BALANCE = 'running_balance';
export const OPENED_PROFIT_LOSS = 'opened_profit_loss';
export const RUNNING_GROWTH = 'running_growth';

export const FILTER_PERFORMANCE_LINE = [
  { value: OPENED_PROFIT_LOSS, label: 'account.profitLoss' },
  { value: RUNNING_PROFIT_LOSS, label: 'account.closedPL' },
  { value: RUNNING_BALANCE, label: 'account.balance' },
  { value: RUNNING_GROWTH, label: 'account.growth' },
];

export const EQUITY_FILTER_EQUITY = 'EQUITY_FILTER_EQUITY';
export const EQUITY_FILTER_DRAWDOWN = 'EQUITY_FILTER_DRAWDOWN';

export const EQUITY_FILTER = [
  { value: EQUITY_FILTER_EQUITY, label: 'account.equity' },
  { value: EQUITY_FILTER_DRAWDOWN, label: 'account.drawdown' },
];

export const FILTER_PERFORMANCE_BAR = [
  { value: 'monthly', label: 'Monthly' },
  { value: 'daily', label: 'Daily' },
];

export const PERFORMANCE_BAR_FROM = 'PERFORMANCE_BAR_FROM';

export const PERFORMANCE_BAR_DEFAULT_HEIGHT = 300;

export const PERFORMANCE_LINE_CHART_DEFAULT_HEIGHT = 300;

export const PERFORMANCE_LINE_FROM = 'PERFORMANCE_LINE_FROM';

export const TRADING_STATISTICS_FROM = 'TRADING_STATISTICS_FROM';

export const TRADES_FROM = 'TRADES_FROM';

export const COPY_SETTINGS_MODAL = 'COPY_SETTINGS_MODAL';

export const COPY_SETTINGS_FORM = 'COPY_SETTINGS_FORM';

export const DATE_FORMAT_MASK = {
  DD_MMM_YYYY: 'DD MMM YYYY',
  MMM: 'MMM',
  MMM_DD: 'MMM DD',
};

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const RISK_TYPE = [
  { value: 'risk_multiplier_by_balance', label: 'riskType.risk_multiplier_by_balance' },
  { value: 'risk_multiplier_by_equity', label: 'riskType.risk_multiplier_by_equity' },
  { value: 'lot_multiplier', label: 'riskType.lot_multiplier' },
  { value: 'fixed_lot', label: 'riskType.fixed_lot' },
];

export const YES_NO = [
  { value: 'yes', label: 'common:constants.yes' },
  { value: 'no', label: 'common:constants.no' },
];

export const COPIER_MODES = [
  { value: 'on', label: 'copySettings.copierMode.on' },
  { value: 'off', label: 'copySettings.copierMode.off' },
  { value: 'noNewTrades', label: 'copySettings.copierMode.noNewTrades' },
];

export const FEE = [
  { value: '5', label: '5%' },
  { value: '10', label: '10%' },
  { value: '15', label: '15%' },
  { value: '20', label: '20%' },
  { value: '25', label: '25%' },
  { value: '30', label: '30%' },
];

export const COPY_SETTINGS_TABS = ['general', 'riskSettings', 'stopsLimits', 'disableSymbols'];

export const FORM_DEFAULTS = {
  copierMode: COPIER_MODES[0].value,
  reverseCopy: YES_NO[1].value,
  riskType: RISK_TYPE[0].value,
  multiplier: 1,
  slippage: 5,
  maxLot: 50,
  forceMinLot: YES_NO[1].value,
  copyPending: YES_NO[1].value,
  copyStopLoss: YES_NO[1].value,
  copyTakeProfit: YES_NO[1].value,
  copyExistingTrades: YES_NO[1].value,
  emailNotifications: YES_NO[0].value,
  useFee: YES_NO[1].value,
  feeValue: '10',
  disableSymbols: [],
  symbolMap: [],
  isEnableAllSymbols: true,
};

export const EMPTY_FORM = {
  copierMode: null,
  reverseCopy: null,
  riskType: null,
  multiplier: null,
  slippage: null,
  maxLot: null,
  forceMinLot: null,
  copyPending: null,
  copyStopLoss: null,
  copyTakeProfit: null,
  copyExistingTrades: null,
  emailNotifications: null,
  useFee: null,
  feeValue: null,
  disableSymbols: [],
  symbolMap: null,
  isEnableAllSymbols: null,
};

export const ADD_COPIER_WARNING_MODAL = 'ADD_COPIER_WARNING_MODAL';

export const NO_DATA_MODAL = 'NO_DATA_MODAL';

export const ADD_COPIER_CONFIRMATION_MODAL = 'ADD_COPIER_CONFIRMATION_MODAL';

export const JOIN_PLUS_MODAL = 'JOIN_PLUS_MODAL';

export const JOIN_PLUS_FORM = 'JOIN_PLUS_FORM';

export const DEMO_ACCOUNT_MODAL = 'DEMO_ACCOUNT_MODAL';

export const SHARE_LINK_MODAL = 'SHARE_LINK_MODAL';

export const INVALID_HUB_MODAL = 'INVALID_HUB_MODAL';

export const INVALID_LINK_MODAL = 'INVALID_LINK_MODAL';

export const TRADES_TABLE_OPEN_TRADES_TAB_TYPE = 'openTrades';

export const TRADES_TABLE_HISTORY_TAB_TYPE = 'tradingHistory';

export const TRADES_TABLE_TAB_TYPES = [
  TRADES_TABLE_OPEN_TRADES_TAB_TYPE,
  TRADES_TABLE_HISTORY_TAB_TYPE,
];

export const TRADING_STATISTIC_DEFAULT_TOTAL_TRADES = 0;

export const TRADING_STATISTIC_SCALE = [0, 20, 40, 60, 80, 100];
