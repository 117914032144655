import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import i18n from 'i18n';
import { I18nextProvider } from 'react-i18next';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Provider from 'react-redux/es/components/Provider';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/es/integration/react';
import FreeChat from '../FreeChat';
import config from 'config';

import { BrowserRouter } from 'react-router-dom';
import { configureStore } from 'store/configureStore';
import { RoutesForStaticSite } from '../../constants/routeConstants';
import ErrorBoundary from '../../modules/errorBoundary';
import CookiesBanner from '../CookiesBanner';
import CustomThemeProvider from '../CustomThemeProvider';
import Router from '../Router';
import AppCssBaseline from '../AppCssBaseline';

export const store = configureStore();
let persistor = persistStore(store);

const hideChatForStaticPage = () => {
  if (window.location.pathname.includes(RoutesForStaticSite.DAY1X_SUBSCRIBTION)) return true;

  return false;
};

const App = () => (
  <I18nextProvider i18n={i18n}>
    <GoogleOAuthProvider clientId={config.GOOGLE_OAUTH.CLIENT_ID}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter basename={config.HUB_PREFIX}>
            <CustomThemeProvider>
              <AppCssBaseline>
                <CssBaseline />
                <ErrorBoundary>
                  <Router />
                  {!hideChatForStaticPage() ? (
                    <FreeChat license="7eb6d36b-9a3a-4468-9400-751f2cc30a3f" />
                  ) : null}
                </ErrorBoundary>
                <CookiesBanner />
              </AppCssBaseline>
            </CustomThemeProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </GoogleOAuthProvider>
  </I18nextProvider>
);

export default App;
