import {
  accountPassword,
  checkNonLatin,
  checkRuIBAN,
  dateAfter1900,
  dateLessThenToday,
  email,
  fullAgeDateOfBirth,
  maxNumberLength,
  moreThanZero,
  password,
  positiveNumber,
  stringLength60,
  stringLength8or11,
  stringLengthMax34,
  stringLengthMax128,
  stringLengthMax64,
} from './fieldValidationRules';

export const FIRST_NAME_VALIDATORS = [checkNonLatin, stringLength60];
export const LAST_NAME_VALIDATORS = [checkNonLatin, stringLength60];
export const BIRTHDAY_VALIDATORS = [
  checkNonLatin,
  dateLessThenToday,
  dateAfter1900,
  fullAgeDateOfBirth,
];
export const EMAIL_VALIDATORS = [checkNonLatin, email];
export const PASSWORD_VALIDATORS = [checkNonLatin, password, stringLengthMax128];
export const CONFIRMATION_PASSWORD_VALIDATORS = [checkNonLatin, password, stringLengthMax128];
export const ACCOUNT_PASSWORD_VALIDATORS = [checkNonLatin, accountPassword, stringLengthMax64];
export const ACCOUNT_CONFIRMATION_PASSWORD_VALIDATORS = [
  checkNonLatin,
  accountPassword,
  stringLengthMax64,
];
export const AMOUNT_VALIDATORS = [checkNonLatin, positiveNumber, moreThanZero, maxNumberLength];

export const BENEFICIARY_ACCOUNT_NUMBER_VALIDATORS = [
  checkNonLatin,
  stringLengthMax34,
  checkRuIBAN,
];
export const SWIFT_CODE_VALIDATORS = [checkNonLatin, stringLength8or11];
