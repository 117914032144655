import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isMobile, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { getFileExtension, isFile, getImageUrl } from '../../../helpers/file';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import TPIconButton from '../../../TPIconButton';
import TPTypography from '../../../TPTypography';
import TPImage from '../../../TPImage';
import TPTooltip from '../../../TPTooltip';

import useStyles from './styles';

export const TPFileItem = ({
  value,
  onClick,
  onDelete,
  className,
  disabled = false,
  error,
  startAdornment,
  endAdornment,
}) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const { file, url, originalName } = value || {};
  const name = file && isFile(file) ? file.name || 'camera-photo' : originalName || '';
  const extension = getFileExtension(name);
  const imageUrl = useMemo(() => getImageUrl(url, file), [url, file]);
  useEffect(() => {
    // Revoke the data uris to avoid memory leaks, will run on unmount
    return () => imageUrl && typeof imageUrl === 'object' && URL.revokeObjectURL(imageUrl);
  }, [imageUrl]);

  const handleClick = useCallback(
    (e) => {
      if (!disabled && onClick) {
        onClick(e);
      }
    },
    [onClick, disabled],
  );

  return (
    <div className={classnames(className, classes.root)}>
      {startAdornment}
      <div
        className={classnames(classes.fileContainer, {
          [classes.clickable]: !disabled && onClick,
          [classes.fileContainerError]: error,
        })}
        onClick={handleClick}>
        {imageUrl ? (
          <TPImage className={classes.fileImage} src={imageUrl} alt={name} />
        ) : (
          <div className={classes.nonImageFileContainer}>
            <DescriptionOutlinedIcon fontSize="large" />
            <TPTypography component="span" variant="body2" bold className={classes.fileExtension}>
              {extension}
            </TPTypography>
          </div>
        )}
      </div>
      <div className={classes.fileItemDescription}>
        <TPTypography
          component="span"
          variant="body2"
          color={error ? 'error' : 'primary'}
          truncated
          lineClamp={1}>
          {name}
        </TPTypography>
        {onDelete ? (
          <TPTooltip content={t('labels.delete')} disabled={isTablet || isMobile}>
            <TPIconButton disabled={disabled} onClick={onDelete} size="small">
              <DeleteOutlineIcon fontSize="small" />
            </TPIconButton>
          </TPTooltip>
        ) : null}
      </div>
      {endAdornment}
    </div>
  );
};

TPFileItem.propTypes = {
  value: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  startAdornment: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  endAdornment: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

const TPReduxFileItem = ({ input, meta, errors, ...others }) => {
  const error = meta.submitFailed && errors ? errors[0] : null;
  const { value } = input || {};
  return <TPFileItem items={value} {...input} error={error} {...others} />;
};

export default TPReduxFileItem;
