import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import TPButton from 'components/TP-UI/TPButton';

import useStyles from './styles';

const TPScrollToTopButton = ({ onScrollToTop }) => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  return (
    <div className={classes.scrollToTop}>
      <TPButton onClick={onScrollToTop} className={classes.button}>
        <VerticalAlignTopIcon className={classes.icon} />
        {t('buttons.scrollToTop')}
      </TPButton>
    </div>
  );
};

TPScrollToTopButton.propTypes = {
  onScrollToTop: PropTypes.func.isRequired,
};

export default TPScrollToTopButton;
