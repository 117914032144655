import { all, takeEvery, put } from 'redux-saga/effects';
import { logoutAsCurrentUser } from '../actions';
import { signinAsUserRequest } from 'modules/userManagement/pages/UserDetails/actions';

function* logoutHandler() {
  const userId = localStorage.getItem('adminId');
  const adminToken = localStorage.getItem('adminToken');
  if (userId && adminToken) {
    localStorage.removeItem('adminId');
    localStorage.removeItem('adminToken');
    yield put(signinAsUserRequest({ userId, customAuthToken: adminToken }));
  }
}

export default function* watchRequest() {
  yield all([takeEvery(logoutAsCurrentUser, logoutHandler)]);
}
