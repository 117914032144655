import { createAction } from 'redux-actions';

export const sendTradingViewSubscriptionRequest = createAction(
  'SEND_TRADING_VIEW_SUBSCRIPTION_REQUEST',
);
export const sendTradingViewSubscriptionSuccess = createAction(
  'SEND_TRADING_VIEW_SUBSCRIPTION_SUCCESS',
);
export const sendTradingViewSubscriptionFail = createAction('SEND_TRADING_VIEW_SUBSCRIPTION_FAIL');

export const getTradingViewSubscriptionRequest = createAction(
  'GET_TRADING_VIEW_SUBSCRIPTION_REQUEST',
);
export const getTradingViewSubscriptionSuccess = createAction(
  'GET_TRADING_VIEW_SUBSCRIPTION_SUCCESS',
);
export const getTradingViewSubscriptionFail = createAction('GET_TRADING_VIEW_SUBSCRIPTION_FAIL');
