import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: 0,

      '&:hover $label': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.lightest,
      },
    },
    contentWrapper: {
      display: 'flex',
      width: '100%',
      alignItems: 'flex-start',
      ...theme.navMenuList.item.content,
    },
    selected: {
      '& $text': {
        ...theme.navMenuList.item.selected.text,
      },

      '& $label': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.lightest,
      },
    },
    fullWidth: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
    iconContainer: {
      ...theme.navMenuList.item.icon,
    },
    text: {
      whiteSpace: 'normal',
      paddingTop: '0.2rem',
    },
    label: {
      ...theme.navMenuList.item.label,
    },
  }),
  { name: 'TPNavMenuListItem' },
);

export default useStyles;
