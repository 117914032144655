import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import TPTypography from 'components/TP-UI/TPTypography';
import TPGrid from 'components/TP-UI/TPGrid';

import useStyles from './styles';

const TPAccordionSummary = ({
  titleIcon: TitleIcon,
  title,
  variant = 'outlined',
  titleVariant = 'body1',
  className,
}) => {
  const classes = useStyles();

  return (
    <AccordionSummary
      expandIcon={<ExpandMoreOutlinedIcon />}
      className={classnames(
        classes.root,
        [{ [classes.elevation]: variant === 'elevation' }],
        className,
      )}
      classes={{
        content: classes.content,
        expandIcon: classes.expandIcon,
      }}>
      <TPGrid container spacing={2} alignItems="center" wrap="nowrap">
        {TitleIcon && (
          <TPGrid item>
            <TitleIcon fontSize="medium" className={classes.titleIcon} />
          </TPGrid>
        )}
        <TPGrid item>
          <TPTypography variant={titleVariant}>{title}</TPTypography>
        </TPGrid>
      </TPGrid>
    </AccordionSummary>
  );
};

TPAccordionSummary.propTypes = {
  titleIcon: PropTypes.elementType,
  title: PropTypes.string,
  className: PropTypes.string,
  titleVariant: TPTypography.propTypes.variant,
  variant: PropTypes.oneOf(['outlined', 'elevation']),
};

TPAccordionSummary.muiName = AccordionSummary.muiName;

export default TPAccordionSummary;
