import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    image: {
      width: '100%',
      maxWidth: '268px',
      maxHeight: '305px',
    },
    imageContainer: {
      padding: theme.spacing(3, 0),
    },
    contentContainer: {
      margin: '0 auto',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '70%',
      },
    },
  }),
  { name: 'PagePlaceholder' },
);
