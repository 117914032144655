import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    layout: {
      margin: theme.spacing(1),
      display: 'grid',
    },

    group: {
      margin: theme.spacing(2),
    },

    header: {
      margin: theme.spacing(2, 0, 2, 0),
    },
  }),
  { name: 'Radio' },
);

export default useStyles;
