import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import TableRowContainer from '../../containers/TableRowContainer';

const TableList = ({
  classes,
  tableFields,
  data,
  minHeight,
  generateTableItemId,
  maxHeight,
  ExpandedRowContent,
  onExpand,
  expendedRowId,
  onClick,
  getRowClassName,
}) => {
  return (
    <Scrollbars
      autoHeight
      autoHeightMin={minHeight || 300}
      autoHeightMax={maxHeight || '65vh'}
      renderTrackHorizontal={() => <div className={classes.invisibleScroll} />}
      renderTrackVertical={() => <div className={classes.invisibleScroll} />}>
      <div className={classes.itemsContainer}>
        <Suspense fallback="">
          {data.map((item, i) => {
            const rowId =
              item._id || (generateTableItemId && generateTableItemId(item)) || `TableList-${i}`;
            return (
              <TableRowContainer
                key={rowId}
                tableItem={item}
                tableFields={tableFields}
                ExpandedRowContent={ExpandedRowContent}
                isExpanded={expendedRowId === rowId}
                rowIndex={i}
                onExpand={() =>
                  onExpand(item._id || (generateTableItemId && generateTableItemId(item)))
                }
                onClick={onClick}
                getRowClassName={getRowClassName}
              />
            );
          })}
        </Suspense>
      </div>
    </Scrollbars>
  );
};

TableList.propTypes = {
  tableFields: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  generateTableItemId: PropTypes.func,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
  getRowClassName: PropTypes.func,
};

export default withStyles(styles)(TableList);
