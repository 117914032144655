import React from 'react';
import { reduxForm } from 'redux-form';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TPGrid from 'components/TP-UI/TPGrid';
import TPErrorMessage from 'components/TP-UI/TPErrorMessage';
import TPButton from 'components/TP-UI/TPButton';
import TPDocument from 'components/TP-UI/TPDocument';

import useStyles from './styles';

const MigrationForm = ({ handleSubmit, error, isPending, hub }) => {
  const { t } = useTranslation('');
  const classes = useStyles();
  return (
    <form className={classes.form} onSubmit={handleSubmit} noValidate>
      <TPGrid container spacing={2} direction="column">
        {error && (
          <TPGrid item xs={12}>
            <TPErrorMessage error={error} size="medium" />
          </TPGrid>
        )}
        <TPGrid item container spacing={1} direction="column">
          <TPGrid item xs={12}>
            <TPDocument fullWidth rows={10}>
              <Trans i18nKey={`legal.${hub}`} ns="migrateMyAccount" />
            </TPDocument>
          </TPGrid>
        </TPGrid>
        <TPGrid item xs={12}>
          <TPButton fullWidth primary size="large" type="submit" loading={isPending}>
            {t('migrateMyAccount:confirmButton')}
          </TPButton>
        </TPGrid>
      </TPGrid>
    </form>
  );
};

MigrationForm.propTypes = {
  hub: PropTypes.string,
  form: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isPending: PropTypes.bool.isRequired,
};

export default reduxForm()(MigrationForm);
