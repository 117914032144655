import React from 'react';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import useStyles from './styles';

const TRENDING_SETTINGS = {
  flat: { className: 'flat', Icon: TrendingFlatIcon },
  up: { className: 'up', Icon: CallMadeIcon },
  down: { className: 'down', Icon: CallReceivedIcon },
};

const TPTrendingIcon = ({ trending, size = '', className }) => {
  const classes = useStyles();
  const { className: iconClassName, Icon } = TRENDING_SETTINGS[trending];

  return Icon ? (
    <span
      className={classnames(
        classes.trendingContainer,
        classes[iconClassName],
        { [classes[`${size}Size`]]: size },
        className,
      )}>
      <Icon color="inherit" fontSize="inherit" />
    </span>
  ) : null;
};

TPTrendingIcon.propTypes = {
  trending: PropTypes.oneOf(['down', 'up', 'flat']),
  size: PropTypes.oneOf(['', 'small', 'medium', 'large', 'extraLarge']),
};

export default TPTrendingIcon;
