import { all, put, takeEvery, select, call } from 'redux-saga/effects';
import { stopSubmit, initialize, getFormSyncErrors, updateSyncErrors, change } from 'redux-form';
import { push } from 'modules/reduxNavigation/actions';
import get from 'lodash/get';
import * as geolocationActions from 'modules/geolocation/actions';
import * as actionCreators from '../actions';
import { SIGNUP_FORM } from '../constants';
import { showNotificationError } from 'modules/notifications/actions';
import { DEFAULT_LANG, AUSTRALIA } from 'constants/index';
import * as geolocationSelectors from '../../../../geolocation/selectors';
import changeLanguage from 'utils/changeLanguage';
import showErrorMessage from 'utils/showErrorMessage';
import { Routes } from 'constants/routeConstants';

function* redirectToDashboard() {
  yield put(push(Routes.HOME));
}

function* showError(action) {
  yield put(
    stopSubmit(SIGNUP_FORM, {
      _error: get(action, 'error.data.message'),
    }),
  );
}

function* handleCountryByIpSuccess({ response }) {
  const userCountry = response.data.country;
  if (userCountry === AUSTRALIA) {
    yield changeLanguage(DEFAULT_LANG);
  }
}

function* handleGoogleAuthError(action) {
  yield call(showErrorMessage, action);
}

function* handleAppleAuthError(action) {
  yield call(showErrorMessage, action);
}

function* setDefaultCountry() {
  const defaultCountry = yield select(geolocationSelectors.getCurrentLocation);
  if (!defaultCountry) {
    return;
  }
  const formData = {
    phoneNumber: `+${defaultCountry.callingCodes[0]}`,
    country: defaultCountry,
    phoneCountry: defaultCountry,
  };
  yield put(initialize(SIGNUP_FORM, formData));
}

function* handleVerifyPhone(action) {
  const formErrors = yield select(getFormSyncErrors(SIGNUP_FORM));
  if (formErrors && formErrors.phoneNumber) {
    return;
  }
  yield put(actionCreators.verifyPhoneRequest(action.payload));
}

function* handleVerifyPhoneCode(action) {
  const formErrors = yield select(getFormSyncErrors(SIGNUP_FORM));
  if (formErrors && formErrors.codeConfirmation) {
    return;
  }
  yield put(actionCreators.verifyPhoneCodeRequest(action.payload));
}

function* handleVerifyPhoneError(action) {
  const errorMessage = get(action, 'error.data.message');

  yield put(
    stopSubmit(SIGNUP_FORM, {
      _error: errorMessage,
    }),
  );
  yield put(
    updateSyncErrors(SIGNUP_FORM, {
      phoneNumber: errorMessage,
    }),
  );
}
function* handleVerifyPhoneCodeError(action) {
  const errorMessage = get(action, 'error.data.message');
  yield put(
    stopSubmit(SIGNUP_FORM, {
      _error: errorMessage,
    }),
  );
  yield put(
    updateSyncErrors(SIGNUP_FORM, {
      codeConfirmation: errorMessage,
    }),
  );
}

function* triggerFormValidation() {
  yield put(change(SIGNUP_FORM, 'codeConfirmation', ''));
}

function* showAppleAuthWarning() {
  yield put(
    showNotificationError({
      key: 'auth:signUp.appleSignUpExistUser',
    }),
  );
}

export default function* watchRequest() {
  yield all([
    takeEvery(actionCreators.signupSuccess, redirectToDashboard),
    takeEvery(actionCreators.googleAuthSuccess, redirectToDashboard),
    takeEvery(actionCreators.appleAuthSuccess, redirectToDashboard),
    takeEvery(actionCreators.signupFail, showError),
    takeEvery(actionCreators.googleAuthFail, handleGoogleAuthError),
    takeEvery(actionCreators.appleAuthFail, handleAppleAuthError),
    takeEvery(geolocationActions.getCountryByIpSuccess, handleCountryByIpSuccess),
    takeEvery(geolocationActions.getCountriesSuccess, setDefaultCountry),
    takeEvery(actionCreators.verifyPhone, handleVerifyPhone),
    takeEvery(actionCreators.verifyPhoneCode, handleVerifyPhoneCode),
    takeEvery(
      [actionCreators.verifyPhoneFail, actionCreators.verifyPhoneCodeFail],
      handleVerifyPhoneError,
    ),
    takeEvery(actionCreators.verifyPhoneCodeFail, handleVerifyPhoneCodeError),
    takeEvery(actionCreators.verifyPhoneCodeSuccess, triggerFormValidation),
    takeEvery(actionCreators.appleUserExist, showAppleAuthWarning),
  ]);
}
