import React, { useMemo } from 'react';
import MainLayout from 'modules/common/MainLayout';
import CountryUsers from '../../containers/CountryUsersContainer';
import VolumeUsers from '../../containers/UserVolumesContainer';
import ActiveUsers from '../../containers/ActiveUsersContainer';
import MarketingChannels from '../../containers/MarketingChannelsContainer';
import NetDeposit from '../../containers/NetDepositContainer';
import OverviewData from '../../containers/OverviewDataContainer';
import { useSelector } from 'react-redux';
import { getIsGlobalAdmin } from 'modules/auth/selectors';
import Page from 'modules/common/Page';
import TPTabs from 'components/TP-UI/TPTabs';

const DashboardPage = () => {
  const isGlobalAdmin = useSelector(getIsGlobalAdmin);

  const navTabs = useMemo(() => {
    return [
      {
        label: 'Overview Data',
        content: OverviewData,
        path: '/overview',
      },
      ...(isGlobalAdmin
        ? [
            {
              label: 'Country Users',
              content: CountryUsers,
              path: '/country-users',
            },
          ]
        : []),
      {
        label: 'Volume Users',
        content: VolumeUsers,
        path: '/volume-users',
      },
      {
        label: 'Active Users',
        content: ActiveUsers,
        path: '/active-users',
      },
      {
        label: 'Net Deposit Users',
        content: NetDeposit,
        path: '/net-deposit-users',
      },
      {
        label: 'Marketing Channels',
        content: MarketingChannels,
        path: '/marketing-channels',
      },
    ];
  }, [isGlobalAdmin]);

  return (
    <Page docTitle="Dashboard">
      <MainLayout title="Dashboard">
        <TPTabs tabs={navTabs} nav />
      </MainLayout>
    </Page>
  );
};

export default DashboardPage;
