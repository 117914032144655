import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTheme } from '@material-ui/core';
import TPGrid from 'components/TP-UI/TPGrid';
import TPTypography from 'components/TP-UI/TPTypography';
import TPButton from 'components/TP-UI/TPButton';
import TPModal from 'components/TP-UI/TPModal';
import { useDispatch } from 'react-redux';
import { openModal, closeModal } from 'components/TP-UI/TPModal/actions';
import TPTabs from 'components/TP-UI/TPTabs';
import TPCardAvatar from 'components/TP-UI/TPCard/components/TPCardAvatar';
import TPImage from 'components/TP-UI/TPImage';

import useStyles from './styles';

const DELETE_ACCOUNT_MODAL = 'DELETE_ACCOUNT_MODAL';
const ADD_ACCOUNT_MODAL = 'ADD_ACCOUNT_MODAL';
const ADD_ACCOUNT_WIZARD_MODAL = 'ADD_ACCOUNT_WIZARD_MODAL';
const TERMS_MODAL = 'TERMS_MODAL';
const SUCCESS_CONFIRMATION_WIZARD_MODAL = 'SUCCESS_CONFIRMATION_WIZARD_MODAL';

const LiveForm = () => {
  return (
    <form>
      <TPTypography>form for live account...</TPTypography>
    </form>
  );
};
const DemoForm = () => {
  return (
    <form>
      <TPTypography>form for demo account...</TPTypography>
    </form>
  );
};

const TABS = [
  {
    label: 'Live',
    content: LiveForm,
  },
  {
    label: 'Demo',
    content: DemoForm,
  },
];

const ConfirmationTestModal = () => {
  const dispatch = useDispatch();

  const handleDeleteClick = useCallback(() => {
    //some code here to delete account
    dispatch(closeModal(DELETE_ACCOUNT_MODAL));
  }, [dispatch]);

  const handleCancelDeleteClick = useCallback(() => {
    dispatch(closeModal(DELETE_ACCOUNT_MODAL));
  }, [dispatch]);

  return (
    <TPModal
      modalName={DELETE_ACCOUNT_MODAL}
      title="Delete Confimation"
      text="Are you sure want to delete your account?"
      onClose={handleCancelDeleteClick}
      buttons={[
        { label: 'Delete', primary: true, onClick: handleDeleteClick },
        { label: 'Cancel', secondary: true, onClick: handleCancelDeleteClick },
      ]}
    />
  );
};
const TermsModal = () => {
  return (
    <TPModal modalName={TERMS_MODAL} confirmation title="Terms & Conditions" hideClose>
      <TPTypography variant="body1">
        Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece
        of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock,
        a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure
        Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the
        word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from
        sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum (The Extremes of Good and
        Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very
        popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum dolor sit amet..,
        amet.., comes from a line in section 1.10.32.
      </TPTypography>
      <TPTypography variant="body1">
        The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those
        interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum by Cicero are
        also reproduced in their exact original form, accompanied by English versions from the 1914
        translation by H. Rackham.
      </TPTypography>
    </TPModal>
  );
};
const AddAccountModal = ({ modalName, wizard = false }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const tabs = useMemo(() => [...TABS], []);
  const timeoutRef = useRef();

  const handleAddClick = useCallback(() => {
    setLoading(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setLoading(false);
      dispatch(closeModal(modalName));
    }, 1500);
  }, [dispatch, timeoutRef, modalName]);

  const handleCancelClick = useCallback(() => {
    dispatch(closeModal(modalName));
  }, [dispatch, modalName]);

  const buttons = useMemo(
    () => [
      {
        label: 'Add',
        primary: true,
        onClick: handleAddClick,
      },
      {
        label: 'Cancel',
        secondary: true,
        onClick: handleCancelClick,
      },
    ],
    [handleAddClick, handleCancelClick],
  );
  return (
    <TPModal
      modalName={modalName}
      onClose={handleCancelClick}
      title="Add Account"
      variant={wizard ? 'wizard' : 'primary'}
      buttons={buttons}
      loading={loading}>
      <TPTabs tabs={tabs} />
    </TPModal>
  );
};

const SuccessConfirmationWizardModal = ({ onSubmit }) => {
  const classes = useStyles();
  const { withdraw } = useTheme();
  const { src, ...imageStyles } = withdraw.success.avatar;

  return (
    <TPModal
      modalName={SUCCESS_CONFIRMATION_WIZARD_MODAL}
      variant="wizard"
      justifyContent="center"
      hideButtons
      hideClose>
      <TPGrid container spacing={3} direction="column" alignItems="center">
        <TPGrid container item justifyContent="center" xs={12}>
          <TPGrid item>
            <TPCardAvatar className={classes.imageContainer} primary>
              <TPImage src={src} style={imageStyles} />
            </TPCardAvatar>
          </TPGrid>
        </TPGrid>
        <TPGrid item>
          <TPTypography variant="h3">Contrary to popular belief</TPTypography>
        </TPGrid>
        <TPGrid item>
          <TPTypography variant="body1" align="center">
            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a
            piece of classical Latin literature from 45 BC, making it over 2000 years old.
          </TPTypography>
        </TPGrid>
        <TPGrid item>
          <TPButton primary size="large" onClick={onSubmit}>
            Got It
          </TPButton>
        </TPGrid>
      </TPGrid>
    </TPModal>
  );
};

const Modals = () => {
  const dispatch = useDispatch();
  const handleDeleteClick = useCallback(() => {
    dispatch(openModal(DELETE_ACCOUNT_MODAL));
  }, [dispatch]);

  const handleAddAccountClick = useCallback(() => {
    dispatch(openModal(ADD_ACCOUNT_MODAL));
  }, [dispatch]);

  const handleAddAccountClickWizard = useCallback(() => {
    dispatch(openModal(ADD_ACCOUNT_WIZARD_MODAL));
  }, [dispatch]);

  const handleTermsClick = useCallback(() => {
    dispatch(openModal(TERMS_MODAL));
  }, [dispatch]);

  const handleSuccessConfirmationWizardModalClick = useCallback(() => {
    dispatch(openModal(SUCCESS_CONFIRMATION_WIZARD_MODAL));
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    dispatch(closeModal(SUCCESS_CONFIRMATION_WIZARD_MODAL));
  }, [dispatch]);

  return (
    <>
      <TPGrid container spacing={1}>
        <TPGrid item xs={12}>
          <TPButton primary onClick={handleDeleteClick}>
            Delete account
          </TPButton>
          <ConfirmationTestModal />
        </TPGrid>
        <TPGrid item xs={12}>
          <TPButton primary onClick={handleTermsClick}>
            Terms and condition
          </TPButton>
          <TermsModal />
        </TPGrid>
        <TPGrid item xs={12}>
          <TPButton alternative onClick={handleAddAccountClick}>
            Add Account
          </TPButton>
          <AddAccountModal modalName={ADD_ACCOUNT_MODAL} />
        </TPGrid>
        <TPGrid item xs={12}>
          <TPButton alternative onClick={handleAddAccountClickWizard}>
            Wizard Modal
          </TPButton>
          <AddAccountModal modalName={ADD_ACCOUNT_WIZARD_MODAL} wizard />
        </TPGrid>
        <TPGrid item xs={12}>
          <TPButton primary onClick={handleSuccessConfirmationWizardModalClick}>
            Wizard Confirmation Modal
          </TPButton>
          <SuccessConfirmationWizardModal onSubmit={handleSubmit} />
        </TPGrid>
      </TPGrid>
    </>
  );
};

export default Modals;
