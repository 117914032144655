import React, { lazy, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import isEqual from 'lodash/isEqual';
import TPGrid from 'components/TP-UI/TPGrid';
import { isNodeClickable } from '../../helpers';

const Account = lazy(() => import('../Account'));

const checkAccountsEqual = (oldProps, newProps) => {
  return (
    isEqual(oldProps.accounts, newProps.accounts) &&
    isEqual(oldProps.showHidden, newProps.showHidden) &&
    isEqual(oldProps.showLeverage, newProps.showLeverage)
  );
};

const SortAccounts = ({
  showHidden,
  onSort,
  accounts,
  actions,
  onMyPerformanceView,
  isQuizRequired,
  isLead2,
  isLead3,
  isLead4,
  onCreateAccount,
  onLiveFund,
  showFundDemoAccountModal,
  wasRejected,
  userStatus,
  showLeverage,
  userIsVerified,
  followerId,
  onAcceptInvitation,
  hub,
  onUndoArchived,
  canUserCreateAccounts,
  tradeSyncAccounts,
}) => {
  const SortableItem = SortableElement(({ account }) => {
    return (
      <TPGrid item xs={12} md={6} xl={4}>
        <Account
          account={account}
          onEdit={() => actions.showEditAccountModal(account)}
          onChangeVisibility={actions.hideAccountOpen}
          onMyPerformanceView={onMyPerformanceView}
          isQuizRequired={isQuizRequired}
          isLead2={isLead2}
          isLead3={isLead3}
          isLead4={isLead4}
          onCreateAccount={onCreateAccount}
          onFund={
            account.isLive ? () => onLiveFund(account) : () => showFundDemoAccountModal(account)
          }
          wasRejected={wasRejected}
          userStatus={userStatus}
          showLeverage={showLeverage}
          userIsVerified={userIsVerified}
          followerId={followerId}
          onAcceptInvitation={onAcceptInvitation}
          hub={hub}
          onUndoArchived={onUndoArchived}
          canUserCreateAccounts={canUserCreateAccounts}
          tradeSyncAccounts={tradeSyncAccounts}
        />
      </TPGrid>
    );
  });

  const SortableList = SortableContainer(({ items }) => (
    <TPGrid container spacing={2}>
      {items.map((value, index) => (
        <SortableItem key={value._id} index={index} account={value} />
      ))}
    </TPGrid>
  ));

  const SortableComponent = ({ accounts }) => {
    const [acc, setAcc] = useState(
      accounts
        .filter((account) => !!showHidden === !!account.isHidden)
        .sort((a, b) => a.indexId - b.indexId),
    );

    const sortAccounts = useCallback(({ acc, oldIndex, newIndex }) => {
      onSort({ accountId: acc[oldIndex]._id, indexId: newIndex });
    }, []);

    const shouldCancelStart = useCallback((e) => {
      if (isNodeClickable(e.target, e.currentTarget) || document.body.clientWidth < 1085) {
        return true;
      }
    }, []);

    const onSortEnd = useCallback(
      ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
          const sorted = arrayMove(acc, oldIndex, newIndex);
          setAcc(sorted);
          sortAccounts({ acc, oldIndex, newIndex });
        }
      },
      [acc, sortAccounts],
    );

    return (
      <SortableList
        axis="xy"
        items={acc}
        onSortEnd={onSortEnd}
        shouldCancelStart={shouldCancelStart}
      />
    );
  };

  return <SortableComponent accounts={accounts} />;
};

SortAccounts.propTypes = {
  onLiveFund: PropTypes.func.isRequired,
  onMyPerformanceView: PropTypes.func.isRequired,
  onCreateAccount: PropTypes.func.isRequired,
  showFundDemoAccountModal: PropTypes.func.isRequired,
  onAcceptInvitation: PropTypes.func.isRequired,
  accounts: PropTypes.array,
  actions: PropTypes.object.isRequired,
  isQuizRequired: PropTypes.bool,
  isLead2: PropTypes.bool.isRequired,
  isLead3: PropTypes.bool.isRequired,
  isLead4: PropTypes.bool.isRequired,
  wasRejected: PropTypes.bool.isRequired,
  userStatus: PropTypes.string.isRequired,
  hub: PropTypes.string.isRequired,
  onUndoArchived: PropTypes.func.isRequired,
  canUserCreateAccounts: PropTypes.bool,
};

export default React.memo(SortAccounts, checkAccountsEqual);
