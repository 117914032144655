import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    spacing: {
      margin: theme.spacing(1),
    },
    content: {
      margin: theme.spacing(3),
    },
  }),
  { name: 'Drawers' },
);

export default useStyles;
