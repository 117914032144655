export const WITHDRAW_SEARCH_FORM = 'WITHDRAW_SEARCH_FORM';

export const INITIAL_FILTERS_VALUES = {
  search: '',
  dateFrom: '',
  dateTo: '',
};

export const DEFAULT_PAGINATION = {
  currentPage: 0,
  pageSize: 10,
  total: 0,
};

export const DEFAULT_HISTORY_PERIOD_DAYS = 60;
