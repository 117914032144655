import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'redux-form';
import * as apiSelectors from 'modules/api/selectors';
import MarketingChannelsView from '../views/MarketingChannelsView';
import MarketingDataExport from '../views/MarketingDataExport';
import {
  getMarketingChannelDataRequest,
  getMarketingChannelSourcesRequest,
  getMarketingExportRequest,
  openMarketingExportModal,
} from '../actions';
import { getMarketingChannelData, getMarketingSources } from '../selectors';
import moment from 'moment';
import get from 'lodash/get';
import {
  DASHBOARD_MARKETING_CHANNEL_HEADER_FORM,
  CUSTOM_DATE_RANGE_KEY,
  ACCOUNT_MARKETING_CHANNEL_TYPES,
  MARKETING_DATA_EXPORT_MODAL,
  MARKETING_DATA_EXPORT_FORM,
  MARKETING_GROUP_BY,
} from '../constants';
import { DATE_FORMAT_YYYYMMDD } from '../../../constants';
import FormSelect from 'modules/common/FormSelect';
import CustomModal from 'modules/common/CustomModal';
import PrimaryButton from 'modules/common/PrimaryButton';
import { store } from '../../../components/App';

const MarketingChannelsContainer = () => {
  const dispatch = useDispatch();
  const isPending = useSelector(apiSelectors.createPendingSelector(getMarketingChannelDataRequest));
  const isPendingExport = useSelector(
    apiSelectors.createPendingSelector(getMarketingExportRequest),
  );

  const marketingChannelData = useSelector(getMarketingChannelData).filter(
    ({ data }) => data !== '',
  );
  const marketingSources = useSelector(getMarketingSources);

  useEffect(() => {
    const dateFrom = moment()
      .startOf('month')
      .format(DATE_FORMAT_YYYYMMDD);
    const dateTo = moment().format(DATE_FORMAT_YYYYMMDD);
    import('../sagas/marketingChannels').then((saga) => {
      store.injectSaga('MarketingChannelsContainer', saga.default);
      dispatch(getMarketingChannelDataRequest({ dateFrom, dateTo }));
      dispatch(getMarketingChannelSourcesRequest());
    });
  }, [dispatch]);

  const handleHeaderFormSubmit = useCallback(
    (data) => {
      const dateFrom = get(data, 'dateFrom', moment());
      const dateTo = get(data, 'dateTo', moment());
      const type = get(data, 'accountType', 'default');
      const groupBy = get(data, 'groupBy', 'source');
      const formattedDateFrom = moment(dateFrom).format(DATE_FORMAT_YYYYMMDD);
      const formattedDateTo = moment(dateTo).format(DATE_FORMAT_YYYYMMDD);

      dispatch(
        getMarketingChannelDataRequest({
          dateFrom: formattedDateFrom,
          dateTo: formattedDateTo,
          type,
          groupBy,
        }),
      );
    },
    [dispatch],
  );

  const openMarketingExport = useCallback(() => {
    dispatch(openMarketingExportModal());
  }, [dispatch]);

  const headerProps = {
    form: DASHBOARD_MARKETING_CHANNEL_HEADER_FORM,
    initialValues: {
      customDateSelector: CUSTOM_DATE_RANGE_KEY.MONTH_TO_DATE,
      dateFrom: moment()
        .startOf('month')
        .format(DATE_FORMAT_YYYYMMDD),
      dateTo: moment().format(DATE_FORMAT_YYYYMMDD),
      groupBy: 'source',
      accountType: 'default',
    },
    onSubmit: handleHeaderFormSubmit,
    additionalFilter: (
      <>
        <PrimaryButton
          color="secondary"
          size="small"
          label={'Export'}
          type="button"
          onClick={openMarketingExport}
        />{' '}
        <Field
          name="groupBy"
          id="groupBy"
          component={FormSelect}
          items={MARKETING_GROUP_BY}
          multiple={false}
        />
        <Field
          name="accountType"
          id="accountType"
          component={FormSelect}
          items={ACCOUNT_MARKETING_CHANNEL_TYPES}
          multiple={false}
        />
      </>
    ),
  };

  const exportData = useCallback(
    (data) => {
      dispatch(getMarketingExportRequest(data));
    },
    [dispatch],
  );

  return (
    <>
      <MarketingChannelsView
        headerProps={headerProps}
        isPending={isPending}
        items={marketingChannelData}
      />
      <CustomModal modalName={MARKETING_DATA_EXPORT_MODAL}>
        <MarketingDataExport
          onSubmit={exportData}
          form={MARKETING_DATA_EXPORT_FORM}
          sources={marketingSources}
          isPending={isPendingExport}
        />
      </CustomModal>
    </>
  );
};

export default MarketingChannelsContainer;
