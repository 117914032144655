import api from '..';

export const symbolConfigRequest = () => {
  return api.get('/trading-calculators/symbol');
};

const getParams = (payload) => {
  const params = { ...payload };
  if (typeof params.symbol === 'object') {
    params.symbol = params.symbol.value;
  }
  return params;
};

export const currencyCalculatorRequest = (payload) => {
  return api.get('/trading-calculators/currency', { params: getParams(payload) });
};

//todo: removed it when will the epic FDS-2795 be completed
export const limitsCurrencyCalculatorRequest = (payload) => {
  return api.get('/trading-calculators/currency', { params: payload });
};

export const pipValueCalculatorRequest = (payload) => {
  return api.get('/trading-calculators/pip-value', { params: payload });
};

export const marginCalculatorRequest = (payload) => {
  return api.get('/trading-calculators/margin', { params: payload });
};

export const swapsCalculatorRequest = (payload) => {
  return api.get('/trading-calculators/swaps', { params: payload });
};

export const profitLossCalculatorRequest = (payload) => {
  return api.get('/trading-calculators/profit-loss', { params: payload });
};
