import React from 'react';

import TPGrid from 'components/TP-UI/TPGrid';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import TPDivider from 'components/TP-UI/TPDivider';
import TPTypography from 'components/TP-UI/TPTypography';
import TPIconButton from 'components/TP-UI/TPIconButton';
import NotificationsNone from '@material-ui/icons/NotificationsNone';
import useStyles from './styles';

const Dividers = () => {
  const classes = useStyles();

  return (
    <TPGrid container direction="row" spacing={5}>
      <TPGrid item container direction="row" spacing={5} className={classes.row}>
        <TPGrid item className={classes.primaryContainer}>
          <List>
            <ListItem>
              <TPTypography className={classes.text}>Lorem Ipsum</TPTypography>
            </ListItem>
            <TPDivider />
            <ListItem>
              <TPTypography className={classes.text}>Lorem Ipsum</TPTypography>
            </ListItem>
            <TPDivider variant="middle" />
            <ListItem>
              <TPTypography className={classes.text}>Lorem Ipsum</TPTypography>
            </ListItem>
          </List>
        </TPGrid>

        <TPGrid item className={classes.primaryContainer}>
          <List>
            <ListItem>
              <TPTypography className={classes.text}>Lorem Ipsum</TPTypography>
            </ListItem>
            <TPDivider variant="inset" />
            <ListItem>
              <TPTypography className={classes.text}>Lorem Ipsum</TPTypography>
            </ListItem>
            <TPDivider variant="inset" />
            <ListItem>
              <TPTypography className={classes.text}>Lorem Ipsum</TPTypography>
            </ListItem>
          </List>
        </TPGrid>
        <TPGrid item className={classes.primaryContainer}>
          <List>
            <ListItem>
              <TPTypography className={classes.text}>Lorem Ipsum</TPTypography>
            </ListItem>
            <TPDivider />
            <ListItem>
              <TPTypography className={classes.text}>Lorem Ipsum</TPTypography>
            </ListItem>
            <TPDivider absolute className={classes.divider} />
            <ListItem>
              <TPTypography className={classes.text}>Lorem Ipsum</TPTypography>
            </ListItem>
          </List>
        </TPGrid>
      </TPGrid>

      <TPGrid item container direction="row" spacing={5} className={classes.row}>
        <TPGrid item className={classes.secondaryContainer}>
          <TPTypography>Lorem Ipsum</TPTypography>
          <TPDivider orientation="vertical" flexItem />
          <TPTypography>Lorem Ipsum</TPTypography>
        </TPGrid>
        <TPGrid item className={classes.tertiaryContainer}>
          <TPIconButton size="small">
            <NotificationsNone />
          </TPIconButton>
          <TPDivider orientation="vertical" flexItem />
          <TPIconButton size="small">
            <NotificationsNone />
          </TPIconButton>
        </TPGrid>
        <TPGrid item className={classes.primaryContainer}>
          <TPTypography className={classes.text}>Lorem Ipsum</TPTypography>
          <TPDivider light />
          <TPTypography className={classes.text}>Lorem Ipsum</TPTypography>
        </TPGrid>
      </TPGrid>
    </TPGrid>
  );
};

export default Dividers;
