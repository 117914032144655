import React, { useEffect } from 'react';
import DashboardPage from '../views/DashboardView';
import { useDispatch } from 'react-redux';
import { getDashboardStatsCustomizationDataRequest } from '../actions';
import { store } from 'components/App';
import saga from '../sagas';

const DashboardContainer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    store.injectSaga('DashboardContainer', saga);
    return () => {
      store.injectSaga('DashboardContainer', null);
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDashboardStatsCustomizationDataRequest());
  });

  return <DashboardPage />;
};

export default DashboardContainer;
