import palette from './palette';
import { fontSize, fontWeight } from '../font';

const OverridesMuiTab = {
  root: {
    color: palette.text.primary,
    border: `1px solid ${palette.divider}`,
    backgroundColor: 'transparent',
    padding: '6px 16px',
    minWidth: 'auto',
    maxWidth: 'auto',
    minHeight: 36,
    fontSize: fontSize._18,
    fontWeight: fontWeight.regular,
    textTransform: 'capitalize',
    borderRadius: 10,
    '@media (min-width: 600px)': {
      minWidth: 'auto',
    },
    '&:hover': {
      borderColor: palette.primary.light,
    },
    '&$selected': {
      backgroundColor: palette.primary.lightest,
      borderColor: palette.primary.lightest,
      color: palette.text.primary,
      fontWeight: fontWeight.bold,
    },
  },
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textColorInherit: {
    opacity: 1,
  },
};

export default OverridesMuiTab;
