import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    icon: {
      fontSize: theme.font.fontSize._16,
    },
    root: {
      marginBottom: 0,
      marginRight: 20,
    },
    rootLabel: {
      display: 'flex',
      alignItems: 'flex-start',
      cursor: 'pointer',
      fontSize: theme.typography.body1.fontSize,
      lineHeight: 1.5,
    },
    label: {
      paddingTop: theme.spacing(0.5),
      color: theme.palette.text.primary,
      ...theme.typography.body1,
      lineHeight: 1.5,
    },
    disabled: {
      opacity: 0.5,
    },
    errorIcon: {
      color: theme.palette.error.main,
    },
  }),
  { name: 'TPRadio' },
);
