import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';

import useStyles from './styles';

const TPTag = ({ label, size = 'small' }) => {
  const classes = useStyles();
  return <Chip label={label} size={size} className={classes.root} />;
};

TPTag.propTypes = {
  label: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
};

export default TPTag;
