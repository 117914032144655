import { handleActions } from 'redux-actions';
import { SHOW_NOTIFICATION_ALERT, HIDE_NOTIFICATION_ALERT, RESET_NOTIFICATIONS } from '../actions';
import { ALERT_VARIANTS } from 'components/TP-UI/TPAlert/constants';
import { anchorOriginDefault } from 'components/TP-UI/TPSnackbar/config';
import {
  MAX_VISIBLE_SNACKBARS,
  AUTO_HIDE_VARIANT_RESTRICTIONS,
} from 'components/TP-UI/TPSnackbar/context/constants';

const initialState = {
  queue: [],
  waitingQueue: [],
  defaults: {
    anchorOrigin: anchorOriginDefault,
    variant: ALERT_VARIANTS.INFO,
    message: '',
    open: false,
    autoHide: true,
    autoHideDuration: 2000,
    hideIcon: false,
    minWidth: '400px',
    maxWidth: '400px',
  },
};

const createSnackbarWithDefaults = (state, payload) => {
  const autoHide =
    payload.autoHide ??
    (!AUTO_HIDE_VARIANT_RESTRICTIONS.includes(payload.variant) && state.defaults.autoHide);

  const autoHideDuration = autoHide
    ? payload.autoHideDuration ?? state.defaults.autoHideDuration
    : null;

  return {
    ...state.defaults,
    ...payload,
    key: `tpsnackbar-${Date.now()}-${state.queue.length}`,
    autoHideDuration,
  };
};

const snackbarReducer = handleActions(
  {
    [SHOW_NOTIFICATION_ALERT]: (state, { payload }) => {
      const newSnackbar = createSnackbarWithDefaults(state, payload);
      let newQueue = [...state.queue];
      let newWaitingQueue = [...state.waitingQueue];
      if (newQueue.length < MAX_VISIBLE_SNACKBARS) {
        newQueue.push(newSnackbar);
      } else {
        newWaitingQueue.push(newSnackbar);
      }
      return {
        ...state,
        queue: newQueue,
        waitingQueue: newWaitingQueue,
      };
    },

    [HIDE_NOTIFICATION_ALERT]: (state, { payload }) => {
      let filteredQueue = state.queue.filter((snackbar) => snackbar.key !== payload);
      let newWaitingQueue = [...state.waitingQueue];
      if (newWaitingQueue.length > 0) {
        const nextSnackbar = newWaitingQueue.shift();
        filteredQueue.push({
          ...nextSnackbar,
          key: `tpsnackbar-${Date.now()}-${filteredQueue.length + 1}`,
        });
      }
      return {
        ...state,
        queue: filteredQueue,
        waitingQueue: newWaitingQueue,
      };
    },

    [RESET_NOTIFICATIONS]: () => initialState,
  },
  initialState,
);

export default snackbarReducer;
