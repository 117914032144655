import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    form: {
      width: '100%',
    },
    spacing: {
      marginBottom: theme.spacing(1),
    },
  }),
  { name: 'TinyEditor' },
);

export default useStyles;
