import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    customLinkWrapper: {
      marginTop: theme.spacing(3),
      textAlign: 'center',
    },
  }),
  { name: 'SignUp' },
);

export default useStyles;
