import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  () => ({
    pageSizeContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
  }),
  { name: 'TPPageSize' },
);
