const getAuthData = (data) => {
  const token = new URLSearchParams(data).get('token', null);
  const id = new URLSearchParams(data).get('id', null);
  const refreshToken = new URLSearchParams(data).get('refreshToken', null);

  if (!token || !id || !refreshToken) {
    return null;
  }

  return {
    token,
    _id: id,
    refreshToken,
    role: 'client',
  };
};

export default getAuthData;
