export const USER_MANAGEMENT_FORM = 'USER_MANAGEMENT_FORM';

export const INITIAL_FILTERS_VALUES = {
  search: '',
  dateRangeBy: '',
  dateFrom: '',
  dateTo: '',
  status: [],
  country: [],
  firstTrade: [],
  lastTrade: [],
  lastLogin: [],
  lastDemo: [],
  userType: [],
  volumeDate: '',
  volume: [],
  callStatus: [],
  leadId: '',
  riskStatus: [],
};

export const CLIENTS_PRESET_FILTERS_TYPE = 'CLIENTS';
