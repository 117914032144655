import { all, put, takeEvery } from 'redux-saga/effects';
import { change, stopSubmit, initialize } from 'redux-form';
import { push } from 'modules/reduxNavigation/actions';
import get from 'lodash/get';

import * as actionCreators from '../actions';
import { FORGOT_PASSWORD_FORM, FORGOT_PASSWORD_SUCCESS_MODAL } from '../constants';
import { openModal, closeModal } from 'components/TP-UI/TPModal/actions';
import { FUSION_MARKETS_PREFIX } from '../../../../../constants';
import { setSelectHubOpen, setSelectAvailableHubs } from '../actions';
import { HUB_CODES } from '../../../components/SelectHubModal/config';
import { Routes } from 'constants/routeConstants';
import { LOGOUT_REASON } from '../../../constants';

function* redirectToSignIn() {
  yield put(closeModal(FORGOT_PASSWORD_SUCCESS_MODAL));
  yield put(push(Routes.SIGNIN, { reason: LOGOUT_REASON.USER_ACTION }));
}

function* showError(action) {
  const errorId = get(action, 'error.data.index');
  if (errorId === '155') {
    const hubs = get(action, 'error.data.hubs');
    yield put(setSelectHubOpen(true));
    yield put(setSelectAvailableHubs(hubs));
    yield put(
      change(FORGOT_PASSWORD_FORM, 'prefix', get(HUB_CODES, get(hubs, '0'), FUSION_MARKETS_PREFIX)),
    );
    return;
  }
  yield put(
    stopSubmit(FORGOT_PASSWORD_FORM, {
      _error: get(action, 'error.data.message'),
    }),
  );
}

function* handleForgotPasswordSuccess() {
  yield put(openModal(FORGOT_PASSWORD_SUCCESS_MODAL));
}

function* initializeForm() {
  yield put(initialize(FORGOT_PASSWORD_FORM));
}

export default function* watchRequest() {
  yield all([
    takeEvery(actionCreators.redirectToSignIn, redirectToSignIn),
    takeEvery(actionCreators.forgotPasswordFail, showError),
    takeEvery(actionCreators.forgotPasswordSuccess, handleForgotPasswordSuccess),
    takeEvery(actionCreators.initializeForm, initializeForm),
  ]);
}
