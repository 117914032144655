import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import TPGrid from 'components/TP-UI/TPGrid';
import TPRadioGroup from 'components/TP-UI/TPRadioGroup';
import TPTypography from 'components/TP-UI/TPTypography';
import FormError from 'modules/common/FormError';
import { required } from 'utils/validation/fieldValidationRules';

const QuizForm = (props) => {
  const { questionId, questionLabel, answers, error, submitFailed } = props;

  return (
    <TPGrid container spacing={3}>
      {error && submitFailed && (
        <TPGrid item xs={12}>
          <FormError error={error} />
        </TPGrid>
      )}
      <TPGrid item container spacing={1}>
        <TPGrid item xs={12}>
          <TPTypography variant="body1">{questionLabel}</TPTypography>
        </TPGrid>
        {Array.isArray(answers) && (
          <TPGrid item xs={12}>
            <Field
              buttons={answers}
              component={TPRadioGroup}
              name={questionId}
              validate={[required]}
            />
          </TPGrid>
        )}
      </TPGrid>
    </TPGrid>
  );
};

QuizForm.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  questionId: PropTypes.string,
  questionLabel: PropTypes.string,
};

export default QuizForm;
