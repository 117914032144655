import React from 'react';
import TPGrid from 'components/TP-UI/TPGrid';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import TPTextField from 'components/TP-UI/TPTextField';
import TPTypography from 'components/TP-UI/TPTypography';
import TPInputAdornment from 'components/TP-UI/TPInputAdornment';
import Search from '@material-ui/icons/Search';
import TPSelect from 'components/TP-UI/TPSelect';
import Avatar from '@material-ui/core/Avatar';
import TPSearchTextField from 'components/TP-UI/TPSearchTextField';
import TPPhoneTextField from 'components/TP-UI/TPPhoneTextField';
import countries from './countries.json';
import AmountTextField from 'modules/common/AmountTextField';
import { useSelector } from 'react-redux';
import TPCopyButton from 'components/TP-UI/TPCopyButton';
import { integer } from '../../../utils/normalizations';

import useStyles from './styles';

const testRequire = (value) => {
  return value === false ? { key: 'common:errors.form.isRequired' } : undefined;
};

const TextFields = () => {
  const classes = useStyles();
  const copyFieldValue = useSelector((state) =>
    formValueSelector('TP-TEXTFIELDS-FORM')(state, 'copy'),
  );
  return (
    <form className={classes.form}>
      <TPGrid container spacing={1}>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Normal, default size</TPTypography>
          <Field
            component={TPTextField}
            id="age"
            name="age"
            label="Age"
            placeholder="Please enter age"
            fullWidth
            required
            clearable
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Multiline (textarea), optional</TPTypography>
          <Field
            component={TPTextField}
            id="type"
            name="type"
            multiline={true}
            label="Type"
            minRows={2}
            placeholder="Please enter text"
            fullWidth
            clearable={true}
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Hint, type number</TPTypography>
          <Field
            component={TPTextField}
            id="age"
            name="age"
            type="number"
            label="Age"
            placeholder="Please enter age"
            fullWidth
            required
            hint="Additional information"
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">No label</TPTypography>
          <Field
            component={TPTextField}
            id="currency"
            name="currency"
            placeholder="No label"
            fullWidth
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Error</TPTypography>
          <Field
            component={TPTextField}
            id="currencyR"
            name="currencyR"
            label="Currency"
            placeholder="Please enter currency"
            fullWidth
            validate={[testRequire]}
            required
            hint="Additional info"
            meta={{ error: { key: 'common:errors.form.isRequired' }, submitFailed: true }}
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Disabled</TPTypography>
          <Field
            component={TPTextField}
            id="currency"
            name="currency"
            label="Disabled"
            disabled
            placeholder="Please enter currency"
            fullWidth
            hint="Additional info"
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Small size, clearable</TPTypography>
          <Field
            component={TPTextField}
            id="country"
            name="country"
            placeholder="small size"
            fullWidth
            size="small"
            hint="Additional info"
            clearable
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Confidential (e.g. password)</TPTypography>
          <Field
            component={TPTextField}
            id="password"
            name="password"
            placeholder="Password"
            type="password"
            autocomplete="current-password"
            confidential
            fullWidth
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Copy to clipboard</TPTypography>
          <Field
            component={TPTextField}
            id="copy"
            name="copy"
            type="text"
            fullWidth
            className={classes.control}
            endAdornment={<TPCopyButton text={copyFieldValue} />}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Custom selected value render</TPTypography>
          <Field
            component={TPTextField}
            id="amount"
            name="amount"
            label="Amount"
            type="number"
            placeholder="Please select amount"
            fullWidth
            startAdornment="$"
            required
            hint="Additional information"
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Custom selected value render, type tel</TPTypography>
          <Field
            component={TPTextField}
            id="phone"
            name="phone"
            label="Phone number"
            type="tel"
            placeholder="Please enter phone number"
            fullWidth
            startAdornment="+"
            required
            hint="Additional information"
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Start adornment with icon</TPTypography>
          <Field
            component={TPTextField}
            id="search"
            name="search"
            type="text"
            placeholder="Search"
            fullWidth
            required
            className={classes.control}
            clearable
            startAdornment={
              <TPInputAdornment position="start">
                <Search />
              </TPInputAdornment>
            }
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Start adornment with select</TPTypography>
          <Field
            component={TPTextField}
            id="search"
            name="search"
            type="text"
            placeholder="Search"
            fullWidth
            required
            className={classes.control}
            clearable
            startAdornment={
              <TPInputAdornment position="start">
                <Field
                  component={TPSelect}
                  id="country"
                  name="country"
                  variant="custom"
                  reservedErrorSpace={false}
                  options={[
                    {
                      label: 'England',
                      value: 'England',
                      img: 'http://localhost:3000/static/media/en.2e9c71c7.svg',
                    },
                    {
                      label: 'Thai',
                      value: 'Thai',
                      img: 'http://localhost:3000/static/media/th.9667e838.svg',
                    },
                  ]}
                  renderOption={(option) => (
                    <>
                      <Avatar className={classes.lngIcon} alt={option.label} src={option.img} />{' '}
                      {option.label}
                    </>
                  )}
                />
              </TPInputAdornment>
            }
          />
        </TPGrid>
      </TPGrid>
      <TPGrid container spacing={1}>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">
            TPSearchTextField(debounced) component: default size
          </TPTypography>
          <Field
            component={TPSearchTextField}
            id="search"
            name="search"
            placeholder="Search"
            fullWidth
            className={classes.control}
            clearable
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">
            TPSearchTextField(debounced) component: small size
          </TPTypography>
          <Field
            component={TPSearchTextField}
            id="search2"
            name="search2"
            placeholder="Search"
            fullWidth
            className={classes.control}
            clearable
            size="small"
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">
            AmountTextField component decimal allowed (based on TPTextField)
          </TPTypography>
          <Field
            component={AmountTextField}
            id="amount"
            name="amount"
            label="Amount"
            placeholder="Amount"
            currency="eur"
            fullWidth
            className={classes.control}
            clearable
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">
            AmountTextField component integer only (based on TPTextField)
          </TPTypography>
          <Field
            component={AmountTextField}
            id="amount"
            name="amount"
            label="Amount"
            placeholder="Amount"
            currency="eur"
            fullWidth
            className={classes.control}
            clearable
            type="text"
            normalize={integer}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">TPPhoneTextField component</TPTypography>
          <Field
            component={TPPhoneTextField}
            label="Phone Number"
            id="phone"
            name="phone"
            placeholder="Enter phone"
            options={countries}
            groupBy="region"
            fullWidth
            autofocus
            className={classes.control}
            clearable
          />
        </TPGrid>
      </TPGrid>
    </form>
  );
};

export default reduxForm({
  form: 'TP-TEXTFIELDS-FORM',
  initialValues: {
    age: null,
    type: '',
    password: '',
    currency: 1,
    currencyR: null,
    country: 'England',
    amount: 0,
    phone: null,
    search: '',
    search2: '',
    copy: 'Copy this text to the clipboard',
  },
})(TextFields);
