import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';

import styles from './styles';

const PagesList = ({ classes, pages, currentPage, onPageChange }) => {
  return (
    <div className={classes.stepper}>
      {pages.map((page) => {
        const isCurrentStep = isEqual(page, currentPage);
        return (
          <button
            key={page}
            value={page}
            onClick={onPageChange}
            className={classNames(classes.pages, { [classes.currentPage]: isCurrentStep })}>
            {page}
          </button>
        );
      })}
    </div>
  );
};

PagesList.propTypes = {
  pages: PropTypes.array,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(PagesList);
