import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'modules/reduxNavigation/actions';
import { useTranslation } from 'react-i18next';

import TPModal from 'components/TP-UI/TPModal';
import { closeModal } from 'components/TP-UI/TPModal/actions';
import { Routes } from 'constants/routeConstants';

import verificationModalNames from '../../constants/verificationModalNames';
import QuizModalContent from '../QuizModalContent';
import { useTheme } from '@material-ui/core';

const UpgradeQuizBlockedModal = () => {
  const { t } = useTranslation('quiz');
  const dispatch = useDispatch();
  const { quiz } = useTheme();
  const imageConfig = quiz.upgradeBlockedModal.avatar;

  const handleClose = useCallback(() => {
    dispatch(push(Routes.MY_ACCOUNTS));
    dispatch(closeModal(verificationModalNames.UPGRADE_BLOCKED_MODAL));
  }, [dispatch]);

  return (
    <TPModal
      modalName={verificationModalNames.UPGRADE_BLOCKED_MODAL}
      title={t('pro.page.title')}
      onClose={handleClose}>
      <QuizModalContent
        imageConfig={imageConfig}
        text={t('upgradeQuizBlockedModal.description')}
        title={t('upgradeQuizBlockedModal.title')}
      />
    </TPModal>
  );
};

export default UpgradeQuizBlockedModal;
