import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      position: 'absolute',
      width: '520px',
      maxWidth: '95%',
      outline: 'none',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%) !important',
      maxHeight: '95%',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(3),
      ...theme.modal.root,
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
      },
    },
    rootWizard: {
      '& $root': {
        width: '1104px', //based on design
        boxShadow: 'none',
        maxHeight: '100%',
        maxWidth: '100%',
      },
    },
    justifyTop: {
      '& $root': {
        top: '2.5%',
        transform: 'translate(-50%, -2.5%) !important',

        [theme.breakpoints.up('sm')]: {
          top: '10%',
          transform: 'translate(-50%, -10%) !important',
        },
      },
    },
    fullWidth: {
      width: '100%',
    },
    titleContainer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
      overflowWrap: 'break-word',
    },
    titleEndAdornment: {
      display: 'inline-block',
      paddingLeft: theme.spacing(1),
    },
    contentContainer: {
      overflow: 'auto',
      width: '100%',
    },
    content: {
      overflow: 'hidden',
      paddingTop: theme.spacing(1), // space for form label
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),

      [theme.breakpoints.up('sm')]: {
        right: theme.spacing(2),
        top: theme.spacing(2),
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: theme.spacing(3),
      gap: theme.spacing(2),
    },
  }),
  { name: 'TPModal' },
);
