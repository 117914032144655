import { handleActions } from 'redux-actions';
import { getPaymentsPermissionsFail, getPaymentsPermissionsSuccess } from '../actions';

const defaultState = {
  withdraw: {
    allowed: [],
  },
  deposit: {
    allowed: [],
  },
};

export default handleActions(
  {
    [getPaymentsPermissionsSuccess](state, action) {
      return {
        ...state,
        ...action.response.data,
      };
    },
    [getPaymentsPermissionsFail]() {
      return defaultState;
    },
  },
  defaultState,
);
