import React, { useState } from 'react';
import classNames from 'classnames';
import TPGrid from 'components/TP-UI/TPGrid';
import config from 'config';
import TPSidebarLogo from 'components/TP-UI/TPSidebarLogo';
import { THEME_NAME } from 'constants/theme';

import useStyles from './styles';

const { LOGO, CURRENT_HUB_LABEL } = config;

const SidebarLogos = () => {
  const linkTo = '/auth/sign-in';
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const toggle = () => setExpanded((prevState) => !prevState);

  const wrapperClassName = classNames(
    {
      [classes.maximized]: expanded,
      [classes.minimized]: !expanded,
    },
    classes.logoWrapper,
  );

  return (
    <TPGrid id="logoBlock" item container alignItems="center" className={wrapperClassName}>
      <TPSidebarLogo
        alt={CURRENT_HUB_LABEL}
        src={LOGO[THEME_NAME.LIGHT][expanded ? 'origin' : 'short']}
        path={linkTo}
        expanded={expanded}
        aria-controls="logoBlock"
        onExpandClick={toggle}
      />
    </TPGrid>
  );
};

export default SidebarLogos;
