import React, { useEffect } from 'react';

const GoogleTagManager = () => {
  const styles = 'display:none;visibility:hidden';

  useEffect(() => {
    const script = document.createElement('script');

    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-PZG4W4R');`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-PZG4W4R"
        title="Google Tag Manager"
        height="0"
        width="0"
        style={{ styles }}></iframe>
    </noscript>
  );
};

export default GoogleTagManager;
