const styles = () => ({
  sortButton: {
    padding: '0',
    width: '15px',
    height: '15px',
    '& svg': {
      width: '15px',
      height: '15px',
    },
  },
  sortDisabled: {
    opacity: '0.3',
  },
  sortDesc: {
    transform: 'rotate(180deg)',
  },
});

export default styles;
