import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  () => ({
    modalContainer: {
      width: '400px',
    },
  }),
  { name: 'SelectHubModal' },
);

export default useStyles;
