import { combineActions, handleActions } from 'redux-actions';

import * as actionCreators from '../actions';
import { WITHDRAW_METHOD_LABEL } from '../constants';
const defaultState = [];

export default handleActions(
  {
    [combineActions(actionCreators.searchWithdrawHistorySuccess)](state, action) {
      const withdrawals = action.response.data.withdrawals || [];
      return {
        ...action.response.data,
        withdrawals: withdrawals.map((withdrawal) => ({
          ...withdrawal,
          type: WITHDRAW_METHOD_LABEL[withdrawal.type] || withdrawal.type,
        })),
      };
    },
  },
  defaultState,
);
