import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import classnames from 'classnames';

import TPFormLabel from 'components/TP-UI/TPFormLabel';
import { TPCheckbox } from '../TPCheckbox';
import TPErrorMessage from '../TPErrorMessage';

import useStyles from './styles';

export const TPCheckboxGroup = ({
  name,
  value,
  legend,
  options,
  size = 'medium',
  disabled,
  focused,
  error,
  reservedErrorSpace = true,
  onChange,
}) => {
  const classes = useStyles();

  const handelChange = useCallback(
    (event) => {
      const index = value.indexOf(event.target.name);
      let newVal = [...value];
      if (index >= 0) {
        newVal.splice(index, 1);
      } else {
        newVal.push(event.target.name);
      }
      onChange(newVal);
    },
    [value, onChange],
  );

  return (
    <FormControl component="fieldset" name={name}>
      {legend && (
        <TPFormLabel component="legend" disabled={disabled} focuses={focused}>
          {legend}
        </TPFormLabel>
      )}
      <>
        {options?.map((option) => (
          <TPCheckbox
            size={size}
            key={option.label}
            name={option.value}
            value={value?.includes(option.value)}
            label={option.label}
            disabled={disabled}
            reservedErrorSpace={false}
            onChange={handelChange}
          />
        ))}
      </>
      <div className={classnames({ [classes.errorContainer]: reservedErrorSpace })}>
        {error && (
          <TPErrorMessage error={error} size="small" className={classes[`error_${size}`]} />
        )}
      </div>
    </FormControl>
  );
};

TPCheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  legend: PropTypes.string,
  value: PropTypes.array.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any,
      value: PropTypes.any,
      disabled: PropTypes.bool,
    }),
  ),
  disabled: PropTypes.bool,
  focused: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
  onChange: PropTypes.func.isRequired,
};

const TPReduxCheckboxGroup = ({ input, meta, ...others }) => {
  const error = meta.submitFailed && meta.error ? meta.error : null;
  const { onChange, onBlur, value } = input;
  const handleBlur = useCallback(() => onBlur(value), [onBlur, value]);

  return (
    <TPCheckboxGroup {...input} error={error} {...others} onChange={onChange} onBlur={handleBlur} />
  );
};

export default TPReduxCheckboxGroup;
