import moment from 'moment';
import { get } from 'lodash';

export const formatDateTime = (date) => {
  if (!date) {
    return '';
  }
  return moment(date)
    .utc()
    .format('DD MMM YYYY HH:mm:ss');
};

export const getMasterAccountName = (invite) => {
  if (invite.leadAccountId) {
    return `${get(invite, 'leadAccountId.accountNickname', '')} ${get(
      invite,
      'leadAccountId.mt4login',
      '',
    )}`;
  }
  return `${get(invite, 'user.firstName')} ${get(invite, 'user.lastName')} ${invite.mt4login}`;
};

export const getFollowerAccountName = (invite) => {
  if (invite.followerAccountId) {
    return `${get(invite, 'followerAccountId.accountNickname', '')} ${get(
      invite,
      'followerAccountId.mt4login',
      '',
    )}`;
  }
  return `${get(invite, 'copier.followerAccountId.accountNickname', '')} ${get(
    invite,
    'copier.followerAccountId.mt4login',
    '',
  )}`;
};

export const getInviteProperty = (invite, key) => {
  return get(invite, `copier.${key}`, get(invite, key));
};

export const getEmailNotificationsEnabled = (invite) => invite?.emailNotifications === 'yes';

export const assignTradesUniqueIndex = (trades) => {
  return [...trades].map((trade, index) => ({ ...trade, index }));
};
