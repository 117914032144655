const styles = () => ({
  button: {
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: '900',
    lineHeight: '15px',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    width: 102,
    height: 34,
  },
  container: {
    display: 'flex',
    padding: '24px 24px',
    flexDirection: 'row',
    backgroundColor: '#F4F6F9',
    width: 260,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  loader: {
    alignSelf: 'center',
    width: '191px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
});

export default styles;
