const styles = () => ({
  container: {
    height: '280px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  text: {
    color: '#DADFEC',
    fontSize: '32px',
    fontWeight: 300,
    lineHeight: '24px',
    textAlign: 'center',
    marginTop: '25px',
    height: '24px',
  },
  noDataImage: {
    height: '100px',
    alignSelf: 'center',
  },
  noMatchesImage: {
    height: '148px',
    alignSelf: 'center',
  },
});

export default styles;
