import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import ListSubheader from '@material-ui/core/ListSubheader';
import TPErrorMessage from 'components/TP-UI/TPErrorMessage';
import TPSelectSelectedValue from './components/TPSelectSelectedValue';
import classnames from 'classnames';
import { groupOptionsByKey } from '../helpers/mappers';
import { getOption } from '../helpers/options';
import { menuPropsDefault } from './config';
import { VARIANT } from './constants';

import useStyles from './styles';

export const TPSelect = ({
  variant = 'default',
  name,
  label,
  value = '',
  options = [],
  optionValue = 'value',
  optionLabel = 'label',
  placeholder,
  hint,
  error = null,
  multiple,
  required,
  groupBy,
  grouped,
  disabled,
  clearable,
  fullWidth,
  size = 'medium',
  className,
  renderValue,
  renderOption,
  noOptionsText,
  reservedErrorSpace = true,
  autocomplete,
  autofocus = false,
  hideArrow = false,
  menuProps = {},
  selectDisplayProps = {},
  selectClasses,
  returnsObject = false,
  onChange,
  onBlur,
}) => {
  const classes = useStyles();
  const [currentValue, setCurrentValue] = useState(value);

  const renderValueFunc = useCallback(
    (value) => {
      const props = {
        value,
        multiple,
        renderValue,
        placeholder,
        options,
        optionValue,
        optionLabel,
        grouped,
        clearable,
        disabled,
        onChange,
      };
      return <TPSelectSelectedValue {...props} />;
    },
    [
      multiple,
      renderValue,
      placeholder,
      options,
      optionValue,
      optionLabel,
      grouped,
      clearable,
      disabled,
      onChange,
    ],
  );

  const displayedOptions = useMemo(() => {
    return groupBy ? groupOptionsByKey({ options, key: groupBy }) : options;
  }, [options, groupBy]);

  const displayedMenuProps = useMemo(() => {
    return Object.assign({}, menuPropsDefault, menuProps);
  }, [menuProps]);

  const change = useCallback(
    (newVal) => {
      if (onChange) {
        const selectedOption = getOption(displayedOptions, optionValue, newVal, grouped || groupBy);
        const val = returnsObject ? selectedOption ?? newVal : newVal;
        onChange(val);
      }
    },
    [onChange, returnsObject, displayedOptions, optionValue, grouped, groupBy],
  );

  const handleChange = useCallback(
    (event) => {
      let newVal = event.target.value;
      if (multiple) {
        //user can select empty option or others
        if (currentValue[0] === '' || currentValue[0] === null) {
          newVal = newVal.slice(1);
        } else if (newVal[newVal.length - 1] === '' || newVal[newVal.length - 1] === null) {
          newVal = newVal.slice(-1);
        }
      }
      if (multiple) {
        setCurrentValue(newVal);
      } else {
        change(newVal);
      }
    },
    [change, currentValue, multiple],
  );

  const handleClose = useCallback(() => {
    if (multiple && JSON.stringify(value) !== JSON.stringify(currentValue)) {
      change(currentValue);
    }
  }, [change, multiple, value, currentValue]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const margin = size === 'small' ? 'dense' : 'none';

  const additionalSelectProps = {};

  if (variant !== 'custom') {
    additionalSelectProps.notched = true;
  }

  if (hideArrow) {
    additionalSelectProps.inputProps = { IconComponent: () => null };
  }

  /**
   * Select does not support object as value and changing value from controlled to uncontrolled (e.g. from 'Test' to undefined)
   */
  const selectValue =
    returnsObject && currentValue ? currentValue[optionValue] || '' : currentValue;

  return (
    <FormControl
      variant={VARIANT[variant]}
      required={required}
      disabled={disabled}
      error={!!error}
      fullWidth={fullWidth}
      margin={margin}
      className={className}>
      {label ? (
        <InputLabel id={name + '_label'} shrink={true}>
          {label}
        </InputLabel>
      ) : null}
      <Select
        labelId={name + '_label'}
        id={name}
        value={selectValue}
        onClose={handleClose}
        onChange={handleChange}
        onBlur={onBlur}
        label={label ? label : undefined}
        multiple={multiple}
        displayEmpty={true}
        autoComplete={autocomplete}
        autoFocus={autofocus}
        classes={selectClasses}
        MenuProps={{
          ...displayedMenuProps,
          classes: { paper: classes.menuPaper, ...displayedMenuProps?.classes },
        }}
        SelectDisplayProps={selectDisplayProps}
        {...additionalSelectProps}
        className={classnames({
          [classes.customSelect]: variant === 'custom',
          [classes.hiddenArrow]: hideArrow,
        })}
        renderValue={renderValueFunc}>
        {displayedOptions && !groupBy && !grouped && displayedOptions.length > 0 ? (
          displayedOptions.map((item) => (
            <MenuItem
              value={item[optionValue]}
              key={item[optionValue]}
              disabled={item.disabled}
              component={item.component}
              to={item.to || null}>
              {renderOption ? renderOption(item) : item[optionLabel]}
            </MenuItem>
          ))
        ) : displayedOptions && displayedOptions.length > 0 ? (
          displayedOptions.map((item) => {
            return [
              <ListSubheader key={item.label} className={classes.groupLabel}>
                {item.label}
              </ListSubheader>,
              ...item.options.map((item) => (
                <MenuItem
                  value={item[optionValue]}
                  key={item[optionValue]}
                  disabled={item.disabled}
                  component={item.component}
                  to={item.to || null}>
                  {renderOption ? renderOption(item) : item[optionLabel]}
                </MenuItem>
              )),
            ];
          })
        ) : (
          <MenuItem disabled>{noOptionsText || 'No options'}</MenuItem>
        )}
      </Select>
      {hint && <FormHelperText component="div">{hint}</FormHelperText>}
      <div className={classnames({ [classes.errorContainer]: reservedErrorSpace })}>
        {error && <TPErrorMessage error={error} size="small" />}
      </div>
    </FormControl>
  );
};

TPSelect.muiName = Select.muiName;
TPSelect.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  /**
   * Default object type
   */
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.any,
        value: PropTypes.any,
        disabled: PropTypes.bool,
        component: PropTypes.elementType,
        to: PropTypes.string,
      }),
    ),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.any,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.any,
            value: PropTypes.any,
            disabled: PropTypes.bool,
          }),
        ),
      }),
    ),
  ]),
  /**
   * Key in option object
   */
  optionValue: PropTypes.string,
  /**
   * Key in option object
   */
  optionLabel: PropTypes.string,
  /**
   * Key in option object to group items
   */
  groupBy: PropTypes.string,
  /**
   * Marked that options is already grouped
   */
  grouped: PropTypes.bool,
  noOptionsText: PropTypes.node,
  placeholder: PropTypes.node,
  hint: PropTypes.node,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'small']),
  renderValue: PropTypes.func,
  renderOption: PropTypes.func,
  fullWidth: PropTypes.bool,
  /**
   * Reserved space to display error in 1 line
   */
  reservedErrorSpace: PropTypes.bool,
  autocomplete: PropTypes.string,
  autofocus: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'custom']),
  hideArrow: PropTypes.bool,
  menuProps: PropTypes.shape({
    anchorOrigin: PropTypes.shape({
      vertical: PropTypes.oneOf(['top', 'center', 'bottom', PropTypes.number]),
      horizontal: PropTypes.oneOf(['left', 'center', 'right', PropTypes.number]),
    }),
    transformOrigin: PropTypes.shape({
      vertical: PropTypes.oneOf(['top', 'center', 'bottom', PropTypes.number]),
      horizontal: PropTypes.oneOf(['left', 'center', 'right', PropTypes.number]),
    }),
  }),
  selectDisplayProps: PropTypes.object,
  selectClasses: PropTypes.object,
  /**
   * If set to true, returns selected item (object) in onChange instead of selected value. Works only for single selection.
   */
  returnsObject: PropTypes.bool,
  /**
   * Called when value is changed for single selection, Called when menu is closed for multiple selection
   */
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

const TPReduxSelect = ({ input, meta, ...others }) => {
  const error = meta.submitFailed && meta.error ? meta.error : null;
  const { onChange, onBlur, value } = input;
  const handleBlur = useCallback(() => onBlur(value), [onBlur, value]);
  return <TPSelect {...input} error={error} {...others} onChange={onChange} onBlur={handleBlur} />;
};

export default TPReduxSelect;
