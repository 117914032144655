import OverridesMuiButton from './button';
import OverridesMuiIconButton from './iconButton';

import { createTheme } from '@material-ui/core/styles';
import common from './common';
import palette from './palette';
import { typography, OverridesTypography } from './typography';
import { fontFamily, fontSize, fontWeight, htmlBodyFontSize } from '../font';
import OverridesCssBaseline from './cssBaseline';
import OverridesMuiRadio from './radio';
import OverridesMuiCheckbox from './checkbox';
import OverridesMuiFormLabel from './formLabel';
import OverridesMuiTabs from './tabs';
import OverridesMuiTab from './tab';
import OverridesMuiMenuItem from './menuItem';
import OverridesMuiOutlinedInput from './outlinedInput';
import OverridesMuiSelect from './select';
import OverridesMuiFormHelperText from './formHelperText';
import OverridesMuiListItem from './listItem';
import OverridesMuiMenu from './menu';
import OverridesMuiInputAdornment from './inputAdornment';
import OverridesMuiDivider from './divider';
import OverridesMuiFormControl from './formControl';
import OverridesMuiChip from './chip';
import OverridesMuiPaper from './paper';
import OverridesMuiStepIcon from './stepIcon';
import OverridesMuiStepConnector from './stepConnector';
import OverridesMuiStepper from './stepper';
import OverridesMuiStepLabel from './stepLabel';
import OverridesMuiStep from './step';
import OverridesMuiBottomNavigationAction from './bottomNavigationAction';
import OverridesMuiDrawer from './drawer';
import Banner from './banner';
import OverridesMuiAccordion from './accordion';

import images from './images';

// light or default theme
const theme = {
  themeName: 'light',
  // palette
  palette: {
    ...palette,
  },
  font: {
    htmlBodyFontSize: htmlBodyFontSize,
    fontFamily: fontFamily,
    fontWeight: fontWeight,
    fontSize: fontSize,
  },

  // common styles
  ...common,
  banner: { ...Banner },
  // styles old. do not use it
  input: {
    color: palette.text.primary,
  },
  tip: {
    color: '#767C9E',
    fontSize: fontSize._12,
    display: 'block',
  },
  contentLayout: {
    backgroundColor: '#FEFEFE',
    color: '#7777C1',
    borderColor: '#EFF2F7',
    accentBackgroundColor: '#F4F6F9',
    boxShadow: '0 5px 11px 0 rgba(63,90,151,0.06)',
    greyColor: '#B0B0B0',
  },
  //new styles
  informer: {
    backgroundColor: palette.background.main,
    boxShadow: '0 2px 4px rgba(13,19,80,0.06)',
  },
  performance: {
    bar: {
      barLoss: palette.error.darker,
      barLossBackground: palette.primary.lightest,
      barProfit: palette.primary.light,
      barProfitBackground: palette.primary.lightest,
      font: palette.text.secondary,
    },
    line: {
      background: palette.primary.lightest,
      border: palette.primary.lighter,
      font: palette.text.secondary,
      pointBackground: palette.background.main,
      pointBorder: palette.primary.light,
      pointHoverBackground: palette.primary.light,
      pointHoverBorder: palette.primary.light,
    },
    portfolio: {
      pieChartColors: [
        palette.primary.main,
        palette.success.main,
        palette.error.main,
        palette.success.light,
        palette.primary.light,
        palette.error.light,
        palette.primary.dark,
        palette.success.dark,
        palette.error.dark,
        palette.success.lighter,
        palette.error.lighter,
        palette.primary.lighter,
        palette.primary.lightest,
        palette.error.lightest,
        palette.success.lightest,
      ],
    },
    tradingStatistics: {
      chart1: {
        backgroundColor: palette.chart.chart1,
      },
      chart2: {
        backgroundColor: palette.chart.chart2,
      },
      chart3: {
        backgroundColor: palette.chart.chart3,
      },
    },
  },

  // components old
  radioInput: {
    color: '#36425A',
  },
  imageArray: {
    backgroundColor: '#FAFBFF',
    border: '1px dashed #7777C1',
  },
  picker: {
    backgroundColor: '#FFFFFF',
    hoverBackgroundColor: '#EFF2F7',
  },
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#01082B',
  },
  searchInput: {
    backgroundColor: '#FFFFFF',
  },
  tableAmountField: {
    backgroundColor: '#F8F9FB',
    labelColor: '#74C3C4',
    color: '#36425A',
  },
  tableTextField: {
    labelColor: '#74C3C4',
  },
  tableAmountReuseField: {
    backgroundColor: '#F8F9FB',
    labelColor: '#74C3C4',
    color: '#36425A',
  },
  tableDeleteField: {
    backgroundColor: '#F8F9FB',
  },
  //check on UI
  myPerformance: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #F0F2F6',
  },
  loader: {
    backgroundColor: 'rgb(250, 251, 252, 0.3)',
  },
  alphabeticalPicker: {
    backgroundColor: '#FFFFFF',
    borderWidth: '1px !important',
    borderColor: '#DADFEC !important',
    option: {
      selected: {
        backgroundColor: '#DADFEC !important',
      },
    },
  },
  //views
  promotions: {
    tradingEssentialSubscription: {
      benefit: {
        minHeight: '65px',
        borderRadius: '10px',
        backgroundColor: palette.primary.lightest,
      },
      badge: {
        border: `1px solid ${palette.primary.lightest}`,
        backgroundColor: palette.background.main,
      },
    },
    howItWorks: {
      'howItWorksItem::after': {
        content: `url("${images.tradingView.tradingViewArrowImg}")`,
      },
      blockImage: {
        borderRadius: '10px',
        backgroundColor: palette.primary.lightest,
      },
    },
  },
  similarUsers: {
    icon: {
      color: palette.warning.main,
    },
  },
  quiz: {
    quizModal: {
      avatar: {
        height: 160,
        src: images.common.leaderboardImg,
        width: 180,
        maxHeight: 160,
        maxWidth: 180,
        objectPosition: `${-8}px ${34}px`,
      },
    },
    proQuizBlockedModal: {
      avatar: {
        height: 145,
        src: images.common.rejectedImg,
        width: 145,
        maxHeight: 145,
        maxWidth: 145,
        objectPosition: `${30}px ${40}px`,
      },
    },
    quizBlockedModal: {
      avatar: {
        height: 145,
        src: images.common.rejectedImg,
        width: 145,
        maxHeight: 145,
        maxWidth: 145,
        objectPosition: `${30}px ${40}px`,
      },
    },
    secondFailedModal: {
      avatar: {
        height: 145,
        src: images.common.waitingImg,
        width: 145,
        maxHeight: 145,
        maxWidth: 145,
        objectPosition: `${30}px ${40}px`,
      },
    },
    successQuizModal: {
      avatar: {
        height: 190,
        src: images.common.successImg,
        width: 250,
        maxHeight: 190,
        maxWidth: 250,
        objectPosition: `${20}px ${65}px`,
      },
    },
    tryAgainModal: {
      avatar: {
        height: 215,
        src: images.common.waitingImg,
        width: 215,
        maxHeight: 215,
        maxWidth: 215,
        objectPosition: `${11}px ${78}px`,
      },
    },
    upgradeBlockedModal: {
      avatar: {
        height: 145,
        src: images.common.rejectedImg,
        width: 145,
        maxHeight: 145,
        maxWidth: 145,
        objectPosition: `${30}px ${40}px`,
      },
    },
    continueAsRetailsModal: {
      avatar: {
        height: 215,
        src: images.common.waitingImg,
        width: 215,
        maxHeight: 215,
        maxWidth: 215,
        objectPosition: `${11}px ${78}px`,
      },
    },
    preQuizModal: {
      avatar: {
        height: 215,
        src: images.common.waitingImg,
        width: 215,
        maxHeight: 215,
        maxWidth: 215,
        objectPosition: `${11}px ${78}px`,
      },
    },
  },
  tmd: {
    checkModal: {
      avatar: {
        height: 200,
        src: images.common.waitingImg,
        width: 180,
        maxHeight: 200,
        maxWidth: 180,
        objectPosition: `${10}px ${75}px`,
      },
    },
    failModal: {
      avatar: {
        height: 130,
        src: images.common.rejectedImg,
        width: 130,
        maxHeight: 130,
        maxWidth: 130,
        objectPosition: `${25}px ${40}px`,
      },
    },
    successModal: {
      avatar: {
        height: 180,
        src: images.common.successImg,
        width: 180,
        maxHeight: 130,
        maxWidth: 130,
        objectPosition: `${20}px ${65}px`,
      },
    },
  },
  aml: {
    success: {
      avatar: {
        height: 180,
        src: images.common.successImg,
        width: 180,
        maxHeight: 180,
        maxWidth: 180,
        objectPosition: `${20}px ${65}px`,
      },
    },
  },
  withdraw: {
    failed: {
      avatar: {
        height: 300,
        left: 40,
        src: images.common.rejectedImg,
        top: 60,
        width: 180,
        maxHeight: 300,
        maxWidth: 180,
        objectPosition: `${40}px ${60}px`,
      },
    },
    success: {
      avatar: {
        height: 300,
        left: 28,
        src: images.common.successImg,
        top: 105,
        width: 199,
        maxHeight: 300,
        maxWidth: 199,
        objectPosition: `${28}px ${105}px`,
      },
    },
  },

  // overrides
  overrides: {
    MuiCssBaseline: { ...OverridesCssBaseline },
    MuiTabs: { ...OverridesMuiTabs },
    MuiTab: { ...OverridesMuiTab },
    MuiButton: { ...OverridesMuiButton },
    MuiIconButton: { ...OverridesMuiIconButton },
    MuiSelect: { ...OverridesMuiSelect },
    MuiOutlinedInput: { ...OverridesMuiOutlinedInput },
    MuiListItem: { ...OverridesMuiListItem },
    MuiMenu: { ...OverridesMuiMenu },
    MuiMenuItem: { ...OverridesMuiMenuItem },
    MuiDialog: {
      paper: {
        '@media (max-width: 600px)': {
          margin: '5px',
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0px 24px 24px',
      },
    },
    MuiCheckbox: { ...OverridesMuiCheckbox },
    MuiRadio: { ...OverridesMuiRadio },
    MuiTablePagination: {
      toolbar: {
        '@media (max-width: 480px)': {
          padding: 0,
        },
      },
      selectRoot: {
        '@media (max-width: 480px)': {
          marginRight: '16px',
        },
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: fontSize._24,
      },

      fontSizeSmall: {
        fontSize: fontSize._16,
      },

      fontSizeLarge: {
        fontSize: fontSize._32,
      },
    },
    MuiFormLabel: { ...OverridesMuiFormLabel },
    MuiFormHelperText: { ...OverridesMuiFormHelperText },
    MuiInputAdornment: { ...OverridesMuiInputAdornment },
    MuiDivider: { ...OverridesMuiDivider },
    MuiFormControl: { ...OverridesMuiFormControl },
    MuiChip: { ...OverridesMuiChip },
    MuiPaper: { ...OverridesMuiPaper },
    MuiStepIcon: { ...OverridesMuiStepIcon },
    MuiStepConnector: { ...OverridesMuiStepConnector },
    MuiStepper: { ...OverridesMuiStepper },
    MuiStepLabel: { ...OverridesMuiStepLabel },
    MuiStep: { ...OverridesMuiStep },
    MuiBottomNavigationAction: { ...OverridesMuiBottomNavigationAction },
    MuiDrawer: { ...OverridesMuiDrawer },
    MuiTypography: { ...OverridesTypography },
    MuiAccordion: { ...OverridesMuiAccordion },
  },
  typography: { ...typography },
  //defined theme images
  images: {
    ...images,
  },
};

export default createTheme(theme);
