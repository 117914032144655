import { handleActions } from 'redux-actions';
import { getMfaQrCodeSuccess, getMfaQrCodeFail, setPanelExpanded } from '../actions';
import { logoutSuccess } from '../../../actions';

const defaultState = {
  qrCodeImage: null,
  secret: null,
  expanded: false,
};

export default handleActions(
  {
    [getMfaQrCodeSuccess]: (state, action) => {
      return {
        ...state,
        qrCodeImage: action.response.data.dataUrl,
        secret: action.response.data.secret,
      };
    },
    [getMfaQrCodeFail]: (state) => {
      return {
        ...state,
        qrCodeImage: null,
        secret: null,
      };
    },
    [setPanelExpanded]: (state, action) => {
      return {
        ...state,
        expanded: action.payload,
      };
    },
    [logoutSuccess]: () => {
      return defaultState;
    },
  },
  defaultState,
);
