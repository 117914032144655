import { CURRENCY } from 'constants/index';
import i18n from 'i18n';
import { PSP_PERMISSION_KEY } from '../../../constants';

export const WITHDRAW_TRANSFER_FORM = 'WITHDRAW_TRANSFER_FORM';
export const WITHDRAW_UNIONPAY_NAMES = 'WITHDRAW_UNIONPAY_NAMES';
export const WITHDRAWAL_METHOD_MODAL = 'WITHDRAWAL_METHOD_MODAL';
export const WITHDRAWAL_METHOD_FORM = 'WITHDRAWAL_METHOD_FORM';
export const WITHDRAWAL_FORM = 'WITHDRAWAL_FORM';

export const UNABLE_REFUND_MODAL = 'UNABLE_REFUND_MODAL';
export const UNABLE_REFUND_FORM = 'UNABLE_REFUND_FORM';
export const UNABLE_TO_REFUND_MODAL_SUCCESS = 'UNABLE_TO_REFUND_MODAL_SUCCESS';

export const MIFINITY_CURRENCIES = [
  CURRENCY.AUD,
  CURRENCY.CAD,
  CURRENCY.EUR,
  CURRENCY.GBP,
  CURRENCY.USD,
];

export const MIFINITY_EU_CURRENCIES = [
  ...MIFINITY_CURRENCIES,
  CURRENCY.CZK,
  CURRENCY.DKK,
  CURRENCY.PLN,
  CURRENCY.NOK,
  CURRENCY.SEK,
  CURRENCY.CHF,
];

export const JETON_TARGET_CURRENCIES = [
  CURRENCY.AUD,
  CURRENCY.CAD,
  CURRENCY.EUR,
  CURRENCY.GBP,
  CURRENCY.USD,
  CURRENCY.JPY,
];

// this should match server side WITHDRAWAL_METHODS record
export const WITHDRAW_METHOD = {
  currencyCloudLocal: 'Currency Cloud Local',
  bankwire: 'Bankwire',
  hyperwallet: 'Bank Transfer',
  currencyCloud: 'Currency Cloud',
  localBank: 'Local Bank',
  payid: 'PayID',
  unionpay: 'Unionpay/Local Bank',
  skrill: 'Skrill',
  neteller: 'Neteller',
  paypal: 'Paypal',
  perfectMoney: 'Perfect Money',
  mifinity: 'Mifinity',
  jeton: 'Jeton',
  interacETransferOutbound: 'Interac e-Transfer Outbound',
  crypto: 'Crypto',
  cryptoDay1: 'Crypto Day1',
  astropay: 'Astropay',
  dragonpay: 'Dragonpay',
  paypalRefund: 'PayPal Refund',
};

export const WITHDRAW_METHOD_LABEL = {
  [WITHDRAW_METHOD.unionpay]: 'UnionPay/Local Bank Transfer',
  [WITHDRAW_METHOD.bankwire]: 'Bank Wire/Transfer',
  [WITHDRAW_METHOD.hyperwallet]: 'Bank Transfer',
  [WITHDRAW_METHOD.localBank]: 'Local Bank Transfer',
  [WITHDRAW_METHOD.skrill]: 'Skrill',
  [WITHDRAW_METHOD.neteller]: 'Neteller',
  [WITHDRAW_METHOD.paypal]: 'PayPal',
  [WITHDRAW_METHOD.perfectMoney]: 'Perfect Money',
  [WITHDRAW_METHOD.mifinity]: 'MiFinity',
  [WITHDRAW_METHOD.interacETransferOutbound]: 'Interac\u00AEe-Transfer',
  [WITHDRAW_METHOD.crypto]: 'Crypto',
  [WITHDRAW_METHOD.cryptoDay1]: 'Crypto',
  [WITHDRAW_METHOD.astropay]: 'Astropay',
  [WITHDRAW_METHOD.dragonpay]: 'Dragonpay',
  [WITHDRAW_METHOD.jeton]: 'Jeton',
  [WITHDRAW_METHOD.payid]: 'PayID',
  [WITHDRAW_METHOD.currencyCloud]: 'Bank Transfer',
  [WITHDRAW_METHOD.currencyCloudLocal]: 'Local Bank Transfer',
};

export const WITHDRAW_METHOD_PERMISSION_KEY = {
  [WITHDRAW_METHOD.unionpay]: PSP_PERMISSION_KEY.UNIONPAY,
  [WITHDRAW_METHOD.bankwire]: PSP_PERMISSION_KEY.BANKWIRE,
  [WITHDRAW_METHOD.hyperwallet]: PSP_PERMISSION_KEY.HYPERWALLET,
  [WITHDRAW_METHOD.localBank]: PSP_PERMISSION_KEY.LOCAL_BANK,
  [WITHDRAW_METHOD.skrill]: PSP_PERMISSION_KEY.SKRILL,
  [WITHDRAW_METHOD.neteller]: PSP_PERMISSION_KEY.NETELLER,
  [WITHDRAW_METHOD.paypal]: PSP_PERMISSION_KEY.PAYPAL,
  [WITHDRAW_METHOD.paypalRefund]: PSP_PERMISSION_KEY.PAYPAL_REFUND,
  [WITHDRAW_METHOD.perfectMoney]: PSP_PERMISSION_KEY.PERFECT_MONEY,
  [WITHDRAW_METHOD.mifinity]: PSP_PERMISSION_KEY.MIFINITY,
  [WITHDRAW_METHOD.interacETransferOutbound]: PSP_PERMISSION_KEY.INTERAC_E_TRANSFER_OUTBOUND,
  [WITHDRAW_METHOD.crypto]: PSP_PERMISSION_KEY.CRYPTO_FINRAX,
  [WITHDRAW_METHOD.cryptoDay1]: PSP_PERMISSION_KEY.CRYPTO_DAY1,
  [WITHDRAW_METHOD.astropay]: PSP_PERMISSION_KEY.ASTROPAY,
  [WITHDRAW_METHOD.dragonpay]: PSP_PERMISSION_KEY.DRAGONPAY,
  [WITHDRAW_METHOD.jeton]: PSP_PERMISSION_KEY.JETON,
  [WITHDRAW_METHOD.payid]: PSP_PERMISSION_KEY.PAYID,
  [WITHDRAW_METHOD.currencyCloud]: PSP_PERMISSION_KEY.CURRENCY_CLOUD,
  [WITHDRAW_METHOD.currencyCloudLocal]: PSP_PERMISSION_KEY.CURRENCY_CLOUD_LOCAL,
};

export const MIN_WITHDRAWAL_COMMON = 0.01;
export const MIN_WITHDRAWAL_PAY_ID = 1;

export const MIN_WITHDRAWAlS = {
  usd: 10,
  gbp: 10,
  eur: 10,
  aud: 10,
  cad: 15,
  hkd: 100,
  jpy: 1120,
  nzd: 15,
  sgd: 15,
  thb: 355,
  huf: 500,
  czk: 10,
  dkk: 10,
  pln: 10,
  nok: 10,
  sek: 10,
  chf: 10,
};

export const MIN_MIFINITY_WITHDRAWALS = {
  ...MIN_WITHDRAWAlS,
  aud: 15,
  jpy: 1500,
  nzd: 20,
  czk: 250,
  dkk: 75,
  pln: 50,
  nok: 100,
  sek: 100,
  thb: 300,
};

export const MAX_WITHDRAWAlS = {
  usd: 9999,
  gbp: 9999,
  eur: 9999,
  aud: 9999,
  cad: 9999,
  hkd: 9999,
  jpy: 9999,
  nzd: 9999,
  sgd: 9999,
  huf: 9999,
  czk: 9999,
  dkk: 9999,
  pln: 9999,
  nok: 9999,
  sek: 9999,
  chf: 9999,
};

export const MAX_MIFINITY_WITHDRAWALS = { ...MAX_WITHDRAWAlS, gbp: 8500, chf: 9500, thb: 500000 };

export const MIN_INTERNATIONAL_WITHDRAWAlS = {
  usd: 35,
  gbp: 30,
  eur: 35,
  aud: 50,
  cad: 50,
  hkd: 280,
  jpy: 4000,
  nzd: 55,
  sgd: 50,
  thb: 355,
  huf: 500,
  czk: 35,
  dkk: 35,
  pln: 35,
  nok: 35,
  sek: 35,
  chf: 35,
};

export const WITHDRAW_TRANSFER_MODAL = 'WITHDRAW_TRANSFER_MODAL';

export const WITHDRAW_RESULT_MODAL_SUCCESS = 'WITHDRAW_RESULT_MODAL_SUCCESS';
export const WITHDRAW_RESULT_MODAL_ERROR = 'WITHDRAW_RESULT_MODAL_ERROR';
export const WITHDRAWAL_REQUESTED_MODAL_SUCCESS = 'WITHDRAWAL_REQUESTED_MODAL_SUCCESS';
export const WITHDRAWAL_REQUESTED_MODAL_ERROR = 'WITHDRAWAL_REQUESTED_MODAL_ERROR';

export const CLIENT_CONFIRM_WITHDRAW_MODAL = 'CLIENT_CONFIRM_WITHDRAW_MODAL';

// Need to sync that constant with list of allowed countries for hyperwallet on back-end side,
// since this is restriction.
// So if country is not defined in that list or list of supported currencies for allowed types are empty,
// the country will be removed from foreign countries list as the result
// and if it's the user country, the list of currencies will be empty
export const HYPERWALLET_ALLOWED_ACCOUNT_TYPES_BY_COUNTRY = {
  Iceland: {
    WIRE_ACCOUNT: true,
  },
  Liechtenstein: {
    WIRE_ACCOUNT: true,
  },
  Norway: {
    WIRE_ACCOUNT: true,
  },
  Singapore: {
    BANK_ACCOUNT: true,
    WIRE_ACCOUNT: true,
  },
  'United Arab Emirates': {
    BANK_ACCOUNT: true,
    WIRE_ACCOUNT: true,
  },
  'United Kingdom of Great Britain and Northern Ireland': {
    BANK_ACCOUNT: true,
    WIRE_ACCOUNT: true,
  },
};

export const HYPERWALLET_ALLOWED_IBAN_COUNTRIES = [
  { value: 'AT', label: 'Austria' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czech Republic' },
  { value: 'HR', label: 'Croatia' },
  { value: 'DK', label: 'Denmark' },
  { value: 'EE', label: 'Estonia' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'DE', label: 'Germany' },
  { value: 'GR', label: 'Greece' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IT', label: 'Italy' },
  { value: 'LV', label: 'Latvia' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'MT', label: 'Malta' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'NO', label: 'Norway' },
  { value: 'PL', label: 'Poland' },
  { value: 'PT', label: 'Portugal' },
  { value: 'RO', label: 'Romania' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SK', label: 'Slovakia' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'ES', label: 'Spain' },
  { value: 'SE', label: 'Sweden' },
  { value: 'TH', label: 'Thailand' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'GB', label: 'United Kingdom of Great Britain and Northern Ireland' },
];

export const PAYID_TYPES = {
  EMAIL: 'Email',
  PHONE_NUMBER: 'PhoneNumber',
};

export const PAYID_WITHDRAWAL_TYPES_VALUES = [
  { value: PAYID_TYPES.EMAIL, label: 'Email' },
  { value: PAYID_TYPES.PHONE_NUMBER, label: 'Phone Number' },
];

export const FORBIDDEN_CURRENCY_CLOUD_WITHDRAWAL_FOREIGN_COUNTRIES = [
  // Need to provide "purpose_code" to make a payment. See https://support.currencycloud.com/hc/en-gb/articles/360017430000-Payment-Purpose-Codes
  'Bahrain',
  'Belarus',
  'Cuba',
  'Kazakhstan',
  'Libya',
  'Myanmar',
  'Sudan',
  'Syrian Arab Republic',
  // Need to provide "purpose_code" to make a payment. See https://support.currencycloud.com/hc/en-gb/articles/360017430000-Payment-Purpose-Codes
  'United Arab Emirates',
  // Need to implement:
  // 1. "Verify Beneficiary" functionality to make payment (See https://developer.currencycloud.com/guides/integration-guides/verifying-beneficiary-account/)
  // 2. There are 2 different options for "Regular" UK payment in GBP: we need to select only one (This API: https://developer.currencycloud.com/api-reference/#get-beneficiary-requirements)
  'United Kingdom of Great Britain and Northern Ireland',
  'Venezuela (Bolivarian Republic of)',
];

// We don't allow any payments to/from blocked countries but this is exceptions list for Currency Cloud withdrawals
export const BLOCKED_COUNTRIES_ALLOWED_CURRENCY_CLOUD = ['United States of America'];

export const UNABLE_REFUND_REASON = {
  NO_VALID: 'noValid',
  NOT_OWNER: 'notOwner',
  CANCELED: 'cancelled',
  OTHER: 'other',
};

export const UNABLE_REFUND_REASON_LABEL = {
  [UNABLE_REFUND_REASON.NO_VALID]: i18n.t(
    'payments:withdraw2.unableToRefund.messages.noValidAccount',
  ),
  [UNABLE_REFUND_REASON.NOT_OWNER]: i18n.t('payments:withdraw2.unableToRefund.messages.notOwner'),
  [UNABLE_REFUND_REASON.CANCELED]: i18n.t(
    'payments:withdraw2.unableToRefund.messages.canceledAccount',
  ),
  [UNABLE_REFUND_REASON.OTHER]: i18n.t('payments:withdraw2.unableToRefund.messages.other'),
};

export const UNABLE_REFUND_REASONS_OPTIONS = Object.entries(UNABLE_REFUND_REASON_LABEL).map(
  ([reason, label]) => ({
    value: reason,
    label: label,
  }),
);

export const DRAGONPAY_AMOUNT_MARK_UP = 0.02;

export const DEFAULT_HISTORY_LIMIT = 5;

export const FINISH_WITHDRAWAL_REASON = {
  CANCELLED: 'CANCELLED',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

export const PAYPAL_CURRENCY_DOES_NOT_SUPPORT_DECIMALS = [
  CURRENCY.HUF.value,
  CURRENCY.JPY.value,
  CURRENCY.TWD.value,
];
