import React, { useState, useMemo } from 'react';
import TPGrid from 'components/TP-UI/TPGrid';
import NotificationsNone from '@material-ui/icons/NotificationsNone';
import TPTypography from 'components/TP-UI/TPTypography';
import TPIconButton from 'components/TP-UI/TPIconButton';
import TPMenu from 'components/TP-UI/TPMenu';
import TPButton from 'components/TP-UI/TPButton';
import useStyles from './styles';
import TPLanguageSelect from 'components/TP-UI/TPLanguageSelect';
import TPBadge from 'components/TP-UI/TPBadge';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { Field, reduxForm } from 'redux-form';

const Menus = () => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState('');

  const simpleMenuItems = useMemo(
    () => [
      {
        id: 'reset',
        label: 'Reset',
        onClick: () => setSelectedValue(''),
      },
      {
        id: '1',
        label: 'Profile link',
        path: '/auth/sign-in',
      },
      {
        id: '2',
        renderItem: () => 'Sort by Account Status',
        onClick: (id) => setSelectedValue(id),
      },
      {
        id: '3',
        renderItem: () => 'Sort by Country',
        onClick: (id) => setSelectedValue(id),
        disabled: true,
      },
    ],
    [],
  );

  const menuItemsWithSelect = useMemo(
    () => [
      ...simpleMenuItems,
      {
        id: '222',
        noGutters: true,
        renderItem: () => (
          <Field
            component={TPLanguageSelect}
            id="language"
            name="language"
            hideArrow={false}
            fullWidth
            selectDisplayProps={{
              className: classes.select,
            }}
            selectClasses={{
              icon: classes.selectIcon,
            }}
            options={[
              {
                label: 'English',
                language: 'en',
                icon: 'http://localhost:3000/static/media/en.2e9c71c7.svg',
              },
              {
                label: 'Thai',
                language: 'th',
                icon: 'http://localhost:3000/static/media/th.9667e838.svg',
              },
            ]}
          />
        ),
      },
    ],
    [simpleMenuItems, classes.select, classes.selectIcon],
  );

  const menuItemsWithIcon = useMemo(
    () => [
      ...simpleMenuItems,
      {
        id: '45',
        label: 'Sort by Date',
        iconComponent: ArrowDownwardIcon,
        onClick: (id) => setSelectedValue(id),
      },
    ],
    [simpleMenuItems],
  );

  const menuItemsWithCustomItem = useMemo(
    () => [
      ...simpleMenuItems,
      {
        id: '1223',
        renderItem: () => <div className={classes.lastMenuItem}>Div</div>,
        onClick: (id) => setSelectedValue(id),
        noGutters: true,
      },
    ],
    [simpleMenuItems, classes.lastMenuItem],
  );

  return (
    <TPGrid container direction="column" spacing={1}>
      <TPGrid item>
        <TPGrid container spacing={2}>
          <TPGrid item className={classes.spacing}>
            <TPTypography variant="subtitle1" className={classes.spacing}>
              Default
            </TPTypography>
            <TPMenu value={selectedValue} menuItems={simpleMenuItems}>
              <TPIconButton color="primary" size="large">
                <NotificationsNone />
              </TPIconButton>
            </TPMenu>
          </TPGrid>
          <TPGrid item className={classes.spacing}>
            <TPTypography variant="subtitle1" className={classes.spacing}>
              With Country Select
            </TPTypography>
            <TPMenu value={selectedValue} menuItems={menuItemsWithSelect}>
              <TPIconButton color="primary" size="large">
                {selectedValue ? (
                  <TPBadge variant="dot" color="error">
                    <NotificationsNone />
                  </TPBadge>
                ) : (
                  <NotificationsNone />
                )}
              </TPIconButton>
            </TPMenu>
          </TPGrid>
          <TPGrid item className={classes.spacing}>
            <TPTypography variant="subtitle1" className={classes.spacing}>
              With Custom Header
            </TPTypography>
            <TPMenu
              header={
                <div className={classes.headerWithIcon}>
                  <NotificationsNone />
                  <TPTypography variant="subtitle1" className={classes.spacing}>
                    Header
                  </TPTypography>
                </div>
              }
              value={selectedValue}
              menuItems={menuItemsWithCustomItem}>
              <TPIconButton color="primary" size="large">
                B
              </TPIconButton>
            </TPMenu>
          </TPGrid>
          <TPGrid item className={classes.spacing}>
            <TPTypography variant="subtitle1" className={classes.spacing}>
              With Icon
            </TPTypography>
            <TPMenu value={selectedValue} menuItems={menuItemsWithIcon}>
              <TPIconButton color="primary" size="large">
                {selectedValue ? (
                  <TPBadge variant="dot" color="error">
                    <NotificationsNone />
                  </TPBadge>
                ) : (
                  <NotificationsNone />
                )}
              </TPIconButton>
            </TPMenu>
          </TPGrid>
          <TPGrid item className={classes.spacing}>
            <TPTypography variant="subtitle1" className={classes.spacing}>
              Disabled
            </TPTypography>
            <TPMenu value={selectedValue} menuItems={simpleMenuItems}>
              <TPIconButton color="primary" size="large" disabled>
                B
              </TPIconButton>
            </TPMenu>
          </TPGrid>
        </TPGrid>
      </TPGrid>
      <TPGrid container item>
        <TPGrid container item>
          <TPMenu placement="bottom" value={selectedValue} menuItems={simpleMenuItems}>
            <TPButton fullWidth={true} primary={true} color="primary" size="medium">
              Full Width
            </TPButton>
          </TPMenu>
        </TPGrid>
      </TPGrid>
    </TPGrid>
  );
};

export default reduxForm({
  form: 'TP-MENU',
  initialValues: {
    country: 1,
    language: 'en',
  },
})(Menus);
