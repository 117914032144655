import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';
import { reduxForm } from 'redux-form';

import TPCardAvatar from 'components/TP-UI/TPCard/components/TPCardAvatar';
import TPGrid from 'components/TP-UI/TPGrid';
import TPImage from 'components/TP-UI/TPImage';
import TPModal from 'components/TP-UI/TPModal';
import TPProgressBar from 'components/TP-UI/TPProgressBar';
import { REDUX_FORM_DEFAULT_CONFIG } from 'constants/form';
import QuizForm from '../QuizForm';

import useStyles from './styles';

const QuizModal = (props) => {
  const {
    answers,
    currentQuestionIndex = 0,
    form,
    handleSubmit,
    loading,
    modalName,
    onCancel,
    onPrevClick,
    progress,
    questionId,
    questionLabel,
    title,
    titleEndAdornment,
    totalQuestionCount = 0,
    error,
    submitFailed,
    questionComponent: QuestionComponent,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation('common');
  const { quiz } = useTheme();
  const { src, ...imageStyles } = quiz.quizModal.avatar;

  const buttons = useMemo(() => {
    return [
      {
        label:
          currentQuestionIndex === totalQuestionCount - 1 ? t('buttons.finish') : t('buttons.next'),
        onClick: handleSubmit,
        primary: true,
        type: 'submit',
      },
      ...(currentQuestionIndex > 0
        ? [
            {
              label: t('buttons.previous'),
              onClick: onPrevClick,
              secondary: true,
            },
          ]
        : []),
    ];
  }, [currentQuestionIndex, handleSubmit, onPrevClick, t, totalQuestionCount]);

  return (
    <TPModal
      buttons={buttons}
      form={form}
      loading={loading}
      modalName={modalName}
      onClose={onCancel}
      onSubmit={handleSubmit}
      title={title}
      titleEndAdornment={titleEndAdornment}>
      <TPGrid container spacing={3}>
        <TPGrid item xs={12}>
          <TPProgressBar primary thickness={8} progress={progress} />
        </TPGrid>
        <TPGrid container item justifyContent="center" xs={12}>
          <TPGrid item>
            <TPCardAvatar className={classes.imageContainer} primary>
              <TPImage src={src} style={imageStyles} />
            </TPCardAvatar>
          </TPGrid>
        </TPGrid>
        <TPGrid item>
          {QuestionComponent ? (
            <QuestionComponent
              answers={answers}
              questionId={questionId}
              questionLabel={questionLabel}
              form={form}
              error={error}
              submitFailed={submitFailed}
            />
          ) : (
            <QuizForm
              answers={answers}
              questionId={questionId}
              questionLabel={questionLabel}
              error={error}
              submitFailed={submitFailed}
            />
          )}
        </TPGrid>
      </TPGrid>
    </TPModal>
  );
};

QuizModal.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  currentQuestionIndex: PropTypes.number,
  form: PropTypes.string,
  loading: PropTypes.bool,
  modalName: PropTypes.string,
  onPrevClick: PropTypes.func,
  onSubmit: PropTypes.func,
  progress: PropTypes.number,
  questionId: PropTypes.string,
  questionLabel: PropTypes.string,
  title: PropTypes.string,
  titleEndAdornment: PropTypes.node,
  totalQuestionCount: PropTypes.number,
  questionComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
};

export default reduxForm({
  ...REDUX_FORM_DEFAULT_CONFIG,
})(QuizModal);
