import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@material-ui/core';

const TPPopoverBase = ({ children, className, ...props }) => {
  return (
    <Popover className={className} {...props}>
      {children}
    </Popover>
  );
};

TPPopoverBase.muiName = Popover.muiName;

TPPopoverBase.propTypes = {
  /**
   * A ref for imperative actions. It currently only supports updatePosition() action.
   */
  action: PropTypes.func,

  /**
   * A HTML element, or a function that returns it. It's used to set the position of the popover.
   */
  anchorEl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]) }),
  ]),

  /**
   * This is the point on the anchor where the popover's anchorEl will attach to.
   * This is not used when the anchorReference is 'anchorPosition'.
   */
  anchorOrigin: PropTypes.shape({
    horizontal: PropTypes.oneOfType([
      PropTypes.oneOf(['left', 'center', 'right']),
      PropTypes.number,
    ]),
    vertical: PropTypes.oneOfType([PropTypes.oneOf(['top', 'center', 'bottom']), PropTypes.number]),
  }),

  /**
   * The position that may be used to set the position of the popover.
   * The coordinates are relative to the application's client area.
   */
  anchorPosition: PropTypes.shape({
    left: PropTypes.number,
    top: PropTypes.number,
  }),

  /**
   * This determines which anchor prop to refer to to set the position of the popover.
   */
  anchorReference: PropTypes.oneOf(['anchorEl', 'anchorPosition', 'none']),

  /**
   * The content of the component.
   */
  children: PropTypes.node,

  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object,

  /**
   * A HTML element, component instance, or function that returns either.
   * The container will passed to the Modal component.
   * By default, it uses the body of the anchorEl's top-level document object.
   */
  container: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.instanceOf(React.Component),
    PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  ]),

  /**
   * The elevation of the popover.
   */
  elevation: PropTypes.number,

  /**
   * This function retrieves the content anchor element.
   * The content anchor element should be an element inside the popover.
   */
  getContentAnchorEl: PropTypes.func,

  /**
   * Specifies how close to the edge of the window the popover can appear.
   */
  marginThreshold: PropTypes.number,

  /**
   * Callback fired when the component requests to be closed.
   */
  onClose: PropTypes.func,

  /**
   * If true, the popover is visible.
   */
  open: PropTypes.bool.isRequired,

  /**
   * Props applied to the Paper element.
   */
  PaperProps: PropTypes.object,

  /**
   * This is the point on the popover which will attach to the anchor's origin.
   */
  transformOrigin: PropTypes.shape({
    horizontal: PropTypes.oneOfType([
      PropTypes.oneOf(['left', 'center', 'right']),
      PropTypes.number,
    ]),
    vertical: PropTypes.oneOfType([PropTypes.oneOf(['top', 'center', 'bottom']), PropTypes.number]),
  }),

  /**
   * The component used for the transition.
   */
  TransitionComponent: PropTypes.elementType,

  /**
   * Set to 'auto' to automatically calculate transition time based on height.
   */
  transitionDuration: PropTypes.oneOfType([
    PropTypes.oneOf(['auto']),
    PropTypes.number,
    PropTypes.shape({
      appear: PropTypes.number,
      enter: PropTypes.number,
      exit: PropTypes.number,
    }),
  ]),

  /**
   * Props applied to the Transition element.
   */
  TransitionProps: PropTypes.object,
};

export default TPPopoverBase;
