import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { checkNonLatin, required } from 'utils/validation/fieldValidationRules';
import TPModal from 'components/TP-UI/TPModal';
import TPAlert from 'components/TP-UI/TPAlert';
import TPGrid from 'components/TP-UI/TPGrid';
import TPErrorMessage from 'components/TP-UI/TPErrorMessage';
import TPTypography from 'components/TP-UI/TPTypography';
import TPDocument from 'components/TP-UI/TPDocument';
import TPTextField from 'components/TP-UI/TPTextField';
import TPSelect from 'components/TP-UI/TPSelect';
import LpoaTerms from 'modules/lpoa/components/LpoaTerms';
import PropTypes from 'prop-types';

const LpoaModal = ({
  modalName,
  fullName,
  lpoaInfo,
  accounts,
  loading,
  form,
  error,
  handleSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();

  const buttons = useMemo(
    () => [
      {
        label: 'Sign',
        primary: true,
        onClick: handleSubmit,
      },
      {
        label: t('common:buttons.cancel'),
        secondary: true,
        onClick: onCancel,
      },
    ],
    [handleSubmit, onCancel, t],
  );

  return (
    <TPModal
      form={form}
      modalName={modalName}
      title={t('profile:limitedPower.title')}
      buttons={buttons}
      loading={loading}
      onClose={onCancel}>
      <TPGrid container direction="column" spacing={3}>
        {error && (
          <TPGrid item xs={12}>
            <TPErrorMessage error={error} size="medium" />
          </TPGrid>
        )}
        <TPGrid item container spacing={2} direction="column">
          <TPGrid item>
            <TPAlert variant="info">{t('profile:limitedPower.modal.warning')}</TPAlert>
          </TPGrid>

          <TPGrid item>
            <TPTypography variant="h6">{t('profile:limitedPower.modal.granted')}</TPTypography>
          </TPGrid>
          <TPGrid item>
            <TPTypography variant="body1">
              <Trans
                i18nKey="limitedPower.modal.appoint"
                ns="profile"
                values={{ fullName, attorney: lpoaInfo.attorney }}
              />
            </TPTypography>
            <TPTypography variant="body1">
              {t('profile:limitedPower.modal.purposesTitle')}
            </TPTypography>
          </TPGrid>
          <TPGrid item>
            <TPDocument fullWidth rows={20}>
              <LpoaTerms fee={lpoaInfo.fee} />
            </TPDocument>
          </TPGrid>
          <TPGrid item>
            <TPTypography variant="h6">THE CUSTOMER</TPTypography>
          </TPGrid>
          <TPGrid item>
            <TPTypography variant="body1">{fullName}</TPTypography>
          </TPGrid>
          <TPGrid item>
            <Field
              fullWidth
              name="sign"
              label={t('upgradeToPro:sign.label')}
              placeholder={t('upgradeToPro:sign.placeholder')}
              component={TPTextField}
              validate={[checkNonLatin, required]}
              required
            />
          </TPGrid>
          <TPGrid item>
            <Field
              name="userAccount"
              label="My Trading Account Number:"
              component={TPSelect}
              fullWidth
              options={accounts}
              placeholder="Account Number"
              validate={required}
              required
            />
          </TPGrid>

          <TPGrid item>
            <TPTypography variant="body1">
              {t('profile:')}
              <Trans
                i18nKey="limitedPower.modal.accept"
                ns="profile"
                values={{ attorney: lpoaInfo.attorney }}
              />
            </TPTypography>
          </TPGrid>
        </TPGrid>
      </TPGrid>
    </TPModal>
  );
};

LpoaModal.propTypes = {
  modalName: PropTypes.string,
  fullName: PropTypes.string,
  lpoaInfo: PropTypes.shape({
    attorney: PropTypes.string,
    fee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  accounts: PropTypes.array,
  loading: PropTypes.bool,
  form: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default reduxForm()(LpoaModal);
