import React from 'react';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { showNotificationError } from 'modules/notifications/actions';
import GoogleButton from '../GoogleButton';
import useStyles from './styles';
import classnames from 'classnames';

const GoogleSignin = ({ disabled, onSuccess }) => {
  const classes = useStyles();
  const { t } = useTranslation('auth');
  const dispatch = useDispatch();

  const onSuccessGoogleResponse = (response) => {
    const userObject = jwtDecode(response.credential);
    const { email } = userObject;
    onSuccess({
      googleUser: {
        email: email,
        token: response.credential,
      },
    });
  };

  const onFailureGoogleResponse = () => {
    const errorMessage = t('signUp.somethingWentWrong');
    dispatch(showNotificationError(errorMessage));
  };

  return (
    <div className={classnames(classes.root, { [classes.disabled]: disabled })}>
      <GoogleButton
        onFailure={onFailureGoogleResponse}
        onSuccess={onSuccessGoogleResponse}
        text="signin_with"
      />
    </div>
  );
};

export default GoogleSignin;
