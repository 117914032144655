import { handleActions } from 'redux-actions';

import { getFeedFiltersSuccess, getFeedFiltersFail } from '../actions';

const defaultState = null;

export default handleActions(
  {
    [getFeedFiltersSuccess](state, action) {
      return action.response.data;
    },

    [getFeedFiltersFail]() {
      return defaultState;
    },
  },
  defaultState,
);
