export const Routes = {
  SIGNIN: '/auth/sign-in',
  SIGNUP: '/auth/sign-up',
  HOME: '/',
  ACCOUNTS: '/accounts',
  MIGRATION_REQUESTS: '/migration-requests',
  MY_ACCOUNTS: '/accounts/list',
  PAYMENTS: '/payments',
  PAYMENTS_SUCCESS: '/payments?id=',
  CONFIRM_PAYMENT: '/payments/confirm-payment',
  CRYPTO_DAY1_DEPOSIT: '/payments/crypto-deposit',
  PAY_ID_PAYMENT: '/payments/pay-id',
  PAYPAL_SIMULATE: '/payments/simulate-paypal',
  PAY_ID_FINMO_PAYMENT: '/payments/pay-id-finmo',
  CONFIRM_REALTIME_PAYMENT: '/payments/confirm-realtime-payment',
  CONFIRM_CURRENCY_CLOUD: '/payments/confirm-currency-cloud',
  WITHDRAWAL_HISTORY: '/payments/withdraw/history',
  DOWNLOADS: '/downloads',
  FUSION_PLUS_ABOUT: '/plus/about',
  FUSION_PLUS_INVITATIONS: '/plus/invitations',
  DAY1_EXCHANGE: '/day1-exchange',
  CRYPTO_WAITLIST: '/crypto-waitlist',
  MY_FOLLOWERS: '/plus/my-followers',
  MASTER_ACCOUNTS: '/plus/master-accounts',
  MY_FOLLOWERS_WAITLIST: '/plus/waitlist',
  MY_PERFORMANCE: '/my-performance/:id',
  USER_PERFORMANCE: '/shared-performance/:id',
  MY_FOLLOWERS_JOIN: '/follower-join/:inviteFollowerId',
  MY_FOLLOWERS_TRADES: '/plus/trades',
  ECONOMIC_CALENDAR: '/economic-calendar',
  MIGRATE_MY_ACCOUNT: '/migrate-my-account',
  UPGRADE_TO_PRO: '/upgrade-to-pro',
  AUTH: '/auth',
  AUTH_MFA_INPUT: '/auth/mfa-input',
  AUTH_MFA_REQUIRED: '/auth/mfa-required',
  AUTH_MFA_SETUP: '/auth/mfa',
  AUTH_FORGOT_PASSWORD: '/auth/forgot-password',
  AUTH_SIGN_IN_INTERNAL: '/auth/sign-in/internal',
  AUTH_PARTNERS: '/auth/partners',
  AUTH_PARTNERS_SIGNIN: '/auth/partners/sign-in',
  AUTH_FORCE_PASSWORD_CHANGE: '/auth/force-password-change',
  AUTH_RESET_PASSWORD: '/auth/reset-password/:token?',
  AUTH_MIGRATION: '/auth/migrate/:token?',
  AUTH_EMAIL_VERIFICATION: '/auth/email-verification',
  PROFILE: '/profile',
  PROFILE_VERIFICATION: '/profile/verification',
  UPGRADE_TO_PRO_V_2: '/upgrade-to-pro-v2',
  USER_MANAGEMENT: '/user-management',
  USER_MANAGEMENT_DETAILS: '/user-management/user',
  USER_MANAGEMENT_DETAILS_ID: '/user-management/user/:id',
  TICKET_HISTORY_ID: '/tickets/history/:id',
  VERIFICATION_MANAGEMENT: '/verification-management',
  CONTACT_US: '/contact-us',
  VERIFICATION_MANAGEMENT_DETAILS: '/verification-management/request',
  VERIFICATION_MANAGEMENT_DETAILS_ID: '/verification-management/request/:id',
  VALIDATION_REF_CODES: '/validation-ref-codes/:pageNumber?',
  PAYMENT_MANAGEMENT_DETAILS: '/payment-management/request',
  PAYMENT_MANAGEMENT_DETAILS_ID: '/payment-management/request/:id',
  PAYMENT_MANAGEMENT: '/payment-management',
  EMAIL_MANAGEMENT: '/email-management',
  TRADING_CALCULATORS: '/trading-calculators',
  PUBLIC_CALCULATORS: '/public-calculators',
  PUBLIC_CALCULATORS_ASIC: '/public-calculators-asic',
  TECHNICAL_INSIGHT: '/insight/technical-insight',
  PUBLIC_ECONOMIC_CALENDAR: '/public-economic-calendar',
  //verify legacy?? not styled
  DAY1X_SUBSCRIBTION: '/public-day1-subscribe',
  PAGE_WARNING: '/page-warning-configuration',
  RISK_GROUP: '/risk-group',
  MT4LIVE_COUNTRIES: '/mt4Live-countries',
  PAYMENT_QUEUE: '/payment-queue/:pageNumber?',
  NOTIFICATIONS: '/notifications',
  REDUCE_FEES: '/reduce-fees',
  MARKET_BUZZ: '/market-buzz',
  MARKET_BUZZ_DASHBOARD: '/market-buzz/dashboard',
  MARKET_BUZZ_DASHBOARD_ID: '/market-buzz/dashboard/:entityId',
  MY_FEEDS: '/news/my-feeds',
  MY_FEEDS_WITH_ID: '/news/my-feeds/:id',
  SENTIMENT: '/news/sentiment',
  ANALYST_VIEWS: '/insight/analyst',
  ANALYST_VIEWS_WITH_ID: '/insight/analyst/:id',
  NEWS_SEARCH: '/news/search',
  SECURITY_LOGS: '/security-logs/:pageNumber?',
  OPERATIONS: '/operations',
  MFA_SETTINGS: '/settings/mfa',
  ERROR_PAGE: '/error',
  DASHBOARD: '/dashboard',
  CELLXPERT_RESET_PASSWORD: '/ib-reset-password/:token',
  LEAD_JOIN: '/user/join/:token',
  VPS: '/vps',
  CUSTOM_FIELDS: '/custom-fields',
  CUSTOM_FIELDS_ANSWERS: '/custom-fields-answers',
  CLIENT_REQUESTS: '/client-requests/:pageNumber?',
  ACCOUNT_REQUESTS: '/account-requests/:pageNumber?',
  FOLLOWUP_REQUESTS: '/followup-requests/:pageNumber?',
  EVIDENCE_STATEMENT: '/evidence-statement',
  EXPERIENCE_UPLOADS: '/experience-uploads/:pageNumber?',
  KYC_REQUESTS: '/kyc-requests/:pageNumber?',
  KYC_PENDING_REQUESTS: '/kyc-pending-requests/:pageNumber?',
  IM_USER_MESSAGE: '/user-management/im',
  PROMOTIONS: '/promotions',
  UPGRADES: '/upgrades',
};

export const RoutesForStaticSite = {
  DAY1X_SUBSCRIBTION: '/public-day1-subscribe',
};

export const PAYMENT_TAB_PATHS = {
  DEPOSIT: 'deposit',
  TRANSFER: 'transfer',
  WITHDRAW: 'withdraw',
  WITHDRAW2: 'withdraw2',
};

export const DEPOSIT_PATH = `${Routes.PAYMENTS}/${PAYMENT_TAB_PATHS.DEPOSIT}`;
export const WITHDRAWAL_PATH = `${Routes.PAYMENTS}/${PAYMENT_TAB_PATHS.WITHDRAW}`;
export const WITHDRAWAL2_PATH = `${Routes.PAYMENTS}/${PAYMENT_TAB_PATHS.WITHDRAW2}`;
