import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHideCompleteAML } from '../../selectors';
import { COMPLETE_AML_MODAL } from '../../constants';
import TPModal from 'components/TP-UI/TPModal';
import { openModal, closeModal } from 'components/TP-UI/TPModal/actions';
import { hideCompleteAML } from '../../actions';
import { getUser, isAuHub } from '../../../auth/selectors';
import { get } from 'lodash';
import { isDirty, reset } from 'redux-form';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const EMAIL_QUIZ_QUERY = 'showAML';

const CompleteAMLContainer = ({ withdrawForm, onOpenQuiz }) => {
  const dispatch = useDispatch();
  const isHideCompleteAML = useSelector(getHideCompleteAML);
  const user = useSelector(getUser);
  const withdrawFormIsDirty = useSelector(isDirty(withdrawForm));
  const isAustralianHub = useSelector(isAuHub);
  const history = useHistory();

  useEffect(() => {
    if (
      isAustralianHub &&
      withdrawForm &&
      user &&
      !get(user, 'AMLProgress.passed', false) &&
      withdrawFormIsDirty
    ) {
      dispatch(openModal(COMPLETE_AML_MODAL));
    }
  }, [dispatch, isAustralianHub, user, withdrawForm, withdrawFormIsDirty]);

  useEffect(() => {
    const hasAMLQuery = history.location.search.includes(EMAIL_QUIZ_QUERY);
    if (
      (!isHideCompleteAML || hasAMLQuery) &&
      user &&
      !get(user, 'AMLProgress.passed', false) &&
      get(user, 'AMLProgress.requestedAt', null)
    ) {
      if (hasAMLQuery) {
        history.replace(history.location.pathname);
      }
      dispatch(openModal(COMPLETE_AML_MODAL));
    }
  }, [dispatch, history, isHideCompleteAML, user]);

  const handleOpenAMLQuiz = useCallback(() => {
    dispatch(closeModal(COMPLETE_AML_MODAL));
    dispatch(reset(withdrawForm));
    onOpenQuiz();
  }, [dispatch, onOpenQuiz, withdrawForm]);

  const handleCancel = useCallback(() => {
    dispatch(closeModal(COMPLETE_AML_MODAL));
    dispatch(hideCompleteAML());
    dispatch(reset(withdrawForm));
  }, [dispatch, withdrawForm]);

  const buttons = useMemo(
    () => [
      { label: "Let's do it", primary: true, onClick: handleOpenAMLQuiz },
      { label: 'Not Now', secondary: true, onClick: handleCancel },
    ],
    [handleCancel, handleOpenAMLQuiz],
  );

  return (
    <TPModal
      text="Kindly take a minute to complete an AML questionnaire."
      title="4 Quick Questions"
      modalName={COMPLETE_AML_MODAL}
      buttons={buttons}
      onClose={handleCancel}
    />
  );
};

CompleteAMLContainer.propTypes = {
  withdrawForm: PropTypes.string,
  onOpenQuiz: PropTypes.func.isRequired,
};

export default CompleteAMLContainer;
