import api from '..';

/**
 * Join to Refer A Friend program
 */
export const joinToReferFriendRequest = (payload) => {
  return api.post('/raf', payload);
};

/**
 * Get a referral link
 */
export const getReferralLinkRequest = () => {
  return api.get('/raf/referral-link');
};

/**
 * Get a referrals list
 */
export const getReferralsRequest = (params) => {
  return api.get('/raf/referrals', { params });
};

export const getFeesDiscountRequest = () => {
  return api.get('/raf/fees-discount');
};

export const getReferralBalanceRequest = () => {
  return api.get('/raf/balance');
};

export const getReferralEarningsRequest = () => {
  return api.get('/raf/earnings');
};

export const getReferralEarningsWithdrawalRequest = (payload) => {
  return api.post('/raf/withdrawal', payload);
};
