import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    multilineAdornment: {
      alignSelf: 'baseline',
    },
    errorContainer: {
      minHeight: theme.textError.root.minHeight,
    },
    password: {
      '& input': {
        textOverflow: 'unset',
      },
    },
    number: {
      '& input': {
        appearance: 'textfield',
      },
    },
  }),
  { name: 'TPTextField' },
);

export default useStyles;
