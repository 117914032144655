import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { getPhoneVerificationFormError } from 'modules/auth/pages/SignUp/selectors';
import {
  checkNonLatin,
  phoneNumber,
  required,
  stringLength60,
} from '../../../../utils/validation/fieldValidationRules';
import { SIGNUP_FORM } from '../../pages/SignUp/constants';
import config from '../../../../config';
import TPTextField from 'components/TP-UI/TPTextField';
import TPPhoneTextField from 'components/TP-UI/TPPhoneTextField';
import TPButton from 'components/TP-UI/TPButton';
import TPGrid from 'components/TP-UI/TPGrid';

import useStyles from './styles';

import { initializeApp } from 'firebase/app';
import {
  getAuth,
  RecaptchaVerifier,
  setPersistence,
  browserSessionPersistence,
} from 'firebase/auth';

const PhoneVerificationForm = ({
  alphabeticalCountries,
  onVerify,
  isCodeRequested,
  isCodeRequestPending,
  onConfirm,
  isCodeConfirmingRequestPending,
  isPhoneVerified,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('auth');
  const formValues = useSelector(getFormValues(SIGNUP_FORM)) || {};
  const [captcha, setCaptcha] = useState(null);
  const [reCaptchaVerifier, setReCaptchaVerifier] = useState(null);
  const isPhoneError = useSelector(getPhoneVerificationFormError);

  const renderCaptcha = useCallback(() => {
    initializeApp(config.FIREBASE);
    const auth = getAuth();
    setPersistence(auth, browserSessionPersistence).then(() => {
      const reCaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'normal',
          theme: 'light',
          callback: (response) => {
            setCaptcha(response);
            setReCaptchaVerifier(reCaptchaVerifier);
          },
        },
        auth,
      );
      reCaptchaVerifier.render();
    });
  }, []);

  useEffect(() => {
    renderCaptcha();
  }, [renderCaptcha]);

  const handleVerifyClick = () => {
    reCaptchaVerifier.recaptcha.reset();
    onVerify({ phoneNumber: formValues.phoneNumber, recaptchaToken: captcha });
    reCaptchaVerifier.clear();
    isPhoneError && renderCaptcha();
  };
  const handleConfirmClick = () => {
    onConfirm({ code: formValues.codeConfirmation, phoneNumber: formValues.phoneNumber });
  };

  return (
    <>
      {isCodeRequested && !isPhoneVerified ? (
        <>
          <TPGrid container spacing={1} direction="column">
            <TPGrid item xs={12}>
              <Field
                name="codeConfirmation"
                id="codeConfirmation"
                label={t('auth:signUp.confirmPhone.confirmationCode')}
                hint={`${t('auth:signUp.confirmPhone.codeSent')} ${formValues.phoneNumber}`}
                component={TPTextField}
                fullWidth
                required
                validate={[checkNonLatin, required, stringLength60]}
              />
            </TPGrid>
          </TPGrid>
          <TPGrid container spacing={2} className={classes.buttons}>
            <TPGrid item xs={12}>
              <TPButton
                size="large"
                fullWidth
                primary
                loading={isCodeConfirmingRequestPending}
                onClick={handleConfirmClick}>
                {t('auth:signUp.confirmPhone.confirm')}
              </TPButton>
            </TPGrid>
          </TPGrid>
        </>
      ) : (
        <>
          <TPGrid container spacing={1} direction="column">
            <TPGrid item xs={12}>
              <Field
                name="phoneNumber"
                id="phoneNumber"
                label={t('common:labels.phoneNumber')}
                type="text"
                component={TPPhoneTextField}
                options={alphabeticalCountries}
                grouped
                required
                fullWidth
                disabled={isPhoneVerified}
                hint={isPhoneVerified ? t('auth:signUp.confirmPhone.numberVerified') : ''}
                validate={[checkNonLatin, required, phoneNumber]}
              />
            </TPGrid>
            {!isPhoneVerified ? (
              <TPGrid item xs={12}>
                <div id="recaptcha-container" className={classes.captcha}></div>
              </TPGrid>
            ) : null}
          </TPGrid>
          {!isPhoneVerified ? (
            <TPGrid container spacing={2} className={classes.buttons}>
              <TPGrid item xs={12}>
                <TPButton
                  primary
                  size="large"
                  fullWidth
                  onClick={handleVerifyClick}
                  loading={isCodeRequestPending}
                  disabled={!captcha}>
                  {t('auth:signUp.confirmPhone.verify')}
                </TPButton>
              </TPGrid>
            </TPGrid>
          ) : null}
        </>
      )}
    </>
  );
};

export default PhoneVerificationForm;
