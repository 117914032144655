import React, { useState, useEffect, useCallback, memo } from 'react';
import TPScrollToTopButton from './components/TPScrollToTopButton';
import PropTypes from 'prop-types';

const TPScrollToTop = ({ visibleAt = 300, scrollContainerRef, onVisibilityChange }) => {
  const [visible, setVisible] = useState(false);

  const toggleVisibility = useCallback(() => {
    const newVisibilityState = scrollContainerRef.current.scrollTop > visibleAt;
    setVisible(newVisibilityState);
    onVisibilityChange(newVisibilityState);
  }, [visibleAt, scrollContainerRef, onVisibilityChange]);

  const handleScrollToTop = useCallback(() => {
    if (scrollContainerRef?.current) {
      scrollContainerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [scrollContainerRef]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', toggleVisibility);
      return () => scrollContainer.removeEventListener('scroll', toggleVisibility);
    }
  }, [toggleVisibility, scrollContainerRef, onVisibilityChange, visible]);

  return visible ? <TPScrollToTopButton onScrollToTop={handleScrollToTop} /> : null;
};

TPScrollToTop.propTypes = {
  visibleAt: PropTypes.number,
  scrollContainerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  onVisibilityChange: PropTypes.func.isRequired,
};

export default memo(TPScrollToTop);
