export const DIALOG_NAME = {
  ADD_ACCOUNT: 'ADD_ACCOUNT',
  EDIT_ACCOUNT: 'EDIT_ACCOUNT',
};
export const ADD_ACCOUNT_MODAL = 'ADD_ACCOUNT_MODAL';
export const EDIT_ACCOUNT_MODAL = 'EDIT_ACCOUNT_MODAL';
export const FUND_DEMO_ACCOUNT_MODAL = 'FUND_DEMO_ACCOUNT_MODAL';

export const ADD_ACCOUNT_FORM = 'ADD_ACCOUNT_FORM';
export const EDIT_ACCOUNT_FORM = 'EDIT_ACCOUNT_FORM';
export const FUND_DEMO_ACCOUNT_FORM = 'FUND_DEMO_ACCOUNT_FORM';

export const MIGRATION_MODAL = 'MIGRATION_MODAL';

export const FUND_OPTIONS = [
  { value: 'deposit', label: 'Deposit' },
  { value: 'withdraw', label: 'Withdraw' },
];

export const TOGGLE_ACCOUNT_VISIBILITY_CONFIRMATION_MODAL =
  'TOGGLE_ACCOUNT_VISIBILITY_CONFIRMATION_MODAL';

export const ACCEPT_INVITATION_MODAL = 'ACCEPT_INVITATION_MODAL';

export const ACCEPT_INVITATION_FORM = 'ACCEPT_INVITATION_FORM';

export const UNDO_ARCHIVE_MODAL = 'UNDO_ARCHIVE_MODAL';
export const UNDO_ARCHIVE_FORM = 'UNDO_ARCHIVE_FORM';

export const ACCOUNTS_LIMIT = {
  PLAIN: 10,
  VIP: 1000,
};
export const DEMO_ACCOUNTS_LIMIT = {
  PLAIN: 10,
  VIP: 1000,
};

export const LOGIN_LABEL = {
  mt4: 'mt4login',
  mt4_2: 'mt4login',
  mt5: 'mt5login',
  ctrader: 'ctraderlogin',
  tradingView: 'tradingViewlogin',
};
