import React from 'react';
import { useTheme } from '@material-ui/core';
import TPModal from 'components/TP-UI/TPModal';
import { TMD_LOCKED_MODAL } from '../../constants';
import TPCardAvatar from 'components/TP-UI/TPCard/components/TPCardAvatar';
import TPTypography from 'components/TP-UI/TPTypography';
import TPGrid from 'components/TP-UI/TPGrid';
import TPLink from 'components/TP-UI/TPLink';
import TPImage from 'components/TP-UI/TPImage';

import useStyles from './styles';

const TMDLockedModal = () => {
  const classes = useStyles();
  const { tmd } = useTheme();
  const { src, ...avatarStyles } = tmd.failModal.avatar;

  return (
    <TPModal
      modalName={TMD_LOCKED_MODAL}
      className={classes.modal}
      hideButtons
      hideClose
      disableEscapeKeyDown
      title="Oh no! Unfortunately you don't meet our target market">
      <TPGrid justifyContent="center" container spacing={2}>
        <TPGrid item>
          <TPCardAvatar size="80px" primary>
            <TPImage src={src} style={avatarStyles} />
          </TPCardAvatar>
        </TPGrid>
        <TPGrid item>
          <TPTypography>
            To ensure you don&apos;t engage in trading activities you are not suitable for,
            you&apos;ll be logged out in 30 seconds, and account closed. If you believe this is a
            mistake, please contact our support team at{' '}
            <TPLink path="mailto:help@fusionmarkets.com" size="large">
              help@fusionmarkets.com
            </TPLink>
          </TPTypography>
        </TPGrid>
      </TPGrid>
    </TPModal>
  );
};

export default TMDLockedModal;
