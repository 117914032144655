import { handleActions } from 'redux-actions';
import * as actions from '../actions';
import { assignTradesUniqueIndex } from '../helpers';

const defaultState = {
  followers: null,
  accounts: [],
  leadAccount: {},
  invite: {},
  invites: [],
  cxd: null,
  symbolMap: [],
  settingsAccepted: false,
  settings: {},
  openedTrades: null,
  closedTrades: null,
  closedTradesCount: 0,
  errorTrades: null,
  inviteFollowerId: '',
  myInvitations: null,
  waitlist: [],
  selectedFollowers: [],
  masterAccounts: [],
};

export default handleActions(
  {
    [actions.getMyFollowersSuccess]: (state, action) => {
      return { ...state, followers: action.response.data };
    },
    [actions.getUserFollowersMyFollowersSuccess]: (state, action) => {
      return {
        ...state,
        followers: action.response.data.map((f) => ({
          ...f,
          leadAccount: f.leadAccountId,
          followerAccount: f.followerAccountId,
        })),
      };
    },
    [actions.getMyFollowersAccountsSuccess]: (state, action) => {
      return { ...state, accounts: action.response.data };
    },
    [actions.getMasterAccountsSuccess]: (state, action) => {
      return { ...state, masterAccounts: action.response.data };
    },
    [actions.createFollowerAccountSuccess]: (state, action) => {
      return { ...state, accounts: [...state.accounts, action.response.data] };
    },
    [actions.getMyFollowersJoinIdSuccess]: (state, action) => {
      return {
        ...state,
        leadAccount: action.response.data.account,
        settings: action.response.data.settings,
      };
    },
    [actions.getFollowerInvitesSuccess]: (state, action) => {
      return { ...state, invites: action.response.data };
    },
    [actions.getUserCxdSuccess]: (state, action) => {
      return { ...state, cxd: action.response.data.cxd };
    },
    [actions.myFollowersTradesOpenedSuccess]: (state, action) => {
      return {
        ...state,
        openedTrades: assignTradesUniqueIndex(action.response.data.trades),
      };
    },
    [actions.myFollowersTradesErrorSuccess]: (state, action) => {
      return {
        ...state,
        errorTrades: assignTradesUniqueIndex(action.response.data.trades),
      };
    },
    [actions.myFollowersTradesClosedSuccess]: (state, action) => {
      return {
        ...state,
        closedTrades: assignTradesUniqueIndex(action.response.data.trades),
        closedTradesCount: action.response.data.count,
      };
    },

    [actions.userFollowersTradesOpenedSuccess]: (state, action) => {
      return {
        ...state,
        openedTrades: assignTradesUniqueIndex(action.response.data.trades),
      };
    },
    [actions.userFollowersTradesErrorSuccess]: (state, action) => {
      return {
        ...state,
        errorTrades: assignTradesUniqueIndex(action.response.data.trades),
      };
    },
    [actions.userFollowersTradesClosedSuccess]: (state, action) => {
      return {
        ...state,
        closedTrades: assignTradesUniqueIndex(action.response.data.trades),
        closedTradesCount: action.response.data.count,
      };
    },

    [actions.getMyFollowersMyInvitationsSuccess]: (state, action) => {
      return { ...state, myInvitations: action.response.data };
    },
    [actions.getUserFollowersMyInvitationsSuccess]: (state, action) => {
      return { ...state, myInvitations: action.response.data };
    },
    [actions.createFollowerInvitationLinkSuccess]: (state, action) => {
      return { ...state, inviteFollowerId: action.response.data.link };
    },
    [actions.setInviteFollowerId]: (state, action) => {
      return { ...state, inviteFollowerId: action.payload };
    },
    [actions.myFollowersGetWaitlistSuccess]: (state, action) => {
      return { ...state, waitlist: action.response.data };
    },

    [actions.selectedFollowers]: (state, action) => {
      return { ...state, selectedFollowers: action.payload };
    },

    [actions.editSelectedCopiersSuccess]: (state) => {
      return { ...state, selectedFollowers: [] };
    },
  },
  defaultState,
);
