// https://support.google.com/google-ads/answer/7012522#lead_tracking
import Cookies from 'js-cookie';
import { getCookieDomain } from 'utils/getCookieName';

const LOCAL_STORAGE_KEY = 'gclid';

const isGclsrcValid = (gclsrcParam) => {
  return !gclsrcParam || gclsrcParam.indexOf('aw') !== -1;
};

export function getGclid() {
  const query = new URLSearchParams(document.location.search);

  let gclid = query.get('gclid');
  const gclsrc = query.get('gclsrc');

  if (!gclid || !isGclsrcValid(gclsrc)) {
    gclid = Cookies.get(LOCAL_STORAGE_KEY);
    if (!gclid) {
      return null;
    }
  } else {
    Cookies.set(LOCAL_STORAGE_KEY, String(gclid), {
      path: '/',
      sameSite: 'none',
      secure: true,
      domain: getCookieDomain(),
    });
  }

  return gclid;
}
