import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { HorizontalBar as Bar } from 'react-chartjs-2';
import { useTheme } from '@material-ui/core';

const HorizontalBar = ({ items = [], labelKey = 'name', dataKey, label, lineHeight = 40 }) => {
  const labels = useMemo(() => items.map((i) => i[labelKey]), [items, labelKey]);
  const data = useMemo(() => items.map((i) => i[dataKey]), [items, dataKey]);
  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        onClick: () => {},
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              offsetGridLines: false,
            },
          },
        ],
      },
    }),
    [],
  );
  const { palette } = useTheme();
  const len = items.length;
  const chartHeight = lineHeight * len - 0.07 * len * len;

  const dataset = useMemo(
    () => ({
      labels,
      datasets: [
        {
          label: label,
          data,
          type: 'horizontalBar',
          backgroundColor: palette.chart.chart1,
          borderColor: palette.chart.chart1,
        },
      ],
    }),
    [data, labels, label, palette],
  );

  return (
    <div style={{ width: '100%', height: chartHeight + 'px' }}>
      <Bar data={dataset} options={options} />
    </div>
  );
};

HorizontalBar.propTypes = {
  items: PropTypes.array,
  labelKey: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  lineHeight: PropTypes.number,
};

export default HorizontalBar;
