import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(2),
      ...theme.stepper.stepHeader.root,
    },
    passed: {
      ...theme.stepper.stepHeader.passed,
    },
    active: {
      paddingBottom: 0,
      '&$passed': {
        background: theme.stepper.stepHeader.root.backgroundColor,
      },
    },
    popoverContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
  { name: 'TPStepHeader' },
);
