const styles = () => ({
  navLink: {
    outlineColor: '#ACF8E8',
    color: '#7777C1',
    textDecoration: 'none',
    fontSize: 14,
    '&:hover': {
      color: '#2727C5',
    },
  },
});

export default styles;
