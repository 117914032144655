import { handleActions } from 'redux-actions';
import get from 'lodash/get';

import * as actionCreators from '../actions';

const defaultState = { fields: [], hyperwalletAccountTypes: [], userStatus: '' };

export default handleActions(
  {
    [actionCreators.getTransferDetailsSuccess](state, action) {
      const stateToUpdate = {
        ...state,
        fields: get(action, 'response.data.fields', []),
      };
      if (!get(state, 'hyperwalletAccountTypes', []).length) {
        stateToUpdate.hyperwalletAccountTypes = get(action, 'response.data.accountTypes', []);
      }
      return stateToUpdate;
    },
    [actionCreators.getTransferDetailsFail]() {
      return defaultState;
    },
    [actionCreators.getUserStatusSuccess](state, action) {
      const status = get(action, 'response.data.userStatus', '');

      const stateToUpdate = {
        ...state,
        userStatus: status,
      };

      return stateToUpdate;
    },
  },
  defaultState,
);
