import get from 'lodash/get';
import { stopSubmit } from 'redux-form';
import { all, put, takeEvery } from 'redux-saga/effects';
import { forcePasswordChangeFail, forcePasswordChangeSuccess } from '../actions';
import { FORCE_PASSWORD_CHANGE_FORM } from '../constants';
import { redirectToDashboard } from '../../SignIn/sagas';

function* showError(action) {
  const { message: errorMessage } = get(action, 'error.data', {});

  yield put(
    stopSubmit(FORCE_PASSWORD_CHANGE_FORM, {
      _error: errorMessage,
    }),
  );
}

export default function* watchRequest() {
  yield all([
    takeEvery(forcePasswordChangeSuccess, redirectToDashboard),
    takeEvery(forcePasswordChangeFail, showError),
  ]);
}
