import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Buttons from './Buttons';
import IconButtons from './IconButtons';
import Breadcrumbs from './Breadcrumbs';
import SidebarLogos from './SidebarLogos';
import Tabs from './Tabs';
import useStyles from './styles';
import TPTypography from 'components/TP-UI/TPTypography';
import Checkbox from './Checkbox';
import Radio from './Radio';
import Links from './Links';
import ProgressBars from './ProgressBars';
import Selects from './Selects';
import Popovers from './Popovers';
import Typography from './Typography';
import Tooltips from './Tooltips';
import Loaders from './Loaders';
import TextFields from './TextFields';
import Modals from './Modals';
import DatePickers from './DatePickers';
import Dividers from './Dividers';
import Alerts from './Alerts';
import Snackbars from './Snackbars';
import SnackbarProvider from 'components/TP-UI/TPSnackbar/context';
import Tables from './Tables';
import Cards from './Cards';
import Chips from './Chips';
import TrendingComponents from './TrendingComponents';
import DescriptionLists from './DescriptionLists';
import TinyEditor from './TinyEditor';
import Drawers from './Drawers';
import NavMenuLists from './NavMenuLists';
import Autocompletes from './Autocompletes';
import Menus from './Menus';
import Headers from './Headers';
import Images from './Images';
import Steppers from 'modules/UIComponents/Steppers';
import BottomNavigations from './BottomNavigations';
import FileUploaders from './FileUploaders';
import Sliders from './Sliders';
import TPCopyButtons from './TPCopyButtons';
import Accordions from './Accordions';
import QuizModal from './QuizModal';
import Banners from './Banners';
import Ratings from './Ratings';
import Tags from './Tags';

const UIComponents = () => {
  const classes = useStyles();

  /**
   * Showcase for TPScrollToTop component.
   *
   * Usage:
   *
   *   const scrollContainerRef = useRef(null);
   *
   *   return (
   *     <div className={classes.layout} ref={scrollContainerRef}>
   *       <TPScrollToTop scrollContainerRef={scrollContainerRef} />
   *       ... // other components
   *     </div>
   *   );
   *
   */

  return (
    <div className={classes.layout}>
      <TPTypography variant="h1">UI Components Library</TPTypography>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Accordions</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Accordions />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Alerts</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Alerts />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Autocompletes</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Autocompletes />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Banners</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Banners />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">BottomNavigations</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <BottomNavigations />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Breadcrumbs</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Breadcrumbs />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Buttons</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Buttons />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Cards</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Cards />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Checkbox</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Checkbox />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Chips</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Chips />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">CopyButtons</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <TPCopyButtons />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">DatePickers</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <DatePickers />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Description Lists (details)</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <DescriptionLists />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Dividers</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Dividers />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Drawers</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Drawers />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">File Uploaders</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <FileUploaders onSubmit={() => {}} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Headers</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Headers />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Images</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Images />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">IconButton</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <IconButtons />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Links</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Links />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Loaders</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Loaders />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Menus</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Menus />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Modals</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Modals />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">NavMenuLists</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <NavMenuLists />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Popovers</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Popovers />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">ProgressBars</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <ProgressBars />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Quiz Modal</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <QuizModal />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Radio</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Radio />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Ratings</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Ratings />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Select</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Selects />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">SidebarLogo</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <SidebarLogos />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Sliders</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Sliders />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Snackbars</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <SnackbarProvider>
            <Snackbars />
          </SnackbarProvider>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Steppers</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Steppers />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Tables</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Tables />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Tabs</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Tabs />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Tags</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Tags />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Text Fields</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <TextFields />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">TinyMCE Editor</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <TinyEditor />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Tooltips</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Tooltips />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Trending components</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <TrendingComponents />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <TPTypography variant="h6">Typography</TPTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default UIComponents;
