import makeStyles from '@material-ui/core/styles/makeStyles';

const getButtonSize = (size) => ({
  width: size,
  height: size,
});

const useStyles = makeStyles(
  (theme) => ({
    xsmallSize: {
      ...getButtonSize('16px'),
      ...theme.iconButton.root.xsmallSize,
    },
    smallSize: {
      ...getButtonSize('32px'),
      ...theme.iconButton.root.smallSize,
    },
    mediumSize: {
      ...getButtonSize('36px'),
      ...theme.iconButton.root.mediumSize,
    },
    largeSize: {
      ...getButtonSize('48px'),
      ...theme.iconButton.root.largeSize,
    },
    primaryColor: {
      ...theme.iconButton.primaryColor.root,
      '&:hover, &.Mui-focusVisible': {
        ...theme.iconButton.primaryColor.hover,
      },
      '&:active': {
        ...theme.iconButton.primaryColor.active,
      },
      '&.Mui-disabled': {
        ...theme.iconButton.primaryColor.disabled,
      },
    },
    textContent: {
      ...theme.iconButton.root.text,
    },
  }),
  { name: 'TPIconButton' },
);

export default useStyles;
