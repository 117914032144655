import React from 'react';
import TPLink from 'components/TP-UI/TPLink';

const re = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim;

const replaceLink = ({ text }) => {
  const parts = text.split(re);
  const links = text.match(re);

  const res = [];
  for (let i = 0; i < parts.length; i++) {
    res.push(parts[i]);
    if (links && links[i]) {
      res.push(
        <TPLink path={links[i]} target="_blank" rel="noopener noreferrer">
          {links[i]}
        </TPLink>,
      );
    }
  }

  return res;
};

export default replaceLink;
