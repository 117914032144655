import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '../../SignUp/hooks/useQuery';
import { createPendingSelector } from 'modules/api/selectors';
import EmailVerification from '../components/EmailVerification';
import { verifyEmailRequest, sendVerificationEmailRequest } from '../actions';
import { getCanResend, getVerificationError } from '../selectors';
import { store } from 'components/App';
import saga from '../sagas';
import { getHub } from 'components/App/selectors';

const EmailVerificationContainer = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const token = query.get('token') || null;
  const pending = useSelector(createPendingSelector(verifyEmailRequest));
  const pendingResendEmail = useSelector(createPendingSelector(sendVerificationEmailRequest));
  const verificationError = useSelector(getVerificationError);
  const canResend = useSelector(getCanResend);
  const hub = useSelector(getHub);

  const handleSendEmail = useCallback(() => {
    dispatch(sendVerificationEmailRequest());
  }, [dispatch]);

  useEffect(() => {
    store.injectSaga('EmailVerification', saga);

    return () => {
      store.injectSaga('EmailVerification', null);
    };
  }, []);

  useEffect(() => {
    //avoid calling verification when url does not contain hub, issue with page reloading
    if (token && dispatch && hub) {
      dispatch(verifyEmailRequest({ verificationToken: token }));
    }
  }, [dispatch, token, hub]);

  return (
    <EmailVerification
      token={token}
      canResend={canResend}
      pending={pending}
      pendingResendEmail={pendingResendEmail}
      verificationError={verificationError}
      onSendEmail={handleSendEmail}
    />
  );
};

export default EmailVerificationContainer;
