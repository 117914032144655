import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = {};

export default handleActions(
  {
    [actionCreators.getOperationSubscriptionSuccess]: (state, action) => {
      return { ...state, subscriptions: action.response.data.subscriptions };
    },
    [actionCreators.getOperationSubscriptionFail]: () => {
      return { ...defaultState };
    },
  },
  defaultState,
);
