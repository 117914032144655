import { handleActions } from 'redux-actions';

import {
  getPresetFiltersSuccess,
  createPresetFiltersSuccess,
  savePresetFiltersSuccess,
  deletePresetFiltersSuccess,
  cleanupPresetFilters,
} from '../actions';

const defaultState = {};

export default handleActions(
  {
    [getPresetFiltersSuccess](state, action) {
      return { ...state, [action.payload.type]: action.response.data };
    },
    [createPresetFiltersSuccess](state, action) {
      return {
        ...state,
        [action.payload.type]: [...state[action.payload.type], action.response.data],
      };
    },
    [savePresetFiltersSuccess](state, action) {
      const updatedFilterIndex = state[action.payload.type].findIndex(
        (filter) => filter._id === action.payload.filter._id,
      );

      const updatedList = [...state[action.payload.type]];
      updatedList.splice(updatedFilterIndex, 1, action.response.data);

      return {
        ...state,
        [action.payload.type]: updatedList,
      };
    },
    [deletePresetFiltersSuccess](state, action) {
      return {
        ...state,
        [action.payload.type]: state[action.payload.type].filter(
          (filter) => filter._id !== action.payload.filter._id,
        ),
      };
    },
    [cleanupPresetFilters]() {
      return defaultState;
    },
  },
  defaultState,
);
