import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...theme.authPage.root,
    },
    content: {
      maxWidth: '900px',
      margin: '0 auto',
    },
    formLayoutWrapper: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      padding: `${theme.spacing(5)}px ${theme.spacing(3)}px 0`,
      '@media (max-width:680px)': {
        padding: theme.spacing(2),
      },
    },
    formLayout: {
      maxWidth: theme.authPage.formWrapper.maxWidth,
      width: '100%',
      minHeight: '640px',
      background: theme.authPage.formWrapper.background,
      boxShadow: theme.authPage.formWrapper.boxShadow,
      borderRadius: theme.authPage.formWrapper.borderRadius,
      display: 'grid',
      overflow: 'hidden',
      gridTemplateColumns: '1fr 1fr',
      '@media (max-width:780px)': {
        gridTemplateColumns: '1fr',
        minHeight: 'auto',
      },
    },
    formDescription: {
      textAlign: 'center',
      '@media (max-width:780px)': {
        display: 'none',
      },
      ...theme.authPage.formDescription,
    },
    formWrapper: {
      padding: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        padding: `${theme.spacing(3)}px ${theme.spacing(1.75)}px ${theme.spacing(4)}px`,
      },
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%',
      paddingTop: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(4),
      },
    },
    logo: {
      ...theme.authPage.logo,
    },
    disclaimer: {
      textAlign: 'justify',
      padding: `${theme.spacing(6)}px ${theme.spacing(5)}px 0 ${theme.spacing(5)}px`,
      '@media (max-width:1240px)': {
        paddingBottom: theme.spacing(2),
      },
      '@media (max-width:680px)': {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
      '& > p:first-child': {
        margin: 0,
      },
      '& > p': {
        marginTop: 0,
      },
    },
    languageContainer: {
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
  { name: 'Layout' },
);

export default useStyles;
