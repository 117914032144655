import api from 'modules/api';

export const changePasswordRequest = (payload) => {
  return api.post('/auth/change-password', payload);
};

export const searchBankDetailsRequest = (payload) => {
  return api.get('/bank-accounts', { params: payload });
};

export const createBankDetailsRequest = (payload) => {
  return api.post('/bank-accounts', payload);
};

export const deleteBankDetailsRequest = (id) => {
  return api.delete(`/bank-accounts/${id}`);
};

export const createAuthorisedPersonRequest = (payload) => {
  return api.post('/authorised-person', payload);
};

export const getAuthorisedPersonsRequest = () => {
  return api.get('/authorised-person');
};

export const deleteAuthorisedPersonRequest = (id) => {
  return api.delete(`/authorised-person/${id}`);
};

export const saveEmailNotificationsSettingsRequest = (payload) => {
  return api.post('/auth/settings/email-notifications', payload);
};

export const unsubscribeEmailNotificationsSettingsRequest = () => {
  return api.post('/auth/settings/email-notifications', { categories: [] });
};
export const subscribeEmailNotificationsSettingsRequest = (payload) => {
  return api.post('/auth/settings/email-notifications', payload);
};

export const toggleMailchimpSubscriptionRequest = ({ userId, payload }) => {
  return api.post(`/users/${userId}/email-feed`, payload);
};

export const getMailchimpStatusRequest = ({ userId }) => {
  return api.get(`/users/${userId}/email-feed`);
};
