import searchPlaceholderImg from 'static/images/themes/fusion/light/common/search_placeholder.svg';
import emptyListImg from 'static/images/themes/fusion/light/common/emptyList.svg';
import interrogativeImg from 'static/images/themes/fusion/light/common/interrogative.svg';
import waitingImg from 'static/images/themes/fusion/light/common/waiting.svg';
import rejectedImg from 'static/images/themes/fusion/light/common/rejected.svg';
import successImg from 'static/images/themes/fusion/light/common/success.svg';
import noAccountsImg from 'static/images/themes/fusion/light/common/no_accounts.svg';
import leaderboardImg from 'static/images/themes/fusion/light/common/leaderboard.svg';

import selectOneImg from 'static/images/themes/fusion/light/news/select_one.svg';
import authBG2Img from 'static/images/themes/fusion/light/auth/authBG2.svg';
import authBG1Img from 'static/images/themes/fusion/light/auth/authBG1.svg';
import emptyFeedImg from 'static/images/themes/fusion/light/feed/EmptyFeed.svg';
import noNewsImg from 'static/images/themes/fusion/light/feed/NoNewsImage.svg';
import cryptoMeditationImg from 'static/images/themes/fusion/light/day1/crypto-meditation.svg';
import day1Img from 'static/images/themes/fusion/light/day1/day1.svg';
import textBubbleTailImg from 'static/images/themes/fusion/light/day1/textBubbleTail.svg';
import aboutFusionPlusImg from 'static/images/themes/fusion/light/fusionPlus/about-fusion-plus.svg';
import earnOnceOffPaymentImg from 'static/images/themes/fusion/light/raf/earn-once-off-payment.svg';
import reduceCommissionsImg from 'static/images/themes/fusion/light/raf/reduce-commissions.svg';
import personalLinkImg from 'static/images/themes/fusion/light/raf/personal-link-bg.svg';
import reduceFeesImg from 'static/images/themes/fusion/light/reduce-fees/reduce-fees.svg';
import tradingViewLogoImg from 'static/images/themes/fusion/light/tradingView/trading-view-logo.svg';
import tradingViewImageImg from 'static/images/themes/fusion/light/tradingView/trading-view-image.svg';
import tradingViewArrowImg from 'static/images/themes/fusion/light/tradingView/Arrow.svg';
import partySmileImg from 'static/images/themes/fusion/light/common/partyPopper.svg';

import paypalLogoImg from 'static/images/themes/fusion/light/payments/pay_pal_logo.svg';
import netellerLogoImg from 'static/images/themes/fusion/light/payments/neteller_logo.svg';
import bankTransferLogoImg from 'static/images/themes/fusion/light/payments/bank_transfer_logo.svg';
import bankwireLogoImg from 'static/images/themes/fusion/light/payments/bank_wire_transfer_logo.svg';
import localBankTransferLogoImg from 'static/images/themes/fusion/light/payments/local_bank_transfer_logo.svg';
import unionLogoImg from 'static/images/themes/fusion/light/payments/unionpay_logo.svg';
import skrillLogoImg from 'static/images/themes/fusion/light/payments/skrill_logo.svg';
import perfectMoneyLogoImg from 'static/images/themes/fusion/light/payments/perfect_money_logo.svg';
import interacLogoImg from 'static/images/themes/fusion/light/payments/interac_logo.svg';
import cryptoLogoImg from 'static/images/themes/fusion/light/payments/crypto_logo.svg';
import astropayLogoImg from 'static/images/themes/fusion/light/payments/astro_pay_logo.svg';
import dragonpayLogoImg from 'static/images/themes/fusion/light/payments/dragon_pay_logo.png';
import jetonLogoImg from 'static/images/themes/fusion/light/payments/jeton_logo.svg';
import mifinityLogoImg from 'static/images/themes/fusion/light/payments/mifinity_logo.svg';
import payidLogoImg from 'static/images/themes/fusion/light/payments/pay_id_logo.svg';
import paypalAndMoreImg from 'static/images/themes/fusion/light/payments/paypalAndMore.svg';
import cryptoDayImg from 'static/images/themes/fusion/light/payments/cryptoDay1.svg';
import bankTransferImg from 'static/images/themes/fusion/light/payments/bankTransfer.svg';
import payIdImg from 'static/images/themes/fusion/light/payments/payId.svg';
/**
 * Define images that are depends on theme colors, use it inside components via useTheme hook
 * the structure should be the same for all themes, since there is no defaults
 * Example [module name]: {
 *  [image name]: [imported file]
 * }
 */

const images = {
  common: {
    searchPlaceholderImg,
    emptyListImg,
    interrogativeImg,
    waitingImg,
    rejectedImg,
    successImg,
    noAccountsImg,
    leaderboardImg,
    partySmileImg,
  },
  auth: {
    authBG1Img,
    authBG2Img,
  },
  news: {
    selectOneImg,
  },
  feeds: {
    emptyFeedImg,
    noNewsImg,
  },
  day1: {
    cryptoMeditationImg,
    day1Img,
    textBubbleTailImg,
  },
  fusionPlus: {
    aboutFusionPlusImg,
  },
  raf: {
    earnOnceOffPaymentImg,
    reduceCommissionsImg,
    personalLinkImg,
  },
  reduceFees: {
    reduceFeesImg,
  },
  tradingView: {
    tradingViewImageImg,
    tradingViewLogoImg,
    tradingViewArrowImg,
  },
  payments: {
    paypalLogoImg,
    netellerLogoImg,
    bankTransferLogoImg,
    bankwireLogoImg,
    localBankTransferLogoImg,
    unionLogoImg,
    skrillLogoImg,
    perfectMoneyLogoImg,
    interacLogoImg,
    cryptoLogoImg,
    astropayLogoImg,
    dragonpayLogoImg,
    jetonLogoImg,
    mifinityLogoImg,
    payidLogoImg,
    paypalAndMoreImg,
    cryptoDayImg,
    bankTransferImg,
    payIdImg,
  },
};

export default images;
