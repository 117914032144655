import React from 'react';
import { Field, reduxForm } from 'redux-form';

import CheckboxLabel from './CheckboxLabel';
import TPCheckbox from 'components/TP-UI/TPCheckbox';
import TPButton from 'components/TP-UI/TPButton';
import TPGrid from 'components/TP-UI/TPGrid';
import TPErrorMessage from 'components/TP-UI/TPErrorMessage';
import { required } from 'utils/validation/fieldValidationRules';

import { useTranslation } from 'react-i18next';

const ConfirmContinueAsRetailClientForm = ({ handleSubmit, error }) => {
  const { t } = useTranslation('quiz');

  return (
    <form onSubmit={handleSubmit}>
      <TPGrid container item alignItems="center" spacing={3}>
        {error && (
          <TPGrid item xs={12}>
            <TPErrorMessage error={error} size="medium" />
          </TPGrid>
        )}
        <TPGrid container item spacing={2}>
          <TPGrid item>
            <Field
              label={<CheckboxLabel />}
              component={TPCheckbox}
              name="confirmCheckbox"
              validate={[required]}
            />
          </TPGrid>
          <TPGrid container item justifyContent="flex-end">
            <TPButton size="large" primary type="submit">
              {t('proQuizBlockedModal.buttonText')}
            </TPButton>
          </TPGrid>
        </TPGrid>
      </TPGrid>
    </form>
  );
};

export default reduxForm({
  form: 'PRO_QUIZ_BLOCKED_MODAL_FORM',
  initialValues: { confirmCheckbox: false },
  destroyOnUnmount: true,
})(ConfirmContinueAsRetailClientForm);
