import fusionNormal from './fusion/light';
import fmgpNormal from './fmgp/light';
import { APP_THEME_NAME, THEME_NAME } from 'constants/theme';

const themes = {
  [APP_THEME_NAME.FUSION_MARKETS]: {
    [THEME_NAME.LIGHT]: fusionNormal,
  },
  [APP_THEME_NAME.FMGP]: {
    [THEME_NAME.LIGHT]: fmgpNormal,
  },
};

export default function getTheme(appTheme, theme = THEME_NAME.LIGHT) {
  return (
    (themes[appTheme] && themes[appTheme][theme]) ||
    themes[APP_THEME_NAME.FUSION_MARKETS][THEME_NAME.LIGHT]
  );
}
