const styles = (theme) => ({
  select: {
    maxWidth: '166px',
    flexGrow: 1,
    border: '1px solid #DADFEC',
    borderRadius: '2px',
    // backgroundColor: '#FFFFFF',
  },

  selectRoot: {
    alignSelf: 'center',
    fontSize: '14px',
    backgroundColor: theme.contentLayout.backgroundColor,
    boxSizing: 'paddingBox',
    paddingLeft: '14px',
    '&:focus': {
      backgroundColor: theme.contentLayout.backgroundColor,
      outline: '1px solid #74C3C4',
    },
  },
  menuItem: {
    padding: '15px 20px',
  },
  disabledItem: {
    color: '#74C3C4',
    paddingLeft: 10,
    fontStyle: 'italic',
    fontWeight: 600,
    opacity: 0.8,
  },
});

export default styles;
