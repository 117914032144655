import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TPFileItem } from './components/TPFileItem';
import TPReduxFileItem from './components/TPFileItem';
import TPFilesPreviewModal from '../TPFilesPreviewModal';

import useStyles from './styles';

export const TPFileList = ({
  items,
  disabled = false,
  preview = false,
  onDelete,
  renderItem,
  className,
}) => {
  const classes = useStyles();
  const [previewItemIndex, setPreviewItemIndex] = useState(null);
  const handleItemClick = useCallback((index) => {
    setPreviewItemIndex(index);
  }, []);
  const handlePreviewClose = useCallback(() => {
    setPreviewItemIndex(null);
  }, []);

  return (
    <div className={className}>
      <ul className={classes.list}>
        {items
          ? items.map((item, index) => {
              const props = {
                value: item,
                disabled,
                //covers cases when component is not redux or Field or FieldArray redux components
                //errors prop are returned by dropzone uploader component (TPFileUploader)
                errors: item.errors || (items.get ? items.get(index).errors : []),
                onClick: () => handleItemClick(index),
                onDelete: onDelete ? () => onDelete(index) : null,
              };
              return (
                <li key={index}>
                  {renderItem ? (
                    renderItem(props)
                  ) : typeof item === 'string' ? (
                    <Field name={props.value} component={TPReduxFileItem} {...props} />
                  ) : (
                    <TPFileItem {...props} />
                  )}
                </li>
              );
            })
          : null}
      </ul>
      {preview && previewItemIndex !== null && (
        <TPFilesPreviewModal
          value={previewItemIndex}
          //covers cases when component is not redux or Field or FieldArray redux components
          items={Array.isArray(items) ? items : items.getAll()}
          onClose={handlePreviewClose}
        />
      )}
    </div>
  );
};

TPFileList.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  renderItem: PropTypes.func,
  disabled: PropTypes.bool,
  /**
   * Ability to preview files by clicking on file item, that opens preview modal
   */
  preview: PropTypes.bool,
  onDelete: PropTypes.func,
};

//Supports Field and FieldArray
//Note: If FileList is used with TPFileUploader please use the same redux component: Field or FieldArray and provide the same validation rules
const TPReduxFileList = ({ input, fields, onDelete, ...others }) => {
  const { value } = input || {};
  const handleDelete = useCallback(
    async (index) => {
      if (fields) {
        await fields.remove(index);
      }
      if (onDelete) {
        onDelete(index);
      }
    },
    [onDelete, fields],
  );
  return <TPFileList items={value || fields} onDelete={handleDelete} {...input} {...others} />;
};

export default TPReduxFileList;
