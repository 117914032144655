import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import Tooltip from '@material-ui/core/Tooltip';

import styles from './styles';

const ClearInputIcon = ({ classes, name, onClickHandler, ...props }) => {
  const { t } = useTranslation('common');

  return (
    <Tooltip title={props.title || t('placeholders.cancelChanges')}>
      <IconButton
        type="submit"
        aria-label={t('placeholders.clear')}
        label={t('placeholders.clear')}
        name={name}
        color="primary"
        className={classes.iconBtn}
        onClick={onClickHandler}>
        <ClearOutlinedIcon className={classes.smallIcon} />
      </IconButton>
    </Tooltip>
  );
};

ClearInputIcon.propTypes = {
  onClickHandler: PropTypes.func,
  name: PropTypes.string,
};

ClearInputIcon.defaultProps = {
  onClickHandler: () => null,
};

export default withStyles(styles)(ClearInputIcon);
