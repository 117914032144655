import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as braze from '@braze/web-sdk';
import config from '../../config';
import { USER_ROLE } from 'constants/userRoles';
import { getIsAuthenticated, getIsImpersonation, getUser } from 'modules/auth/selectors';

const Braze = () => {
  const user = useSelector(getUser);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const isImpersonation = useSelector(getIsImpersonation);

  const { _id, email, role } = user ?? {};

  const environment = process.env.REACT_APP_ENV;

  const isProd = environment === 'production_rd';
  const isDev = environment === 'dev_rd';
  const isStaging = environment === 'testing_rd';
  const isLocal = environment === 'development';

  const isAllowedEnv = isProd || isDev || isStaging || isLocal;

  const isClientUser = role === USER_ROLE.CLIENT;

  const shouldInitializeBraze =
    isAllowedEnv && isAuthenticated && _id && !isImpersonation && isClientUser;

  useEffect(() => {
    if (shouldInitializeBraze) {
      if (!braze.isInitialized()) {
        braze.initialize(config.BRAZE.API_KEY, {
          baseUrl: config.BRAZE.API_URL,
          enableLogging: isProd ? false : true,
          ...(!isProd && { serviceWorkerLocation: '/braze-service-worker.js' }),
        });
      }

      braze.changeUser(_id);

      if (isProd) {
        braze.openSession();
      } else {
        braze.getUser().setEmail(email);
        braze.automaticallyShowInAppMessages();
      }
    }
  }, [shouldInitializeBraze, _id, email, isProd]);

  return null;
};

export default Braze;
