import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TPTextField } from 'components/TP-UI/TPTextField';
import TPInputAdornment from 'components/TP-UI/TPInputAdornment';
import Search from '@material-ui/icons/Search';
import useDebounce from 'hooks/useDebounce';
import usePrevious from '../hooks/usePrevious';

export const TPSearchTextField = ({
  name,
  value = '',
  size = 'medium',
  error,
  reservedErrorSpace = false,
  onChange,
  onBlur,
  debounced = true,
  ...props
}) => {
  const [searchTerm, setSearchTerm] = useState(value);
  const debouncedSearchTerm = useDebounce(searchTerm, debounced ? 700 : 0);
  const prevDebouncedSearchTerm = usePrevious(debouncedSearchTerm);
  useEffect(() => {
    setSearchTerm(value);
  }, [value]);

  useEffect(() => {
    if (onChange && prevDebouncedSearchTerm !== debouncedSearchTerm) {
      onChange(debouncedSearchTerm);
    }
  }, [prevDebouncedSearchTerm, debouncedSearchTerm, onChange]);

  const handleChange = useCallback((value) => {
    setSearchTerm(value);
  }, []);

  return (
    <TPTextField
      name={name}
      value={searchTerm}
      error={error}
      size={size}
      reservedErrorSpace={reservedErrorSpace}
      onChange={handleChange}
      onBlur={onBlur}
      startAdornment={
        <TPInputAdornment position="start">
          <Search fontSize={size} />
        </TPInputAdornment>
      }
      {...props}
    />
  );
};

TPSearchTextField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Reserved space to display error in 1 line
   */
  reservedErrorSpace: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  debounced: PropTypes.bool,
};

const TPSearchReduxTextField = ({ input, meta, ...others }) => {
  const error = meta.submitFailed && meta.error ? meta.error : null;
  const { onChange, onBlur, value } = input;
  const handleBlur = useCallback(() => onBlur(value), [onBlur, value]);
  return (
    <TPSearchTextField
      {...input}
      error={error}
      {...others}
      onChange={onChange}
      onBlur={handleBlur}
    />
  );
};

export default TPSearchReduxTextField;
