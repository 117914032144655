import palette from './palette';

const OverridesMuiStepConnector = {
  vertical: {
    marginLeft: 16,
    paddingBottom: 0,
  },
  line: {
    borderColor: palette.primary.lightest,
  },
  lineVertical: {
    borderLeftStyle: 'solid',
    borderLeftWidth: 1,
    /**
     * This number is responsible for the proper alignment of the step headers with the step labels.
     * It depends on the height in pixels between the headers.
     */
    height: 33,
  },
};

export default OverridesMuiStepConnector;
