import { CRAZYEGG_SRC } from '../constants';

export const loadCrazyEggScript = () => {
  const script = document.createElement('script');
  script.src = CRAZYEGG_SRC;
  script.async = true;
  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  };
};
