import common from './common';
const OverridesMuiSelect = {
  outlined: {
    borderRadius: common.formControl.root.borderRadius,
  },
  select: {
    '&:focus': {
      backgroundColor: common.formControl.focus.backgroundColor,
    },
  },
  selectMenu: {
    height: null,
    minHeight: 'auto',
  },
};

export default OverridesMuiSelect;
