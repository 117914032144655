import React from 'react';
import { useTheme } from '@material-ui/core';
import TPModal from 'components/TP-UI/TPModal';
import { AML_SUCCESS_MODAL } from '../../constants';
import InfoTip from 'modules/common/InfoTip';
import TPGrid from 'components/TP-UI/TPGrid';
import TPCardAvatar from 'components/TP-UI/TPCard/components/TPCardAvatar';
import TPTypography from 'components/TP-UI/TPTypography';
import TPImage from 'components/TP-UI/TPImage';

import useStyles from './styles';

const AMLSuccessModal = () => {
  const classes = useStyles();
  const { aml } = useTheme();
  const { src, ...imageStyles } = aml.success.avatar || {};

  return (
    <TPModal
      className={classes.modal}
      modalName={AML_SUCCESS_MODAL}
      title="Questionnaire"
      titleEndAdornment={
        <InfoTip
          iconSize="medium"
          tooltipContent="Why do we ask this? Understanding the origin of funds helps prevent money laundering and other financial crimes. This information is necessary for compliance with regulatory requirements."
        />
      }>
      <TPGrid justifyContent="center" container spacing={2}>
        <TPGrid item>
          <TPCardAvatar size="80px" primary>
            <TPImage src={src} style={imageStyles} />
          </TPCardAvatar>
        </TPGrid>
        <TPGrid item>
          <TPTypography align="center" variant="h5" gutterBottom>
            Thank you
          </TPTypography>
          <TPTypography>
            Thank you for completing the questionnaire. You can now continue exploring Fusion
            Markets.
          </TPTypography>
        </TPGrid>
      </TPGrid>
    </TPModal>
  );
};

export default AMLSuccessModal;
