import React from 'react';
import PropTypes from 'prop-types';
import Page from 'modules/common/Page';
import { useTranslation } from 'react-i18next';
import config from 'config';
import Layout from '../../../../components/LayoutNew';
import TPTypography from 'components/TP-UI/TPTypography';
import TPLink from 'components/TP-UI/TPLink';
import TPGrid from 'components/TP-UI/TPGrid';
import { FORGOT_PASSWORD_SUCCESS_MODAL } from '../../constants';
import ForgotPasswordForm from '../ForgotPasswordForm';
import TPModal from 'components/TP-UI/TPModal';
import { Routes } from 'constants/routeConstants';

import useStyles from './styles';

const ForgotPassword = ({ form, onSubmit, onSuccess, isPending }) => {
  const classes = useStyles();
  const { t } = useTranslation('auth');
  const { CURRENT_HUB_LABEL } = config;

  return (
    <Page docTitle={t('forgotPassword.documentTitle', { company: CURRENT_HUB_LABEL })}>
      <Layout
        title={t('forgotPasswordText')}
        welcomeTitle={t('logInWelcome')}
        welcomeDescription={t('logInWelcomeDescription')}>
        <TPGrid container spacing={5}>
          <TPGrid item xs={12}>
            <ForgotPasswordForm form={form} onSubmit={onSubmit} isPending={isPending} />
          </TPGrid>
          <TPGrid item container direction="column" spacing={1} alignItems="center" xs={12}>
            <TPGrid item>
              <TPTypography variant="body1">
                <TPLink to={{ pathname: Routes.SIGNIN, state: { cleanupStore: true } }}>
                  {t('backToLogIn')}
                </TPLink>
              </TPTypography>
            </TPGrid>
            <TPGrid item>
              <TPTypography variant="body1">
                {t('notAMemberYet')} <TPLink to={`/auth/sign-up`}>{t('buttons.signUp')}</TPLink>
              </TPTypography>
            </TPGrid>
          </TPGrid>
        </TPGrid>
        <TPModal
          modalName={FORGOT_PASSWORD_SUCCESS_MODAL}
          className={classes.forgotPasswordSuccessModal}
          title={t('forgotPassword.modalTitle')}
          text={t('forgotPassword.modalText')}
          onClose={onSuccess}
        />
      </Layout>
    </Page>
  );
};

ForgotPassword.propTypes = {
  form: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
};

export default ForgotPassword;
