import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = {
  items: null,
  total: 0,
};

export default handleActions(
  {
    [actionCreators.getPaymentsHistorySuccess](state, action) {
      return {
        items: action.response.data.payments,
        total: action.response.data.totalNumber,
      };
    },

    [actionCreators.getPaymentsHistoryFail]() {
      return defaultState;
    },
  },
  defaultState,
);
