import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import useStyles from './styles';

const TPBottomNavigation = ({ value, children, className, onChange }) => {
  const classes = useStyles();

  return (
    <BottomNavigation
      value={value}
      onChange={onChange}
      className={classnames(classes.root, className)}>
      {children}
    </BottomNavigation>
  );
};

TPBottomNavigation.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

TPBottomNavigation.muiName = BottomNavigation.muiName;

export default TPBottomNavigation;
