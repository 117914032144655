import { combineActions, handleActions } from 'redux-actions';
import get from 'lodash/get';

import {
  signinSuccess,
  setDestinationHub,
  appleSigninSuccess,
  googleSigninSuccess,
} from '../actions';
import * as userActionCreators from 'modules/userManagement/pages/UserDetails/actions';

const defaultState = null;

export default handleActions(
  {
    [combineActions(setDestinationHub)](state, action) {
      return action.payload;
    },
    [combineActions(signinSuccess, userActionCreators.signinAsUserSuccess)](
      state,
      {
        response: {
          data: { user },
        },
      },
    ) {
      return get(user, 'migration.destination', defaultState);
    },
    [combineActions(appleSigninSuccess, userActionCreators.signinAsUserSuccess)](
      state,
      {
        response: {
          data: { user },
        },
      },
    ) {
      return get(user, 'migration.destination', defaultState);
    },
    [combineActions(googleSigninSuccess, userActionCreators.signinAsUserSuccess)](
      state,
      {
        response: {
          data: { user },
        },
      },
    ) {
      return get(user, 'migration.destination', defaultState);
    },
  },
  defaultState,
);
