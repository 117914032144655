import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import classNames from 'classnames';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';

const TPImage = ({ src, alt = '', className, ...props }) => {
  const classes = useStyles();
  const [errorOccured, setErrorStatus] = useState(false);

  const handleError = useCallback(
    ({ currentTarget }) => {
      currentTarget.onerror = null;
      setErrorStatus(true);
    },
    [setErrorStatus],
  );

  return !errorOccured ? (
    <img
      src={src}
      alt={alt}
      className={classNames(classes.image, className)}
      onError={handleError}
      {...props}
    />
  ) : (
    <div className={classNames(classes.image, classes.placeholder, className)}>
      <ImageOutlinedIcon fontSize="inherit" classes={{ root: classes.placeholderIcon }} />
    </div>
  );
};

TPImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export default TPImage;
