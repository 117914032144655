import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    layout: {
      margin: theme.spacing(5),
    },
  }),
  { name: 'UIComponents' },
);

export default useStyles;
