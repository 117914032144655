import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  button: {
    height: '34px',
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: '900',
    lineHeight: '15px',
    padding: '0 13px',
    whiteSpace: 'nowrap',
  },
  item: {
    padding: '26px 10px',
    textAlign: 'center',
    minWidth: '104px',
    width: 'fit-content',
    maxWidth: '284px',
    backgroundColor: theme.tableAmountField.backgroundColor,
  },
}));
