import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  label: {
    color: theme.tableAmountField.labelColor,
    fontFamily: 'Lato, sans-serif',
    fontSize: '12px',
    lineHeight: '15px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  amountText: {
    color: theme.tableAmountField.color,
    fontFamily: 'Lato, sans-serif',
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '22px',
    marginTop: '2px',
    whiteSpace: 'nowrap',
  },
  amountContainer: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    minWidth: '88px',
    maxWidth: '104px',
    '@media (min-width:640px)': {
      minWidth: '88px',
      maxWidth: '200px',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(0),
    },
    '@media (min-width:768px)': {
      minWidth: '88px',
      maxWidth: '250px',
    },
    '@media (min-width:1024px)': {
      minWidth: '104px',
      maxWidth: '307px',
      paddingLeft: theme.spacing(4),
    },
    paddingTop: theme.spacing(3),
  },
}));
