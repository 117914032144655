import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    rootLabel: {
      display: 'flex',
      cursor: 'pointer',
      ...theme.checkbox.rootLabel,
    },
    label: {
      paddingTop: theme.spacing(0.5),
      overflowWrap: 'break-word',
      wordBreak: 'break-word',
      ...theme.checkbox.label,
    },
    disabled: {
      ...theme.checkbox.disabled,
    },
    error_small: {
      marginLeft: 4,
    },
    error_medium: {
      marginLeft: 8,
    },
    errorIcon: {
      ...theme.checkbox.errorIcon,
    },
    smallSize: {
      ...theme.checkbox.smallSize,
    },
    mediumSize: {
      ...theme.checkbox.mediumSize,
    },
    errorContainer: {
      minHeight: theme.textError.root.minHeight,
    },
  }),
  { name: 'TPCheckbox' },
);
