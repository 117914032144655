import get from 'lodash/get';

export const getDestinationHub = (state) => get(state, 'auth.signIn.destinationHub');

export const getHideMfaToken = (state) => get(state, 'auth.hideMfaToken');

export const getSelectHub = (state) => get(state, 'selectHub');

export const getSelectAvailableHubs = (state) => get(state, 'selectAvailableHubs');

export const getShowCaptcha = (state) => {
  const result = get(state, 'auth.signIn.showCaptcha');
  return result;
};
