import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import TPModal from 'components/TP-UI/TPModal';
import { closeModal } from 'components/TP-UI/TPModal/actions';
import { accountPlanSelector } from 'modules/auth/selectors';
import { Routes } from 'constants/routeConstants';
import { ACCOUNT_PLANS } from 'constants/accountPlans';

import verificationModalNames from '../../constants/verificationModalNames';
import QuizModalContent from '../QuizModalContent';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core';

const SuccessQuizModal = () => {
  const { t } = useTranslation('quiz');
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedPlan = useSelector(accountPlanSelector);
  const { quiz } = useTheme();
  const imageConfig = quiz.successQuizModal.avatar;

  const handleClose = useCallback(() => {
    dispatch(closeModal(verificationModalNames.SUCCESS_QUIZ_MODAL));
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    if (selectedPlan === ACCOUNT_PLANS.PRO) {
      history.push(Routes.UPGRADE_TO_PRO);
      handleClose();
    } else {
      history.push(Routes.MY_ACCOUNTS);
      handleClose();
    }
  }, [handleClose, selectedPlan, history]);

  const buttons = useMemo(
    () => [
      {
        label: t('common:buttons.gotIt'),
        primary: true,
        onClick: handleSubmit,
      },
    ],
    [t, handleSubmit],
  );

  const isProPlan = selectedPlan === ACCOUNT_PLANS.PRO;
  const titleTarget = isProPlan ? ACCOUNT_PLANS.PRO : ACCOUNT_PLANS.RETAIL;
  const title = t(`quiz:${titleTarget}.page.title`);

  return (
    <TPModal
      modalName={verificationModalNames.SUCCESS_QUIZ_MODAL}
      title={title}
      buttons={buttons}
      onClose={handleClose}>
      <QuizModalContent
        imageConfig={imageConfig}
        text={t(`successQuizModal.${selectedPlan || ACCOUNT_PLANS.RETAIL}Description`)}
        title={t('successQuizModal.title')}
      />
    </TPModal>
  );
};

export default SuccessQuizModal;
