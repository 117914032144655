export default (theme) => ({
  root: {},
  title: {
    color: theme.palette.text.main,
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: '300',
    margin: '0',
    border: 'none',
    marginBottom: '25px',
  },
  buttonsSection: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  datePickerCustom: {
    '& .react-datepicker-wrapper': {
      width: '100%',
    },
    '& .react-datepicker__input-container input': {
      width: '100%',
    },
  },
  datePicker: {
    width: '100%',
  },
});
