import React from 'react';
import { compose } from 'redux';
import { Form, Field, reduxForm } from 'redux-form';
import { Typography, Grid, withStyles } from '@material-ui/core';
import DatePicker from 'modules/common/DatePicker';
import Picker from 'modules/common/Picker';

import PrimaryButton from 'modules/common/PrimaryButton';

import styles from './styles';

const MarketingDataExport = ({ classes, handleSubmit, sources, isPending }) => {
  const sourcesValues = sources.map((source) => ({
    value: source,
    label: source,
  }));

  return (
    <Form onSubmit={handleSubmit} className={classes.root}>
      <Typography className={classes.title}>Export data</Typography>

      <Grid item xs={12} className={classes.inputContainer}>
        <Field
          id="dateFrom"
          name="dateFrom"
          component={DatePicker}
          type="text"
          placeholder={'Date From'}
          customTitle={'Date From'}
          classes={{ DatePicker: classes.datePicker, DatePickerCustom: classes.datePickerCustom }}
          customInput
        />
      </Grid>

      <Grid item xs={12} className={classes.inputContainer}>
        <Field
          id="dateTo"
          name="dateTo"
          component={DatePicker}
          type="text"
          placeholder={'Date To'}
          customTitle={'Date To'}
          classes={{ DatePicker: classes.datePicker, DatePickerCustom: classes.datePickerCustom }}
          customInput
        />
      </Grid>

      <Grid item xs={12} className={classes.fieldContainer}>
        <Field
          name="source"
          component={Picker}
          label={'Source'}
          values={sourcesValues}
          placeholder={'Source'}
        />
      </Grid>

      <div className={classes.buttonsSection}>
        <PrimaryButton
          color="primary"
          size="large"
          label={'Export'}
          isPending={isPending}
          type="submit"
        />
      </div>
    </Form>
  );
};

export default compose(reduxForm(), withStyles(styles))(MarketingDataExport);
