import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TPErrorMessage from 'components/TP-UI/TPErrorMessage';
import useStyles from './styles';
import classnames from 'classnames';

export const TPCheckbox = ({
  name,
  value,
  label,
  color = 'primary',
  size = 'medium',
  disabled = false,
  required = false,
  error,
  reservedErrorSpace = true,
  onChange,
  onBlur,
  ...others
}) => {
  const classes = useStyles();
  const isChecked = typeof value === 'boolean' ? value : Boolean(value) && value !== 'false';

  return (
    <>
      <label className={classes.rootLabel}>
        <Checkbox
          name={name}
          value={value}
          disabled={disabled}
          checked={isChecked}
          color={color}
          size={size}
          icon={
            <CheckBoxOutlineBlankIcon
              className={classNames({
                [classes.errorIcon]: error,
              })}
            />
          }
          checkedIcon={<CheckBoxIcon />}
          required={required}
          onChange={onChange}
          onBlur={onBlur}
          {...others}
        />

        <span
          className={classNames({
            [classes.label]: true,
            [classes.disabled]: disabled,
            [classes[`${size}Size`]]: size,
          })}>
          {label}
        </span>
      </label>
      <div className={classnames({ [classes.errorContainer]: reservedErrorSpace })}>
        {error && (
          <TPErrorMessage error={error} size="small" className={classes[`error_${size}`]} />
        )}
      </div>
    </>
  );
};

TPCheckbox.muiName = Checkbox.muiName;

TPCheckbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  color: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['small', 'medium']),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  /**
   * Reserved space to display error in 1 line
   */
  reservedErrorSpace: PropTypes.bool,
  onChange: PropTypes.func,
};

const TPReduxCheckbox = ({ input, meta, ...others }) => {
  const error = meta.submitFailed && meta.error ? meta.error : null;
  const { onChange, onBlur, value } = input;
  const handleBlur = useCallback(() => onBlur(value), [onBlur, value]);
  return (
    <TPCheckbox {...input} error={error} {...others} onChange={onChange} onBlur={handleBlur} />
  );
};

export default TPReduxCheckbox;
