import {
  FUSION_MARKETS_LABEL,
  FUSION_MARKETS_PREFIX,
  FUSION_MARKETS_X_LABEL,
  FUSION_MARKETS_X_PREFIX,
  FUSION_MARKETS_EU_LABEL,
  FUSION_MARKETS_EU_PREFIX,
} from '../../../../../constants';

export const SIGNIN_FORM = 'SIGNIN_FORM';

export const WRONG_FM_MODAL = 'WRONG_FM_MODAL';

export const SEGMENT_ANONYMOUS_ID = 'ajs_anonymous_id';

//TODO: Need to check of usage, seems redundant
export const MIGRATION_ERRORS = ['120', '129'];

export const NOTIFY_MIGRATED_CLIENT_PERIOD_IN_DAYS = 60;

export const HUBS = [
  { value: FUSION_MARKETS_PREFIX, label: FUSION_MARKETS_LABEL },
  { value: FUSION_MARKETS_X_PREFIX, label: FUSION_MARKETS_X_LABEL },
  { value: FUSION_MARKETS_EU_PREFIX, label: FUSION_MARKETS_EU_LABEL },
];

export const SIGN_IN_STATUS = {
  SUCCESS: 'success',
  NEED_MFA: 'needMfa',
  CHANGE_PASSWORD: 'changePassword',
  REQUIRED_MFA: 'requiredMFA',
  REQUIRED_VERIFICATION: 'requiredVerification',
};

export const REQUIRED_VERIFICATION_MODAL = 'REQUIRED_VERIFICATION_MODAL';
