import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from 'modules/auth/components/LayoutNew';
import Page from 'modules/common/Page';
import config from 'config';
import EnableMfa from 'modules/auth/components/MfaPanel/components/EnableMfa';
import TPButton from 'components/TP-UI/TPButton';
import TPGrid from 'components/TP-UI/TPGrid';
import TPTypography from 'components/TP-UI/TPTypography';
import TPLoader from 'components/TP-UI/TPLoader';
import PropTypes from 'prop-types';
import TPAlert from 'components/TP-UI/TPAlert';
import MfaSetupLater from '../MfaSetupLater';
import TPLink from 'components/TP-UI/TPLink';
import { Routes } from 'constants/routeConstants';

const MfaSetup = ({ required, loading, pending, onSubmit, qrCode, secret, error = null }) => {
  const { t } = useTranslation('auth');
  const { CURRENT_HUB_LABEL } = config;

  return (
    <Page docTitle={t('mfaSetup.documentTitle', { company: CURRENT_HUB_LABEL })}>
      <Layout
        title={t('mfaSetup.title')}
        welcomeTitle={t('logInWelcome')}
        welcomeDescription={t('logInWelcomeDescription')}>
        {loading ? (
          <TPLoader />
        ) : (
          <TPGrid container spacing={3} direction="column" alignItems="center">
            {!required ? (
              <TPGrid item>
                <TPAlert variant="info">{t('mfaSetup.captionOptional')}</TPAlert>
              </TPGrid>
            ) : null}
            <TPGrid item>
              <EnableMfa
                qrCode={qrCode}
                secret={secret}
                onSubmit={onSubmit}
                error={error}
                button={
                  <TPGrid item xs={12}>
                    <TPButton fullWidth primary size="large" type="submit" loading={pending}>
                      {t('mfaSetup.button')}
                    </TPButton>
                  </TPGrid>
                }
              />
            </TPGrid>
            {!required ? (
              <TPGrid item>
                <TPTypography variant="body1">
                  <MfaSetupLater />
                </TPTypography>
              </TPGrid>
            ) : (
              <TPGrid item>
                <TPTypography variant="body1">
                  <TPLink to={{ pathname: Routes.SIGNIN, state: { cleanupStore: true } }}>
                    {t('backToLogIn')}
                  </TPLink>
                </TPTypography>
              </TPGrid>
            )}
          </TPGrid>
        )}
      </Layout>
    </Page>
  );
};

MfaSetup.propTypes = {
  required: PropTypes.bool,
  loading: PropTypes.bool,
  pending: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  qrCode: PropTypes.string,
  secret: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default MfaSetup;
