import MenuList from '@material-ui/core/MenuList';
import PropTypes from 'prop-types';
import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getTranslation } from 'utils/compositeTranslationHandler';
import TPNavMenuListAccordion from './components/TPNavMenuListAccordion';
import TPNavMenuListItem from './components/TPNavMenuListItem';
import useStyles from './styles';

const TPNavMenuList = ({ selectedRoute, minimized = false, menuItems, onItemClick }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const selectedAccordionId = useMemo(
    () =>
      menuItems.find((menuItem) =>
        menuItem.items?.some((item) => selectedRoute.includes(item.path)),
      )?.id ?? '',
    [menuItems, selectedRoute],
  );
  const [expandedAccordionId, setExpandedAccordionId] = useState(selectedAccordionId);

  const handleClick = useCallback((id) => {
    setExpandedAccordionId((accordionId) => (accordionId === id ? '' : id));
  }, []);

  return (
    <nav className={classes.nav}>
      <MenuList className={classes.menuList}>
        {menuItems.map(({ id, label, iconComponent, path, items, labelText }) =>
          !minimized && items && items[0] ? (
            <TPNavMenuListAccordion
              id={id}
              key={id}
              label={getTranslation(t, label)}
              expanded={id === expandedAccordionId}
              selected={id === selectedAccordionId}
              selectedRoute={selectedRoute}
              items={items}
              iconComponent={iconComponent}
              onClick={handleClick}
              onNestedItemClick={onItemClick}
            />
          ) : (
            <TPNavMenuListItem
              id={id}
              key={id}
              minimized={minimized}
              labelText={getTranslation(t, labelText)}
              label={getTranslation(t, label)}
              selected={selectedRoute.includes(path) || id === selectedAccordionId}
              iconComponent={iconComponent}
              path={path}
              onClick={onItemClick}
            />
          ),
        )}
      </MenuList>
    </nav>
  );
};

TPNavMenuList.propTypes = {
  selectedRoute: PropTypes.string.isRequired,
  minimized: PropTypes.bool,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      id: PropTypes.string,
      iconComponent: PropTypes.elementType,
      path: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
          id: PropTypes.string,
          iconComponent: PropTypes.elementType,
          path: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  onItemClick: PropTypes.func,
};

export default TPNavMenuList;
