import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      '& > *': {
        marginTop: theme.spacing(1),
      },
      '& > *:first-child': {
        marginTop: 0,
      },
    },
  }),
  { name: 'TPAccordionDetails' },
);
