import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    imageContainer: {
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  }),
  { name: 'Modals' },
);

export default useStyles;
