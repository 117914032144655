import React, { useState } from 'react';
import classNames from 'classnames';
import TPGrid from 'components/TP-UI/TPGrid';
import TPSidebarLogo from 'components/TP-UI/TPSidebarLogo';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';
import WhatshotOutlinedIcon from '@material-ui/icons/WhatshotOutlined';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import SwapCallsOutlinedIcon from '@material-ui/icons/SwapCallsOutlined';
import BeenhereOutlinedIcon from '@material-ui/icons/BeenhereOutlined';
import TPNavMenuList from 'components/TP-UI/TPNavMenuList';
import config from 'config';
import { THEME_NAME } from 'constants/theme';

import useStyles from './styles';

const { LOGO, CURRENT_HUB_LABEL } = config;

const NavMenuLists = () => {
  const linkTo = '/auth/sign-in';
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const toggle = () => setExpanded((prevState) => !prevState);

  const wrapperClassName = classNames(
    {
      [classes.maximized]: expanded,
      [classes.minimized]: !expanded,
    },
    classes.root,
  );

  return (
    <TPGrid id="logoBlock" item container alignItems="center" className={wrapperClassName}>
      <TPSidebarLogo
        alt={CURRENT_HUB_LABEL}
        src={LOGO[THEME_NAME.LIGHT][expanded ? 'origin' : 'short']}
        path={linkTo}
        expanded={expanded}
        aria-controls="logoBlock"
        onExpandClick={toggle}
      />
      <TPNavMenuList
        minimized={!expanded}
        selectedRoute="personal-details"
        menuItems={[
          {
            id: 'dashboard',
            label: 'Dashboard',
            iconComponent: DashboardOutlinedIcon,
          },
          {
            id: 'id_verification',
            label: 'ID Verification',
            iconComponent: VerifiedUserOutlinedIcon,
          },
          {
            id: 'settingsMenu',
            label: 'Settings',
            iconComponent: SettingsOutlinedIcon,
            items: [
              {
                id: 'migration_requests',
                label: 'Migration requests',
                iconComponent: SwapCallsOutlinedIcon,
                path: 'personal-details',
              },
              {
                id: 'security',
                label: 'Logs',
                iconComponent: BeenhereOutlinedIcon,
              },
            ],
          },
          {
            id: 'news',
            label: { key: 'menu:category.news' },
            iconComponent: AssignmentOutlinedIcon,
            items: [
              {
                id: 'news_search',
                label: { key: 'menu:category.discover' },
                iconComponent: TrendingUpOutlinedIcon,
              },
              {
                id: 'market_buzz',
                label: { key: 'menu:category.marketBuzz' },
                iconComponent: WhatshotOutlinedIcon,
              },
              {
                id: 'sentiment',
                label: { key: 'menu:category.sentiment' },
                iconComponent: FaceOutlinedIcon,
              },
              {
                id: 'myFeeds',
                label: { key: 'menu:category.myFeeds' },
                iconComponent: StarBorderIcon,
              },
            ],
          },
        ]}
      />
    </TPGrid>
  );
};

export default NavMenuLists;
