import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PhoneVerificationForm from '../components/PhoneVerificationForm';
import * as geolocationActions from '../../geolocation/actions';
import * as signUpActions from '../pages/SignUp/actions';
import { getAlphabeticalCountries } from '../../geolocation/selectors';
import { getIsCodeRequested, getIsPhoneVerified } from '../pages/SignUp/selectors';
import { createPendingSelector } from '../../api/selectors';

const PhoneVerificationContainer = () => {
  const dispatch = useDispatch();
  const alphabeticalCountries = useSelector(getAlphabeticalCountries);
  const isCodeRequested = useSelector(getIsCodeRequested);
  const isPhoneVerified = useSelector(getIsPhoneVerified);
  const isCodeRequestPending = useSelector(createPendingSelector(signUpActions.verifyPhoneRequest));
  const isCodeConfirmingRequestPending = useSelector(
    createPendingSelector(signUpActions.verifyPhoneCodeRequest),
  );

  useEffect(() => {
    dispatch(geolocationActions.getCountriesAction());
    dispatch(signUpActions.verifyPhoneReset());
  }, [dispatch]);

  const handleVerify = useCallback(
    (data) => {
      dispatch(signUpActions.verifyPhone(data));
    },
    [dispatch],
  );

  const handleConfirm = useCallback(
    (data) => {
      dispatch(signUpActions.verifyPhoneCode(data));
    },
    [dispatch],
  );

  return (
    <PhoneVerificationForm
      alphabeticalCountries={alphabeticalCountries}
      onVerify={handleVerify}
      isCodeRequested={isCodeRequested}
      isCodeRequestPending={isCodeRequestPending}
      onConfirm={handleConfirm}
      isCodeConfirmingRequestPending={isCodeConfirmingRequestPending}
      isPhoneVerified={isPhoneVerified}
    />
  );
};

export default PhoneVerificationContainer;
