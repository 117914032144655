import config from 'config';
import { ACCOUNT_SERVERS } from 'constants/index.js';

import i18n from 'i18n';
import translateLabelsInArray from 'i18n/helpers/translateLabelsInArray';

export const getAccountTypes = (accountTypes) => {
  const { ACCOUNT_TYPES } = config;
  return translateLabelsInArray(accountTypes || ACCOUNT_TYPES);
};

export const getAllowedAccountServers = (userAllowedAccountCreation = true) => {
  const accountServers = i18n.t('common:constants.accountIsLive', {
    returnObjects: true,
  });
  return userAllowedAccountCreation === false
    ? accountServers.filter(({ value }) => value !== ACCOUNT_SERVERS.LIVE)
    : accountServers;
};

export const getSecretQuestions = () =>
  i18n.t('profile:verification.accountSettings.securityQuestions', {
    returnObjects: true,
  });

export const getDocumentTypesThirdStep = () =>
  i18n.t('profile:verification.documentTypesThirdStep', {
    returnObjects: true,
  });

export const getDocumentTypesThirdStepSecondStep = () =>
  i18n.t('profile:verification.documentTypesThirdStepSecondStep', {
    returnObjects: true,
  });

export const getDocumentSides = () =>
  i18n.t('profile:verification.documentSides', {
    returnObjects: true,
  });

export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);
