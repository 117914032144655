import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    popoverPaper: {
      overflowY: 'hidden',
    },
    content: {
      width: '313px',
      padding: theme.spacing(3),
    },
    list: {
      paddingInlineStart: theme.spacing(3),
    },
    listItem: {
      fontSize: theme.typography.body1.fontSize,
    },
    btn: {
      minWidth: 'auto',
    },
    backdrop: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: theme.zIndex.drawer + 1,
      mixBlendMode: 'hard-light',
      overflow: 'hidden',
    },
    spotlight: {
      zIndex: theme.zIndex.drawer + 2,
      position: 'absolute',
      backgroundColor: theme.palette.background.main,
      opacity: 0.4,
      transition: 'opacity 0.2s ease 0s',
      mixBlendMode: 'hard-light',
    },
  }),
  { name: 'Step' },
);
