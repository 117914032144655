import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import TPGrid from 'components/TP-UI/TPGrid';
import TPModal from 'components/TP-UI/TPModal';
import { ACCOUNT_PLANS } from 'constants/accountPlans';
import { selectAccountPlanRequest } from 'modules/accounts/pages/MyAccounts/actions';

import verificationModalNames from '../../constants/verificationModalNames';
import BenefitsSection from './components/BenefitsSection';

const ProOrRetailModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('quiz');

  const handleAccountPlanSelect = (accountPlan) => {
    dispatch(selectAccountPlanRequest(accountPlan));
  };

  return (
    <TPModal
      modalName={verificationModalNames.PRO_OR_RETAIL_MODAL}
      hideButtons
      title={t('proOrRetailModal.title')}>
      <TPGrid container direction="column" spacing={4}>
        <TPGrid item xs={12}>
          <BenefitsSection
            accountPlan={ACCOUNT_PLANS.PRO}
            benefits={t(`proOrRetailModal.pro.benefits`, { returnObjects: true })}
            buttonText={t(`proOrRetailModal.pro.selectAccountPlanButtonText`)}
            onAccountPlanSelect={handleAccountPlanSelect}
            title={t('proOrRetailModal.pro.title')}
          />
        </TPGrid>
        <TPGrid item xs={12}>
          <BenefitsSection
            accountPlan={ACCOUNT_PLANS.RETAIL}
            benefits={t(`proOrRetailModal.retail.benefits`, { returnObjects: true })}
            buttonText={t(`proOrRetailModal.retail.selectAccountPlanButtonText`)}
            onAccountPlanSelect={handleAccountPlanSelect}
            title={t('proOrRetailModal.retail.title')}
          />
        </TPGrid>
      </TPGrid>
    </TPModal>
  );
};

export default ProOrRetailModal;
