import { handleActions } from 'redux-actions';
import get from 'lodash/get';

import { getFeesDiscountFail, getFeesDiscountRequest, getFeesDiscountSuccess } from '../actions';

const defaultState = null;

export default handleActions(
  {
    [getFeesDiscountRequest]: () => '',
    [getFeesDiscountSuccess]: (_state, action) => get(action, 'response.data.feesDiscount', ''),
    [getFeesDiscountFail]: () => '',
  },
  defaultState,
);
