import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      // content container size based on
      // popover with 16px padding
      width: '468px',
      height: '418px',
      maxWidth: '80vw',
      maxHeight: '60vh',
      overflow: 'hidden',
    },
    tabs: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    panelRoot: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      marginTop: theme.spacing(1),
      '&[hidden]': {
        display: 'none',
      },
    },
    panelContent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
    },
    sectionInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'NotificationsContent' },
);
