const styles = () => ({
  stepper: {
    display: 'flex',
    height: '30px',
    alignItems: 'center',
  },
  pages: {
    fontSize: '14px',
    minWidth: '24px',
    height: '24px',
    padding: 2,
    lineHeight: '17px',
    color: '#7777C1',
    '&:hover': {
      color: '#2727C5',
    },
    margin: '2px',
    textAlign: 'center',
    background: 'none',
    border: 'none',
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
  },
  currentPage: {
    backgroundColor: 'white',
    borderRadius: '50px',
    border: '1px solid #DADFEC',
    cursor: 'initial',
    '&:hover': {
      color: '#7777C1',
    },
  },
});

export default styles;
