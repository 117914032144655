import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import ReCaptcha from 'modules/common/ReCaptcha';
import { TPModal } from 'components/TP-UI/TPModal';
import TPSelect from 'components/TP-UI/TPSelect';
import { HUBS, HUB_CODES } from './config';
import TPGrid from 'components/TP-UI/TPGrid';

import useStyles from './styles';

const SelectHubModal = ({
  form,
  visible,
  loading,
  handleSubmit,
  buttonLabel,
  hubs,
  showCaptcha,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const buttons = useMemo(() => [{ label: buttonLabel, primary: true, type: 'submit' }], [
    buttonLabel,
  ]);
  const availableHubs = useMemo(() => {
    return hubs
      .map((hubCode) => HUBS.find((hub) => hub.value === HUB_CODES[hubCode]))
      .filter((hub) => hub);
  }, [hubs]);

  return (
    <TPModal
      value={visible}
      title={t('common:labels.selectHub')}
      hideClose
      buttons={buttons}
      loading={loading}
      form={form}
      onSubmit={handleSubmit}
      className={classes.modalContainer}>
      <TPGrid container spacing={2} direction="column">
        <TPGrid item>
          <Field name="prefix" fullWidth component={TPSelect} label="Hub" options={availableHubs} />
        </TPGrid>
        {showCaptcha && (
          <TPGrid item>
            <ReCaptcha form={form} />
          </TPGrid>
        )}
      </TPGrid>
    </TPModal>
  );
};

SelectHubModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  form: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
};

const SelectHubReduxModal = reduxForm({ destroyOnUnmount: true })(SelectHubModal);

export default SelectHubReduxModal;
