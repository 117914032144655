import jwtDecode from 'jwt-decode';
import { closeModal, openModal } from 'components/TP-UI/TPModal/actions';
import { showNotificationError } from 'modules/notifications/actions';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { reset } from 'redux-form';
import { equalPasswords } from 'utils/validation/formValidationRules';
import GoogleButton from '../GoogleButton';
import classnames from 'classnames';
import { GOOGLE_AUTH_CREATE_PASSWORD_MODAL, GOOGLE_AUTH_CREATE_PASSWORD_FORM } from './constants';
import CreatePasswordModal from './components/CreatePasswordModal';

import useStyles from './styles';

const GoogleSignup = ({ disabled, onSuccess }) => {
  const classes = useStyles();
  const { t } = useTranslation('auth');
  const dispatch = useDispatch();
  const [googleUser, setGoogleUser] = useState(null);

  const handlePasswordSubmit = useCallback(
    ({ password }) => {
      const { email, given_name, family_name, ...restData } = googleUser;

      dispatch(closeModal(GOOGLE_AUTH_CREATE_PASSWORD_MODAL));
      onSuccess({
        googleUser: {
          email: email,
          firstName: given_name,
          lastName: family_name,
          ...restData,
        },
        password,
      });

      dispatch(reset(GOOGLE_AUTH_CREATE_PASSWORD_FORM));
    },
    [dispatch, onSuccess, googleUser],
  );

  const validatePasswordForm = (data) => {
    return equalPasswords(data);
  };

  const onSuccessGoogleResponse = (response) => {
    const userObject = jwtDecode(response.credential);

    setGoogleUser(userObject);
    dispatch(openModal(GOOGLE_AUTH_CREATE_PASSWORD_MODAL));
  };

  const onFailureGoogleResponse = () => {
    const errorMessage = t('signUp.somethingWentWrong');

    dispatch(showNotificationError(errorMessage));
  };

  return (
    <div className={classnames(classes.root, { [classes.disabled]: disabled })}>
      <GoogleButton
        onFailure={onFailureGoogleResponse}
        onSuccess={onSuccessGoogleResponse}
        text="signup_with"
      />
      <CreatePasswordModal
        modalName={GOOGLE_AUTH_CREATE_PASSWORD_MODAL}
        form={GOOGLE_AUTH_CREATE_PASSWORD_FORM}
        onSubmit={handlePasswordSubmit}
        validate={validatePasswordForm}
      />
    </div>
  );
};

export default GoogleSignup;
