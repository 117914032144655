import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    buttons: {
      marginBottom: theme.spacing(4),
    },
    captcha: {
      margin: `0 auto ${theme.spacing(3)}px auto`,
    },
  }),
  { name: 'PhoneVerificationForm' },
);

export default useStyles;
