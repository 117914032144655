import get from 'lodash/get';
import { handleActions } from 'redux-actions';
import * as actions from '../actions';

const defaultState = {
  status: '',
};

export default handleActions(
  {
    [actions.createMigrateRequest](state) {
      return { ...state, status: 'Loading' };
    },
    [actions.createMigrateSuccess](state, action) {
      const status = get(action, 'response.data.partners.users.ok', {});

      return { ...state, status };
    },
    [actions.createMigrateFail](state) {
      return state;
    },
  },
  defaultState,
);
