import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { push } from 'modules/reduxNavigation/actions';
import { FUSION_MARKETS } from 'constants/index';
import * as actionCreators from '../actions';
import { Routes } from 'constants/routeConstants';
import { USER_ROLE } from 'constants/userRoles';
import * as authSelectors from 'modules/auth/selectors';
import * as accountActions from 'modules/accounts/pages/MyAccounts/actions';
import Sidebar from '../components/Sidebar';
import { getUserHub } from 'modules/auth/selectors';
import * as verificationActionCreators from '../../VerificationModalsManager/actions';

class SidebarContainer extends Component {
  constructor(props) {
    super(props);
    this.isUser =
      this.props.userRole !== USER_ROLE.ADMIN && this.props.userRole !== USER_ROLE.MANAGER;
    this.continueVerificationPath =
      this.props.hub === FUSION_MARKETS ? Routes.MY_ACCOUNTS : Routes.PROFILE_VERIFICATION;

    this.state = {
      minimized: localStorage.getItem('sidebar_minimized') === 'true',
    };
  }

  componentDidMount() {
    const {
      userId,
      actions: { getUpdatedUserRequest },
    } = this.props;
    if (userId) {
      getUpdatedUserRequest(userId);
    }
  }

  handleContinueVerification = () => {
    const { startIdVerification } = this.props.verificationActions;

    startIdVerification();
  };

  handleExpandClick = () => {
    localStorage.setItem('sidebar_minimized', !this.state.minimized);
    this.setState({
      minimized: !this.state.minimized,
    });
  };

  render() {
    const {
      verified,
      verificationProgress,
      posted,
      userRole,
      header,
      expandable = true,
      mobile,
      onMinMenuClick,
      onChange,
      newUser,
    } = this.props;

    const props = {
      header,
      mobile,
      userRole,
      verificationProgress,
      verified,
      posted,
      newUser,
      expandable,
      minimized: expandable && this.state.minimized,
      onExpandClick: this.handleExpandClick,
      continueVerificationPath: this.continueVerificationPath,
      onMinMenuClick,
      onChange,
      onContinueVerification: this.handleContinueVerification,
    };

    return <Sidebar {...props} />;
  }
}

const mapStateToProps = (state) => ({
  userId: authSelectors.getUserId(state),
  verified: authSelectors.getUserIsVerified(state),
  verificationProgress: authSelectors.getUserVerificationProgress(state),
  posted: authSelectors.getUserIsPosted(state),
  newUser: authSelectors.getUserIsNew(state),
  userRole: authSelectors.getUserRole(state),
  hub: getUserHub(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionCreators, dispatch),
  push: bindActionCreators(push, dispatch),
  accountActions: bindActionCreators(accountActions, dispatch),
  verificationActions: bindActionCreators(verificationActionCreators, dispatch),
});

SidebarContainer.propTypes = {
  userId: PropTypes.string,
  userRole: PropTypes.string,
  header: PropTypes.node,
  mobile: PropTypes.bool,
  expandable: PropTypes.bool,
  verified: PropTypes.bool,
  verificationProgress: PropTypes.number,
  verificationActions: PropTypes.shape({
    startIdVerification: PropTypes.func.isRequired,
  }),
  posted: PropTypes.bool,
  actions: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  accountActions: PropTypes.object.isRequired,
  hub: PropTypes.string.isRequired,
  onMinMenuClick: PropTypes.func,
  onChange: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContainer);
