import { generatePath, useRouteMatch, useHistory } from 'react-router-dom';

const useSetCurrentPageUrl = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const setCurrentPageUrl = (pageNumber) => {
    history.push({
      pathname: generatePath(match.path, { pageNumber }),
    });
  };

  return setCurrentPageUrl;
};

export default useSetCurrentPageUrl;
