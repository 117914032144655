/**
 *
 * @param {String} dateString - the date in string format that we want to transform to Date object
 * @param {String} format - the format of the given `dateString`
 * @returns {Date | null}
 */
const parseCustomDateString = (dateString, format) => {
  if (!format) return null;
  const separators = /[-./\sT:Z]/;
  const dateParts = dateString.split(separators).filter(Boolean);
  const formatParts = format.split(separators);

  if (dateParts.length !== formatParts.length) return null;

  let day,
    month,
    year,
    hours = 0,
    minutes = 0,
    seconds = 0,
    milliseconds = 0;

  formatParts.forEach((part, index) => {
    const value = parseInt(dateParts[index], 10);
    switch (part) {
      case 'DD':
      case 'D':
        day = value;
        break;
      case 'MM':
      case 'M':
        month = value - 1;
        break;
      case 'YYYY':
        year = value;
        break;
      case 'YY':
        year = value >= 50 ? 1900 + value : 2000 + value;
        break;
      case 'HH':
        hours = value;
        break;
      case 'mm':
        minutes = value;
        break;
      case 'ss':
        seconds = value;
        break;
      case 'SSS':
        milliseconds = value;
        break;
      default:
        break;
    }
  });

  if (!year || month === undefined || !day) return null;

  if (month < 0 || month > 11 || day < 1 || day > 31) return null;

  const result = new Date(year, month, day, hours, minutes, seconds, milliseconds);

  if (result.getMonth() !== month || isNaN(result)) return null;

  return result;
};

const mapStartOf = {
  day: (date) => {
    date = date.setHours(0, 0, 0, 0);
    return date;
  },
  week: (date) => {
    date.setDate(date.getDate() - (date.getDay() || 7) + 1);
    date.setHours(0, 0, 0, 0);
    return date;
  },
  month: (date) => {
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
    return date;
  },
  quarter: (date) => {
    const currentMonthInQuarter = date.getMonth() % 3;
    if (currentMonthInQuarter !== 0) {
      date.setMonth(date.getMonth() - currentMonthInQuarter);
    }
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
    return date;
  },
  year: (date) => {
    date.setMonth(0);
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
    return date;
  },
};

const mapEndOf = {
  day: (date) => {
    date = date.setHours(23, 59, 59, 999);
    return date;
  },
  week: (date) => {
    date.setDate(date.getDate() - (date.getDay() || 7) + 7);
    date.setHours(23, 59, 59, 999);
    return date;
  },
  month: (date) => {
    date.setMonth(date.getMonth() + 1);
    date.setDate(0);
    date.setHours(23, 59, 59, 999);
    return date;
  },
  quarter: (date) => {
    const monthsLeftInQuarter = 3 - (date.getMonth() % 3);
    if (monthsLeftInQuarter !== 0) {
      date.setMonth(date.getMonth() + monthsLeftInQuarter);
    }
    date.setDate(0);
    date.setHours(23, 59, 59, 999);
    return date;
  },
  year: (date) => {
    date.setMonth(11);
    date.setDate(31);
    date.setHours(23, 59, 59, 999);
    return date;
  },
};

const mapDiff = {
  days: (msDifference) => {
    return Math.floor(Math.abs(msDifference) / 1000 / 60 / 60 / 24);
  },
  hours: (msDifference) => {
    return Math.floor(Math.abs(msDifference) / 1000 / 60 / 60);
  },
  minutes: (msDifference) => {
    return Math.floor(Math.abs(msDifference) / 1000 / 60);
  },
  seconds: (msDifference) => {
    return Math.floor(Math.abs(msDifference) / 1000);
  },
  milliseconds: (msDifference) => {
    return Math.abs(msDifference);
  },
};

const pad = (number, length = 2) => String(number).padStart(length, '0');

const getOrdinal = (n) => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

const tokenMap = {
  YYYY: (date) => date.getFullYear(),
  YY: (date) => String(date.getFullYear()).slice(-2),
  MMMM: (date) => date.toLocaleString('default', { month: 'long' }),
  MMM: (date) => date.toLocaleString('default', { month: 'short' }),
  MM: (date) => pad(date.getMonth() + 1),
  M: (date) => date.getMonth() + 1,
  DD: (date) => pad(date.getDate()),
  D: (date) => date.getDate(),
  Do: (date) => getOrdinal(date.getDate()),
  dddd: (date) => date.toLocaleString('default', { weekday: 'long' }),
  ddd: (date) => date.toLocaleString('default', { weekday: 'short' }),
  HH: (date) => pad(date.getHours()),
  H: (date) => date.getHours(),
  hh: (date) => pad(date.getHours() % 12 || 12),
  h: (date) => date.getHours() % 12 || 12,
  mm: (date) => pad(date.getMinutes()),
  m: (date) => date.getMinutes(),
  ss: (date) => pad(date.getSeconds()),
  s: (date) => date.getSeconds(),
  A: (date) => (date.getHours() < 12 ? 'AM' : 'PM'),
  a: (date) => (date.getHours() < 12 ? 'am' : 'pm'),
  z: (date) => getTimezoneOffset(date, false),
  zz: (date) => getTimezoneOffset(date, true),
};

const tokensRegex = /\[.*?\]|YYYY|YY|MMMM|MMM|MM|M|DD|Do|D|dddd|ddd|HH|H|hh|h|mm|m|ss|s|A|a|zz|z/g;

const replaceTokens = (match, _date) => {
  if (match.startsWith('[') && match.endsWith(']')) {
    return match.slice(1, -1);
  }
  return tokenMap[match](_date);
};

const units = {
  year: (_date, amount) => _date.setFullYear(_date.getFullYear() + amount),
  quarter: (_date, amount) => _date.setMonth(_date.getMonth() + amount * 3),
  month: (_date, amount) => _date.setMonth(_date.getMonth() + amount),
  week: (_date, amount) => _date.setDate(_date.getDate() + amount * 7),
  day: (_date, amount) => _date.setDate(_date.getDate() + amount),
  hours: (_date, amount) => _date.setHours(_date.getHours() + amount),
  minutes: (_date, amount) => _date.setMinutes(_date.getMinutes() + amount),
  seconds: (_date, amount) => _date.setSeconds(_date.getSeconds() + amount),
  milliseconds: (_date, amount) => _date.setMilliseconds(_date.getMilliseconds() + amount),
};

const modifyDate = (date, amount = 0, unit) => {
  if (!Object.keys(units).includes(unit)) return date;

  const _date = new Date(date);

  units[unit](_date, amount);
  return _date;
};

const getTimezoneOffset = (date, extended) => {
  const timeZoneOffset = date.getTimezoneOffset() / 60;
  const timezonePrefix = timeZoneOffset <= 0 ? '+' : '-';

  return timeZoneOffset > 10
    ? timezonePrefix + timeZoneOffset + ':00'
    : timezonePrefix + (extended ? '0' : '') + (0 - timeZoneOffset) + ':00';
};

/**
 * @description A helper function that assists in transforming Dates - eventually should replace moment.js for Fusion Markets
 * @param {String | undefined} date - the date in string format to initialize with. If `date` is `undefined`, it will default to current date and time. Supported ***Date object***, ***ISO String*** and ***string but with date format required***.
 * @param {String | undefined} format - the format that the `date` argument follows. If `format` is `undefined` - it is expected that `date` argument is in valid format for Date object.
 * @returns
 */
const fmDate = (date = new Date(), format) => {
  let _date = date instanceof Date && !isNaN(date) ? date : new Date(date);

  if (isNaN(_date)) {
    _date = parseCustomDateString(date, format);
  }

  return {
    /**
     * @returns {Date | null} Date as object in Local Machine Time
     */
    toDate() {
      return _date;
    },
    /**
     * @returns {String | null} Date as ISO string in UTC timezone
     */
    toISOString() {
      return _date instanceof Date ? _date.toISOString() : null;
    },
    /**
     * @desc Modifies the date and time to be at the beginning of given parameter
     * @param {'day' | 'week' | 'month' | 'quarter' | 'year'} unit
     */
    startOf(unit) {
      if (!_date || !Object.keys(mapStartOf).includes(unit)) return this;
      _date = new Date(mapStartOf[unit](_date));
      return this;
    },
    /**
     * @desc Modifies the date and time to be at the end of given parameter
     * @param {'day' | 'week' | 'month' | 'quarter' | 'year'} unit
     */
    endOf(unit) {
      if (!_date || !Object.keys(mapEndOf).includes(unit)) return this;
      _date = new Date(mapEndOf[unit](_date));
      return this;
    },
    /**
     * @desc Adds the specified amount of time to the date.
     * @param {number} amount - The amount of time to add.
     * @param {'year' | 'quarter' | 'month' | 'week' | 'day' | 'hours' | 'minutes' | 'seconds' | 'milliseconds'} unit - The unit of time.
     */
    add(amount, unit) {
      if (_date) {
        _date = new Date(modifyDate(_date, amount, unit));
      }
      return this;
    },
    /**
     * @desc Subtracts the specified amount of time from the date.
     * @param {number} amount - The amount of time to subtract.
     * @param {'year' | 'quarter' | 'month' | 'week' | 'day' | 'hours' | 'minutes' | 'seconds' | 'milliseconds'} unit - The unit of time.
     */
    subtract(amount, unit) {
      if (_date) {
        _date = new Date(modifyDate(_date, -amount, unit));
      }
      return this;
    },
    /**
     * @param {String} formatString - for all formats visit https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
     * @returns {String | null}
     */
    format(formatString) {
      if (!formatString || !_date) return this.toISOString();
      return formatString.replace(tokensRegex, (match) => replaceTokens(match, _date));
    },
    /**
     * @desc Difference between dates.
     * @param {string | undefined } dateTo - The date to compare the difference.
     * @param {'days' | 'hours' | 'minutes' | 'seconds' | 'milliseconds'} unit - The unit of time.
     * @returns {Number | null}
     */
    diff(dateTo = new Date(), unit) {
      const dTo = new Date(dateTo);
      if (!_date || isNaN(dTo) || !Object.keys(mapDiff).includes(unit)) return null;
      let msDifference = dTo - _date;
      return mapDiff[unit](msDifference);
    },
  };
};

export default fmDate;
