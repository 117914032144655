import React from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';

import PrimaryButton from 'modules/common/PrimaryButton';
import CustomLink from 'modules/common/CustomLink';

import useStyles from './styles';

const TableButtonField = ({
  showEmptyField = () => false,
  label,
  route,
  dataLabel,
  tableItem,
  onClick,
  disabled = false,
}) => {
  const buttonLabel = isFunction(label) ? label(tableItem) : label;
  const classes = useStyles();
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onClick(tableItem);
  };

  return (
    <div className={classes.item}>
      {!showEmptyField(tableItem) && (
        <>
          {route && (
            <CustomLink to={`${route}/${tableItem[dataLabel]}`}>
              <PrimaryButton color="secondary" label={buttonLabel} className={classes.button} />
            </CustomLink>
          )}
          {onClick && (
            <PrimaryButton
              color="secondary"
              label={buttonLabel}
              className={classes.button}
              onClick={handleClick}
              disabled={disabled}
            />
          )}
        </>
      )}
    </div>
  );
};

TableButtonField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  dataLabel: PropTypes.string,
  tableItem: PropTypes.object,
  route: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  showEmptyField: PropTypes.func,
};

export default TableButtonField;
