import React from 'react';
import PropTypes from 'prop-types';
import TPTypography from '../../../TPTypography';
import TPImage from '../../../TPImage';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { getFileExtension, getImageUrl } from '../../../helpers/file';

import useStyles from './styles';
import classnames from 'classnames';

const TPSliderItem = ({ url, name, onClick, className }) => {
  const classes = useStyles();
  const extension = getFileExtension(url);
  const imageUrl = getImageUrl(url);
  return (
    <div className={classnames(classes.root, className)}>
      <div className={classes.fileContainer} onClick={onClick}>
        {imageUrl ? (
          <TPImage className={classes.fileImage} src={url} alt={name} />
        ) : (
          <div className={classes.nonImageFileContainer}>
            <DescriptionOutlinedIcon fontSize="large" />
            <TPTypography component="span" variant="body2" bold className={classes.fileExtension}>
              {extension}
            </TPTypography>
          </div>
        )}
      </div>
    </div>
  );
};

TPSliderItem.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
};

export default TPSliderItem;
