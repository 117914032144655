import React, { useMemo } from 'react';
import { useTheme } from '@material-ui/core';
import TPModal from 'components/TP-UI/TPModal';
import { TMD_CHECK_MODAL } from '../../constants';
import TPCardAvatar from 'components/TP-UI/TPCard/components/TPCardAvatar';
import TPTypography from 'components/TP-UI/TPTypography';
import TPGrid from 'components/TP-UI/TPGrid';
import InfoTip from 'modules/common/InfoTip';
import TPProgressBar from 'components/TP-UI/TPProgressBar';
import TPImage from 'components/TP-UI/TPImage';
import PropTypes from 'prop-types';

import useStyles from './styles';

const TMDCheckModal = ({ onSubmit, onRecheck, loading, onRecheckCancel }) => {
  const classes = useStyles();
  const buttons = useMemo(
    () => [
      { label: 'Submit', primary: true, onClick: onSubmit },
      { label: 'Recheck', secondary: true, onClick: onRecheck },
    ],
    [onRecheck, onSubmit],
  );
  const { tmd } = useTheme();
  const { src, ...avatarStyles } = tmd.checkModal.avatar;

  return (
    <TPModal
      loading={loading}
      className={classes.modal}
      modalName={TMD_CHECK_MODAL}
      onClose={onRecheckCancel}
      title="Appropriateness Test"
      titleEndAdornment={
        <InfoTip
          iconSize="medium"
          tooltipContent="Trading Forex/CFDs is highly risky. This questionnaire will help determine your suitability to trade these types of financial instruments."
        />
      }
      buttons={buttons}>
      <TPGrid justifyContent="center" container spacing={2}>
        <TPGrid item xs={12}>
          <TPProgressBar primary thickness={8} progress={100} />
        </TPGrid>
        <TPGrid item>
          <TPCardAvatar size="80px" primary>
            <TPImage src={src} style={avatarStyles} />
          </TPCardAvatar>
        </TPGrid>
        <TPGrid item>
          <TPTypography align="center" variant="h5" gutterBottom>
            Check your answers
          </TPTypography>
          <TPTypography>
            In order to trade with Fusion Markets, you must answer 100% of these questions
            correctly.
          </TPTypography>
          <TPTypography>
            Kindly check your answers. You will{' '}
            <TPTypography bold color="error" component="span">
              not be able to continue
            </TPTypography>{' '}
            if you fail the &quot;Appropriateness&quot; test.
          </TPTypography>
        </TPGrid>
      </TPGrid>
    </TPModal>
  );
};

TMDCheckModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onRecheck: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onRecheckCancel: PropTypes.func.isRequired,
};

export default TMDCheckModal;
