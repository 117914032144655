import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import omit from 'lodash/omit';
import assign from 'lodash/assign';

import { equalPasswords } from 'utils/validation/formValidationRules';
import SignUp from '../components/SignUp';
import { SIGNUP_FORM, SIGNUP_REQUIRE_PHONE_COUNTRIES, MAX_LENGTH_COUNTRY_CODE } from '../constants';
import {
  FOLLOWER_ID_COOKIE_NAME,
  REFERRAL_COOKIE_NAME,
  REFERRAL_COOKIE_HUB,
  UTM_COOKIE_NAME,
  FM_REFERRAL_COOKIE_NAME,
  FOLLOWER_HUB_COOKIE_NAME,
  REFCODE_COOKIE_NAME,
  REFCODE_COOKIE_HUB,
} from 'constants/index';
import { MAP_HUB_BY_PREFIX } from 'constants/index';

import * as actionCreators from '../actions';
import * as apiSelectors from 'modules/api/selectors';
import * as appSelectors from 'selectors';
import * as signupSelectors from '../selectors/index';
import * as notificationsActions from 'modules/notifications/actions';
import { getGclid } from '../helpers/getGclid';
import { SEGMENT_ANONYMOUS_ID } from '../../SignIn/constants';
import { getHub } from 'components/App/selectors';

class SignUpContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.boundAppleSignupSuccess = this.handleAppleSignupSuccess.bind(this);
    this.state = {
      isQuery: new URLSearchParams(window.location.search).get('phoneVerification') === 'true',
    };
  }

  componentDidMount() {
    const { params } = this.props;

    if (params.referral) {
      Cookies.set(FM_REFERRAL_COOKIE_NAME, params.referral, { sameSite: 'none', secure: true });
    }
  }

  getAdditionalData = (params) => {
    const data = {};
    const followerId = Cookies.get(FOLLOWER_ID_COOKIE_NAME) || params.followerId;
    const followerHub = Cookies.get(FOLLOWER_HUB_COOKIE_NAME) || params.followerHub;
    if (followerId) {
      data.followerId = followerId;
    }

    const refcode = Cookies.get(REFCODE_COOKIE_NAME) || params[REFCODE_COOKIE_NAME];
    const refhub = Cookies.get(REFCODE_COOKIE_HUB) || params[REFCODE_COOKIE_HUB];

    if (refcode) {
      data.refcode = refcode;
    }
    if (refhub) {
      data.hub = refhub;
    }

    const referral = params.cxd || Cookies.get(REFERRAL_COOKIE_NAME);
    if (referral) {
      data.cxd = referral;
      data.subID = params.subID;
      const cxdHub = Cookies.get(REFERRAL_COOKIE_HUB);
      if (cxdHub) {
        data.hub = cxdHub;
      }
    }
    if (followerHub) {
      data.hub = followerHub;
    }

    const hub = MAP_HUB_BY_PREFIX[this.props.prefix];
    if (hub && !data.hub) {
      data.hub = hub;
    }

    const utm = Cookies.get(UTM_COOKIE_NAME);
    if (utm) {
      data.utm = JSON.parse(utm);
    }
    const inviteFriendCode = Cookies.get(FM_REFERRAL_COOKIE_NAME) || params.referral;
    if (inviteFriendCode) {
      data.inviteFriendCode = inviteFriendCode;
    }

    const gclid = getGclid();
    if (gclid) {
      data.gclid = gclid;
    }

    return data;
  };

  onSubmit = (data) => {
    const {
      actions: { signupRequest },
      params,
    } = this.props;

    data = assign({}, data, this.getAdditionalData(params));
    data = omit(data, ['confirmPassword', 'conditions', 'country', 'phoneCountry']);
    const segmentAnonymousId = Cookies.get(SEGMENT_ANONYMOUS_ID);

    if (segmentAnonymousId) {
      data.segmentAnonymousId = segmentAnonymousId;
    }

    data?.phoneNumber?.length <= MAX_LENGTH_COUNTRY_CODE && (data = omit(data, ['phoneNumber']));

    signupRequest(data);
  };

  validate = (data) => {
    let results = {};
    const { isPhoneVerified } = this.props;

    if (!data.conditions) {
      results._error = { key: 'common:errors.form.needConfirmYouAreOver18' };
    }

    if ((this.isPhoneVerificationRequired() || this.state.isQuery) && !isPhoneVerified) {
      results._error = { key: 'common:errors.form.needVerifyPhone' };
    }
    results = assign(results, equalPasswords(data));
    return results;
  };

  handleGoogleSignupSuccess = (data) => {
    const {
      actions: { googleAuthRequest },
      params,
    } = this.props;

    data = assign({}, data, this.getAdditionalData(params));
    googleAuthRequest(data);
  };

  handleAppleSignupSuccess(data) {
    const {
      actions: { appleAuthRequest, appleUserExist },
      params,
    } = this.props;

    if (!data.appleUser) {
      appleUserExist();
    } else {
      data = assign({}, data, this.getAdditionalData(params));
      appleAuthRequest(data);
    }
  }

  isPhoneVerificationRequired = () => {
    const { userCountry } = this.props;
    return SIGNUP_REQUIRE_PHONE_COUNTRIES.includes(userCountry);
  };

  render() {
    const { isSignupPending, userCountry } = this.props;
    return (
      <SignUp
        onSubmit={this.onSubmit}
        form={SIGNUP_FORM}
        validate={this.validate}
        isPending={isSignupPending}
        onGoogleSignupSuccess={this.handleGoogleSignupSuccess}
        onAppleSignupSuccess={this.boundAppleSignupSuccess}
        userCountry={userCountry}
        phoneVerificationRequired={this.isPhoneVerificationRequired()}
      />
    );
  }
}

SignUpContainer.propTypes = {
  isSignupPending: PropTypes.bool.isRequired,
  params: PropTypes.object,
  prefix: PropTypes.string,
  actions: PropTypes.shape({
    signupRequest: PropTypes.func.isRequired,
    googleAuthRequest: PropTypes.func.isRequired,
    appleAuthRequest: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    isSignupPending: apiSelectors.createPendingSelector(actionCreators.signupRequest)(state),
    params: appSelectors.getParams(state),
    userCountry: signupSelectors.getUserCountry(state),
    isPhoneVerified: signupSelectors.getIsPhoneVerified(state),
    prefix: getHub(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    notificationActions: bindActionCreators(notificationsActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpContainer);
