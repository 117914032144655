import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import TPGrid from 'components/TP-UI/TPGrid';
import TPImage from 'components/TP-UI/TPImage';
import TPTypography from 'components/TP-UI/TPTypography';

const PagePlaceholder = ({ title, message, image, imageAlt, button, children }) => {
  const classes = useStyles();

  return (
    <TPGrid container direction="column" alignItems="center" spacing={3}>
      <TPGrid item className={classes.imageContainer} xs={12}>
        <TPImage className={classes.image} alt={imageAlt} src={image} />
      </TPGrid>
      <TPGrid item container spacing={2} alignItems="center" xs={12}>
        {title ? (
          <TPGrid item xs={12}>
            <TPTypography align="center" variant="h5" className={classes.contentContainer}>
              {title}
            </TPTypography>
          </TPGrid>
        ) : null}
        {message ? (
          <TPGrid item xs={12}>
            <TPTypography align="center" variant="body1" className={classes.contentContainer}>
              {message}
            </TPTypography>
          </TPGrid>
        ) : null}
        {children}
      </TPGrid>
      {button ? (
        <TPGrid item xs={12}>
          {button}
        </TPGrid>
      ) : null}
    </TPGrid>
  );
};

PagePlaceholder.propTypes = {
  title: PropTypes.string,
  message: PropTypes.node,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  button: PropTypes.node,
};

export default PagePlaceholder;
