import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      maxWidth: 1280,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
    image: {
      [theme.breakpoints.down('xs')]: {
        maxHeight: '40vh',
      },
    },
    inputIcon: {
      color: theme.palette.primary.light,
      fontSize: 48,
    },
    textContainer: {
      padding: theme.spacing(2),
      textAlign: 'center',
    },
  }),
  { name: 'TPTakePhoto' },
);
