import config from 'config';
import { AUSTRALIA } from 'constants/index';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import { createSelector } from 'reselect';
import { LOCATION_FIELDS } from '../constants';
import { VerificationStatuses as Status } from '../../../constants/verificationStatuses';
import { USER_DETAILS_BLOCKED_STATUS } from 'modules/userManagement/pages/UserDetails/constants';

export const getUser = (state) => state.user || {};

export const getUserProQuizProgress = createSelector(getUser, (user) => user.proQuizProgress);
export const getUserRetailQuizProgress = createSelector(getUser, (user) => user.retailQuizProgress);

export const getUserProfile = createSelector(getUser, (user) => user.profile || {});

export const getUserUtm = createSelector(getUser, (user) => user.utm || {});

export const getUserId = createSelector(getUser, (user) => user._id);

export const getUserPayments = createSelector(getUser, (user) => user.payments || {});

export const getUserAccountSettings = createSelector(getUser, (user) => user.accountSettings || {});

export const getUserIdentity = createSelector(getUser, (user) => {
  const identity = user.identity || {};
  (identity.photoId || []).forEach((file, i) => {
    file.documentSide = get(user, `identity.photoIdSide.${i}`, i ? 'BACK_SIDE' : 'FRONT_SIDE');
  });
  (identity.additionalDoc || []).forEach((file, i) => {
    file.documentType = get(user, `identity.additionalDocType.${i}`, '');
  });
  return identity;
});

export const getUserVerification = createSelector(getUser, (user) => user.verification || {});

export const getCanAdminApproveUser = createSelector(
  getUserVerification,
  (verification) =>
    verification.status === Status.REJECTED ||
    verification.status === Status.EDITING.LEAD2 ||
    verification.status === Status.EDITING.LEAD3 ||
    verification.status === Status.EDITING.LEAD4,
);

export const getIsUserLocked = createSelector(getUser, (user) =>
  get(user, 'lockParam.locked', false),
);

export const getKYCHistory = createSelector(getUserVerification, (verification) => {
  const history = get(verification, 'kycStatus.history') || [];

  history.map((item) => {
    item.updatedAt = new Date(item.updatedAt);
    return item;
  });

  for (let i = 1; i < history.length; i++) {
    const prev = history[i - 1];

    prev.completedAt = history[i].updatedAt;
  }

  return history;
});

export const getIsTradingDisabled = createSelector(getUser, (user) =>
  get(user, 'tradingStatus.disabled', false),
);

export const getIsMfaEnabled = createSelector(getUser, (user) => get(user, 'mfa.enabled', false));

export const getUserVerificationProfile = (state) =>
  get(state, 'profile.verification.verificationUser.profile');

export const getUserStatus = createSelector(
  getUserVerification,
  (verification) => verification.status || '',
);

export const getDisplayedUserStatus = createSelector(
  getUserStatus,
  getIsUserLocked,
  (status, isLocked) => (isLocked ? USER_DETAILS_BLOCKED_STATUS : status),
);

export const getUserMigration = createSelector(getUser, (user) => user.migration || {});

export const getIsUserMigrated = createSelector(
  getUserMigration,
  (migration) => !!migration.migrated,
);

export const getUserMigrationStatus = createSelector(
  getUserMigration,
  (migration) => migration.status || '',
);

export const getUserMigrationDestinationLabel = createSelector(getUserMigration, (migration) =>
  config[migration.destination] ? config[migration.destination].LABEL : '',
);

export const getUserFields = createSelector(getUser, getUserProfile, (user, profile) => ({
  _id: user._id,
  email: user.email,
  userLeadManager: user.userLeadManager || undefined,
  firstName: profile.firstName,
  lastName: profile.lastName,
  phoneNumber: profile.phoneNumber,
  dateOfRegistration: user.createdAt,
  managerComments: user.managerComments || [],
  logCallComments: user.logCallComments || [],
  callStatus: user.callStatus,
  riskFlag: user.userRiskFlag,
  managerFollowUp: user.managerFollowUp,
  lastLoginTime: user.lastLoginTime,
  addedByCellxpertId: user.addedByCellxpertId,
  isPro: user.isPro,
  proSign: user.proSign,
  proSignStamp: user.proSignStamp,
  allowCopyAccount: user.allowCopyAccount,
  isIBClient: user.isIBClient,
  authorisedPersons: user.authorisedPersons || [],
  wholesale: user.wholesale,
  allowUpgrateToPro: user.allowUpgrateToPro,
  isLeverageRestricted: user.isLeverageRestricted,
  allowAccountCreation: user.allowAccountCreation,
  allowUnlimitedAccounts: user.allowUnlimitedAccounts,
  allowUnlimitedDemoAccounts: user.allowUnlimitedDemoAccounts,
  manualAccountApprove: user.manualAccountApprove,
  isTestUser: user.isTestUser,
  lastLoginIPs: user.lastLoginIPs || [],
  clientRiskClassification: user.verification?.clientRiskClassification || null,
  tradingStatementEvidence: user.tradingStatementEvidence || {},
  kycStatus: user.verification?.kycStatus || null,
  verificationChecklist: user.verification?.verificationChecklist || null,
  ECPUser: user.ECPUser,
  profile: user.profile,
  isPrivateRelayEmail: user.isPrivateRelayEmail || null,
  additionalEmail: user.additionalEmail || null,
  swapFreeUser: user.swapFreeUser || null,
  isPartner: user.isPartner || false,
  allowTransactionalEmails: user.braze?.allowTransactionalEmails || false,
}));

export const getUserLogComments = createSelector(getUser, (user) => {
  return user.logCallComments || [];
});

export const getCustomFieldsAnswers = createSelector(getUser, (user) => {
  if (user.customFieldsAnswers) {
    return user.customFieldsAnswers.map((answer) => {
      return {
        question: answer.questionId,
        userAnswers: answer.userAnswers,
      };
    });
  }

  return [];
});

export const getUserSumsub = createSelector(getUser, (user) => {
  return get(user, 'verification.sumsub', {});
});

export const getUserFullName = createSelector(getUserProfile, (user) => {
  return user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : undefined;
});

export const getUserCxd = createSelector(getUser, (user) => {
  return user.cxd;
});

export const getUserRefCode = createSelector(getUser, (user) => {
  return user.refcode;
});

export const getUserLocation = createSelector(getUserProfile, (profile) => {
  let location = '';
  location = LOCATION_FIELDS.reduce((previousField, currentField, index) => {
    const currentValue = index === 0 ? profile[currentField] : `, ${profile[currentField]}`;
    let result = profile[currentField] ? previousField + currentValue : previousField;
    if (index !== 0 && !previousField) {
      result = profile[currentField];
    }
    return result;
  }, location);
  return location;
});

export const isAustralian = createSelector(getUserProfile, (profile) => {
  return isEqual(profile.country, AUSTRALIA);
});

export const getQuestions = (state) => state.customFields.questions;

export const getCombineQuestions = createSelector(
  getCustomFieldsAnswers,
  getQuestions,
  (answers, questions) => {
    if (questions && answers)
      return questions.map((question) => {
        const answer = answers
          .filter((a) => a.question === question._id)
          .map((a) => {
            return {
              customResponse: a.userAnswers.customResponse,
              answers: question.allowMultipleAnswers
                ? question.answers.map((q) => a.userAnswers.answers.includes(q.value) && q.value)
                : a.userAnswers.answers,
            };
          });

        return {
          ...question,
          userAnswers:
            answer.length > 0
              ? {
                  customResponse: answer[0].customResponse,
                  answers: answer[0].answers,
                }
              : null,
        };
      });
  },
);
