import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    placeholder: { ...theme.formControl.placeholder },
    valueContainer: {
      display: 'inline-flex',
      alignItems: 'center',
      width: '100%',
      height: 'inherit',
    },
    value: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      flex: 1,
    },
  }),
  { name: 'TPSelectSelectedValue' },
);
