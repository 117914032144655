import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    form: {
      ...theme.form.root,
    },
    linkContainer: {
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'SignInForm' },
);

export default useStyles;
