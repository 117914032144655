import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      display: 'inline-block',
      borderColor: theme.formControl.root.borderColor,
      borderWidth: theme.formControl.root.borderWidth,
      borderRadius: theme.formControl.root.borderRadius,
      borderStyle: 'solid',
      padding: theme.spacing(2),
      height: (props) => props.rows * 1.2 + 'em',
      overflowY: 'scroll',
    },
    fullWidth: {
      width: '100%',
    },
  }),
  { name: 'TPDocument' },
);
