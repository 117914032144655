import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    error_small: {
      marginLeft: 4,
    },
    error_medium: {
      marginLeft: 8,
    },
    errorIcon: {
      ...theme.checkbox.errorIcon,
    },
    errorContainer: {
      minHeight: theme.textError.root.minHeight,
    },
  }),
  { name: 'TPRadioGroup' },
);
