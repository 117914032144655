import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import { FLAG_IMG_BASE_URL } from './config';
import classnames from 'classnames';

import useStyles from './styles';

const TPFlagIcon = ({ label, countryCode, className }) => {
  const classes = useStyles();
  const src = countryCode
    ? `${FLAG_IMG_BASE_URL}/${String(countryCode).toLowerCase()}.svg`
    : label?.charAt(0) ?? '';
  return (
    <Avatar
      variant="square"
      className={classnames(classes.flagIcon, className)}
      alt={label}
      src={src}
    />
  );
};

TPFlagIcon.propTypes = {
  label: PropTypes.string,
  countryCode: PropTypes.string, //e.g. BE
};

export default TPFlagIcon;
