import React from 'react';
import Header from '../../components/OverviewDataHeader';
import OverviewData from '../../components/OverviewData';
import { Grid } from '@material-ui/core';
import DashboardGraph from '../../components/DashboardGraph';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const OverviewDataView = ({
  classes,
  headerProps,
  statsCustomizationProps,
  overviewData,
  isOverviewDataLoading,
  isGraphDataLoading,
  isTotalAndGraphDataLoading,
  graphDatasets,
  shouldCompareTo,
  comparisonGraphDatasets,
  isComparisonGraphDataLoading,
  isUserEligibleNewMetrics,
}) => {
  return (
    <div>
      <Header
        {...headerProps}
        statsCustomizationProps={statsCustomizationProps}
        isUserEligibleNewMetrics={isUserEligibleNewMetrics}
      />
      <OverviewData
        data={overviewData}
        isLoading={isOverviewDataLoading}
        isTotalAndGraphDataLoading={isTotalAndGraphDataLoading}
      />
      <Grid container direction="column">
        <Grid item className={classes.graphWrapper}>
          <DashboardGraph
            isLoading={isGraphDataLoading}
            isTotalAndGraphDataLoading={isTotalAndGraphDataLoading}
            datasets={graphDatasets}
          />
        </Grid>
        {shouldCompareTo && (
          <Grid item className={classes.graphWrapper}>
            <DashboardGraph
              isLoading={isComparisonGraphDataLoading}
              datasets={comparisonGraphDatasets}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default withStyles(styles)(OverviewDataView);
