import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    iconFilled: {
      color: theme.palette.success.main,
    },
    iconEmpty: {
      color: theme.palette.text.disabled,
    },
    iconHovered: {
      color: theme.palette.success.main,
    },
  }),
  { name: 'Ratings' },
);

export default useStyles;
