import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import useStyles from './styles';
import classNames from 'classnames';

const TPIconButtonBase = ({
  className,
  color = '',
  size = 'medium',
  disableRipple = false,
  children,
  forwardedRef,
  ...props
}) => {
  const classes = useStyles();
  const iconButtonClassName = classNames(
    {
      [classes[`${size}Size`]]: size,
      [classes[`${color}Color`]]: color,
      [classes.textContent]: typeof children === 'string',
    },
    className,
  );

  return (
    <IconButton
      ref={forwardedRef}
      className={iconButtonClassName}
      disableRipple={!!color || disableRipple}
      {...props}>
      {children}
    </IconButton>
  );
};

TPIconButtonBase.propTypes = {
  color: PropTypes.oneOf(['', 'primary']),
  size: PropTypes.oneOf(['large', 'medium', 'small', 'xsmall']),
  disableRipple: PropTypes.bool,
};

const TPIconButton = React.forwardRef((props, ref) => (
  <TPIconButtonBase {...props} forwardedRef={ref} />
));

TPIconButton.muiName = IconButton.muiName;

export default TPIconButton;
