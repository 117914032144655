import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedRounded from '@material-ui/icons/RadioButtonUncheckedRounded';
import classNames from 'classnames';
import useStyles from './styles';

const TPRadio = ({
  label,
  color = 'primary',
  disabled = false,
  checked,
  size = 'medium',
  ...others
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <label className={classes.rootLabel} disabled={disabled}>
        <Radio
          checked={checked}
          disabled={disabled}
          color={color}
          size={size}
          icon={<RadioButtonUncheckedRounded fontSize={size} />}
          checkedIcon={<RadioButtonChecked fontSize={size} />}
          {...others}
        />

        <span
          className={classNames({
            [classes.label]: true,
            [classes.disabled]: disabled,
          })}>
          {label}
        </span>
      </label>
    </div>
  );
};

TPRadio.muiName = Radio.muiName;

TPRadio.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['small', 'medium']),
  value: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default TPRadio;
