import React from 'react';
import PropTypes from 'prop-types';
import FormLabel from '@material-ui/core/FormLabel';

const TPFormLabel = ({ component = 'label', color = 'primary', children, ...others }) => {
  return (
    <FormLabel component={component} color={color} {...others}>
      {children}
    </FormLabel>
  );
};

TPFormLabel.muiName = FormLabel.muiName;

TPFormLabel.propTypes = {
  component: PropTypes.elementType,
  children: PropTypes.node,
  color: PropTypes.oneOf(['primary']),
};

export default TPFormLabel;
