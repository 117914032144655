import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { change } from 'redux-form';

import * as actionCreators from '../actions';
import PaginationBar from '../components/PaginationBar';
import * as selectors from '../selectors';
import {
  PAGINATION_FORM,
  DEFAULT_PAGE_LIMIT,
  PAGINATION_DEFAULT_PAGE,
  PAGINATION_DEFAULT_SKIP,
} from '../constants';
import countSkip from '../helpers/countSkip';
import useSetCurrentPageUrl from '../hooks/useSetCurrentPageUrl';
import isPageExists from '../helpers/isPageExists';

const PaginationContainer = ({ onPageChange }) => {
  const dispatch = useDispatch();
  const totalNumberAsPages = useSelector(selectors.getTotalNumberAsPages);
  const currentPage = useSelector(selectors.getCurrentPage);
  const routeParams = useParams();
  const urlPageNumber = routeParams.pageNumber;
  const match = useRouteMatch();
  const setCurrentPageUrl = useSetCurrentPageUrl();
  const pagesNumber = useSelector(selectors.getPagesNumber);
  const selectedPageSize = useSelector(selectors.getSelectedPageSize);

  useEffect(() => {
    dispatch(change(PAGINATION_FORM, 'pageNumber', ''));
  }, [dispatch]);

  useEffect(() => {
    const parsedPageNumber = Number.parseInt(urlPageNumber);
    if (
      (!urlPageNumber ||
        (totalNumberAsPages.length > 0 && !isPageExists(parsedPageNumber, pagesNumber))) &&
      currentPage &&
      currentPage !== parsedPageNumber
    ) {
      setCurrentPageUrl(currentPage);
    }
  }, [
    currentPage,
    urlPageNumber,
    dispatch,
    match,
    totalNumberAsPages,
    setCurrentPageUrl,
    pagesNumber,
  ]);

  useEffect(() => {
    const parsedPageNumber = Number.parseInt(urlPageNumber);

    if (
      parsedPageNumber &&
      isPageExists(parsedPageNumber, pagesNumber) &&
      parsedPageNumber !== currentPage
    ) {
      dispatch(actionCreators.setCurrentPage(parsedPageNumber));
      const skip = countSkip(parsedPageNumber, selectedPageSize);
      onPageChange && onPageChange({ skip });
    }
  }, [dispatch, urlPageNumber, onPageChange, currentPage, pagesNumber, selectedPageSize]);

  const onGoToPageSubmit = ({ pageNumber }) => {
    const pageNumberParsed = Number.parseInt(pageNumber);
    changePage(pageNumberParsed);
  };

  const validatePageNumber = ({ pageNumber }) => {
    let error;
    const parsedPageNumber = Number.parseInt(pageNumber);
    if (!parsedPageNumber) {
      error = 'Wrong page number';
    } else if (!isPageExists(parsedPageNumber, pagesNumber)) {
      error = "Page doesn't exist";
    }
    return { _error: error };
  };

  const onPreviousPageClick = () => {
    changePage(currentPage - 1);
  };

  const onNextPageClick = () => {
    changePage(currentPage + 1);
  };

  const changePage = (pageNumber) => {
    setCurrentPageUrl(pageNumber);
  };

  const onPageClick = (event) => {
    const pageNumber = Number.parseInt(event.currentTarget.value);
    changePage(pageNumber);
    dispatch(change(PAGINATION_FORM, 'pageNumber', ''));
  };

  const onPageSizeChange = (pageSize) => {
    onPageChange && onPageChange({ skip: PAGINATION_DEFAULT_SKIP, limit: pageSize });
    changePage(PAGINATION_DEFAULT_PAGE);
    dispatch(change(PAGINATION_FORM, 'pageNumber', ''));
  };

  const props = {
    currentPage,
    onPageChange: onPageClick,
    pages: totalNumberAsPages,
    onPreviousPageClick: onPreviousPageClick,
    onNextPageClick: onNextPageClick,
    form: PAGINATION_FORM,
    onSubmit: onGoToPageSubmit,
    validate: validatePageNumber,
    initialValues: {
      pageSize: DEFAULT_PAGE_LIMIT,
    },
    onPageSizeChange,
  };
  return <PaginationBar {...props} />;
};

PaginationContainer.propTypes = {
  onPageChange: PropTypes.func.isRequired,
};

export default PaginationContainer;
