import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Status from 'modules/common/Status';
import Table from 'modules/common/Table';
import formatFullDate from 'utils/formatFullDate';
import formatLargeText from 'utils/formatLargeText';
import formatAmount from 'utils/formatAmount';

const WithdrawalHistoryTable = ({
  filters = {},
  pagination,
  items,
  loading,
  hidePagination,
  scrollable,
  onChange,
}) => {
  const { t } = useTranslation('common');

  const columns = useMemo(
    () => [
      {
        label: t('labels.account'),
        dataKey: 'account',
        format: formatLargeText,
      },
      {
        label: t('labels.date'),
        dataKey: 'date',
        format: formatFullDate,
      },
      {
        label: t('labels.withdrawalMethod'),
        dataKey: 'type',
      },
      {
        label: t('labels.amount'),
        dataKey: 'amount',
        renderItem: ({ amount, currency }) => formatAmount(amount, currency),
      },
      {
        label: t('labels.status'),
        dataKey: 'status',
        renderItem: (item) => <Status value={item.status || ''} />,
      },
    ],
    [t],
  );

  return (
    <Table
      pagination={pagination}
      items={items}
      loading={loading}
      filters={filters}
      columns={columns}
      itemKey="_id"
      hidePagination={hidePagination}
      scrollable={scrollable}
      onChange={onChange}
    />
  );
};

WithdrawalHistoryTable.propTypes = {
  filters: PropTypes.object,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    pageSize: PropTypes.number,
    total: PropTypes.number,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      account: PropTypes.string,
      date: PropTypes.string,
      type: PropTypes.string,
      status: PropTypes.string,
      amount: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  hidePagination: PropTypes.bool,
  scrollable: PropTypes.bool,
  onChange: PropTypes.func,
};

export default WithdrawalHistoryTable;
