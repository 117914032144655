import api from 'modules/api';
import getFormData from './getFormData';

export const sendTradingViewSubscriptionRequest = (payload) => {
  const formData = getFormData(payload);
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  return api.post(`/trading-view-subscription/`, formData, headers);
};

export const getTradingViewSubscriptionRequest = (userId) => {
  return api.get(`/trading-view-subscription/`, { params: { userId } });
};

export const getTvSubscriptionsRequest = (params) => {
  return api.get('/trading-view-subscription/list', { params });
};

export const getTvSubscriptionSignedDocsRequest = (subId) => {
  return api.get('/trading-view-subscription/signed-documents', { params: { subId } });
};

export const getTvSubscriptionsLotsRequest = (subscriptionIds) => {
  return api.get('/trading-view-subscription/lots', { params: { subscriptionIds } });
};

export const getTvSubscriptionHistoryRequest = (subscription) => {
  return api.get('/trading-view-subscription/history', { params: { ...subscription } });
};

export const postTvSubscriptionEditRequest = (payload) => {
  return api.post('/trading-view-subscription/edit', payload);
};

export const postTvSubscriptionCancelRequest = (payload) => {
  return api.post('/trading-view-subscription/cancel', payload);
};
