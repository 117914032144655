export const FOREX_TAB_ID = 'forex';

export const MENU_ITEMS = [
  { id: FOREX_TAB_ID, type: 'forex', forexFilter: 'major', name: 'Forex' },
  { id: 'cryptos', type: 'crypto', forexFilter: 'all', name: 'Cryptos' },
  { id: 'commodities', type: 'commodities', name: 'Commodities' },
  { id: 'index', type: 'index', name: 'Indices' },
  { id: 'stock', type: 'stock', name: 'Stocks' },
];

export const FIELDS_ORDER = ['preference', 'alternative', 'comment', 'supportAndResist'];
export const ANALYST_VIEW_DETAILS_MODAL = 'ANALYST_VIEW_DETAILS_MODAL';
