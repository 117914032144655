import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = {
  analystData: null,
  articles: [],
  articleCountsTimeseries: {},
  entity: {},
  sentimentTimeseries: {},
  sentimentSummary: {},
  topics: [],
  entityId: null,
  sentimentChartData: {},
  dailyPricesChartData: [],
  sentimentChartDataLoading: true,
};

export default handleActions(
  {
    [actionCreators.getDashboardDataSuccess](state, action) {
      return { ...state, ...action.response.data };
    },

    [actionCreators.getDashboardDataFail]() {
      return defaultState;
    },
    [actionCreators.getSentimentTimeseriesSuccess](state, action) {
      return { ...state, ...{ sentimentChartData: action.response.data } };
    },

    [actionCreators.getSentimentTimeseriesFail](state) {
      return { ...state, ...{ sentimentChartData: {} } };
    },

    [actionCreators.getDailyPricesSuccess](state, action) {
      return { ...state, ...{ dailyPricesChartData: action.response.data } };
    },

    [actionCreators.getDailyPricesFail](state) {
      return { ...state, ...{ dailyPricesChartData: [] } };
    },

    [actionCreators.setDailyPrices](state, action) {
      return { ...state, dailyPricesChartData: action.payload };
    },
  },
  defaultState,
);
