import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import omit from 'lodash/omit';

import { MIGRATION_FORM } from '../constants';
import * as actionCreators from '../actions';
import * as apiSelectors from 'modules/api/selectors';
import * as appSelectors from 'selectors';
import Migration from '../components/Migration';
import { getUserName } from 'modules/auth/selectors';
import config from 'config';

class MigrationContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
    };
  }
  onSubmit = (data) => {
    const {
      actions: { userMigrationRequest },
      token,
    } = this.props;
    data = omit(data, ['conditions']);
    userMigrationRequest({ ...data, migrationToken: token });
  };

  render() {
    const { isMigrationPending, tokenHub } = this.props;
    const { userName } = this.state;

    return (
      <>
        <Migration
          onSubmit={this.onSubmit}
          form={MIGRATION_FORM}
          isPending={isMigrationPending}
          hub={tokenHub}
          userName={userName}
          destinationHub={config.CURRENT_HUB_LABEL}
        />
      </>
    );
  }
}

MigrationContainer.propTypes = {
  isMigrationPending: PropTypes.bool.isRequired,
  token: PropTypes.string,
  actions: PropTypes.shape({
    userMigrationRequest: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    isMigrationPending: apiSelectors.createPendingSelector(actionCreators.userMigrationRequest)(
      state,
    ),
    token: appSelectors.getToken(ownProps),
    tokenHub: appSelectors.getTokenHub(ownProps),
    userName: getUserName(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MigrationContainer);
