export const boldText = () => ({ dataText: { fontWeight: 'bold' } });
export const normalText = () => ({ dataText: { fontWeight: 'normal' } });
export const mobileSmallCell = () => ({
  item: {
    padding: '17px 0px',
    '@media (min-width:640px)': {
      padding: '17px 15px',
    },
    '@media (min-width:1366px)': {
      padding: '22px 10px',
    },
  },
});
export const leftWideCell = () => ({
  item: { paddingLeft: '32px' },
});

export const greyBackground = () => ({
  item: {
    backgroundColor: '#F8F9FB',
  },
});
