import React from 'react';
import PropTypes from 'prop-types';
import TPTypography from 'components/TP-UI/TPTypography';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import TPTooltip from 'components/TP-UI/TPTooltip';

import useStyles from './styles';

const InfoTip = ({
  text,
  tooltipContent,
  minWidth,
  minimized = false,
  className,
  iconSize = 'small',
}) => {
  const classes = useStyles();

  return (
    <TPTooltip
      className={className}
      content={tooltipContent}
      trigger="click"
      disabled={!tooltipContent}
      minWidth={minWidth}>
      <TPTypography
        variant="inherit"
        color="highlight"
        className={minimized ? classes.minimizedInfo : null}>
        <ErrorOutlineIcon fontSize={iconSize} className={minimized ? null : classes.infoIcon} />
        {minimized ? null : text}
      </TPTypography>
    </TPTooltip>
  );
};

InfoTip.propTypes = {
  text: PropTypes.string,
  tooltipContent: PropTypes.node,
  minWidth: PropTypes.string,
  iconSize: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default InfoTip;
