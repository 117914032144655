import { DEFAULT_LANG, LANG_COOKIE_NAME } from '../constants';
import i18n from '../i18n';
import Cookies from 'js-cookie';
import { getCookieDomain } from './getCookieName';

const changeLanguage = (lang = DEFAULT_LANG) => {
  i18n.changeLanguage(lang);
  Cookies.set(LANG_COOKIE_NAME, lang, {
    path: '/',
    sameSite: 'none',
    secure: true,
    domain: getCookieDomain(),
  });
};

export default changeLanguage;
