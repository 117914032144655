import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = {
  account: {},
  affiliate: {},
  amount: 0,
};

export default handleActions(
  {
    [actionCreators.openTransferModal](state, action) {
      return { ...defaultState, account: action.payload };
    },
    [actionCreators.closeTransferModal]() {
      return { ...defaultState };
    },

    [actionCreators.getAffiliateDataFail]() {
      return { ...defaultState };
    },

    [actionCreators.getAffiliateDataSuccess](state, action) {
      return { ...state, affiliate: action.response.data };
    },
    [actionCreators.createCellxpertTransferRequest]: (state) => {
      return { ...state };
    },
    [actionCreators.createCellxpertTransferSuccess]() {
      return { ...defaultState };
    },
    [actionCreators.createCellxpertTransferFail](state) {
      return { ...state };
    },
  },
  defaultState,
);
