import financialGuideDoc from 'static/documents/FM__ASIC__FSG_August_2024.pdf';
import pdsDoc from 'static/documents/FM_PDS_September_2023-ASIC.pdf';
import termsDoc from 'static/documents/FM_ASIC_Terms_Conditions_January_2025.pdf';
import reduceFeesTermsDoc from 'static/documents/Refer a Friend - T&Cs.pdf';
import privacyDoc from 'static/documents/FM_Privacy_Policy_September_2023-ASIC.pdf';
import TMDDoc from 'static/documents/FM_ASIC_TMD_September_2023.pdf';
import TradingViewPromotionDoc from 'static/documents/FM__ASIC__TradingView_Promotion_May_2024.pdf';

import termsXDoc from 'static/documents/Financial_Product_Terms_-_VFSC.pdf';
import seyTermsDoc from 'static/documents/Complaint_Handling_Policy.pdf';
import seyPrivacyDoc from 'static/documents/Privacy_Policy_sey.pdf';
import pdsXDoc from 'static/documents/Product Disclosure Statement - VFSC.pdf';
import privacyXDoc from 'static/documents/Privacy_Policy - VFSC.pdf';
import TradingViewPromotionXDoc from 'static/documents/FM__VSFC__TradingView_Promotion_December_2024.pdf';

import cryptoDay1TermsDoc from 'static/documents/Terms_and_Conditions_Crypto.pdf';

import {
  FUSION_MARKETS_X,
  FUSION_MARKETS_EU,
  FUSION_MARKETS_EU_PREFIX,
  FUSION_MARKETS_X_PREFIX,
  FUSION_MARKETS,
  FUSION_MARKETS_PREFIX,
} from './index';

export const DOCUMENTS = {
  pds: pdsDoc,
  terms: termsDoc,
  reduceFeesTerms: reduceFeesTermsDoc,
  financialGuide: financialGuideDoc,
  privacy: privacyDoc,
  sey_terms: seyTermsDoc,
  sey_privacy: seyPrivacyDoc,
  tmd: TMDDoc,
  tradingViewPromotion: TradingViewPromotionDoc,
  cryptoDay1Terms: cryptoDay1TermsDoc,
};

export const DOCUMENTS_X = {
  pds: pdsXDoc,
  terms: termsXDoc,
  privacy: privacyXDoc,
  reduceFeesTerms: reduceFeesTermsDoc,
  financialGuide: financialGuideDoc,
  sey_terms: seyTermsDoc,
  sey_privacy: seyPrivacyDoc,
  tradingViewPromotion: TradingViewPromotionXDoc,
  cryptoDay1Terms: cryptoDay1TermsDoc,
};

export const DOCUMENTS_EU = {};

export const HUB_TO_DOCS = {
  [FUSION_MARKETS]: DOCUMENTS,
  [FUSION_MARKETS_X]: DOCUMENTS_X,
  [FUSION_MARKETS_EU]: DOCUMENTS_EU,
  [FUSION_MARKETS_PREFIX]: DOCUMENTS,
  [FUSION_MARKETS_X_PREFIX]: DOCUMENTS_X,
  [FUSION_MARKETS_EU_PREFIX]: DOCUMENTS_EU,
};
