import { createAction } from 'redux-actions';

export const getRefCodesRequest = createAction('GET_REF_CODES_REQUEST');
export const getRefCodesSuccess = createAction('GET_REF_CODES_SUCCESS');
export const getRefCodesFail = createAction('GET_REF_CODES_FAIL');

export const createRefCodeRequest = createAction('CREATE_REF_CODE_REQUEST');
export const createRefCodeSuccess = createAction('CREATE_REF_CODE_SUCCESS');
export const createRefCodeFail = createAction('CREATE_REF_CODE_FAIL');

export const deleteRefCodeRequest = createAction('DELETE_REF_CODE_REQUEST');
export const deleteRefCodeSuccess = createAction('DELETE_REF_CODE_SUCCESS');
export const deleteRefCodeFail = createAction('DELETE_REF_CODE_FAIL');

export const openAddValidationRefCodeModal = createAction('OPEN_ADD_VALIDATION_REF_CODE_MODAL');
export const closeAddValidationRefCodeModal = createAction('CLOSE_ADD_VALIDATION_REF_CODE_MODAL');
