import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import Modal from '@material-ui/core/Modal';
import { Typography } from '@material-ui/core';
import PrimaryButton from 'modules/common/PrimaryButton';

const CustomModal = ({
  classes,
  isModalOpen,
  onClose,
  children,
  className,
  text,
  closeModal,
  customCloseModal,
  isCloseButtonNeeded,
  title,
}) => {
  const { t } = useTranslation('common');
  return (
    <Modal open={isModalOpen} onClose={onClose} className={classes.modal} disableEnforceFocus>
      <div className={classNames(classes.paper, className)}>
        {isCloseButtonNeeded && (
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={customCloseModal || closeModal}
            color="primary">
            <CloseIcon />
          </IconButton>
        )}
        {!text ? (
          children
        ) : (
          <div className={classes.container}>
            <div className={classes.infoContainer}>
              {title && (
                <div className={classes.titleContainer}>
                  <Typography className={classes.title}>{title}</Typography>
                </div>
              )}
              <div>
                <Typography className={classes.infoText}>{text}</Typography>
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <PrimaryButton
                onClick={closeModal}
                className={classNames({
                  [classes.submitButton]: true,
                })}
                label={t('buttons.gotIt')}
                color="primary"
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

CustomModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  isCloseButtonNeeded: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
  closeModal: PropTypes.func,
  customCloseModal: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string,
};

CustomModal.defaultProps = {
  isCloseButtonNeeded: false,
};

export default withStyles(styles)(CustomModal);
