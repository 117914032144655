import { handleActions } from 'redux-actions';
import * as actionCreators from '../actions';

const defaultState = [];

export default handleActions(
  {
    [actionCreators.uploadArchivingSuccess](state, action) {
      return { ...state, updateAccounts: action.response.data.updateAccounts };
    },
  },
  defaultState,
);
