import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    section: {
      marginBottom: theme.spacing(3),
    },
    header: {
      margin: theme.spacing(1),
      ...theme.typography.h6,
    },
    ol: {
      margin: 0,
      paddingLeft: theme.spacing(2),
      ...theme.typography.body1,

      '& > li': {
        marginLeft: theme.spacing(1),
      },
    },
    dot: {
      listStyleType: 'disc',
    },
  }),
  { name: 'JoinPlusTerms' },
);

export default useStyles;
