import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    circleWrapper: {
      position: 'relative',
      ...theme.progressBar.circle.root,
    },
    circleTrack: {
      ...theme.progressBar.circle.track,
    },
    circleBar: {
      position: 'absolute',
      left: 0,
    },
    circleBarSecondary: {
      ...theme.progressBar.circle.bar.secondary,
    },
    circleLabel: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    lineTrack: {
      width: '100%',
      ...theme.progressBar.line.root,
      ...theme.progressBar.line.track,
    },
    lineBar: {
      ...theme.progressBar.line.root,
    },
    lineBarSecondary: {
      ...theme.progressBar.line.bar.secondary,
    },
    lineLabel: {
      marginTop: theme.spacing(1),
    },
    lineBarError: {
      ...theme.progressBar.line.bar.error,
    },
    lineBarWarning: {
      ...theme.progressBar.line.bar.warning,
    },
    lineBarSuccess: {
      ...theme.progressBar.line.bar.success,
    },
  }),
  { name: 'TPProgressBar' },
);

export default useStyles;
