import api from 'modules/api/index';

export const getVerificationChecklistQuestionsRequest = ({ type }) => {
  return api.get(`/verification-checklist?type=${type}`);
};

export const setUserVerificationChecklistAnswersRequest = (payload) => {
  return api.post('/verification-checklist/user', payload);
};

export const setAccountVerificationChecklistAnswersRequest = (payload) => {
  const { ...rest } = payload;
  return api.post('/verification-checklist/account', rest);
};
