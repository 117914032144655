import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Field, FieldArray, formValues, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import TPGrid from 'components/TP-UI/TPGrid';
import TPTypography from 'components/TP-UI/TPTypography';
import TPFileUploader from 'components/TP-UI/TPFileUploader';
import TPFileList from 'components/TP-UI/TPFileList';
import { UPLOADER_FILE_TYPE } from 'components/TP-UI/TPFileUploader/constants';
import {
  allowedFilesTypes,
  maxFiles,
  maxFilesSize,
  required,
} from 'utils/validation/fieldValidationRules';

import TPFileItem from 'components/TP-UI/TPFileList/components/TPFileItem';
import { getDocumentSides } from 'helpers';
import TPSelect from 'components/TP-UI/TPSelect';
import TPButton from 'components/TP-UI/TPButton';
import TPTextField from 'components/TP-UI/TPTextField';
import useTakePhoto from 'components/TP-UI/TPFileUploader/hooks/useTakePhoto';
import { WEBCAM_STATE } from 'components/TP-UI/TPFileUploader/components/TPTakePhoto/constants';
import TPTakePhoto from 'components/TP-UI/TPFileUploader/components/TPTakePhoto';
import TPCardHeader from 'components/TP-UI/TPCard/components/TPCardHeader';
import TPCardTitle from 'components/TP-UI/TPCard/components/TPCardTitle';
import TPCardContent from 'components/TP-UI/TPCard/components/TPCardContent';
import TPCardActions from 'components/TP-UI/TPCard/components/TPCardActions';
import TPCard from 'components/TP-UI/TPCard';
import {
  MAX_LARGE_IMAGE_FILE_SIZE,
  MAX_LARGE_TEXT_FILE_SIZE,
  MAX_PHOTO_ID_FILES_NUMBER,
} from '../../../constants';
import { isMobile } from 'react-device-detect';

import useStyles from './styles';

const TakePhotoBox = ({
  webcamStatusMessages,
  captureButtonLabel = 'Capture',
  recaptureButtonLabel = 'Re-capture',
}) => {
  const inputRef = useRef();
  const playerRef = useRef();
  const mode = isMobile ? 'input' : 'video';
  const {
    cameraAllowed,
    imageDataURL,
    canTakePhoto,
    webcamState,
    initializeMedia,
    stopTracking,
    capture,
    recapture,
  } = useTakePhoto({ mode });

  const inputProps = useMemo(
    () => ({
      inputRef,
      name: 'proof',
      onChange: () => capture(inputRef),
    }),
    [capture],
  );

  const handleCaptureClick = useCallback(() => {
    capture(playerRef);
  }, [capture]);

  const handleRecapture = useCallback(() => {
    recapture(playerRef);
  }, [recapture]);

  const buttons = useMemo(() => {
    return [
      ...(canTakePhoto
        ? [
            {
              label: !imageDataURL ? captureButtonLabel : recaptureButtonLabel,
              primary: true,
              disabled: mode === 'video' && webcamState === WEBCAM_STATE.WAIT,
              onClick: !imageDataURL ? handleCaptureClick : handleRecapture,
            },
          ]
        : []),
    ];
  }, [
    captureButtonLabel,
    recaptureButtonLabel,
    handleCaptureClick,
    handleRecapture,
    webcamState,
    canTakePhoto,
    imageDataURL,
    mode,
  ]);

  useEffect(() => {
    initializeMedia(playerRef);
    return () => {
      stopTracking(playerRef);
    };
  }, [stopTracking, initializeMedia]);

  return (
    <TPCard fullWidth>
      <TPCardHeader>
        <TPCardTitle>Take a Photo</TPCardTitle>
      </TPCardHeader>
      <TPCardContent>
        <TPTakePhoto
          mode={mode}
          inputProps={inputProps}
          playerRef={playerRef}
          imageDataURL={imageDataURL}
          cameraAllowed={cameraAllowed}
          webcamState={webcamState}
          webcamStatusMessages={webcamStatusMessages}
        />
        <TPCardActions>
          {buttons.map(({ label, ...button }, index) => (
            <TPButton {...button} key={index}>
              {label}
            </TPButton>
          ))}
        </TPCardActions>
      </TPCardContent>
    </TPCard>
  );
};

const MAX_DOC_FILES_NUMBER = 2;
const MAX_BULK_UPLOAD_FILES_NUMBER = 1;

const FileUploaders = ({ bulkUploadFile, handleSubmit }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const photoIDFileTypes = useMemo(() => [UPLOADER_FILE_TYPE.PNG, UPLOADER_FILE_TYPE.PDF], []);

  //Note: order of validators are important and should start from dropzone validators and only after that common
  const photoIDFilesValidators = useMemo(
    () => [
      allowedFilesTypes(photoIDFileTypes),
      maxFilesSize(MAX_LARGE_IMAGE_FILE_SIZE),
      maxFiles(MAX_PHOTO_ID_FILES_NUMBER),
    ],
    [photoIDFileTypes],
  );
  const renderPhotoIDFile = useCallback(
    (props) => (
      <Field
        name={props.value}
        component={TPFileItem}
        {...props}
        startAdornment={
          <Field
            name={`${props.value}.documentSide`}
            label={t('common:labels.documentSide')}
            type="text"
            fullWidth
            size="small"
            placeholder={t('common:labels.documentSide')}
            component={TPSelect}
            options={getDocumentSides() || []}
            required
            validate={[required]}
          />
        }
      />
    ),
    [t],
  );

  const docFileTypes = useMemo(
    () => [
      UPLOADER_FILE_TYPE.PNG,
      UPLOADER_FILE_TYPE.JPG,
      UPLOADER_FILE_TYPE.JPEG,
      UPLOADER_FILE_TYPE.PDF,
    ],
    [],
  );

  //Note: order of validators are important and should start from dropzone validators and only after that common
  const docFilesValidators = useMemo(
    () => [
      allowedFilesTypes(docFileTypes),
      maxFilesSize(MAX_LARGE_TEXT_FILE_SIZE),
      maxFiles(MAX_DOC_FILES_NUMBER),
    ],
    [docFileTypes],
  );
  const renderDocFile = useCallback(
    (props) => (
      <Field
        name={props.value}
        component={TPFileItem}
        {...props}
        startAdornment={
          <Field
            name={`${props.value}.type`}
            label={t('common:labels.type')}
            type="text"
            fullWidth
            size="small"
            placeholder={t('common:labels.type')}
            component={TPTextField}
            required
            validate={[required]}
          />
        }
      />
    ),
    [t],
  );
  const bulkUploadFileTypes = useMemo(() => [UPLOADER_FILE_TYPE.CSV], []);
  //Note: order of validators are important and should start from dropzone validators and only after that common
  const bulkUploadFileValidators = useMemo(
    () => [
      maxFiles(MAX_BULK_UPLOAD_FILES_NUMBER),
      allowedFilesTypes(bulkUploadFileTypes),
      maxFilesSize(MAX_LARGE_TEXT_FILE_SIZE),
    ],
    [bulkUploadFileTypes],
  );

  const webcamStatusMessages = useMemo(
    () => ({
      denied: t('common:fileUploader.webcamStateMessage.denied'),
      granted: t('common:fileUploader.webcamStateMessage.granted'),
      wait: t('common:fileUploader.webcamStateMessage.wait'),
      noCamera: t('common:fileUploader.webcamStateMessage.noCamera'),
    }),
    [t],
  );
  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit}>
      <TPGrid container spacing={1}>
        <TPGrid item xs={12} md={6}>
          <TPTypography variant="subtitle1">
            Uploader with restrictions: max file size, accepted types, number of files, custom file
            rendering with form component
          </TPTypography>
          <FieldArray
            component={TPFileUploader}
            id="photoIDFiles"
            name="photoIDFiles"
            placeholder="Drag and drop file here"
            uploadFileLabel={t('common:fileUploader.uploadFile')}
            webCamEnabled
            webcamStatusMessages={webcamStatusMessages}
            takePhotoLabel={t('common:fileUploader.webCamUpload')}
            captureButtonLabel={t('common:fileUploader.capture')}
            cancelButtonLabel={t('common:buttons.cancel')}
            okButtonLabel={t('common:buttons.gotIt')}
            maxFiles={MAX_PHOTO_ID_FILES_NUMBER}
            maxSize={MAX_LARGE_IMAGE_FILE_SIZE}
            acceptTypes={photoIDFileTypes}
            validate={photoIDFilesValidators}
            className={classes.control}
          />
          <FieldArray
            component={TPFileList}
            id="photoIDFiles"
            name="photoIDFiles"
            renderItem={renderPhotoIDFile}
            preview
            validate={photoIDFilesValidators}
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={12}>
          <TPButton primary type="submit" size="large">
            Save
          </TPButton>
        </TPGrid>
        <TPGrid item xs={12} md={6}>
          <TPTypography variant="subtitle1">
            Uploader with restrictions: max file size, accepted types, number of files, custom file
            rendering with form component
          </TPTypography>
          <FieldArray
            component={TPFileUploader}
            id="docFiles"
            name="docFiles"
            placeholder={t('common:fileUploader.placeholder')}
            maxFiles={MAX_DOC_FILES_NUMBER}
            maxSize={MAX_LARGE_TEXT_FILE_SIZE}
            maxSizeRestrictionMessage={{ key: 'common:fileUploader.maxSizeRestrictionMessage' }}
            acceptTypes={docFileTypes}
            acceptTypesRestrictionMessage={{
              key: 'common:fileUploader.acceptTypesRestrictionMessage',
            }}
            validate={docFilesValidators}
            className={classes.control}
          />
          <FieldArray
            component={TPFileList}
            id="docFiles"
            name="docFiles"
            renderItem={renderDocFile}
            preview
            validate={docFilesValidators}
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={12}>
          <TPButton primary type="submit" size="large">
            Save
          </TPButton>
        </TPGrid>
      </TPGrid>
      <TPGrid container spacing={1}>
        <TPGrid item xs={8}>
          <TPTypography variant="subtitle1">Use only uploader, 1 csv file</TPTypography>
          <Field
            component={TPFileUploader}
            name="bulkUploadFile"
            placeholder={t('common:fileUploader.placeholder')}
            maxFiles={MAX_BULK_UPLOAD_FILES_NUMBER}
            maxSize={MAX_LARGE_TEXT_FILE_SIZE}
            maxSizeRestrictionMessage={{ key: 'common:fileUploader.maxSizeRestrictionMessage' }}
            acceptTypes={bulkUploadFileTypes}
            acceptTypesRestrictionMessage={{
              key: 'common:fileUploader.acceptTypesRestrictionMessage',
            }}
            validate={bulkUploadFileValidators}
            className={classes.control}
          />
          <div>
            Uploaded file name:{' '}
            {bulkUploadFile ? bulkUploadFile[0]?.file.name : 'Please upload file'}
          </div>
          <TPGrid item xs={12}>
            <TPButton primary type="submit" size="large">
              Save
            </TPButton>
          </TPGrid>
        </TPGrid>
      </TPGrid>
      <TPGrid container spacing={1}>
        <TPGrid item xs={8}>
          <TPTypography variant="subtitle1">Use only take a photo</TPTypography>
          <TakePhotoBox />
        </TPGrid>
      </TPGrid>
    </form>
  );
};

export default compose(
  reduxForm({
    form: 'TP-FILE-UPLOADERS-FORM',
    initialValues: {
      photoIDFiles: [
        {
          _id: '643e89a8c96b74621417adef',
          url:
            'https://fusionmarkets-staging.s3.ap-southeast-2.amazonaws.com/1681820069769_Client_new_account_id_verification_button_shown_not_clickable.csv?AWSAccessKeyId=AKIA4KRP45JNWM5ZRBD4&Expires=1692115646&Signature=bMr7vqdYN28jZxBTtnOjbKVNvwk%3D',
          mimeType: 'text/csv',
          originalName: 'Client_new_account_id_verification_button_shown_not_clickable.csv',
          size: 48202,
          key: '1681820069769_Client_new_account_id_verification_button_shown_not_clickable.csv',
        },
        {
          _id: '643e89a8c96b74621417adef',
          url:
            'https://fusionmarkets-staging.s3.ap-southeast-2.amazonaws.com/1681820069769_Client_new_account_id_verification_button_shown_not_clickable.JPG?AWSAccessKeyId=AKIA4KRP45JNWM5ZRBD4&Expires=1692710124&Signature=%2BSUDXmjfF1Xg30GCQvOb2%2BZdWaQ%3D',
          mimeType: 'image/jpeg',
          originalName: 'Client_new_account_id_verification_button_shown_not_clickable.JPG',
          size: 48202,
          key: '1681820069769_Client_new_account_id_verification_button_shown_not_clickable.JPG',
        },
        {
          _id: '643e89a8c96b74621417adf0',
          url:
            'https://fusionmarkets-staging.s3.ap-southeast-2.amazonaws.com/1681820069779_driver_license_back.PNG?AWSAccessKeyId=AKIA4KRP45JNWM5ZRBD4&Expires=1692710124&Signature=N4bAWtBbLSskppT%2Fo28LVJOqNuQ%3D',
          mimeType: 'image/png',
          originalName: 'driver_license_back.PNG',
          size: 264082,
          key: '1681820069779_driver_license_back.PNG',
        },
      ],
      bulkUploadFile: null,
      docFiles: null,
    },
  }),
  formValues('bulkUploadFile'),
  formValues('photoIDFiles'),
)(FileUploaders);
