import api from '..';

export const getIMMessagesRequest = () => {
  return api.get('/im');
};

export const getIMMessagesPollingRequest = () => {
  return api.get('/im');
};

export const getIMMessagesNewRequest = () => {
  return api.get('/im');
};

export const getIMUserMessagesRequest = (data) => {
  return api.get(`/im/${data.userId._id}`);
};

export const pinIMUserMessagesRequest = (data) => {
  return api.post(`/im/${data.userId._id}/pin`);
};

export const getIMUserMessagesPollingRequest = (data) => {
  return api.get(`/im/${data.userId._id}`);
};

export const setIMUserMessagesRequest = ({ userId, message, messenger }) => {
  return api.post(`/im/${userId}`, { message, messenger });
};

export const newIMUserMessagesRequest = ({ userId, message, messenger }) => {
  return api.post(`/im/${userId}`, { message, messenger });
};

export const sendIMUserHandshakeRequest = ({ userId, handshakeMessage }) => {
  return api.post(`/im/${userId}/handshake`, {
    handshakeMessage: handshakeMessage,
  });
};

export const sendNewIMUserHandshakeRequest = sendIMUserHandshakeRequest;

export const getIMNewUserMessagesRequest = (data) => {
  return api.get(`/im/${data.userId._id}`);
};
