import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  () => ({
    root: {
      width: '100%',
      height: 'auto',
      minHeight: 40,
    },
  }),
  { name: 'TPCardImage' },
);
