import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      marginBottom: theme.spacing(2),
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
    primary: {
      backgroundColor: theme.palette.primary.lightest,
      color: theme.palette.primary.text,
    },
  }),
  { name: 'TPCardAvatar' },
);
