import get from 'lodash/get';
import { createSelector } from 'reselect';
import { NOTIFICATION_TYPES, MAX_DISPLAYED_ITEMS_NUMBER } from '../constants';

export const getNotifications = (state) => {
  return get(state, 'userNotifications.notifications');
};

export const getNotification = (state) => get(state, 'userNotifications.notification');

export const getUserNotificationsCount = (state) =>
  get(state, 'userNotifications.userNotificationsCount');

export const getUserNotifications = createSelector(
  (state) => state.userNotifications.userNotifications,
  (state) => state.userNotifications.viewedUserNotificationIds,
  (notifications, viewedUserNotificationIds) => {
    return notifications
      .map((notification) => ({
        ...notification,
        viewed: viewedUserNotificationIds.includes(notification._id),
      }))
      .sort((a, b) => a.viewed - b.viewed);
  },
);

export const getNotificationItems = createSelector([getUserNotifications], (notifications) => {
  const items = [];

  notifications.every((item) => {
    if (item.type === NOTIFICATION_TYPES.NOTIFICATION) {
      items.push(item);
    }

    return items.length < MAX_DISPLAYED_ITEMS_NUMBER;
  });

  return items;
});

export const getNotificationUnreadItemsCount = createSelector(
  [getNotificationItems],
  (notificationItems) => notificationItems.filter((item) => !item.viewed).length,
);

export const getWhatsNewItems = createSelector([getUserNotifications], (notifications) => {
  const items = [];

  notifications.every((item) => {
    if (item.type === NOTIFICATION_TYPES.WHATS_NEW) {
      items.push(item);
    }

    return items.length < MAX_DISPLAYED_ITEMS_NUMBER;
  });

  return items;
});

export const getWhatsNewUnreadItemsCount = createSelector(
  [getWhatsNewItems],
  (whatsNewItems) => whatsNewItems.filter((item) => !item.viewed).length,
);

export const getPendingNotificationsCount = (state) =>
  get(state, 'userNotifications.pendingNotificationsCount');
export const getUsersVerificationNotificationsCount = (state) =>
  get(state, 'userNotifications.usersVerificationNotificationsCount');
export const getAccountsVerificationNotificationsCount = (state) =>
  get(state, 'userNotifications.accountsVerificationNotificationsCount');
export const getFollowUpsNotificationsCount = (state) =>
  get(state, 'userNotifications.followupsVerificationNotificationsCount');
export const getExperienceUploadsNotificationsCount = (state) =>
  get(state, 'userNotifications.experienceUploadsNotificationsCount');
export const getKYCVerificationsNotificationsCount = (state) =>
  get(state, 'userNotifications.kycVerificationNotificationsCount');
export const getKYCVerificationsFollowUpNotificationsCount = (state) =>
  get(state, 'userNotifications.kycVerificationsFollowUpNotificationsCount');
