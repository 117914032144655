import React, { useCallback, useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import config from 'config';
import { Field, change, formValues } from 'redux-form';
import PropTypes from 'prop-types';

const ReCaptcha = ({ form, captcha }) => {
  const dispatch = useDispatch();
  const captchaRef = useRef();

  const handleChange = useCallback(
    (captcha) => {
      dispatch(change(form, 'captcha', captcha));
    },
    [dispatch, form],
  );

  useEffect(() => {
    if (!captcha && captchaRef.current) {
      captchaRef.current.reset();
    }
  }, [captcha, captchaRef]);

  return (
    <>
      <Field id="captcha" name="captcha" type="hidden" component="input" />
      <ReCAPTCHA
        ref={captchaRef}
        sitekey={config.CAPTCHA.client}
        onChange={handleChange}
        theme="light"
        size="normal"
      />
    </>
  );
};

ReCaptcha.propTypes = {
  form: PropTypes.string.isRequired,
};

export default formValues('captcha')(ReCaptcha);
