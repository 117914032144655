const styles = () => ({
  button: {
    paddingTop: 10,
    paddingBottom: 10,
    boxShadow: '2px 2px 8px 4px rgba(170,12,156,0.12)',
    fontSize: '1em',
    background: 'linear-gradient(113.45deg, #742DCE 0%, #050B99 51%, #742DCE 100%)',
    backgroundSize: '200% auto',
    transition: '0.5s',
    '&:hover': {
      backgroundPosition: 'right center',
      boxShadow: '2px 2px 8px 4px rgba(170,12,156,0.12)',
    },
    '&:active': {
      background: 'linear-gradient(113.45deg, #050B99 0%, #080C9A 100%)',
      boxShadow: '2px 2px 8px 4px rgba(170,12,156,0.12)',
    },
  },
  buttonLabel: {
    color: 'white',
    fontFamily: 'Lato, sans-serif',
    fontWeight: 900,
    fontSize: '1em',
    lineHeight: '15px',
    boxShadow: '2px 2px 8px 4px rgba(170,12,156,0.12)',
  },
  buttonContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hidden: {
    visibility: 'hidden',
    transition: 'none',
    pointerEvents: 'none',
  },
  visible: {
    visibility: 'visible',
  },
  loader: {
    width: '100%',
    position: 'absolute',
    textAlign: 'center',
  },
  buttonTextContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
