import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    statusContainer: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    statusIcon: {
      display: 'inline-flex',
      marginRight: theme.spacing(1),
    },
    approvedIcon: {
      color: theme.palette.success.main,
    },
    partialApprovedIcon: {
      color: theme.palette.warning.main,
    },
    openedIcon: {
      color: theme.palette.primary.lighter,
    },
    unselectedIcon: {
      color: theme.palette.warning.main,
    },
  }),
  { name: 'Status' },
);
