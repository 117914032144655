import React from 'react';
import TPCheckbox from 'components/TP-UI/TPCheckbox';
import TPLink from 'components/TP-UI/TPLink';
import useStyles from './styles';
import { Field, reduxForm } from 'redux-form';
import { NOTIFICATION_CATEGORIES } from '../../userNotifications/constants';
import { requiredArray } from 'utils/validation/fieldValidationRules';
import TPCheckboxGroup from 'components/TP-UI/TPCheckboxGroup';
import TPGrid from 'components/TP-UI/TPGrid';
import TPTypography from 'components/TP-UI/TPTypography';

const testRequire = (value) => {
  return value === false ? { key: 'common:errors.form.isRequired' } : undefined;
};
const Checkbox = () => {
  const classes = useStyles();
  const signUpText = (
    <>
      I confirm I am over 18 years and accept the
      <TPLink target="_blank" rel="noopener noreferrer" path="">
        Terms and Conditions
      </TPLink>
    </>
  );

  return (
    <form>
      <div className={classes.layout}>
        <Field
          label="Option1 small"
          component={TPCheckbox}
          name="checkbox1"
          size="small"
          disabled={true}
        />
        <Field label={signUpText} disabled={true} component={TPCheckbox} name="checkbox1" />
        <Field label="Checkbox" component={TPCheckbox} name="checkbox2" />
        <Field
          label="Checkbox"
          component={TPCheckbox}
          name="checkbox4"
          validate={[testRequire]}
          meta={{ error: { key: 'common:errors.form.isRequired' }, submitFailed: true }}
        />
        <TPGrid container spacing={2}>
          <TPGrid item>
            <TPTypography variant="h5">Group of checkboxes</TPTypography>
          </TPGrid>
          <TPGrid item>
            <Field
              name="categories"
              component={TPCheckboxGroup}
              options={NOTIFICATION_CATEGORIES}
              validate={[requiredArray]}
            />
          </TPGrid>
          <TPGrid item>
            <TPTypography variant="h5">Group of checkboxes, error</TPTypography>
          </TPGrid>
          <TPGrid item>
            <Field
              name="categories"
              component={TPCheckboxGroup}
              options={NOTIFICATION_CATEGORIES}
              validate={[requiredArray]}
              meta={{ error: { key: 'common:errors.form.isRequired' }, submitFailed: true }}
            />
          </TPGrid>
        </TPGrid>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'TP-CHECKBOX-FORM',
  initialValues: {
    checkbox2: true,
    checkbox3: true,
    categories: [],
  },
})(Checkbox);
