import { combineReducers } from 'redux';

import userCountry from './userCountry';
import phoneVerification from './phoneVerification';

const reducer = combineReducers({
  userCountry,
  phoneVerification,
});

export default reducer;
