import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import TPModal from 'components/TP-UI/TPModal';
import { closeModal } from 'components/TP-UI/TPModal/actions';
import { ACCOUNT_PLANS } from 'constants/accountPlans';
import { Routes } from 'constants/routeConstants';
import { selectAccountPlanRequest } from 'modules/accounts/pages/MyAccounts/actions';

import verificationModalNames from '../../constants/verificationModalNames';
import QuizModalContent from '../QuizModalContent';
import { useTheme } from '@material-ui/core';

const ContinueAsRetailClientModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('quiz');
  const { quiz } = useTheme();
  const imageConfig = quiz.continueAsRetailsModal.avatar;

  const handleClose = useCallback(() => {
    dispatch(closeModal(verificationModalNames.CONTINUE_AS_RETAIL_CLIENT));
  }, [dispatch]);

  const handleApprove = useCallback(() => {
    dispatch(selectAccountPlanRequest(ACCOUNT_PLANS.RETAIL));
    dispatch(closeModal(verificationModalNames.PRO_SIGN_MODAL));
    history.push(Routes.MY_ACCOUNTS);
    handleClose();
  }, [dispatch, handleClose, history]);

  const buttons = useMemo(
    () => [
      {
        label: t('continueAsRetailClientModal.primaryButtonText'),
        primary: true,
        onClick: handleApprove,
      },
      {
        label: t('continueAsRetailClientModal.secondaryButtonText'),
        secondary: true,
        onClick: handleClose,
      },
    ],
    [t, handleApprove, handleClose],
  );

  return (
    <TPModal
      modalName={verificationModalNames.CONTINUE_AS_RETAIL_CLIENT}
      title={t('pro.page.title')}
      hideClose
      buttons={buttons}>
      <QuizModalContent
        imageConfig={imageConfig}
        text={t('continueAsRetailClientModal.description')}
        title={t('continueAsRetailClientModal.title')}
      />
    </TPModal>
  );
};

export default ContinueAsRetailClientModal;
