import React from 'react';
import ReactDOM from 'react-dom';
import { stopReportingRuntimeErrors } from 'react-error-overlay';

import App from './components/App';
import './i18n';

if (process.env.NODE_ENV === 'development') {
  stopReportingRuntimeErrors();
}

ReactDOM.render(<App />, document.getElementById('root'));
