export const DEFAULT_PAGINATION = {
  currentPage: 0,
  pageSize: 10,
  total: 0,
  nav: true,
};

export const DEFAULT_SORTING = {
  sortBy: 'createdAt',
  sortAsc: false,
};

export const NEED_TO_PARSE = ['firstTrade', 'lastTrade', 'lastLogin', 'lastDemo', 'volume', 'cxd'];

export const FILTER_MAPPER = {
  firstTradeDays: 'firstTrade',
  lastTradeDays: 'lastTrade',
  lastLoginDays: 'lastLogin',
  lastDemoDays: 'lastDemo',
};

export const SORTING_MAPPER = {
  firstTradeDays: 'firstTradeTime',
  lastTradeDays: 'lastTradeTime',
  lastLoginDays: 'lastLoginTime',
  lastDemoDays: 'lastDemoTime',
};

//when user sorting by that fields need to send default values with sorting values
//or when user shows columns for some of them need to send default filter values to get data
export const DEFAULT_FILTER_VALUES = {
  firstTrade: [-1],
  lastTrade: [-1],
  volume: [-1],
};

export const SORT_BY_OPTIONS = [
  { value: '', label: 'Registration Date' },
  { value: 'firstTradeTime', label: 'First Trade Date' },
  { value: 'lastSuccessfulDeposit', label: 'Last Successful Deposit' },
  { value: 'lastUnsuccessfulDeposit', label: 'Last Unsuccessful Deposit' },
  { value: 'lastDemoTime', label: 'Last New Demo Account' },
];

export const FIRST_TRADE_RANGES = [
  { value: '[0, 5]', label: '0 - 5 days' },
  { value: '[6, 10]', label: '6 - 10 days' },
  { value: '[11, 20]', label: '11 - 20 days' },
  { value: '[21, 30]', label: '21 - 30 days' },
  { value: '[31, 60]', label: '31 - 60 days' },
  { value: '[61]', label: '61 days +' },
];

export const LAST_TRADE_RANGES = [
  { value: '[0, 5]', label: '0 - 5 days' },
  { value: '[6, 10]', label: '6 - 10 days' },
  { value: '[11, 20]', label: '11 - 20 days' },
  { value: '[21, 30]', label: '21 - 30 days' },
  { value: '[31, 60]', label: '31 - 60 days' },
  { value: '[61]', label: '61 days +' },
];

export const LAST_LOGIN_RANGES = [
  { value: '[0, 5]', label: '0 - 5 days' },
  { value: '[6, 10]', label: '6 - 10 days' },
  { value: '[11, 20]', label: '11 - 20 days' },
  { value: '[21, 30]', label: '21 - 30 days' },
  { value: '[31, 60]', label: '31 - 60 days' },
  { value: '[61]', label: '61 days +' },
];

export const LAST_DEMO_RANGES = [
  { value: '[0, 5]', label: '0 - 5 days' },
  { value: '[6, 10]', label: '6 - 10 days' },
  { value: '[11, 20]', label: '11 - 20 days' },
  { value: '[21, 30]', label: '21 - 30 days' },
  { value: '[31, 60]', label: '31 - 60 days' },
  { value: '[61]', label: '61 days +' },
];

export const VOLUME_RANGES = [
  { value: '[0, 15000]', label: '0 - 15000' },
  { value: '[15001, 100000]', label: '15001 - 100000' },
  { value: '[100001, 500000]', label: '100001 - 500000' },
  { value: '[500001, 1500000]', label: '500001 - 1500000' },
  { value: '[1500001]', label: '1500001 +' },
];

export const VOLUME_DATES = [
  { value: 30, label: '30 days' },
  { value: 60, label: '60 days' },
];

export const USER_TYPES = [
  { value: 'pro', label: 'Pro' },
  { value: 'wholesale', label: 'Wholesale' },
  { value: 'retail', label: 'Retail' },
];
