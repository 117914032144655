import palette from './palette';

const OverridesMuiDivider = {
  root: {
    backgroundColor: palette.divider,
    margin: '8px 0 8px 0px',
  },
  vertical: {
    margin: '0px 8px 0px 8px',
  },
};

export default OverridesMuiDivider;
