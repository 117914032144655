import { useCallback, useEffect, useState } from 'react';
import { VIDEO_SETTINGS, WEBCAM_STATE } from '../components/TPTakePhoto/constants';
import { isIOS, isMobile } from 'react-device-detect';

/**
 * Need to call stopTracking on component unmount
 */
const useTakePhoto = ({ value = null, mode = 'video' } = {}) => {
  const [imageDataURL, setImageDataUrl] = useState(null);
  const [cameraAllowed, setCameraAllowed] = useState(false);
  const [webcamState, setWebcamState] = useState(WEBCAM_STATE.WAIT);
  const canTakePhoto = webcamState === WEBCAM_STATE.WAIT || webcamState === WEBCAM_STATE.GRANTED;

  useEffect(() => {
    if (value) {
      setImageDataUrl(URL.createObjectURL(value));
    }
  }, [value]);

  const capture = useCallback(
    (playerRef, onFinish) => {
      if (playerRef.current.srcObject && mode === 'video') {
        let canvas = document.createElement('canvas');
        canvas.width = playerRef.current.videoWidth;
        canvas.height = playerRef.current.videoHeight;
        let contex = canvas.getContext('2d');
        contex.drawImage(playerRef.current, 0, 0, canvas.width, canvas.height);
        playerRef.current.srcObject.getVideoTracks().forEach((track) => {
          track.stop();
        });

        canvas.toBlob((blob) => {
          const date = new Date().getTime();
          const file = new File([blob], `image${date}.png`, { type: 'image/png' });
          if (onFinish) {
            onFinish(file);
          } else {
            setImageDataUrl(URL.createObjectURL(file));
          }
        });
      } else if (mode === 'input') {
        const file = playerRef.current.files[0];
        if (onFinish) {
          onFinish(file);
        } else {
          setImageDataUrl(URL.createObjectURL(file));
        }
      }
    },
    [mode],
  );

  const stopTracking = useCallback((playerRef) => {
    if (playerRef?.current?.srcObject) {
      playerRef.current.srcObject.getVideoTracks().forEach((track) => {
        track.stop();
      });
    }
  }, []);

  const initializeMedia = useCallback(
    async (playerRef) => {
      setImageDataUrl(null);

      if (mode === 'input') {
        stopTracking(playerRef);
        return;
      }

      if (!('mediaDevices' in navigator)) {
        navigator.mediaDevices = {};
      }

      if (!('getUserMedia' in navigator.mediaDevices)) {
        navigator.mediaDevices.getUserMedia = function(constraints) {
          let getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

          if (!getUserMedia) {
            return Promise.reject(new Error('getUserMedia Not Implemented'));
          }

          return new Promise((resolve, reject) => {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }
      const enumerateDevices = await navigator.mediaDevices.enumerateDevices();
      const videoInputs = enumerateDevices.filter((device) => device.kind === 'videoinput');

      if (videoInputs.length) {
        const params = {
          video: VIDEO_SETTINGS,
          audio: false,
        };
        if (isIOS && isMobile) {
          params.video.facingMode = { exact: 'user' };
        } else if (videoInputs[0].deviceId) {
          params.deviceId = {
            exact: videoInputs[0].deviceId,
          };
        }
        setWebcamState(WEBCAM_STATE.WAIT);

        navigator.mediaDevices
          .getUserMedia(params)
          .then((stream) => {
            setWebcamState(WEBCAM_STATE.GRANTED);
            setCameraAllowed(true);
            playerRef.current.srcObject = stream;
          })
          .catch(() => {
            setWebcamState(WEBCAM_STATE.DENIED);
            setCameraAllowed(false);
          });
      } else {
        setWebcamState(WEBCAM_STATE.NO_CAMERA);
      }
    },
    [mode, stopTracking],
  );

  const recapture = useCallback(
    (playerRef, onFinish) => {
      if (imageDataURL && typeof imageDataURL === 'object') {
        URL.revokeObjectURL(imageDataURL);
      }
      setImageDataUrl(null);
      if (!playerRef?.current?.srcObject && mode === 'video') {
        initializeMedia(playerRef);
      }
      if (onFinish) {
        onFinish(null);
      }
    },
    [imageDataURL, initializeMedia, mode],
  );

  useEffect(() => {
    // Revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      imageDataURL && typeof imageDataURL === 'object' && URL.revokeObjectURL(imageDataURL);
  }, [imageDataURL]);

  return {
    cameraAllowed,
    imageDataURL,
    canTakePhoto,
    webcamState,
    initializeMedia,
    stopTracking,
    capture,
    recapture,
  };
};

export default useTakePhoto;
