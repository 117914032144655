import { handleActions } from 'redux-actions';
import { clearBankDetails, saveBankDetails } from '../actions';

const defaultState = {};

export default handleActions(
  {
    [saveBankDetails](state, action) {
      return action.payload;
    },
    [clearBankDetails]() {
      return defaultState;
    },
  },
  defaultState,
);
