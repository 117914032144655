import { all, put, takeEvery, call } from 'redux-saga/effects';
import { push } from 'modules/reduxNavigation/actions';

import { showNotificationInfo } from 'modules/notifications/actions';
import { signinAsUserSuccess, signinAsUserFail } from '../actions';
import showErrorMessage from 'utils/showErrorMessage';
import { Routes } from 'constants/routeConstants';

function* signinAsUserSuccessHandler() {
  const email = localStorage.getItem('adminId');
  yield put(push(Routes.HOME));
  if (email) {
    yield put(showNotificationInfo(`Login as user successfully`));
  }
}

function* signinAsUserFailHandler(action) {
  localStorage.removeItem('adminId');
  localStorage.removeItem('adminToken');
  yield call(showErrorMessage, action);
}

export default function* watchRequest() {
  yield all([
    takeEvery(signinAsUserSuccess, signinAsUserSuccessHandler),
    takeEvery(signinAsUserFail, signinAsUserFailHandler),
  ]);
}
