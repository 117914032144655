import React from 'react';
import TPGrid from 'components/TP-UI/TPGrid';
import TPRating, { TPRating as Rating } from 'components/TP-UI/TPRating';
import { Field, reduxForm } from 'redux-form';
import TPTypography from 'components/TP-UI/TPTypography';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';

import useStyles from './styles.js';

const testRequire = (value) => {
  return value === false ? { key: 'common:errors.form.isRequired' } : undefined;
};

const Ratings = () => {
  const classes = useStyles();
  return (
    <form>
      <TPGrid container spacing={1}>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Simple rating</TPTypography>
          <Field component={TPRating} name="rating" label="Feedback" />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Maximum 10</TPTypography>
          <Field component={TPRating} name="feedRating" max={10} />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Hint</TPTypography>
          <Field component={TPRating} name="rating" hint="Please provide your feedback" />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Error</TPTypography>
          <Field
            component={TPRating}
            name="rating"
            validate={[testRequire]}
            required
            hint="Additional info"
            meta={{ error: { key: 'common:errors.form.isRequired' }, submitFailed: true }}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Disabled, hint</TPTypography>
          <Field component={TPRating} name="rating" disabled hint="Please provide your feedback" />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Readonly, hint</TPTypography>
          <Field component={TPRating} name="rating" readonly hint="Your feedback" />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Small size, hint</TPTypography>
          <Field
            component={TPRating}
            name="rating"
            size="small"
            hint="Please provide your feedback"
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Large size, hint</TPTypography>
          <Field
            component={TPRating}
            name="rating"
            size="large"
            hint="Please provide your feedback"
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Custom icon</TPTypography>
          <Field
            component={TPRating}
            name="rating"
            size="large"
            icon={SentimentSatisfiedIcon}
            hint="Please provide your feedback"
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Custom color</TPTypography>
          <Field
            component={TPRating}
            name="rating"
            icon={SentimentSatisfiedIcon}
            hint="Please provide your feedback"
            classes={classes}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Not redux, readonly</TPTypography>
          <Rating value={3} readonly hint="Your feedback" />
        </TPGrid>
      </TPGrid>
    </form>
  );
};

export default reduxForm({
  form: 'TP-RATINGS-FORM',
  initialValues: {
    rating: 0,
    feedRating: 2,
  },
})(Ratings);
