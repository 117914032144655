import { handleActions } from 'redux-actions';
import get from 'lodash/get';

import {
  getReferralBalanceFail,
  getReferralBalanceRequest,
  getReferralBalanceSuccess,
} from '../actions';

const defaultState = null;

export default handleActions(
  {
    [getReferralBalanceRequest]: () => '',
    [getReferralBalanceSuccess]: (_state, action) => get(action, 'response.data.balance', ''),
    [getReferralBalanceFail]: () => '',
  },
  defaultState,
);
