import React from 'react';
import TPTypography from '../../../TPTypography';

const TPCardDescription = ({ color = 'secondary', className, children }) => {
  return (
    <TPTypography variant="body2" color={color} className={className}>
      {children}
    </TPTypography>
  );
};

export default TPCardDescription;
