import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Header from '../components/Header';
import { logout, logoutAsCurrentUser } from 'modules/auth/actions';
import { getIsClientRole, getUserEmail, getUserName, isAuHub } from 'modules/auth/selectors';
import { LOGOUT_REASON } from '../../../auth/constants';

const HeaderContainer = ({
  title,
  mobile,
  showActions,
  breadcrumbsRoutes,
  titleClassName,
  onDrawerClose,
  children,
}) => {
  const dispatch = useDispatch();
  const email = useSelector(getUserEmail);
  const userName = useSelector(getUserName);
  const isClient = useSelector(getIsClientRole);
  const auHub = useSelector(isAuHub);

  const handleLogout = useCallback(() => {
    dispatch(logout({ reason: LOGOUT_REASON.USER_ACTION }));
  }, [dispatch]);

  const handleLogoutAsCurrentUser = useCallback(() => {
    dispatch(logoutAsCurrentUser());
  }, [dispatch]);

  return (
    <Header
      isClient={isClient}
      userName={userName}
      email={email}
      title={title}
      isAuHub={auHub}
      breadcrumbsRoutes={breadcrumbsRoutes}
      mobile={mobile}
      showActions={showActions}
      onDrawerClose={onDrawerClose}
      onLogoutClick={handleLogout}
      onLogoutAsCurrentUser={handleLogoutAsCurrentUser}
      titleClassName={titleClassName}>
      {children}
    </Header>
  );
};

HeaderContainer.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType, PropTypes.string]).isRequired,
  mobile: PropTypes.bool,
  showActions: PropTypes.bool,
  breadcrumbsRoutes: PropTypes.array,
  onDrawerClose: PropTypes.func,
  titleClassName: PropTypes.string,
};

export default HeaderContainer;
