import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import TPButton from '../../../TPButton';
import { Field, reduxForm } from 'redux-form';
import { debounce } from './helpers/debounce';
import TPIconButton from '../../../TPIconButton';
import TPBadge from '../../../TPBadge';
import DnsIcon from '@material-ui/icons/Dns';
import DnsOutlinedIcon from '@material-ui/icons/DnsOutlined';
import { MAX_PRIMARY_FILTERS } from './constants';
import TPTooltip from '../../../TPTooltip';
import { useTranslation } from 'react-i18next';
import { isTablet, isMobile } from 'react-device-detect';

import useStyles from './styles';

const TPFilters = ({
  fields,
  children,
  loading = false,
  disabled = false,
  handleSubmit,
  reset,
  pristine,
  maxPrimaryFilters = MAX_PRIMARY_FILTERS,
  className,
}) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const [showFilterButtons, setShowFilterButtons] = useState(false);
  const [primaryFilters, setPrimaryFilters] = useState();
  const [additionalFilters, setAdditionalFilters] = useState([]);
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);

  useEffect(() => {
    if (fields) {
      setShowFilterButtons(fields.length > maxPrimaryFilters);
      setPrimaryFilters(fields.slice(0, maxPrimaryFilters));
      if (fields.length > maxPrimaryFilters) {
        setAdditionalFilters(fields.slice(maxPrimaryFilters));
      }
    }
  }, [fields, maxPrimaryFilters]);

  const handleToggleFiltersClick = useCallback(() => {
    setShowAdditionalFilters((val) => !val);
  }, []);

  const debouncedHandleChange = useMemo(
    () => (callback) =>
      debounce((value) => {
        if (typeof callback === 'function') {
          callback(value);
        }
        handleSubmit();
      }, 700),
    [handleSubmit],
  );

  return (
    <form className={classnames(classes.headerContainer, className)} onSubmit={handleSubmit}>
      <div className={classes.actionsContainer}>
        {primaryFilters ? (
          <div className={classes.filtersContainer}>
            {primaryFilters.map(({ width, ...props }) => (
              <div
                className={classes.filter}
                style={width ? { width: width } : null}
                key={props.name}>
                <Field
                  clearable={true}
                  {...props}
                  reservedErrorSpace={false}
                  size="small"
                  debounced={additionalFilters.length === 0}
                  disabled={loading || disabled}
                  fullWidth
                  {...(additionalFilters.length === 0
                    ? { onChange: debouncedHandleChange(props.onChange) }
                    : {})}
                />
              </div>
            ))}
          </div>
        ) : null}
        <div className={classes.settingsContainer}>
          <div className={classes.settings}>
            <div className={classes.filtersButtonsContainer}>
              {showFilterButtons ? (
                <>
                  <TPButton alternative type="submit" loading={loading} disabled={disabled}>
                    {t('buttons.apply')}
                  </TPButton>
                  <TPButton secondary onClick={reset} disabled={loading || disabled}>
                    {t('buttons.reset')}
                  </TPButton>
                </>
              ) : null}
            </div>
            <div className={classes.settingButtonsContainer}>
              {additionalFilters.length > 0 ? (
                <TPTooltip
                  disabled={isTablet || isMobile}
                  content={
                    showAdditionalFilters ? t('labels.collapseFilters') : t('labels.expandFilters')
                  }>
                  <TPIconButton onClick={handleToggleFiltersClick}>
                    <TPBadge variant="dot" color="error" invisible={pristine}>
                      {showAdditionalFilters ? <DnsIcon /> : <DnsOutlinedIcon />}
                    </TPBadge>
                  </TPIconButton>
                </TPTooltip>
              ) : null}
              {children}
            </div>
          </div>
        </div>
      </div>
      {showAdditionalFilters ? (
        <div className={classes.filtersContainer}>
          {additionalFilters.map(({ width, ...props }) => (
            <div
              className={classes.filter}
              style={width ? { width: width } : null}
              key={props.name}>
              <Field
                clearable={true}
                {...props}
                reservedErrorSpace={false}
                size="small"
                disabled={loading || disabled}
                fullWidth
              />
            </div>
          ))}
        </div>
      ) : null}
    </form>
  );
};

TPFilters.propTypes = {
  form: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      /**
       * Since there is no form label should be in filter's panel, the placeholder should inform user about field meaning
       */
      placeholder: PropTypes.node,
      /**
       * Redux Form Component
       */
      component: PropTypes.elementType.isRequired,
      /**
       * field width in pixels or percentage, ex. 100px or 25%
       */
      width: PropTypes.string,
      className: PropTypes.string,
      onChange: PropTypes.func,
    }),
  ),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  maxPrimaryFilters: PropTypes.number,
  /**
   * Called when the page number or pageSize is changed, function ({page, pageSize})
   */
  onSubmit: PropTypes.func,
};

const TPFiltersReduxForm = reduxForm({
  enableReinitialize: true,
  destroyOnUnmount: true,
  /**
   * This is default name to resolve issue with unmount for cases like using sorting or/and file actions or/and allow columns settings.
   * Need to set unique name for each place of usage, if you define filters!
   */
  form: 'TPTABLE_FILTERS_FORM',
})(TPFilters);

export default TPFiltersReduxForm;
