import React from 'react';
import Badge from '@material-ui/core/Badge';
import PropTypes from 'prop-types';
import useStyles from './styles';
import classNames from 'classnames';

const TPBadge = ({
  offsetX = 0,
  offsetY = 0,
  overlap = 'rectangular',
  classes = {},
  className,
  children,
  ...props
}) => {
  const badgeClasses = useStyles({ offsetX, offsetY });
  classes.badge = classNames(classes.badge, badgeClasses.offset, className);

  return (
    <Badge classes={classes} overlap={overlap} {...props}>
      {children}
    </Badge>
  );
};

TPBadge.propTypes = {
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  overlap: PropTypes.oneOf(['circular', 'rectangular']),
  classes: PropTypes.object,
};

TPBadge.muiName = Badge.muiName;

export default TPBadge;
