import palette from './palette';
import { fontSize } from '../font';

const OverridesMuiChip = {
  root: {
    color: palette.primary.text,
    backgroundColor: palette.neutral.lightest,
    fontSize: fontSize._16,
  },
  outlined: {
    color: palette.text.primary,
    '&:hover': {
      borderColor: palette.primary.lighter,
    },
    '& $deleteIcon': {
      marginRight: '10px',
    },
    '& $deleteIconSmall': {
      marginRight: '5px',
    },
  },
  label: {
    fontSize: 'inherit',
  },
  deleteIcon: {
    width: '16px',
    height: '16px',
    color: palette.primary.text,
    margin: '0 10px 0 -5px',
    padding: 0,
  },
  sizeSmall: {
    fontSize: fontSize._14,
  },
};

export default OverridesMuiChip;
