import classnames from 'classnames';
import React from 'react';
import useStyles from './styles';

const LpoaTerms = ({ fee }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div>
        <ol className={classes.list}>
          <li>
            <p>
              To do business with Fusion Markets (the “Company”) pursuant to the customer agreement
              with them and in accordance with the rules applicable thereto; and
            </p>
          </li>
          <li>
            <p>For that purpose, to:</p>
          </li>
          <div>
            <ol type="a" className={classes.list}>
              <li>
                <p>
                  open, operate and close the Customer’s account (as appropriate) with the exclusion
                  of cash withdrawals and third-party payments;
                </p>
              </li>
              <li>
                <p>
                  deal on the Customer’s behalf with the Company which the Attorney undertakes on
                  such terms as the Attorney shall determine in its discretion, including (without
                  limitation) entering into transactions using any electronic or online trading
                  system provided by the Company to the Attorney, or by giving oral or written
                  instructions;
                </p>
              </li>
              <li>
                <p>make margin payments on the Customer’s account;</p>
              </li>
              <li>
                <p>
                  do anything else contemplated by the customer agreement which is reasonably
                  necessary for the purposes of the Customer’s dealing with the Company pursuant to
                  that agreement.
                </p>
              </li>
            </ol>
          </div>
          <li>
            <p>
              The Customer authorises the Company to accept all instructions for its account,
              whether orally or in writing, from the Attorney and its servants and agents. The
              Company shall not be obliged to make any enquiry of the Customer or of any other
              account, account holder or person before acting on such instructions.
            </p>
          </li>
          <li>
            <p>
              The Customer accepts full responsibility and liability for all such instructions (and
              for all transactions that may be entered into as a result) and will indemnify the
              Company and keep it indemnified against any loss, damage or expense incurred by it as
              a result of its acting on such instructions. This indemnity shall be effective:
            </p>
          </li>
          <div>
            <ol type="a" className={classes.list}>
              <li>
                <p>whatever the circumstances giving rise to such loss, damage or expense;</p>
              </li>
              <li>
                <p>
                  whatever the knowledge, acts or omissions of the Company in relation to any other
                  account held by any other person or body (including the Attorney named above) with
                  the Company.
                </p>
              </li>
            </ol>
          </div>
          <li>
            <p>
              The Customer further agrees that this indemnity shall extend to loss, damage or
              expense incurred by the Company in reversing incorrect or erroneous instructions
              submitted by the Attorney that result in a transaction that must, for the protection
              of the Company or its other customers or for reasons of market integrity, be reversed.
            </p>
          </li>
          <li>
            <p>
              The Customer acknowledges and accepts the inherent risk that online or electronic
              communications may not reach their intended destination or may do so much later than
              intended for reasons outside your control. The Customer accepts that it bears the risk
              of the Attorney’s instructions being lost for any reason whatsoever (including,
              without limitation, malfunctions in any electronic or online trading system).
            </p>
          </li>
          <li>
            <p>
              The Customer acknowledges and accepts that, in providing an electronic or online
              trading system to the Attorney, the Company has the right but not the obligation to
              set limits, controls, parameters and/or other controls on the Attorney’s ability to
              use such a system. The Customer accepts that if the Company chooses not to place any
              such limits or controls on the Attorney’s trading, or if such limits or controls fail
              for any reason, the Company will not exercise oversight or control over such
              instructions given by the Attorney and the Customer accepts full responsibility and
              liability for the Attorney’s actions in such circumstances.
            </p>
          </li>
          <li>
            <p>
              The Customer undertakes to ratify whatever the Attorney does under the authority or
              purported authority of this power. The Customer agrees to indemnify the Attorney
              against all expenses, losses and liabilities incurred by it when acting in pursuance
              of this power.
            </p>
          </li>
          <li>
            <p>
              This authorisation may only be amended or revoked by the Customer in writing. Unless
              notified otherwise in writing by the Company, any such variation or revocation shall
              not be effective until two working days after it is received by the Company. The
              Customer acknowledges that it will remain liable for all instructions given to the
              Company prior to the revocation/variation being effective, and that it will be
              responsible for any losses which may arise on any transactions which are open at such
              time. The Customer will indemnify and keep the Company indemnified in respect of any
              such losses.
            </p>
          </li>
          <li>
            <p>
              Each Customer who is an individual consents to and authorises the Company to disclose
              to the Attorney such personal information concerning the Customer as is reasonably
              necessary for any of the purposes specified in Clause 2 or any purpose incidental
              thereto.
            </p>
          </li>
          <li>
            <p>
              This Deed shall be governed by and construed in accordance with the laws of the State
              of New South Wales and I irrevocably submit to the jurisdiction of the State of New
              South Wales courts in relation to any dispute arising out of this Deed.
            </p>
          </li>
        </ol>
      </div>
      <div className={classes.subTitle}>
        <p className={classes.title}>AUTHORITY TO DEDUCT AND PAY REBATES, COMMISSIONS AND FEES</p>
        <ol className={classnames(classes.list, classes.list)}>
          <li>
            <p>Authority</p>
            <p className={classes.content}>
              The Client authorises and directs the Company to withdraw from the Client Account and
              pay to the Attorney the fees and commissions (“Fees”) calculated by the Attorney and
              payable at the specified time(s) set out in the Schedule.
            </p>
          </li>
          <li>
            <p>Authority Revocable</p>
            <p className={classes.content}>
              This authority may be revoked by notice in writing by the Client to the Company.
            </p>
          </li>
          <li>
            <p>Liability</p>
            <p className={classes.content}>
              It is acknowledged by the Client that the Company is bound to act in accordance with
              this Authority and is released from any obligation or liability that would or might
              otherwise attach to the Company in relation to this Authority or the calculation of
              any Fees by the Attorney.
            </p>
          </li>
          <li>
            <p>Indemnity</p>
            <p className={classes.content}>
              The Client continuously indemnifies the Company in respect of all liabilities and
              expenses properly incurred or suffered by the Company by reason of acting in
              accordance with this Authority or connected with it or arising out of the calculation
              of any Fees by the Attorney.
            </p>
          </li>
          <li>
            <p>Attorney Compensation</p>
            <p className={classes.content}>
              The Client understands that the Company may provide compensation to Attorney from the
              revenues generated by trading done by the Attorney on the Client’s behalf and that
              such compensation may be on a per-trade basis. The Client understands that such
              compensation may create a conflict of interest for the Attorney in that the Attorney
              may have an incentive to make trades to generate compensation. The Client understands
              and accepts this conflict of interest, waives any and all objections to such conflict
              of interest, and agrees to hold harmless and indemnify the Attorney and the Company
              for any losses or liability incurred arising out of or related to such conflicts.
            </p>
          </li>
          <li>
            <p>Performance Fees</p>
            <p
              className={
                classes.content
              }>{`The client hereby ratifies and confirms compensation for the
            Attorney's services according to the following terms: Profit Share of ${fee}% in excess
            of the initial deposit (agreed level). The account will be subjected to a new agreed
            level whenever there's a top-up deposit or withdrawal.`}</p>
          </li>
          <li>
            <p>Calculation</p>
            <p className={classes.content}>
              Profit share is calculated and payable on the last business day of each calendar month
              in arrears or when the account equity exceeds the agreed level, whichever comes later.
              Each such month being a ‘calculation period’ where:
            </p>
            <ol className={classnames(classes.content, classes.list)} type="I">
              <li>Profit:</li>
              <div>
                <ol type="i">
                  <li>Net Realized Profits (including swaps and commissions); plus</li>
                  <li>Net floating loss; plus</li>
                  <li>Positive trade-related adjustments; plus</li>
                  <li>Negative trade-related adjustments; minus</li>
                </ol>
              </div>
              <li>Agreed Level:</li>
              <div>
                <ol type="i" className={classes.list}>
                  <li>Initial deposit will be the default agreed level</li>
                  <li>
                    If the account incurs a loss, no performance fee shall be paid until the account
                    balance exceeds the agreed level.
                  </li>
                  <li>
                    {`Whenever there's a deposit or withdrawal, the remaining balance will be the new
                agreed level.`}
                  </li>
                </ol>
              </div>
            </ol>
          </li>
          <li>
            <p>Withdrawal</p>
            <p className={classes.content}>
              The client acknowledges that any withdrawal or revocation requests may cause further
              loss to the Client account and that the Company is not accountable for any such
              losses. If the Client requests a withdrawal from his/her account and there are
              insufficient funds available to pay the Attorney as a result of such request or other
              action taken by the Client, the Client hereby agrees and understands that all fees are
              debited from his/her trading account prior to requested withdrawal.
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default LpoaTerms;
