import makeStyles from '@material-ui/core/styles/makeStyles';
const useStyles = makeStyles(
  () => ({
    modal: {
      width: '500px',
    },
  }),
  { name: 'AMLSuccessModal' },
);
export default useStyles;
