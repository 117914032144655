import palette from './palette';
import { colors } from './colors';

const OverridesMuiRadio = {
  root: {
    padding: 4,
    marginRight: 6,
    color: colors.gray.darker,

    '&$disabled': {
      opacity: '0.5',
    },
  },
  colorPrimary: {
    color: colors.gray.darker,

    '&$checked': {
      color: palette.primary.main,
      '&$disabled': {
        color: palette.primary.main,
      },
    },
    '&$disabled': {
      color: colors.gray.darker,
    },
  },
};

export default OverridesMuiRadio;
