export const TV_ELIGIBLE_MODAL = 'TV_ELIGIBLE_MODAL';
export const TV_ELIGIBLE_PENDING_MODAL = 'TV_ELIGIBLE_PENDING_MODAL';
export const TV_SUB_CANCEL_MODAL = 'TV_SUB_CANCEL_MODAL';
export const TV_ELIGIBLE_FORM = 'TV_ELIGIBLE_FORM';

export const TV_SUBSCRIPTION_PLANS = {
  NONE: 'none',
  ESSENTIAL: 'essential',
  PLUS: 'plus',
  PREMIUM: 'premium',
};

export const TV_SUBSCRIPTION_PLANS_CONFIG = {
  [TV_SUBSCRIPTION_PLANS.NONE]: {
    label: 'None',
    value: 'none',
    minLots: 0,
    clientVisible: false,
    refundAmount: 0,
  },
  [TV_SUBSCRIPTION_PLANS.ESSENTIAL]: {
    label: 'Essential',
    value: 'essential',
    minLots: 2,
    clientVisible: true,
    refundAmount: 12.95,
  },
  [TV_SUBSCRIPTION_PLANS.PLUS]: {
    label: 'Plus',
    value: 'plus',
    minLots: 6,
    clientVisible: true,
    refundAmount: 24.95,
  },
  [TV_SUBSCRIPTION_PLANS.PREMIUM]: {
    label: 'Premium',
    value: 'premium',
    minLots: 12,
    clientVisible: true,
    refundAmount: 49.95,
  },
};

export const TV_SUBSCRIPTION_PLANS_OPTIONS = Object.values(TV_SUBSCRIPTION_PLANS_CONFIG);

export const TV_SUBSCRIPTION_STATUS = {
  APPROVED: 'approved',
  CANCELLED: 'cancelled',
};

export const TV_SUBSCRIPTION_STATUS_OPTIONS = [
  { value: TV_SUBSCRIPTION_STATUS.APPROVED, label: 'Approved' },
  { value: TV_SUBSCRIPTION_STATUS.CANCELLED, label: 'Cancelled' },
];

export const TV_CHART_DAY = 1000 * 60 * 60 * 24;
export const TV_MAX_LARGE_IMAGE_FILE_SIZE = 1024 * 1024 * 20;
export const TV_MAX_PHOTO_ID_FILES_NUMBER = 4;
