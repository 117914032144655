import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    spacing: {
      margin: theme.spacing(1),
    },
    badge: {
      backgroundColor: theme.palette.success.main,
    },
  }),
  { name: 'IconButtons' },
);

export default useStyles;
