import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import TPLoader from '../TPLoader';

import useStyles from './styles';

const TPCard = ({
  variant = 'outlined',
  loading,
  disabled,
  selected,
  fullWidth,
  stretch,
  onClick,
  className,
  children,
}) => {
  const classes = useStyles();

  return (
    <Card
      variant={variant}
      disabled={loading || disabled}
      className={classnames(classes.root, className, [
        {
          [classes.clickable]: onClick,
          [classes.disabled]: loading || disabled,
          [classes.selected]: selected,
          [classes.fullWidth]: fullWidth,
          [classes.stretch]: stretch,
        },
      ])}
      onClick={onClick}>
      {children}
      {loading ? (
        <div className={classes.cardLoader}>
          <TPLoader />
        </div>
      ) : null}
    </Card>
  );
};

TPCard.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  fullWidth: PropTypes.bool,
  /**
   * Card expands to full height and width.
   */
  stretch: PropTypes.bool,
  onClick: PropTypes.func,
};

TPCard.muiName = Card.muiName;

export default TPCard;
