import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import * as authSelectors from 'modules/auth/selectors';
import loadScript from 'utils/loadScript';
import './styles.css';
import { setFreshchatIdRequest } from '../../modules/api/calls';
import { getCurrentLanguage } from 'modules/common/LanguageToggle/selectors';

const FreeChat = ({ license }) => {
  const user = useSelector(authSelectors.getUser);
  const isAuthenticated = useSelector(authSelectors.getIsAuthenticated);
  const [script, setScript] = useState();
  const [chatId, setChatId] = useState();
  const isClientRole = useSelector(authSelectors.getIsClientRole);
  const lang = useSelector(getCurrentLanguage);

  useEffect(() => {
    let mounted = true;

    async function getScript() {
      const script = await loadScript('https://fusionmarkets.freshchat.com/js/widget.js', []);
      if (mounted) {
        setScript(script);
      }
    }
    getScript();

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (!script) {
      return;
    }

    if (!window.fcWidget || isEmpty(window.fcWidget)) {
      setChatId(null);
      return;
    }

    if (!isAuthenticated) {
      if (!chatId) {
        return;
      }
      window.fcWidget.destroy();
      window.fcWidget.user.clear();
      setChatId(null);
      return;
    }

    if (!user.email || !user.profile) {
      return;
    }

    if (!isClientRole) {
      if (chatId) {
        console.log('destroy chat');
        window.fcWidget.destroy();
        window.fcWidget.user.clear();
        setChatId(null);
      }
      return;
    }

    // prevention of rendering in any other iframe's
    /* eslint-disable-next-line no-restricted-globals */
    if (self !== top) {
      return;
    }

    if (chatId === user._id) {
      return;
    }

    setChatId(user._id);
    window.fcWidget.destroy();
    window.fcWidget.init({
      token: license,
      host: 'https://fusionmarkets.freshchat.com',
      locale: lang || 'en',
      tags: ['public', 'fusion'],
      externalId: user._id,
      ...(user.freshchatId && { restoreId: user.freshchatId }),
      email: user.email,
      firstName: get(user, 'profile.firstName'),
      lastName: get(user, 'profile.lastName'),
      config: {
        cssNames: {
          widget: 'custom_fc_frame',
          expanded: 'custom_fc_expanded',
        },
        headerProperty: {
          appName: 'Fusion Markets',
        },
      },
    });

    window.fcWidget.user.get((resp) => {
      let status = resp && resp.status;
      if (status !== 200) {
        window.fcWidget.on('user:created', (resp) => {
          let status = resp && resp.status,
            data = resp && resp.data;
          if (status === 200 && data.restoreId) {
            setFreshchatIdRequest({ userId: user._id, freshchatId: data.restoreId });
          }
        });
      }
    });
  }, [user, license, isClientRole, script, chatId, isAuthenticated, lang]);

  return null;
};

export default FreeChat;
