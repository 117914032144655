import purpleLogoFM from 'static/images/app/fm/logo/light/logo.svg';
import purpleLogoFMShort from 'static/images/app/fm/logo/light/logoShort.svg';
import backgroundFM from 'static/images/old/signUpBackground.png';

import { MIGRATION_DESTINATIONS_VALUES_FMX } from 'modules/userManagement/pages/UserDetails/constants';
import { THEME_NAME } from 'constants/theme';

const TESTING_CONFIG = {
  CURRENT_HUB_LABEL: 'Fusion Markets',
  CURRENT_HUB_THEME: 'FUSION_MARKETS',
  API_HOST: 'https://api.fusionmarkets-staging.com',
  AVAILABLE_LANGUAGES: ['en', 'th', 'ch', 'chm', 'id', 'jp', 'vn', 'es'],
  HUB_PREFIX: '',
  LOGO: {
    [THEME_NAME.LIGHT]: {
      origin: purpleLogoFM,
      short: purpleLogoFMShort,
    },
  },
  MARKETING_SITE_URLS: {
    ASIC: 'https://fusionmarkets-staging.com.au',
    VFSC: 'https://fusionmarkets-staging.com',
  },
  CURRENCY_CLOUD_BANK_ROUTING_CODE: {
    ach_routing_number: {
      name: 'ACH Payment',
      description: 'Accepts payments originating in the US up to $1.000.000 sent via ACH.',
    },
    bic_swift: {
      name: 'SWIFT Payment',
      description: 'Used for international wire payments or high-value transactions.',
    },
    wire_routing_number: {
      name: 'Fedwire Payment',
      description: 'Accepts payments originating in the US up to $1.000.000 sent via Fedwire.',
    },
    sort_code: {
      name: 'UK Faster Payment',
      description:
        'Accepts payments up to £1,000,000 originating in the UK. Funds typically arrive within a few minutes of being sent, 24/7.',
    },
    routing_code: {
      name: 'EFT Payment',
      description: 'For local Canadian payments on the EFT payment schema',
    },
    eur_priority_regular: {
      name: 'SEPA Payment',
      description:
        'For euro payments to anywhere in the European Union. as well as number of non-EU countries, similar to a domestic payment.',
    },
  },
  LOGIN_BACKGROUND: backgroundFM,
  MIGRATION_DESTINATIONS_VALUES: MIGRATION_DESTINATIONS_VALUES_FMX,
  HUBS: [
    {
      KEY: 'FUSION_MARKETS',
      LABEL: 'Fusion Markets',
      SIGN_IN_URL: '/auth/sign-in',
      SIGN_UP_URL: '/auth/sign-up',
    },
    {
      KEY: 'FUSION_MARKETS_X',
      LABEL: 'Fusion Markets X',
      SIGN_IN_URL: '/auth/sign-in',
      SIGN_UP_URL: '/auth/sign-up',
    },
  ],
  GOOGLE_TAG_MANAGER: {
    ID: 'GTM-TEST',
    THAI_ID: 'GTM-THAI-TEST',
  },
  ACCOUNT_TYPES: [
    {
      i18nValue: 'common:constants.accountTypes.ZERO',
      value: 'ZERO',
    },
    {
      i18nValue: 'common:constants.accountTypes.classic',
      value: 'Classic',
    },
    {
      i18nValue: 'common:constants.accountTypes.swapFree',
      value: 'SWAP_FREE',
    },
  ],
  SIGNIN_DISCLAIMER: 'disclaimerX',
  DEFAULT_DEPOSIT_METHOD: 'Praxis',
  MIGRATE_ACCOUNT_COUNTRIES: [
    'Singapore',
    'Malaysia',
    'United Kingdom of Great Britain and Northern Ireland',
    'Italy',
    'Germany',
    'South Africa',
    'Kenya',
    'Nigeria',
    'Australia',
  ],
  MIGRATE_ACCOUNT_COUNTRIES_HIDE: ['Australia'],
  HUB_NEED_MIGRATION_REQUEST: 'FUSION_MARKETS_X',
  UPGRADE_TO_PRO_COUNTRIES: ['Australia'],
  UPGRADE_TO_PRO_PAGE: 'http://fusionmarkets-staging.com/Trading/Fusion-Markets-Pro',
  CAPTCHA: {
    client: '6Lfvb1IaAAAAAITnV_0lXae8oDeeFmsoThV-Z3xq',
    clientV3: '6LdoY-opAAAAANCu5Uy_cYGmSdYLpGYo8bIlhA0G',
  },
  PLACES: {
    API_KEY: 'AIzaSyALo9YKPcvRp8iUDVgPum3EhO44LxlVjAA',
  },
  GOOGLE_OAUTH: {
    CLIENT_ID: '271439598679-jcthp66etombinu4tulbba6dk6g4tloj.apps.googleusercontent.com',
  },
  APPLE_SIGNIN: {
    CLIENT_ID: 'com.fusionmarkets-staging.sign-up-service',
    REDIRECT_URI: '/auth/sign-up',
  },
  FM_PLUS: new Set(['philhorner88@gmail.com', 'my3v11@yandex.ru']),
  TRANSLATIONS_NOT_ALLOWED_COUNTRY_CODES: new Set(['AU']),
  ZEPTO: {
    TEST: true,
    ALLOW_ZEPTO: [
      'philhorner88@gmail.com',
      'horner_sarah@hotmail.com',
      'fqfxfook@leadwizzer.com',
      'lackpmcr@solarunited.net',
      'dvswaquvzhl@canfga.org',
    ],
  },
  TWILIO: {
    ALLOW_IM: ['admin@example.com'],
  },
  MT4_LIVE2_ALLOWED_USERS: new Set(['admin@example.com']),
  HOTJAR: {
    ENABLED: false,
    ID: 3369381,
    SV: 6,
  },
  FINGERPRINT: {
    ENABLED: false, // Disable for now as it doesn't work anyway.
    API_KEY: 'ROjggEi34zGysBChqZLl',
  },
  FIREBASE: {
    apiKey: 'AIzaSyC7T9NimVOkhTwMgfP-kUjVnuw98Y7OVUo',
    authDomain: 'fusionmarkets.firebaseapp.com',
    projectId: 'fusionmarkets',
    storageBucket: 'fusionmarkets.appspot.com',
    messagingSenderId: '271439598679',
    appId: '1:271439598679:web:d7204f6d171cde9250bf12',
    measurementId: 'G-2Z3MPLQ7VM',
  },
  TINY_API_KEY: 'gu7w8juew10xetvvcqwclt2q0g76vkx98cbhymxfue3u2g5j',
  MAILCHIMP: {
    SUBSCRIPTION_URL:
      'https://fusionmarkets.us20.list-manage.com/subscribe?u=5e19c3262725b9c48b50569e0&id=b4ada10dc6',
  },
  SALES_TEAM_USERS: new Set([
    'shapovalovdenis1+400@gmail.com',
    'tnsborutflsrt+sale@bugfoo.com',
    'zhivkometlarov+sales@coherentsolutions.com',
  ]),
  SALES_REPORTS_USERS: new Set([
    'shapovalovdenis1+400@gmail.com',
    'tnsborutflsrt+sale@bugfoo.com',
    'zhivkometlarov+sales@coherentsolutions.com',
    'kurian.amaljose@gmail.com',
    'test_1+sales@yogirt.com',
  ]),
  TV_BLOG_POST_URL: {
    FUSION_MARKETS: 'https://fusionmarkets.com.au/posts/tradingview-subscription',
    FUSION_MARKETS_X: 'https://fusionmarkets.com/posts/tradingview-subscription',
  },
  DAY1_SIGN_URL: 'https://exchange.day1x.io',
  TV_SUBSCRIPTION_PLANS_URL: 'https://www.tradingview.com/pricing/',
  HELP_EMAIL: 'help@fusionmarkets.com',
  BRAZE: {
    API_KEY: '28264dc5-4c71-4d20-b107-fde7f0c0f346',
    API_URL: 'https://sdk.iad-07.braze.com',
  },
  HELP_TEAM_EMAIL: 'help@fusionmarkets.com',
};

export default TESTING_CONFIG;
