import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Page from 'modules/common/Page';
import Layout from '../../../../components/LayoutNew';
import MigrationForm from '../MigrationForm';
import TPAlert from 'components/TP-UI/TPAlert';
import TPGrid from 'components/TP-UI/TPGrid';

const Migration = ({ form, userName, hub, destinationHub, isPending, onSubmit }) => {
  const { t } = useTranslation('auth');
  return (
    <Page docTitle={t('welcomeMigration.title', { destinationHub })}>
      <Layout
        title={t('welcomeMigration.title', { destinationHub })}
        welcomeTitle={t('logInWelcome')}
        welcomeDescription={t('logInWelcomeDescription')}>
        <TPGrid container spacing={3}>
          <TPGrid item xs={12}>
            <TPAlert variant="info" hideIcon>
              {userName
                ? t('welcomeMigration.messageWithName', { userName, destinationHub })
                : t('welcomeMigration.message', { destinationHub })}
            </TPAlert>
          </TPGrid>
          <TPGrid item xs={12}>
            <MigrationForm form={form} hub={hub} onSubmit={onSubmit} isPending={isPending} />
          </TPGrid>
        </TPGrid>
      </Layout>
    </Page>
  );
};

Migration.propTypes = {
  form: PropTypes.string,
  userName: PropTypes.string,
  destinationHub: PropTypes.string,
  hub: PropTypes.string,
  onSubmit: PropTypes.func,
  isPending: PropTypes.bool,
};

export default Migration;
