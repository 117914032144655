export default (isLoading) => ({
  responsive: true,
  maintainAspectRatio: false,
  tooltips: {
    enabled: true,
  },
  hover: {
    mode: 'nearest',
    intersect: true,
  },
  scales: {
    xAxes: [
      {
        type: 'time',
        distribution: 'series',
        // offset: false,
        unit: 'day',
        time: {
          displayFormats: {
            day: 'DD/MM/YYYY',
          },
        },
        gridLines: {
          display: false,
        },
        ticks: {
          display: !isLoading,
          fontFamily: 'Lato, sans-serif',
        },
      },
    ],
    yAxes: [
      {
        display: true,
        scaleLabel: {
          display: false,
        },
      },
    ],
  },
});
