import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TPModal } from 'components/TP-UI/TPModal';

const OnboardingModal = ({ open, onSkip, onStart }) => {
  const { t } = useTranslation('onboarding');

  const buttons = useMemo(
    () => [
      {
        label: t('buttons.start'),
        primary: true,
        onClick: onStart,
        // DO NOT REMOVE! id="welcome-got-it-button" is a conversion tracking key
        id: 'welcome-got-it-button',
      },
      {
        label: t('buttons.skip'),
        secondary: true,
        onClick: onSkip,
      },
    ],
    [t, onSkip, onStart],
  );

  return (
    <TPModal
      value={open}
      title={t('welcome.title')}
      buttons={buttons}
      onClose={onSkip}
      text={t('welcome.message')}
    />
  );
};

OnboardingModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSkip: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
};

export default OnboardingModal;
