export const SYMBOLS = [
  'AUDCAD',
  'AUDCHF',
  'AUDHUF',
  'AUDJPY',
  'AUDNZD',
  'AUDSGD',
  'AUDUSD',
  'CADCHF',
  'CADJPY',
  'CHFDKK',
  'CHFHUF',
  'CHFJPY',
  'CHFNOK',
  'CHFPLN',
  'CHFSEK',
  'CHFSGD',
  'EURAUD',
  'EURCAD',
  'EURCHF',
  'EURCZK',
  'EURDKK',
  'EURGBP',
  'EURHKD',
  'EURHUF',
  'EURILS',
  'EURJPY',
  'EURMXN',
  'EURNOK',
  'EURNZD',
  'EURPLN',
  'EURRUB',
  'EURSEK',
  'EURSGD',
  'EURTRY',
  'EURUSD',
  'EURZAR',
  'GBPAUD',
  'GBPCAD',
  'GBPCHF',
  'GBPDKK',
  'GBPHUF',
  'GBPJPY',
  'GBPMXN',
  'GBPNOK',
  'GBPNZD',
  'GBPPLN',
  'GBPSEK',
  'GBPSGD',
  'GBPTRY',
  'GBPUSD',
  'GBPZAR',
  'HKDJPY',
  'MXNJPY',
  'NOKJPY',
  'NOKSEK',
  'NZDCAD',
  'NZDCHF',
  'NZDHUF',
  'NZDJPY',
  'NZDSGD',
  'NZDUSD',
  'SEKJPY',
  'SGDJPY',
  'TRYJPY',
  'USDBRL',
  'USDCAD',
  'USDCHF',
  'USDCNH',
  'USDCZK',
  'USDDKK',
  'USDHKD',
  'USDHUF',
  'USDIDR',
  'USDILS',
  'USDINR',
  'USDJPY',
  'USDKRW',
  'USDMXN',
  'USDNOK',
  'USDPLN',
  'USDRUB',
  'USDSEK',
  'USDSGD',
  'USDTHB',
  'USDTRY',
  'USDZAR',
  'XAGEUR',
  'XAGUSD',
  'XAUAUD',
  'XAUCNH',
  'XAUEUR',
  'XAUTRY',
  'XAUUSD',
  'ZARJPY',

  '3M',
  'Accenture',
  'ActivisionBlizzard',
  'ADAUSD',
  'Adobe',
  'ADP',
  'Airbnb',
  'AlibabaGroup',
  'Alphabet-A',
  'Alphabet-C',
  'Amazon',
  'AMD',
  'AmericanExpress',
  'Amgen',
  'Apple',
  'AppliedMaterials',
  'AT&T',
  'Atlassian',
  'AUS200',
  'AVAXUSD',
  'Baidu',
  'BankofAmericaCorp',
  'BCHUSD',
  'Berkshire',
  'BeyondMeat',
  'BlackRock',
  'BlockInc',
  'BNBUSD',
  'Boeing',
  'Booking',
  'Broadcom',
  'BTCUSD',
  'CA60',
  'Caterpillar',
  'CharlesSchwab',
  'Charter',
  'Chevron',
  'CHINAH',
  'Cisco',
  'Citigroup',
  'Coca-Cola',
  'COFARA',
  'COFROB',
  'Coinbase',
  'Comcast',
  'CORN',
  'CostcoWholesale',
  'COTTON',
  'CVSHealth',
  'DocuSign',
  'DOGEUSD',
  'Doordash',
  'DOTUSD',
  'Dow',
  'E35',
  'eBay',
  'ElectronicArts',
  'EOSUSD',
  'ETHUSD',
  'EUSTX50',
  'ExxonMobil',
  'Ford',
  'FRA40',
  'GeneralElectric',
  'GeneralMotors',
  'GER40',
  'GileadSciences',
  'GoldmanSachs',
  'HK50',
  'HomeDepot',
  'Honeywell',
  'IBM',
  'Intel',
  'Intuit',
  'IntuitiveSurgical',
  'Johnson&Johnson',
  'JPMorganChase',
  'JPN225',
  'LNKUSD',
  'LTCUSD',
  'LucidGroup',
  'Lyft',
  'Mastercard',
  'MATICUSD',
  'McDonalds',
  'Medtronic',
  'Merck',
  'Meta',
  'MicronTechnology',
  'Microsoft',
  'MorganStanley',
  'NAS100',
  'Netflix',
  'NETH25',
  'Nike',
  'NIO',
  'NVIDIA',
  'OJ',
  'SOLUSD',
  'Oracle',
  'PayPal',
  'Pepsi',
  'Pfizer',
  'PhilipMorrisInternational',
  'Pinterest',
  'Procter&Gamble',
  'Qualcomm',
  'QuantumScape',
  'RobinhoodMarkets',
  'Roku',
  'S&PGlobal',
  'Salesforce',
  'ServiceNow',
  'Shopify',
  'Snapchat',
  'Snowflake',
  'SOYBEAN',
  'Spotify',
  'Starbucks',
  'SUGAR',
  'SUGARRAW',
  'Target',
  'Tesla',
  'TexasInstruments',
  'ThermoFisherScientific',
  'TMEGroup',
  'Toyota',
  'Travelers',
  'TSMC',
  'Twilio',
  'Uber',
  'UK100',
  'UKCOCOA',
  'UnionPacific',
  'UnitedHealth',
  'UnitedParcelService',
  'US30',
  'US500',
  'USCOCOA',
  'USDX',
  'Verizon',
  'VirginGalactic',
  'Visa',
  'Walgreens',
  'Walmart',
  'WaltDisney',
  'WellsFargo',
  'WHEAT',
  'XALUSD',
  'XBRUSD',
  'XCUUSD',
  'XLMUSD',
  'XNGUSD',
  'XNIUSD',
  'XPBUSD',
  'XPDUSD',
  'XPTUSD',
  'XRPUSD',
  'XTIUSD',
  'XZNUSD',
  'UKGILT',
  'UST10Y',
  'UST05Y',
];
