import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    spacing: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    table: {
      marginTop: theme.spacing(2),
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    button: {
      minWidth: '135px',
    },
  }),
  { name: 'Tables' },
);

export default useStyles;
