import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core';
import TPTypography from 'components/TP-UI/TPTypography';
import TPGrid from 'components/TP-UI/TPGrid';
import TPImage from 'components/TP-UI/TPImage';

import useStyles from './styles';

const NoMatches = ({ text }) => {
  const classes = useStyles();
  const { images } = useTheme();

  return (
    <TPGrid
      className={classes.container}
      container
      direction="column"
      justifyContent="center"
      alignItems="center">
      <TPGrid item>
        <TPImage className={classes.image} src={images.common.searchPlaceholderImg} alt={text} />
      </TPGrid>
      <TPGrid item>
        <TPTypography variant="body1">{text}</TPTypography>
      </TPGrid>
    </TPGrid>
  );
};

NoMatches.propTypes = {
  text: PropTypes.string.isRequired,
};

export default NoMatches;
