import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    container: {
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'PreQuizModal' },
);
