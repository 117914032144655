import { handleActions } from 'redux-actions';
import * as actionCreators from '../actions';

const defaultState = {
  status: true,
  statusText: '',
  isLoading: false,
};

export default handleActions(
  {
    [actionCreators.postDayxPublicRequest](state) {
      return { ...state, statusText: '', isLoading: true };
    },
    [actionCreators.postDayxPublicFail](state) {
      return {
        ...state,
        status: false,
        statusText: 'An error occurred while submitting the form.',
        isLoading: false,
      };
    },
    [actionCreators.postDayxPublicSuccess](state) {
      return {
        ...state,
        status: true,
        statusText:
          "Thank you for joining the Day1 waitlist. We'll be in touch closer to launch to provide you with early access",
        isLoading: false,
      };
    },

    [actionCreators.dayxStatusChanged]() {
      return defaultState;
    },
  },
  defaultState,
);
