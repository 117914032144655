import api from '..';

export const mt4LiveGetCountriesRequest = () => {
  return api.get('/mt4Live-countries/country');
};

export const mt4LiveAddCountryRequest = ({ countryCode }) => {
  return api.post('/mt4Live-countries/country', { countryCode });
};

export const mt4LiveRemoveCountryRequest = ({ countryCode }) => {
  return api.delete(`/mt4Live-countries/country/${countryCode}`);
};
