import React from 'react';
import formatFullDate from 'utils/formatFullDate';
import { Routes } from 'constants/routeConstants';
import formatDateToPassedDays from 'utils/formaDateToPassedDays';
import TPLink from 'components/TP-UI/TPLink';
import Status from 'modules/common/Status';

export const FOLLOW_UPS_FORM = 'FOLLOW_UPS_FORM';

export const SORT_FIELDS = ['dateAsc', 'statusAsc'];

export const DEFAULT_HIDDEN_COLUMNS = ['callStatus', 'lastContact'];

export const DEFAULT_SORTING = {
  sortBy: 'date',
  sortAsc: false,
};

export const SORTING_MAPPER = {
  date: 'updatedAt',
  'verification.status': 'status',
};

export const DEFAULT_COLUMNS = [
  {
    label: 'Username',
    dataKey: 'userName',
    default: true,
    width: '200px',
  },
  {
    label: 'Account Status',
    dataKey: 'verification.status',
    default: true,
    sortable: true,
    renderItem: (item) => <Status value={item?.verification?.status ?? ''} />,
    width: '130px',
  },
  {
    label: 'Email',
    dataKey: 'email',
    default: true,
    width: '200px',
  },
  {
    label: 'Date',
    dataKey: 'date',
    sortable: true,
    default: true,
    format: formatFullDate,
    width: '200px',
  },
  {
    label: 'Country',
    dataKey: 'country',
    default: true,
    width: '100px',
  },
  {
    label: 'Call Status',
    dataKey: 'callStatus',
    width: '100px',
  },
  {
    label: 'Last Contact',
    dataKey: 'lastContact',
    format: formatDateToPassedDays,
    width: '200px',
  },
  {
    width: '140px',
    textAlign: 'right',
    default: true,
    renderItem: (item) => {
      return (
        <TPLink path={`${Routes.USER_MANAGEMENT_DETAILS}/${item.userId}`}>Show Details</TPLink>
      );
    },
  },
];

export const LAST_CONTACT_RANGES = [
  { value: '[0, 4]', label: '0 - 4 days' },
  { value: '[5, 9]', label: '5 - 9 days' },
  { value: '[10, 14]', label: '10 - 14 days' },
  { value: '[15, 20]', label: '15 - 20 days' },
  { value: '[21, -1]', label: '21 days +' },
];

export const NEED_TO_PARSE = ['lastContact'];

export const FOLLOW_UPS_PRESET_FILTERS_TYPE = 'FOLLOW_UPS';
