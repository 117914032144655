import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Typography } from '@material-ui/core';

import { getMultipleFieldsData } from './helpers';
import loader_squares from 'static/images/old/loader_squares_secondary.gif';

const TableTextField = ({
  classes,
  label,
  dataLabel,
  dataIcon,
  tableItem,
  formatFunc,
  formatFuncArray,
  className,
  isLoading,
}) => {
  const { fieldLabels, fieldValues } = useMemo(
    () => getMultipleFieldsData(label, dataLabel, tableItem),
    [label, dataLabel, tableItem],
  );

  return (
    <div className={classNames([classes.item, className])}>
      {fieldValues.map((fieldValue, key) => {
        if (fieldValue === undefined) {
          return null;
        }
        return (
          <div key={key}>
            <Typography className={classes.label}>{fieldLabels[key]}</Typography>
            <div className={classes.dataText}>
              {dataIcon && get(tableItem, dataIcon) && (
                <div className={classes.dataIconContainer}>
                  <img className={classes.dataIcon} alt="flag" src={get(tableItem, dataIcon)} />
                </div>
              )}
              {isLoading ? (
                <img alt="" src={loader_squares} className={classes.loader} />
              ) : (
                <div key={key}>
                  {formatFunc
                    ? formatFunc(fieldValue)
                    : formatFuncArray && formatFuncArray.length && formatFuncArray[key]
                    ? formatFuncArray[key](fieldValue)
                    : fieldValue}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

TableTextField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  dataLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  tableItem: PropTypes.object.isRequired,
  formatFunc: PropTypes.func,
  formatFuncArray: PropTypes.array,
  className: PropTypes.string,
  route: PropTypes.string,
  getRedirectRoute: PropTypes.func,
};

export default withStyles(styles)(TableTextField);
