import { combineActions, handleActions } from 'redux-actions';

import * as actionCreators from '../actions';
const defaultState = '';

export default handleActions(
  {
    [combineActions(
      actionCreators.paymentsDepositIsignthisSuccess,
      actionCreators.paymentsDepositZotapaySuccess,
      actionCreators.paymentsDepositPaymentAsiaSuccess,
    )](state, action) {
      return action.payload.amount;
    },
  },
  defaultState,
);
