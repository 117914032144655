import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    trendingContainer: {
      display: 'inline-flex',
      borderRadius: '50%',
      fontSize: '0.75em',
      padding: '0.3333em',
    },
    up: {
      ...theme.trending.up,
    },
    down: {
      ...theme.trending.down,
    },
    flat: {
      ...theme.trending.flat,
    },
    smallSize: {
      ...theme.trending.smallSize,
    },
    mediumSize: {
      ...theme.trending.mediumSize,
    },
    largeSize: {
      ...theme.trending.largeSize,
    },
    extraLargeSize: {
      ...theme.trending.extraLargeSize,
    },
  }),
  { name: 'TPTrendingIcon' },
);
