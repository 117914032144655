import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    warning: {
      ...theme.textWarning.root,
    },
    mediumSize: {
      ...theme.textWarning.mediumSize,
    },
  }),
  { name: 'TPWarningMessage' },
);
