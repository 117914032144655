import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    container: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    root: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
    },
  }),
  { name: 'TPPagination' },
);
