const styles = () => ({
  pagination: {
    display: 'flex',
    alignItems: 'baseline',
  },
  arrowButton: {
    background: 'none',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    color: '#8E96B3',
    '&:hover': {
      color: '#767d96',
    },
    fontSize: 15,
    paddingTop: 5,
    alignSelf: 'center',
  },
  arrowButtonDisabled: {
    color: '#c4c6ce',
    pointerEvents: 'none',
  },
  dots: {
    fontSize: '14px',
    lineHeight: '17px',
    color: '#2727C5',
  },
  pageSizeContainer: {
    color: '#7777C1',
    fontSize: '14px',
    lineHeight: '17px',
    marginLeft: 10,
    fontWeight: 300,
  },
  pageSizeSelect: {
    marginLeft: 5,
    marginRight: 5,
    fontWeight: 400,
  },
});

export default styles;
