const OverridesMuiTabs = {
  root: {
    border: 'none',
    backgroundColor: 'transparent',
    minHeight: 'auto',
  },
  indicator: {
    display: 'none',
  },
  scroller: {
    scrollbarWidth: 'none',
    marginBottom: '0!important',
  },
  flexContainer: {
    flexWrap: 'wrap',
    gap: 16,
  },
};

export default OverridesMuiTabs;
