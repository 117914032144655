import React, { useCallback } from 'react';
import { reset } from 'redux-form';

import QuizModal from '../../common/Quiz';
import { useDispatch, useSelector } from 'react-redux';
import {
  PRO_QUIZ_FORM,
  PRO_QUIZ_MODAL,
  RETAIL_QUIZ_FORM,
  RETAIL_QUIZ_MODAL,
} from 'modules/profile/constants';
import { useTranslation } from 'react-i18next';
import { ACCOUNT_PLANS } from 'constants/accountPlans';
import InfoTip from 'modules/common/InfoTip';
import {
  currentQuestionSelector,
  progressIndexSelector,
  questionsSelector,
} from 'modules/profileQuiz/selectors';
import { accountPlanSelector } from 'modules/auth/selectors';
import { createPendingSelector } from 'modules/api/selectors';
import {
  changeQuizCurrentQuestion,
  changeQuizProgress,
  passQuizRequest,
  resetQuizProgress,
} from 'modules/profileQuiz/actions';
import { closeModal } from 'components/TP-UI/TPModal/actions';
import PreQuizModal from 'modules/profileQuiz/containers/PreQuizModalContainer';

const ProfileQuizContainer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const questions = useSelector(questionsSelector);
  const currentQuestionIndex = useSelector(currentQuestionSelector);
  const progressIndex = useSelector(progressIndexSelector);
  const selectedPlan = useSelector(accountPlanSelector);
  const loading = useSelector(createPendingSelector(passQuizRequest));
  const isProPlan = selectedPlan === ACCOUNT_PLANS.PRO;

  const titleTarget = isProPlan ? ACCOUNT_PLANS.PRO : ACCOUNT_PLANS.RETAIL;
  const title = t(`quiz:${titleTarget}.page.title`);
  const titleEndAdornment = (
    <InfoTip iconSize="medium" tooltipContent={t(`quiz:${titleTarget}.tooltip.description`)} />
  );

  const quizModalName = isProPlan ? PRO_QUIZ_MODAL : RETAIL_QUIZ_MODAL;
  const quizForm = isProPlan ? PRO_QUIZ_FORM : RETAIL_QUIZ_FORM;

  const handleNext = useCallback(() => {
    const nextQuestionIndex = currentQuestionIndex + 1;

    dispatch(changeQuizCurrentQuestion(nextQuestionIndex));

    if (progressIndex < nextQuestionIndex) {
      dispatch(changeQuizProgress(nextQuestionIndex));
    }
  }, [currentQuestionIndex, dispatch, progressIndex]);

  const handleSubmit = useCallback(
    (values) => {
      if (currentQuestionIndex === questions.length - 1) {
        dispatch(changeQuizProgress(questions.length));
        dispatch(passQuizRequest(values));
      } else if (currentQuestionIndex < questions.length - 1) {
        handleNext(currentQuestionIndex);
      }
    },
    [currentQuestionIndex, handleNext, dispatch, questions.length],
  );

  const handlePrevious = useCallback(() => {
    dispatch(changeQuizCurrentQuestion(currentQuestionIndex - 1));
  }, [currentQuestionIndex, dispatch]);

  const handleCancel = useCallback(() => {
    dispatch(closeModal(quizModalName));
    dispatch(reset(quizForm));
    dispatch(resetQuizProgress());
  }, [dispatch, quizForm, quizModalName]);

  return (
    <>
      <PreQuizModal />
      <QuizModal
        currentQuestionIndex={currentQuestionIndex}
        form={quizForm}
        loading={loading}
        modalName={quizModalName}
        onNext={handleNext}
        onPrevious={handlePrevious}
        onSubmit={handleSubmit}
        progressIndex={progressIndex}
        questions={questions}
        onCancel={handleCancel}
        title={title}
        titleEndAdornment={titleEndAdornment}
      />
    </>
  );
};

export default ProfileQuizContainer;
