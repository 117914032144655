import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import useStyles from './styles';

const TPLanguageIcon = ({ label, icon }) => {
  const classes = useStyles();

  return (
    <>
      <Avatar className={classes.lngIcon} alt={label} src={icon} />
    </>
  );
};

TPLanguageIcon.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
};

export default TPLanguageIcon;
