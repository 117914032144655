import { combineReducers } from 'redux';

import clients from '../pages/Clients/reducers';
import myClients from '../pages/MyClients/reducers';
import userDetails from '../pages/UserDetails/reducers';
import employees from '../pages/Employees/reducers';
import blockedUsers from '../pages/BlockedUsers/reducers';
import birthdays from '../pages/ClientsBirthdays/reducers';
import followUps from '../pages/FollowUps/reducers';
import operationNotifications from '../pages/Notifications/reducers';
import vpsUsers from '../pages/VPSUsers/reducers';
import fusionPlusUsers from '../pages/FusionPlusUsers/reducers';
import tvSubscription from '../pages/TVSubscriptions/reducers';
import sales from '../pages/Sales/reducers';

const reducer = combineReducers({
  clients,
  myClients,
  userDetails,
  employees,
  blockedUsers,
  birthdays,
  followUps,
  operationNotifications,
  vpsUsers,
  fusionPlusUsers,
  tvSubscription,
  sales,
});

export default reducer;
