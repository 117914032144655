import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'modules/reduxNavigation/actions';
import { Trans, useTranslation } from 'react-i18next';

import TPLink from 'components/TP-UI/TPLink';
import TPModal from 'components/TP-UI/TPModal';
import { closeModal } from 'components/TP-UI/TPModal/actions';
import { ACCOUNT_PLANS } from 'constants/accountPlans';
import { DATE_FORMAT_FULL } from 'constants/index';
import { Routes } from 'constants/routeConstants';
import { getUser, targetUserQuizSelector } from 'modules/auth/selectors';
import formatDate, { editDate } from 'utils/formatDate';

import { BLOCK_DAYS_AMOUNT } from '../../constants/blockedDaysAmount';
import verificationModalNames from '../../constants/verificationModalNames';
import QuizModalContent from '../QuizModalContent';
import { PRE_QUIZ_MODAL } from 'modules/profileQuiz/constants';
import { useTheme } from '@material-ui/core';

const QuizBlockedModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('quiz');

  const targetQuizProgress = useSelector(targetUserQuizSelector);
  const { selectedPlan } = useSelector(getUser);
  const { blockedDate } = targetQuizProgress || {};

  const endDate = editDate(blockedDate, BLOCK_DAYS_AMOUNT, 'day');
  const formattedEndDate = formatDate(endDate, DATE_FORMAT_FULL);

  const { quiz } = useTheme();
  const imageConfig = quiz.quizBlockedModal.avatar;

  const generateTranslatedInfoText = useCallback(
    (selectedPlan) => {
      if (selectedPlan === ACCOUNT_PLANS.PRO) {
        return (
          <Trans i18nKey="secondFailModal.pro.infoText" ns="quiz">
            Sadly you didn&apos;t pass the quiz on the second attempt. If you are still interested
            in becoming a Fusion Pro client, please reach out to our support team at{' '}
            <TPLink path="mailto:help@fusionmarkets.com">help@fusionmarkets.com</TPLink> and we can
            be of further assistance.
          </Trans>
        );
      }

      return t(`quizBlockedModal.${ACCOUNT_PLANS.RETAIL}.description`, {
        date: formattedEndDate ?? '',
      });
    },
    [t, formattedEndDate],
  );

  const handleClose = useCallback(() => {
    dispatch(closeModal(verificationModalNames.QUIZ_BLOCKED_MODAL));
    dispatch(closeModal(PRE_QUIZ_MODAL));
    dispatch(push(Routes.MY_ACCOUNTS));
  }, [dispatch]);

  const title = useMemo(
    () => (selectedPlan === ACCOUNT_PLANS.PRO ? t('pro.page.title') : t('retail.page.title')),
    [t, selectedPlan],
  );

  return (
    <TPModal
      modalName={verificationModalNames.QUIZ_BLOCKED_MODAL}
      title={title}
      onClose={handleClose}>
      <QuizModalContent
        imageConfig={imageConfig}
        text={generateTranslatedInfoText(selectedPlan || ACCOUNT_PLANS.RETAIL)}
        title={t(`quizBlockedModal.${selectedPlan || ACCOUNT_PLANS.RETAIL}.title`)}
      />
    </TPModal>
  );
};

export default QuizBlockedModal;
