import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';

import useStyles from './styles';

const TPCurrency = ({ label, flag }) => {
  const classes = useStyles();

  return label || flag ? (
    <span className={classes.root}>
      {flag ? (
        <Avatar variant="square" className={classes.flagIcon} alt={label} src={flag} />
      ) : null}
      {label || ''}
    </span>
  ) : null;
};

TPCurrency.propTypes = {
  label: PropTypes.string,
  flag: PropTypes.string,
};

export default TPCurrency;
