import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  () => ({
    nav: {
      width: '100%',
    },
    menuList: {
      padding: 0,
    },
  }),
  { name: 'TPNavMenuList' },
);

export default useStyles;
