import { UPLOADER_FILE_TYPE } from 'components/TP-UI/TPFileUploader/constants';

export const EVIDENCE_FILE_TYPES = [
  UPLOADER_FILE_TYPE.JPEG,
  UPLOADER_FILE_TYPE.JPG,
  UPLOADER_FILE_TYPE.PDF,
  UPLOADER_FILE_TYPE.PNG,
];

export const EVIDENCE_UPLOAD_SUCCESS_MODAL = 'EVIDENCE_UPLOAD_SUCCESS_MODAL';

export const EVIDENCE_UPLOAD_TYPES = {
  TRADING_STATEMENT: 'TRADING_STATEMENT',
  TRADING_STATEMENT_PRO: 'TRADING_STATEMENT_PRO',
};

export const EVIDENCE_UPLOAD_TYPE_SELECTION_FORM = 'EVIDENCE_UPLOAD_TYPE_SELECTION_FORM';

export const MAX_EVIDENCE_FILES_NUMBER = 4;

export const TRADING_STATEMENT_EVIDENCE_FORM = 'TRADING_STATEMENT_EVIDENCE_FORM';

export const UPLOAD_EVIDENCE_STATUSES = {
  INITIAL: 'INITIAL',
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};
