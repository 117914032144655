import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Layout from '../../../../components/LayoutNew';
import TPTypography from 'components/TP-UI/TPTypography';
import TPLink from 'components/TP-UI/TPLink';
import Page from 'modules/common/Page';
import SignInForm from '../SignInForm';
import config from 'config';
import { Routes } from 'constants/routeConstants';

import useStyles from './styles';

const SignIn = ({
  form,
  initialValues,
  isPending,
  internal = false,
  showCaptcha,
  onAppleSignupSuccess,
  onGoogleSigninSuccess,
  onSubmit,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('auth');
  const { CURRENT_HUB_LABEL } = config;

  return (
    <Page
      docTitle={t('signIn.documentTitle', { company: CURRENT_HUB_LABEL })}
      docMeta={t('signIn.documentDescription')}>
      <Layout
        title={t('logIn')}
        welcomeTitle={t('logInWelcome')}
        welcomeDescription={t('logInWelcomeDescription')}>
        <SignInForm
          form={form}
          initialValues={initialValues}
          onSubmit={onSubmit}
          isPending={isPending}
          internal={internal}
          showCaptcha={showCaptcha}
          onAppleSignupSuccess={onAppleSignupSuccess}
          onGoogleSigninSuccess={onGoogleSigninSuccess}
        />
        <div className={classes.customLinkWrapper}>
          <TPTypography variant="body1">
            {t('notAMemberYet')} <TPLink to={Routes.SIGNUP}>{t('buttons.signUp')}</TPLink>
          </TPTypography>
        </div>
      </Layout>
    </Page>
  );
};

SignInForm.propTypes = {
  form: PropTypes.string.isRequired,
  isPending: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showCaptcha: PropTypes.bool,
};

export default SignIn;
