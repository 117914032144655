import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useStyles from './styles';

const TPDocument = ({ children, rows, fullWidth }) => {
  const classes = useStyles({ rows });
  return (
    <div className={classnames(classes.root, [{ [classes.fullWidth]: fullWidth }])}>{children}</div>
  );
};

TPDocument.propTypes = {
  children: PropTypes.node,
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fullWidth: PropTypes.bool,
};

export default TPDocument;
