export const smallCell = () => ({
  item: { minWidth: '108px' },
});
export const limitedResponsiveMediumCell = () => ({
  item: {
    width: '120px',
    '@media (min-width:400px) and (max-width:1365px)': {
      width: 'auto',
    },
    '@media (min-width:1366px)': {
      width: '120px',
    },
    '@media (min-width:1440px)': {
      width: '160px',
    },
  },
});
export const normalCell = () => ({
  item: { minWidth: '150px' },
});

export const noPadding = () => ({
  item: { paddingLeft: '5px', paddingRight: '5px' },
});
export const limitedCell = () => ({
  item: { maxWidth: '150px' },
});
export const accountNameLimitedCell = () => ({
  item: { maxWidth: '208px', minWidth: '208px' },
});
export const accountInfoCell = () => ({
  item: {
    paddingLeft: '5px',
    paddingRight: '5px',
    maxWidth: '330px',
    minWidth: '330px',
    '@media (min-width:1600px)': {
      paddingRight: '20px',
    },
  },
});
export const smallLimitedCell = () => ({
  item: { width: '140px' },
});
export const normalLimitedCell = () => ({
  item: { width: '180px' },
});
export const mediumLimitedCell = () => ({
  item: { width: '220px' },
});
export const hugeLimitedCell = () => ({
  item: {
    width: '420px',
    '@media (max-width:680px)': {
      width: '280px',
    },
  },
});
export const fixedCell = () => ({
  item: {
    width: '158px',
    overflowX: 'hidden',
    '@media (min-width:1680px)': {
      width: '250px',
    },
  },
});
export const countryCell = () => ({
  item: {
    width: '100px',
    overflowX: 'hidden',
    '@media (min-width:1680px)': {
      width: '150px',
    },
  },
});
export const percent20 = () => ({
  item: {
    width: '20%',
    overflowX: 'hidden',
    '@media (max-width:800px)': {
      width: '150px',
    },
  },
});
export const percent40 = () => ({
  item: {
    width: '40%',
    overflowX: 'hidden',
    '@media (max-width:800px)': {
      width: '150px',
    },
  },
});
export const percent80 = () => ({
  item: {
    width: '80%',
    overflowX: 'hidden',
    '@media (max-width:800px)': {
      width: '150px',
    },
  },
});
export const percent60 = () => ({
  item: {
    width: '60%',
    overflowX: 'hidden',
    '@media (max-width:800px)': {
      width: '400px',
    },
  },
});
export const alignCenter = () => ({ item: { paddingLeft: '0px', textAlign: 'center' } });

export const responsiveCell = () => ({
  item: {
    '@media (max-width:480px)': {
      minWidth: '120px',
    },
  },
});

export const bigLimitedCell = () => ({
  item: { width: '300px' },
});

export const capitalized = () => ({
  item: { textTransform: 'capitalize' },
});

export const mobileSmallCell = () => ({
  item: {
    padding: '17px 0px',
    '@media (min-width:640px)': {
      padding: '17px 15px',
    },
    '@media (min-width:1366px)': {
      padding: '22px 10px',
    },
    '@media (max-width:640px)': {
      maxWidth: '80px',
      minWidth: '80px',
    },
  },
});
