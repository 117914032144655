import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import PrimaryButton from 'modules/common/PrimaryButton';

const TableTwoPrimaryButtonsField = ({
  classes,
  leftLabel,
  rightLabel,
  onLeftClick,
  onRightClick,
  tableItem,
  showEmptyField,
}) => {
  return (
    <div className={classes.container}>
      {!showEmptyField(tableItem) && (
        <>
          <PrimaryButton
            color="secondary"
            label={leftLabel}
            className={classes.button}
            onClick={() => onLeftClick(tableItem)}
          />
          <PrimaryButton
            color="primary"
            label={rightLabel}
            className={classes.button}
            onClick={() => onRightClick(tableItem)}
          />
        </>
      )}
    </div>
  );
};

TableTwoPrimaryButtonsField.propTypes = {
  leftLabel: PropTypes.string.isRequired,
  rightLabel: PropTypes.string.isRequired,
  onLeftClick: PropTypes.func.isRequired,
  onRightClick: PropTypes.func.isRequired,
  tableItem: PropTypes.object.isRequired,
  showEmptyField: PropTypes.func,
};

export default withStyles(styles)(TableTwoPrimaryButtonsField);
