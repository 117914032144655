import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      gap: theme.spacing(2),
      ...theme.fileUploader.root,
    },
    fullWidth: {
      width: '100%',
    },
    fileAccepted: {
      ...theme.fileUploader.fileAccepted,
    },
    fileRejected: {
      ...theme.fileUploader.fileRejected,
    },
    buttonsContainer: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: theme.spacing(2),
      '@media(max-width): 420px': {
        flexDirection: 'column',
      },
    },
    errorContainer: {
      minHeight: theme.textError.root.minHeight,
    },
  }),
  { name: 'TPFileUploader' },
);
