import { handleActions } from 'redux-actions';

import {
  getDashboardOverviewDataSuccess,
  getDashboardOverviewDataFail,
  getDashboardStatsCustomizationDataSuccess,
  getDashboardStatsCustomizationDataFail,
  saveDashboardStatsCustomizationDataSuccess,
  saveDashboardStatsCustomizationDataFail,
  getDashboardGraphDataSuccess,
  getDashboardGraphDataFail,
  getDashboardTotalAndGraphDataSuccess,
  getDashboardTotalAndGraphDataFail,
  getActiveUsersGraphDataSuccess,
  getDashboardComparisonGraphDataSuccess,
  getDashboardComparisonGraphDataFail,
} from '../actions';

const defaultState = {
  metrics: null,
  graphData: [],
  comparisonGraphData: [],
  stats: {},
  activeUsersGraphData: [],
};

export default handleActions(
  {
    [getDashboardOverviewDataSuccess](state, action) {
      return { ...state, metrics: action.response.data };
    },
    [getDashboardOverviewDataFail](state) {
      return { ...state, metrics: null };
    },
    [getDashboardStatsCustomizationDataSuccess](state, action) {
      return { ...state, stats: action.response.data.metrics };
    },
    [getDashboardStatsCustomizationDataFail](state) {
      return state;
    },
    [saveDashboardStatsCustomizationDataSuccess](state, action) {
      return { ...state, stats: action.response.data.metrics };
    },
    [saveDashboardStatsCustomizationDataFail](state) {
      return state;
    },
    [getDashboardGraphDataSuccess](state, action) {
      return { ...state, graphData: action.response.data };
    },
    [getActiveUsersGraphDataSuccess](state, action) {
      return { ...state, activeUsersGraphData: action.response.data };
    },
    [getDashboardGraphDataFail](state) {
      return state;
    },
    [getDashboardComparisonGraphDataSuccess](state, action) {
      return { ...state, comparisonGraphData: action.response.data };
    },
    [getDashboardComparisonGraphDataFail](state) {
      return state;
    },
    [getDashboardTotalAndGraphDataSuccess](state, action) {
      return {
        ...state,
        metrics: action.response.data.total,
        graphData: action.response.data.graph,
      };
    },
    [getDashboardTotalAndGraphDataFail](state) {
      return state;
    },
  },
  defaultState,
);
