import React, { useMemo } from 'react';
import TPModal from 'components/TP-UI/TPModal';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { checkNonLatin, required } from 'utils/validation/fieldValidationRules';
import TPAutocomplete from 'components/TP-UI/TPAutocomplete';
import PropTypes from 'prop-types';
import TPGrid from 'components/TP-UI/TPGrid';
import FormError from 'modules/common/FormError';
import { REDUX_FORM_DEFAULT_CONFIG } from 'constants/form';

const TPPresetFiltersDeleteModal = ({
  modalName,
  handleSubmit,
  onCancel,
  form,
  filtersList = [],
  error,
  loading,
  submitFailed,
  translationConfig,
}) => {
  const { t } = useTranslation('common');

  const buttons = useMemo(
    () => [
      {
        primary: true,
        type: 'submit',
        label: t('buttons.delete'),
        onClick: handleSubmit,
      },
      { label: t('buttons.cancel'), secondary: true, onClick: onCancel },
    ],
    [t, onCancel, handleSubmit],
  );

  return (
    <TPModal
      modalName={modalName}
      title={
        translationConfig.deleteModalTitle
          ? translationConfig.deleteModalTitle
          : t('presetFilters.delete')
      }
      buttons={buttons}
      onClose={onCancel}
      onSubmit={handleSubmit}
      form={form}
      loading={loading}>
      <TPGrid container spacing={3}>
        {submitFailed && error && (
          <TPGrid item xs={12}>
            <FormError error={error} />
          </TPGrid>
        )}
        <TPGrid item xs={12}>
          <Field
            name="presetId"
            label={
              translationConfig.presetNamePlaceholder
                ? translationConfig.presetNamePlaceholder
                : t('presetFilters.placeHolder')
            }
            placeholder={
              translationConfig.presetNamePlaceholder
                ? translationConfig.presetNamePlaceholder
                : t('presetFilters.placeHolder')
            }
            component={TPAutocomplete}
            fullWidth
            required
            validate={[required, checkNonLatin]}
            options={filtersList}
            optionValue="_id"
          />
        </TPGrid>
      </TPGrid>
    </TPModal>
  );
};

TPPresetFiltersDeleteModal.propTypes = {
  modalName: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  form: PropTypes.string,
  filtersList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      filters: PropTypes.object,
      _id: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  translationConfig: PropTypes.object,
};

const TPPresetFiltersDeleteReduxModal = reduxForm({
  ...REDUX_FORM_DEFAULT_CONFIG,
  enableReinitialize: true,
})(TPPresetFiltersDeleteModal);

export default TPPresetFiltersDeleteReduxModal;
