import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import classnames from 'classnames';
import FormHelperText from '@material-ui/core/FormHelperText';
import TPErrorMessage from '../TPErrorMessage';
import FormControl from '@material-ui/core/FormControl';

import useStyles from './styles';

export const TPRating = ({
  name,
  value,
  disabled = false,
  icon: Icon = StarBorderOutlinedIcon,
  max = 5,
  readonly = false,
  size = 'medium',
  classes = {},
  required = false,
  autofocus = false,
  reservedErrorSpace = false,
  hint,
  error,
  className,
  onChange,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const innerClasses = useStyles();
  const items = useMemo(() => Array(max).fill(null), [max]);

  const handleMouseEnter = useCallback((i) => {
    setHoveredIndex(i);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHoveredIndex(-1);
  }, []);

  const handleClick = useCallback(
    (i) => {
      if (onChange) {
        onChange(i + 1);
      }
    },
    [onChange],
  );

  const enabled = !disabled && !readonly;

  return (
    <FormControl
      variant="standard"
      required={required}
      disabled={disabled}
      autoFocus={autofocus}
      className={className}>
      <div
        id={name}
        className={classnames(innerClasses.root, { [innerClasses.disabled]: disabled })}>
        {items.map((_, i) => {
          let handlers = {};
          if (enabled) {
            handlers = {
              onMouseEnter: () => handleMouseEnter(i),
              onMouseLeave: handleMouseLeave,
              onClick: () => handleClick(i),
            };
          }
          return i + 1 <= value ? (
            <Icon
              key={i}
              className={classnames(innerClasses.iconFilled, {
                [classes.iconFilled]: classes?.iconFilled,
                [innerClasses.iconHovered]: i <= hoveredIndex,
                [classes.iconHovered]: classes?.iconFilled && i <= hoveredIndex,
                [innerClasses.iconEmpty]: hoveredIndex > -1 && i > hoveredIndex,
                [classes.iconEmpty]: classes?.iconEmpty && hoveredIndex > -1 && i > hoveredIndex,
              })}
              fontSize={size}
              {...handlers}
            />
          ) : (
            <Icon
              key={i}
              fontSize={size}
              className={classnames(innerClasses.iconEmpty, {
                [classes.iconEmpty]: classes?.iconEmpty,
                [innerClasses.iconHovered]: i <= hoveredIndex,
                [classes.iconHovered]: classes?.iconFilled && i <= hoveredIndex,
              })}
              color="disabled"
              {...handlers}
            />
          );
        })}
      </div>
      {hint && <FormHelperText component="div">{hint}</FormHelperText>}
      <div className={classnames({ [classes.errorContainer]: reservedErrorSpace })}>
        {error && <TPErrorMessage error={error} size="small" />}
      </div>
    </FormControl>
  );
};

TPRating.propTypes = {
  value: PropTypes.number,
  /**
   * The name attribute of the radio input elements. If readOnly is false, the prop is required, this input name`should be unique within the parent form.
   */
  name: PropTypes.string,
  /**
   * If true, the rating will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Removes all hover effects and pointer events.
   */
  readonly: PropTypes.bool,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  /**
   * The icon to display.
   */
  icon: PropTypes.elementType,
  /**
   * Maximum rating.
   */
  max: PropTypes.number,
  required: PropTypes.bool,
  autofocus: PropTypes.bool,
  /**
   * Reserved space to display error in 1 line
   */
  reservedErrorSpace: PropTypes.bool,
  hint: PropTypes.node,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  classes: PropTypes.shape({
    iconFilled: PropTypes.string,
    iconEmpty: PropTypes.string,
    iconHovered: PropTypes.string,
  }),
  className: PropTypes.string,
  /**
   * Callback fired when the value changes.
   */
  onChange: PropTypes.func,
};

const TPReduxRating = ({ input, meta, ...others }) => {
  const error = meta.submitFailed && meta.error ? meta.error : null;
  const { onChange } = input;
  return (
    <TPRating {...input} error={error} reservedErrorSpace={true} {...others} onChange={onChange} />
  );
};

export default TPReduxRating;
