import React from 'react';
import { FORCE_PASSWORD_CHANGE_FORM } from '../constants';
import { createPendingSelector } from '../../../../api/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { forcePasswordChangeRequest } from '../actions';
import omit from 'lodash/omit';
import ForcePasswordChange from '../components/ForcePasswordChange';
import validatePasswords from '../helpers/passwordsValidation';

const ForcePasswordChangeContainer = () => {
  const dispatch = useDispatch();
  const isPending = useSelector(createPendingSelector(forcePasswordChangeRequest()));

  const onSubmit = (data) => {
    dispatch(forcePasswordChangeRequest(omit(data, ['confirmPassword'])));
  };

  return (
    <ForcePasswordChange
      form={FORCE_PASSWORD_CHANGE_FORM}
      onSubmit={onSubmit}
      isPending={isPending}
      validate={validatePasswords}
    />
  );
};
export default ForcePasswordChangeContainer;
