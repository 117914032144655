export default (theme) => ({
  dataWrapper: {
    backgroundColor: theme.palette.white,
    padding: '30px 20px',
    minHeight: 232,
  },
  dataItem: {
    marginBottom: 20,
    textAlign: 'center',
  },
  dataItemValue: {
    fontSize: 30,
  },
  dataItemLabel: {},
  dataItemComparison: {
    fontSize: 10,
    margin: '-8px 0 8px 0',
  },
  dataItemIncomePositive: {
    color: '#7DC4B5',
  },
  dataItemIncomeNegative: {
    color: '#AA5072',
  },
});
