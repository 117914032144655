import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  () => ({
    loaderContainer: {
      minHeight: 100,
      flex: 1, // stretch to container
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
  { name: 'TPLoader' },
);
