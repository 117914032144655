import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import TPGrid from 'components/TP-UI/TPGrid';
import { email, required, checkNonLatin } from 'utils/validation/fieldValidationRules';
import TPTypography from 'components/TP-UI/TPTypography';
import TPTextField from 'components/TP-UI/TPTextField';
import TPButton from 'components/TP-UI/TPButton';
import TPSelect from 'components/TP-UI/TPSelect';
import TPLink from 'components/TP-UI/TPLink';
import AppleSignup from '../../../../components/AppleSignup';
import { HUBS } from '../../constants';
import GoogleSignin from 'modules/auth/components/GoogleSignin';
import ReCaptcha from 'modules/common/ReCaptcha';
import { REDUX_FORM_DEFAULT_CONFIG } from 'constants/form';
import FormError from 'modules/common/FormError';

import useStyles from './styles';

const SignInForm = ({
  handleSubmit,
  submitFailed,
  error = null,
  isPending,
  internal,
  showCaptcha,
  onAppleSignupSuccess,
  onGoogleSigninSuccess,
  form,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('auth');

  return (
    <form className={classes.form} onSubmit={handleSubmit} noValidate>
      <TPGrid container spacing={5} direction="column">
        <TPGrid item container spacing={3} direction="column">
          {submitFailed && error && (
            <TPGrid item>
              <FormError error={error} />
            </TPGrid>
          )}
          <TPGrid item container spacing={1} direction="column">
            {internal && (
              <TPGrid item>
                <Field
                  name="prefix"
                  component={TPSelect}
                  label="Hub"
                  placeholder="Please select hub"
                  options={HUBS}
                  fullWidth
                  required
                  validate={[required]}
                />
              </TPGrid>
            )}
            <TPGrid item>
              <Field
                name="email"
                label={t('common:labels.email')}
                placeholder={t('common:labels.email')}
                type="text"
                component={TPTextField}
                autofocus
                fullWidth
                autocomplete="username"
                required
                validate={[checkNonLatin, required, email]}
              />
            </TPGrid>
            <TPGrid item>
              <Field
                name="password"
                label={t('common:labels.password')}
                placeholder={t('common:labels.password')}
                confidential
                fullWidth
                autocomplete="current-password"
                component={TPTextField}
                required
                validate={[checkNonLatin, required]}
              />
              <TPTypography variant="body1" align="right" className={classes.linkContainer}>
                <TPLink to="/auth/forgot-password">{t('forgotPasswordText')}</TPLink>
              </TPTypography>
            </TPGrid>
            {showCaptcha ? (
              <TPGrid item>
                <ReCaptcha form={form} />
              </TPGrid>
            ) : null}
          </TPGrid>
          <TPGrid item>
            <TPButton fullWidth primary size="large" type="submit" loading={isPending}>
              {t('buttons.logIn')}
            </TPButton>
          </TPGrid>
        </TPGrid>
        <TPGrid item container spacing={2} direction="column">
          <TPGrid item>
            <GoogleSignin disabled={isPending} onSuccess={onGoogleSigninSuccess} />
          </TPGrid>
          <TPGrid item>
            <AppleSignup disabled={isPending} onSuccess={onAppleSignupSuccess} type="sign-in" />
          </TPGrid>
        </TPGrid>
      </TPGrid>
    </form>
  );
};

SignInForm.propTypes = {
  isPending: PropTypes.bool.isRequired,
  internal: PropTypes.bool,
  showCaptcha: PropTypes.bool,
  onAppleSignupSuccess: PropTypes.func.isRequired,
  onGoogleSigninSuccess: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default reduxForm({ ...REDUX_FORM_DEFAULT_CONFIG, enableReinitialize: true })(SignInForm);
