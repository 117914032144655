import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      width: 'fit-content',
      overflow: 'visible',
      padding: `0 ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`,
      '&:before': {
        backgroundColor: 'initial',
      },
    },
    elevation: {
      padding: 0,
      '&$expanded': {
        padding: 0,
        margin: 0,
      },
    },
    filled: {
      ...theme.overrides.MuiAccordion.filled,
    },
    expanded: {
      padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    },
    disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
    loading: {
      opacity: 0.5,
    },
    accordionLoader: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyItems: 'center',
    },
    fullWidth: {
      width: '100%',
      boxSizing: 'border-box',
    },
    rounded: {
      borderRadius: theme.accordion.root.borderRadius,
      '&:first-child, &:last-child': {
        borderRadius: theme.accordion.root.borderRadius,
      },
    },
  }),
  { name: 'TPAccordion' },
);
