import React, { useCallback, useMemo } from 'react';
import { compose } from 'redux';
import { Field, reduxForm, formValues } from 'redux-form';
import { Typography, Grid, withStyles } from '@material-ui/core';

import PrimaryButton from 'modules/common/PrimaryButton';
import FormSelect from 'modules/common/FormSelect';
import styles from './styles';

const CountryFilter = ({ classes, countryFilterValue, countries, closeModal, selectFilter }) => {
  const countryItems = useMemo(() => {
    return [
      { value: '', label: 'Select Country' },
      ...countries
        .map(({ country }) => ({ value: country, label: country }))
        .sort((a, b) => {
          if (a.value > b.value) {
            return 1;
          }
          if (a.value < b.value) {
            return -1;
          }
          return 0;
        }),
    ];
  }, [countries]);

  const selectFilterHandler = useCallback(() => {
    selectFilter(countryFilterValue);
  }, [selectFilter, countryFilterValue]);

  return (
    <form className={classes.root}>
      <Typography className={classes.title}> Filter </Typography>

      <Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.fieldContainer}>
            <Field
              name="countryFilterValue"
              id="countryFilterValue"
              component={FormSelect}
              items={countryItems}
              multiple={true}
              className={classes.filter}
            />
          </Grid>
        </Grid>
      </Grid>

      <div className={classes.buttonsSection}>
        <PrimaryButton
          color="primary"
          size="large"
          label={'Apply Filter'}
          type="button"
          onClick={selectFilterHandler}
        />
        <PrimaryButton
          color="secondary"
          size="large"
          label={'Cancel'}
          type="button"
          onClick={closeModal}
        />
      </div>
    </form>
  );
};

export default compose(
  reduxForm({
    form: 'COUNTRY_FILTER_FORM',
  }),
  formValues('countryFilterValue'),
  withStyles(styles),
)(CountryFilter);
