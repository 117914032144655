import React from 'react';
import TPGrid from 'components/TP-UI/TPGrid';
import TPProgressBar from 'components/TP-UI/TPProgressBar';
import TPTypography from 'components/TP-UI/TPTypography';
import useStyles from './styles';

const ProgressBars = () => {
  const classes = useStyles();
  return (
    <TPGrid container spacing={1}>
      <TPTypography>Line Progress</TPTypography>
      <TPGrid item container alignItems="center" className={classes.spacing}>
        <TPProgressBar thickness={8} progress={75} label="75%" />
      </TPGrid>
      <TPTypography>Circle Progress</TPTypography>
      <TPGrid item container alignItems="center" className={classes.spacing}>
        <TPProgressBar circle progress={55} label="55%" />
      </TPGrid>
      <TPTypography>Custom Progress</TPTypography>
      <TPGrid item container alignItems="center" className={classes.spacing}>
        <TPProgressBar
          primary
          circle
          progress={40}
          label="8/20 lots"
          diameter={230}
          labelColor="primary"
          labelVariant="h1"
        />
      </TPGrid>
    </TPGrid>
  );
};

export default ProgressBars;
