import React, { useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import TPGrid from 'components/TP-UI/TPGrid';
import TPPageHeader from 'components/TP-UI/TPPageHeader';
import config from 'config';
import TPImage from 'components/TP-UI/TPImage';
import TPTypography from 'components/TP-UI/TPTypography';
import HeaderButtons from 'modules/common/HeaderButtons';
import ActionsContent from './components/ActionsContent';
import { THEME_NAME } from 'constants/theme';

import useStyles from './styles';

const { LOGO, CURRENT_HUB_LABEL } = config;

const Headers = () => {
  const mobile = isMobileOnly;
  const classes = useStyles();
  const buttonGroupProps = useMemo(
    () => [
      {
        label: 'Primary',
        primary: true,
      },
      {
        label: 'Alternative',
        alternative: true,
      },
    ],
    [],
  );

  return (
    <TPGrid container spacing={1}>
      <TPTypography>Default (reload page as mobile device for mobile view test)</TPTypography>
      <TPGrid item container xs={12} alignItems="center">
        <TPPageHeader
          minimized={mobile}
          title="Header"
          reservedBreadcrumbsSpace
          actions={mobile ? null : <ActionsContent />}>
          <HeaderButtons items={buttonGroupProps} />
        </TPPageHeader>
      </TPGrid>
      <TPGrid item container xs={12} alignItems="center">
        <TPPageHeader
          minimized={mobile}
          title="Header"
          breadcrumbsRoutes={[
            { label: 'Payments', path: '/payments' },
            { label: 'Accaunt', path: '/accounts' },
            { label: 'Home', path: '/' },
          ]}
          actions={mobile ? null : <ActionsContent hideCalendar />}>
          <HeaderButtons items={buttonGroupProps} />
        </TPPageHeader>
      </TPGrid>
      <TPTypography>Drawer</TPTypography>
      <TPGrid item container xs={12} alignItems="center">
        <TPPageHeader
          minimized
          title={
            <TPImage
              alt={CURRENT_HUB_LABEL}
              src={LOGO[THEME_NAME.LIGHT].origin}
              className={classes.logo}
            />
          }
          actions={<ActionsContent />}></TPPageHeader>
      </TPGrid>
    </TPGrid>
  );
};

export default Headers;
