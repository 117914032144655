import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import TPImage from '../../../TPImage';
import TPTypography from '../../../TPTypography';
import { WEBCAM_STATE_DEFAULT_MESSAGE } from './constants';
import classnames from 'classnames';
import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';

import useStyles from './styles';

const TPTakePhoto = ({
  mode = 'video',
  playerRef,
  inputProps,
  imageDataURL,
  cameraAllowed,
  webcamState,
  webcamStatusMessages,
  className,
}) => {
  const { inputRef, name, disabled, onChange } = inputProps || {};
  const classes = useStyles();
  const messages = useMemo(
    () => Object.assign({}, WEBCAM_STATE_DEFAULT_MESSAGE, webcamStatusMessages),
    [webcamStatusMessages],
  );

  const handleChange = useCallback(
    (value) => {
      if (value.target.files[0] && onChange) {
        onChange(value.target.files[0]);
      }
    },
    [onChange],
  );

  const handleClick = useCallback(() => {
    if (inputRef && inputRef.current && !disabled) {
      inputRef.current.click();
    }
  }, [inputRef, disabled]);

  return (
    <div className={classnames(classes.root, className)} onClick={mode === 'input' && handleClick}>
      {imageDataURL ? (
        <TPImage src={imageDataURL} className={classes.image} alt="cameraPic" />
      ) : mode === 'video' ? (
        cameraAllowed ? (
          <video width="100%" ref={playerRef} autoPlay playsInline muted />
        ) : (
          <div className={classes.textContainer}>
            <TPTypography color="secondary">{messages[webcamState]}</TPTypography>
          </div>
        )
      ) : (
        <>
          <input
            ref={inputRef}
            type="file"
            name={name}
            capture="user"
            accept="image/*"
            hidden
            onChange={handleChange}
          />
          <AddAPhotoOutlinedIcon className={classes.inputIcon} />
        </>
      )}
    </div>
  );
};

TPTakePhoto.propTypes = {
  mode: PropTypes.oneOf(['video', 'input']),
  imageDataURL: PropTypes.string,
  /**
   * It's required for 'video' mode
   */
  playerRef: PropTypes.object,
  cameraAllowed: PropTypes.bool,
  webcamState: PropTypes.string,
  webcamStatusMessages: PropTypes.shape({
    denied: PropTypes.string,
    granted: PropTypes.string,
    wait: PropTypes.string,
    noCamera: PropTypes.string,
  }),
  /**
   * It's required for 'input' mode
   */
  inputProps: PropTypes.shape({
    inputRef: PropTypes.object,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
  }),
};

export default TPTakePhoto;
