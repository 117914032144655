import { all, put, takeEvery } from 'redux-saga/effects';
import * as notificationsActions from 'modules/notifications/actions';
import * as actions from '../actions';
import get from 'lodash/get';

function* createMigrateSuccessSaga({ payload, response }) {
  const accounts = get(response, 'data.updatedAccounts', {});
  const users = get(response, 'data.updatedUsers', {});

  if (!accounts?.nMatched && !users?.nMatched) {
    yield put(
      notificationsActions.showNotificationError(`User with cxd=${payload.cxd} not found!`),
    );
  } else {
    yield put(notificationsActions.showNotificationInfo('Migration completed!'));
  }
}

function* createMigrateFailSaga(action) {
  yield put(
    notificationsActions.showNotificationError({
      key: get(action, 'error.data.message'),
    }),
  );
}

export default function* migrateSaga() {
  yield all([takeEvery(actions.createMigrateSuccess, createMigrateSuccessSaga)]);
  yield all([takeEvery(actions.createMigrateFail, createMigrateFailSaga)]);
}
