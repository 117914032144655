import { combineActions, handleActions } from 'redux-actions';

import * as userActionCreators from 'modules/userManagement/pages/UserDetails/actions';
import * as signInActionCreators from 'modules/auth/pages/SignIn/actions';
import { cleanupAuth } from '../actions';
import { signinSuccess } from 'modules/auth/pages/SignIn/actions';

const defaultState = false;

export default handleActions(
  {
    [combineActions(userActionCreators.signinAsUserSuccess)](_, action) {
      return action.response.data.isImpersonation;
    },
    [cleanupAuth]: () => {
      return defaultState;
    },
    [combineActions(signinSuccess)]() {
      return defaultState;
    },
    [combineActions(signInActionCreators.appleSigninSuccess)]() {
      return defaultState;
    },
    [combineActions(signInActionCreators.googleSigninSuccess)]() {
      return defaultState;
    },
  },
  defaultState,
);
