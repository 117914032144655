export const DEFAULT_PAGE_LIMIT = '50';

export const PAGE_LIMITS_VALUES = [
  { label: '5', value: '5' },
  { label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
];

export const PAGINATION_FORM = 'PAGINATION_FORM';

export const PAGINATION_BAR_PAGES_LIMIT = 9;

export const totalNumbersLabels = {
  clients: 'clients',
  templates: 'templates',
  emails: 'emailHistory',
  withdrawals: 'pendingWithdrawals',
  paypalWithdrawals: 'paypalPendingWithdrawals',
  deposits: 'smartwayRequests',
  payments: 'paymentsHistory',
  accounts: 'pendingAccounts',
  verifications: 'verificationRequests',
  bankDetails: 'bankDetails',
  userEmails: 'userEmails',
  userPayments: 'userPayments',
  employees: 'employees',
  securityLogs: 'securityLogs',
  partnerTransfers: 'partnerTransfers',
  followUps: 'followUps',
  operationNotifications: 'operationNotifications',
  users: 'users',
  tickets: 'tickets',
  queue: 'queue',
};

export const PAGINATION_DEFAULT_PAGE = 1;

export const PAGINATION_DEFAULT_SKIP = 0;
