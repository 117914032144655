import { createAction } from 'redux-actions';

export const getReferralLinkRequest = createAction('GET_REFERRAL_LINK_REQUEST');
export const getReferralLinkSuccess = createAction('GET_REFERRAL_LINK_SUCCESS');
export const getReferralLinkFail = createAction('GET_REFERRAL_LINK_FAIL');

export const joinToReferFriendRequest = createAction('JOIN_TO_REFER_FRIEND_REQUEST');
export const joinToReferFriendSuccess = createAction('JOIN_TO_REFER_FRIEND_SUCCESS');
export const joinToReferFriendFail = createAction('JOIN_TO_REFER_FRIEND_FAIL');

export const getReferralsRequest = createAction('GET_REFERRALS_REQUEST');
export const getReferralsSuccess = createAction('GET_REFERRALS_SUCCESS');
export const getReferralsFail = createAction('GET_REFERRALS_FAIL');

export const getFeesDiscountRequest = createAction('GET_FEES_DISCOUNT_REQUEST');
export const getFeesDiscountSuccess = createAction('GET_FEES_DISCOUNT_SUCCESS');
export const getFeesDiscountFail = createAction('GET_FEES_DISCOUNT_FAIL');

export const getReferralBalanceRequest = createAction('GET_REFERRAL_BALANCE_REQUEST');
export const getReferralBalanceSuccess = createAction('GET_REFERRAL_BALANCE_SUCCESS');
export const getReferralBalanceFail = createAction('GET_REFERRAL_BALANCE_FAIL');

export const getReferralEarningsRequest = createAction('GET_REFERRAL_EARNINGS_REQUEST');
export const getReferralEarningsSuccess = createAction('GET_REFERRAL_EARNINGS_SUCCESS');
export const getReferralEarningsFail = createAction('GET_REFERRAL_EARNINGS_FAIL');

export const getReferralEarningsWithdrawalRequest = createAction(
  'GET_REFERRAL_EARNINGS_WITHDRAWAL_REQUEST',
);
export const getReferralEarningsWithdrawalSuccess = createAction(
  'GET_REFERRAL_EARNINGS_WITHDRAWAL_SUCCESS',
);
export const getReferralEarningsWithdrawalFail = createAction(
  'GET_REFERRAL_EARNINGS_WITHDRAWAL_FAIL',
);
