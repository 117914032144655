import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reset } from 'redux-form';

import TPModal from 'components/TP-UI/TPModal';
import { closeModal, openModal } from 'components/TP-UI/TPModal/actions';
import { ACCOUNT_PLANS } from 'constants/accountPlans';
import { ACCOUNT_SERVERS } from 'constants/index';
import { Routes } from 'constants/routeConstants';
import { accountPlanSelector, targetUserQuizSelector } from 'modules/auth/selectors';
import { getQuizRequest, resetQuizProgress } from 'modules/profileQuiz/actions';

import {
  PRO_QUIZ_FORM,
  PRO_QUIZ_MODAL,
  RETAIL_QUIZ_FORM,
  RETAIL_QUIZ_MODAL,
} from '../../../profile/constants';
import verificationModalNames from '../../constants/verificationModalNames';
import QuizModalContent from '../QuizModalContent';
import { useTheme } from '@material-ui/core';

const FirstFailedQuizModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('quiz');
  const selectedPlan = useSelector(accountPlanSelector);
  const targetQuiz = useSelector(targetUserQuizSelector);
  const { quiz } = useTheme();
  const imageConfig = quiz.tryAgainModal.avatar;

  const isProPlan = selectedPlan === ACCOUNT_PLANS.PRO;
  const titleTarget = isProPlan ? ACCOUNT_PLANS.PRO : ACCOUNT_PLANS.RETAIL;
  const title = t(`quiz:${titleTarget}.page.title`);

  const handleClose = useCallback(() => {
    dispatch(closeModal(verificationModalNames.TRY_AGAIN_MODAL));
    dispatch(reset(isProPlan ? PRO_QUIZ_FORM : RETAIL_QUIZ_FORM));
    dispatch(resetQuizProgress());
  }, [dispatch, isProPlan]);

  const handleTryAgain = useCallback(() => {
    dispatch(getQuizRequest());
    handleClose();
    if (!targetQuiz?.isBlocked) {
      const activeQuizModalName =
        selectedPlan === ACCOUNT_PLANS.PRO ? PRO_QUIZ_MODAL : RETAIL_QUIZ_MODAL;
      dispatch(openModal(activeQuizModalName));
    }
  }, [dispatch, handleClose, selectedPlan, targetQuiz]);

  const handleTryLater = useCallback(() => {
    handleClose();
    history.push({
      pathname: Routes.MY_ACCOUNTS,
      state: { activeTab: ACCOUNT_SERVERS.DEMO },
    });
  }, [handleClose, history]);

  const buttons = useMemo(
    () => [
      {
        label: t('firstFailModal.primaryButtonText'),
        primary: true,
        onClick: handleTryAgain,
      },
      {
        label: t('firstFailModal.secondaryButtonText'),
        secondary: true,
        onClick: handleTryLater,
      },
    ],
    [t, handleTryAgain, handleTryLater],
  );

  return (
    <TPModal
      title={title}
      onClose={handleTryLater}
      modalName={verificationModalNames.TRY_AGAIN_MODAL}
      buttons={buttons}>
      <QuizModalContent
        imageConfig={imageConfig}
        text={t(`firstFailModal.${selectedPlan || 'retail'}Description`)}
        title={t('firstFailModal.title')}
      />
    </TPModal>
  );
};

export default FirstFailedQuizModal;
