import React from 'react';
import TPGrid from 'components/TP-UI/TPGrid';
import TPButton from 'components/TP-UI/TPButton';
import useStyles from './styles';

const Buttons = () => {
  const classes = useStyles();
  return (
    <TPGrid container spacing={1}>
      <TPGrid item xs={12}>
        <TPButton primary className={classes.button}>
          Primary
        </TPButton>
        <TPButton primary className={classes.button} loading>
          Primary
        </TPButton>
        <TPButton primary className={classes.button} disabled>
          Primary
        </TPButton>
      </TPGrid>
      <TPGrid item xs={12}>
        <TPButton secondary className={classes.button}>
          Secondary
        </TPButton>
        <TPButton secondary loading className={classes.button}>
          Secondary
        </TPButton>
        <TPButton secondary disabled className={classes.button}>
          Secondary
        </TPButton>
      </TPGrid>
      <TPGrid item xs={12}>
        <TPButton alternative className={classes.button}>
          Alternative
        </TPButton>
        <TPButton alternative loading className={classes.button}>
          Alternative
        </TPButton>
        <TPButton alternative disabled className={classes.button}>
          Alternative
        </TPButton>
      </TPGrid>
      <TPGrid item xs={12}>
        <TPButton primary size={'large'} className={classes.button}>
          Primary Large
        </TPButton>
        <TPButton secondary size={'large'} className={classes.button}>
          Secondary Large
        </TPButton>
        <TPButton alternative size={'large'} className={classes.button}>
          Alternative Large
        </TPButton>
      </TPGrid>
      <TPGrid item xs={12}>
        <TPButton primary size={'small'} className={classes.button}>
          Primary Small
        </TPButton>
        <TPButton secondary size={'small'} className={classes.button}>
          Secondary Small
        </TPButton>
        <TPButton alternative size={'small'} className={classes.button}>
          Alternative Small
        </TPButton>
      </TPGrid>
      <TPGrid item xs={12}>
        <TPGrid container>
          <TPGrid item xs={4}>
            <TPButton primary fullWidth className={classes.button}>
              Primary full width
            </TPButton>
          </TPGrid>
          <TPGrid item xs={4}>
            <TPButton secondary fullWidth className={classes.button}>
              Secondary full width
            </TPButton>
          </TPGrid>
          <TPGrid item xs={4}>
            <TPButton alternative fullWidth className={classes.button}>
              Alternative full width
            </TPButton>
          </TPGrid>
        </TPGrid>
      </TPGrid>
    </TPGrid>
  );
};

export default Buttons;
