import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  () => ({
    root: {
      borderRadius: '10px',

      '&.MuiChip-sizeSmall': {
        height: '28px',
      },
    },
  }),
  { name: 'TPTag' },
);
