import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import styles from './styles';
import FormSelect from 'modules/common/FormSelect';

const Select = ({
  classes,
  name,
  label,
  selectItems,
  hideLabel,
  className,
  onChange,
  multiple,
}) => (
  <>
    {label && !hideLabel && <Typography className={classes.title}>{label}</Typography>}
    <Field
      onChange={onChange}
      className={classNames(classes.field, className)}
      name={name}
      id={name}
      component={FormSelect}
      items={selectItems}
      multiple={multiple}
    />
  </>
);

Select.propTypes = {
  classes: PropTypes.object.isRequired,
  selectItems: PropTypes.array.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  hideLabel: PropTypes.bool,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
};

export default withStyles(styles)(Select);
