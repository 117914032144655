import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';
import { ACCOUNT_SERVERS } from 'constants/index';
import TPButton from 'components/TP-UI/TPButton';

import NoUserVerificationView from 'modules/common/NoUserVerificationView';
import PagePlaceholder from 'modules/common/PagePlaceholder';
import InfoTip from 'modules/common/InfoTip';

const NoAccounts = ({
  accountType,
  isQuizRequired = false,
  onCreateAccount,
  onCompleteVerification,
  newUser = false,
  disableAccountsCreation = false,
  isBannedCountry,
  userAllowedAccountCreation,
}) => {
  const { t } = useTranslation('accounts');
  const { images } = useTheme();

  return accountType === ACCOUNT_SERVERS.DEMO ? (
    <PagePlaceholder
      title={t('noUserVerification.title')}
      image={images.common.noAccountsImg}
      imageAlt="no accounts"
      message={!isBannedCountry && userAllowedAccountCreation ? t('page.account.createDemo') : ''}
      button={
        !isBannedCountry && userAllowedAccountCreation ? (
          <TPButton
            primary
            size="large"
            disabled={disableAccountsCreation}
            onClick={onCreateAccount}>
            {t('common.buttons.add_account_btn')}
          </TPButton>
        ) : null
      }
    />
  ) : (
    <NoUserVerificationView
      title={t('noUserVerification.title')}
      image={images.common.noAccountsImg}
      imageAlt="no accounts"
      onClick={onCompleteVerification}
      newUser={newUser}
      message={
        isQuizRequired ? (
          <>
            {t('noUserVerification.text_au')}
            &nbsp;
            <InfoTip
              tooltipContent={t('common:noUserVerification.traderVerificationTooltip')}
              minimized
            />
          </>
        ) : (
          t('noUserVerification.text')
        )
      }
    />
  );
};

NoAccounts.propTypes = {
  accountType: PropTypes.oneOf([
    ACCOUNT_SERVERS.LIVE,
    ACCOUNT_SERVERS.DEMO,
    ACCOUNT_SERVERS.REBATE,
    ACCOUNT_SERVERS.ATW,
  ]).isRequired,
  isQuizRequired: PropTypes.bool,
  onCreateAccount: PropTypes.func.isRequired,
  onCompleteVerification: PropTypes.func.isRequired,
  newUser: PropTypes.bool,
  isBannedCountry: PropTypes.bool,
};

export default NoAccounts;
