import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TPGrid from 'components/TP-UI/TPGrid';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import TPTypography from 'components/TP-UI/TPTypography';
import countries from './countries.json';
import currencies from './currencies.json';
import TPAutocomplete from 'components/TP-UI/TPAutocomplete';
import TPInputAdornment from '../../../components/TP-UI/TPInputAdornment';
import Search from '@material-ui/icons/Search';
import { required } from '../../../utils/validation/fieldValidationRules';
import TPChip from '../../../components/TP-UI/TPChip';
import { connect } from 'react-redux';

import useStyles from './styles';

const groupedCountries = [
  {
    label: 'A',
    options: [
      {
        name: 'Australia',
      },
      {
        name: 'Austria',
      },
    ],
  },
  {
    label: 'B',
    options: [{ name: 'Brazil' }, { name: 'Bulgaria' }],
  },
  {
    Label: 'C',
    options: [{ name: 'China' }],
  },
];

const formName = 'TP-AUTOCOMPLETES-FORM';

const testRequire = (value) => {
  return value === false ? { key: 'common:errors.form.isRequired' } : undefined;
};

let Autocompletes = ({ countryValue, symbolValue, dispatch }) => {
  const classes = useStyles();
  const [symbols, setSymbols] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedSymbols, setSelectedSymbols] = useState([]);
  const filteredCountries = useMemo(() => {
    return countries.filter((country) => !selectedCountries.some((c) => c === country.name));
  }, [selectedCountries]);

  const handleDeleteCountry = useCallback((index) => {
    setSelectedCountries((value) => {
      const newVal = [...value];
      newVal.splice(index, 1);
      return newVal;
    });
  }, []);

  const handleDeleteSymbol = useCallback((index) => {
    setSelectedSymbols((value) => {
      const newVal = [...value];
      newVal.splice(index, 1);
      return newVal;
    });
  }, []);

  const handleSymbolSearchChange = useCallback((term) => {
    //emulate api call with term value
    if (term !== '') {
      setTimeout(() => {
        setSymbols(
          currencies.filter((currency) =>
            currency.label?.toLowerCase().includes(term.toLowerCase()),
          ),
        );
      }, 300);
    } else {
      setSymbols([]);
    }
  }, []);

  useEffect(() => {
    if (countryValue) {
      setSelectedCountries((value) => [...value, countryValue]);
      dispatch(change(formName, 'country', ''));
    }
  }, [countryValue, dispatch]);

  useEffect(() => {
    if (symbolValue) {
      setSelectedSymbols((value) => [...value, symbolValue]);
      dispatch(change(formName, 'symbol', ''));
    }
  }, [symbolValue, dispatch]);

  return (
    <form className={classes.form}>
      <TPGrid container spacing={1}>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">TPAutocomplete with start icon</TPTypography>
          <Field
            component={TPAutocomplete}
            id="auto_currency"
            name="auto_currency"
            options={currencies}
            placeholder="Enter symbol or name"
            clearable
            hideArrow
            startAdornment={
              <TPInputAdornment position="start">
                <Search />
              </TPInputAdornment>
            }
            hint="Additional info"
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">
            TPAutocomplete with start icon, small size
          </TPTypography>
          <Field
            component={TPAutocomplete}
            id="auto_currency"
            name="auto_currency"
            options={currencies}
            placeholder="Enter symbol or name"
            clearable
            size="small"
            hideArrow
            startAdornment={
              <TPInputAdornment position="start">
                <Search />
              </TPInputAdornment>
            }
            hint="Additional info"
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">
            TPAutocomplete with start icon, hide arrow
          </TPTypography>
          <Field
            component={TPAutocomplete}
            id="auto_currency"
            name="auto_currency"
            options={currencies}
            placeholder="Enter symbol or name"
            clearable
            hideArrow
            startAdornment={
              <TPInputAdornment position="start">
                <Search />
              </TPInputAdornment>
            }
            hint="Additional info"
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Error</TPTypography>
          <Field
            component={TPAutocomplete}
            id="auto_currency"
            name="auto_currency"
            label="Currency"
            placeholder="Please select currency"
            fullWidth
            options={[
              { label: 'USD - US Dollar', value: 1, abbr: 'USD' },
              { label: 'EUR - Euro', value: 2, abbr: 'EUR' },
            ]}
            validate={[testRequire]}
            required
            hint="Additional info"
            meta={{ error: { key: 'common:errors.form.isRequired' }, submitFailed: true }}
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">
            TPAutocomplete with grouped options, custom option value and label, clearable{' '}
          </TPTypography>
          <Field
            component={TPAutocomplete}
            id="groupedCountry"
            name="groupedCountry"
            options={groupedCountries}
            placeholder="Enter country"
            optionValue="name"
            optionLabel="name"
            grouped
            clearable
            hint="Additional info"
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">
            TPAutocomplete with grouped by region custom key{' '}
          </TPTypography>
          <Field
            component={TPAutocomplete}
            id="groupedCountry"
            name="groupedCountry"
            options={countries}
            label="Country"
            placeholder="Country"
            groupBy="region"
            clearable
            required
            validate={[required]}
            hint="Additional info"
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">
            TPAutocomplete with selected multiple values (chips) manually
          </TPTypography>
          <Field
            component={TPAutocomplete}
            id="country"
            name="country"
            options={filteredCountries}
            label="Country"
            placeholder="Country"
            groupBy="region"
            clearable
            required
            validate={[required]}
            reservedErrorSpace={false}
            fullWidth
            hideArrow
            className={classes.control}
          />
          <div className={classes.countryList}>
            {selectedCountries.map((chip, index) => (
              <TPChip
                label={chip}
                size="small"
                key={chip}
                onDelete={() => handleDeleteCountry(index)}
              />
            ))}
          </div>
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">
            TPAutocomplete with selected multiple values (chips)
          </TPTypography>
          <Field
            component={TPAutocomplete}
            id="countriesq"
            name="countriesq"
            options={countries}
            multiple
            label="Country"
            placeholder="Country"
            groupBy="region"
            clearable
            required
            validate={[required]}
            reservedErrorSpace={false}
            fullWidth
            hideArrow
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">
            Server-side TPAutocomplete with selected multiple values (chips) manually
          </TPTypography>
          <Field
            component={TPAutocomplete}
            id="symbol"
            name="symbol"
            options={symbols}
            label="Symbols"
            placeholder="Add Symbol"
            clearable
            required
            validate={[required]}
            reservedErrorSpace={false}
            fullWidth
            hideArrow
            clientSide={false}
            returnsObject
            onSearchChange={handleSymbolSearchChange}
            className={classes.control}
          />
          <div className={classes.symbolsList}>
            {selectedSymbols.map((chip, index) => (
              <TPChip
                label={chip.label}
                size="small"
                key={chip.value}
                onDelete={() => handleDeleteSymbol(index)}
              />
            ))}
          </div>
        </TPGrid>
      </TPGrid>
    </form>
  );
};

Autocompletes = reduxForm({
  form: formName,
  initialValues: {
    auto_currency: null,
    groupedCountry: null,
    country: null,
    symbol: null,
    countriesq: [],
  },
})(Autocompletes);

const selector = formValueSelector(formName);

export default connect((state) => {
  const country = selector(state, 'country');
  const symbol = selector(state, 'symbol');
  return {
    countryValue: country,
    symbolValue: symbol,
  };
})(Autocompletes);
