import isString from 'lodash/isString';

/**
 * Find and return the target DOM element based on a step's 'target'.
 * @param {string} element
 * @returns {HTMLElement|null}
 */
export const getElement = (element) => {
  if (!isString(element)) return element;

  const elements = document.querySelectorAll(element);
  const result = [...elements].find(isElementVisible);
  return result;
};

/**
 * Find the bounding client rect
 * @param {HTMLElement} element - The target element
 * @returns {Object}
 */
export function getClientRect(element) {
  if (!element) {
    return {};
  }

  return element.getBoundingClientRect();
}

/**
 * Check if the element is visible
 *
 * @param {HTMLElement} element
 *
 * @returns {boolean}
 */
export function isElementVisible(element) {
  if (!element) return false;

  let parentElement = element;

  while (parentElement) {
    if (parentElement === document.body) break;

    if (parentElement instanceof HTMLElement) {
      const { display, visibility } = getComputedStyle(parentElement);

      if (display === 'none' || visibility === 'hidden') {
        return false;
      }
    }

    parentElement = parentElement.parentNode;
  }
  return true;
}
