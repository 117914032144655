import { handleActions } from 'redux-actions';
import { INITIAL_FILTERS_VALUES } from '../constants';
import { DEFAULT_SORTING } from '../../../components/ClientsTable/constants';
import { getTableColumns } from '../../../components/ClientsTable/helpers/tableColumns';
import { logoutSuccess } from 'modules/auth/actions';
import {
  getFullMyClientsAccountsFail,
  getFullMyClientsAccountsSuccess,
  updateMyClientsTableSettings,
} from '../actions';
/**
 *  Need to store table settings. when user comes back to that page, all previous selections should be applied by default
 *   filters,
 *   sorting,
 *   columns
 */
const defaultState = {
  items: [],
  sorting: DEFAULT_SORTING,
  filters: INITIAL_FILTERS_VALUES,
  columns: getTableColumns().reduce((a, c) => {
    if (c.default) {
      a.push(c.dataKey);
    }
    return a;
  }, []),
};

export default handleActions(
  {
    [getFullMyClientsAccountsSuccess](state, action) {
      return !action.payload.persistStoreData
        ? {
            ...state,
            items: action.response.data.clients,
            totalNumber: action.response.data.totalNumber,
          }
        : state;
    },
    [getFullMyClientsAccountsFail](state) {
      return state.totalNumber !== undefined
        ? state
        : {
            ...defaultState,
            totalNumber: 0,
          };
    },
    [updateMyClientsTableSettings](state, action) {
      return { ...state, ...action.payload };
    },
    [logoutSuccess]() {
      return defaultState;
    },
  },
  defaultState,
);
