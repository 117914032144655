import { handleActions } from 'redux-actions';
import {
  cleanupUnablePaypalRefundRequests,
  getUnablePaypalRefundRequestsFail,
  getUnablePaypalRefundRequestsSuccess,
  getUserPaypalEmailsSuccess,
  getUserPaypalRefundablePaymentsFail,
  getUserPaypalRefundablePaymentsRequest,
  getUserPaypalRefundablePaymentsSuccess,
} from '../actions';
import { UNABLE_PAYPAL_REFUND_REQUEST_STATUS } from '../constants';

const defaultState = {
  requests: null,
  paypalEmails: [],
  paypalRefundablePayments: {
    payload: null,
    items: [],
  },
};

export default handleActions(
  {
    [getUnablePaypalRefundRequestsSuccess](state, action) {
      return {
        ...state,
        requests: action.response.data.requests.map((request) => ({
          ...request,
          status: request.rejected
            ? UNABLE_PAYPAL_REFUND_REQUEST_STATUS.DECLINED
            : request.approvedEmails
            ? request.approvedEmails.length !== request.emails.length
              ? UNABLE_PAYPAL_REFUND_REQUEST_STATUS.PARTIAL_APPROVED
              : UNABLE_PAYPAL_REFUND_REQUEST_STATUS.APPROVED
            : UNABLE_PAYPAL_REFUND_REQUEST_STATUS.PENDING,
        })),
      };
    },
    [getUnablePaypalRefundRequestsFail](state) {
      return {
        ...state,
        requests: [],
      };
    },
    [cleanupUnablePaypalRefundRequests](state) {
      return {
        ...state,
        requests: defaultState.requests,
      };
    },
    [getUserPaypalEmailsSuccess](state, action) {
      return {
        ...state,
        paypalEmails: action.response.data,
      };
    },
    [getUserPaypalRefundablePaymentsRequest](state, action) {
      return {
        ...state,
        paypalRefundablePayments: {
          ...state.paypalRefundablePayments,
          payload: action.payload,
        },
      };
    },
    [getUserPaypalRefundablePaymentsSuccess](state, action) {
      return {
        ...state,
        paypalRefundablePayments: {
          ...state.paypalRefundablePayments,
          items: (action.response.data.payments || []).map((payment, index) => ({
            ...payment,
            key: index,
          })),
          totalNumber: action.response.data.totalNumber || 0,
        },
      };
    },
    [getUserPaypalRefundablePaymentsFail](state) {
      return {
        ...state,
        paypalRefundablePayments: {
          ...state.paypalRefundablePayments,
          items: [],
          totalNumber: 0,
        },
      };
    },
  },
  defaultState,
);
