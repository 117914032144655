import React from 'react';
import { reduxForm } from 'redux-form';
import TPGrid from 'components/TP-UI/TPGrid';
//import TPTinyEditor from 'components/TP-UI/TPTinyEditor';
import TPTypography from 'components/TP-UI/TPTypography';
//import { UPLOAD_EMAIL_FILE_ROUTE } from 'constants/index';
import useStyles from './styles';
import TPAlert from '../../../components/TP-UI/TPAlert';
//
// const testRequire = (value) => {
//   return value === false ? { key: 'common:errors.form.isRequired' } : undefined;
// };

/**
 * Commented usage, since every rendering is counted and we have limits for that.
 * To see how it works, just uncomment, but be careful with count of renders
 * For dev and staging we use free subscription. https://www.tiny.cloud/pricing/
 */
const TinyEditor = () => {
  const classes = useStyles();
  return (
    <form className={classes.form}>
      <TPGrid container spacing={2}>
        <TPGrid item>
          <TPAlert variant="warning">Disabled showing components, please review the code</TPAlert>
        </TPGrid>
        <TPGrid item xs={12}>
          <TPTypography variant="subtitle1" className={classes.spacing}>
            No label, default size
          </TPTypography>
          {/*<Field*/}
          {/*  component={TPTinyEditor}*/}
          {/*  initialValue={'initial value'}*/}
          {/*  name="message"*/}
          {/*  filesUploadUrl={UPLOAD_EMAIL_FILE_ROUTE}*/}
          {/*  onBlur={(e) => {*/}
          {/*    console.log('tiny editor blurred:', e);*/}
          {/*  }}*/}
          {/*  onChange={(e) => {*/}
          {/*    console.log('tiny editor changed:', e);*/}
          {/*  }}*/}
          {/*/>*/}
        </TPGrid>
        <TPGrid item xs={6}>
          <TPTypography variant="subtitle1" className={classes.spacing}>
            Error, default size
          </TPTypography>
          {/*<Field*/}
          {/*  component={TPTinyEditor}*/}
          {/*  initialValue={'initial value'}*/}
          {/*  name="message2"*/}
          {/*  label="Message"*/}
          {/*  filesUploadUrl={UPLOAD_EMAIL_FILE_ROUTE}*/}
          {/*  validate={[testRequire]}*/}
          {/*  required*/}
          {/*  hint="Additional info"*/}
          {/*  meta={{ error: { key: 'common:errors.form.isRequired' }, submitFailed: true }}*/}
          {/*  onBlur={(e) => {*/}
          {/*    console.log('tiny editor blurred:', e);*/}
          {/*  }}*/}
          {/*  onChange={(e) => {*/}
          {/*    console.log('tiny editor changed:', e);*/}
          {/*  }}*/}
          {/*/>*/}
        </TPGrid>
        <TPGrid item xs={6}>
          <TPTypography variant="subtitle1" className={classes.spacing}>
            Label, default size
          </TPTypography>
          {/*<Field*/}
          {/*  component={TPTinyEditor}*/}
          {/*  initialValue={'initial value'}*/}
          {/*  name="message3"*/}
          {/*  label="Label"*/}
          {/*  filesUploadUrl={UPLOAD_EMAIL_FILE_ROUTE}*/}
          {/*  onBlur={(e) => {*/}
          {/*    console.log('tiny editor blurred:', e);*/}
          {/*  }}*/}
          {/*  onChange={(e) => {*/}
          {/*    console.log('tiny editor changed:', e);*/}
          {/*  }}*/}
          {/*/>*/}
        </TPGrid>
        <TPGrid item xs={6}>
          <TPTypography variant="subtitle1" className={classes.spacing}>
            Label, default size, disabled
          </TPTypography>
          {/*<Field*/}
          {/*  component={TPTinyEditor}*/}
          {/*  initialValue={'initial value'}*/}
          {/*  name="message3"*/}
          {/*  label="Label"*/}
          {/*  disabled*/}
          {/*  filesUploadUrl={UPLOAD_EMAIL_FILE_ROUTE}*/}
          {/*  onBlur={(e) => {*/}
          {/*    console.log('tiny editor blurred:', e);*/}
          {/*  }}*/}
          {/*  onChange={(e) => {*/}
          {/*    console.log('tiny editor changed:', e);*/}
          {/*  }}*/}
          {/*/>*/}
        </TPGrid>
        <TPGrid item xs={12}>
          <TPTypography variant="subtitle1">Full width</TPTypography>
          {/*<Field*/}
          {/*  fullWidth*/}
          {/*  component={TPTinyEditor}*/}
          {/*  initialValue={'initial value'}*/}
          {/*  name="message4"*/}
          {/*  filesUploadUrl={UPLOAD_EMAIL_FILE_ROUTE}*/}
          {/*  onBlur={(e) => {*/}
          {/*    console.log('tiny editor blurred:', e);*/}
          {/*  }}*/}
          {/*  onChange={(e) => {*/}
          {/*    console.log('tiny editor changed:', e);*/}
          {/*  }}*/}
          {/*/>*/}
        </TPGrid>
      </TPGrid>
    </form>
  );
};
export default reduxForm({
  form: 'TP-TINYEDITOR-FORM',
  initialValues: {
    message: '<p>Lorem <b>ipsum</b> dolor sit amet</p>',
    message2: '',
    message3: '<p>Hello</p>',
    message4: '',
  },
})(TinyEditor);
