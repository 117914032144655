import palette from './palette';
import { fontSize, fontWeight } from '../font';

const Banner = {
  root: {
    background: palette.primary.gradient.main,
    fontSize: fontSize._16,
    fontWeight: fontWeight.regular,
    color: palette.primary.contrastText,
  },
  link: {
    color: palette.primary.contrastText,
    fontWeight: fontWeight.bold,
    textDecoration: 'underline',
  },
  closeIcon: {
    marginLeft: 'auto',
    fontSize: fontSize._20,
  },
};

export default Banner;
