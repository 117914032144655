import { handleActions } from 'redux-actions';

import {
  currencyCalculatorFail,
  currencyCalculatorReset,
  currencyCalculatorSuccess,
} from '../actions';

const defaultState = {
  rate: 0,
};

export default handleActions(
  {
    [currencyCalculatorSuccess](state, action) {
      return { ...state, rate: action.response.data.conversionRate };
    },
    [currencyCalculatorFail](state) {
      return { ...state, rate: defaultState.rate };
    },
    [currencyCalculatorReset](state) {
      return { ...state, rate: defaultState.rate };
    },
  },
  defaultState,
);
