import { BOTTOM_BAR_HEIGHT, SCROLL_TO_TOP_BUTTON_HEIGHT } from 'modules/menu/constants';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    notifications: {
      left: '30%',
      '@media (max-width:1024px)': {
        left: '0',
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      height: '100dvh',
    },
    containerMobile: {
      flexDirection: 'column',
    },
    publicContainer: {
      justifyContent: 'center',
    },
    mainComponent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    scrollToTopContainer: {
      position: 'relative',
    },
    scrollToTopActive: {
      marginBottom: SCROLL_TO_TOP_BUTTON_HEIGHT,
    },
    mainComponentMobile: {
      paddingBottom: BOTTOM_BAR_HEIGHT,
    },
    mainLayoutContent: {
      flex: 1,
      overflowY: 'auto',
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
      [theme.breakpoints.down('md')]: {
        padding: `${theme.spacing(3)}px ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`,
      },
    },

    publicContent: {
      marginLeft: 0,
      paddingTop: 0,
      backgroundColor: 'transparent',
      padding: 0,
      boxShadow: '0px 0px 20px -20px rgba(34, 60, 80, 0.2)',
    },
    hiddenContainer: {
      display: 'none',
    },
    zIndex1: {
      zIndex: 1,
    },
  }),
  { name: 'MainLayout' },
);

export default useStyles;
