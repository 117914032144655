import { combineReducers } from 'redux';

import hyperwallet from './hyperwallet';
import localBank from './localBank';
import finrax from './finrax';
import currencyCloud from './currencyCloud';
import stepper from './stepper';
import paypalRefund from './paypalRefund';
import conversionRate from './conversionRate';
import cryptoDay1 from './cryptoDay1';

const withdraw = combineReducers({
  hyperwallet,
  localBank,
  finrax,
  currencyCloud,
  stepper,
  paypalRefund,
  conversionRate,
  cryptoDay1,
});

export default withdraw;
