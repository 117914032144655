import React from 'react';
import PropTypes from 'prop-types';
import TPGrid from 'components/TP-UI/TPGrid';
import TPTypography from 'components/TP-UI/TPTypography';
import classNames from 'classnames';
import useStyles from './styles';

const TPStepHeader = ({ title, active, passed, component }) => {
  const classes = useStyles();
  const rootClasses = classNames(classes.root, {
    [classes.passed]: passed,
    [classes.active]: active,
  });

  return (
    <TPGrid
      container
      alignItems="flex-start"
      justifyContent="space-between"
      className={rootClasses}>
      <TPGrid item xs={8}>
        <TPTypography variant="h6" bold color={active || passed ? 'primary' : 'secondary'}>
          {title}
        </TPTypography>
      </TPGrid>
      <TPGrid item xs={4} className={classes.popoverContainer}>
        {component}
      </TPGrid>
    </TPGrid>
  );
};

TPStepHeader.propTypes = {
  /**
   * Title for the stepper step.
   */
  title: PropTypes.string.isRequired,
  /**
   * Custom header component.
   */
  component: PropTypes.node.isRequired,
  /**
   * Indicates if this step is the currently active step.
   */
  active: PropTypes.bool,
  /**
   * Indicates if this step has been passed/completed.
   */
  passed: PropTypes.bool,
};

export default TPStepHeader;
