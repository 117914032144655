import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';
import * as actions from '../actions';

const defaultState = {
  verifications: [],
  accounts: [],
  experienceUploads: [],
  accountsSuccess: false,
  verificationsSuccess: false,
  accountApproved: false,
  accountDeclined: false,
  kycVerifications: [],
  kycVerificationsTotalNumber: 0,
};

export default handleActions(
  {
    [actionCreators.getPendingVerificationsRequest](state) {
      return {
        ...state,
        verificationsSuccess: false,
      };
    },

    [actionCreators.getPendingVerificationsSuccess](state, action) {
      return {
        ...state,
        verifications: action.response.data.verifications,
        verificationsTotalNumber: action.response.data.totalNumber,
        verificationsSuccess: true,
        asc: action.payload.asc,
      };
    },

    [actionCreators.getExperienceUploadsSuccess](state, action) {
      return {
        ...state,
        experienceUploads: action.response.data.tradingStatements,
        experienceUploadsTotalNumber: action.response.data.totalNumber,
        asc: action.payload.asc,
      };
    },
    [actionCreators.getKYCPendingSuccess](state, action) {
      return {
        ...state,
        kycVerifications: action.response.data.verifications.map((verification, i) => ({
          ...verification,
          key: `${i}_${verification._id}`,
        })),
        kycVerificationTotalNumber: action.response.data.totalNumber,
      };
    },
    [actionCreators.getKYCPendingFollowUpSuccess](state, action) {
      return {
        ...state,
        kycPendingVerifications: action.response.data.verifications.map((verification, i) => ({
          ...verification,
          key: `${i}_${verification._id}`,
        })),
        kycPendingVerificationTotalNumber: action.response.data.totalNumber,
      };
    },
    [actionCreators.getPendingAccountsRequest](state) {
      return {
        ...state,
        accountsSuccess: false,
        accountApproved: false,
        accountDeclined: false,
      };
    },

    [actionCreators.getPendingAccountsSuccess](state, action) {
      return {
        ...state,
        accounts: action.response.data.accounts,
        accountsTotalNumber: action.response.data.totalNumber,
        accountsSuccess: true,
        asc: action.payload.asc,
      };
    },

    [actionCreators.getPendingVerificationsFail]() {
      return defaultState;
    },
    [actions.approveAccountsSuccess](state) {
      return {
        ...state,
        accountApproved: true,
      };
    },
    [actions.approveAccountsFail](state) {
      return {
        ...state,
        accountApproved: false,
      };
    },
    [actions.declineAccountsSuccess](state) {
      return {
        ...state,
        accountDeclined: true,
      };
    },
    [actions.declineAccountsFail](state) {
      return {
        ...state,
        accountDeclined: false,
      };
    },
  },
  defaultState,
);
