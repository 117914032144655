import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TPTypography from 'components/TP-UI/TPTypography';
import TPTabs from 'components/TP-UI/TPTabs';
import TPButton from 'components/TP-UI/TPButton';
import { NOTIFICATION_TYPES } from '../../constants';
import NotificationsTabContent from '../NotificationsTabContent';
import useStyles from './styles';

const NotificationsContent = ({
  onMarkAsRead,
  onMarkAllAsRead,
  loading = false,
  markAsReadLoading = false,
  markAllAsReadLoading = false,
  notificationItems = [],
  whatsNewItems = [],
  notificationUnreadItemsCount = 0,
  whatsNewUnreadItemsCount = 0,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('notificationCentre');

  const tabs = useMemo(() => {
    return [
      {
        label: t('page.notifications'),
        badgeContent: notificationUnreadItemsCount,
        content: NotificationsTabContent,
        contentProps: {
          items: notificationItems,
          notificationType: NOTIFICATION_TYPES.NOTIFICATION,
          markAsReadLoading,
          markAllAsReadLoading,
          onMarkAsRead: onMarkAsRead,
        },
        panelProps: {
          classes: {
            root: classes.panelRoot,
            content: classes.panelContent,
          },
        },
      },
      {
        label: t('page.whatsNew'),
        badgeContent: whatsNewUnreadItemsCount,
        content: NotificationsTabContent,
        contentProps: {
          items: whatsNewItems,
          notificationType: NOTIFICATION_TYPES.WHATS_NEW,
          markAsReadLoading,
          markAllAsReadLoading,
          onMarkAsRead: onMarkAsRead,
        },
        panelProps: {
          classes: {
            root: classes.panelRoot,
            content: classes.panelContent,
          },
        },
      },
    ];
  }, [
    notificationItems,
    whatsNewItems,
    notificationUnreadItemsCount,
    whatsNewUnreadItemsCount,
    markAsReadLoading,
    markAllAsReadLoading,
    onMarkAsRead,
    t,
    classes.panelContent,
    classes.panelRoot,
  ]);

  return (
    <div className={classes.root}>
      <section className={classes.sectionInfo}>
        <TPTypography variant="h4">{t('page.title')}</TPTypography>
        {notificationUnreadItemsCount || whatsNewUnreadItemsCount ? (
          <TPButton
            secondary
            onClick={onMarkAllAsRead}
            disabled={markAsReadLoading || markAllAsReadLoading}>
            {t('page.markAllAsRead')}
          </TPButton>
        ) : null}
      </section>
      <TPTabs tabs={tabs} className={classes.tabs} loading={loading} />
    </div>
  );
};

NotificationsContent.propTypes = {
  onMarkAsRead: PropTypes.func,
  onMarkAllAsRead: PropTypes.func,
  loading: PropTypes.bool,
  markAsReadLoading: PropTypes.bool,
  markAllAsReadLoading: PropTypes.bool,
  notificationItems: PropTypes.array,
  whatsNewItems: PropTypes.array,
  notificationUnreadItemsCount: PropTypes.number,
  whatsNewUnreadItemsCount: PropTypes.number,
};

export default NotificationsContent;
