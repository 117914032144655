import { createAction } from 'redux-actions';

export const paymentsCreateDay1UserRequest = createAction('PAYMENTS_CREATE_DAY1_USER_REQUEST');
export const paymentsCreateDay1UserSuccess = createAction('PAYMENTS_CREATE_DAY1_USER_SUCCESS');
export const paymentsCreateDay1UserFail = createAction('PAYMENTS_CREATE_DAY1_USER_FAIL');

export const paymentsGetDay1UserRequest = createAction('PAYMENTS_GET_DAY1_USER_REQUEST');
export const paymentsGetDay1UserSuccess = createAction('PAYMENTS_GET_DAY1_USER_SUCCESS');
export const paymentsGetDay1UserFail = createAction('PAYMENTS_GET_DAY1_USER_FAIL');

export const getPaymentsPermissionsRequest = createAction('GET_PAYMENTS_PERMISSIONS_REQUEST');
export const getPaymentsPermissionsSuccess = createAction('GET_PAYMENTS_PERMISSIONS_SUCCESS');
export const getPaymentsPermissionsFail = createAction('GET_PAYMENTS_PERMISSIONS_FAIL');

export const paymentsCreateDay1TermsCancel = createAction('PAYMENTS_CREATE_DAY1_TERMS_CANCEL');
