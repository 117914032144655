import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { useTheme } from '@material-ui/core';
import TPTypography from 'components/TP-UI/TPTypography';
import TPLink from 'components/TP-UI/TPLink';
import { CONTACT_US_URL } from 'constants/index';
import TPGrid from 'components/TP-UI/TPGrid';
import TPImage from 'components/TP-UI/TPImage';
import TPButton from 'components/TP-UI/TPButton';

import useStyles from './styles';

const ErrorBoundary = ({ onContactSupportClick, showContactSupportButton }) => {
  const { t } = useTranslation('errorPage');
  const classes = useStyles();
  const { images } = useTheme();

  return (
    <TPGrid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={3}
      className={classes.container}>
      <TPGrid item>
        <TPImage className={classes.image} alt={t('title')} src={images.common.interrogativeImg} />
      </TPGrid>
      <TPGrid item container spacing={3} direction="column" alignItems="center">
        <TPGrid item>
          <TPTypography align="center" variant="h5">
            {t('title')}
          </TPTypography>
        </TPGrid>
        <TPGrid item container direction="column">
          <TPGrid item>
            <TPTypography align="center" variant="body1">
              {t('text_1')}
            </TPTypography>
          </TPGrid>
          <TPGrid item>
            <TPTypography align="center" variant="body1">
              <Trans i18nKey="errorPage:text_2">
                <TPLink path={CONTACT_US_URL} target="_blank" rel="noopener noreferrer" />
              </Trans>
            </TPTypography>
          </TPGrid>
        </TPGrid>
        {showContactSupportButton ? (
          <TPGrid item>
            <TPButton primary onClick={onContactSupportClick} size="large">
              {t('buttonLabel')}
            </TPButton>
          </TPGrid>
        ) : null}
      </TPGrid>
    </TPGrid>
  );
};

ErrorBoundary.propTypes = {
  onContactSupportClick: PropTypes.func.isRequired,
  showContactSupportButton: PropTypes.bool.isRequired,
};

export default ErrorBoundary;
