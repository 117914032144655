import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
let TextGroup = ({
  classes,
  text,
  title,
  style,
  isSecondColumnAvailable,
  secondColumnContent,
  tipText,
}) => {
  return (
    <Grid item>
      {(text || title) && (
        <div className={classes.groupWrapper}>
          <div className={classes.sectionTextGroup}>
            <div className={classes.headerContainer}>
              <div className={classes.sectionTextHighlight}>{title}</div>
            </div>
            <div className={style && classes[`sectionText__${style}`]}>{text}</div>
            <p className={classes.tipText}> {tipText}</p>
          </div>
          {isSecondColumnAvailable && secondColumnContent}
        </div>
      )}
    </Grid>
  );
};
TextGroup.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  text: PropTypes.string,
  style: PropTypes.string,
  isSecondColumnAvailable: PropTypes.bool,
  secondColumnContent: PropTypes.object,
};
export default withStyles(styles)(TextGroup);
