import moment from 'moment';
import * as momentTz from 'moment-timezone';
import { get } from 'lodash';
import { toFixed } from 'utils/toFixed';
import { MONTHS, FORM_DEFAULTS, YES_NO, EMPTY_FORM } from '../constants';
import { hasOnlyEmptyValues } from 'utils/hasOnlyEmptyValues';

export const getCurrency = (currency) => {
  if (!currency) {
    return '';
  }
  return String(currency).toUpperCase();
};

export const getEquityPercent = (balance, equity) => {
  if (!balance) {
    return '';
  }

  return toFixed((equity * 100) / balance || 0, 2) + '%';
};

export const getPercentage = (value, total) => {
  if (!total) {
    return '0%';
  }

  return toFixed((value * 100) / total, 2) + '%';
};

export const getAvg = (value, total) => {
  if (!total) {
    return '0';
  }

  return toFixed((value || 0) / total, 2);
};

export const getValueOf = (value, total) => {
  return `${value || 0} of ${total || 0}`;
};

export const tradeInfo = (value, currency, date) => {
  if (!value) {
    return '';
  }

  const month = MONTHS[moment(date).month()];

  return `${value} ${(currency || '').toUpperCase()} ${month}`;
};

const getDateInUtc3 = (date) => {
  return momentTz.utc(date || undefined).tz('Asia/Nicosia');
};

export const formatDateTime = (date) => {
  if (!date) {
    return '';
  }
  return moment(date)
    .utc()
    .format('HH:mm:ss DD MMM');
};

export const getDuration = (from, to) => {
  if (!to) {
    to = getDateInUtc3().format('YYYY-MM-DDTHH:mm:ss+00:00');
  }

  return moment
    .duration(
      moment(to || undefined)
        .utc()
        .diff(moment(from).utc()),
    )
    .humanize();
};

export const setCopierSettings = (copySettings) => {
  let copierMode = copySettings['copierMode'] || FORM_DEFAULTS['copierMode'];
  const useFee = copySettings['useFee'] || FORM_DEFAULTS['useFee'];

  const data = {
    copierMode,
    reverseCopy: get(copySettings, 'reverseCopy', FORM_DEFAULTS['reverseCopy']),
    riskType: get(copySettings, 'riskType', FORM_DEFAULTS['riskType']),
    multiplier: get(copySettings, 'multiplier', FORM_DEFAULTS['multiplier']),
    slippage: get(copySettings, 'slippage', FORM_DEFAULTS['slippage']),
    maxLot: get(copySettings, 'maxLot', FORM_DEFAULTS['maxLot']),
    forceMinLot: get(copySettings, 'forceMinLot', FORM_DEFAULTS['forceMinLot']),
    copyPending: get(copySettings, 'copyPending', FORM_DEFAULTS['copyPending']),
    copyStopLoss: get(copySettings, 'copyStopLoss', FORM_DEFAULTS['copyStopLoss']),
    copyTakeProfit: get(copySettings, 'copyTakeProfit', FORM_DEFAULTS['copyTakeProfit']),
    copyExistingTrades: get(
      copySettings,
      'copyExistingTrades',
      FORM_DEFAULTS['copyExistingTrades'],
    ),
    emailNotifications: get(
      copySettings,
      'emailNotifications',
      FORM_DEFAULTS['emailNotifications'],
    ),
    useFee,
    feeValue: useFee === YES_NO[0].value ? String(copySettings['feeValue'] || 10) : '10',
    isEnableAllSymbols: get(
      copySettings,
      'isEnableAllSymbols',
      FORM_DEFAULTS['isEnableAllSymbols'],
    ),
    disableSymbols: get(copySettings, 'disableSymbols', FORM_DEFAULTS['disableSymbols']),
  };

  const disableSymbols = new Set(copySettings.disableSymbols || []);
  data.isEnableAllSymbols = disableSymbols.size === 0;

  return data;
};

export const getCopierSettings = (formData) => {
  const data = { ...formData };
  data.feeValue = data.useFee === YES_NO[0].value ? parseInt(data.feeValue) : 0;

  delete data.followerAccount;

  return data;
};

export const getOpenedPl = (analysisEquities) =>
  analysisEquities.reduce((res, data) => {
    res[data.date] = data.equity && data.balance ? data.equity - data.balance : 0;
    return res;
  }, {});

export const getPerformanceInitialData = (analysisDaily) => {
  let initialRunningPL = 0;
  return analysisDaily.reduce(
    (res, { deposit_withdraw, running_profit_loss, deposit, withdraw }, index) => {
      if (!index) {
        initialRunningPL = Number(running_profit_loss); // in case if performance for some period needed, initPL can be not 0 at the start of period
        res.profitLoss = initialRunningPL; // case when we have 1 day history
        res.initialBalance = Number(deposit_withdraw);
      } else {
        res.netDeposit += deposit + withdraw;
        res.profitLoss = Number(running_profit_loss) - initialRunningPL;
      }
      return res;
    },
    { initialBalance: 0, profitLoss: 0, netDeposit: 0 },
  );
};

export const getTotalPerformance = (account, analysisDaily) => {
  const { initialBalance, profitLoss, netDeposit } = getPerformanceInitialData(analysisDaily);
  if (!analysisDaily.length) {
    return 0;
  }

  const performance =
    ((profitLoss + (parseFloat(account.openedProfitLoss) || 0)) * 100) /
    (initialBalance + Math.max(0, netDeposit));
  return Number(toFixed(performance, 2));
};

export const resetCopierSettings = () => {
  let data = { ...EMPTY_FORM };

  return data;
};

export const getNewSettingsForCopiers = (data, follower) => {
  const followerSettings = setCopierSettings(follower);
  followerSettings.enabled = follower.enabled;

  let useFee = hasOnlyEmptyValues(data.useFee) ? followerSettings.useFee : data.useFee;
  let feeValue = hasOnlyEmptyValues(data.feeValue) ? followerSettings.feeValue : data.feeValue;

  followerSettings.feeValue = useFee === YES_NO[0].value ? parseInt(feeValue) : 0;

  for (let key in data) {
    followerSettings[key] = hasOnlyEmptyValues(data[key]) ? followerSettings[key] : data[key];
  }

  delete followerSettings.followerAccount;

  return followerSettings;
};

export const getPerformancePercent = (analysisDaily, openedPl, range) => {
  const filteredOpenedPl = Object.keys(openedPl).filter(
    (date) => String(moment(date).year()) === range,
  );

  let floatingPl = 0;
  if (filteredOpenedPl.length) {
    floatingPl = openedPl[filteredOpenedPl.slice(-1)[0]];
  }

  const filteredAnalysisDaily = analysisDaily.filter(
    ({ date }) => String(moment(date).year()) === range,
  );

  const { initialBalance, netDeposit, profitLoss } = getPerformanceInitialData(
    filteredAnalysisDaily,
  );
  const balance = initialBalance + Math.max(0, netDeposit);

  return toFixed(((profitLoss + floatingPl) * 100) / balance, 2);
};

export const datasetKeyProvider = () => {
  return btoa(String(Math.random())).substring(0, 12);
};
