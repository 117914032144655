import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';

import TPTypography from 'components/TP-UI/TPTypography';
import TPGrid from 'components/TP-UI/TPGrid';

import useStyles from './styles';

const NoDataAvailable = ({ text, image }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const { images } = useTheme();

  return (
    <TPGrid container direction="column" justifyContent="center" alignItems="center" spacing={1}>
      <TPGrid item>
        <img
          className={classes.image}
          src={image || images.common.waitingImg}
          alt={text || t('messages.noData')}
        />
      </TPGrid>
      <TPGrid item>
        <TPTypography variant="body1">{text || t('messages.noData')}</TPTypography>
      </TPGrid>
    </TPGrid>
  );
};

NoDataAvailable.propTypes = {
  text: PropTypes.string,
  image: PropTypes.string,
};

export default NoDataAvailable;
