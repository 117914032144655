import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import SecondaryButton from '../../SecondaryButton';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import styles from './styles';

const TableDeleteField = ({ classes, tableItem, onDeleteDetailsClick, label }) => {
  return (
    <div className={classes.deleteContainer}>
      <SecondaryButton
        onClick={() => onDeleteDetailsClick(tableItem._id)}
        content={<DeleteIcon />}
        label={label}
      />
    </div>
  );
};

TableDeleteField.propTypes = {
  className: PropTypes.string,
  onDeleteDetailsClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(TableDeleteField);
