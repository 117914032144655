import { createAction } from 'redux-actions';

export const mt4LiveGetCountriesRequest = createAction('MT4_LIVE_GET_COUNTRIES_REQUEST');
export const mt4LiveGetCountriesSuccess = createAction('MT4_LIVE_GET_COUNTRIES_SUCCESS');
export const mt4LiveGetCountriesFail = createAction('MT4_LIVE_GET_COUNTRIES_FAIL');

export const mt4LiveAddCountryRequest = createAction('MT4_LIVE_ADD_COUNTRY_REQUEST');
export const mt4LiveAddCountrySuccess = createAction('MT4_LIVE_ADD_COUNTRY_SUCCESS');
export const mt4LiveAddCountryFail = createAction('MT4_LIVE_ADD_COUNTRY_FAIL');

export const mt4LiveRemoveCountryRequest = createAction('MT4_LIVE_REMOVE_COUNTRY_REQUEST');
export const mt4LiveRemoveCountrySuccess = createAction('MT4_LIVE_REMOVE_COUNTRY_SUCCESS');
export const mt4LiveRemoveCountryFail = createAction('MT4_LIVE_REMOVE_COUNTRY_FAIL');

export const openAddCountryModal = createAction('MT4_LIVE_OPEN_ADD_COUNTRY_MODAL');
export const closeAddCountryModal = createAction('MT4_LIVE_CLOSE_ADD_COUNTRY_MODAL');

export const openRemoveCountryModal = createAction('MT4_LIVE_OPEN_REMOVE_COUNTRY_MODAL');
export const closeRemoveCountryModal = createAction('MT4_LIVE_CLOSE_REMOVE_COUNTRY_MODAL');
