import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import TPLink from 'components/TP-UI/TPLink';
import TPTypography from 'components/TP-UI/TPTypography';
import useStyles from './styles';

const TPNavMenuListItem = ({
  id,
  className,
  label,
  iconComponent: IconComponent,
  path = '',
  selected = false,
  minimized = false,
  labelText = '',
  onClick,
}) => {
  const classes = useStyles();
  const contentWrapperClassName = classNames(
    classes.contentWrapper,
    { [classes.selected]: selected },
    className,
  );

  return (
    <MenuItem id={id} key={id} selected={selected} onClick={onClick} className={classes.root}>
      {minimized || !path ? (
        <div className={contentWrapperClassName}>
          <ListItemIcon
            className={classNames(classes.iconContainer, { [classes.fullWidth]: minimized })}>
            <IconComponent />
          </ListItemIcon>
          {!minimized ? <TPTypography className={classes.text}>{label}</TPTypography> : null}
        </div>
      ) : (
        <TPLink path={path} className={contentWrapperClassName}>
          <ListItemIcon className={classes.iconContainer}>
            <IconComponent />
          </ListItemIcon>
          <TPTypography component="span" className={classes.text}>
            {label}
          </TPTypography>
          {labelText && (
            <TPTypography component="span" className={classes.label}>
              {labelText}
            </TPTypography>
          )}
        </TPLink>
      )}
    </MenuItem>
  );
};

TPNavMenuListItem.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  labelText: PropTypes.string,
  iconComponent: PropTypes.elementType.isRequired,
  path: PropTypes.string,
  selected: PropTypes.bool,
  minimized: PropTypes.bool,
  onClick: PropTypes.func,
};

export default TPNavMenuListItem;
