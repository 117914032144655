import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    body3: {
      ...theme.typography.body3,
    },
    inherit: {
      fontSize: 'inherit',
      lineHeight: 'inherit',
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    truncated: {
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      width: 'auto',
      overflow: 'hidden',
      wordBreak: 'break-all',
    },
  }),
  { name: 'TPTypography' },
);
