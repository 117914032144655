import React, { useCallback, useState } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import styles from './styles';
import { Field, reduxForm, change, getFormValues } from 'redux-form';
import SettingsIcon from '@material-ui/icons/Settings';
import { CUSTOM_DATE_RANGE_SELECTOR } from '../../constants';
import {
  DASHBOARD_OVERVIEW_DATA_HEADER_FORM,
  DASHBOARD_STATS_CUSTOMIZATION_MODAL,
} from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../../common/CustomModal/actions';
import StatsCustomizationModal from '../StatsCustomizationModal';
import { getDateRangeValuesByCustomType } from '../../utils/dateRange';
import Checkbox from '../../../common/Checkbox';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TPButton from 'components/TP-UI/TPButton';
import TPDatePicker from 'components/TP-UI/TPDatePicker';
import TPSelect from 'components/TP-UI/TPSelect';
import { required } from 'utils/validationRules';

const HeaderPanel = ({
  classes,
  handleSubmit,
  statsCustomizationProps,
  form,
  onNewSubmit,
  isUserEligibleNewMetrics,
}) => {
  const dispatch = useDispatch();
  const [customDateRangeItems, setCustomDateRangeItems] = useState(CUSTOM_DATE_RANGE_SELECTOR);
  const [customCompareDate, setCustomCompareDate] = useState(CUSTOM_DATE_RANGE_SELECTOR);
  const [expanded, setExpanded] = useState(false);

  const formValues = useSelector(getFormValues(form));
  const shouldCompare = formValues?.shouldCompare;

  const handleNewSubmit = useCallback(() => {
    onNewSubmit(formValues);
  }, [formValues, onNewSubmit]);

  const onCustomRangeChange = useCallback(
    (value) => {
      const { dateFrom, dateTo } = getDateRangeValuesByCustomType(value);
      dispatch(change(DASHBOARD_OVERVIEW_DATA_HEADER_FORM, 'dateFrom', dateFrom));
      dispatch(change(DASHBOARD_OVERVIEW_DATA_HEADER_FORM, 'dateTo', dateTo));
      setCustomDateRangeItems([...CUSTOM_DATE_RANGE_SELECTOR]);
    },
    [dispatch],
  );

  const onCompareRangeChange = useCallback(
    (value) => {
      const { dateFrom, dateTo } = getDateRangeValuesByCustomType(value);
      dispatch(change(DASHBOARD_OVERVIEW_DATA_HEADER_FORM, 'dateFromCompare', dateFrom));
      dispatch(change(DASHBOARD_OVERVIEW_DATA_HEADER_FORM, 'dateToCompare', dateTo));
      setCustomCompareDate([...CUSTOM_DATE_RANGE_SELECTOR]);
    },
    [dispatch],
  );

  const onDateChange = useCallback(() => {
    setCustomDateRangeItems([
      { label: 'Custom', value: 'custom', disabled: true },
      ...CUSTOM_DATE_RANGE_SELECTOR,
    ]);
    dispatch(change(DASHBOARD_OVERVIEW_DATA_HEADER_FORM, 'customDateSelector', 'custom'));
  }, [dispatch]);

  const onCompareDateChange = useCallback(() => {
    setCustomCompareDate([
      { label: 'Custom', value: 'custom', disabled: true },
      ...CUSTOM_DATE_RANGE_SELECTOR,
    ]);
    dispatch(change(DASHBOARD_OVERVIEW_DATA_HEADER_FORM, 'compareDateSelector', 'custom'));
  }, [dispatch]);

  const toggleStatsCustomizationModal = useCallback(
    () => dispatch(toggleModal(DASHBOARD_STATS_CUSTOMIZATION_MODAL)),
    [dispatch],
  );

  const toggleExpandFilter = useCallback(() => setExpanded(!expanded), [expanded]);

  const ExpandIcon = expanded ? ExpandLessIcon : ExpandMoreIcon;

  return (
    <>
      <form onSubmit={handleSubmit} noValidate>
        <Grid container className={classes.root} direction="column">
          <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Grid container>
                  <Grid item className={classes.customSelector}>
                    <Field
                      onChange={onCustomRangeChange}
                      name="customDateSelector"
                      id="customDateSelector"
                      component={TPSelect}
                      options={customDateRangeItems}
                      size="small"
                      reservedErrorSpace={false}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      name="dateFrom"
                      id="dateFrom"
                      component={TPDatePicker}
                      returnsStartDay
                      size="small"
                      required
                      validate={[required]}
                      reservedErrorSpace={false}
                      onChange={onDateChange}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      name="dateTo"
                      id="dateTo"
                      component={TPDatePicker}
                      size="small"
                      required
                      validate={[required]}
                      reservedErrorSpace={false}
                      onChange={onDateChange}
                    />
                  </Grid>
                  <Grid item>
                    <TPButton primary type="submit" className={classes.button} size="small">
                      Update (OLD)
                    </TPButton>
                  </Grid>
                  {isUserEligibleNewMetrics && (
                    <Grid item>
                      <TPButton
                        onClick={handleNewSubmit}
                        className={classes.button}
                        alternative
                        size="small">
                        Update (NEW)
                      </TPButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <SettingsIcon className={classes.icon} onClick={toggleStatsCustomizationModal} />
                <ExpandIcon className={classes.icon} onClick={toggleExpandFilter} />
              </Grid>
            </Grid>
          </Grid>
          {expanded && (
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item className={classes.textBetween}>
                      <Field name="shouldCompare" component={Checkbox} label="Compare to:" />
                    </Grid>
                    <Grid item className={classes.customSelector}>
                      <Field
                        onChange={onCompareRangeChange}
                        name="compareDateSelector"
                        id="compareDateSelector"
                        component={TPSelect}
                        options={customCompareDate}
                        size="small"
                        reservedErrorSpace={false}
                        disabled={!shouldCompare}
                      />
                    </Grid>
                    <Grid item className={classes.datePickerWrapper}>
                      <Field
                        name="dateFromCompare"
                        id="dateFromCompare"
                        onChange={onCompareDateChange}
                        component={TPDatePicker}
                        returnsStartDay
                        size="small"
                        required={shouldCompare}
                        validate={shouldCompare ? [required] : []}
                        reservedErrorSpace={false}
                        disabled={!shouldCompare}
                      />
                    </Grid>
                    <Grid item className={classes.datePickerWrapper}>
                      <Field
                        name="dateToCompare"
                        id="dateToCompare"
                        onChange={onCompareDateChange}
                        component={TPDatePicker}
                        size="small"
                        required={shouldCompare}
                        validate={shouldCompare ? [required] : []}
                        reservedErrorSpace={false}
                        disabled={!shouldCompare}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
      <StatsCustomizationModal
        {...statsCustomizationProps}
        toggleModal={toggleStatsCustomizationModal}
      />
    </>
  );
};

const HeaderPanelStyled = withStyles(styles)(HeaderPanel);

export default reduxForm()(HeaderPanelStyled);
