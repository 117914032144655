import {
  EXCEPTION_MODULE_PERMISSIONS_USER_LIST,
  ACTION_PERMISSIONS_USER_LIST,
  DEFAULT_USER_PERMISSIONS,
  ACTION_PERMISSION_TYPE,
  ACTIONS_PERMISSION_KEY,
  MODULES_PERMISSION_KEY,
  PERMISSION_TYPE,
} from 'constants/permissions';

const accessByModulesPermissions = (permissionKey, email, hub) => {
  const permissions = EXCEPTION_MODULE_PERMISSIONS_USER_LIST[email];

  if (!permissions) {
    const defaultPermissionsByHub = DEFAULT_USER_PERMISSIONS[hub];
    const defaultPermission = defaultPermissionsByHub && defaultPermissionsByHub[permissionKey];
    return defaultPermission
      ? defaultPermission === PERMISSION_TYPE.VIEW || defaultPermission === PERMISSION_TYPE.WRITE
      : true; //all available by default
  }

  const permission = permissions[permissionKey];
  if (permission) {
    return permission === PERMISSION_TYPE.VIEW || permission === PERMISSION_TYPE.WRITE;
  } else {
    // all NA by default for users from exception list
    return false;
  }
};

//All allow by default with checking action permissions list and default list
const accessByActionPermissions = (permissionKey, email, hub) => {
  const permissions = ACTION_PERMISSIONS_USER_LIST[email];
  const permission = permissions && permissions[permissionKey];
  if (permission) {
    return permission === ACTION_PERMISSION_TYPE.AVAILABLE;
  } else {
    const defaultPermissionsByHub = DEFAULT_USER_PERMISSIONS[hub];
    const defaultPermission = defaultPermissionsByHub && defaultPermissionsByHub[permissionKey];
    return defaultPermission ? defaultPermission === ACTION_PERMISSION_TYPE.AVAILABLE : true;
  }
};

export const accessByPersonalPermission = (permissionKey, email, hub) => {
  if (!permissionKey || !email) {
    return true;
  }

  email = email.toLowerCase();

  if (Object.values(MODULES_PERMISSION_KEY).some((key) => key === permissionKey)) {
    return accessByModulesPermissions(permissionKey, email, hub);
  } else if (Object.values(ACTIONS_PERMISSION_KEY).some((key) => key === permissionKey)) {
    return accessByActionPermissions(permissionKey, email, hub);
  } else {
    return true;
  }
};
