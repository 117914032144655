import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    spacing: {
      margin: theme.spacing(1),
    },
    container: {
      display: 'inline-flex',
    },
  }),
  { name: 'Tooltips' },
);

export default useStyles;
