import React from 'react';
import PropTypes from 'prop-types';
import Page from 'modules/common/Page';
import { useTranslation } from 'react-i18next';
import config from 'config';
import Layout from '../../../../components/LayoutNew';
import { SEYCHELLES } from 'constants/index';
import TPTypography from 'components/TP-UI/TPTypography';
import TPLink from 'components/TP-UI/TPLink';
import GoogleTagManager from 'components/GoogleTagManager';
import SignUpForm from '../SignUpForm';
import { useQuery } from '../../hooks/useQuery';
import { Routes } from 'constants/routeConstants';

import useStyles from './styles';

const SignUp = ({
  form,
  onSubmit,
  validate,
  onGoogleSignupSuccess,
  onAppleSignupSuccess,
  isPending,
  userCountry,
  phoneVerificationRequired,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('auth');
  const { CURRENT_HUB_LABEL } = config;
  const fsaHub = userCountry === SEYCHELLES;
  const query = useQuery();
  const phoneVerificationEnabled = query.get('phoneVerification') === 'true';
  const phoneRequired = phoneVerificationRequired || phoneVerificationEnabled;

  return (
    <Page
      docTitle={t('signUp.documentTitle', { company: CURRENT_HUB_LABEL })}
      docMeta={t('signUp.documentDescription')}>
      <GoogleTagManager />
      <Layout
        title={t('signUpTitle')}
        welcomeTitle={t('signUpWelcome')}
        welcomeDescription={t('signUpWelcomeDescription')}>
        <SignUpForm
          form={form}
          isPending={isPending}
          fsaHub={fsaHub}
          phoneRequired={phoneRequired}
          validate={validate}
          onSubmit={onSubmit}
          onAppleSignupSuccess={onAppleSignupSuccess}
          onGoogleSignupSuccess={onGoogleSignupSuccess}
        />
        <div className={classes.customLinkWrapper}>
          <TPTypography variant="body1">
            {t('alreadyHaveAccount')}{' '}
            <TPLink to={{ pathname: Routes.SIGNIN, state: { cleanupStore: true } }}>
              {t('buttons.logIn')}
            </TPLink>
          </TPTypography>
        </div>
      </Layout>
    </Page>
  );
};

SignUp.propTypes = {
  form: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isPending: PropTypes.bool,
  onGoogleSignupSuccess: PropTypes.func.isRequired,
  onAppleSignupSuccess: PropTypes.func.isRequired,
  userCountry: PropTypes.string,
  phoneVerificationRequired: PropTypes.bool,
};

export default SignUp;
