import { handleActions } from 'redux-actions';
import * as actions from '../actions';

const defaultState = {
  mt4login: '',
  account: {},
  analysis: {},
  daily: [],
  monthly: [],
  equities: [],
  tradesOpened: [],
  tradesClosed: [],
  tradesCount: {},
  copySettings: {},
  symbolMap: [],
  tradeSyncAccounts: [],
  portfolio: [],
  tradesClosedCount: 0,
  sign: '',
  shareLink: '',
};

export default handleActions(
  {
    [actions.clearData]: (state) => {
      return { ...state, ...defaultState };
    },
    [actions.getMyPerformanceSuccess]: (state, action) => {
      return { ...state, ...action.response.data, tradesClosed: [] };
    },
    [actions.getUserPerformanceSuccess]: (state, action) => {
      return { ...state, ...action.response.data, tradesClosed: [] };
    },
    [actions.getSharedPerformanceSuccess]: (state, action) => {
      return { ...state, ...action.response.data, tradesClosed: [] };
    },
    [actions.getCopierAccountSettingsSuccess]: (state, action) => {
      return { ...state, copySettings: action.response.data };
    },
    [actions.setSymbolMap]: (state, action) => {
      return { ...state, symbolMap: action.payload };
    },
    [actions.addSymbolMap]: (state) => {
      return { ...state, symbolMap: [...state.symbolMap, state.symbolMap.length] };
    },
    [actions.removeSymbolMap]: (state) => {
      return { ...state, symbolMap: state.symbolMap.slice(0, -1) };
    },
    [actions.getMyFollowersAccountsSuccess]: (state, action) => {
      return { ...state, tradeSyncAccounts: action.response.data };
    },
    [actions.getMyPerformanceTradesSuccess]: (state, action) => {
      return {
        ...state,
        tradesClosed: [...action.response.data.trades],
        tradesClosedCount: action.response.data.count,
      };
    },
    [actions.getUserPerformanceTradesSuccess]: (state, action) => {
      return {
        ...state,
        tradesClosed: [...action.response.data.trades],
        tradesClosedCount: action.response.data.count,
      };
    },
    [actions.getSharedPerformanceTradesSuccess]: (state, action) => {
      return {
        ...state,
        tradesClosed: [...state.tradesClosed, ...action.response.data.trades],
        tradesClosedCount: action.response.data.count,
      };
    },
    [actions.saveSign]: (state, action) => {
      return { ...state, sign: action.payload };
    },
    [actions.getPerformanceShareLinkSuccess]: (state, action) => {
      return { ...state, shareLink: action.response.data.shareLink };
    },
  },
  defaultState,
);
