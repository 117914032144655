import { handleActions } from 'redux-actions';
import get from 'lodash/get';

import {
  getInvitationLinkSuccess,
  getInvitationLinkFail,
  createInvitationLinkRequest,
  createInvitationLinkSuccess,
  createInvitationLinkFail,
} from '../actions';

const defaultState = {
  link: null,
  type: '',
  isLoading: true,
  createLinkPending: false,
};

export default handleActions(
  {
    [getInvitationLinkSuccess]: (state, action) => ({
      ...state,
      type: get(action, 'response.data.type', ''),
      link: get(action, 'response.data.link', ''),
      isLoading: false,
    }),
    [getInvitationLinkFail]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [createInvitationLinkRequest]: (state) => ({
      ...state,
      createLinkPending: true,
    }),
    [createInvitationLinkFail]: (state) => ({
      ...state,
      createLinkPending: false,
    }),
    [createInvitationLinkSuccess]: (state, action) => ({
      ...state,
      createLinkPending: false,
      type: get(action, 'response.data.type', ''),
      link: get(action, 'response.data.link', ''),
    }),
  },
  defaultState,
);
