import { combineActions, handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = null;

export default handleActions(
  {
    [combineActions(actionCreators.paymentsDepositBankTransfer)](state, action) {
      return action.payload.currency;
    },
    [combineActions(actionCreators.paymentsDepositBankTransferReset)]() {
      return null;
    },
  },
  defaultState,
);
