import api from 'modules/api';

export const getRefCodesRequest = (payload) => {
  return api.get('/validation-refcode', { params: payload });
};

export const createRefCodeRequest = ({ refCode, refCodes }) => {
  if (refCode) {
    return api.post('/validation-refcode', { refCode });
  }

  const firstFile = refCodes[0].file;

  const formData = new FormData();
  formData.append('file', firstFile);

  return api.post('/validation-refcode', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteRefCodeRequest = (refCode) => {
  return api.delete(`/validation-refcode/${refCode}`);
};
