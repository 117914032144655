import React from 'react';
import { MFA_ENABLE_VERIFICATION_FORM } from '../../constants';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TPButton from 'components/TP-UI/TPButton';
import TPGrid from 'components/TP-UI/TPGrid';
import EnableMfaForm from '../EnableMfaForm';
import { REDUX_FORM_DEFAULT_CONFIG } from 'constants/form';

const EnableMfa = ({ qrCode, secret, error, submitFailed, handleSubmit, button, loading }) => {
  const { t } = useTranslation('auth');

  return (
    <form onSubmit={handleSubmit} noValidate>
      <EnableMfaForm error={error} submitFailed={submitFailed} qrCode={qrCode} secret={secret} />
      {button ? (
        button
      ) : (
        <TPGrid container justifyContent="flex-end">
          <TPGrid item>
            <TPButton loading={loading} primary size="large" type="submit">
              {t('signUp.confirmPhone.verify')}
            </TPButton>
          </TPGrid>
        </TPGrid>
      )}
    </form>
  );
};

EnableMfa.propTypes = {
  qrCode: PropTypes.string,
  secret: PropTypes.string,
  button: PropTypes.node,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

export default reduxForm({
  ...REDUX_FORM_DEFAULT_CONFIG,
  form: MFA_ENABLE_VERIFICATION_FORM,
})(EnableMfa);
