export const CRYPTO_DAY1_TERMS_MODAL = 'CRYPTO_DAY1_TERMS_MODAL';

export const PSP_PERMISSION_KEY = {
  ASTROPAY: 'astropay',
  BANKWIRE: 'bankwire',
  CRYPTO_DAY1: 'crypto-day1',
  CRYPTO_FINRAX: 'crypto-finrax',
  CURRENCY_CLOUD: 'currencyCloud',
  CURRENCY_CLOUD_LOCAL: 'currencyCloudLocal',
  DRAGONPAY: 'dragonpay',
  JETON: 'jeton',
  INTERAC_E_TRANSFER_OUTBOUND: 'interac-e-transfer-outbound',
  HYPERWALLET: 'hyperwallet',
  LOCAL_BANK: 'local-bank',
  MIFINITY: 'mifinity',
  NETELLER: 'neteller',
  PAYID: 'payid',
  PAYPAL: 'paypal',
  PAYPAL_REFUND: 'paypal-refund',
  PERFECT_MONEY: 'perfect-money',
  PRAXIS: 'praxis',
  SKRILL: 'skrill',
  UNIONPAY: 'unionpay',
};

export const DATE_FORMAT_HISTORY = 'Do MMMM YYYY';

export const LOCAL_BANK_CONVERSION_FEE = 0.995;

export const THAI_BANK_DEFAULT_CURRENCY = 'thb';

export const LOCAL_BANKS_ASIAN_CURRENCIES = new Set(['MYR', 'IDR', 'VND']);

export const CRYPTO_ADDRESS_TYPE = {
  MEMO: 'memo',
  NOTE: 'note',
  TAG: 'tag',
  OTHER: 'other',
};
export const CRYPTO_ADDRESS_TYPE_LABELS = {
  [CRYPTO_ADDRESS_TYPE.MEMO]: 'payments:crypto.tags.memo',
  [CRYPTO_ADDRESS_TYPE.TAG]: 'payments:crypto.tags.tag',
  [CRYPTO_ADDRESS_TYPE.NOTE]: 'payments:crypto.tags.note',
};
