import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import formatDateToPassedTime from 'utils/formatDateToPassedTime';
import TPTypography from 'components/TP-UI/TPTypography';
import TPButton from 'components/TP-UI/TPButton';
import TPImage from 'components/TP-UI/TPImage';
import replaceLinks from './replaceLinks';
import useStyles from './styles';

const Notification = ({ notification, pending, onMarkAsRead }) => {
  const { t } = useTranslation('notificationCentre');
  const classes = useStyles();

  const handleMarkAsRead = useCallback(() => onMarkAsRead(notification._id), [
    onMarkAsRead,
    notification._id,
  ]);
  const descriptionComponents = useMemo(() => {
    const descriptionArray = (notification.description || '').split('{{image}}');

    const componentsArray = [];
    for (let i = 0; i < descriptionArray.length; i++) {
      descriptionArray[i].split('\n').forEach((text, index) => {
        componentsArray.push(
          <TPTypography
            variant="body2"
            className={classes.descriptionText}
            key={`${notification._id}-p-${i}-${index}`}>
            {replaceLinks({ text, className: classes.link })}
          </TPTypography>,
        );
      });
      if (notification.images[i]) {
        componentsArray.push(
          <TPImage
            key={`${notification._id}-text-img-${i}`}
            src={notification.images[i].url}
            className={classes.image}
            alt="notification"
          />,
        );
      }
    }
    for (let i = descriptionArray.length; i < notification.images.length; i++) {
      componentsArray.push(
        <TPImage
          key={`${notification._id}-img-${i}`}
          src={notification.images[i].url}
          className={classes.image}
          alt="notification"
        />,
      );
    }

    return componentsArray;
  }, [
    classes.descriptionText,
    classes.image,
    classes.link,
    notification._id,
    notification.description,
    notification.images,
  ]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <TPTypography variant="h5">{notification.title}</TPTypography>
        {!notification.viewed && (
          <TPButton secondary onClick={handleMarkAsRead} disabled={pending}>
            {t('page.markAsRead')}
          </TPButton>
        )}
      </div>
      {descriptionComponents.map((component) => component)}
      <TPTypography variant="body2" color="secondary">
        {formatDateToPassedTime(t, notification.created)}
      </TPTypography>
    </div>
  );
};

Notification.propTypes = {
  notification: PropTypes.shape({
    _id: PropTypes.string,
    viewed: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
    ),
    created: PropTypes.string,
    category: PropTypes.string,
  }).isRequired,
  pending: PropTypes.bool,
  onMarkAsRead: PropTypes.func,
};

export default Notification;
