import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';

const getValue = (value, multiple) => {
  let result = multiple && !value ? [value] : value;

  if (multiple) {
    if (Array.isArray(result)) {
      if (result.length === 0) {
        return [''];
      }
      if (result.length > 1) {
        if (result[result.length - 1] === '') {
          return [''];
        }
        const index = result.indexOf('');
        if (index > -1) {
          result.splice(index, 1);
        }
      }
    } else if (typeof result === 'string') {
      return [result];
    }
  }
  return result;
};

const FormSelect = ({
  classes,
  name,
  input: { onChange, value },
  items,
  className,
  multiple,
  disabled = false,
}) => {
  return (
    <Select
      value={getValue(value, multiple)}
      onChange={(e) => {
        onChange(getValue(e.target.value, multiple));
      }}
      displayEmpty
      disableUnderline
      name={name}
      className={classNames(classes.select, className)}
      disabled={disabled}
      inputProps={{
        classes: {
          root: classes.selectRoot,
        },
        multiple: !!multiple,
      }}>
      {items.map((item) => (
        <MenuItem
          key={`${item.label} - ${item.value}`}
          value={item.value}
          disabled={item.disabled}
          className={classNames({
            [classes.menuItem]: true,
            [classes.disabledItem]: item.disabled,
          })}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
};

FormSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.array,
      PropTypes.number,
    ]),
  }).isRequired,
  items: PropTypes.array.isRequired,
  multiple: PropTypes.bool,
};

export default withStyles(styles)(FormSelect);
