import React from 'react';
import TPGrid from 'components/TP-UI/TPGrid';
import TPLoader from 'components/TP-UI/TPLoader';
import TPTypography from 'components/TP-UI/TPTypography';
import useStyles from './styles';

const Loaders = () => {
  const classes = useStyles();
  return (
    <div>
      <TPGrid container spacing={1}>
        <TPGrid item xs={12}>
          <TPTypography variant="subtitle1">Default</TPTypography>
          <TPLoader />
        </TPGrid>
      </TPGrid>
      <TPGrid container spacing={1}>
        <TPGrid item xs={4}>
          <TPTypography variant="subtitle1">Custom size, secondary color</TPTypography>
          <TPLoader size={25} color="secondary" />
        </TPGrid>
        <TPGrid item xs={8} className={classes.container}>
          <TPTypography variant="subtitle1">Custom size, inherit color</TPTypography>
          <TPLoader size={80} color="inherit" />
        </TPGrid>
      </TPGrid>
    </div>
  );
};

export default Loaders;
