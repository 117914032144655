import get from 'lodash/get';
import { all, put, takeEvery, delay } from 'redux-saga/effects';
import * as actionCreators from '../actions';
import {
  TMD_WARNING_MODAL,
  TMD_LOCKED_MODAL,
  TMD_SUCCESS_MODAL,
  TMD_CHECK_MODAL,
  TMD_QUIZ_FORM,
  TMD_FAILED_LOGOUT_TIMEOUT,
} from '../constants';
import { openModal, closeModal } from 'components/TP-UI/TPModal/actions';
import { logout } from '../../../auth/actions';
import { reset, stopSubmit } from 'redux-form';
import { lockFailedTMDUser, setTmdCurrentQuestionIndex, setTmdProgressIndex } from '../actions';
import { LOGOUT_REASON } from '../../../auth/constants';

function* handleStartTMDProcess() {
  yield put(openModal(TMD_WARNING_MODAL));
}

function* handleTMDAnswersSuccess(action) {
  yield put(closeModal(TMD_CHECK_MODAL));
  yield put(reset(TMD_QUIZ_FORM));
  yield put(setTmdCurrentQuestionIndex(0));
  yield put(setTmdProgressIndex(0));
  const hasAccepted = get(action, 'response.data.user.verification.TMDAccepted.accepted', false);
  if (hasAccepted) {
    yield put(openModal(TMD_SUCCESS_MODAL));
  } else {
    yield put(lockFailedTMDUser());
  }
}

function* handleLockTMDUser() {
  yield put(openModal(TMD_LOCKED_MODAL));
  yield delay(TMD_FAILED_LOGOUT_TIMEOUT);
  yield put(closeModal(TMD_LOCKED_MODAL));
  yield put(logout({ reason: LOGOUT_REASON.TMD_FAIL }));
}
function* handleTMDAnswersFail() {
  yield put(
    stopSubmit(TMD_QUIZ_FORM, {
      _error: { key: 'errors.notifications.unexpectedError' },
    }),
  );
}

export default function* watchRequest() {
  yield all([
    takeEvery(actionCreators.startTMDProcess, handleStartTMDProcess),
    takeEvery(actionCreators.sendTmdAnswersSuccess, handleTMDAnswersSuccess),
    takeEvery(actionCreators.sendTmdAnswersFail, handleTMDAnswersFail),
    takeEvery(actionCreators.lockFailedTMDUser, handleLockTMDUser),
  ]);
}
