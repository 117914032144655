import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isMobileOnly } from 'react-device-detect';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import TPGrid from '../../../TPGrid';
import TPIconButton from '../../../TPIconButton';
import { TPAutocomplete } from '../../../TPAutocomplete';
import { MAX_MONTH_SIZE, MAX_YEAR_SIZE, MONTHS, YEARS } from './constants';

import useStyles from './styles';

const TPDatePickerHeader = ({
  forwardedRef,
  name,
  size,
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const classes = useStyles();
  const year = new Date(date).getFullYear();
  const month = new Date(date).getMonth();

  return (
    <TPGrid container alignItems="center" justifyContent="space-between" wrap="nowrap">
      <TPGrid item>
        <TPIconButton
          ref={forwardedRef}
          size="small"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}>
          <ArrowBackIosOutlinedIcon fontSize="small" />
        </TPIconButton>
      </TPGrid>
      <TPGrid item container spacing={1} justifyContent="center" wrap="nowrap">
        <TPGrid item>
          <TPAutocomplete
            name={`${name}_month`}
            size="small"
            value={month}
            options={MONTHS}
            autofocus={isMobileOnly}
            reservedErrorSpace={false}
            select
            className={classnames(classes.month, {
              [classes.xsmallDatepickerDropdown]: size === 'small',
            })}
            inputProps={{ size: MAX_MONTH_SIZE }}
            onChange={changeMonth}
          />
        </TPGrid>
        <TPGrid item>
          <TPAutocomplete
            name={`${name}_year`}
            size="small"
            value={year}
            options={YEARS}
            onChange={changeYear}
            className={classnames(classes.year, {
              [classes.xsmallDatepickerDropdown]: size === 'small',
            })}
            inputProps={{ size: MAX_YEAR_SIZE }}
            select
            reservedErrorSpace={false}
          />
        </TPGrid>
      </TPGrid>
      <TPGrid item>
        <TPIconButton size="small" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
          <ArrowForwardIosOutlinedIcon fontSize="small" />
        </TPIconButton>
      </TPGrid>
    </TPGrid>
  );
};

TPDatePickerHeader.propTypes = {
  /**
   * Form datepicker name
   */
  name: PropTypes.string.isRequired,
  /**
   * Datepicker text field size
   */
  size: PropTypes.oneOf(['medium', 'small']),
  /**
   * props are provided by react-datepicker
   */
  date: PropTypes.object,
  changeYear: PropTypes.func.isRequired,
  changeMonth: PropTypes.func.isRequired,
  decreaseMonth: PropTypes.func.isRequired,
  increaseMonth: PropTypes.func.isRequired,
  prevMonthButtonDisabled: PropTypes.bool,
  nextMonthButtonDisabled: PropTypes.bool,
};

export default React.forwardRef((props, ref) => (
  <TPDatePickerHeader {...props} forwardedRef={ref} />
));
