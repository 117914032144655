import { isObjectsEqual } from './object';

/*
 *  here arrays are compared by value and position in array
 *  for instance:
 *  [3, 4, 8] === [3, 4, 8]
 *  [3, 8, 4] !== [3, 4, 8]
 *  [3, 8] !== [3, 4, 8]
 *  [1, 2, 3] !== [4, 5, 6]
 * */
export const isArraysEqual = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }

  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i <= arr1.length; i++) {
    const arr1Item = arr1[i];
    const arr2Item = arr2[i];

    if (
      (Array.isArray(arr1Item) && Array.isArray(arr2Item) && !isArraysEqual(arr1Item, arr2Item)) ||
      (typeof arr1Item === 'object' &&
        typeof arr2Item === 'object' &&
        !isObjectsEqual(arr1Item, arr2Item)) ||
      arr2Item !== arr1Item
    ) {
      return false;
    }
  }

  return true;
};
