import palette from './palette';

const OverridesMuiPaper = {
  outlined: {
    borderColor: palette.primary.lightest,
  },
  rounded: {
    borderRadius: 10,
  },
};

export default OverridesMuiPaper;
