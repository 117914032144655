import React from 'react';

import ProOrRetailModal from '../components/ProOrRetailModal';
import AwaitingApprovalModal from '../components/AwaitingApprovalModal';
import FirstFailedQuizModal from '../components/FirstFailModal';
import SecondFailModal from '../components/SecondFailModal';
import SuccessQuizModal from '../components/SuccessQuizModal';
import ContinueAsRetailClientModal from '../components/ContinueAsRetailClientModal';
import ProQuizBlockedModal from '../components/ProQuizBlockedModal';
import QuizBlockedModal from '../components/QuizBlockedModal';
import UpgradeQuizBlockedModal from '../components/UpgradeQuizBlockedModal';
import ProfileQuizModal from 'modules/profileQuiz';
import TMD from 'modules/common/TMD/containers/TMDContainer';

const VerificationModalsManagerContainer = () => {
  return (
    <>
      <ProOrRetailModal />
      <ProfileQuizModal />
      <AwaitingApprovalModal />
      <FirstFailedQuizModal />
      <SecondFailModal />
      <SuccessQuizModal />
      <QuizBlockedModal />
      <UpgradeQuizBlockedModal />
      <ContinueAsRetailClientModal />
      <ProQuizBlockedModal />
      <TMD />
    </>
  );
};

export default VerificationModalsManagerContainer;
