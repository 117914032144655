import get from 'lodash/get';
import { handleActions } from 'redux-actions';
import {
  getAccountsVerificationNotificationsCountSuccess,
  getExperienceUploadsNotificationsCountSuccess,
  getFollowUpsVerificationNotificationsCountSuccess,
  getNotificationsSuccess,
  getPendingNotificationsCountSuccess,
  getUserNotificationsCountSuccess,
  getUserNotificationsSuccess,
  getUsersVerificationNotificationsCountSuccess,
  getKYCPendingCountSuccess,
  getKYCPendingFollowUpCountSuccess,
  markAllAsReadSuccess,
  markAsReadSuccess,
} from '../actions';

const defaultState = {
  notifications: null,
  notification: {},
  userNotificationsCount: 0,
  userNotifications: [],
  viewedUserNotificationIds: [],
  pendingNotificationsCount: 0,
  experienceUploadsNotificationsCount: 0,
  kycVerificationNotifications: [],
  kycVerificationNotificationsCount: 0,
  kycVerificationsFollowUpNotifications: [],
  kycVerificationsFollowUpNotificationsCount: 0,
};

export default handleActions(
  {
    [getNotificationsSuccess]: (state, action) => {
      return {
        ...state,
        notifications: action.response.data.map((notification) => ({
          ...notification,
          users: (notification.users || []).join(', '),
        })),
      };
    },
    [getUserNotificationsCountSuccess]: (state, action) => {
      return { ...state, userNotificationsCount: get(action, 'response.data.count', 0) };
    },
    [getUserNotificationsSuccess]: (state, action) => {
      return {
        ...state,
        userNotifications: get(action, 'response.data.notifications', []),
        viewedUserNotificationIds: get(action, 'response.data.viewedIds', []),
      };
    },
    [markAsReadSuccess]: (state, action) => {
      return {
        ...state,
        viewedUserNotificationIds: [...state.viewedUserNotificationIds, action.payload.id],
        userNotificationsCount: state.userNotificationsCount - 1,
      };
    },
    [markAllAsReadSuccess]: (state, action) => {
      return {
        ...state,
        viewedUserNotificationIds: [...state.viewedUserNotificationIds, ...action.payload.ids],
        userNotificationsCount: 0,
      };
    },
    [getPendingNotificationsCountSuccess]: (state, action) => {
      return { ...state, pendingNotificationsCount: get(action, 'response.data.count', 0) };
    },
    [getUsersVerificationNotificationsCountSuccess]: (state, action) => {
      return {
        ...state,
        usersVerificationNotificationsCount: get(action, 'response.data.count', 0),
      };
    },
    [getExperienceUploadsNotificationsCountSuccess]: (state, action) => {
      return {
        ...state,
        experienceUploadsNotificationsCount: get(action, 'response.data.count', 0),
      };
    },
    [getKYCPendingCountSuccess]: (state, action) => {
      return {
        ...state,
        kycVerificationNotificationsCount: get(action, 'response.data.count', 0),
      };
    },
    [getKYCPendingFollowUpCountSuccess]: (state, action) => {
      return {
        ...state,
        kycVerificationsFollowUpNotificationsCount: get(action, 'response.data.count', 0),
      };
    },
    [getAccountsVerificationNotificationsCountSuccess]: (state, action) => {
      return {
        ...state,
        accountsVerificationNotificationsCount: get(action, 'response.data.count', 0),
      };
    },
    [getFollowUpsVerificationNotificationsCountSuccess]: (state, action) => {
      return {
        ...state,
        followupsVerificationNotificationsCount: get(action, 'response.data.count', 0),
      };
    },
  },
  defaultState,
);
