import React from 'react';
import classnames from 'classnames';

import useStyles from './styles';

const TPCardActions = ({ noGutters, fullWidth, className, children }) => {
  const classes = useStyles();
  return (
    <div
      className={classnames(
        classes.root,
        { [classes.fullWidth]: fullWidth, [classes.noGutters]: noGutters },
        className,
      )}>
      {children}
    </div>
  );
};

export default TPCardActions;
