import { createAction } from 'redux-actions';

export const getPendingWithdrawalsRequest = createAction('GET_PENDING_WITHDRAWALS_REQUEST');
export const getPendingWithdrawalsSuccess = createAction('GET_PENDING_WITHDRAWALS_SUCCESS');
export const getPendingWithdrawalsFail = createAction('GET_PENDING_WITHDRAWALS_FAIL');

export const generatePendingWithdrawalsRequest = createAction(
  'GENERATE_PENDING_WITHDRAWALS_REQUEST',
);
export const generatePendingWithdrawalsSuccess = createAction(
  'GENERATE_PENDING_WITHDRAWALS_SUCCESS',
);
export const generatePendingWithdrawalsFail = createAction('GENERATE_PENDING_WITHDRAWALS_FAIL');

export const setPendingWithdrawalsQuery = createAction('SET_PENDING_WITHDRAWALS_QUERY');
