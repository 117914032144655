import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TPTypography from 'components/TP-UI/TPTypography';
import TPIconButton from 'components/TP-UI/TPIconButton';
import { TPTextField } from 'components/TP-UI/TPTextField';
import classnames from 'classnames';

import useStyles from './styles';

export const TPGoToPage = ({ pagesAmount = 0, disabled = false, onChange, className }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const [goto, setGoto] = useState();

  const handleGoToPageChange = useCallback((value) => {
    setGoto(value);
  }, []);

  const handleGoToPageClick = useCallback(() => {
    setGoto();
    if (goto !== undefined && onChange) {
      const newPage = parseInt(goto) - 1;
      onChange(newPage < 0 ? 0 : newPage >= pagesAmount ? pagesAmount - 1 : newPage);
    }
  }, [onChange, goto, pagesAmount]);

  return (
    <div className={classnames(classes.gotoContainer, className)}>
      <TPTypography variant="body2" color="secondary">
        {t('common:labels:goToPage')}
      </TPTypography>
      <TPTextField
        name="goToPage"
        value={goto}
        type="number"
        size="small"
        onChange={handleGoToPageChange}
        disabled={disabled}
        className={classes.gotoInput}
        reservedErrorSpace={false}
      />
      <TPIconButton disabled={disabled} onClick={handleGoToPageClick}>
        {t('common:buttons:go')}
      </TPIconButton>
    </div>
  );
};

TPGoToPage.propTypes = {
  /**
   * Total number of pages
   */
  pagesAmount: PropTypes.number,
  disabled: PropTypes.bool,
  /**
   * Called when the page number is entered, function (page)
   */
  onChange: PropTypes.func,
};

export default TPGoToPage;
