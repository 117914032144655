import isElInViewport from './isElInViewport';

// Redux supports subforms, so errors for such forms are returned as nested objects
// example
// { profileDetails : { firstName: { key: 'common.required' } } }
// and set id for form components like 'profileDetails.firstName'.
// Redux supports FieldArrays, so errors for such component are returned as an array,
// including undefined value if there is no error for exact form field item,
// and set id for form nested items like 'refunds[0].amount'.
const getFirstError = (errors, keys) => {
  const firstKey = Array.isArray(errors) ? errors.findIndex((e) => e) : Object.keys(errors)[0];
  return typeof errors[firstKey] === 'string' || errors[firstKey]?.key || errors[firstKey]?._error
    ? [keys, firstKey].filter((val) => val).join('.')
    : getFirstError(
        errors[firstKey],
        Array.isArray(errors)
          ? `${keys}[${firstKey}]`
          : [keys, firstKey].filter((val) => val).join('.'),
      );
};

const scrollIntoFirstFormError = (errors) => {
  const firstError = getFirstError(errors);
  const el =
    document.querySelector(`[id="${firstError}"]`) ||
    document.querySelector(`[name="${firstError}"]`);
  const isInViewport = isElInViewport(el);
  if (el && !isInViewport && el.scrollIntoView) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};
export default scrollIntoFirstFormError;
