export const hasOnlyEmptyValues = (item) => {
  if (item === undefined || item === null || item === '') {
    return true;
  }

  if (Array.isArray(item)) {
    return !item.length || item.every((value) => hasOnlyEmptyValues(value));
  }

  if (typeof item === 'object') {
    return hasOnlyEmptyValues(Object.values(item));
  }

  return false;
};
