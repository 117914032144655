import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as apiSelectors from 'modules/api/selectors';
import NetDepositView from '../views/NetDepositView';
import { getNetDepositDataRequest } from '../actions';
import { getNetDeposit } from '../selectors';
import get from 'lodash/get';
import { DASHBOARD_ACTIVE_USERS_HEADER_FORM } from '../constants';
import { CUSTOM_DATE_RANGE_KEY } from '../constants';
import moment from 'moment';
import { DATE_FORMAT_YYYYMMDD } from '../../../constants';
import { Routes } from 'constants/routeConstants';

const NetDepositContainer = () => {
  const dispatch = useDispatch();
  const [sortBy, setSortBy] = useState('netDepoist');
  const [sortDirection, setSortDirection] = useState(-1);
  const [netDepositData, setNetDepositData] = useState([]);
  const isPending = useSelector(apiSelectors.createPendingSelector(getNetDepositDataRequest));
  const netDepositDataResponse = useSelector(getNetDeposit);

  useEffect(() => {
    const dateFrom = moment()
      .startOf('month')
      .format(DATE_FORMAT_YYYYMMDD);
    const dateTo = moment().format(DATE_FORMAT_YYYYMMDD);
    dispatch(getNetDepositDataRequest({ dateFrom, dateTo }));
  }, [dispatch]);

  useEffect(() => {
    setNetDepositData(netDepositDataResponse);
  }, [netDepositDataResponse]);

  const handleHeaderFormSubmit = useCallback(
    (data) => {
      const dateFrom = get(data, 'dateFrom', moment());
      const dateTo = get(data, 'dateTo', moment());
      const formattedDateFrom = moment(dateFrom).format(DATE_FORMAT_YYYYMMDD);
      const formattedDateTo = moment(dateTo).format(DATE_FORMAT_YYYYMMDD);

      dispatch(getNetDepositDataRequest({ dateFrom: formattedDateFrom, dateTo: formattedDateTo }));
    },
    [dispatch],
  );

  const headerProps = {
    form: DASHBOARD_ACTIVE_USERS_HEADER_FORM,
    initialValues: {
      customDateSelector: CUSTOM_DATE_RANGE_KEY.MONTH_TO_DATE,
      dateFrom: moment()
        .startOf('month')
        .format(DATE_FORMAT_YYYYMMDD),
      dateTo: moment().format(DATE_FORMAT_YYYYMMDD),
    },
    onSubmit: handleHeaderFormSubmit,
  };

  const onSort = useCallback(
    (field) => {
      let direction = -1;
      if (field === sortBy) {
        direction = sortDirection * -1;
      }

      const sorted = netDepositDataResponse.sort((a, b) => {
        if (a[field] > b[field]) {
          return direction;
        }
        if (a[field] < b[field]) {
          return direction * -1;
        }
        return 0;
      });
      setNetDepositData([...sorted]);
      setSortBy(field);
      setSortDirection(direction);
    },
    [netDepositDataResponse, sortBy, sortDirection],
  );

  const isSorted = useCallback(
    (field) => {
      return sortBy === field;
    },
    [sortBy],
  );

  const getSortDirection = useCallback(() => {
    return sortDirection;
  }, [sortDirection]);

  const getRedirectRoute = useCallback(
    (item) => `${Routes.USER_MANAGEMENT_DETAILS}/${item._id}`,
    [],
  );

  return (
    <NetDepositView
      isPending={isPending}
      items={netDepositData}
      headerProps={headerProps}
      isDataFiltered={false}
      onSort={onSort}
      isSorted={isSorted}
      getSortDirection={getSortDirection}
      getRedirectRoute={getRedirectRoute}
    />
  );
};

export default NetDepositContainer;
