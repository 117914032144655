import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  () => ({
    offset: {
      transform: ({ offsetX, offsetY }) => `scale(1) translate(${offsetX}%, ${offsetY}%)`,
    },
  }),
  { name: 'TPBadge' },
);

export default useStyles;
