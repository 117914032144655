const OverridesMuiDrawer = {
  root: {
    // theme.zIndex.drawer: 1200, to display the element correctly
    // this index takes into account the display of modals,
    // bottom nav bar and dropdown menu, as well as onboarding
    zIndex: '1200 !important',
  },
};

export default OverridesMuiDrawer;
