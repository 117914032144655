import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Typography } from '@material-ui/core';
import formatPrice from 'utils/formatPrice';
import TableSortButton from '../TableSortButton';

import useStyles from './styles';

const TableAmountField = ({
  label,
  dataLabel,
  tableItem,
  className,
  currencyLabel,
  sort,
  rowIndex,
}) => {
  const classes = useStyles();
  return (
    <div className={classNames([classes.amountContainer, className])}>
      <div>
        <Typography className={classes.label}>
          {label}
          {rowIndex === 0 && sort ? (
            <TableSortButton
              dataLabel={dataLabel}
              onSort={sort.onSort}
              isSorted={sort.isSorted}
              getSortDirection={sort.getSortDirection}
            />
          ) : null}
        </Typography>
        <Typography className={classes.amountText}>
          {formatPrice(
            tableItem[dataLabel],
            currencyLabel ? tableItem[currencyLabel] : tableItem.currency,
          )}
        </Typography>
      </div>
    </div>
  );
};

TableAmountField.propTypes = {
  label: PropTypes.string.isRequired,
  dataLabel: PropTypes.string.isRequired,
  tableItem: PropTypes.object,
  className: PropTypes.string,
  currencyLabel: PropTypes.string,
};

export default TableAmountField;
