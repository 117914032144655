import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { TPModal } from '../../../TPModal';
import { WEBCAM_STATE } from '../TPTakePhoto/constants';
import useTakePhoto from '../../hooks/useTakePhoto';
import TPTakePhoto from '../TPTakePhoto';

const TPTakePhotoModal = ({
  value,
  onTakePhoto,
  onClose,
  webcamStatusMessages,
  title = 'Take a Photo',
  captureButtonLabel = 'Capture',
  cancelButtonLabel = 'Cancel',
  okButtonLabel = 'Got It!',
}) => {
  const playerRef = useRef();
  const {
    cameraAllowed,
    imageDataURL,
    canTakePhoto,
    webcamState,
    initializeMedia,
    stopTracking,
    capture,
  } = useTakePhoto();

  const handleCaptureClick = useCallback(() => {
    capture(playerRef, onTakePhoto);
  }, [capture, onTakePhoto]);

  const handelClose = useCallback(() => {
    stopTracking(playerRef);
    if (onClose) {
      onClose();
    }
  }, [stopTracking, onClose]);

  const buttons = useMemo(() => {
    return [
      {
        label: canTakePhoto ? captureButtonLabel : okButtonLabel,
        primary: true,
        disabled: webcamState === WEBCAM_STATE.WAIT,
        onClick: canTakePhoto ? handleCaptureClick : handelClose,
      },
      ...(canTakePhoto
        ? [
            {
              label: cancelButtonLabel,
              secondary: true,
              onClick: handelClose,
            },
          ]
        : []),
    ];
  }, [
    captureButtonLabel,
    okButtonLabel,
    cancelButtonLabel,
    handelClose,
    handleCaptureClick,
    webcamState,
    canTakePhoto,
  ]);

  useEffect(() => {
    if (value) {
      initializeMedia(playerRef);
    }
    return () => {
      stopTracking(playerRef);
    };
  }, [value, stopTracking, initializeMedia]);

  return (
    <TPModal title={title} value={value} onClose={onClose} buttons={buttons}>
      <TPTakePhoto
        playerRef={playerRef}
        imageDataURL={imageDataURL}
        cameraAllowed={cameraAllowed}
        webcamState={webcamState}
        webcamStatusMessages={webcamStatusMessages}
      />
    </TPModal>
  );
};

TPTakePhotoModal.propTypes = {
  value: PropTypes.bool.isRequired,
  onTakePhoto: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  webcamStatusMessages: PropTypes.shape({
    denied: PropTypes.string,
    granted: PropTypes.string,
    wait: PropTypes.string,
    noCamera: PropTypes.string,
  }),
  title: PropTypes.string,
  captureButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  okButtonLabel: PropTypes.string,
};

export default TPTakePhotoModal;
