import { createAction } from 'redux-actions';

export const getUserRequest = createAction('GET_USER_REQUEST');
export const getUserSuccess = createAction('GET_USER_SUCCESS');
export const getUserFail = createAction('GET_USER_FAIL');

export const updateUserDetailsRequest = createAction('UPDATE_USER_DETAILS_REQUEST');
export const updateUserDetailsSuccess = createAction('UPDATE_USER_DETAILS_SUCCESS');
export const updateUserDetailsFail = createAction('UPDATE_USER_DETAILS_FAIL');

export const getUserPhoneNumberRequest = createAction('GET_USER_PHONE_NUMBER_REQUEST');
export const getUserPhoneNumberSuccess = createAction('GET_USER_PHONE_NUMBER_SUCCESS');
export const getUserPhoneNumberFail = createAction('GET_USER_PHONE_NUMBER_FAIL');

export const editUserDetailsClose = createAction('EDIT_USER_DETAILS_CLOSE');
export const editUserDetailsOpen = createAction('EDIT_USER_DETAILS_OPEN');

export const editUserTradingStatus = createAction('EDIT_USER_TRADING_STATUS');
