import fmDate from 'utils/fmDate';
import { DATE_FORMAT_YYYYMMDD } from 'constants/index';

export const mapUserVolumesParamsToRequestPayload = (data) => {
  const { dateFrom, dateTo, size } = data;
  return {
    from: fmDate(dateFrom).format(DATE_FORMAT_YYYYMMDD),
    to: fmDate(dateTo).format(DATE_FORMAT_YYYYMMDD),
    size,
  };
};
