import React from 'react';
import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Snackbar from '@material-ui/core/Snackbar';
import TPAlert from 'components/TP-UI/TPAlert';
import { getTranslation } from 'utils/compositeTranslationHandler';
import { ALERT_VARIANTS } from 'components/TP-UI/TPAlert/constants';
import { anchorOriginDefault } from 'components/TP-UI/TPSnackbar/config';

import useStyles from './styles';

const TPSnackbarBase = ({
  anchorOrigin = anchorOriginDefault,
  variant = ALERT_VARIANTS.INFO,
  message: Message = '',
  open = false,
  onClose,
  autoHideDuration = 5000,
  hideIcon = false,
  minWidth = '400px',
  maxWidth = '400px',
  offset = 0,
  forwardedRef,
}) => {
  const classes = useStyles({ offset, anchorOrigin });
  const { t } = useTranslation();

  return (
    <Snackbar
      ref={forwardedRef}
      anchorOrigin={anchorOrigin}
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      className={classes.snackbar}>
      <TPAlert
        variant={variant}
        onClose={onClose}
        hideIcon={hideIcon}
        minWidth={isMobileOnly ? '100%' : minWidth}
        maxWidth={maxWidth}>
        {typeof Message === 'function' ? <Message /> : getTranslation(t, Message) || Message}
      </TPAlert>
    </Snackbar>
  );
};

TPSnackbarBase.propTypes = {
  anchorOrigin: PropTypes.shape({
    horizontal: PropTypes.oneOf(['center', 'left', 'right']),
    vertical: PropTypes.oneOf(['bottom', 'top']),
  }),
  variant: PropTypes.oneOf(Object.values(ALERT_VARIANTS)),
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  autoHideDuration: PropTypes.number,
  hideIcon: PropTypes.bool,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
};

const TPSnackbar = React.forwardRef((props, ref) => (
  <TPSnackbarBase {...props} forwardedRef={ref} />
));

export default TPSnackbar;
