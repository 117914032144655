import { handleActions } from 'redux-actions';
import {
  cleanupTicketConversations,
  cleanupTicketDetails,
  cleanupTickets,
  getTicketConversationsFail,
  getTicketConversationsSuccess,
  getTicketDetailsFail,
  getTicketDetailsSuccess,
  getTicketsFail,
  getTicketsSuccess,
  updateTicketConversationsCurrentPage,
} from '../actions';

const defaultState = {
  tickets: null,
  ticketDetails: null,
  conversations: null,
  conversationsPage: 0,
};

export default handleActions(
  {
    [getTicketsSuccess](state, action) {
      return {
        ...state,
        tickets: action.response.data.tickets,
      };
    },
    [getTicketsFail](state) {
      return {
        ...state,
        tickets: [],
      };
    },
    [getTicketDetailsSuccess](state, action) {
      return { ...state, ticketDetails: action.response.data.ticketDetails };
    },
    [getTicketDetailsFail](state) {
      return { ...state, ticketDetails: {} };
    },
    [getTicketConversationsSuccess](state, action) {
      const conversations = (state.conversations || []).concat(action.response.data.conversations);
      return { ...state, conversations, isLastPage: action.response.data.isLastPage };
    },
    [getTicketConversationsFail](state) {
      return {
        ...state,
        conversations: state.conversations || [],
        isLastPage: state.isLastPage !== undefined ? state.isLastPage : true,
        conversationsPage: state.conversationsPage > 0 ? state.conversationsPage - 1 : 0,
      };
    },
    [updateTicketConversationsCurrentPage](state, action) {
      return {
        ...state,
        conversationsPage: action.payload,
      };
    },
    [cleanupTickets](state) {
      return {
        ...state,
        tickets: defaultState.tickets,
      };
    },
    [cleanupTicketDetails](state) {
      return {
        ...state,
        ticketDetails: defaultState.ticketDetails,
      };
    },
    [cleanupTicketConversations](state) {
      return {
        ...state,
        conversations: defaultState.conversations,
        isLastPage: defaultState.isLastPage,
        conversationsPage: defaultState.conversationsPage,
      };
    },
  },
  defaultState,
);
