import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = {
  items: [],
  total: 0,
};

export default handleActions(
  {
    [actionCreators.getEmailTemplatesSuccess](state, action) {
      return {
        items: action.response.data.templates,
        total: action.response.data.totalNumber,
      };
    },
    [actionCreators.editEmailTemplateSuccess](state, action) {
      const editedTemplate = action.response?.data;
      const newList = state.items?.map((template) => {
        return editedTemplate && template._id === editedTemplate._id
          ? { ...editedTemplate }
          : template;
      });
      return {
        ...state,
        items: newList,
      };
    },
    [actionCreators.deleteEmailTemplateSuccess](state, action) {
      const deletedTemplateId = action.payload.id;
      const newList = state.items?.filter((template) => template._id !== deletedTemplateId);
      return {
        ...state,
        items: newList,
      };
    },
  },
  defaultState,
);
