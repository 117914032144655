import config from 'config';
import React, { useCallback, useEffect } from 'react';
import useStyles from './styles';
import classnames from 'classnames';
import { getOriginHub } from 'helpers/getOriginHub';

const APPLE_SIGNUP_SCRIPT_URL =
  'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';

const AppleSignup = ({ onSuccess, disabled, type = 'sign-up' }) => {
  const classes = useStyles();

  const handleSuccess = useCallback(
    (response) => {
      const user = response.detail.user;
      const authorization = response.detail.authorization;

      onSuccess({ appleUser: user, authorization });
    },
    [onSuccess],
  );

  const handleFailure = useCallback((error) => {
    console.log('appleSignup: error', error);
  }, []);

  const initAppleAuth = () => {
    window.AppleID &&
      window.AppleID.auth.init({
        clientId: config.APPLE_SIGNIN.CLIENT_ID,
        scope: 'email name',
        //The redirectURI must match the configurations in the Apple Developer dashboard
        redirectURI: getOriginHub() + config.APPLE_SIGNIN.REDIRECT_URI,
        state: 'state',
        nonce: 'nonce',
        usePopup: true,
      });
  };

  const loadAppleScriptHandler = useCallback(() => {
    initAppleAuth();
  }, []);

  const generateAppleScript = useCallback(() => {
    let appleScript = document.querySelector('#apple-signup-script');

    if (!appleScript) {
      appleScript = document.createElement('script');

      appleScript.setAttribute('src', APPLE_SIGNUP_SCRIPT_URL);
      appleScript.setAttribute('id', 'apple-signup-script');
      appleScript.async = true;
      document.body.appendChild(appleScript);
    }

    if (!window.AppleID) {
      appleScript.onload = loadAppleScriptHandler;
    } else {
      initAppleAuth();
    }

    return appleScript;
  }, [loadAppleScriptHandler]);

  useEffect(() => {
    const appleScript = generateAppleScript();

    document.addEventListener('AppleIDSignInOnSuccess', handleSuccess);
    document.addEventListener('AppleIDSignInOnFailure', handleFailure);

    return () => {
      document.removeEventListener('AppleIDSignInOnSuccess', handleSuccess);
      document.removeEventListener('AppleIDSignInOnFailure', handleFailure);

      appleScript.onload = () => {};
    };
  }, [handleSuccess, handleFailure, generateAppleScript]);

  return (
    <div className={classnames(classes.root, { [classes.disabled]: disabled })}>
      <div
        className={classes.signupButton}
        id="appleid-signin"
        data-mode="left-align"
        data-type={type}
        data-color="white"
        data-border="false"
        data-width="210"
        data-height="48"
        data-logo-size="large"
        data-logo-position="16"
        data-label-position="50"
      />
    </div>
  );
};

export default AppleSignup;
