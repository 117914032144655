import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      '& .slick-prev': {
        left: -16,
      },
      '& .slick-next': {
        right: -16,
      },
      '& .slick-prev, & .slick-next': {
        height: 16,
        width: 16,
        background: theme.palette.primary.lightest,
      },
      '& .slick-prev:before, & .slick-next:before': {
        fontSize: 10,
        color: theme.palette.primary.text,
        display: 'inline-block',
      },
      '& .slick-disabled, & .slick-disabled:before': {
        opacity: 0.5,
      },
      '& .slick-prev:before': {
        paddingRight: 1,
      },
      '& .slick-next:before': {
        paddingLeft: 1,
      },
    },
  }),
  { name: 'TPSlider' },
);
