import React from 'react';
import PropTypes from 'prop-types';
import { STATUS_GROUP_NAME } from './constants';
import { getStatusGroup } from './helpers/statusGroup';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import get from 'lodash/get';
import TPTooltip from 'components/TP-UI/TPTooltip';
import StatusLabel from './components/StatusLabel';

import useStyles from './styles';

const Status = ({ value = '', size = 'small', tooltips, labels }) => {
  const classes = useStyles();
  const statusGroup = getStatusGroup(value);
  const tooltip = get(tooltips, value);

  const labelProps = { statusGroup, size, value, labels };

  return (
    <span className={classes.statusContainer}>
      {statusGroup ? (
        <span className={classes.statusIcon}>
          {statusGroup === STATUS_GROUP_NAME.APPROVED && (
            <CheckCircleOutlineIcon className={classes.approvedIcon} fontSize={size} />
          )}
          {statusGroup === STATUS_GROUP_NAME.PARTIAL_APPROVED && (
            <CheckCircleOutlineIcon className={classes.partialApprovedIcon} fontSize={size} />
          )}
          {statusGroup === STATUS_GROUP_NAME.PENDING && (
            <AccessTimeIcon fontSize={size} color="disabled" />
          )}
          {statusGroup === STATUS_GROUP_NAME.DECLINED && (
            <HighlightOffIcon fontSize={size} color="error" />
          )}
          {statusGroup === STATUS_GROUP_NAME.OPENED && (
            <FiberManualRecordIcon className={classes.openedIcon} fontSize={size} color="primary" />
          )}
          {statusGroup === STATUS_GROUP_NAME.UNSELECTED && (
            <RemoveCircleOutlineOutlinedIcon fontSize={size} className={classes.unselectedIcon} />
          )}
        </span>
      ) : null}
      {tooltip ? (
        <TPTooltip content={tooltip}>
          <StatusLabel {...labelProps} />
        </TPTooltip>
      ) : (
        <StatusLabel {...labelProps} />
      )}
    </span>
  );
};

Status.propTypes = {
  value: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['medium', 'small']),
  tooltips: PropTypes.object,
  labels: PropTypes.object,
};

export default Status;
