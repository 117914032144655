import { createAction } from 'redux-actions';

export const signVpsRequest = createAction('SIGN_VPS_REQUEST');
export const signVpsSuccess = createAction('SIGN_VPS_SUCCESS');
export const signVpsFail = createAction('SIGN_VPS_FAIL');

export const getVpsLotsRequest = createAction('GET_VPS_LOTS_REQUEST');
export const getVpsLotsSuccess = createAction('GET_VPS_LOTS_SUCCESS');
export const getVpsLotsFail = createAction('GET_VPS_LOTS_FAIL');

export const cancelVpsRequest = createAction('CANCEL_VPS_REQUEST');
export const cancelVpsSuccess = createAction('CANCEL_VPS_SUCCESS');
export const cancelVpsFail = createAction('CANCEL_VPS_FAIL');
