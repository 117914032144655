import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import TPTooltip from 'components/TP-UI/TPTooltip';
import TPIconButton from 'components/TP-UI/TPIconButton';
import TPMenu from 'components/TP-UI/TPMenu';
import TuneIcon from '@material-ui/icons/Tune';
import TPBadge from 'components/TP-UI/TPBadge';
import { useDispatch } from 'react-redux';
import TPDivider from 'components/TP-UI/TPDivider';
import TPPresetFiltersCreateModal from './components/TPPresetFiltersCreateModal';
import TPPresetFiltersSaveModal from './components/TPPresetFiltersSaveModal';
import TPPresetFiltersDeleteModal from './components/TPPresetFiltersDeleteModal';
import PropTypes from 'prop-types';
import { openModal, closeModal } from 'components/TP-UI/TPModal/actions';
import {
  CREATE_FILTER_PRESET_MODAL_POSTFIX,
  SAVE_FILTER_PRESET_MODAL_POSTFIX,
  DELETE_FILTER_PRESET_MODAL_POSTFIX,
  CREATE_FILTER_PRESET_FORM_POSTFIX,
  SAVE_FILTER_PRESET_FORM_POSTFIX,
  DELETE_FILTER_PRESET_FORM_POSTFIX,
} from 'modules/common/PresetFilters/constants';
import { reset } from 'redux-form';
import { isObjectsEqual } from 'components/TP-UI/helpers/object';
import { ALL_TYPES_OF_PRESETS } from './constants';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

const TPPresetFilters = ({
  className,
  disabled,
  filterFormValues,
  settings,
  onApplyPreset,
  initialFilterValues,
  translationConfig = {},
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation('common');

  const {
    presetType = ALL_TYPES_OF_PRESETS,
    presetFiltersList = [],
    presetFilterHandlers: { create: createPreset, save: savePreset, remove: deletePreset },
    loading: { generalLoading, createPresetLoading, savePresetLoading, deletePresetLoading },
  } = settings;

  const [presetFilter, setPresetFilter] = useState(null);

  const createModalName = `${presetType}${CREATE_FILTER_PRESET_MODAL_POSTFIX}`;
  const saveModalName = `${presetType}${SAVE_FILTER_PRESET_MODAL_POSTFIX}`;
  const deleteModalName = `${presetType}${DELETE_FILTER_PRESET_MODAL_POSTFIX}`;

  const createFormName = `${presetType}${CREATE_FILTER_PRESET_FORM_POSTFIX}`;
  const saveFormName = `${presetType}${SAVE_FILTER_PRESET_FORM_POSTFIX}`;
  const deleteFormName = `${presetType}${DELETE_FILTER_PRESET_FORM_POSTFIX}`;

  const filtersList = useMemo(
    () =>
      presetFiltersList.map((filter) => ({
        ...filter,
        key: filter._id,
      })),
    [presetFiltersList],
  );

  useEffect(() => {
    if (presetFilter && !isObjectsEqual(presetFilter.filters, filterFormValues)) {
      setPresetFilter(null);
    }
  }, [filterFormValues, presetFilter, setPresetFilter]);

  const handleCreate = useCallback(() => {
    dispatch(openModal(createModalName));
  }, [createModalName, dispatch]);

  const handleSave = useCallback(() => {
    dispatch(openModal(saveModalName));
  }, [saveModalName, dispatch]);

  const handleDelete = useCallback(() => {
    dispatch(openModal(deleteModalName));
  }, [deleteModalName, dispatch]);

  const handleCancelCreation = useCallback(() => {
    dispatch(reset(createFormName));
    dispatch(closeModal(createModalName));
  }, [createModalName, createFormName, dispatch]);

  const handleCancelSaving = useCallback(() => {
    dispatch(reset(saveFormName));
    dispatch(closeModal(saveModalName));
  }, [saveModalName, saveFormName, dispatch]);

  const handleCancelDeleting = useCallback(() => {
    dispatch(reset(deleteFormName));
    dispatch(closeModal(deleteModalName));
  }, [deleteModalName, deleteFormName, dispatch]);

  const handleSubmitCreationPresetFilter = useCallback(
    (values) => {
      createPreset({
        label: values?.presetName,
        filters: filterFormValues,
      });
    },
    [filterFormValues, createPreset],
  );

  const handleSubmitSavingPresetFilter = useCallback(
    (values) => {
      savePreset({
        _id: values?.presetId,
        filters: filterFormValues,
      });
    },
    [savePreset, filterFormValues],
  );

  const handleSubmitDeletingPresetFilter = useCallback(
    (values) => {
      deletePreset({
        _id: values?.presetId,
      });
    },
    [deletePreset],
  );

  const handleSetFilter = useCallback(
    (_id) => {
      if (!_id) {
        return;
      }

      const value = filtersList.find((item) => item._id === _id);
      const { filters } = value;
      setPresetFilter(value);

      onApplyPreset(filters);
    },
    [filtersList, onApplyPreset],
  );

  const isFilterHasDefaultSetting = useMemo(
    () => isObjectsEqual(initialFilterValues, filterFormValues),
    [initialFilterValues, filterFormValues],
  );

  const actionsItems = useMemo(
    () =>
      [
        {
          renderItem: () => <TPDivider className={classes.menuDivider} variant="middle" />,
          noGutters: true,
          readonly: true,
          visible: !!filtersList.length,
        },
        {
          label: translationConfig.createMenuLabel
            ? translationConfig.createMenuLabel
            : t('presetFilters.create'),
          onClick: handleCreate,
          visible: !isFilterHasDefaultSetting,
        },
        {
          label: translationConfig.saveMenuLabel
            ? translationConfig.saveMenuLabel
            : t('presetFilters.save'),
          onClick: handleSave,
          visible: filtersList.length && !isFilterHasDefaultSetting,
        },
        {
          label: translationConfig.deleteMenuLabel
            ? translationConfig.deleteMenuLabel
            : t('presetFilters.delete'),
          onClick: handleDelete,
          visible: !!filtersList.length,
        },
        {
          label: 'Not data yet',
          readonly: true,
          visible: !filtersList.length && isFilterHasDefaultSetting,
        },
      ].filter(({ visible }) => visible),
    [
      classes.menuDivider,
      filtersList,
      handleCreate,
      handleSave,
      handleDelete,
      isFilterHasDefaultSetting,
      translationConfig,
      t,
    ],
  );

  const displayedOptions = useMemo(() => [...filtersList, ...actionsItems], [
    filtersList,
    actionsItems,
  ]);

  return (
    <>
      <TPMenu
        value={presetFilter?._id}
        menuItems={displayedOptions}
        onChange={handleSetFilter}
        itemValue="_id">
        <TPTooltip
          content={
            translationConfig.presetTooltip
              ? translationConfig.presetTooltip
              : t('presetFilters.tooltipName')
          }
          disabled={isTablet || isMobile}>
          <TPIconButton className={className} disabled={generalLoading || disabled}>
            <TPBadge variant="dot" color="error" invisible={!presetFilter}>
              <TuneIcon />
            </TPBadge>
          </TPIconButton>
        </TPTooltip>
      </TPMenu>
      <TPPresetFiltersCreateModal
        modalName={createModalName}
        onSubmit={handleSubmitCreationPresetFilter}
        onCancel={handleCancelCreation}
        form={createFormName}
        filtersList={filtersList}
        loading={createPresetLoading}
        translationConfig={translationConfig}
      />
      <TPPresetFiltersSaveModal
        modalName={saveModalName}
        onSubmit={handleSubmitSavingPresetFilter}
        onCancel={handleCancelSaving}
        form={saveFormName}
        filtersList={filtersList}
        currentValue={presetFilter}
        loading={savePresetLoading}
        translationConfig={translationConfig}
      />
      <TPPresetFiltersDeleteModal
        modalName={deleteModalName}
        onSubmit={handleSubmitDeletingPresetFilter}
        onCancel={handleCancelDeleting}
        form={deleteFormName}
        filtersList={filtersList}
        currentValue={presetFilter}
        loading={deletePresetLoading}
        translationConfig={translationConfig}
      />
    </>
  );
};

TPPresetFilters.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  filterFormValues: PropTypes.object,
  settings: PropTypes.object,
  onApplyPreset: PropTypes.func,
  initialFilterValues: PropTypes.object,
  translationConfig: PropTypes.shape({
    saveMenuLabel: PropTypes.string,
    createMenuLabel: PropTypes.string,
    deleteMenuLabel: PropTypes.string,
    presetTooltip: PropTypes.string,
    createModalTitle: PropTypes.string,
    saveModalTitle: PropTypes.string,
    deleteModalTitle: PropTypes.string,
    presetNamePlaceholder: PropTypes.string,
  }),
};

export default TPPresetFilters;
