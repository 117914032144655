import { handleActions } from 'redux-actions';

import { getPaypalRefundsFail, getPaypalRefundsSuccess } from '../actions';
import { groupBy } from 'lodash';

const defaultState = {
  conversionRates: null,
  ratesId: null,
  refunds: [],
  totalRefund: 0,
  distributions: [],
  refundsError: null,
};

export default handleActions(
  {
    [getPaypalRefundsSuccess](state, action) {
      const { paypalPayments, conversionRates, ratesId } = action.response.data || {};

      const paypalRefundsByEmail = groupBy(paypalPayments, ({ payerEmail }) => payerEmail);
      const refunds = [];
      const distributions = [];

      for (let key in paypalRefundsByEmail) {
        const paypalOrders = paypalRefundsByEmail[key];

        // distributions
        const distributionByCurrency = paypalOrders.reduce((res, value) => {
          const data = res.find((r) => r.currency === value.depositCurrency);
          if (!data) {
            res.push({
              currency: value.depositCurrency,
              amount: value.refundAmount,
              convertedRefundAmount: Number(value.convertedRefundAmount.toFixed(2)),
              conversionRate: conversionRates[value.depositCurrency],
            });
          } else {
            data.amount += value.refundAmount;
            data.convertedRefundAmount += Number(value.convertedRefundAmount.toFixed(2));
          }

          return res;
        }, []);

        distributions[key] = distributionByCurrency.filter((value) => value.amount > 0);

        // refunds, need to rounded
        const refundConvertedAmount = distributionByCurrency.reduce(
          (res, distribution) => res + distribution.convertedRefundAmount,
          0,
        );

        refunds.push({
          payerEmail: key,
          refundConvertedAmount,
        });
      }

      const totalRefund = refunds.reduce((res, value) => res + value.refundConvertedAmount, 0);

      return {
        conversionRates: conversionRates,
        ratesId: ratesId,
        refunds: refunds,
        totalRefund: totalRefund,
        distributions: distributions,
        refundsError: null,
      };
    },

    [getPaypalRefundsFail](state, action) {
      return {
        ...defaultState,
        refundsError: action.response?.data?.message || {
          key: 'common:errors.notifications.unexpectedError',
        },
      };
    },
  },
  defaultState,
);
