import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    spacing: {
      margin: theme.spacing(1),
    },
    container: {
      height: '300px',
      width: '250px',
    },
  }),
  { name: 'Images' },
);
