import get from 'lodash/get';
import moment from 'moment';
import {
  ACCOUNT_STATUSES,
  DATE_FORMAT_DDMMYYYY,
  DATE_FORMAT_MMYY,
  USA_STATES,
} from 'constants/index';
import formatPrice from '../formatPrice';
import formatBytes from '../formatBytes';
import formatFileTypes from '../formatFileTypes';
import { hasOnlyEmptyValues } from 'utils/hasOnlyEmptyValues';

export const email = (value) =>
  value &&
  (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
    value,
  ) ||
    value.includes('@-'))
    ? { key: 'common:errors.form.email' }
    : undefined;

export const required = (value) =>
  value === 0 || (value && /\w+/.test(value))
    ? undefined
    : { key: 'common:errors.form.isRequired' };

export const requiredArray = (value) =>
  Array.isArray(value) && value.length > 0 ? undefined : { key: 'common:errors.form.isRequired' };

export const requiredObject = (value) => {
  return Array.isArray(value) || hasOnlyEmptyValues(value)
    ? { key: 'common:errors.form.isRequired' }
    : undefined;
};

export const requiredFile = (value) => {
  return !value || !(value instanceof File) ? { key: 'common:errors.form.isRequired' } : undefined;
};

/* eslint-disable no-control-regex */
export const checkNonLatin = (value) =>
  /^[\u0000-\u007F]*$/.test(value) ? undefined : { key: 'common:errors.form.nonLatin' };
export const checkNonLatinWithNumbers = (value) =>
  /^[\u0000-\u007F0-9]*$/.test(value) ? undefined : { key: 'common:errors.form.nonLatin' };

/* eslint-enable no-control-regex */
export const captcha = (value) =>
  value === 0 || (value && /\w+/.test(value)) ? undefined : { key: 'common:errors.form.captcha' };

export const dateIsValid = (value) =>
  !value || moment(value, [moment.ISO_8601, DATE_FORMAT_DDMMYYYY]).isValid(moment())
    ? undefined
    : { key: 'common:errors.form.invalidDate' };

export const dateLessThenToday = (value) =>
  !value || moment(value, [moment.ISO_8601, DATE_FORMAT_DDMMYYYY]).isSameOrBefore(moment())
    ? undefined
    : { key: 'common:errors.form.dateLessThenToday' };

export const dateAfter1900 = (value) =>
  !value ||
  moment(value, [moment.ISO_8601, DATE_FORMAT_DDMMYYYY]).isSameOrAfter(
    moment('01-01-1900', 'DD-MM-YYYY'),
  )
    ? undefined
    : { key: 'common:errors.form.dateAfter1900' };

export const fullAgeDateOfBirth = (value) =>
  !value ||
  moment(value, [moment.ISO_8601, DATE_FORMAT_DDMMYYYY]).isSameOrBefore(moment().add(-18, 'years'))
    ? undefined
    : { key: 'common:errors.form.fullAgeDateOfBirth' };

export const dateMoreThanToday = (value) =>
  moment(value, [moment.ISO_8601, DATE_FORMAT_DDMMYYYY]).isSameOrAfter(moment().startOf('day'))
    ? undefined
    : { key: 'common:errors.form.dateMoreThanToday' };

export const expiryCardDate = (value) =>
  moment(value, [moment.ISO_8601, DATE_FORMAT_MMYY]).isSameOrAfter(moment().startOf('day'))
    ? undefined
    : { key: 'common:errors.form.expiryCardDate' };

export const dateRange = (first, second) =>
  moment(first).isBefore(moment(second)) ? undefined : { key: 'common:errors.form.dateRange' };

export const dateIsSane = (value) =>
  !value ||
  moment(value, [moment.ISO_8601, DATE_FORMAT_DDMMYYYY]).isBetween(
    moment().add(-200, 'years'),
    moment().add(200, 'years'),
  )
    ? undefined
    : { key: 'common:errors.form.dateIsSane' };

export const trackingNumber = (value) =>
  value && !/^([0-9]{10})$/i.test(value) ? { key: 'common:errors.form.trackingNumber' } : undefined;

let phoneValidatorCreator = (fieldName) => {
  return (value) =>
    value && !/^\+[0-9]{9,15}$/i.test(value)
      ? { key: 'errors.form.phone', values: [{ fieldName: '', key: fieldName }] }
      : undefined;
};

export const phoneNumber = phoneValidatorCreator('common:errors.descriptiors.phone');

export const faxNumber = phoneValidatorCreator('common:errors.descriptiors.fax');

export const cellNumber = phoneValidatorCreator('common:errors.descriptiors.cell');

export const contactNumber = phoneValidatorCreator('common:errors.descriptiors.contact');

export const einNumber = (value) =>
  value && !/^([0-9]{2}-[0-9]{7})$/i.test(value)
    ? { key: 'common:errors.form.einNumber' }
    : undefined;

export const npiNumber = (value) =>
  value && !/^([0-9]{10})$/i.test(value) ? { key: 'common:errors.form.npiNumber' } : undefined;

export const pinCode = (value) =>
  value && !/^([0-9]{4})$/i.test(value) ? { key: 'common:errors.form.pinCode' } : undefined;

export const vinNumber = (value) =>
  value && !/^([0-9A-HJ-NPR-Za-hj-npr-z]+)$/i.test(value)
    ? { key: 'common:errors.form.vinNumber' }
    : undefined;

export const positiveNumber = (value) => {
  if (value) {
    if (String(value).startsWith('.')) {
      return { key: 'common:errors.form.invalidDataType' };
    }
    if (!/^[+]?[0-9]+(\.[0-9]+)?$/.test(value)) {
      return { key: 'common:errors.form.positiveNumber' };
    }
    if (!/^[0-9.]{1,17}?$/.test(value)) {
      return { key: 'common:errors.form.lessThan16Digitals' };
    }
    if (!/[.]/.test(value) && !/^[0-9]{1,16}?$/.test(value)) {
      return { key: 'common:errors.form.lessThan16Digitals' };
    }
    return undefined;
  }
};

export const moreThanZero = (value) =>
  value && Number(value) <= 0 ? { key: 'common:errors.form.moreThanZero' } : undefined;

export const moreOrZero = (value) =>
  value && Number(value) < 0 ? { key: 'common:errors.form.moreOrZero' } : undefined;

export const positiveInteger = (value) => {
  if (value) {
    if (!/^([0-9]+)$/i.test(value)) {
      return { key: 'common:errors.form.positiveInteger' };
    }
    if (!/^[0-9]{1,16}?$/.test(value)) {
      return { key: 'common:errors.form.lessThan16Digitals' };
    }
    return undefined;
  }
};

export const cardNumber = (value) =>
  value && !/^([0-9]{16})$/i.test(value) ? { key: 'common:errors.form.cardNumber' } : undefined;

export const notZero = (value) =>
  value && Number(value) === 0 ? { key: 'common:errors.form.notZero' } : undefined;

export const yearInteger = (value) =>
  value && (!(1 * value < 2200 && 1 * value > 1800) || !/^([0-9]+)$/i.test(value))
    ? { key: 'common:errors.form.yearInteger' }
    : undefined;

export const yearLessOrEqualCurrent = (value) =>
  value && !(1 * value < 1 + moment().year())
    ? { key: 'common:errors.form.yearLessOrEqualCurrent' }
    : undefined;

export const positiveInteger24 = (value) =>
  value && !/^([01]?[0-9]|2[0-4])$/i.test(value)
    ? { key: 'common:errors.form.positiveInteger24' }
    : undefined;

export const positiveInteger31 = (value) =>
  value && (Number(value) > 31 || Number(value) < 0)
    ? { key: 'common:errors.form.positiveInteger31' }
    : undefined;

let positiveIntegerCreator = (fieldName, amount) => {
  return (value) =>
    value && (Number(value) < amount || Number(value) < 0)
      ? {
          key: 'common:errors.form.positiveIntegerCreator',
          values: [{ fieldName: '', key: fieldName }, { amount }],
        }
      : undefined;
};

export const positiveInteger10Withdrawal = positiveIntegerCreator(
  'common:errors.descriptiors.withdrawal',
  10,
);

export const positiveInteger35Withdrawal = positiveIntegerCreator(
  'common:errors.descriptiors.withdrawal',
  35,
);

export const positiveInteger10Deposit = positiveIntegerCreator(
  'common:errors.descriptiors.deposit',
  10,
);

export const validateDecimalPoints = (value) =>
  value === 0 || (value && /^(?!0(\.0+)?$)(\d+(\.\d{1,2})?)$/.test(String(value)))
    ? undefined
    : { key: 'common:errors.form.invalidAmount' };

export const positiveIntegerMinWithCurrency = (value, fieldName, amount, currency) => {
  return value && (Number(value) < amount || Number(value) < 0)
    ? {
        key: 'common:errors.form.positiveIntegerMinWithCurrency',
        values: [{ fieldName: '', key: fieldName }, { amount: formatPrice(amount, currency) }],
      }
    : undefined;
};

export const positiveIntegerMaxWithCurrency = (value, fieldName, amount, currency) => {
  return value && Number(value) > amount
    ? {
        key: 'common:errors.form.positiveIntegerMaxWithCurrency',
        values: [{ fieldName: '', key: fieldName }, { amount: formatPrice(amount, currency) }],
      }
    : undefined;
};

export const positiveIntegerMinWithdrawal = (value, amount, currency) => {
  return positiveIntegerMinWithCurrency(
    value,
    'common:errors.descriptiors.withdrawal',
    amount,
    currency,
  );
};

export const positiveIntegerMinDeposit = (value, amount, currency) => {
  return positiveIntegerMinWithCurrency(
    value,
    'common:errors.descriptiors.deposit',
    amount,
    currency,
  );
};

export const positiveIntegerMaxDeposit = (value, amount, currency) => {
  return positiveIntegerMaxWithCurrency(
    value,
    'common:errors.descriptiors.deposit',
    amount,
    currency,
  );
};

export const positiveIntegerMax = (value, fieldName, balance, currency) => {
  return value && Number(value) > Number(balance)
    ? {
        key: 'common:errors.form.positiveIntegerMax',
        values: [{ fieldName: '', key: fieldName }, { amount: formatPrice(balance, currency) }],
      }
    : undefined;
};

export const positiveIntegerMaxWithdrawal = (value, balance, currency) => {
  if (Number(balance) === 0) {
    return { key: 'common:errors.form.positiveIntegerMaxWithdrawal' };
  }
  return positiveIntegerMax(value, 'common:errors.descriptiors.withdrawal', balance, currency);
};

export const number = (value) =>
  value && isNaN(Number(value))
    ? {
        key: 'common:errors.form.invalidDataType',
      }
    : undefined;

export const maxNumberLength = (value) => {
  if (value) {
    if (value > 999999.99) {
      return {
        key: 'common:errors.form.maxNumberLength',
      };
    }
    if (value < -999999.99) {
      return {
        key: 'common:errors.form.minNumberLength',
      };
    }
    return undefined;
  }
};

export const maxNumberOfDecimalPlaces = (value) =>
  !/^\d+(?:\.\d{1,3})?$/.test(value)
    ? {
        key: 'common:errors.form.maxNumberOfDecimalPlaces',
      }
    : undefined;

export const positiveInteger168 = (value) =>
  value && (!/^[+-]?[0-9]+(\.[0-9]+)?$/i.test(value) || Number(value) > 168 || Number(value) < 0)
    ? {
        key: 'common:errors.form.positiveInteger168',
      }
    : undefined;

export const expirationCreditCartDate = (value) => {
  if (!/^([0-9]{4})$/i.test(value)) {
    return {
      key: 'common:errors.form.InvalidExpirationDate',
    };
  }
  const creditCardDate = moment(value, 'MMYY');
  let isValid = creditCardDate.isValid() && moment() < creditCardDate.add(1, 'months');
  return !isValid
    ? {
        key: 'common:errors.form.InvalidExpirationDate',
      }
    : undefined;
};

export const ABARoutingNumber = (value) =>
  value && !/^([0-9]{9})$/i.test(value)
    ? {
        key: 'common:errors.form.ABARoutingNumber',
      }
    : undefined;

export const accountNumber = (value) =>
  value && !/^([0-9]{10,12})$/i.test(value)
    ? {
        key: 'common:errors.form.ABARoutingNumber',
      }
    : undefined;

export const stringLength255 = (value) =>
  value && value.length > 255
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 255 }],
      }
    : undefined;

export const stringLengthMin2 = (value) =>
  value && value.length < 2
    ? {
        key: 'common:errors.form.stringLengthMin',
        values: [{ count: 2 }],
      }
    : undefined;

export const stringLengthMin3 = (value) =>
  value && value.length < 3
    ? {
        key: 'common:errors.form.stringLengthMin',
        values: [{ count: 3 }],
      }
    : undefined;

export const stringLengthMin4 = (value) =>
  value && value.length < 4
    ? {
        key: 'common:errors.form.stringLengthMin',
        values: [{ count: 4 }],
      }
    : undefined;

export const stringLengthMin8 = (value) =>
  value && value.length < 8
    ? {
        key: 'common:errors.form.stringLengthMin',
        values: [{ count: 8 }],
      }
    : undefined;

export const stringLengthMin6 = (value) =>
  value && value.length < 6
    ? {
        key: 'common:errors.form.stringLengthMin',
        values: [{ count: 6 }],
      }
    : undefined;

export const stringLengthMax4 = (value) => {
  return value && value.length > 4
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 4 }],
      }
    : undefined;
};

export const stringLengthMax6 = (value) => {
  return value && value.length > 6
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 6 }],
      }
    : undefined;
};

export const stringLengthMax10 = (value) => {
  return value && value.length > 10
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 10 }],
      }
    : undefined;
};

export const stringLength8or11 = (value) => {
  return value && value.length !== 8 && value.length !== 11
    ? {
        key: 'common:errors.form.stringLength8or11',
        values: [{ count: 8 }, { countSecond: 11 }],
      }
    : undefined;
};

export const stringLengthMax64 = (value) =>
  value && value.length > 64
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 64 }],
      }
    : undefined;

export const stringLengthMax128 = (value) =>
  value && value.length > 128
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 128 }],
      }
    : undefined;

export const stringLengthMax34 = (value) =>
  value && value.length > 34
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 34 }],
      }
    : undefined;

export const stringLength512 = (value) =>
  value && value.length > 512
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 512 }],
      }
    : undefined;

export const stringLength500 = (value) =>
  value && value.length > 500
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 500 }],
      }
    : undefined;

export const stringLength120 = (value) =>
  value && value.length > 120
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 120 }],
      }
    : undefined;

export const stringLength200 = (value) =>
  value && value.length > 200
    ? {
        key: 'The maximum length of the field must be 200 characters',
      }
    : undefined;

export const stringLength60 = (value) =>
  value && value.length > 60
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 60 }],
      }
    : undefined;

export const stringLength50 = (value) =>
  value && value.length > 50
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 50 }],
      }
    : undefined;

export const stringLength100 = (value) =>
  value && value.length > 100
    ? {
        key: 'common:errors.form.stringLengthMax',
        values: [{ count: 100 }],
      }
    : undefined;

export const checkRepetitivePassword = (value) => {
  if (!value) {
    return undefined;
  }

  const allowedRepeatedNumber = 2;
  for (let i = 0; i < value.length; i++) {
    let repeatCount = 0;
    for (let j = i + 1; j < value.length; j++) {
      if (value[j] === value[i]) {
        repeatCount++;
      } else {
        break;
      }
      if (repeatCount >= allowedRepeatedNumber) {
        return {
          key: 'common:errors.form.passwordIsRepetitive',
        };
      }
    }
  }
  return undefined;
};

export const password = (value) => {
  if (!value) {
    return undefined;
  }

  const regex = {
    lowerCase: /^(?=.*[a-z]).+$/g,
    upperCase: /^(?=.*[A-Z]).+$/g,
    number: /^(?=.*[0-9]).+$/g,
    special: /[!@#$%^&*()_+{}[\]:;<>,.?~\\`|/-]/g,
  };

  if (value.length < 10) {
    return {
      key: 'common:errors.form.password10Characters',
    };
  }
  let passwordComplexity = 0;
  if (regex.lowerCase.test(value)) {
    passwordComplexity++;
  }
  if (regex.upperCase.test(value)) {
    passwordComplexity++;
  }
  if (regex.number.test(value)) {
    passwordComplexity++;
  }
  if (regex.special.test(value)) {
    passwordComplexity++;
  }

  if (passwordComplexity < 3) {
    return {
      key: 'common:errors.form.passwordNotComplex',
    };
  }

  return checkRepetitivePassword(value);
};

export const accountPassword = (value) => {
  if (!value) {
    return undefined;
  }

  let regex = {
    lowerCase: /^(?=.*[a-z]).+$/g,
    upperCase: /^(?=.*[A-Z]).+$/g,
    number: /^(?=.*[0-9]).+$/g,
    special: /[!@#$%^&*()_+{}[\]:;<>,.?~\\`|/-]/g,
  };

  if (value.length < 8) {
    return {
      key: 'common:errors.form.password8Characters',
    };
  }

  if (!regex.lowerCase.test(value)) {
    return {
      key: 'common:errors.form.password1Lowercase',
    };
  }

  if (!regex.upperCase.test(value)) {
    return {
      key: 'common:errors.form.password1Uppercase',
    };
  }

  if (!regex.number.test(value)) {
    return {
      key: 'common:errors.form.password1Number',
    };
  }

  if (!regex.special.test(value)) {
    return {
      key: 'common:errors.form.passwordSpecialCharacters',
    };
  }

  return undefined;
};

export const getHyperwalletUserStateValidator = (userCountry) => {
  return (value) => {
    if (!value) {
      return undefined;
    }

    if (userCountry === 'United States of America') {
      if (!USA_STATES.map((item) => item.stateISOName).includes(value)) {
        return {
          key: 'common:errors.form.invalidUSUserState',
        };
      }
    } else {
      const regex = /^[a-zA-Z&\-()' ]*$/g;
      if (value.length > 50) {
        return {
          key: 'common:errors.form.stringLengthMax',
          values: [{ count: 50 }],
        };
      }
      if (!regex.test(value)) {
        return {
          key: 'common:errors.form.invalidUserState',
        };
      }
    }
    return undefined;
  };
};

export const hyperwalletUserAddress = (value) => {
  if (!value) {
    return undefined;
  }

  const regex = /^[a-zA-Z0-9#'(),\-./:;° ]*$/g;
  if (value.length < 2) {
    return {
      key: 'common:errors.form.stringLengthMin',
      values: [{ count: 2 }],
    };
  }
  if (value.length > 100) {
    return {
      key: 'common:errors.form.stringLengthMax',
      values: [{ count: 100 }],
    };
  }
  if (!regex.test(value)) {
    return {
      key: 'common:errors.form.invalidUserAddress',
    };
  }
  return undefined;
};

export const userState = (value) => {
  const regex = /^[a-zA-Z&\-()' ]*$/g;
  if (value && !regex.test(value)) {
    return {
      key: 'common:errors.form.invalidUserState',
    };
  }
  return undefined;
};

export const companyCity = (value) =>
  value && value.length > 40
    ? {
        key: 'common:errors.form.companyCity',
      }
    : undefined;

export const companyZip = (value) =>
  value && !/^([0-9]{5})$/i.test(value)
    ? {
        key: 'common:errors.form.companyZip',
      }
    : undefined;

export const isFloat = (value) =>
  !/^[+-]?[0-9]+(\.[0-9]+)?$/i.test(value)
    ? {
        key: 'common:errors.form.isFloat',
      }
    : undefined;

export const prnEvery = (value) =>
  (value && (value < 1 || value > 24)) ||
  !Number.isInteger(value - 0) ||
  value.toString().includes('.')
    ? {
        key: 'common:errors.form.positiveInteger24',
      }
    : undefined;

export const checkHyperwalletField = (fieldData) => {
  return (value, _allValues, props, validators) => {
    const field =
      props.bankwireFields && props.bankwireFields.find((field) => field.name === validators);
    const check = field && field.regularExpression ? new RegExp(field.regularExpression) : '';

    if (value && !check.test(value)) {
      if (fieldData.error) {
        return fieldData.error;
      }

      return {
        key: 'common:errors.form.hyperwalletFieldMinMaxLength',
        values: [{ min: field.minLength, max: field.maxLength }],
      };
    }

    return undefined;
  };
};

export const isAddressNotContainPoBox = (value) => {
  return /\b(?:p\.?\s*o\.?|post\s+office)(\s+)?(?:box|[0-9]*)?\b/i.test(value)
    ? {
        key: 'common:errors.form.addressContainPoBox',
      }
    : undefined;
};

export const isName = (value) =>
  value && !/^[a-zA-Z0-9]+[\w\s-]*[a-zA-Z0-9]$/.test(value)
    ? { key: 'common:errors.form.invalidName' }
    : undefined;

export const maxFiles = (max) => (values) => {
  return values && values.length > max
    ? { key: 'common:errors.form.maxNumberOfFiles', values: [{ maxFiles: max }] }
    : undefined;
};

//check dropzone error codes
export const allowedFilesTypes = (types) => (values) => {
  return values &&
    values.length &&
    values.some((val) => val.errors && val.errors[0].code === 'file-invalid-type')
    ? {
        key: 'common:errors.form.acceptedFileTypes',
        values: [{ acceptTypes: formatFileTypes(types) }],
      }
    : undefined;
};

export const maxFilesSize = (maxSize) => (values) => {
  return values &&
    values.length &&
    values.some((val) => val.errors && val.errors[0].code === 'file-too-large')
    ? {
        key: 'common:errors.form.allowedMaxFileSize',
        values: [{ maxSize: formatBytes(maxSize) }],
      }
    : undefined;
};

export const nonEmptyString = (value, values, scope, field) => {
  return value === '' && values[field] === ''
    ? { key: 'common:errors.form.isRequired' }
    : undefined;
};

export const duplicatedFiles = (values, errorKey) => {
  const names = values?.map((file) => {
    return get(file, 'file.name', '') || get(file, 'originalName', '');
  });
  const isDuplicate = names?.some((fileName, i) => {
    return names.indexOf(fileName) !== i;
  });

  if (isDuplicate) {
    return { key: errorKey || 'common:errors.form.duplicateFiles' };
  }
};

export const validatePayIdPhoneNumber = (value) => {
  return !/^[+][0-9]{1,3}-[1-9]{1,1}[0-9]{3,29}$/g.test(value)
    ? {
        key: 'common:errors.form.payIdPhoneNumber',
      }
    : undefined;
};

export const approvedAccount = (account) => {
  if (account.status === ACCOUNT_STATUSES.PENDING || account.status === ACCOUNT_STATUSES.INITIAL) {
    return { key: 'payments:withdraw.messages.awaitingAccount' };
  } else {
    return undefined;
  }
};

export const phoneInfo = (value) => {
  const phone = value ? value.phoneNumber : '';
  return phoneNumber(phone);
};

export const phoneInfoRequired = (value) => {
  const phone = value ? value.phoneNumber : '';
  return required(phone);
};

export const checkRuIBAN = (value) => {
  return /^RU\d+/i.test(value)
    ? {
        key: 'common:errors.form.IBANProhibited',
      }
    : undefined;
};
