import { handleActions } from 'redux-actions';
import {
  cleanupFee,
  feeFail,
  feeSuccess,
  validateAddressFail,
  validateAddressRequest,
  validateAddressSuccess,
} from '../actions';
const defaultState = { isValid: null, fee: null };

export default handleActions(
  {
    [validateAddressRequest](state) {
      return {
        ...state,
        isValid: null,
      };
    },
    [validateAddressSuccess](state, action) {
      return {
        ...state,
        isValid: action.response.data.isValid,
      };
    },
    [validateAddressFail](state) {
      return {
        ...state,
        isValid: false,
      };
    },
    [feeSuccess](state, action) {
      return {
        ...state,
        fee: action.response.data,
      };
    },
    [feeFail](state) {
      return {
        ...state,
        fee: defaultState.fee,
      };
    },
    [cleanupFee](state) {
      return {
        ...state,
        fee: defaultState.fee,
      };
    },
  },
  defaultState,
);
