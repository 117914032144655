import React from 'react';
import TPGrid from 'components/TP-UI/TPGrid';
import { useTranslation } from 'react-i18next';
import { lettersWithSymbolsAndNumbers } from 'utils/normalizations';
import TPTypography from 'components/TP-UI/TPTypography';
import TPImage from 'components/TP-UI/TPImage';
import { Field } from 'redux-form';
import TPTextField from 'components/TP-UI/TPTextField';
import { checkNonLatin, required } from 'utils/validation/fieldValidationRules';
import PropTypes from 'prop-types';
import FormError from 'modules/common/FormError';
import TPCopyButton from 'components/TP-UI/TPCopyButton';

const EnableMfaForm = ({ qrCode, secret, error, submitFailed }) => {
  const { t } = useTranslation('auth');

  return (
    <TPGrid container spacing={3} direction="column">
      {submitFailed && error && (
        <TPGrid item xs={12}>
          <FormError error={error} />
        </TPGrid>
      )}
      <TPGrid item container spacing={2} direction="column">
        <TPGrid item>
          <TPTypography variant="body1">{t('mfa.setup.step1')}</TPTypography>
        </TPGrid>
        <TPGrid item>
          <TPTypography variant="body1">{t('mfa.setup.step2')}</TPTypography>
        </TPGrid>
        {qrCode && (
          <>
            <TPGrid item>
              <TPImage src={qrCode} alt="QR code" />
            </TPGrid>
            <TPGrid item container spacing={1} alignItems="center">
              <TPGrid item>
                <TPTypography>{t('mfa.setup.secretCode')}</TPTypography>
              </TPGrid>
              <TPGrid item>
                <TPTypography bold>{secret}</TPTypography>
              </TPGrid>
              <TPGrid item>
                <TPCopyButton size="small" text={secret} />
              </TPGrid>
            </TPGrid>
          </>
        )}
        <TPGrid item>
          <TPTypography variant="body1">{t('mfa.setup.step3')}</TPTypography>
        </TPGrid>
        <TPGrid item xs={12}>
          <Field
            component={TPTextField}
            fullWidth
            required
            name="code"
            label={t('mfa.verificationForm.inputLabel')}
            placeholder={t('mfa.verificationForm.inputPlaceholder')}
            type="text"
            validate={[checkNonLatin, required]}
            normalize={lettersWithSymbolsAndNumbers}
          />
        </TPGrid>
      </TPGrid>
    </TPGrid>
  );
};

EnableMfaForm.propTypes = {
  qrCode: PropTypes.string,
  secret: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  submitFailed: PropTypes.bool,
};

export default EnableMfaForm;
