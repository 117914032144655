import TPSidebarLogo from 'components/TP-UI/TPSidebarLogo';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NavMenuList from '../../containers/NavMenuListContainer';
import config from 'config';
import TPTypography from 'components/TP-UI/TPTypography';
import TPLink from 'components/TP-UI/TPLink';
import TPProgressBar from 'components/TP-UI/TPProgressBar';
import TPButton from 'components/TP-UI/TPButton';
import classNames from 'classnames';
import { LOGO_PATH_BY_ROLE } from './config';
import { useTheme } from '@material-ui/core';

import useStyles from './styles';

const { LOGO, CURRENT_HUB_LABEL } = config;

const Sidebar = ({
  header,
  userRole,
  verificationProgress = 0,
  verified = true,
  posted,
  newUser,
  expandable = true,
  minimized,
  mobile,
  onExpandClick,
  continueVerificationPath = '',
  onMinMenuClick,
  onChange,
  onContinueVerification,
}) => {
  const { t } = useTranslation('menu');
  const classes = useStyles();
  const { themeName } = useTheme();

  const rootClassName = classNames(classes.root, {
    [classes.minimized]: minimized,
    [classes.mobile]: mobile,
  });

  const renderProgressBar = () => (
    <TPProgressBar progress={verificationProgress} label={`${verificationProgress}%`} circle />
  );

  return (
    <div className={rootClassName}>
      {header}
      <div className={classes.navigationContainer}>
        {!header ? (
          <TPSidebarLogo
            alt={CURRENT_HUB_LABEL}
            src={minimized ? LOGO[themeName].short : LOGO[themeName].origin}
            path={LOGO_PATH_BY_ROLE[userRole]}
            expanded={!minimized}
            onExpandClick={onExpandClick}
            hideButton={!expandable}
          />
        ) : null}
        <NavMenuList
          mobile={mobile}
          minimized={minimized}
          onMinMenuClick={onMinMenuClick}
          onChange={onChange}
        />
      </div>
      {!verified ? (
        <div className={classes.verificationContainer}>
          {minimized ? (
            posted ? (
              <div className={classes.circleProgress}>{renderProgressBar()}</div>
            ) : (
              <TPLink path={continueVerificationPath} className={classes.circleProgress}>
                {renderProgressBar()}
              </TPLink>
            )
          ) : (
            <>
              <TPTypography>
                {posted ? t('account.awaitingApproval') : t('account.verifyAccount')}
              </TPTypography>
              <div className={classes.lineProgress}>
                <TPProgressBar
                  progress={verificationProgress}
                  label={`${verificationProgress}%`}
                  thickness={8}
                />
              </div>
              {!posted ? (
                <TPButton
                  alternative
                  fullWidth
                  onClick={onContinueVerification}
                  className={classes.verificationButton}>
                  {newUser ? t('common:buttons.verifyNow') : t('account.continue')}
                </TPButton>
              ) : null}
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

Sidebar.propTypes = {
  header: PropTypes.node,
  userRole: PropTypes.string,
  verificationProgress: PropTypes.number,
  verified: PropTypes.bool,
  posted: PropTypes.bool,
  expandable: PropTypes.bool,
  minimized: PropTypes.bool,
  mobile: PropTypes.bool,
  continueVerificationPath: PropTypes.string,
  onExpandClick: PropTypes.func,
  onMinMenuClick: PropTypes.func,
  onChange: PropTypes.func,
  onContinueVerification: PropTypes.func,
};

export default Sidebar;
