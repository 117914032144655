import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as selectors from '../selectors';
import * as actionCreators from '../actions';
import Notifications from 'modules/notifications/components/Notifications';

const NotificationsContainer = () => {
  const { queue } = useSelector(selectors.getNotifications);
  const dispatch = useDispatch();

  const handleClose = useCallback(
    (event, reason, key) => {
      if (reason === 'clickaway') {
        return;
      }
      dispatch(actionCreators.hideNotificationAlert(key));
    },
    [dispatch],
  );

  return <Notifications queue={queue} onClose={handleClose} />;
};

export default NotificationsContainer;
