export default () => ({
  root: {
    borderRadius: '4px 4px 0 0',
    backgroundColor: '#EFF2F7',
    padding: 10,
  },
  button: {
    height: 30,
    marginLeft: 15,
  },
  icon: {
    color: '#c2c2c5',
    cursor: 'pointer',
  },
  customSelector: {
    marginRight: 15,
  },
  textBetween: {
    margin: '0 6px',
    fontSize: '16px',
  },
  statsCustomization: {},
});
