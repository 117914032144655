import React, { Component } from 'react';
import { connect } from 'react-redux';

import Picker from '../components/Picker';

class PickerContainer extends Component {
  constructor(props) {
    super(props);
    this.label = React.createRef();
    this.state = {
      showTopButton: false,
      isOpen: false,
      labelWidth: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.label !== this.props.label) {
      this.setState({ labelWidth: this.label.current.offsetWidth });
    }
  }

  componentDidMount() {
    this.setState({ labelWidth: this.label.current.offsetWidth });
  }

  handleTopButtonChange = (newTopButtonValue) => {
    this.setState({
      showTopButton: newTopButtonValue,
    });
  };

  handlePicker = () => {
    const prevIsOpen = this.state.isOpen;
    this.setState({
      isOpen: !prevIsOpen,
    });
  };

  render() {
    const props = {
      isTopButtonShowed: this.state.showTopButton,
      handleTopButtonChange: this.handleTopButtonChange,
      isOpen: this.state.isOpen,
      handlePicker: this.handlePicker,
      ref: this.label,
      labelWidth: this.state.labelWidth,
    };
    return <Picker {...this.props} {...props} />;
  }
}

PickerContainer.propTypes = {};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PickerContainer);
