import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import styles from './styles';
import loader_squares from 'static/images/old/loader_squares.gif';

const PrimaryButton = ({
  classes,
  className,
  label,
  onClick,
  disabled,
  content,
  startIcon,
  fullWidth,
  isPending,
  customIcon,
  ...data
}) => {
  return (
    <Button
      disableTouchRipple
      focusRipple
      disabled={disabled}
      variant="contained"
      onClick={onClick}
      fullWidth={fullWidth}
      {...data}
      className={classNames({ [className]: true, [classes.hidden]: isPending })}>
      <div className={classes.buttonContent} {...data}>
        <div
          className={classNames({
            [classes.loader]: true,
            [classes.hidden]: true,
            [classes.visible]: isPending,
          })}>
          <img alt="" src={loader_squares} />
        </div>
        {customIcon && <img alt="" src={customIcon} />}
        <div
          className={classNames({
            [classes.buttonTextContainer]: true,
            [classes.hidden]: isPending,
          })}>
          {startIcon}
          {label}
          {content}
        </div>
      </div>
    </Button>
  );
};

PrimaryButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  content: PropTypes.object,
  fullWidth: PropTypes.bool,
  isPending: PropTypes.bool,
};

PrimaryButton.defaultProps = {
  fullWidth: false,
};

export default withStyles(styles)(PrimaryButton);
