import {
  hyperwalletUserAddress,
  isAddressNotContainPoBox,
} from 'utils/validation/fieldValidationRules';

export const userAddressLineForHyperwalletValid = (userAddress) => {
  return (
    !!userAddress && !hyperwalletUserAddress(userAddress) && !isAddressNotContainPoBox(userAddress)
  );
};
