export const duplicatedPresetName = (existingPresets) => (value) => {
  if (value && value.length > 0 && existingPresets) {
    const isNameExists = existingPresets.includes(value);
    if (isNameExists) {
      return { _error: `Preset name ${value} already exists.` };
    }
  }

  return undefined;
};

export const maxFiltersCount = (max, filterCount) => () => {
  return filterCount >= max
    ? { _error: `Sorry, you have max count of preset filters (${max})` }
    : undefined;
};
