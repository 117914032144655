import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      alignItems: 'stretch',
      width: '100%',
      position: 'relative',
      background: 'inherit',
    },
    logoWrapper: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '21px 0 21px 20px',
      minHeight: '91px',
    },
    logo: {
      maxHeight: '48px',
      maxWidth: '70%',
    },
    buttonWrapper: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: '10px',
      background: 'inherit',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
    },
  }),
  { name: 'TPSidebarLogo' },
);

export default useStyles;
