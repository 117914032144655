import { handleActions } from 'redux-actions';

import { getFollowUpsSuccess, cleanupFollowUps } from '../actions';

const defaultState = {
  items: [],
  totalNumber: 0,
};

export default handleActions(
  {
    [getFollowUpsSuccess](state, action) {
      return !action.payload.persistStoreData
        ? {
            ...state,
            ...{
              items: action.response.data.followUps,
              totalNumber: action.response.data.totalNumber,
            },
          }
        : state;
    },
    [cleanupFollowUps]() {
      return defaultState;
    },
  },
  defaultState,
);
