export default () => ({
  root: {
    minHeight: 400,
  },
  chartWrapper: {
    display: 'flex',
  },
  listWrapper: {
    marginLeft: '20%',
  },
  nameLabel: {
    color: '#36425A',
    fontSize: '14px',
    fontFamily: 'Lato, sans-serif',
  },
  valueLabel: {
    fontWeight: 'bold',
  },
  subTitle: {
    marginLeft: '20%',
    color: '#282525',
    fontFamily: 'Lato, sans-serif',
    fontSize: '18px',
    fontWeight: 300,
    lineHeight: '22px',
    marginBottom: '15px',
  },
  gridField: {
    marginBottom: 5,
  },
  usersCount: {
    display: 'flex',
    padding: '10px',
    justifyContent: 'center',
  },
  usersCountContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  period: {
    fontSize: '16px',
    lineHeight: '1.2',
    paddingBottom: '10px',
  },
  title: {
    fontSize: '14px',
    lineHeight: '1.2',
  },
  lastValue: {
    fontSize: '30px',
    lineHeight: '1.2',
  },
  diff: {
    fontSize: '12px',
    lineHeight: '1.2',
  },
  positive: {
    color: '#7DC4B5',
  },
  negative: {
    color: '#AA5072',
  },
});
