import { useCallback, useEffect, useState } from 'react';
import { SEGMENT_SKIP_TRACK_ROUTES } from './constants';
import { trackSegmentLocationRequest } from 'modules/common/Segment/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsAdminRole,
  getIsImpersonation,
  getIsManagerRole,
  getUserId,
} from 'modules/auth/selectors';
import { useUsersLocation } from 'hooks/useUsersLocation';
import Cookies from 'js-cookie';
import { SEGMENT_ANONYMOUS_ID } from 'modules/auth/pages/SignIn/constants';

const SegmentTracker = () => {
  const dispatch = useDispatch();
  const isAdmin = useSelector(getIsAdminRole);
  const isManager = useSelector(getIsManagerRole);
  const isImpersonation = useSelector(getIsImpersonation);
  const usersCurrentLocation = useUsersLocation();
  const [prevPath, setPrevPath] = useState(null);
  const userId = useSelector(getUserId);

  const handleLocationChange = useCallback(
    (data) => {
      if (
        !isAdmin &&
        !isManager &&
        !isImpersonation &&
        !SEGMENT_SKIP_TRACK_ROUTES.includes(data.path)
      ) {
        const segmentAnonymousId = Cookies.get(SEGMENT_ANONYMOUS_ID);
        if (userId) {
          dispatch(trackSegmentLocationRequest({ ...data, userId }));
        } else if (segmentAnonymousId) {
          dispatch(
            trackSegmentLocationRequest({
              ...data,
              title: document.title || data.title,
              segmentAnonymousId,
            }),
          );
        }
      }
    },
    [dispatch, isAdmin, isImpersonation, isManager, userId],
  );

  useEffect(() => {
    if (usersCurrentLocation.path && prevPath !== usersCurrentLocation.path) {
      handleLocationChange(usersCurrentLocation);

      setPrevPath(usersCurrentLocation.path);
    }
  }, [prevPath, usersCurrentLocation, handleLocationChange]);

  return null;
};

export default SegmentTracker;
