import { handleActions } from 'redux-actions';
import * as actions from '../actions';

const defaultState = {
  pageWarning: null,
};

export default handleActions(
  {
    [actions.getWarningPageSuccess]: (state, action) => {
      return { ...state, pageWarning: action.response.data };
    },
  },
  defaultState,
);
