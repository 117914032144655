import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useUsersLocation = () => {
  const location = useLocation();
  const [usersLocaton, setUsersLocation] = useState({});

  useEffect(() => {
    setUsersLocation({
      path: location.pathname,
      title: document.title,
      referrer: document.referrer,
      search: location.search,
      url: window.location.href,
    });
  }, [location]);

  return usersLocaton;
};
