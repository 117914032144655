import createAction from 'redux-actions/es/createAction';

export const getAccountsRequest = createAction('GET_ACCOUNTS_REQUEST');
export const getAccountsSuccess = createAction('GET_ACCOUNTS_SUCCESS');
export const getAccountsFail = createAction('GET_ACCOUNTS_FAIL');

export const getDemoAccountsRequest = createAction('GET_DEMO_ACCOUNTS_REQUEST');
export const getDemoAccountsSuccess = createAction('GET_DEMO_ACCOUNTS_SUCCESS');

export const createAccountRequest = createAction('CREATE_ACCOUNT_REQUEST');
export const createAccountSuccess = createAction('CREATE_ACCOUNT_SUCCESS');
export const createAccountFail = createAction('CREATE_ACCOUNT_FAIL');

export const updateAccountRequest = createAction('UPDATE_ACCOUNT_REQUEST');
export const updateAccountSuccess = createAction('UPDATE_ACCOUNT_SUCCESS');
export const updateAccountFail = createAction('UPDATE_ACCOUNT_FAIL');

export const fundDemoAccountRequest = createAction('FUND_DEMO_ACCOUNT_REQUEST');
export const fundDemoAccountSuccess = createAction('FUND_DEMO_ACCOUNT_SUCCESS');
export const fundDemoAccountFail = createAction('FUND_DEMO_ACCOUNT_FAIL');

export const getAccountRequest = createAction('GET_ACCOUNT_REQUEST');
export const getAccountSuccess = createAction('GET_ACCOUNT_SUCCESS');
export const getAccountFail = createAction('GET_ACCOUNT_FAIL');

export const accountsPageOpened = createAction('ACCOUNTS_PAGE_OPENED');
export const accountsTabChanged = createAction('ACCOUNTS_TAB_CHANGED');

export const showAddAccountModal = createAction('SHOW_ADD_ACCOUNT_MODAL');
export const showEditAccountModal = createAction('SHOW_EDIT_ACCOUNT_MODAL');
export const hideEditAccountModal = createAction('HIDE_EDIT_ACCOUNT_MODAL');
export const showFundDemoAccountModal = createAction('SHOW_FUND_DEMO_ACCOUNT_MODAL');
export const hideFundDemoAccountModal = createAction('HIDE_FUND_DEMO_ACCOUNT_MODAL');
export const accountsHideDialog = createAction('ACCOUNTS_HIDE_DIALOG');
export const additionalAccountDialogToggle = createAction('ADDITIONAL_ACCOUNT_DIALOG_TOGGLE');

export const hideAccountRequest = createAction('HIDE_ACCOUNT_REQUEST');
export const hideAccountSuccess = createAction('HIDE_ACCOUNT_SUCCESS');
export const hideAccountFail = createAction('HIDE_ACCOUNT_FAIL');
export const hideAccountOpen = createAction('HIDE_ACCOUNT_OPEN');
export const hideAccountClose = createAction('HIDE_ACCOUNT_CLOSE');

export const sortAccountRequest = createAction('SORT_ACCOUNT_REQUEST');

export const toggleShowHidden = createAction('TOGGLE_SHOW_HIDDEN');

export const openAcceptInvitationModal = createAction('OPEN_ACCEPT_INVITATION_MODAL');
export const followAccount = createAction('FOLLOW_ACCOUNT');

export const selectProOrRetail = createAction('SELECT_PRO_OR_RETAIL');

export const selectAccountPlanRequest = createAction('SELECT_ACCOUNT_PLAN_REQUEST');
export const selectAccountPlanSuccess = createAction('SELECT_ACCOUNT_PLAN_SUCCESS');
export const selectAccountPlanFail = createAction('SELECT_ACCOUNT_PLAN_FAIL');

export const proSignRequest = createAction('PRO_SIGN_REQUEST');
export const proSignSuccess = createAction('PRO_SIGN_SUCCESS');
export const proSignFail = createAction('PRO_SIGN_FAIL');

export const unArchiveAccountModal = createAction('UNARCHIVE_ACCOUNT_MODAL');

export const unArchiveAccountRequest = createAction('UN_ARCHIVE_ACCOUNT_REQUEST');
export const unArchiveAccountSuccess = createAction('UN_ARCHIVE_ACCOUNT_SUCCESS');
export const unArchiveAccountFail = createAction('UN_ARCHIVE_ACCOUNT_FAIL');

export const requestAdditionalAccountRequest = createAction('REQUEST_ADDITIONAL_ACCOUNT_REQUEST');
export const requestAdditionalAccountSuccess = createAction('REQUEST_ADDITIONAL_ACCOUNT_SUCCESS');
export const requestAdditionalAccountFail = createAction('REQUEST_ADDITIONAL_ACCOUNT_FAIL');
