import { combineActions, handleActions } from 'redux-actions';

import { cleanupAuth } from '../actions';
import { appleAuthSuccess, googleAuthSuccess, signupSuccess } from '../pages/SignUp/actions';
import {
  appleSigninSuccess,
  googleSigninSuccess,
  signinNonfxSuccess,
  signinSuccess,
} from '../pages/SignIn/actions';
import { userMigrationSuccess } from '../pages/Migration/actions';
import { leadUserJoinSuccess } from 'modules/lead/actions';
import { signinAsUserSuccess } from 'modules/userManagement/pages/UserDetails/actions';

const defaultState = false;

export default handleActions(
  {
    [combineActions(
      signupSuccess,
      signinSuccess,
      appleSigninSuccess,
      googleSigninSuccess,
      signinNonfxSuccess,
      googleAuthSuccess,
      appleAuthSuccess,
      userMigrationSuccess,
      leadUserJoinSuccess,
      signinAsUserSuccess,
    )]() {
      return true;
    },
    [cleanupAuth]: () => {
      return defaultState;
    },
  },
  defaultState,
);
