import React from 'react';
import PropTypes from 'prop-types';
import TPPopover from '../TPPopover';
import { isMobile } from 'react-device-detect';

const TPTooltipBase = ({
  content,
  children,
  placement = 'top',
  disabled,
  minWidth,
  maxWidth = '250px',
  enterDelay = 500,
  offset = '0, 4',
  trigger = 'hover',
  open,
  forwardedRef,
  ...props
}) => {
  const actualTrigger = isMobile ? 'click' : trigger;
  return (
    <TPPopover
      ref={forwardedRef}
      content={content}
      placement={placement}
      disabled={disabled}
      trigger={actualTrigger}
      minWidth={minWidth}
      maxWidth={maxWidth}
      enterDelay={actualTrigger === 'click' ? 0 : enterDelay}
      offset={offset}
      data-clickable={actualTrigger === 'click'}
      open={open}
      {...props}>
      {children}
    </TPPopover>
  );
};

TPTooltipBase.propTypes = {
  content: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node, PropTypes.string]),
  children: PropTypes.node.isRequired,
  placement: PropTypes.oneOf([
    'top-start',
    'top',
    'top-end',
    'left-start',
    'left',
    'left-end',
    'right-start',
    'right',
    'right-end',
    'bottom-start',
    'bottom',
    'bottom-end',
    'auto-start',
    'auto',
    'auto-end',
  ]),
  disabled: PropTypes.bool,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  offset: PropTypes.string,
  trigger: PropTypes.oneOf(['hover', 'click']),
};

const TPTooltip = React.forwardRef((props, ref) => <TPTooltipBase {...props} forwardedRef={ref} />);

export default TPTooltip;
