import { Routes } from '../../../constants/routeConstants';

import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';
import WhatshotOutlinedIcon from '@material-ui/icons/WhatshotOutlined';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import TimelineIcon from '@material-ui/icons/Timeline';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import ExposureOutlinedIcon from '@material-ui/icons/ExposureOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import DonutSmallOutlinedIcon from '@material-ui/icons/DonutSmallOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import SwapCallsOutlinedIcon from '@material-ui/icons/SwapCallsOutlined';
import BeenhereOutlinedIcon from '@material-ui/icons/BeenhereOutlined';
import AssignmentLateOutlinedIcon from '@material-ui/icons/AssignmentLateOutlined';
import NotificationImportantOutlinedIcon from '@material-ui/icons/NotificationImportantOutlined';
import NewReleasesOutlinedIcon from '@material-ui/icons/NewReleasesOutlined';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';
import NotListedLocationOutlinedIcon from '@material-ui/icons/NotListedLocationOutlined';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import TodayIcon from '@material-ui/icons/Today';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import { getUrlWithParams } from 'helpers/router';

export const MENU_ITEM_ID = {
  DASHBOARD: 'dashboard',
  VERIFICATION_MANAGEMENT: 'id_verification',
  PAYMENT_MANAGEMENT: 'payment_management',
  EMAIL_MANAGEMENT: 'email_management',
  USER_MANAGEMENT: 'user_management',
  OPERATIONS: 'operations',
  SETTINGS: 'settingsMenu',
  MY_ACCOUNTS: 'accounts',
  PAYMENTS: 'payments',
  NEWS: 'news',
  TRADE_IDEAS: 'tradeIdeas',
  VPS: 'vps',
  TRADING_CALCULATORS: 'tradingCalculators',
  ECONOMIC_CALENDAR: 'economicCalendar',
  TRADING_TOOLS: 'tradingTools',
  CRYPTO_WAITLIST: 'cryptoWaitlist',
  FUSION_PLUS: 'fusionPlus',
  UPGRADE_TO_PRO: 'upgradeToPro',
  PROMOTIONS: 'promotions',
  UPGRADES: 'upgrades',
};

export const MENU_ITEMS = [
  {
    id: MENU_ITEM_ID.DASHBOARD,
    label: 'Dashboard',
    iconComponent: DashboardOutlinedIcon,
    path: Routes.DASHBOARD,
    mainMenuItem: true,
  },
  {
    id: MENU_ITEM_ID.VERIFICATION_MANAGEMENT,
    label: 'ID Verification',
    iconComponent: VerifiedUserOutlinedIcon,
    path: Routes.VERIFICATION_MANAGEMENT,
    mainMenuItem: true,
  },
  {
    id: MENU_ITEM_ID.PAYMENT_MANAGEMENT,
    label: 'Payment Management',
    iconComponent: AccountBalanceWalletOutlinedIcon,
    path: Routes.PAYMENT_MANAGEMENT,
    mainMenuItem: true,
  },
  {
    id: MENU_ITEM_ID.EMAIL_MANAGEMENT,
    label: 'Email Management',
    iconComponent: MailOutlinedIcon,
    path: Routes.EMAIL_MANAGEMENT,
    mainMenuItem: false,
  },
  {
    id: MENU_ITEM_ID.USER_MANAGEMENT,
    label: 'User Management',
    iconComponent: SupervisorAccountOutlinedIcon,
    path: Routes.USER_MANAGEMENT,
    mainMenuItem: false,
  },
  {
    id: MENU_ITEM_ID.OPERATIONS,
    label: 'Operations',
    iconComponent: DonutSmallOutlinedIcon,
    path: Routes.OPERATIONS,
    mainMenuItem: false,
  },
  {
    id: MENU_ITEM_ID.SETTINGS,
    label: 'Settings',
    iconComponent: SettingsOutlinedIcon,
    mainMenuItem: false,
    items: [
      {
        id: 'migration_requests',
        label: 'Migration requests',
        iconComponent: SwapCallsOutlinedIcon,
        path: Routes.MIGRATION_REQUESTS,
      },
      {
        id: 'securityLogs',
        label: 'Logs',
        iconComponent: BeenhereOutlinedIcon,
        path: getUrlWithParams(Routes.SECURITY_LOGS, { pageNumber: 1 }),
        routePath: Routes.SECURITY_LOGS,
      },
      {
        id: 'validationRefCodes',
        label: 'Ref Codes',
        iconComponent: ListAltOutlinedIcon,
        path: getUrlWithParams(Routes.VALIDATION_REF_CODES, { pageNumber: 1 }),
        routePath: Routes.VALIDATION_REF_CODES,
      },
      {
        id: 'pageWarning',
        label: 'Page Warning',
        iconComponent: AssignmentLateOutlinedIcon,
        path: Routes.PAGE_WARNING,
      },
      {
        id: 'notifications',
        label: 'Notifications',
        iconComponent: NotificationImportantOutlinedIcon,
        path: Routes.NOTIFICATIONS,
      },
      {
        id: 'riskGroup',
        label: 'Risk Group',
        iconComponent: NewReleasesOutlinedIcon,
        path: Routes.RISK_GROUP,
      },
      {
        id: 'mt4LiveCountries',
        label: 'TradeServer LIVE Rules',
        iconComponent: FlagOutlinedIcon,
        path: Routes.MT4LIVE_COUNTRIES,
      },
      {
        id: 'paymentQueue',
        label: 'Queue',
        iconComponent: HourglassEmptyOutlinedIcon,
        path: Routes.PAYMENT_QUEUE,
      },
      {
        id: 'questions',
        label: 'Custom Fields',
        iconComponent: NotListedLocationOutlinedIcon,
        path: Routes.CUSTOM_FIELDS,
      },
      {
        id: 'settings',
        label: 'MFA',
        iconComponent: TuneOutlinedIcon,
        path: Routes.MFA_SETTINGS,
      },
    ],
  },
  {
    id: MENU_ITEM_ID.MY_ACCOUNTS,
    label: { key: 'menu:category.accounts' },
    iconComponent: PersonOutlineIcon,
    path: Routes.MY_ACCOUNTS,
    mainMenuItem: true,
  },
  {
    id: MENU_ITEM_ID.PAYMENTS,
    label: { key: 'menu:category.payments' },
    iconComponent: AccountBalanceWalletOutlinedIcon,
    path: Routes.PAYMENTS,
    mainMenuItem: true,
  },
  {
    id: MENU_ITEM_ID.NEWS,
    label: { key: 'menu:category.news' },
    iconComponent: AssignmentOutlinedIcon,
    mainMenuItem: false,
    items: [
      {
        id: 'news_search',
        label: { key: 'menu:category.discover' },
        iconComponent: TrendingUpOutlinedIcon,
        path: Routes.NEWS_SEARCH,
      },
      {
        id: 'market_buzz',
        label: { key: 'menu:category.marketBuzz' },
        iconComponent: WhatshotOutlinedIcon,
        path: Routes.MARKET_BUZZ,
      },
      {
        id: 'sentiment',
        label: { key: 'menu:category.sentiment' },
        iconComponent: FaceOutlinedIcon,
        path: Routes.SENTIMENT,
      },
      {
        id: 'myFeeds',
        label: { key: 'menu:category.myFeeds' },
        iconComponent: StarBorderIcon,
        path: Routes.MY_FEEDS,
      },
    ],
  },
  {
    id: MENU_ITEM_ID.TRADE_IDEAS,
    label: { key: 'menu:category.tradeIdeas' },
    iconComponent: AssessmentOutlinedIcon,
    mainMenuItem: true,
    items: [
      {
        id: 'analystViews',
        label: { key: 'menu:category.analystViews' },
        iconComponent: TimelineIcon,
        path: Routes.ANALYST_VIEWS,
      },
      {
        id: 'technicalInsight',
        label: { key: 'menu:category.technicalInsight' },
        iconComponent: EmojiObjectsOutlinedIcon,
        path: Routes.TECHNICAL_INSIGHT,
      },
    ],
  },
  {
    id: MENU_ITEM_ID.VPS,
    label: { key: 'menu:category.vps' },
    iconComponent: DescriptionOutlinedIcon,
    path: Routes.VPS,
    mainMenuItem: false,
  },
  {
    id: MENU_ITEM_ID.TRADING_CALCULATORS,
    label: { key: 'menu:category.tradingCalculators' },
    iconComponent: ExposureOutlinedIcon,
    path: Routes.TRADING_CALCULATORS,
    mainMenuItem: false,
  },
  {
    id: MENU_ITEM_ID.ECONOMIC_CALENDAR,
    label: { key: 'menu:category.economicCalendar' },
    iconComponent: TodayIcon,
    path: Routes.ECONOMIC_CALENDAR,
    mainMenuItem: false,
  },
  {
    id: MENU_ITEM_ID.TRADING_TOOLS,
    label: { key: 'menu:category.tradingTools' },
    iconComponent: GetAppOutlinedIcon,
    path: Routes.DOWNLOADS,
    mainMenuItem: false,
  },
  {
    id: MENU_ITEM_ID.PROMOTIONS,
    label: { key: 'menu:category.promotions' },
    iconComponent: LoyaltyIcon,
    path: Routes.PROMOTIONS,
    mainMenuItem: false,
    labelText: { key: 'menu:category.new' },
  },
  {
    id: MENU_ITEM_ID.UPGRADES,
    label: { key: 'menu:category.upgrades' },
    iconComponent: LoyaltyIcon,
    path: Routes.UPGRADES,
    mainMenuItem: false,
    labelText: { key: 'menu:category.new' },
  },
  {
    id: MENU_ITEM_ID.FUSION_PLUS,
    label: { key: 'menu:category.fusionPlus' },
    iconComponent: GroupOutlinedIcon,
    mainMenuItem: false,
    items: [
      {
        id: 'fusionPlusAbout',
        label: { key: 'menu:category.fusionPlusAbout' },
        iconComponent: ContactSupportOutlinedIcon,
        path: Routes.FUSION_PLUS_ABOUT,
      },
      {
        id: 'fusionPlusInvitations',
        label: { key: 'menu:category.fusionPlusInvitations' },
        iconComponent: ConfirmationNumberOutlinedIcon,
        path: Routes.FUSION_PLUS_INVITATIONS,
      },
      {
        id: 'fusionPlusTrades',
        label: { key: 'menu:category.fusionPlusTrades' },
        iconComponent: TimelineIcon,
        path: Routes.MY_FOLLOWERS_TRADES,
      },
      {
        id: 'myFollowers',
        label: { key: 'menu:category.myFollowers' },
        iconComponent: GroupAddOutlinedIcon,
        path: Routes.MY_FOLLOWERS,
      },
      {
        id: 'masterAccounts',
        label: { key: 'menu:category.masterAccounts' },
        iconComponent: SupervisorAccountOutlinedIcon,
        path: Routes.MASTER_ACCOUNTS,
      },
      {
        id: 'joinWaitlist',
        label: { key: 'menu:category.joinWaitlist' },
        iconComponent: ListAltOutlinedIcon,
        path: Routes.MY_FOLLOWERS_WAITLIST,
      },
    ],
  },
  {
    id: MENU_ITEM_ID.CRYPTO_WAITLIST,
    label: { key: 'menu:category.cryptoWaitlist' },
    iconComponent: MonetizationOnOutlinedIcon,
    path: Routes.DAY1_EXCHANGE,
    mainMenuItem: false,
    labelText: { key: 'menu:category.new' },
  },
  {
    id: MENU_ITEM_ID.UPGRADE_TO_PRO,
    label: { key: 'menu:category.upgradeToPro' },
    iconComponent: AttachMoneyIcon,
    path: Routes.UPGRADE_TO_PRO,
    mainMenuItem: false,
  },
];
