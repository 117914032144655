import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    tabPanelContent: {
      minHeight: 100,
      padding: `${theme.spacing(3)}px 0`,
    },
  }),
  { name: 'TPTabPanel' },
);
