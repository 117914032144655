import get from 'lodash/get';
import first from 'lodash/first';
import React, { useMemo, useState, useEffect } from 'react';
import { withStyles, Typography } from '@material-ui/core';
import styles from './styles';
import PropTypes from 'prop-types';
import loader_squares from 'static/images/old/loader_squares.gif';
import Grid from '@material-ui/core/Grid';
import TextGroup from 'modules/common/TextGroup';
import { Line } from 'react-chartjs-2';
import Header from '../../components/BaseHeader';
import getGridOptions from './getGridOptions';
import getDataset from './getDataset';
import { DASHBOARD_METRICS, DASHBOARD_OVERVIEW_DATA_LABEL } from '../../constants';
import noMatchesFound from 'static/images/old/no_matches_found.svg';

const ActiveUsersPage = ({ classes, isPending, data, datasets, headerProps, period }) => {
  const options = useMemo(() => getGridOptions(isPending), [isPending]);
  const dataset = useMemo(() => getDataset(datasets), [datasets]);

  const getLastActualActiveUsersItem = (data) => {
    for (let i = data.length; i-- > 0; ) {
      if (data[i].activeUsers) return data[i];
    }
  };

  const [lastValue, setLastValue] = useState();
  const [firstValue, setFirstValue] = useState();
  const [isGrow, setIsGrow] = useState();

  useEffect(() => {
    if (!data) {
      return;
    }

    const firstItem = first(data);
    const lastItem = getLastActualActiveUsersItem(data);
    setFirstValue(firstItem);
    setLastValue(lastItem);

    setIsGrow(
      get(lastItem, DASHBOARD_METRICS.activeUsers, 0) >
        get(firstItem, DASHBOARD_METRICS.activeUsers, 0),
    );
  }, [data]);

  return (
    <React.Fragment>
      <Header {...headerProps} />
      <Grid container direction="column" className={`${classes.sectionText}`}>
        {isPending ? (
          <Grid container justifyContent="center" alignItems="center" className={classes.root}>
            <img alt="" src={loader_squares} />
          </Grid>
        ) : !datasets || !(datasets[DASHBOARD_METRICS.activeUsers] || []).length ? (
          <Grid
            container
            className={classes.root}
            justifyContent="center"
            alignItems="center"
            direction="column">
            <img src={noMatchesFound} alt="no-details-yet" />
            <TextGroup text="No active users for this time period" />
          </Grid>
        ) : (
          <Grid container direction="row">
            <Grid item xs={8} md={9} className={classes.field}>
              <Line height={360} data={dataset} options={options} />
            </Grid>
            {lastValue ? (
              <Grid item xs={4} md={3} className={classes.usersCount}>
                <div className={classes.usersCountContainer}>
                  <Typography className={classes.period}>{period} Days</Typography>
                  <Typography className={classes.title}>
                    {DASHBOARD_OVERVIEW_DATA_LABEL.activeUsers}
                  </Typography>
                  <Typography className={classes.lastValue}>
                    {lastValue[DASHBOARD_METRICS.activeUsers]}
                  </Typography>
                  <Typography
                    className={`${classes.diff} ${isGrow ? classes.positive : classes.negative}`}>
                    {isGrow ? '+' : ''}
                    {lastValue[DASHBOARD_METRICS.activeUsers] -
                      firstValue[DASHBOARD_METRICS.activeUsers]}
                  </Typography>
                </div>
              </Grid>
            ) : null}
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

ActiveUsersPage.propTypes = {
  isPending: PropTypes.bool,
  items: PropTypes.array,
  headerProps: PropTypes.object,
};

export default withStyles(styles)(ActiveUsersPage);
