import { handleActions } from 'redux-actions';
import { get } from 'lodash';
import * as actions from '../actions';

const defaultState = {
  feed: null,
  articles: [],
  snippets: {},
  entities: [],
};

export default handleActions(
  {
    [actions.getMyFeedDetailsDataRequest]: () => {
      return defaultState;
    },
    [actions.getMyFeedDetailsDataSuccess]: (state, action) => {
      return { ...state, feed: action.response.data };
    },
    [actions.getMyFeedArticlesSuccess]: (state, action) => {
      return { ...state, articles: action.response.data };
    },
    [actions.getArticleContentRequest]: (state, action) => {
      const { provider, id } = action.payload;
      return {
        ...state,
        ...{
          snippets: { ...state.snippets, ...{ [`${provider / id}`]: { isLoading: true } } },
        },
      };
    },
    [actions.getArticleContentSuccess]: (state, action) => {
      const { provider, id } = action.payload;
      const data = get(action.response, 'data');

      return {
        ...state,
        ...{
          snippets: {
            ...state.snippets,
            ...{ [`${provider}/${id}`]: { isLoading: false, data } },
          },
        },
      };
    },
    [actions.getArticleContentFail]: (state, action) => {
      const { provider, articleId } = action.payload;
      return {
        ...state,
        ...{
          snippets: {
            ...state.snippets,
            ...{ [`${provider}/${articleId}`]: { isLoading: false, data: '' } },
          },
        },
      };
    },
    [actions.getDiscoverEntitiesSuccess]: (state, action) => {
      return { ...state, entities: action.response.data };
    },
    [actions.getDiscoverEntitiesFail]: (state) => {
      return { ...state, entities: [] };
    },
  },
  defaultState,
);
