import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    selectContainer: {
      minWidth: '56px',
    },
    selectPlaceholder: {
      display: 'flex',
      justifyContent: 'center',
    },
    sign: {
      marginRight: theme.spacing(0.5),
      color: theme.palette.text.secondary,
    },
    flagIcon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'TPPhoneTextField' },
);

export default useStyles;
