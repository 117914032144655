import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import includes from 'lodash/includes';
import { getUserDocuments } from 'modules/auth/selectors';

import { getIpCountryCode } from 'modules/geolocation/selectors';
import { POLICY_COOKIE_NAME, COUNTRY_CODES_FOR_COOKIES } from '../../../constants';

import CookiesBanner from '../components/CookiesBanner';
import { getCookieDomain } from 'utils/getCookieName';
import { useIsInIframe } from '../hooks/useIsInIframe';

const CookiesBannerContainer = () => {
  const documents = useSelector(getUserDocuments);
  const ipCountryCode = useSelector(getIpCountryCode);
  const isInIframe = useIsInIframe();

  const [isBannerViewed, setIsBannerViewed] = useState(
    () => Cookies.get(POLICY_COOKIE_NAME) === 'true',
  );

  const showBanner = useMemo(
    () => includes(COUNTRY_CODES_FOR_COOKIES, ipCountryCode) && !isBannerViewed && !isInIframe,
    [ipCountryCode, isBannerViewed, isInIframe],
  );

  const handleClose = useCallback(() => {
    Cookies.set(POLICY_COOKIE_NAME, true, { expires: 365, domain: getCookieDomain() });
    setIsBannerViewed(true);
  }, []);

  return showBanner ? <CookiesBanner policyLink={documents.privacy} onClose={handleClose} /> : null;
};

export default CookiesBannerContainer;
