import { handleActions } from 'redux-actions';

import {
  createCellxpertTransferSuccess,
  deleteDemoAccountSuccess,
  getUserFullAccountsFail,
  getUserFullAccountsSuccess,
  getUserDemoAccountsFail,
  getUserDemoAccountsSuccess,
  getUserLiveAccountsFail,
  getUserLiveAccountsSuccess,
  getUserTotalBalanceSuccess,
  setDemoAccountUnlimitedExpirationSuccess,
  getUserRejectedAccountsSuccess,
} from '../actions';

const defaultState = {
  filteredLiveAccounts: {
    requestPayload: null,
  },
  filteredDemoAccounts: {
    requestPayload: null,
  },
  filteredRejectedAccounts: {
    requestPayload: null,
  },
};

export default handleActions(
  {
    [getUserLiveAccountsSuccess](state, action) {
      return {
        ...state,
        filteredLiveAccounts: {
          accounts: action.response.data.accounts,
          totalNumber: action.response.data.totalNumber,
          requestPayload: action.payload,
        },
      };
    },
    [getUserRejectedAccountsSuccess](state, action) {
      return {
        ...state,
        filteredRejectedAccounts: {
          accounts: action.response.data.accounts,
          totalNumber: action.response.data.totalNumber,
          requestPayload: action.payload,
        },
      };
    },
    [getUserDemoAccountsSuccess](state, action) {
      return {
        ...state,
        filteredDemoAccounts: {
          accounts: action.response.data.accounts,
          totalNumber: action.response.data.totalNumber,
          requestPayload: action.payload,
        },
      };
    },

    [getUserLiveAccountsFail]() {
      return defaultState;
    },

    [getUserDemoAccountsFail]() {
      return defaultState;
    },

    [getUserFullAccountsSuccess](state, action) {
      return {
        ...state,
        ...action.response.data,
      };
    },

    [getUserFullAccountsFail]() {
      return defaultState;
    },

    [deleteDemoAccountSuccess](state, action) {
      const deletedAccount = action.response.data;
      const demoAccounts = state.demoAccounts?.filter(
        (account) => account._id !== deletedAccount._id,
      );
      const filteredDemoAccounts = state.filteredDemoAccounts?.accounts.filter(
        (account) => account._id !== deletedAccount._id,
      );
      return {
        ...state,
        filteredDemoAccounts: { ...state.filteredDemoAccounts, accounts: filteredDemoAccounts },
        demoAccounts,
      };
    },

    [setDemoAccountUnlimitedExpirationSuccess](state, action) {
      const targetAccount = action.response.data;
      const { demoAccounts } = state;

      return {
        ...state,
        demoAccounts: demoAccounts.map((a) => {
          if (a._id === targetAccount._id) {
            a.isLimitless = targetAccount.isLimitless;
          }
          return a;
        }),
      };
    },
    [createCellxpertTransferSuccess]: (state, action) => {
      const accountId = action.payload.account._id;
      const { balance } = action.response.data;
      const { liveAccounts } = state;
      return {
        ...state,
        filteredLiveAccounts: liveAccounts.map((a) => {
          if (a._id === accountId) {
            a.balance = String(parseInt(balance));
          }
          return a;
        }),
      };
    },

    [getUserTotalBalanceSuccess]: (state, action) => {
      return {
        ...state,
        totalBalance: action.response.data,
      };
    },
  },
  defaultState,
);
