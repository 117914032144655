import { fontSize } from '../font';
import palette from './palette';

const OverridesMuiFormHelperText = {
  root: {
    fontSize: fontSize._12,
    lineHeight: 1.2,
    color: palette.text.secondary,
    '&$error': {
      color: palette.text.secondary,
    },
  },
  contained: {
    marginLeft: 0,
    marginRight: 0,
  },
};

export default OverridesMuiFormHelperText;
