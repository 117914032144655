import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import classNames from 'classnames';
import TPIconButton from 'components/TP-UI/TPIconButton';
import useStyles from './styles';

const TPSidebarLogo = ({
  alt,
  src = '',
  path = '',
  hideButton = false,
  expanded = false,
  onExpandClick,
  className,
}) => {
  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      {path ? (
        <Link to={path} className={classes.logoWrapper}>
          <img src={src} alt={alt} className={classes.logo} />
        </Link>
      ) : (
        <div className={classes.logoWrapper}>
          <img src={src} alt={alt} className={classes.logo} />
        </div>
      )}
      {!hideButton ? (
        <div className={classes.buttonWrapper}>
          <TPIconButton
            className={classes.expandButton}
            aria-expanded={expanded}
            onClick={onExpandClick}>
            {expanded ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </TPIconButton>
        </div>
      ) : null}
    </div>
  );
};

TPSidebarLogo.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string,
  path: PropTypes.string,
  hideButton: PropTypes.bool,
  expanded: PropTypes.bool,
  onExpandClick: PropTypes.func.isRequired,
};

export default TPSidebarLogo;
