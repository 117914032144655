import React, { useCallback, useState } from 'react';
import OverviewDataView from '../views/OverviewDataView';
import {
  CUSTOM_DATE_RANGE_KEY,
  DASHBOARD_OVERVIEW_DATA_HEADER_FORM,
  DASHBOARD_STATS_CUSTOMIZATION_FORM,
  DEFAULT_METRICS_DATES,
} from '../constants';
import get from 'lodash/get';
import {
  getDashboardComparisonGraphDataRequest,
  getDashboardGraphDataRequest,
  getDashboardOverviewDataRequest,
  getDashboardTotalAndGraphDataRequest,
  saveDashboardStatsCustomizationDataRequest,
} from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDashboardComparisonGraphDatasets,
  getDashboardGraphDatasets,
  getDashboardOverviewData,
  getDashboardStats,
} from '../selectors';
import { createPendingSelector } from '../../api/selectors';
import { getIsGlobalAdmin, getUserEmail } from 'modules/auth/selectors';
import { getUserEligibleNewMetrics } from '../utils/newMetricsEligibleUsers';

const OverviewDataContainer = () => {
  const dispatch = useDispatch();
  const stats = useSelector(getDashboardStats);
  const overviewData = useSelector(getDashboardOverviewData);
  const graphDatasets = useSelector(getDashboardGraphDatasets);
  const isGraphDataLoading = useSelector(createPendingSelector(getDashboardGraphDataRequest));
  const isOverviewDataLoading = useSelector(createPendingSelector(getDashboardOverviewDataRequest));
  const isTotalAndGraphDataLoading = useSelector(
    createPendingSelector(getDashboardTotalAndGraphDataRequest),
  );
  const [shouldCompareTo, setShouldCompareTo] = useState(false);
  const comparisonGraphDatasets = useSelector(getDashboardComparisonGraphDatasets);
  const isComparisonGraphDataLoading = useSelector(
    createPendingSelector(getDashboardComparisonGraphDataRequest),
  );
  const isGlobalAdmin = useSelector(getIsGlobalAdmin);
  const userEmail = useSelector(getUserEmail);
  const isUserEligibleNewMetrics = getUserEligibleNewMetrics(userEmail);

  const handleHeaderFormSubmit = useCallback(
    (data) => {
      const dateFrom = data.dateFrom || DEFAULT_METRICS_DATES['dateFrom'];
      const dateTo = data.dateTo || DEFAULT_METRICS_DATES['dateTo'];
      const shouldCompare = get(data, 'shouldCompare', false);
      let payload = {
        dateFrom,
        dateTo,
        asUser: get(stats, 'asUser', ''),
      };

      const dateFromCompare = data.dateFromCompare || DEFAULT_METRICS_DATES['compareDateFrom'];
      const dateToCompare = data.dateToCompare || DEFAULT_METRICS_DATES['compareDateTo'];

      if (shouldCompare) {
        dispatch(
          getDashboardComparisonGraphDataRequest({
            dateFrom: dateFromCompare,
            dateTo: dateToCompare,
          }),
        );
        payload.dateFromCompare = dateFromCompare;
        payload.dateToCompare = dateToCompare;
        setShouldCompareTo(true);
      } else {
        setShouldCompareTo(false);
      }
      dispatch(
        getDashboardGraphDataRequest({
          dateFrom,
          dateTo,
          asUser: payload.asUser,
        }),
      );
      // TODO: When tested on PRODUCTION by Amal and confirmed, we need to remove old button
      dispatch(
        getDashboardOverviewDataRequest({
          ...payload,
        }),
      );
    },
    [dispatch, stats],
  );

  const handleHeaderFormNewSubmit = useCallback(
    (data) => {
      const dateFrom = data.dateFrom || DEFAULT_METRICS_DATES['dateFrom'];
      const dateTo = data.dateTo || DEFAULT_METRICS_DATES['dateTo'];
      const shouldCompare = get(data, 'shouldCompare', false);

      let payload = {
        dateFrom: dateFrom,
        dateTo: dateTo,
        asUser: get(stats, 'asUser', ''),
      };

      if (shouldCompare) {
        const dateFromCompare = data.dateFromCompare || DEFAULT_METRICS_DATES['compareDateFrom'];
        const dateToCompare = data.dateToCompare || DEFAULT_METRICS_DATES['compareDateTo'];

        dispatch(
          getDashboardComparisonGraphDataRequest({
            dateFrom: dateFromCompare,
            dateTo: dateToCompare,
          }),
        );
        setShouldCompareTo(true);
      } else {
        setShouldCompareTo(false);
      }

      dispatch(
        getDashboardTotalAndGraphDataRequest({
          dateFrom,
          dateTo,
          asUser: payload.asUser,
        }),
      );
    },
    [dispatch, stats],
  );

  const headerProps = {
    form: DASHBOARD_OVERVIEW_DATA_HEADER_FORM,
    initialValues: {
      customDateSelector: CUSTOM_DATE_RANGE_KEY.MONTH_TO_DATE,
      dateFrom: DEFAULT_METRICS_DATES['dateFrom'],
      dateTo: DEFAULT_METRICS_DATES['dateTo'],
      shouldCompare: false,
      compareDateSelector: CUSTOM_DATE_RANGE_KEY.MONTH_TO_DATE,
      dateFromCompare: DEFAULT_METRICS_DATES['compareDateFrom'],
      dateToCompare: DEFAULT_METRICS_DATES['compareDateTo'],
    },
    onSubmit: handleHeaderFormSubmit,
    onNewSubmit: handleHeaderFormNewSubmit,
  };

  const handleStatsCustomizationFormSubmit = useCallback(
    (data) => {
      dispatch(saveDashboardStatsCustomizationDataRequest({ metrics: data }));
    },
    [dispatch],
  );

  const statsCustomizationProps = {
    form: DASHBOARD_STATS_CUSTOMIZATION_FORM,
    initialValues: stats,
    onSubmit: handleStatsCustomizationFormSubmit,
    isGlobalAdmin: isGlobalAdmin,
  };

  return (
    <OverviewDataView
      headerProps={headerProps}
      statsCustomizationProps={statsCustomizationProps}
      overviewData={overviewData}
      isOverviewDataLoading={isOverviewDataLoading}
      isGraphDataLoading={isGraphDataLoading}
      isTotalAndGraphDataLoading={isTotalAndGraphDataLoading}
      graphDatasets={graphDatasets}
      shouldCompareTo={shouldCompareTo}
      comparisonGraphDatasets={comparisonGraphDatasets}
      isComparisonGraphDataLoading={isComparisonGraphDataLoading}
      isUserEligibleNewMetrics={isUserEligibleNewMetrics}
    />
  );
};

export default OverviewDataContainer;
