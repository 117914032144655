import { handleActions } from 'redux-actions';
import get from 'lodash/get';

import { getReferralLinkFail, getReferralLinkRequest, getReferralLinkSuccess } from '../actions';

const defaultState = null;

export default handleActions(
  {
    [getReferralLinkRequest]: () => '',
    [getReferralLinkSuccess]: (_state, action) => get(action, 'response.data.referralLink', ''),
    [getReferralLinkFail]: () => '',
  },
  defaultState,
);
