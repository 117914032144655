const styles = (theme) => ({
  title: {
    marginRight: '10px',
    color: theme.palette.text.main,
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '22px',
    fontFamily: 'Lato, sans-serif',
    whiteSpace: 'nowrap',
  },
  field: {
    marginRight: 20,
  },
});

export default styles;
