import makeStyles from '@material-ui/core/styles/makeStyles';
import { BOTTOM_BAR_HEIGHT } from '../../constants/index';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'fixed',
      bottom: 0,
      height: BOTTOM_BAR_HEIGHT,
      zIndex: theme.zIndex.modal,
    },
  }),
  { name: 'BottomNavigation' },
);

export default useStyles;
