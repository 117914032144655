import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { required } from 'utils/validation/fieldValidationRules';
import TPModal from 'components/TP-UI/TPModal';
import { closeModal } from 'components/TP-UI/TPModal/actions';
import TPGrid from 'components/TP-UI/TPGrid';
import TPTextField from 'components/TP-UI/TPTextField';
import TPTypography from 'components/TP-UI/TPTypography';
import TPDocument from 'components/TP-UI/TPDocument';
import JoinPlusTerms from '../JoinPlusTerms';
import TPErrorMessage from 'components/TP-UI/TPErrorMessage';

const JoinFusionPlusModal = ({ modalName, form, reset, handleSubmit, joinPending, error }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('myFollowers');

  const handleCancelClick = useCallback(() => {
    reset(form);
    dispatch(closeModal(modalName));
  }, [dispatch, modalName, reset, form]);

  const buttons = useMemo(
    () => [
      { label: t('joinPlus.join'), primary: true, type: 'submit', onClick: handleSubmit },
      { label: t('common:buttons.cancel'), secondary: true, onClick: handleCancelClick },
    ],
    [t, handleSubmit, handleCancelClick],
  );

  return (
    <TPModal
      form={form}
      modalName={modalName}
      onClose={handleCancelClick}
      onSubmit={handleSubmit}
      title={t('joinPlus.title')}
      loading={joinPending}
      buttons={buttons}>
      <TPGrid container direction="column" spacing={3}>
        {error && (
          <TPGrid item xs={12}>
            <TPErrorMessage error={error} size="medium" />
          </TPGrid>
        )}
        <TPGrid item container spacing={2} direction="column">
          <TPGrid item>
            <TPTypography variant="h6" gutterBottom>
              {'Fusion Plus Customer Terms and Conditions'}
            </TPTypography>
            <TPDocument fullWidth rows={14}>
              <JoinPlusTerms />
            </TPDocument>
          </TPGrid>
          <TPGrid item>
            <Field
              component={TPTextField}
              fullWidth
              required
              clearable
              autofocus
              name="sign"
              label={t('joinPlus.sign')}
              placeholder={t('joinPlus.sign')}
              validate={[required]}
            />
          </TPGrid>
        </TPGrid>
      </TPGrid>
    </TPModal>
  );
};

JoinFusionPlusModal.propTypes = {
  modalName: PropTypes.string,
  form: PropTypes.string,
  reset: PropTypes.func,
  joinPending: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default reduxForm({ enableReinitialize: true })(JoinFusionPlusModal);
