import React from 'react';
import PropTypes from 'prop-types';
import Page from 'modules/common/Page';
import { useTranslation } from 'react-i18next';
import config from 'config';
import Layout from '../../../../components/LayoutNew';
import TPTypography from 'components/TP-UI/TPTypography';
import TPLink from 'components/TP-UI/TPLink';
import { Routes } from 'constants/routeConstants';

import TPLoader from 'components/TP-UI/TPLoader';
import TPGrid from 'components/TP-UI/TPGrid';
import FormError from 'modules/common/FormError';
import TPButton from 'components/TP-UI/TPButton';

const EmailVerification = ({
  token,
  canResend,
  pending,
  pendingResendEmail,
  verificationError,
  onSendEmail,
}) => {
  const { t } = useTranslation('auth');
  const { CURRENT_HUB_LABEL } = config;

  const error = !token ? { key: 'backend:verificationTokenExpired' } : verificationError;
  return (
    <Page docTitle={t('emailVerification.documentTitle', { company: CURRENT_HUB_LABEL })}>
      <Layout
        title={t('emailVerification.title')}
        welcomeTitle={t('logInWelcome')}
        welcomeDescription={t('logInWelcomeDescription')}>
        <TPGrid container spacing={3} alignItems="center" justifyContent="center">
          {pending ? (
            <TPGrid item xs={12}>
              <TPLoader />
            </TPGrid>
          ) : error ? (
            <TPGrid item xs={12}>
              <FormError error={error} />
            </TPGrid>
          ) : null}
          {canResend ? (
            <TPGrid item xs={12}>
              <TPButton
                fullWidth
                primary
                size="large"
                onClick={onSendEmail}
                loading={pendingResendEmail}>
                {t('emailVerification.resendEmail')}
              </TPButton>
            </TPGrid>
          ) : null}
          <TPGrid item>
            <TPTypography variant="body1">
              <TPLink to={{ pathname: Routes.SIGNIN, state: { cleanupStore: true } }}>
                {t('backToLogIn')}
              </TPLink>
            </TPTypography>
          </TPGrid>
        </TPGrid>
      </Layout>
    </Page>
  );
};

EmailVerification.propTypes = {
  token: PropTypes.string,
  canResend: PropTypes.bool,
  pending: PropTypes.bool,
  pendingResendEmail: PropTypes.bool,
  verificationError: PropTypes.object,
  onSendEmail: PropTypes.func.isRequired,
};

export default EmailVerification;
