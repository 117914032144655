import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    groupLabel: {
      ...theme.formControl.groupLabel,
    },
    errorContainer: {
      minHeight: theme.textError.root.minHeight,
    },
    customSelect: {
      '&:before, &:after': {
        display: 'none',
      },
      '&$hiddenArrow .MuiSelect-select.MuiSelect-select': {
        paddingRight: 0,
      },
    },
    hiddenArrow: {
      '& .MuiSelect-select.MuiSelect-select': {
        paddingRight: theme.spacing(1.25),
      },
    },
    menuPaper: {
      maxHeight: 180,
    },
  }),
  { name: 'TPSelect' },
);
