import React from 'react';
import classnames from 'classnames';
import Avatar from '@material-ui/core/Avatar';

import useStyles from './styles';

const TPCardImage = ({ src, alt, className, children, ...props }) => {
  const classes = useStyles();

  return (
    <Avatar
      variant="rounded"
      src={src}
      alt={alt}
      className={classnames(classes.root, className)}
      {...props}>
      {children}
    </Avatar>
  );
};

export default TPCardImage;
