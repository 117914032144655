import { fontSize, fontWeight } from './font';

export const typographyFonts = {
  h1: {
    fontWeight: fontWeight.bold,
    fontSize: fontSize._32,
    lineHeight: 1.1875,
  },
  h2: {
    fontWeight: fontWeight.regular,
    fontSize: fontSize._32,
    lineHeight: 1.215,
  },
  h3: {
    fontWeight: fontWeight.bold,
    fontSize: fontSize._24,
    lineHeight: 1.2,
  },
  h4: {
    fontWeight: fontWeight.regular,
    fontSize: fontSize._24,
    lineHeight: 1.2,
  },
  h5: {
    fontWeight: fontWeight.bold,
    fontSize: fontSize._20,
    lineHeight: 1.2,
  },
  h6: {
    fontWeight: fontWeight.regular,
    fontSize: fontSize._20,
    lineHeight: 1.2,
  },
  subtitle1: {
    fontWeight: fontWeight.bold,
    fontSize: fontSize._14,
    lineHeight: 1.215,
  },
  subtitle2: {
    fontWeight: fontWeight.regular,
    fontSize: fontSize._12,
    lineHeight: 1.167,
  },
  body1: {
    fontWeight: fontWeight.regular,
    fontSize: fontSize._16,
    lineHeight: 1.1875,
  },
  body2: {
    fontWeight: fontWeight.regular,
    fontSize: fontSize._14,
    lineHeight: 1.4285,
  },
  body3: {
    fontWeight: fontWeight.regular,
    fontSize: fontSize._12,
    lineHeight: 1.167,
  },
  button: {
    fontWeight: fontWeight.bold,
    fontSize: fontSize._14,
    lineHeight: 1.215,
  },
};
