import palette from './palette';

const OverridesMuiListItem = {
  root: {
    '&$selected, &$selected:hover': {
      backgroundColor: palette.primary.lightest,
    },
  },
};

export default OverridesMuiListItem;
