import { DOCUMENTS, HUB_TO_DOCS } from 'constants/documents';

const getDocuments = (userHub, unauthorizedHub) => {
  if (userHub && HUB_TO_DOCS[userHub]) {
    return HUB_TO_DOCS[userHub];
  }
  // use unauthorizedHub only if there is no userHub
  if (unauthorizedHub && HUB_TO_DOCS[unauthorizedHub]) {
    return HUB_TO_DOCS[unauthorizedHub];
  }
  return DOCUMENTS;
};

export default getDocuments;
