import { createAction } from 'redux-actions';

export const riskGroupGetCountriesRequest = createAction('RISK_GROUP_GET_COUNTRIES_REQUEST');
export const riskGroupGetCountriesSuccess = createAction('RISK_GROUP_GET_COUNTRIES_SUCCESS');
export const riskGroupGetCountriesFail = createAction('RISK_GROUP_GET_COUNTRIES_FAIL');

export const riskGroupAddCountryRequest = createAction('RISK_GROUP_ADD_COUNTRY_REQUEST');
export const riskGroupAddCountrySuccess = createAction('RISK_GROUP_ADD_COUNTRY_SUCCESS');
export const riskGroupAddCountryFail = createAction('RISK_GROUP_ADD_COUNTRY_FAIL');

export const riskGroupRemoveCountryRequest = createAction('RISK_GROUP_REMOVE_COUNTRY_REQUEST');
export const riskGroupRemoveCountrySuccess = createAction('RISK_GROUP_REMOVE_COUNTRY_SUCCESS');
export const riskGroupRemoveCountryFail = createAction('RISK_GROUP_REMOVE_COUNTRY_FAIL');
