const isStateRestricted = (userProfile, countiesCollection) => {
  const { country: userCountry, state: userState } = userProfile;
  return (
    userState &&
    countiesCollection
      ?.find(({ name }) => name === userCountry)
      ?.states?.find(({ name, code }) => name === userState || code === userState)?.restrictedState
  );
};

export default isStateRestricted;
