import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import QuizModal from '../components/QuizModal';

const QuizModalContainer = (props) => {
  const {
    currentQuestionIndex = 0,
    form,
    loading,
    modalName,
    onCancel,
    onNext,
    onPrevious,
    onSubmit,
    progressIndex,
    questions = [],
    title,
    titleEndAdornment,
    questionComponent,
  } = props;

  const handleSubmit = useCallback(
    (values) => {
      if (onSubmit && currentQuestionIndex === questions.length - 1) {
        onSubmit(values);
      } else if (onNext && currentQuestionIndex < questions.length - 1) {
        onNext(currentQuestionIndex);
      }
    },
    [currentQuestionIndex, onNext, onSubmit, questions.length],
  );

  const handlePrevClick = useCallback(() => {
    if (onPrevious) {
      onPrevious(currentQuestionIndex);
    }
  }, [currentQuestionIndex, onPrevious]);

  const progress = (progressIndex * 100) / questions.length;

  return (
    <QuizModal
      answers={questions[currentQuestionIndex]?.answers}
      currentQuestionIndex={currentQuestionIndex}
      form={form}
      loading={loading}
      modalName={modalName}
      onCancel={onCancel}
      onPrevClick={handlePrevClick}
      onSubmit={handleSubmit}
      progress={progress}
      questionId={questions[currentQuestionIndex]?._id}
      questionLabel={questions[currentQuestionIndex]?.label}
      title={title}
      titleEndAdornment={titleEndAdornment}
      totalQuestionCount={questions?.length}
      questionComponent={questionComponent}
    />
  );
};

QuizModalContainer.propTypes = {
  currentQuestionIndex: PropTypes.number,
  form: PropTypes.string,
  loading: PropTypes.bool,
  modalName: PropTypes.string,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onSubmit: PropTypes.func,
  progressIndex: PropTypes.number,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
      label: PropTypes.string,
    }),
  ),
  title: PropTypes.string,
  titleEndAdornment: PropTypes.node,
  questionComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
};

export default QuizModalContainer;
