import { combineActions, handleActions } from 'redux-actions';
import {
  forgotPasswordFail,
  forgotPasswordSuccess,
  setSelectHubOpen,
  setSelectAvailableHubs,
  initializeForm,
} from '../actions';

const defaultState = {
  selectHub: false,
  selectAvailableHubs: [],
};

export default handleActions(
  {
    [combineActions(setSelectHubOpen)](state, action) {
      return { ...state, selectHub: action.payload };
    },
    [combineActions(setSelectAvailableHubs)](state, action) {
      return { ...state, selectAvailableHubs: action.payload };
    },
    [combineActions(forgotPasswordSuccess, forgotPasswordFail)](state) {
      return { ...state, selectHub: false };
    },
    [combineActions(initializeForm)](state) {
      return { ...state, selectHub: false };
    },
  },
  defaultState,
);
