import React from 'react';
import TPAlert from 'components/TP-UI/TPAlert';
import TPGrid from 'components/TP-UI/TPGrid';
import TPAccordion from 'components/TP-UI/TPAccordion';
import TPAccordionSummary from 'components/TP-UI/TPAccordion/components/TPAccordionSummary';
import TPAccordionDetails from 'components/TP-UI/TPAccordion/components/TPAccordionDetails';
import TPTypography from 'components/TP-UI/TPTypography';
import { TPTextField } from 'components/TP-UI/TPTextField';
import AddAlertOutlinedIcon from '@material-ui/icons/AddAlertOutlined';

const Accordions = () => {
  return (
    <TPGrid container spacing={2}>
      <TPGrid item xs={12}>
        <TPAccordion loading={true} fullWidth defaultExpanded={true}>
          <TPAccordionSummary title="Loading" />
          <TPAccordionDetails>
            <TPGrid container spacing={2}>
              <TPGrid item xs={6}>
                <TPTextField
                  id="slippage"
                  name="slippage"
                  label="Max Slippage Acceptable"
                  placeholder="Max Slippage Acceptable"
                  fullWidth
                  required
                  clearable
                  autofocus
                />
              </TPGrid>
              <TPGrid item xs={6}>
                <TPTextField
                  id="maxLot"
                  name="maxLot"
                  label="Max Lot"
                  placeholder="Max Lot"
                  fullWidth
                  required
                  clearable
                  autofocus
                />
              </TPGrid>
            </TPGrid>
          </TPAccordionDetails>
        </TPAccordion>
      </TPGrid>
      <TPGrid item xs={12}>
        <TPAccordion fullWidth>
          <TPAccordionSummary title="Disable Symbols" />
          <TPAccordionDetails>
            <TPAlert variant="info">All symbols are available</TPAlert>
          </TPAccordionDetails>
        </TPAccordion>
      </TPGrid>
      <TPGrid item xs={12}>
        <TPAccordion disabled={true} fullWidth>
          <TPAccordionSummary title="Disabled" />
          <TPAccordionDetails>
            <TPTypography variant="body1">Disabled Accordion</TPTypography>
          </TPAccordionDetails>
        </TPAccordion>
      </TPGrid>
      <TPGrid item xs={12}>
        <TPAccordion fullWidth variant="outlined">
          <TPAccordionSummary title="Variant Outlined" />
          <TPAccordionDetails>
            <TPTypography variant="body1">Variant Outlined</TPTypography>
          </TPAccordionDetails>
        </TPAccordion>
      </TPGrid>
      <TPGrid item xs={12}>
        <TPAccordion fullWidth variant="elevation" elevation>
          <TPAccordionSummary title="Variant Elevation" />
          <TPAccordionDetails>
            <TPTypography variant="body1">Variant Elevation</TPTypography>
          </TPAccordionDetails>
        </TPAccordion>
      </TPGrid>
      <TPGrid item xs={12}>
        <TPAccordion fullWidth variant="filled">
          <TPAccordionSummary title="Variant Filled" />
          <TPAccordionDetails>
            <TPTypography variant="body1">Variant Filled</TPTypography>
          </TPAccordionDetails>
        </TPAccordion>
      </TPGrid>
      <TPGrid item xs={12}>
        <TPAccordion fullWidth variant="filled">
          <TPAccordionSummary titleIcon={AddAlertOutlinedIcon} title="Variant Filled with Icon" />
          <TPAccordionDetails>
            <TPTypography variant="body1">Variant Filled with Icon</TPTypography>
          </TPAccordionDetails>
        </TPAccordion>
      </TPGrid>
    </TPGrid>
  );
};

export default Accordions;
