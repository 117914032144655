import { handleActions } from 'redux-actions';

import { setWithdrawalActiveStep } from '../actions';

const defaultState = {
  activeStep: 0,
};

export default handleActions(
  {
    [setWithdrawalActiveStep](state, action) {
      return { ...state, activeStep: action?.payload || defaultState.activeStep };
    },
  },
  defaultState,
);
