import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TPButton from 'components/TP-UI/TPButton';

import PagePlaceholder from '../PagePlaceholder';

const NoUserVerificationView = ({ newUser, onClick, ...props }) => {
  const { t } = useTranslation('common');

  return (
    <PagePlaceholder
      {...props}
      button={
        <TPButton primary size="large" onClick={onClick}>
          {newUser ? t('buttons.verifyNow') : t('buttons.continue')}
        </TPButton>
      }
    />
  );
};

NoUserVerificationView.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.node,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  newUser: PropTypes.bool,
};

export default NoUserVerificationView;
