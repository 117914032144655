import { handleActions } from 'redux-actions';
import { getTradingViewSubscriptionFail, getTradingViewSubscriptionSuccess } from '../actions';

const defaultState = {
  subscription: {},
  checkAvailable: false,
};

export default handleActions(
  {
    [getTradingViewSubscriptionSuccess](state, action) {
      return {
        ...state,
        checkAvailable: action.response?.data?.available,
        subscription: action.response?.data?.value,
      };
    },
    [getTradingViewSubscriptionFail]() {
      return defaultState;
    },
  },
  defaultState,
);
