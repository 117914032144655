import createAction from 'redux-actions/es/createAction';

export const changePasswordRequest = createAction('CHANGE_PASSWORD_REQUEST');
export const changePasswordSuccess = createAction('CHANGE_PASSWORD_SUCCESS');
export const changePasswordFail = createAction('CHANGE_PASSWORD_FAIL');

export const searchBankDetailsRequest = createAction('SEARCH_BANK_DETAILS_REQUEST');
export const searchBankDetailsSuccess = createAction('SEARCH_BANK_DETAILS_SUCCESS');
export const searchBankDetailsFail = createAction('SEARCH_BANK_DETAILS_FAIL');

export const profilePageOpened = createAction('PROFILE_PAGE_OPENED');

export const createBankDetailsRequest = createAction('CREATE_BANK_DETAILS_REQUEST');
export const createBankDetailsSuccess = createAction('CREATE_BANK_DETAILS_SUCCESS');
export const createBankDetailsFail = createAction('CREATE_BANK_DETAILS_FAIL');

export const deleteBankDetailsRequest = createAction('DELETE_BANK_DETAILS_REQUEST');
export const deleteBankDetailsSuccess = createAction('DELETE_BANK_DETAILS_SUCCESS');
export const deleteBankDetailsFail = createAction('DELETE_BANK_DETAILS_FAIL');

export const createAuthorisedPersonRequest = createAction('CREATE_AUTHORISED_PERSON_REQUEST');
export const createAuthorisedPersonSuccess = createAction('CREATE_AUTHORISED_PERSON_SUCCESS');
export const createAuthorisedPersonFail = createAction('CREATE_AUTHORISED_PERSON_FAIL');

export const getAuthorisedPersonsRequest = createAction('GET_AUTHORISED_PERSONS_REQUEST');
export const getAuthorisedPersonsSuccess = createAction('GET_AUTHORISED_PERSONS_SUCCESS');
export const getAuthorisedPersonsFail = createAction('GET_AUTHORISED_PERSONS_FAIL');

export const deleteAuthorisedPersonRequest = createAction('DELETE_AUTHORISED_PERSON_REQUEST');
export const deleteAuthorisedPersonSuccess = createAction('DELETE_AUTHORISED_PERSON_SUCCESS');
export const deleteAuthorisedPersonFail = createAction('DELETE_AUTHORISED_PERSON_FAIL');

export const saveEmailNotificationsSettingsRequest = createAction(
  'SAVE_EMAIL_NOTIFICATIONS_SETTINGS_REQUEST',
);
export const toggleMailchimpSubscriptionRequest = createAction(
  'TOGGLE_MAILCHIMP_SUBSCRIPTION_REQUEST',
);
export const saveEmailNotificationsSettingsSuccess = createAction(
  'SAVE_EMAIL_NOTIFICATIONS_SETTINGS_SUCCESS',
);
export const toggleMailchimpSubscriptionSuccess = createAction(
  'TOGGLE_MAILCHIMP_SUBSCRIPTION_SUCCESS',
);
export const saveEmailNotificationsSettingsFail = createAction(
  'SAVE_EMAIL_NOTIFICATIONS_SETTINGS_FAIL',
);
export const subscribeEmailNotificationsSettingsRequest = createAction(
  'SUBSCRIBE_EMAIL_NOTIFICATIONS_SETTINGS_REQUEST',
);
export const unsubscribeEmailNotificationsSettingsRequest = createAction(
  'UNSUBSCRIBE_EMAIL_NOTIFICATIONS_SETTINGS_REQUEST',
);
export const subscribeEmailNotificationsSettingsRequestSuccess = createAction(
  'SUBSCRIBE_EMAIL_NOTIFICATIONS_SETTINGS_SUCCESS',
);
export const subscribeEmailNotificationsSettingsRequestFail = createAction(
  'SUBSCRIBE_EMAIL_NOTIFICATIONS_SETTINGS_FAIL',
);
export const unsubscribeEmailNotificationsSettingsRequestSuccess = createAction(
  'UNSUBSCRIBE_EMAIL_NOTIFICATIONS_SETTINGS_SUCCESS',
);
export const unsubscribeEmailNotificationsSettingsRequestFail = createAction(
  'UNSUBSCRIBE_EMAIL_NOTIFICATIONS_SETTINGS_FAIL',
);
export const toggleMailchimpSubscriptionFail = createAction('TOGGLE_MAILCHIMP_SUBSCRIPTION_FAIL');
export const getMailchimpStatusRequest = createAction('GET_MAILCHIMP_STATUS_REQUEST');
export const getMailchimpStatusSuccess = createAction('GET_MAILCHIMP_STATUS_SUCCESS');
export const getMailchimpStatusFail = createAction('GET_MAILCHIMP_STATUS_FAIL');
