import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import { reduxForm } from 'redux-form';

import styles from './styles';
import GoToPageFields from '../GoToPageFields';
import PagesList from '../PagesList';
import Select from 'modules/common/InternalHubTable/Header/Select';
import { PAGE_LIMITS_VALUES } from '../../constants';

const PaginationBar = ({
  classes,
  pages,
  currentPage,
  onPageChange,
  onPreviousPageClick,
  onNextPageClick,
  handleSubmit,
  onPageSizeChange,
}) => {
  const pagesNumber = pages.length;
  const lastPageIndex = pages.length - 1;
  const lastPagesPart = pages[lastPageIndex];
  const lastPage = lastPagesPart && lastPagesPart[lastPagesPart.length - 1];
  return (
    <form onSubmit={handleSubmit} className={classes.pagination}>
      <button
        onClick={onPreviousPageClick}
        className={classNames(classes.arrowButton, {
          [classes.arrowButtonDisabled]: currentPage === 1,
        })}>
        <ArrowLeft fontSize="small" />
      </button>
      {pages.map((pagesPart, index) => (
        <Fragment key={index}>
          <PagesList pages={pagesPart} currentPage={currentPage} onPageChange={onPageChange} />
          {index !== lastPageIndex && <div className={classes.dots}>...</div>}
        </Fragment>
      ))}
      <button
        className={classNames(classes.arrowButton, {
          [classes.arrowButtonDisabled]: currentPage === lastPage || pagesNumber === 0,
        })}
        onClick={onNextPageClick}>
        <ArrowRight fontSize="small" />
      </button>
      <GoToPageFields />
      <div className={classes.pageSizeContainer}>
        Show
        <Select
          onChange={onPageSizeChange}
          name="pageSize"
          selectItems={PAGE_LIMITS_VALUES}
          className={classes.pageSizeSelect}
        />
        entries
      </div>
    </form>
  );
};

PaginationBar.propTypes = {
  pages: PropTypes.array,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPreviousPageClick: PropTypes.func.isRequired,
  onNextPageClick: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
};

export default compose(
  reduxForm({
    destroyOnUnmount: false,
  }),
  withStyles(styles),
)(PaginationBar);
