import { handleActions } from 'redux-actions';

import {
  getTvSubscriptionsFail,
  getTvSubscriptionsSuccess,
  getTvSubscriptionHistoryFail,
  getTvSubscriptionHistorySuccess,
  getTvSubscriptionsRequest,
  getTvSubscriptionsLotsSuccess,
  getTvSubscriptionSignedDocsSuccess,
} from '../actions';

const defaultState = {
  tvSubscriptions: [],
  totalNumber: undefined,
  subscriptionHistory: undefined,
  requestPayload: undefined,
  tvSubSignedDocs: [],
};

export default handleActions(
  {
    [getTvSubscriptionsRequest](state, action) {
      return {
        ...state,
        requestPayload: action.payload,
      };
    },
    [getTvSubscriptionsSuccess](state, action) {
      return {
        ...state,
        tvSubscriptions: action.response.data.tvSubscriptions,
        totalNumber: action.response.data.totalNumber,
      };
    },
    [getTvSubscriptionsFail](state) {
      return { ...state, totalNumber: state.totalNumber === undefined ? 0 : state.totalNumber };
    },
    [getTvSubscriptionHistorySuccess](state, action) {
      return {
        ...state,
        subscriptionHistory: action.response.data.history,
      };
    },
    [getTvSubscriptionHistoryFail](state) {
      return { ...state, subscriptionHistory: [] };
    },
    [getTvSubscriptionsLotsSuccess](state, action) {
      const subsWithLots = action.response.data.subsWithLots;
      return {
        ...state,
        tvSubscriptions: state.tvSubscriptions.map((sub) => ({
          ...sub,
          lots: subsWithLots[sub._id],
        })),
      };
    },
    [getTvSubscriptionSignedDocsSuccess](state, action) {
      return {
        ...state,
        tvSubSignedDocs: action.response.data.signedDocuments,
      };
    },
  },
  defaultState,
);
