import { createAction } from 'redux-actions';

export const startUpgradeToPro = createAction('UPGRADE_TO_PRO');
export const upgradeToProFail = createAction('UPGRADE_TO_PRO_FAIL');
export const upgradeToProRequest = createAction('UPGRADE_TO_PRO_REQUEST');
export const upgradeToProSuccess = createAction('UPGRADE_TO_PRO_SUCCESS');

export const getQuizInsightsFail = createAction('GET_QUIZ_INSIGHTS_FAIL');
export const getQuizInsightsRequest = createAction('GET_QUIZ_INSIGHTS_REQUEST');
export const getQuizInsightsSuccess = createAction('GET_QUIZ_INSIGHTS_SUCCESS');

export const setEvidenceUploadView = createAction('SET_EVIDENCE_UPLOAD_VIEW');
