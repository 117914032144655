import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { FUSION_MARKETS_PREFIX, FUSION_MARKETS_X_PREFIX } from '../../constants';
import { getUserLanguage } from '../../modules/auth/selectors';
import config from '../../config';
import { getAvailableRoutes } from '../../modules/menu/selectors';
import * as actions from '../App/actions';
import LocationHandler from '../LocationHandler';
import { flatMap } from 'lodash';
import PrivateRoute from '../../modules/privateRoute/container/PrivateRouteContainer';
import UIComponents from '../../modules/UIComponents';
import NotFound from '../../modules/common/NotFound';
import VerificationModalsManager from 'modules/VerificationModalsManager';
import ReduxNavigation from '../../modules/reduxNavigation';
import Braze from 'components/Braze';

const HubRouter = () => {
  const dispatch = useDispatch();
  const [forceRedirect, setForceRedirect] = useState(false);
  const params = useParams();

  const hub =
    params.hub && [FUSION_MARKETS_X_PREFIX, FUSION_MARKETS_PREFIX].includes(`/${params.hub}`)
      ? `/${params.hub}`
      : '';
  const history = useHistory();
  const location = useLocation();
  const lang = useSelector(getUserLanguage);
  const { pathname } = location;
  const baseName = (config.HUB_PREFIX || '') + hub;
  const availableRoutes = useSelector(getAvailableRoutes);

  useEffect(() => {
    let isForceRedirect = false;
    const userHub = lang;
    if (userHub && userHub !== hub) {
      const newPathName = hub ? pathname.replace(`${hub}/`, `${userHub}/`) : userHub + pathname;
      if (pathname !== newPathName) {
        isForceRedirect = true;
      }
    }
    setForceRedirect(isForceRedirect);
  }, [lang, hub, pathname]);

  useEffect(() => {
    if (forceRedirect) {
      const userHub = lang;
      // Hack to update current path, since there are 2 BrowserRouter with different history instances,
      // that is why current history contains the latest url when hub parameter was changed
      const { pathname, search } = window.location;
      history.replace(
        `${pathname.replace(`${config.HUB_PREFIX}${hub}/`, `${userHub}/`)}${search ?? ''}`,
      );
    }
  }, [forceRedirect, history, hub, lang]);

  useEffect(() => {
    dispatch(actions.updateHub(hub));
  }, [dispatch, hub]);

  useEffect(() => {
    dispatch(actions.updateBasename(baseName));
  }, [dispatch, baseName]);

  /*
   * Please do not use BrowserRouter inside Routes since this component has their own context
   * and push method may work incorrectly
   */
  return !forceRedirect ? (
    <BrowserRouter key={hub} basename={baseName}>
      <ReduxNavigation />
      <LocationHandler />
      <Braze />
      <Switch>
        {flatMap(availableRoutes, ({ isPrivate, path, ...props }, index) => {
          if (Array.isArray(path)) {
            return path.map((individualPath, pathIndex) =>
              isPrivate ? (
                <PrivateRoute
                  key={`${individualPath}-${pathIndex}-${index}`}
                  path={individualPath}
                  {...props}
                />
              ) : (
                <Route
                  key={`${individualPath}-${pathIndex}-${index}`}
                  path={individualPath}
                  {...props}
                />
              ),
            );
          }

          return isPrivate ? (
            <PrivateRoute key={`${path}-${index}`} path={path} {...props} />
          ) : (
            <Route key={`${path}-${index}`} path={path} {...props} />
          );
        })}
        {/* UI Components */}
        {(process.env.REACT_APP_ENV === 'development' ||
          process.env.REACT_APP_ENV === 'development_cy_x' ||
          process.env.REACT_APP_ENV === 'dev_rd' ||
          process.env.REACT_APP_ENV === 'dev_cy_x') && (
          <Route path="/ui-components" component={UIComponents} />
        )}
        <Route path="*" component={NotFound} />
      </Switch>
      <VerificationModalsManager />
    </BrowserRouter>
  ) : null;
};

export default HubRouter;
