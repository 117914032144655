import createAction from 'redux-actions/es/createAction';

export const signinRequest = createAction('SIGNIN_REQUEST');
export const signinSuccess = createAction('SIGNIN_SUCCESS');
export const signinFail = createAction('SIGNIN_FAIL');

export const signinNonfxSuccess = createAction('SIGNIN_NONFX_SUCCESS');
export const validateSigninNonfxSuccess = createAction('VALIDATE_SIGNIN_NONFX_SUCCESS');

export const preInsertLogin = createAction('PRE_INSERT_LOGIN');

export const setDestinationHub = createAction('SET_DESTINATION_HUB');

export const updateToken = createAction('SIGNIN_UPDATE_TOKEN');

export const showCaptchaToken = createAction('SHOW_CAPTCHA_TOKEN');

export const setHub = createAction('SET_HUB');
export const selectHub = createAction('SELECT_HUB');
export const selectAvailableHubs = createAction('SELECT_AVAILABLE_HUBS');

export const validateFingerprintRequest = createAction('VALIDATE_FINGERPRINT_REQUEST');

export const appleSigninRequest = createAction('APPLE_SIGNIN_REQUEST');
export const appleSigninSuccess = createAction('APPLE_SIGNIN_SUCCESS');
export const appleSigninFail = createAction('APPLE_SIGNIN_FAIL');

export const googleSigninRequest = createAction('GOOGLE_SIGNIN_REQUEST');
export const googleSigninSuccess = createAction('GOOGLE_SIGNIN_SUCCESS');
export const googleSigninFail = createAction('GOOGLE_SIGNIN_FAIL');
