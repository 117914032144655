import PropTypes from 'prop-types';
import { check } from '../service';

/**
 * @example
 * <WithPermissions
    role={user.role}
    perform="dashboard-page:visit"
    fallback={() => <h2>User can't do it</h2>}
    >
      <h2>User can do it</h2>
    </WithPermissions>
 */
const WithPermissions = ({ role, perform, children, fallback, data }) => {
  return check(role, perform, data) ? children : fallback();
};

WithPermissions.propTypes = {
  role: PropTypes.string.isRequired,
  perform: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  fallback: PropTypes.func,
};

WithPermissions.defaultProps = {
  fallback: () => null,
};

export default WithPermissions;
