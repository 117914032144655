import { HEADER_ITEM_ID } from 'modules/common/Header/constants';
import { MENU_ITEM_ID } from '../../menu/constants/menuItems';

export const STEPS = [
  { targetId: MENU_ITEM_ID.MY_ACCOUNTS, messageKey: 'accounts.message' },
  { targetId: MENU_ITEM_ID.PAYMENTS, messageKey: 'payments.message' },
  { targetId: MENU_ITEM_ID.NEWS, messageKey: 'news.message' },
  {
    targetId: MENU_ITEM_ID.TRADE_IDEAS,
    messageKeys: [
      'tradeIdeas.message1',
      'tradeIdeas.message2',
      'tradeIdeas.message3',
      'tradeIdeas.message4',
    ],
  },
  { targetId: MENU_ITEM_ID.VPS, messageKey: 'sponsoredVps.message' },
  { targetId: MENU_ITEM_ID.TRADING_CALCULATORS, messageKey: 'tradingCalculators.message' },
  { targetId: MENU_ITEM_ID.ECONOMIC_CALENDAR, messageKey: 'economicCalendar.message' },
  { targetId: MENU_ITEM_ID.TRADING_TOOLS, messageKey: 'tradingTools.message' },
  { targetId: MENU_ITEM_ID.PROMOTIONS, messageKey: 'promotions.message' },
  { targetId: MENU_ITEM_ID.UPGRADES, messageKey: 'upgrades.message' },
  {
    targetId: MENU_ITEM_ID.FUSION_PLUS,
    messageKey: 'fusionPlus.message',
  },
  { targetId: MENU_ITEM_ID.CRYPTO_WAITLIST, messageKey: 'cryptoWaitlist.message' },
  {
    targetId: HEADER_ITEM_ID.NOTIFICATION,
    isMenuItem: false,
    anchorPos: {
      anchor: { vertical: 'bottom', horizontal: 'right' },
      transform: { vertical: 'top', horizontal: 'right' },
    },
    messageKey: 'notifications.message',
  },
  {
    targetId: HEADER_ITEM_ID.PROFILE,
    isMenuItem: false,
    anchorPos: {
      anchor: { vertical: 'bottom', horizontal: 'right' },
      transform: { vertical: 'top', horizontal: 'right' },
    },
    messageKey: 'profile.message',
  },
];
