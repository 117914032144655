import { VerificationStatuses as VerificationStatus } from '../../../constants/verificationStatuses';
import { DEPOSIT_DEFAULT_STATUS, Status as PaymentStatus } from '../../../constants/paymentStatus';
import { InvitationStatuses } from '../../reduceFees/constants';
import { TicketStatuses } from '../../../constants/ticketStatuses';
import { Status as vpsConstants } from '../../userManagement/pages/VPSUsers/constants';
import {
  MAILCHIMP_USER_STATUS,
  UNABLE_PAYPAL_REFUND_REQUEST_STATUS,
  USER_DETAILS_BLOCKED_STATUS,
} from '../../userManagement/pages/UserDetails/constants';
import { SUMSUB_STATUSES } from 'constants/sumsubStatuses';
import { QUIZ_ANSWER_STATUSES } from 'constants/quizAnswerStatuses';
import { TV_SUBSCRIPTION_STATUS } from 'modules/tradingView/constants';

import { REFERRAL_STATUS } from 'modules/referFriend/constants';
export const STATUS_LABEL = {
  posted: 'posted',
  approved: 'approved',
  rejected: 'rejected',
  funded: 'funded',
  active: 'active',
  dormant: 'dormant',
  sumsub: 'pending',
};

export const STATUS_GROUP_NAME = {
  APPROVED: 'approved',
  PARTIAL_APPROVED: 'partial_approved',
  PENDING: 'pending',
  DECLINED: 'declined',
  OPENED: 'opened',
  UNSELECTED: 'unselected',
};

export const STATUS_GROUP = {
  [STATUS_GROUP_NAME.APPROVED]: [
    VerificationStatus.APPROVED,
    PaymentStatus.APPROVED,
    PaymentStatus.TRUSTED,
    VerificationStatus.FUNDED,
    VerificationStatus.ACTIVE,
    InvitationStatuses.ACCEPTED,
    TicketStatuses.RESOLVED,
    DEPOSIT_DEFAULT_STATUS,
    SUMSUB_STATUSES.GREEN,
    QUIZ_ANSWER_STATUSES.CORRECT,
    MAILCHIMP_USER_STATUS.SUBSCRIBED,
    TV_SUBSCRIPTION_STATUS.APPROVED,
    UNABLE_PAYPAL_REFUND_REQUEST_STATUS.APPROVED,
  ],
  [STATUS_GROUP_NAME.PENDING]: [
    VerificationStatus.NEW,
    ...Object.values(VerificationStatus.EDITING),
    VerificationStatus.POSTED,
    VerificationStatus.AUTOMATIC_APPROVEMENT,
    PaymentStatus.IN_PROCESS,
    PaymentStatus.PENDING,
    PaymentStatus.TOCONFIRM,
    MAILCHIMP_USER_STATUS.PENDING,
    UNABLE_PAYPAL_REFUND_REQUEST_STATUS.PENDING,
  ],
  [STATUS_GROUP_NAME.DECLINED]: [
    VerificationStatus.REJECTED,
    PaymentStatus.DECLINED,
    PaymentStatus.EXPIRED,
    VerificationStatus.DORMANT,
    'Failed',
    vpsConstants.CANCELLED,
    TicketStatuses.CLOSED,
    USER_DETAILS_BLOCKED_STATUS,
    SUMSUB_STATUSES.RED,
    QUIZ_ANSWER_STATUSES.INCORRECT,
    MAILCHIMP_USER_STATUS.UNSUBSCRIBED,
    MAILCHIMP_USER_STATUS.CLEANED,
    MAILCHIMP_USER_STATUS.ARCHIVED,
    TV_SUBSCRIPTION_STATUS.CANCELLED,
    REFERRAL_STATUS.VERIFYING,
    UNABLE_PAYPAL_REFUND_REQUEST_STATUS.DECLINED,
  ],
  [STATUS_GROUP_NAME.OPENED]: [TicketStatuses.OPEN],
  [STATUS_GROUP_NAME.UNSELECTED]: [REFERRAL_STATUS.NOT_TRADED],
  [STATUS_GROUP_NAME.PARTIAL_APPROVED]: [UNABLE_PAYPAL_REFUND_REQUEST_STATUS.PARTIAL_APPROVED],
};
