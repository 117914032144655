import palette from './palette';
import { fontFamily, fontSize, fontWeight } from '../font';
import { colors } from './colors';

import { typographyFonts } from '../typographyFonts';
import images from './images';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});
const spacing = 8;

//variables that are used inside mui overrides or in TP-UI component styles
const common = {
  textError: {
    root: {
      color: palette.error.main,
      fontSize: fontSize._12,
      display: 'flex',
      padding: '2px 0',
      minHeight: '1.7em',
    },
    mediumSize: {
      fontSize: fontSize._16,
      minHeight: '2em',
    },
  },
  textWarning: {
    root: {
      color: palette.warning.main,
      fontSize: fontSize._12,
      display: 'flex',
      alignItems: 'center',
      padding: '2px 0',
      minHeight: '1.6em',
    },
    mediumSize: {
      fontSize: fontSize._14,
      minHeight: '2em',
    },
  },
  form: {
    root: {
      position: 'relative',
      width: '100%',
    },
  },
  formControl: {
    root: {
      borderColor: colors.gray.lighter,
      borderRadius: 10,
      borderWidth: '1px',
      paddingTop: 14,
      paddingBottom: 15,
      fontSize: fontSize._16,
      lineHeight: '1.2em',
    },
    hover: {
      borderColor: colors.gray.dark,
    },
    focus: {
      backgroundColor: 'none',
      borderColor: colors.gray.darker,
      borderWidth: '1px',
    },
    smallSize: {
      paddingTop: 3,
      paddingBottom: 4,
      fontSize: fontSize._14,
      height: '1.786em',
    },
    placeholder: {
      color: palette.text.secondary,
      fontSize: 'inherit',
      fontFamily: 'inherit',
      opacity: 1,
    },
    groupLabel: {
      lineHeight: 1.2,
      background: palette.background.main,
    },
  },
  checkbox: {
    rootLabel: {
      fontSize: fontSize._16,
      lineHeight: 1.5,
    },
    label: {
      color: palette.text.primary,
      ...typographyFonts.body1,
    },
    disabled: {
      opacity: 0.5,
    },
    errorIcon: {
      color: palette.error.main,
    },
    smallSize: {
      fontSize: fontSize._14,
      lineHeight: 1.2,
    },
    mediumSize: {
      fontSize: fontSize._16,
      lineHeight: 1.5,
    },
  },
  iconButton: {
    root: {
      text: {
        color: palette.text.primary,
        fontWeight: fontWeight.bold,
        lineHeight: 1.5,
      },
      xsmallSize: {
        fontSize: fontSize._12,
      },
      smallSize: {
        fontSize: fontSize._14,
      },
      mediumSize: {
        fontSize: fontSize._16,
      },
      largeSize: {
        fontSize: fontSize._24,
      },
    },
    primaryColor: {
      root: {
        background: palette.primary.lightest,
        color: palette.primary.text,
      },
      hover: {
        background: palette.primary.lighter,
      },
      active: {
        background: palette.primary.light,
      },
      disabled: {
        background: palette.primary.lightest,
        color: palette.primary.text,
        opacity: '0.5',
      },
    },
  },
  link: {
    root: {
      textDecoration: 'underline',
      fontWeight: fontWeight.regular,
      fontSize: 'inherit',
      lineHeight: 'normal',
      color: palette.primary.main,
    },
    hover: {
      color: palette.primary.dark,
    },
    active: {
      color: palette.primary.darker,
    },
    smallSize: {
      fontSize: fontSize._12,
    },
    mediumSize: {
      fontSize: fontSize._14,
    },
    largeSize: {
      fontSize: fontSize._16,
    },
  },
  progressBar: {
    circle: {
      root: {
        lineHeight: 0,
      },
      track: {
        color: palette.primary.lightest,
      },
      bar: {
        secondary: {
          color: palette.primary.lighter,
        },
      },
    },
    line: {
      root: {
        borderRadius: '10px',
      },
      track: {
        backgroundColor: palette.primary.lightest,
      },
      bar: {
        secondary: {
          backgroundColor: palette.primary.lighter,
        },
        error: {
          backgroundColor: palette.error.main,
        },
        warning: {
          backgroundColor: palette.warning.main,
        },
        success: {
          backgroundColor: palette.success.main,
        },
      },
    },
  },
  modal: {
    root: {
      backgroundColor: palette.background.main,
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.04)',
      outline: 'none',
      borderRadius: 12,
    },
  },
  datePicker: {
    root: {
      border: 'none',
      boxShadow:
        '0px 1px 18px rgba(0, 0, 0, 0.12), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.2)',
    },
    calendar: {
      header: {
        root: {
          backgroundColor: palette.background.main,
          border: 'none',
        },
        currentMonth: {
          fontFamily: fontFamily,
          fontWeight: fontWeight.regular,
          fontSize: fontSize._16,
          lineHeight: 1.4,
          color: palette.text.primary,
        },
        navigation: {
          button: {
            position: 'absolute',
            height: '24px',
            width: '24px',
            top: 16,
            border: 'none',
          },
          icon: {
            position: 'absolute',
            height: '8px',
            width: '8px',
            top: 8,
            borderColor: colors.gray.darker,
            borderStyle: 'solid',
            borderWidth: '2px 2px 0px 0px',
          },
        },
      },
      body: {
        week: {
          day: {
            width: '36px',
            lineHeight: '36px',
            fontFamily: fontFamily,
            fontWeight: fontWeight.regular,
            fontSize: fontSize._12,
            color: palette.text.secondary,
          },
        },
        month: {
          day: {
            root: {
              width: '36px',
              height: '36px',
              lineHeight: 'normal',
              fontFamily: fontFamily,
              fontWeight: fontWeight.regular,
              fontSize: fontSize._14,
              color: palette.text.primary,
              borderRadius: '50%',
            },
            today: {
              boxSizing: 'border-box',
              border: `2px solid ${palette.primary.lighter}`,
            },
            hover: {
              background: palette.primary.lightest,
            },
            selected: {
              color: palette.background.main,
              background: palette.primary.gradient.main,
            },
            outsideMonth: {
              color: palette.text.secondary,
            },
            disabled: {
              color: palette.text.secondary,
            },
          },
        },
      },
    },
  },
  popover: {
    root: {
      padding: '16px',
      fontSize: fontSize._14,
      fontWeight: fontWeight.regular,
      lineHeight: 1.2,
      color: palette.text.primary,
      borderRadius: '10px',
    },
  },
  scrollToTop: {
    button: {
      root: {
        fontWeight: fontWeight.bold,
        borderRadius: 0,
        backgroundColor: palette.primary.lightest,
        color: palette.primary.text,
      },
      hover: {
        backgroundColor: palette.primary.lighter,
      },
      active: {
        background: palette.primary.light,
      },
    },
  },
  thirdPartyButton: {
    root: {
      border: `1px solid ${palette.primary.main}`,
      borderRadius: 10,
      color: palette.primary.main,
      fontSize: fontSize._16,
      fontFamily: fontFamily,
      overflow: 'hidden',
    },
    hover: {
      borderColor: palette.primary.light,
      background: `-webkit-${palette.primary.gradient.light}`,
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
      color: palette.primary.gradient.light,
    },
    disabled: {
      cursor: 'default',
      'pointer-events': 'none',
      opacity: 0.5,
    },
  },
  authPage: {
    root: {
      background: palette.background.main,
    },
    logo: {
      marginTop: spacing,
      marginBottom: spacing * 6,
      [breakpoints.down('xs')]: {
        marginBottom: spacing * 3,
      },
    },
    formWrapper: {
      maxWidth: 780,
      background: palette.background.main,
      boxShadow: '0px 10px 32px 10px rgba(7, 5, 53, 0.1)',
      borderRadius: '8px',
    },
    formDescription: {
      backgroundImage: `url('${images.auth.authBG2Img}'), url('${images.auth.authBG1Img}')`,
      backgroundSize: 'auto, cover',
      backgroundRepeat: 'no-repeat, no-repeat',
      backgroundPosition: 'center center, top left',
      justifyContent: 'flex-end',
      gap: '8px',
      padding: 4 * spacing,
    },
    form: {
      backgroundColor: palette.background.main,
    },
  },
  alert: {
    root: {
      borderRadius: '12px',
    },
    success: {
      backgroundColor: palette.success.main,
      color: palette.success.contrastText,
    },
    error: {
      backgroundColor: palette.error.main,
      color: palette.error.contrastText,
    },
    warning: {
      backgroundColor: palette.warning.main,
      color: palette.warning.contrastText,
    },
    info: {
      backgroundColor: palette.info.main,
      color: palette.info.contrastText,
    },
    icon: {
      fontSize: fontSize._20,
    },
  },
  snackbar: {
    wrapper: {
      borderRadius: '13px',
      boxShadow:
        '0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)',
    },
  },
  table: {
    contentContainer: {
      border: `1px solid ${palette.primary.lightest}`,
      borderRadius: 10,
    },
    columnTitle: {
      color: palette.text.secondary,
      fontSize: fontSize._12,
    },
    cell: {
      padding: 16,
      minWidth: 50,
      borderBottom: `1px solid ${palette.primary.lightest}`,
    },
    contentLoading: {
      opacity: 0.7,
    },
    pagination: {
      marginTop: 16,
    },
    summary: {
      background: palette.primary.lightest,
      padding: 16,
    },
  },
  trending: {
    up: {
      backgroundColor: palette.success.lightest,
      color: palette.success.main,
    },
    down: {
      backgroundColor: palette.error.lightest,
      color: palette.error.main,
    },
    flat: {
      backgroundColor: palette.neutral.lightest,
      color: palette.neutral.main,
    },
    smallSize: {
      fontSize: fontSize._10,
      padding: 3,
    },
    mediumSize: {
      fontSize: fontSize._14,
      padding: 4,
    },
    largeSize: {
      fontSize: fontSize._16,
      padding: 8,
    },
    extraLargeSize: {
      fontSize: fontSize._20,
      padding: 10,
    },
  },
  numberDifference: {
    up: {
      color: palette.success.main,
    },
    down: {
      color: palette.error.main,
    },
    flat: {
      color: palette.neutral.main,
    },
    smallSize: {
      ...typographyFonts.body3,
      padding: 3,
    },
    mediumSize: {
      ...typographyFonts.body2,
      padding: 4,
    },
    largeSize: {
      ...typographyFonts.body1,
      padding: 8,
    },
  },
  navMenuList: {
    item: {
      content: {
        minHeight: '56px',
        padding: '16px 20px',
        textDecoration: 'none',
      },
      icon: {
        minWidth: 36,
        color: palette.text.primary,
      },
      selected: {
        text: {
          fontWeight: fontWeight.bold,
        },
      },
      label: {
        fontSize: fontSize._12,
        border: '1px solid',
        borderColor: palette.primary.main,
        color: palette.primary.main,
        padding: '3px 10px',
        borderRadius: '6px',
        marginLeft: '10px',
      },
    },
    accordion: {
      nestedItem: {
        root: {
          paddingLeft: '56px',
        },
        selected: {
          backgroundColor: palette.primary.lighter,
        },
      },
      selected: {
        root: {
          backgroundColor: palette.primary.lightest,
        },
        nestedItem: {
          hover: {
            backgroundColor: palette.primary.lighter,
          },
        },
      },
    },
  },
  sidebar: {
    root: {
      borderRight: `1px solid ${palette.neutral.lighter}`,
      transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      background: palette.background.main,
    },
    navigation: {
      background: 'inherit',
      width: 'inherit',
    },
    verification: {
      color: palette.primary.text,
      borderTop: `1px solid ${palette.neutral.lighter}`,
      background: 'inherit',
      width: 'inherit',
    },
  },
  menu: {
    header: {
      lineHeight: 1.2,
      background: palette.background.main,
    },
    item: {
      linkContainer: {
        textDecoration: 'none',
      },
      icon: {
        minWidth: 24,
        color: palette.text.primary,
      },
      text: {
        ...typographyFonts.body2,
        whiteSpace: 'normal',
        lineHeight: 1.2,
        color: palette.text.primary,
      },
    },
  },
  pageHeader: {
    root: {
      background: palette.background.main,
      borderBottom: `1px solid ${palette.neutral.lighter}`,
    },
  },
  card: {
    clickable: {
      cursor: 'pointer',
      position: 'relative',
      '&:hover': {
        borderColor: palette.primary.lighter,
      },
    },
    selected: {
      borderColor: palette.primary.lighter,
      position: 'relative',
      '&::after': {
        content: '""',
        display: 'block',
        width: 7,
        backgroundColor: palette.primary.lighter,
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        borderRadius: '0 10px 10px 0',
      },
    },
    divider: {
      borderTop: `1px solid ${palette.primary.lightest}`,
    },
  },
  notifications: {
    item: {
      notLastChild: {
        borderBottom: `1px solid ${palette.primary.lightest}`,
      },
      image: {
        borderRadius: '10px',
      },
    },
  },
  imagePlaceholder: {
    backgroundColor: palette.primary.lightest,
    color: palette.primary.light,
    fontSize: fontSize._140,
    borderRadius: '10px',
  },
  bottomNavigation: {
    root: {
      backgroundColor: palette.background.main,
      borderTop: `1px solid ${palette.neutral.lighter}`,
    },
  },
  fileUploader: {
    root: {
      backgroundColor: palette.primary.lightest,
      borderStyle: 'dashed',
      borderWidth: 1,
      borderColor: palette.primary.light,
      borderRadius: 10,
    },
    fileAccepted: {
      borderColor: palette.primary.main,
    },
    fileRejected: {
      borderColor: palette.error.main,
      backgroundColor: palette.error.lightest,
    },
  },
  fileListItem: {
    root: {
      width: 120,
      height: 120,
    },
    fileContainer: {
      borderRadius: 10,
      border: `1px solid ${palette.primary.lightest}`,
    },
    fileContainerError: {
      border: `1px solid ${palette.error.main}`,
    },
    nonImageFileContainer: {
      backgroundColor: palette.primary.lightest,
    },
  },
  currency: {
    icon: {
      border: `1px solid ${palette.primary.lightest}`,
    },
  },
  stepper: {
    headerWrapper: {
      borderRadius: 10,
      border: `1px solid ${palette.primary.lightest}`,
    },
    stepHeader: {
      root: {
        borderRadius: 10,
        backgroundColor: 'transparent',
      },
      passed: {
        backgroundColor: palette.primary.lightest,
      },
    },
    stepperBase: {
      backgroundColor: 'transparent',
    },
  },
  sentimentPalette: {
    color1: {
      backgroundColor: palette.error.main,
    },
    color2: {
      backgroundColor: palette.error.light,
    },
    color3: {
      backgroundColor: palette.text.primary,
    },
    color4: {
      backgroundColor: palette.success.light,
    },
    color5: {
      backgroundColor: palette.success.main,
    },
    color6: {
      backgroundColor: palette.text.primary,
    },
  },
  accordion: {
    root: {
      borderRadius: 10,
    },
  },
  tabBadge: {
    counter: {
      backgroundColor: palette.success.main,
      color: palette.success.contrastText,
      borderRadius: '50%',
      padding: '5px 0',
      textAlign: 'center',
      fontSize: '0.585em',
      width: '2.2em',
      height: '2.2em',
      lineHeight: '1.215em',
    },
    badge: {
      backgroundColor: palette.primary.main,
      color: palette.primary.lightest,
      fontSize: fontSize._12,
      padding: '3px 10px',
      borderRadius: '6px',
      marginLeft: '10px',
    },
  },
  descriptionList: {
    summary: {
      backgroundColor: palette.primary.lightest,
      borderRadius: 10,
      padding: '12px 16px',
    },
  },
  cookieBanner: {
    root: {
      backgroundColor: palette.neutral.darkest,
      zIndex: 1600,
      color: palette.primary.contrastText,
      opacity: 0.8,
    },
    content: {
      minHeight: '20px',
      maxHeight: '150px',
      overflow: 'auto',
      letterSpacing: 0,
    },
    link: {
      color: 'inherit',
      textDecoration: 'none',
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderColor: 'inherit',
      '&:hover': {
        color: 'inherit',
      },
    },
    button: {
      color: 'inherit',
    },
  },
};

export default common;
