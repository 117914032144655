import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createPendingSelector } from '../../../../api/selectors';
import { hasOnlyEmptyValues } from 'utils/hasOnlyEmptyValues';
import { clearEmptyValues } from 'utils/clearEmptyValues';
import { required } from 'utils/validation/fieldValidationRules';
import TPSearchTextField from 'components/TP-UI/TPSearchTextField';
import TPDatePicker from 'components/TP-UI/TPDatePicker';
import TPLoader from 'components/TP-UI/TPLoader';
import {
  DEFAULT_HISTORY_PERIOD_DAYS,
  DEFAULT_PAGINATION,
  INITIAL_FILTERS_VALUES,
  WITHDRAW_SEARCH_FORM,
} from '../constants';
import { getHistory, getHistoryTotal } from '../selectors';
import { cleanupWithdrawalHistory, searchWithdrawHistoryRequest } from '../actions';
import WithdrawalHistoryTable from 'modules/payments/components/WithdrawalHistoryTable';
import PagePlaceholder from 'modules/common/PagePlaceholder';
import fmDate from 'utils/fmDate';
import { useTheme } from '@material-ui/core';

const WithdrawHistoryLayoutContainer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { images } = useTheme();

  const withdrawals = useSelector(getHistory);
  const totalNumber = useSelector(getHistoryTotal);
  const [filtered, setFiltered] = useState(false);
  const loading = useSelector(createPendingSelector(searchWithdrawHistoryRequest));

  const filtersDefault = useMemo(() => {
    const defaultDateFrom = fmDate()
      .endOf('day')
      .subtract(DEFAULT_HISTORY_PERIOD_DAYS, 'day');
    const defaultDateTo = fmDate();

    return {
      ...INITIAL_FILTERS_VALUES,
      dateFrom: defaultDateFrom.toISOString(),
      dateTo: defaultDateTo.toISOString(),
    };
  }, []);

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  const filters = useMemo(
    () => ({
      form: WITHDRAW_SEARCH_FORM,
      fields: [
        {
          name: 'search',
          component: TPSearchTextField,
          placeholder: t('placeholders.search'),
          width: '250px',
        },
        {
          name: 'dateFrom',
          placeholder: t('placeholders.from'),
          component: TPDatePicker,
          clearable: false,
          required: true,
          validate: [required],
          width: '155px',
          returnsStartDay: true,
        },
        {
          name: 'dateTo',
          placeholder: t('placeholders.to'),
          component: TPDatePicker,
          clearable: false,
          required: true,
          validate: [required],
          width: '155px',
        },
      ],
      initialValues: filtersDefault,
    }),
    [t, filtersDefault],
  );

  useEffect(() => {
    if (totalNumber !== undefined) {
      setPagination((pagination) => ({ ...pagination, total: totalNumber }));
    }
  }, [totalNumber]);

  useEffect(() => {
    dispatch(
      searchWithdrawHistoryRequest({
        skip: DEFAULT_PAGINATION.currentPage * DEFAULT_PAGINATION.pageSize,
        page: DEFAULT_PAGINATION.currentPage,
        limit: DEFAULT_PAGINATION.pageSize,
        ...clearEmptyValues(filtersDefault),
      }),
    );

    return () => {
      dispatch(cleanupWithdrawalHistory());
    };
  }, [dispatch, filtersDefault]);

  const handleChange = useCallback(
    ({ page, pageSize, ...filters }) => {
      setPagination((pagination) => ({ ...pagination, currentPage: page, pageSize }));
      setFiltered(!hasOnlyEmptyValues(filters));

      dispatch(
        searchWithdrawHistoryRequest({
          skip: page * pageSize,
          limit: pageSize,
          ...clearEmptyValues(filters),
        }),
      );
    },
    [dispatch],
  );

  return totalNumber === undefined ? (
    <TPLoader />
  ) : totalNumber === 0 && !filtered && !loading ? (
    <PagePlaceholder
      image={images.common.waitingImg}
      imageAlt={t('payments:withdraw2.messages.noWithdrawalsYet')}
      message={t('payments:withdraw2.messages.noWithdrawalsYet')}
    />
  ) : (
    <WithdrawalHistoryTable
      pagination={pagination}
      items={withdrawals}
      totalNumber={totalNumber}
      filtered={filtered}
      loading={loading}
      filters={filters}
      onChange={handleChange}
    />
  );
};

export default WithdrawHistoryLayoutContainer;
