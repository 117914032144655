import { createAction } from 'redux-actions';

export const getClientsRequest = createAction('GET_CLIENTS_REQUEST');
export const getClientsSuccess = createAction('GET_CLIENTS_SUCCESS');
export const getClientsFail = createAction('GET_CLIENTS_FAIL');

export const getFullClientsRequest = createAction('GET_FULL_CLIENTS_REQUEST');
export const getFullClientsSuccess = createAction('GET_FULL_CLIENTS_SUCCESS');
export const getFullClientsFail = createAction('GET_FULL_CLIENTS_FAIL');

export const updateFullClientsTableSettings = createAction('UPDATE_FULL_CLIENTS_TABLE_SETTINGS');
