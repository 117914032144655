import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    popper: {
      //zIndex: (props) => (props.hoverTrigger ? theme.zIndex.modal + 1 : theme.zIndex.modal),
      zIndex: theme.zIndex.modal + 1,
      textAlign: 'left',
    },
    root: {
      ...theme.popover.root,
      wordBreak: 'break-word',
    },
    container: {
      cursor: 'pointer',
      display: 'inline-block',
    },
    fullWidth: {
      width: '100%',
    },
    disabled: {
      cursor: 'default',
    },
  }),
  { name: 'TPPopover' },
);
