import React from 'react';
import PropTypes from 'prop-types';
import TPTextField from 'components/TP-UI/TPTextField';
import TPCurrency from 'components/TP-UI/TPCurrency';
import { CURRENCY } from '../../../constants';
import MinMaxAmountHint from 'modules/payments/pages/Withdraw2/components/MinMaxAmountHint';

export const AmountTextField = ({
  currency = null,
  min,
  minLabel,
  max,
  maxLabel,
  hint,
  type,
  ...props
}) => {
  const defaultCurrency = currency
    ? { value: currency.toLowerCase(), label: currency.toUpperCase() }
    : {};
  const currencyInfo = CURRENCY[currency?.toUpperCase()] || defaultCurrency;
  const minMaxHint =
    min || max ? (
      <MinMaxAmountHint
        min={min}
        minLabel={minLabel}
        max={max}
        maxLabel={maxLabel}
        currency={currencyInfo.value}
      />
    ) : null;

  return (
    <TPTextField
      {...props}
      type={type ?? 'number'}
      hint={hint || minMaxHint}
      endAdornment={
        <>
          {props.endAdornment}
          <TPCurrency {...currencyInfo} />
        </>
      }
    />
  );
};

AmountTextField.propTypes = {
  currency: PropTypes.string,
  min: PropTypes.number,
  minLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  max: PropTypes.number,
  maxLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  hint: PropTypes.node,
  ...TPTextField.propTypes,
};

export default AmountTextField;
