import omit from 'lodash/omit';
import hideMessage from 'modules/common/HideMessages/reducers';
import AML from 'modules/aml/reducers/index';
import { persistentReducer as hideCompleteAML } from 'modules/aml/reducers/index';
import i18n from 'modules/common/LanguageToggle/reducers';
import pagination from 'modules/common/PaginationBar/reducers';
import TMD from 'modules/common/TMD/reducers';
import reduceFees from 'modules/reduceFees/reducers';
import tradingCalculators from 'modules/tradingCalculators/reducers';
import tradingCentralEmbed from 'modules/tradingCentralEmbed/reducers';
import { reducer as formReducer } from 'redux-form';
import { createTransform, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import security from './modules/./security/reducers';
import accounts from './modules/accounts/reducers';
import analystData from './modules/analystViews/reducers';
import { apiCalls } from './modules/api/reducers';
import auth from './modules/auth/reducers';
import selectHub from './modules/auth/reducers/selectHub';
import selectAvailableHubs from './modules/auth/reducers/selectAvailableHubs';
import modal from './modules/common/CustomModal/reducers';
import contactUs from './modules/contactUs/reducers';
import discover from './modules/discover/reducers';
import emailManagement from './modules/emailManagement/reducers';
import geolocation from './modules/geolocation/reducers';
import migrateMyAccount from './modules/migrateMyAccount/reducers';
import notifications from './modules/notifications/reducers';
import paymentManagement from './modules/paymentManagement/reducers';
import payments from './modules/payments/reducers';
import profile from './modules/profile/reducers';
import user from './modules/user/reducers';
import userManagement from './modules/userManagement/reducers';
import validationRefCodes from './modules/validationRefCodes/reducers';
import verificationManagement from './modules/verificationManagement/reducers';
import myFeedDetails from './modules/discover/pages/MyFeedDetails/reducers';
import newsSearch from './modules/discover/pages/Search/reducers';
import pageWarning from './modules/pageWarning/reducers';
import customFields from './modules/customFields/reducers';
import userNotifications from './modules/./userNotifications/reducers';
import dashboard from './modules/dashboard/reducers';
import migrationApproveRequests from './modules/migrationRequests/reducers';
import operations from './modules/operations/reducers';
import myFollowers from './modules/myFollowers/reducers';
import myPerformance from './modules/myPerformance/reducers';
import lpoa from './modules/lpoa/reducers';
import riskGroups from './modules/riskGroup/reducers';
import mt4LiveCountries from './modules/mt4LiveCountries/reducers';
import paymentQueue from './modules/paymentQueue/reducers';
import imMessages from './modules/userManagement/pages/Messages/reducers';
import migrateToIB from './modules/operations/pages/MigrateFromCellxpertToIbPortal/reducers';
import day1x from './modules/day1xSubscribtion/reducers';
import day1Transfer from './modules/day1Exchange/reducers';
import profileQuizReducer from './modules/profileQuiz/reducers';
import vps from 'modules/vps/reducers';
import basenameReducer from 'components/App/reducer';
import evidenceStatementReducer from './modules/evidenceUploadPortal/reducers';
import upgradeToPro from './modules/upgradeToPro/reducers';
import tradingView from 'modules/tradingView/reducers';
import referFriend from './modules/referFriend/reducers';
import presetFilters from './modules/common/PresetFilters/reducers';
import verificationChecklists from './modules/common/VerificationChecklist/reducers';
import reduxNavigationReducer from './modules/reduxNavigation/reducers';
// CODE_GETERATOR_NEW_REDUCER_IMPORT_PLACEHOLDER

const authTransform = createTransform(
  (state) => state,
  (state) => omit(omit(state, 'isFirstVisit'), 'signIn.showCaptcha'),
  { whitelist: ['auth'] },
);

const rootPersistConfig = {
  key: 'authData',
  storage: storage,
  whitelist: ['auth', 'hideMessage', 'hideCompleteAML'],
  transforms: [authTransform],
};

const rootReducer = () =>
  persistCombineReducers(rootPersistConfig, {
    form: formReducer,
    apiCalls,
    quiz: profileQuizReducer,
    auth,
    selectHub,
    selectAvailableHubs,
    notifications,
    accounts,
    profile,
    payments,
    modal,
    user,
    userManagement,
    paymentManagement,
    validationRefCodes,
    verificationManagement,
    contactUs,
    geolocation,
    emailManagement,
    pagination,
    TMD,
    tradingCentralEmbed,
    tradingCalculators,
    i18n,
    reduceFees,
    hideMessage,
    AML,
    hideCompleteAML,
    migrateMyAccount,
    analystData,
    discover,
    security,
    myFeedDetails,
    newsSearch,
    pageWarning,
    userNotifications,
    dashboard,
    migrationApproveRequests,
    operations,
    myFollowers,
    myPerformance,
    lpoa,
    riskGroups,
    mt4LiveCountries,
    paymentQueue,
    customFields,
    imMessages,
    day1x,
    migrateToIB,
    vps,
    app: basenameReducer,
    evidenceStatement: evidenceStatementReducer,
    upgradeToPro,
    tradingView,
    day1Transfer,
    referFriend,
    presetFilters,
    verificationChecklists,
    navigation: reduxNavigationReducer,
    // CODE_GETERATOR_NEW_REDUCER_PLACEHOLDER
  });

export default rootReducer;
