import { handleActions } from 'redux-actions';
import * as actions from '../actions';

const defaultState = {
  success: [],
  errors: [],
  isCompleted: false,
};

export default handleActions(
  {
    [actions.uploadAwtFileRequest]: () => {
      return { ...defaultState };
    },
    [actions.uploadAwtFileSuccess]: (state, action) => {
      return { ...state, ...action.response.data, isCompleted: true };
    },
  },
  defaultState,
);
