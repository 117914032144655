import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Page from 'modules/common/Page';
import MainLayout from 'modules/common/MainLayout';
import NotFoundContent from 'modules/common/NotFound/components/NotFoundContent';

const NotFoundView = ({ userAuthenticated, onRedirectToHome, buttonLabel }) => {
  const { t } = useTranslation('notFoundPage');

  if (userAuthenticated) {
    return (
      <Page docTitle={t('title')}>
        <MainLayout title={t('title')}>
          <NotFoundContent onRedirectToHome={onRedirectToHome} buttonLabel={buttonLabel} />
        </MainLayout>
      </Page>
    );
  } else {
    return <NotFoundContent onRedirectToHome={onRedirectToHome} buttonLabel={buttonLabel} />;
  }
};

NotFoundView.propTypes = {
  userAuthenticated: PropTypes.bool.isRequired,
  onRedirectToHome: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
};

export default NotFoundView;
