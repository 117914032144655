export const REDUCE_FEES_FORBIDDEN_COUNTRIES = ['India'];
export const INVITE_FRIEND_FORM = 'INVITE_FRIEND_FORM';

export const BONUS_TYPES = {
  ONE_OFF_BONUS: 'oneOffBonus',
  REDUCED_RATE: 'reducedRate',
};

export const InvitationStatuses = {
  PENDING: 'Pending',
  ACCEPTED: 'Accepted',
};
