import { handleActions } from 'redux-actions';

import {
  getOperationNotificationsSuccess,
  getOperationNotificationsFail,
  cleanupOperationNotifications,
} from '../actions';

const defaultState = {
  items: [],
  requestPayload: null,
};

export default handleActions(
  {
    [getOperationNotificationsSuccess](state, action) {
      return !action.payload.persistStoreData
        ? {
            ...state,
            items: action.response.data.notifications,
            totalNumber: action.response.data.totalNumber,
            requestPayload: action.payload,
          }
        : state;
    },
    [getOperationNotificationsFail](state) {
      return state.totalNumber !== undefined
        ? state
        : {
            ...defaultState,
            totalNumber: 0,
          };
    },
    [cleanupOperationNotifications]() {
      return defaultState;
    },
  },
  defaultState,
);
