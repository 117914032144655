import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const TPTabBadge = ({ value, type = 'counter' }) => {
  const classes = useStyles();
  const badgeClass = classes[type];

  const displayedValue = type === 'counter' ? (value > 99 ? '99+' : value) : value;

  return <span className={badgeClass}>{displayedValue}</span>;
};

TPTabBadge.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.oneOf(['counter', 'badge']),
};

export default TPTabBadge;
