import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { required } from 'utils/validation/fieldValidationRules';
import React, { useCallback, useMemo } from 'react';
import TPModal from 'components/TP-UI/TPModal';
import { closeModal } from 'components/TP-UI/TPModal/actions';
import { useDispatch } from 'react-redux';
import TPGrid from 'components/TP-UI/TPGrid';
import FormError from 'modules/common/FormError';
import TPTextField from 'components/TP-UI/TPTextField';
import {
  CONFIRMATION_PASSWORD_VALIDATORS,
  PASSWORD_VALIDATORS,
} from 'utils/validation/fieldsValidators';
import { REDUX_FORM_DEFAULT_CONFIG } from 'constants/form';

const CreatePasswordModal = ({ modalName, form, error, submitFailed, reset, handleSubmit }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('auth');
  const handleCancelClick = useCallback(() => {
    reset(form);
    dispatch(closeModal(modalName));
  }, [dispatch, modalName, reset, form]);
  const buttons = useMemo(
    () => [
      {
        label: t('signUp.createPasswordModal.saveButton'),
        type: 'submit',
        primary: true,
        onClick: handleSubmit,
      },
      {
        label: t('common:buttons.cancel'),
        secondary: true,
        onClick: handleCancelClick,
      },
    ],
    [t, handleSubmit, handleCancelClick],
  );
  return (
    <TPModal
      form={form}
      modalName={modalName}
      onClose={handleCancelClick}
      onSubmit={handleSubmit}
      title={t('signUp.createPasswordModal.title')}
      buttons={buttons}>
      <TPGrid container spacing={3} direction="column">
        {error && submitFailed ? (
          <TPGrid item xs={12}>
            <FormError error={error} />
          </TPGrid>
        ) : null}
        <TPGrid item container spacing={1} direction="column">
          <TPGrid item xs={12}>
            <Field
              name="password"
              label={t('common:labels.createPassword')}
              placeholder={t('common:labels.createPassword')}
              confidential
              fullWidth
              autocomplete="new-password"
              component={TPTextField}
              required
              hint={t('tipText')}
              validate={[required, ...PASSWORD_VALIDATORS]}
            />
          </TPGrid>
          <TPGrid item xs={12}>
            <Field
              name="confirmPassword"
              label={t('common:labels.confirmPassword')}
              placeholder={t('common:labels.confirmPassword')}
              confidential
              fullWidth
              autocomplete="new-password"
              component={TPTextField}
              required
              validate={[required, ...CONFIRMATION_PASSWORD_VALIDATORS]}
            />
          </TPGrid>
        </TPGrid>
      </TPGrid>
    </TPModal>
  );
};

export default reduxForm({ ...REDUX_FORM_DEFAULT_CONFIG, enableReinitialize: true })(
  CreatePasswordModal,
);
