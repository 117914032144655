export const colors = {
  blue: {
    darkest: '#04001E',
    darker: '#040444',
    dark: '#050068',
    main: '#1B159B',
    light: '#4365F0',
    lighter: '#7E96FF',
    lightest: '#EAF0FF',
  },
  purple: {
    darkest: '#0D0517',
    darker: '#260F45',
    dark: '#411973',
    main: '#742CCE',
    light: '#C1A0EB',
    lighter: '#E0CFF5',
    lightest: '#F1EBFB',
  },
  gray: {
    darkest: '#1F2937',
    darker: '#4B5563',
    dark: '#6B7280',
    main: '#9CA3AF',
    light: '#D1D5DB',
    lighter: '#E5E7EB',
    lightest: '#F3F4F6',
  },
  green: {
    darkest: '#1A3D24',
    darker: '#2B663C',
    dark: '#3D8F54',
    main: '#51B56D',
    light: '#8BCE9E',
    lighter: '#C5E6CE',
    lightest: '#ECF7EF',
  },
  red: {
    darkest: '#780101',
    darker: '#C70101',
    dark: '#FD1C1C',
    main: '#FE6B6B',
    light: '#FE9C9C',
    lighter: '#FFBDBD',
    lightest: '#FFDEDE',
  },
  white: {
    darkest: '#DADFEC',
    darker: '#EAEAEF',
    dark: '#f9fafb',
    main: '#FFFFFF',
  },
  orange: {
    darkest: '#913B00',
    darker: '#AA4900',
    dark: '#AA6413',
    main: '#CB8434',
    light: '#E99B40',
    lighter: '#FFB966',
    lightest: '#FFD5A4',
  },
  yellow: {
    darkest: '#BF9900',
    darker: '#CCA300',
    dark: '#E6B800',
    main: '#FFCC00',
    light: '#FFEFB0',
    lighter: '#FFF7D9',
    lightest: '#FFFAE6',
  },
};
