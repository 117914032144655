import React, { Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import config from 'config';
import { loadCrazyEggScript } from 'utils/loadCrazyegg';
import * as authSelectors from '../../modules/auth/selectors';
import { loadHotjar } from '../../utils/loadHotjar';
import NotFound from 'modules/common/NotFound';
import HubRouter from '../HubRouter';

const Router = () => {
  const isInternalUser = useSelector(authSelectors.getIsInternalUser);

  useEffect(() => {
    if (config.HOTJAR.ENABLED && isInternalUser) {
      loadHotjar();
    }
  }, [isInternalUser]);

  useEffect(() => {
    const cleanup = loadCrazyEggScript();

    return () => {
      if (cleanup) {
        cleanup();
      }
    };
  }, []);

  return (
    <Suspense fallback={''}>
      <Switch>
        <Route path="/:hub?" component={HubRouter} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default Router;
