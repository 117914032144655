import { handleActions } from 'redux-actions';
import * as signUpActionCreators from '../actions';

const defaultState = {
  requested: false,
  verified: false,
};

export default handleActions(
  {
    [signUpActionCreators.verifyPhoneSuccess](state) {
      return {
        ...state,
        requested: true,
      };
    },
    [signUpActionCreators.verifyPhoneCodeSuccess](state) {
      return {
        ...state,
        verified: true,
      };
    },
    [signUpActionCreators.verifyPhoneReset]() {
      return {
        requested: false,
        verified: false,
      };
    },
  },
  defaultState,
);
