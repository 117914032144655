const styles = (theme) => ({
  modal: {},
  paper: {
    position: 'absolute',
    width: '288px',
    backgroundColor: theme.contentLayout.backgroundColor,
    boxShadow: '0 2px 6px 2px rgba(0,0,0,0.04)',
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) !important',
    borderRadius: '8px',
    paddingRight: '17px',
    paddingLeft: '21px',
    '@media (min-width:768px)': {
      paddingRight: '35px',
      paddingLeft: '35px',
      width: '529px',
    },
    paddingTop: '50px',
    paddingBottom: '35px',
    maxHeight: '95%',
    overflow: 'auto',
  },
  container: {},
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#DBE0EC',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '20px',
  },
  titleContainer: {
    width: '100%',
  },
  title: {
    color: theme.palette.text.main,
    fontSize: '28px',
    fontWeight: 300,
    lineHeight: '34px',
    fontFamily: 'Lato, sans-serif',
    marginBottom: '13px',
  },
  infoText: {
    marginTop: '9px',
    color: theme.palette.text.main,
    fontSize: '16px',
    fontWeight: 300,
    lineHeight: '20px',
    fontFamily: 'Lato, sans-serif',
  },
  submitButton: {
    padding: '17px 50px',
    borderRadius: '6px',
  },
});

export default styles;
