import { handleActions } from 'redux-actions';

import { getClientSaleFail, getClientSaleSuccess } from '../actions';

const defaultState = {};

export default handleActions(
  {
    [getClientSaleSuccess]: (state, action) => {
      return { ...state, sale: action.response.data.sale };
    },
    [getClientSaleFail]: () => {
      return { ...defaultState };
    },
  },
  defaultState,
);
