import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      rowGap: theme.spacing(3),
      padding: theme.spacing(2),
      paddingTop: theme.spacing(3),
    },
    mobileOnly: {
      padding: 0,
    },
    stepLabel: {
      cursor: 'pointer',
    },
    stepperContainer: {
      padding: 0,
      paddingTop: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        flexBasis: theme.spacing(8),
      },
    },
    stepperBase: { ...theme.stepper.stepperBase },
    headerWrapper: {
      marginBottom: theme.spacing(2),
      ...theme.stepper.headerWrapper,
    },
  }),
  { name: 'TPStepper' },
);
