import compose from 'lodash/fp/compose';
import map from 'lodash/fp/map';
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';

export const formatCurrencySpecs = (currencySpecs) =>
  Object.keys(currencySpecs).map((key) => ({
    label: key,
    value: key,
  }));

export const getContractSizes = compose(sortBy, uniq, map('contractSize'));

export const attachCurrency = (value, currency) =>
  currency ? `${value} ${currency.toUpperCase()}` : value;

export const isRegulatorAsic = (regulator) => String(regulator).toUpperCase() === 'ASIC';
