import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TPTextField } from 'components/TP-UI/TPTextField';
import { TPSelect } from 'components/TP-UI/TPSelect';
import TPInputAdornment from '../TPInputAdornment';
import FlagIcon from './components/TPFlagIcon';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';

import useStyles from './styles';

export const TPPhoneTextField = ({
  name,
  value,
  options,
  grouped,
  groupBy,
  optionValue = 'value',
  optionLabel = 'label',
  loading,
  autofocus = false,
  error,
  returnsObject = false,
  onChange,
  onBlur,
  type = 'number',
  ...props
}) => {
  const classes = useStyles();
  const [country, setCountry] = useState();
  const [code, setCode] = useState();
  const inputRef = useRef();
  const [focus, setFocus] = useState(autofocus);

  const inputValue = useMemo(() => {
    const number = returnsObject ? value?.phoneNumber || '' : value;
    return number.replace('+', '');
  }, [value, returnsObject]);

  const countryValue = returnsObject ? value?.phoneCountry || '' : '';

  useEffect(() => {
    if (options) {
      let opts = options;
      if (grouped) {
        opts = options.reduce((arr, group) => {
          return [...arr, ...group.options];
        }, []);
      }
      let option;
      if ((returnsObject && countryValue) || (country && country[optionValue])) {
        const currentCountry = returnsObject ? countryValue : country[optionValue];
        option = opts.find((option) => {
          return currentCountry === option[optionValue];
        });
      }
      if (
        !option ||
        option.callingCodes[0] !== inputValue.substring(0, option.callingCodes[0].length)
      ) {
        option = opts.find((option) => {
          const code = option.callingCodes && option.callingCodes[0];
          return code && code === inputValue.substring(0, code.length);
        });
      }
      if (option) {
        setCountry(option);
        setCode(option.callingCodes && option.callingCodes[0]);
      } else {
        setCountry('');
        setCode('');
      }
    }
  }, [returnsObject, inputValue, country, countryValue, code, options, grouped, optionValue]);

  useEffect(() => {
    if (inputRef.current && focus) {
      inputRef.current.focus();
      setFocus(false);
    }
  }, [country, focus]);

  const handleChange = useCallback(
    (value, countryName) => {
      if (onChange) {
        const sign = value ? '+' : '';
        const phoneNumber = sign + value;
        onChange(returnsObject ? { phoneNumber, phoneCountry: countryName } : phoneNumber);
      }
    },
    [onChange, returnsObject],
  );

  const handleTextFieldChange = useCallback(
    (value) => {
      handleChange(value, country[optionValue]);
    },
    [handleChange, country, optionValue],
  );

  const handleCountryChange = useCallback(
    (val) => {
      const newCode = val?.callingCodes[0] || '';
      const newVal = !inputValue
        ? newCode
        : newCode
        ? newCode + inputValue.substring(code.length)
        : inputValue;

      const newCountry = val[optionValue];

      setCountry(val);
      setCode(newCode);
      handleChange(newVal, newCountry);
      setFocus(true);
    },
    [optionValue, handleChange, inputValue, code],
  );

  const handleKeyDown = useCallback(
    (e) =>
      type === 'number' &&
      (['e', 'E', '+', '-', '.'].includes(e.key) || [38, 40].includes(e.keyCode)) &&
      e.preventDefault(),
    [type],
  );

  const handleWheel = useCallback((e) => {
    e.target.blur();
    return false;
  }, []);

  const renderValue = useCallback(
    (option) =>
      option ? (
        <FlagIcon label={option[optionLabel] || ''} countryCode={option.alpha2Code} />
      ) : null,
    [optionLabel],
  );

  const renderOption = useCallback(
    (option) => (
      <>
        <FlagIcon
          className={classes.flagIcon}
          label={option[optionLabel] || ''}
          countryCode={option.alpha2Code}
        />{' '}
        {option[optionLabel] || ''} {option?.callingCodes[0] ? `(+${option.callingCodes[0]})` : ''}
      </>
    ),
    [optionLabel, classes.flagIcon],
  );

  return (
    <TPTextField
      inputRef={inputRef}
      name={name}
      value={inputValue}
      error={error}
      type={type}
      loading={loading}
      onChange={handleTextFieldChange}
      onKeyDown={handleKeyDown}
      onBlur={onBlur}
      //prevent scrolling onwheel
      onWheel={handleWheel}
      autofocus={autofocus}
      {...props}
      startAdornment={
        <>
          <TPInputAdornment position="start" className={classes.selectContainer}>
            {options.length && options.length > 0 ? (
              <TPSelect
                id={`${name}_country_code`}
                name={`${name}_country_code`}
                value={country}
                variant="custom"
                placeholder={
                  <span className={classes.selectPlaceholder}>
                    <FlagOutlinedIcon />
                  </span>
                }
                reservedErrorSpace={false}
                loading={loading}
                options={options}
                grouped={grouped}
                groupBy={groupBy}
                optionValue={optionValue}
                optionLabel={optionLabel}
                returnsObject
                fullWidth
                onChange={handleCountryChange}
                renderValue={renderValue}
                renderOption={renderOption}
              />
            ) : null}
          </TPInputAdornment>
          <span className={classes.sign}>+</span>
        </>
      }
    />
  );
};

TPPhoneTextField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ phoneNumber: PropTypes.string, phoneCountry: PropTypes.string }),
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      flag: PropTypes.string,
      callingCodes: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Reserved space to display error in 1 line
   */
  reservedErrorSpace: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  returnsObject: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'number']),
};

const TPPhoneReduxTextField = ({ input, meta, ...others }) => {
  const error = meta.submitFailed && meta.error ? meta.error : null;
  return (
    <TPPhoneTextField
      {...input}
      error={error}
      {...others}
      onChange={(value) => {
        input.onChange(value);
      }}
      onBlur={() => input.onBlur(input.value)}
    />
  );
};

export default TPPhoneReduxTextField;
