import { combineActions, handleActions } from 'redux-actions';

import * as actionCreators from '../actions';
import { CURRENCY_CLOUD_TRANSFER_STATES, DIALOG_NAME } from '../constants';
import { DIALOG_NAME as ACCOUNT_DIALOG_NAME } from '../../../../accounts/pages/MyAccounts/constants';
import {
  accountsHideDialog,
  createAccountSuccess,
} from '../../../../accounts/pages/MyAccounts/actions';

const defaultState = {
  rate: '',
  history: null,
  activeDialog: '',
  submitValues: {},
  currencyCloudRates: {},
  completedTransfer: {},
  currencyCloudActiveView: CURRENCY_CLOUD_TRANSFER_STATES.form,
  forcePlainTransfer: false,
};

export default handleActions(
  {
    [actionCreators.transferPageOpened](state) {
      return {
        ...defaultState,
        history: state.history,
      };
    },
    [actionCreators.transferAccountsShowConfirm](state, action) {
      return {
        ...state,
        activeDialog: DIALOG_NAME.CONFIRM_TRANSFER,
        submitValues: action.payload,
      };
    },
    [actionCreators.updateCurrencyFromPicker](state, action) {
      return {
        ...state,
        currencyCloudPickerFrom: action.payload,
      };
    },
    [actionCreators.updateCurrencyToPicker](state, action) {
      return {
        ...state,
        currencyCloudPickerTo: action.payload,
      };
    },
    [combineActions(
      actionCreators.transferAccountsHideDialog,
      accountsHideDialog,
      createAccountSuccess,
    )](state) {
      return {
        ...state,
        activeDialog: '',
      };
    },

    [actionCreators.getConversionRateSuccess](state, action) {
      return {
        ...state,
        rate: action.response.data.rate,
      };
    },

    [actionCreators.createTransferSuccess](state) {
      return {
        ...defaultState,
        history: state.history,
      };
    },
    [actionCreators.createTransferCurrencyCloudSuccess](state, action) {
      return {
        ...defaultState,
        currencyCloudActiveView: CURRENCY_CLOUD_TRANSFER_STATES.success,
        completedTransfer: action.response.data,
        history: state.history,
      };
    },
    [actionCreators.searchTransferHistorySuccess](state, action) {
      return {
        ...state,
        history: action.response.data,
      };
    },
    [actionCreators.transfersCreateAccount](state) {
      return {
        ...state,
        activeDialog: ACCOUNT_DIALOG_NAME.ADD_ACCOUNT,
      };
    },
    [actionCreators.currencyCloudRatesSuccess](state, action) {
      return {
        ...state,
        currencyCloudRates: action.response.data,
        currencyCloudActiveView: CURRENCY_CLOUD_TRANSFER_STATES.quotes,
      };
    },
    [combineActions(
      actionCreators.transfersCurrencyCloudCancel,
      actionCreators.createTransferCurrencyCloudFail,
    )](state) {
      return {
        ...defaultState,
        history: state.history,
      };
    },
    [actionCreators.switchCurrencyCloudWeekendRates](state) {
      return {
        ...state,
        forcePlainTransfer: true,
      };
    },
    [actionCreators.transferReuseHistory](state) {
      return {
        ...state,
        currencyCloudActiveView: CURRENCY_CLOUD_TRANSFER_STATES.form,
      };
    },
  },
  defaultState,
);
