import { put, delay, takeEvery } from 'redux-saga/effects';
import { ALERT_VARIANTS } from 'components/TP-UI/TPAlert/constants';
import * as actionCreators from '../actions';

function* showInfo(action) {
  const { autoHideDuration, key } = action.payload;
  if (autoHideDuration) {
    yield delay(autoHideDuration);
    yield put(actionCreators.removeNotification(key));
  }
}

function* showError(action) {
  const { autoHideDuration, key } = action.payload;
  if (autoHideDuration) {
    yield delay(autoHideDuration);
    yield put(actionCreators.removeNotification(key));
  }
}

function* index() {
  yield takeEvery(actionCreators.SHOW_NOTIFICATION_ALERT, (action) => {
    if (action.payload.variant === ALERT_VARIANTS.INFO) {
      showInfo(action);
    } else if (action.payload.variant === ALERT_VARIANTS.ERROR) {
      showError(action);
    }
  });
}

export default index;
