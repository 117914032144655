import React from 'react';
import PropTypes from 'prop-types';
import TPIconButton from '../../../TPIconButton';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import TPMenu from '../../../TPMenu';
import { isMobile, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import TPTooltip from '../../../TPTooltip';

const TPFileActionsMenu = ({ loading, disabled, actions = [], className }) => {
  const { t } = useTranslation('common');
  return (
    <TPMenu menuItems={actions}>
      <TPTooltip content={t('labels.downloads')} disabled={isTablet || isMobile}>
        <TPIconButton className={className} disabled={loading || disabled}>
          <GetAppOutlinedIcon />
        </TPIconButton>
      </TPTooltip>
    </TPMenu>
  );
};

TPFileActionsMenu.propTypes = {
  actions: TPMenu.propTypes.menuItems,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default TPFileActionsMenu;
