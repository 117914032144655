import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import styles from './styles';
import { Grid, withStyles } from '@material-ui/core';
import loader_squares from 'static/images/old/loader_squares.gif';
import { DASHBOARD_GRAPH_COLORS, DASHBOARD_OVERVIEW_DATA_LABEL } from '../../constants';

const DashboardGraph = ({ classes, isLoading, datasets, isTotalAndGraphDataLoading }) => {
  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: true,
      },
      hover: {
        mode: 'nearest',
        intersect: true,
      },
      legend: {
        position: 'right',
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            distribution: 'series',
            // offset: false,
            unit: 'day',
            time: {
              displayFormats: {
                day: 'DD/MM/YYYY',
              },
            },
            gridLines: {
              display: false,
            },
            ticks: {
              display: !isLoading || !isTotalAndGraphDataLoading,
              fontFamily: 'Lato, sans-serif',
            },
          },
        ],
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: false,
            },
          },
        ],
      },
    }),
    [isLoading, isTotalAndGraphDataLoading],
  );

  const dataset = useMemo(
    () => ({
      datasets: Object.keys(datasets).map((key, index) => ({
        label: DASHBOARD_OVERVIEW_DATA_LABEL[key],
        borderColor: DASHBOARD_GRAPH_COLORS[index],
        data: datasets[key],
        pointRadius: 2,
        fill: false,
        lineTension: 0.3,
        borderWidth: 3,
      })),
    }),
    [datasets],
  );

  return (
    <Grid container className={classes.root} justifyContent="center" alignContent="center">
      {isLoading || isTotalAndGraphDataLoading ? (
        <img alt="" src={loader_squares} />
      ) : (
        <Line height={360} data={dataset} options={options} />
      )}
    </Grid>
  );
};

export default withStyles(styles)(DashboardGraph);
