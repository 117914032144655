import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    pagesContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    prevIcon: {
      marginLeft: theme.spacing(1.25),
    },
    pageList: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
      display: 'flex',
    },
    nextIcon: {
      marginLeft: theme.spacing(0.25),
    },
  }),
  { name: 'TPPagesNavigation' },
);
