import React, { useRef, useLayoutEffect } from 'react';
import { GAP_BETWEEN_SNACKBARS } from 'components/TP-UI/TPSnackbar/context/constants';
import TPSnackbar from 'components/TP-UI/TPSnackbar';

const Notifications = ({ queue, onClose }) => {
  const snackbarRefs = useRef([]);

  useLayoutEffect(() => {
    let accumulatedOffset = {
      'top-left': 0,
      'top-center': 0,
      'top-right': 0,
      'bottom-left': 0,
      'bottom-center': 0,
      'bottom-right': 0,
    };

    snackbarRefs.current.forEach((snackbarRef, index) => {
      if (snackbarRef) {
        const height = snackbarRef.clientHeight;
        const verticalPosition = queue[index].anchorOrigin.vertical;
        const horizontalPosition = queue[index].anchorOrigin.horizontal;
        const combinedPosition = `${verticalPosition}-${horizontalPosition}`;

        if (verticalPosition === 'top') {
          snackbarRef.style.transform = `translateY(${accumulatedOffset[combinedPosition]}px)`;
          accumulatedOffset[combinedPosition] += height + GAP_BETWEEN_SNACKBARS;
        } else if (verticalPosition === 'bottom') {
          snackbarRef.style.transform = `translateY(${-accumulatedOffset[combinedPosition]}px)`;
          accumulatedOffset[combinedPosition] += height + GAP_BETWEEN_SNACKBARS;
        }
      }
    });
  }, [queue]);

  return queue.map((snackbar, index) => (
    <TPSnackbar
      ref={(el) => (snackbarRefs.current[index] = el)}
      key={snackbar.key}
      {...snackbar}
      open
      onClose={(event, reason) => onClose(event, reason, snackbar.key)}
    />
  ));
};

export default Notifications;
