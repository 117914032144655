import React from 'react';
import TPBanner from 'components/TP-UI/TPBanner';
import TPGrid from 'components/TP-UI/TPGrid';
import { Trans } from 'react-i18next';
import TPLink from 'components/TP-UI/TPLink';

const Banners = () => {
  const handleCloseBanner = () => {};
  return (
    <TPGrid container spacing={2} direction="column">
      <TPGrid item>
        <TPBanner>This is banner with text!</TPBanner>
      </TPGrid>

      <TPGrid item>
        <TPBanner onClose={handleCloseBanner}>This is banner with text and close icon!</TPBanner>
      </TPGrid>

      <TPGrid item>
        <TPBanner onClose={handleCloseBanner}>
          <Trans i18nKey="banners.FM5thAnniversary" ns="common">
            Fusion Markets Turns 5!
            <span role="img" aria-label="party pooper">
              🎉
            </span>
            To celebrate, we&apos;re offering a 10% rebate on commissions for trades opened on May
            31st.
            <TPLink path="https://fusionmarkets.com/posts/fusion-markets-celebrates-5-years#5yr_anniversary_promotion">
              Click here to learn more.
            </TPLink>
          </Trans>
        </TPBanner>
      </TPGrid>
    </TPGrid>
  );
};

export default Banners;
