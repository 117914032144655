import React from 'react';
import { useTranslation } from 'react-i18next';
import TPTypography from 'components/TP-UI/TPTypography';
import PropTypes from 'prop-types';
import TPGrid from 'components/TP-UI/TPGrid';
import formatAmount from 'utils/formatAmount';

const MinMaxAmountHint = ({ min, minLabel, max, maxLabel, currency }) => {
  const { t } = useTranslation('payments');

  return min || max ? (
    <TPGrid container direction="column">
      {min ? (
        <TPGrid item>
          <TPTypography variant="inherit" color="secondary">
            {minLabel ?? t('withdraw2.messages.titleMin')}&nbsp;
          </TPTypography>
          <TPTypography variant="inherit">{formatAmount(min, currency)}</TPTypography>
        </TPGrid>
      ) : null}
      {max ? (
        <TPGrid item>
          <TPTypography variant="inherit" color="secondary">
            {maxLabel ?? t('withdraw2.messages.titleMax')}&nbsp;
          </TPTypography>
          <TPTypography variant="inherit">{formatAmount(max, currency)}</TPTypography>
        </TPGrid>
      ) : null}
    </TPGrid>
  ) : null;
};

MinMaxAmountHint.propTypes = {
  min: PropTypes.number,
  minLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  max: PropTypes.number,
  maxLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  currency: PropTypes.string.isRequired,
};

export default MinMaxAmountHint;
