import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TPButton from 'components/TP-UI/TPButton';
import { openModal } from 'components/TP-UI/TPModal/actions';
import { LPOA_DOCUMENT_MODAL } from 'modules/lpoa/constants';

import useStyles from './styles';

const LPOANotification = () => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(openModal(LPOA_DOCUMENT_MODAL));
  }, [dispatch]);

  return (
    <TPButton secondary size="small" className={classes.lpoaBtn} onClick={handleClick}>
      {t('lpoa:notificationMessage')}
    </TPButton>
  );
};

export default LPOANotification;
