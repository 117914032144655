import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TPBottomNavigationAction from '../TPBottomNavigationAction';
import { getTranslation } from 'utils/compositeTranslationHandler';
import TPMenu from 'components/TP-UI/TPMenu';

const TPBottomNavigationActionMenu = ({
  id,
  label,
  iconComponent,
  value,
  selected,
  selectedRoute,
  menuItems = [],
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const mappedMenuItems = useMemo(
    () =>
      menuItems.map((item) => ({
        id: item.id,
        path: item.path,
        iconComponent: item.iconComponent,
        label: getTranslation(t, item.label),
        selected: selectedRoute.includes(item.path),
      })),
    [menuItems, selectedRoute, t],
  );

  const handleOpen = useCallback(() => setOpen(true), [setOpen]);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  return (
    <TPMenu menuItems={mappedMenuItems} onOpen={handleOpen} onClose={handleClose}>
      <TPBottomNavigationAction
        expandable
        expanded={open}
        id={id}
        key={id}
        value={value}
        selected={selected}
        label={label}
        iconComponent={iconComponent}
      />
    </TPMenu>
  );
};

TPBottomNavigationActionMenu.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  iconComponent: PropTypes.elementType,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selected: PropTypes.bool,
  selectedRoute: PropTypes.string,
  menuItems: PropTypes.array,
};

export default TPBottomNavigationActionMenu;
