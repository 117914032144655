import palette from './palette';

const OverridesMuiAccordion = {
  filled: {
    border: `1px solid ${palette.divider}`,
    transition: 'background-color .3s',
    backgroundColor: palette.primary.lightest,
    '&.Mui-expanded': {
      backgroundColor: palette.background.main,
    },
  },
};

export default OverridesMuiAccordion;
