import { useDispatch } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';
import { CUSTOM_DATE_RANGE_SELECTOR } from '../constants';
import { getDateRangeValuesByCustomType } from '../utils/dateRange';
import { change } from 'redux-form';
import TPSelect from 'components/TP-UI/TPSelect';
import TPDatePicker from 'components/TP-UI/TPDatePicker';
import { required } from 'utils/validation/fieldValidationRules';

const useDashboardFilterFields = ({ form, additionalFilters }) => {
  const dispatch = useDispatch();
  const [customDateRangeItems, setCustomDateRangeItems] = useState(CUSTOM_DATE_RANGE_SELECTOR);

  const handleCustomRangeChange = useCallback(
    (value) => {
      const { dateFrom, dateTo } = getDateRangeValuesByCustomType(value);
      dispatch(change(form, 'dateFrom', dateFrom));
      dispatch(change(form, 'dateTo', dateTo));
      setCustomDateRangeItems([...CUSTOM_DATE_RANGE_SELECTOR]);
    },
    [dispatch, form],
  );

  const handleDateChange = useCallback(() => {
    setCustomDateRangeItems([
      { label: 'Custom', value: 'custom', disabled: true },
      ...CUSTOM_DATE_RANGE_SELECTOR,
    ]);
    dispatch(change(form, 'customDateSelector', 'custom'));
  }, [dispatch, form]);

  const fields = useMemo(
    () => [
      {
        name: 'customDateSelector',
        placeholder: 'Date range',
        clearable: false,
        component: TPSelect,
        options: customDateRangeItems,
        width: '155px',
        onChange: handleCustomRangeChange,
      },
      {
        name: 'dateFrom',
        component: TPDatePicker,
        clearable: false,
        required: true,
        validate: [required],
        placeholder: 'Date from',
        width: '125px',
        onChange: handleDateChange,
      },
      {
        name: 'dateTo',
        component: TPDatePicker,
        clearable: false,
        required: true,
        validate: [required],
        placeholder: 'Date to',
        width: '125px',
        onChange: handleDateChange,
      },
      ...(additionalFilters || []),
    ],
    [customDateRangeItems, handleCustomRangeChange, handleDateChange, additionalFilters],
  );

  return fields;
};

export default useDashboardFilterFields;
