import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import TPSelect from 'components/TP-UI/TPSelect';
import TPCurrency from 'components/TP-UI/TPCurrency';

export const CurrencySelect = (props) => {
  const renderCurrency = useCallback((option) => (option ? <TPCurrency {...option} /> : null), []);
  return <TPSelect renderValue={renderCurrency} renderOption={renderCurrency} {...props} />;
};

CurrencySelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      flag: PropTypes.string,
    }),
  ),
  ...TPSelect.propTypes,
};

export default CurrencySelect;
