export default (accountName, noFormatForSmallScreens, halfSymbolsAmount = 6, maxTextLength) => {
  let formattedName = accountName;
  let maxLength = window.outerWidth >= 640 ? 24 : 15;
  if (window.outerWidth <= 480) {
    maxLength = 15;
  }
  if (noFormatForSmallScreens) {
    maxLength = maxTextLength || 24;
  }
  if (formattedName.length > maxLength) {
    formattedName =
      formattedName.substr(0, halfSymbolsAmount) +
      '...' +
      formattedName.substr(formattedName.length - halfSymbolsAmount, formattedName.length);
  }

  return formattedName;
};
