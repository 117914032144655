import { handleActions } from 'redux-actions';

import {
  cleanupEmployees,
  editEmployeesAssignedCxdSuccess,
  editEmployeesDisableMfaSuccess,
  editEmployeesPermissionsSuccess,
  editEmployeesRoleSuccess,
  getEmployeesFail,
  getEmployeesSuccess,
  getFullEmployeesFail,
  getFullEmployeesSuccess,
} from '../actions';

const updateProp = (employee, userId, prop, value) => {
  if (employee._id === userId) {
    return { ...employee, [prop]: value };
  }
  return employee;
};

const defaultState = {
  items: [],
  filteredEmployees: {
    items: [],
    requestPayload: null,
  },
};

export default handleActions(
  {
    [getFullEmployeesSuccess](state, action) {
      return {
        ...state,
        items: action.response.data.employees,
        totalNumber: action.response.data.totalNumber,
      };
    },
    [getFullEmployeesFail](state) {
      return {
        ...state,
        items: defaultState.items,
      };
    },
    [getEmployeesSuccess](state, action) {
      return {
        ...state,
        filteredEmployees: {
          items: action.response.data.employees,
          totalNumber: action.response.data.totalNumber,
          requestPayload: action.payload,
        },
      };
    },
    [getEmployeesFail](state) {
      return state.filteredEmployees.totalNumber !== undefined
        ? state
        : {
            ...state,
            filteredEmployees: {
              ...defaultState.filteredEmployees,
              totalNumber: 0,
            },
          };
    },
    [cleanupEmployees](state) {
      return {
        ...state,
        filteredEmployees: defaultState.filteredEmployees,
      };
    },
    [editEmployeesDisableMfaSuccess](state, action) {
      const userId = action.payload.userId;
      return {
        ...state,
        items: state.items.map((employee) => updateProp(employee, userId, 'mfaEnabled', false)),
        filteredEmployees: {
          ...state.filteredEmployees,
          items: state.filteredEmployees.items.map((employee) =>
            updateProp(employee, userId, 'mfaEnabled', false),
          ),
        },
      };
    },
    [editEmployeesRoleSuccess](state, action) {
      const userId = action.payload.userId;
      return {
        ...state,
        items: state.items.map((employee) =>
          updateProp(employee, userId, 'role', action.payload.role),
        ),
        filteredEmployees: {
          ...state.filteredEmployees,
          items: state.filteredEmployees.items.map((employee) =>
            updateProp(employee, userId, 'role', action.payload.role),
          ),
        },
      };
    },
    [editEmployeesAssignedCxdSuccess](state, action) {
      const userId = action.payload.userId;
      return {
        ...state,
        items: state.items.map((employee) =>
          updateProp(employee, userId, 'assignedCxds', action.response.data.assignedCxds),
        ),
        filteredEmployees: {
          ...state.filteredEmployees,
          items: state.filteredEmployees.items.map((employee) =>
            updateProp(employee, userId, 'assignedCxds', action.response.data.assignedCxds),
          ),
        },
      };
    },
    [editEmployeesPermissionsSuccess](state, action) {
      const userId = action.payload.userId;
      return {
        ...state,
        items: state.items.map((employee) =>
          updateProp(employee, userId, 'permissions', {
            ...employee.permissions,
            allowSendFromUser: action.payload.allowSendFromUser,
          }),
        ),
        filteredEmployees: {
          ...state.filteredEmployees,
          items: state.filteredEmployees.items.map((employee) =>
            updateProp(employee, userId, 'permissions', {
              ...employee.permissions,
              allowSendFromUser: action.payload.allowSendFromUser,
            }),
          ),
        },
      };
    },
  },
  defaultState,
);
