import { handleActions } from 'redux-actions';

import {
  getCryptoDay1AssetsSuccess,
  paymentsWithdrawDay1FeeFail,
  paymentsWithdrawDay1FeeSuccess,
  paymentsWithdrawDay1LimitFail,
  paymentsWithdrawDay1LimitRequest,
  paymentsWithdrawDay1LimitSuccess,
  paymentsWithdrawDay1ValidateRequest,
  paymentsWithdrawDay1ValidateSuccess,
} from '../actions';
const defaultState = {
  assets: [],
  limit: {},
};

export default handleActions(
  {
    [getCryptoDay1AssetsSuccess](state, action) {
      return {
        ...state,
        assets: action.response.data.data,
      };
    },
    [paymentsWithdrawDay1ValidateRequest](state) {
      return {
        ...state,
        isValid: false,
      };
    },
    [paymentsWithdrawDay1ValidateSuccess](state, action) {
      return {
        ...state,
        isValid: action.response.data.isValid,
      };
    },
    [paymentsWithdrawDay1FeeSuccess](state, action) {
      return {
        ...state,
        fee: action.response.data,
      };
    },
    [paymentsWithdrawDay1FeeFail](state) {
      return {
        ...state,
        fee: null,
      };
    },
    [paymentsWithdrawDay1LimitRequest](state) {
      return {
        ...state,
        limit: {},
      };
    },
    [paymentsWithdrawDay1LimitSuccess](state, action) {
      return {
        ...state,
        limit: action.response.data,
      };
    },
    [paymentsWithdrawDay1LimitFail](state) {
      return {
        ...state,
        limit: {},
      };
    },
  },
  defaultState,
);
