import React from 'react';
import PropTypes from 'prop-types';
import TPLink from 'components/TP-UI/TPLink';
import NotificationsButton from '../NotificationsButton';

const NotificationsLink = ({ path, notificationsCount, onClick }) => {
  return (
    <TPLink path={path}>
      <NotificationsButton notificationsCount={notificationsCount} onClick={onClick} />
    </TPLink>
  );
};

NotificationsLink.propTypes = {
  notificationsCount: PropTypes.number.isRequired,
  path: PropTypes.string,
  onClick: PropTypes.func,
};

export default NotificationsLink;
