const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    minWidth: '100%',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '21px',
    '@media (min-width:640px)': {
      minWidth: '220px',
      maxWidth: '220px',
      paddingLeft: '23px',
      paddingBottom: '0px',
      height: '100%',
      flexDirection: 'column',
    },
    '@media (min-width:768px)': {
      minWidth: '250px',
      maxWidth: '250px',
      paddingLeft: '40px',
    },
    '@media (min-width:1366px)': {
      minWidth: '350px',
      maxWidth: '350px',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingLeft: '25px',
      paddingRight: '25px',
    },
    backgroundColor: theme.tableAmountReuseField.backgroundColor,
    paddingTop: '21px',
  },
  label: {
    color: theme.tableAmountReuseField.labelColor,
    fontFamily: 'Lato, sans-serif',
    fontSize: '12px',
    lineHeight: '15px',
  },
  amountText: {
    color: theme.palette.text.main,
    fontFamily: 'Lato, sans-serif',
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '22px',
    marginTop: '2px',
    whiteSpace: 'nowrap',
  },
  amountSmallText: {
    fontSize: '15px',
  },
  reuseButton: {
    fontSize: '14px',
    lineHeight: '17px',
    height: '32px',
    width: '115px',
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: '10px',
    marginLeft: '5px',
    position: 'relative',
    top: '-20px',
    left: '10px',
    '@media (min-width:640px)': {
      marginTop: '20px',
      marginLeft: '-5px',
      position: 'static',
      top: 0,
      left: 0,
    },
    '@media (min-width:1366px)': {
      marginTop: '10px',
      marginLeft: '0px',
    },
  },
  icon: {
    fontSize: '15px',
    marginRight: '2px',
    width: '',
  },
  amountContainer: {
    width: '50%',
    '@media (min-width:640px)': {
      width: 'auto',
    },
  },
});

export default styles;
