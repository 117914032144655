import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0),
      margin: theme.spacing(0),
      minHeight: '48px',
      '&.Mui-expanded': {
        minHeight: '48px',
      },
    },
    elevation: {
      minHeight: '32px',
      '&.Mui-expanded': {
        minHeight: '32px',
      },
    },
    content: {
      margin: theme.spacing(0),
      '&.Mui-expanded': {
        margin: theme.spacing(0),
      },
    },
    expandIcon: {
      marginRight: theme.spacing(-0.25),
      padding: 0,
    },
    titleIcon: {
      display: 'flex',
      fill: theme.palette.primary.text,
    },
  }),
  { name: 'TPAccordionSummary' },
);
