import React, { useState, useCallback } from 'react';
import TPStepper from 'components/TP-UI/TPStepper';
import { steps as initialSteps } from 'modules/UIComponents/Steppers/config';
import { STEP_ACTION } from 'modules/UIComponents/Steppers/constants';

const Steppers = () => {
  const { NEXT, PREV, FINISH } = STEP_ACTION;
  const [activeStep, setActiveStep] = useState(0);

  const steps = initialSteps.map((step, index) => ({
    ...step,
    passed: index < activeStep,
  }));

  const handleNext = useCallback(() => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  }, [activeStep, steps.length]);

  const handlePrev = useCallback(() => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  }, [activeStep]);

  const handleFinish = useCallback(() => {
    if (activeStep === steps.length - 1) {
      /**
       * add custom logic here
       */
    }
  }, [activeStep, steps.length]);

  const actionHandlers = {
    [NEXT]: handleNext,
    [PREV]: handlePrev,
    [FINISH]: handleFinish,
    /**
     * add custom handlers here
     */
  };

  const handleStepChange = (actionOrIndex) => {
    if (typeof actionOrIndex === 'number') {
      setActiveStep(actionOrIndex);
      return;
    }

    if (actionHandlers[actionOrIndex]) {
      actionHandlers[actionOrIndex]();
    }
  };

  return <TPStepper steps={steps} activeStep={activeStep} onStepChange={handleStepChange} />;
};

export default Steppers;
