import api from '../index';
import { MAP_HUB_BY_PREFIX } from '../../../constants';

export const signinRequest = (payload) => {
  if (!payload.hideMfaToken) {
    delete payload.hideMfaToken;
  }
  if (payload.prefix) {
    payload.hub = MAP_HUB_BY_PREFIX[payload.prefix];
  }
  delete payload.prefix;
  return api.post('/auth/signin', payload);
};

export const appleSigninRequest = (payload) => {
  if (!payload.hideMfaToken) {
    delete payload.hideMfaToken;
  }

  return api.post('/auth/apple/signin', payload);
};

export const googleSigninRequest = (payload) => {
  if (!payload.hideMfaToken) {
    delete payload.hideMfaToken;
  }
  return api.post('/auth/google/signin', payload);
};

export const signupRequest = (payload) => {
  if (payload.cxd === 'null') {
    delete payload.cxd;
  }
  if (payload.hub === 'null') {
    delete payload.hub;
  }
  if (payload.phoneNumber === 'null') {
    delete payload.phoneNumber;
  }
  return api.post('/auth/signup', payload).then(async (response) => {
    if (response.status === 200 && window.dataLayer) {
      // Needs to correct work of Google Tag Manager (GTM)
      window.dataLayer.push({
        event: 'formSubmitted',
        leadsUserData: {
          email_address: payload.email,
        },
      });
    }

    return response;
  });
};

export const forgotPasswordRequest = (payload) => {
  if (payload.prefix) {
    payload.hub = MAP_HUB_BY_PREFIX[payload.prefix];
  }
  delete payload.prefix;
  return api.post('/auth/forgot-password', payload);
};

export const resetPasswordRequest = (payload) => {
  return api.post('/auth/reset-password', payload);
};

export const getMfaQrCodeRequest = () => {
  return api.get('/auth/mfa/qr-code');
};

export const setupMfaRequest = ({ code, secret }) => {
  return api.post('/auth/mfa/setup', { code, secret });
};

export const setupMfaRequiredRequest = ({ code, secret }) => {
  return api.post('/auth/mfa-required/setup', { code, secret });
};

export const disableMfaRequest = (payload) => {
  return api.post('/auth/mfa/disable', payload);
};

export const disableUserMfaRequest = (payload) => {
  return api.post(`/auth/mfa/disable/${payload.userId}`);
};

export const validateMfaRequest = (payload) => {
  return api.post('/auth/mfa/input', payload);
};

export const googleAuthRequest = (payload) => {
  if (payload.cxd === 'null') {
    delete payload.cxd;
  }
  if (payload.hub === 'null') {
    delete payload.hub;
  }
  return api.post('/auth/google', payload);
};

export const appleAuthRequest = (payload) => {
  if (payload.cxd === 'null') {
    delete payload.cxd;
  }
  if (payload.hub === 'null') {
    delete payload.hub;
  }

  return api.post('/auth/apple', payload);
};

export const signinAsUserRequest = (payload) => {
  return api.post('/auth/sign-in-as-user', payload);
};

export const forcePasswordChangeRequest = (payload) => {
  return api.post('/auth/force-password-change', payload);
};

export const leadUserJoinRequest = ({ password, token }) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return api.post(
    '/lead/accept',
    {
      password,
    },
    { headers },
  );
};

export const validateFingerprintRequest = (payload) => {
  return api.post('/auth/validate-fingerprint', payload);
};

export const verifyPhoneRequest = (payload) => {
  return api.post('/auth/verify-phone', payload);
};

export const verifyPhoneCodeRequest = (payload) => {
  return api.post('/auth/verify-phone-code', payload);
};

export const verifyEmailRequest = (payload) => {
  return api.post('/auth/verify-email', payload);
};

export const sendVerificationEmailRequest = () => {
  return api.post('/auth/send-email-verification');
};

export const postponeSetupMfaRequest = (payload) => {
  return api.patch('/auth/mfa/postpone', payload);
};
