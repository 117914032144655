import { get } from 'lodash';
import { handleActions, combineActions } from 'redux-actions';
import * as actionCreators from '../actions';
import { UPLOAD_EVIDENCE_STATUSES } from '../constants';

const defaultState = {
  activeStep: 0,
  type: null,
  initialDataLoaded: false,
};

export default handleActions(
  {
    [actionCreators.setActiveStep](state, action) {
      return { ...state, activeStep: action.payload };
    },
    [actionCreators.getEvidenceStatementFail](state) {
      return { ...state, status: UPLOAD_EVIDENCE_STATUSES.INITIAL, initialDataLoaded: true };
    },
    [actionCreators.getEvidenceStatementSuccess](state, action) {
      const data = get(action, 'response.data');
      const { status, type } = data;
      const activeStep = !status || status === UPLOAD_EVIDENCE_STATUSES.INITIAL ? 0 : 1;

      return {
        ...state,
        ...data,
        initialDataLoaded: true,
        activeStep,
        type,
      };
    },
    [combineActions(
      actionCreators.createEvidenceStatementSuccess,
      actionCreators.updateEvidenceStatementSuccess,
    )](state, action) {
      const data = get(action, 'response.data.user');
      const tradingStatementEvidence = data.tradingStatementEvidence;
      const { status, type } = tradingStatementEvidence;
      const activeStep = !status || status === UPLOAD_EVIDENCE_STATUSES.INITIAL ? 0 : 1;

      return {
        ...state,
        ...tradingStatementEvidence,
        initialDataLoaded: true,
        activeStep,
        type,
      };
    },
  },
  defaultState,
);
