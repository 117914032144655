import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    form: {
      ...theme.form.root,
    },
  }),
  { name: 'ForcePasswordChangeForm' },
);

export default useStyles;
