import fmDate from 'utils/fmDate';
import TPSelect from 'components/TP-UI/TPSelect';

export const DASHBOARD_ACTIVE_USERS_HEADER_FORM = 'DASHBOARD_ACTIVE_USERS_HEADER_FORM';
export const DASHBOARD_COUNTRY_USERS_HEADER_FORM = 'DASHBOARD_COUNTRY_USERS_HEADER_FORM';
export const DASHBOARD_USER_VOLUMES_HEADER_FORM = 'DASHBOARD_USER_VOLUMES_HEADER_FORM';
export const DASHBOARD_OVERVIEW_DATA_HEADER_FORM = 'DASHBOARD_OVERVIEW_DATA_HEADER_FORM';
export const DASHBOARD_MARKETING_CHANNEL_HEADER_FORM = 'DASHBOARD_MARKETING_CHANNEL_HEADER_FORM';

export const DASHBOARD_STATS_CUSTOMIZATION_FORM = 'DASHBOARD_STATS_CUSTOMIZATION_FORM';
export const DASHBOARD_STATS_CUSTOMIZATION_MODAL = 'DASHBOARD_STATS_CUSTOMIZATION_MODAL';
export const DASHBOARD_GRAPH_COLORS = [
  '#87f1ffff',
  '#c4bbb8ff',
  '#b2dae4ff',
  '#bfc0cbff',
  '#bea6b4ff',
  '#bd8b9cff',
  '#b64f7bff',
  '#af125aff',
  '#841f36ff',
  '#582b11ff',
  '#39375bff',
  '#574a79ff',
  '#665388ff',
  '#745c97ff',
  '#9481b1ff',
  '#bcb0cfff',
  '#f2eff5ff',
  '#ccc0d8ff',
  '#a690bbff',
];

export const DASHBOARD_METRICS = {
  registrations: 'registrations',
  demoAccounts: 'demoAccounts',
  liveAccounts: 'liveAccounts',
  fundedAccounts: 'fundedAccounts',
  tradedAccounts: 'tradedAccounts',
  netDeposits: 'netDeposits',
  depositsTotal: 'depositsTotal',
  withdrawalsTotal: 'withdrawalsTotal',
  volumeTraded: 'volumeTraded',
  volumeForex: 'volumeForex',
  volumeCryptos: 'volumeCryptos',
  volumeIndices: 'volumeIndices',
  activeUsers: 'activeUsers',
  conversionRate: 'conversionRate',
  withCxdUsers: 'withCxdUsers',
  withoutCxdUsers: 'withoutCxdUsers',
  rejectedAccounts: 'rejectedAccounts',
  mt4Accounts: 'mt4Accounts',
  mt5Accounts: 'mt5Accounts',
  ctraderAccounts: 'ctraderAccounts',
  tradingViewAccounts: 'tradingViewAccounts',
  leadOwnerFilter: 'leadOwnerFilter',
};

export const DASHBOARD_METRICS_PRIORITY = {
  [DASHBOARD_METRICS.registrations]: 1,
  [DASHBOARD_METRICS.demoAccounts]: 2,
  [DASHBOARD_METRICS.liveAccounts]: 3,
  [DASHBOARD_METRICS.fundedAccounts]: 4,
  [DASHBOARD_METRICS.tradedAccounts]: 5,
  [DASHBOARD_METRICS.depositsTotal]: 6,
  [DASHBOARD_METRICS.withdrawalsTotal]: 7,
  [DASHBOARD_METRICS.netDeposits]: 8,
  [DASHBOARD_METRICS.volumeTraded]: 9,
  [DASHBOARD_METRICS.volumeForex]: 10,
  [DASHBOARD_METRICS.volumeCryptos]: 11,
  [DASHBOARD_METRICS.volumeIndices]: 12,
  [DASHBOARD_METRICS.conversionRate]: 13,
  [DASHBOARD_METRICS.activeUsers]: 14,
  [DASHBOARD_METRICS.withCxdUsers]: 15,
  [DASHBOARD_METRICS.withoutCxdUsers]: 16,
  [DASHBOARD_METRICS.rejectedAccounts]: 17,
  [DASHBOARD_METRICS.mt4Accounts]: 18,
  [DASHBOARD_METRICS.mt5Accounts]: 19,
  [DASHBOARD_METRICS.ctraderAccounts]: 20,
  [DASHBOARD_METRICS.tradingViewAccounts]: 21,
  [DASHBOARD_METRICS.leadOwnerFilter]: 22,
};

export const DASHBOARD_OVERVIEW_DATA_LABEL = {
  [DASHBOARD_METRICS.registrations]: 'Registrations',
  [DASHBOARD_METRICS.demoAccounts]: 'Demo Accounts',
  [DASHBOARD_METRICS.liveAccounts]: 'Live Users',
  [DASHBOARD_METRICS.fundedAccounts]: 'Funded Accounts',
  [DASHBOARD_METRICS.tradedAccounts]: 'Traded Accounts',
  [DASHBOARD_METRICS.depositsTotal]: 'Deposits (total)',
  [DASHBOARD_METRICS.withdrawalsTotal]: 'Withdrawals (total)',
  [DASHBOARD_METRICS.netDeposits]: 'Net Deposits',
  [DASHBOARD_METRICS.volumeTraded]: 'Volume Traded',
  [DASHBOARD_METRICS.volumeForex]: 'Volume Forex',
  [DASHBOARD_METRICS.volumeCryptos]: 'Volume Cryptos',
  [DASHBOARD_METRICS.volumeIndices]: 'Volume Indices',
  [DASHBOARD_METRICS.conversionRate]: 'Conversion of total leads to traded',
  [DASHBOARD_METRICS.activeUsers]: 'Active Users',
  [DASHBOARD_METRICS.withCxdUsers]: 'Referred',
  [DASHBOARD_METRICS.withoutCxdUsers]: 'Direct',
  [DASHBOARD_METRICS.rejectedAccounts]: 'Rejected Accounts %',
  [DASHBOARD_METRICS.mt4Accounts]: 'Live Users With MT4 Accounts',
  [DASHBOARD_METRICS.mt5Accounts]: 'Live Users With MT5 Accounts',
  [DASHBOARD_METRICS.ctraderAccounts]: 'Live Users With cTrader Accounts',
  [DASHBOARD_METRICS.tradingViewAccounts]: 'Live Users With TradingView Accounts',
  [DASHBOARD_METRICS.leadOwnerFilter]: 'Lead Owner',
};

export const CURRENCY_FORMAT_METRICS = [
  DASHBOARD_METRICS.depositsTotal,
  DASHBOARD_METRICS.withdrawalsTotal,
  DASHBOARD_METRICS.netDeposits,
  DASHBOARD_METRICS.volumeTraded,
];

export const CUSTOM_DATE_RANGE_KEY = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  LAST_7_DAYS: 'last7Days',
  LAST_30_DAYS: 'last30Days',
  WEEK_TO_DATE: 'weekToDate',
  MONTH_TO_DATE: 'monthToDate',
  QUARTER_TO_DATE: 'quarterToDate',
  YEAR_TO_DATE: 'yearToDate',
  PREVIOUS_WEEK: 'previousWeek',
  PREVIOUS_MONTH: 'previousMonth',
  PREVIOUS_QUARTER: 'previousQuarter',
};

export const CUSTOM_DATE_RANGE_LABEL = {
  [CUSTOM_DATE_RANGE_KEY.TODAY]: 'Today',
  [CUSTOM_DATE_RANGE_KEY.YESTERDAY]: 'Yesterday',
  [CUSTOM_DATE_RANGE_KEY.LAST_7_DAYS]: 'Last 7 Days',
  [CUSTOM_DATE_RANGE_KEY.LAST_30_DAYS]: 'Last 30 Days',
  [CUSTOM_DATE_RANGE_KEY.WEEK_TO_DATE]: 'Week to Date',
  [CUSTOM_DATE_RANGE_KEY.MONTH_TO_DATE]: 'Month to Date',
  [CUSTOM_DATE_RANGE_KEY.QUARTER_TO_DATE]: 'Quarter to Date',
  [CUSTOM_DATE_RANGE_KEY.YEAR_TO_DATE]: 'Year to Date',
  [CUSTOM_DATE_RANGE_KEY.PREVIOUS_WEEK]: 'Previous Week',
  [CUSTOM_DATE_RANGE_KEY.PREVIOUS_MONTH]: 'Previous Month',
  [CUSTOM_DATE_RANGE_KEY.PREVIOUS_QUARTER]: 'Previous Quarter',
};

export const CUSTOM_DATE_RANGE_SELECTOR = [
  {
    label: CUSTOM_DATE_RANGE_LABEL[CUSTOM_DATE_RANGE_KEY.TODAY],
    value: CUSTOM_DATE_RANGE_KEY.TODAY,
  },
  {
    label: CUSTOM_DATE_RANGE_LABEL[CUSTOM_DATE_RANGE_KEY.YESTERDAY],
    value: CUSTOM_DATE_RANGE_KEY.YESTERDAY,
  },
  {
    label: CUSTOM_DATE_RANGE_LABEL[CUSTOM_DATE_RANGE_KEY.LAST_7_DAYS],
    value: CUSTOM_DATE_RANGE_KEY.LAST_7_DAYS,
  },
  {
    label: CUSTOM_DATE_RANGE_LABEL[CUSTOM_DATE_RANGE_KEY.LAST_30_DAYS],
    value: CUSTOM_DATE_RANGE_KEY.LAST_30_DAYS,
  },
  {
    label: CUSTOM_DATE_RANGE_LABEL[CUSTOM_DATE_RANGE_KEY.WEEK_TO_DATE],
    value: CUSTOM_DATE_RANGE_KEY.WEEK_TO_DATE,
  },
  {
    label: CUSTOM_DATE_RANGE_LABEL[CUSTOM_DATE_RANGE_KEY.MONTH_TO_DATE],
    value: CUSTOM_DATE_RANGE_KEY.MONTH_TO_DATE,
  },
  {
    label: CUSTOM_DATE_RANGE_LABEL[CUSTOM_DATE_RANGE_KEY.QUARTER_TO_DATE],
    value: CUSTOM_DATE_RANGE_KEY.QUARTER_TO_DATE,
  },
  {
    label: CUSTOM_DATE_RANGE_LABEL[CUSTOM_DATE_RANGE_KEY.YEAR_TO_DATE],
    value: CUSTOM_DATE_RANGE_KEY.YEAR_TO_DATE,
  },
  {
    label: CUSTOM_DATE_RANGE_LABEL[CUSTOM_DATE_RANGE_KEY.PREVIOUS_WEEK],
    value: CUSTOM_DATE_RANGE_KEY.PREVIOUS_WEEK,
  },
  {
    label: CUSTOM_DATE_RANGE_LABEL[CUSTOM_DATE_RANGE_KEY.PREVIOUS_MONTH],
    value: CUSTOM_DATE_RANGE_KEY.PREVIOUS_MONTH,
  },
  {
    label: CUSTOM_DATE_RANGE_LABEL[CUSTOM_DATE_RANGE_KEY.PREVIOUS_QUARTER],
    value: CUSTOM_DATE_RANGE_KEY.PREVIOUS_QUARTER,
  },
];

export const ACCOUNT_COUNTRY_FILTER = [
  { value: '', label: 'All Countries' },
  {
    value: ['Canada', 'Singapore', 'United Kingdom of Great Britain and Northern Ireland'],
    label: 'CA + SG + GB',
  },
  {
    value: [
      'Canada',
      'Singapore',
      'United Kingdom of Great Britain and Northern Ireland',
      'Malaysia',
    ],
    label: 'CA + MY + SG + GB',
  },
  { value: 'custom', label: 'Custom' },
];

export const ACCOUNT_MARKETING_CHANNEL_TYPES = [
  { value: 'default', label: 'Registrations' },
  { value: 'client', label: 'Live' },
  { value: 'traded', label: 'Traded' },
];

export const MARKETING_GROUP_BY = [
  { value: 'source', label: 'Source' },
  { value: 'medium', label: 'Medium' },
  { value: 'campaign', label: 'Campaign' },
];

export const MARKETING_DATA_EXPORT_MODAL = 'MARKETING_DATA_EXPORT_MODAL';

export const MARKETING_DATA_EXPORT_FORM = 'MARKETING_DATA_EXPORT_FORM';

export const COUNTRY_FIELDS = {
  LIVE: 'live',
  FUNDED: 'funded',
  TRADED: 'traded',
  VOLUME: 'volume',
  REGISTERED: 'registered',
};

export const COUNTRY_FILTER_FORM = 'COUNTRY_FILTER_FORM';

export const COUNTRY_FILTER_MODAL = 'COUNTRY_FILTER_MODAL';

export const EMPLOYE_FILTER = [
  {
    value: '',
    label: 'All Owners',
  },
  {
    value: 'joe.rowland@fusionmarkets.com',
    label: 'Joe Rowland',
  },
  {
    value: 'fabian.capuana@fusionmarkets.com',
    label: 'Fabian Capuana',
  },
  {
    value: 'xavier.herrera@fusionmarkets.com',
    label: 'Xavier Herrera',
  },
  {
    value: 'matthew.gladstone@fusionmarkets.com',
    label: 'Matthew Gladstone',
  },
  {
    value: 'graham.miller@fusionmarkets.com',
    label: 'Graham Miller',
  },
  {
    value: 'ryan.lim@fusionmarkets.com',
    label: 'Ryan Lim',
  },
  {
    value: 'mitchell.sinnatamby@fusionmarkets.com',
    label: 'Mitchell Sinnatamby',
  },
  {
    value: 'arno.venter@fusionmarkets.com',
    label: 'Arno Venter',
  },
];

// errors on mfa incorrect, required mfa and force password
export const listErrorsNotNotification = ['460', '442', '446'];

export const DEFAULT_METRICS_DATES = {
  dateFrom: fmDate()
    .startOf('month')
    .toISOString(),
  dateTo: fmDate()
    .endOf('day')
    .toISOString(),
  compareDateFrom: fmDate()
    .subtract(1, 'month')
    .startOf('month')
    .toISOString(),
  compareDateTo: fmDate()
    .subtract(1, 'month')
    .endOf('day')
    .toISOString(),
};

export const NEW_DASHBOARD_ELIGIBLE_TESTERS = [
  'amal.jose@fusionmarkets.com',
  'matthew.gladstone@fusionmarkets.com',
  'admin@example.com',
];

export const INITIAL_VOLUMES_FILTERS_VALUES = {
  customDateSelector: CUSTOM_DATE_RANGE_KEY.MONTH_TO_DATE,
  dateFrom: fmDate()
    .startOf('month')
    .toISOString(),
  dateTo: fmDate().toISOString(),
  size: 10,
};

export const VOLUMES_ADDITIONAL_FIELDS = [
  {
    name: 'size',
    placeholder: 'Size',
    clearable: false,
    component: TPSelect,
    options: [
      {
        label: 'Top 10',
        value: 10,
      },
      {
        label: 'Top 20',
        value: 20,
      },
      {
        label: 'Top 30',
        value: 30,
      },
      {
        label: 'Top 50',
        value: 50,
      },
      {
        label: 'Top 100',
        value: 100,
      },
    ],
    width: '155px',
  },
];
