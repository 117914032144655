import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TPErrorMessage from 'components/TP-UI/TPErrorMessage';
import TPInputAdornment from 'components/TP-UI/TPInputAdornment';
import TPIconButton from 'components/TP-UI/TPIconButton';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import TPWarningMessage from 'components/TP-UI/TPWarningMessage';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

const TPTextFieldBase = ({
  variant = 'outlined',
  name,
  label,
  value = '',
  type = 'text',
  placeholder = '',
  hint,
  confidential = false,
  multiline = false,
  minRows,
  maxRows,
  hideDetails = false,
  error = null,
  required,
  disabled,
  clearable,
  readonly = false,
  autocomplete,
  autofocus = false,
  fullWidth,
  size = 'medium',
  className,
  reservedErrorSpace = true,
  startAdornment,
  endAdornment,
  onChange,
  onBlur,
  onFocus,
  onClick,
  onKeyDown,
  onWheel,
  inputRef,
  inputProps,
  InputProps,
  forwardedRef,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const [showContent, setShowContent] = useState(!confidential);
  const [showCapsLockWarningMessage, setShowCapsLockWarningMessage] = useState(false);

  useEffect(() => {
    if (confidential) {
      setShowContent(false);
    }
  }, [confidential]);

  const handleChange = useCallback(
    (event) => {
      if (onChange) {
        onChange(event.target.value, event);
      }
    },
    [onChange],
  );

  const handleClickShowSensitive = useCallback(() => {
    if (confidential) {
      setShowContent(!showContent);
    }
  }, [confidential, showContent]);

  const handleClearClick = useCallback(
    (event) => {
      event.stopPropagation();
      if (value && onChange) {
        event.target.value = '';
        onChange('', event);
      }
    },
    [value, onChange],
  );

  const handleMouseDown = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleKeyUp = useCallback((event) => {
    if (event.getModifierState('CapsLock')) {
      setShowCapsLockWarningMessage(true);
    } else {
      setShowCapsLockWarningMessage(false);
    }
  }, []);

  const handleBlur = useCallback(() => {
    if (showCapsLockWarningMessage) {
      window.addEventListener('keyup', handleKeyUp);
    }
    if (onBlur) {
      onBlur();
    }
  }, [showCapsLockWarningMessage, handleKeyUp, onBlur]);

  const handleWheel = useCallback(
    (e) => {
      if (onWheel) {
        onWheel(e);
      } else if (type === 'number') {
        e.target.blur();
        return false;
      }
    },
    [onWheel, type],
  );

  useEffect(() => {
    if (!showCapsLockWarningMessage) {
      window.removeEventListener('keyup', handleKeyUp);
    }

    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [showCapsLockWarningMessage, handleKeyUp]);

  const inputType = !showContent ? 'password' : confidential ? 'text' : type;

  const additionalProps = {};
  if (confidential) {
    additionalProps.onKeyUp = handleKeyUp;
  }
  if (confidential || onBlur) {
    additionalProps.onBlur = handleBlur;
  }

  return (
    <FormControl
      variant={variant}
      required={required}
      disabled={disabled}
      fullWidth={fullWidth}
      autoFocus={autofocus}
      className={className}>
      <TextField
        ref={forwardedRef}
        inputRef={inputRef}
        id={name}
        type={inputType}
        variant={variant}
        required={required}
        disabled={disabled}
        multiline={multiline}
        error={!!error}
        minRows={minRows}
        maxRows={maxRows}
        value={value}
        placeholder={placeholder}
        fullWidth={fullWidth}
        onChange={handleChange}
        onFocus={onFocus}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onWheel={handleWheel}
        label={label ? label : undefined}
        autoFocus={autofocus}
        autoComplete={autocomplete}
        InputLabelProps={{
          shrink: true,
        }}
        size={size}
        inputProps={inputProps}
        InputProps={{
          ...InputProps,
          readOnly: readonly,
          className: classnames({
            [classes.password]: inputType === 'password',
            [classes.number]: inputType === 'number',
          }),
          startAdornment,
          endAdornment:
            confidential || clearable || endAdornment ? (
              <TPInputAdornment
                position="end"
                className={classnames({ [classes.multilineAdornment]: multiline })}>
                {clearable && value ? (
                  <TPIconButton
                    size="xsmall"
                    onMouseDown={handleMouseDown}
                    onTouchStart={handleMouseDown}
                    onClick={handleClearClick}>
                    <CloseIcon fontSize="small" />
                  </TPIconButton>
                ) : null}
                {confidential ? (
                  <TPIconButton
                    aria-label="toggle confidential visibility"
                    onClick={handleClickShowSensitive}
                    onMouseDown={handleMouseDown}
                    size="medium">
                    {showContent ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                  </TPIconButton>
                ) : null}
                {endAdornment}
              </TPInputAdornment>
            ) : null,
        }}
        {...additionalProps}
      />
      {!hideDetails ? (
        <>
          {hint && <FormHelperText component="div">{hint}</FormHelperText>}
          <div className={classnames({ [classes.errorContainer]: reservedErrorSpace })}>
            {showCapsLockWarningMessage && (
              <TPWarningMessage warning={t('errors.form.capsLockIsOn')} size="small" />
            )}
            {error && <TPErrorMessage error={error} size="small" />}
          </div>
        </>
      ) : null}
    </FormControl>
  );
};

TPTextFieldBase.muiName = Input.muiName;
TPTextFieldBase.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  type: PropTypes.oneOf(['text', 'password', 'number', 'tel', 'email']),
  /**
   * when content is confidential (confidential) and displayed like password with
   * ability to view it clicking on 'eye' icon
   */
  confidential: PropTypes.bool,
  placeholder: PropTypes.string,
  /**
   * Indicates that the error and hint should not be shown
   */
  hideDetails: PropTypes.bool,
  hint: PropTypes.node,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  multiline: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  readonly: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'small']),
  autocomplete: PropTypes.string,
  autofocus: PropTypes.bool,
  /**
   * Maximum number of rows to display when multiline option is set to true.
   */
  maxRows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Minimum number of rows to display when multiline option is set to true.
   */
  minRows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  renderValue: PropTypes.func,
  fullWidth: PropTypes.bool,
  /**
   * Reserved space to display error in 1 line
   */
  reservedErrorSpace: PropTypes.bool,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  /**
   * Props that will be applied to the input element
   */
  inputProps: PropTypes.object,
  InputProps: PropTypes.shape({}),
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
};

export const TPTextField = React.forwardRef((props, ref) => (
  <TPTextFieldBase {...props} forwardedRef={ref} />
));

const TPReduxTextField = ({ input, meta, ...others }) => {
  const error = meta.submitFailed && meta.error ? meta.error : null;
  const { onChange, onBlur, value } = input;
  const handleBlur = useCallback(() => onBlur(value), [onBlur, value]);
  return (
    <TPTextField {...input} error={error} {...others} onChange={onChange} onBlur={handleBlur} />
  );
};

export default TPReduxTextField;
