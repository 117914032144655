import { handleActions } from 'redux-actions';
import get from 'lodash/get';

import {
  getReferralEarningsFail,
  getReferralEarningsRequest,
  getReferralEarningsSuccess,
} from '../actions';

const defaultState = null;

export default handleActions(
  {
    [getReferralEarningsRequest]: () => '',
    [getReferralEarningsSuccess]: (_state, action) => get(action, 'response.data.earnings', ''),
    [getReferralEarningsFail]: () => '',
  },
  defaultState,
);
