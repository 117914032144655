export default (amount, currency = 'USD', minDigits = 0) => {
  // to prevent application crash
  if (currency === '') {
    currency = 'USD';
  }
  if (!amount) {
    amount = 0;
  }
  let formattedAmount;
  if (Intl) {
    const formatter = new Intl.NumberFormat('en-US', {
      currency,
      style: 'currency',
      minimumFractionDigits: minDigits,
    });
    formattedAmount = formatter.format(amount);

    if (/^[A-Z]{3}\s/.test(formattedAmount)) {
      formattedAmount = formattedAmount.replace(/^([A-Z]{3})\s+/, '$1 ');
    } else {
      formattedAmount = formattedAmount.replace(/^(\D+)/, '$1 ');
    }
  } else {
    formattedAmount = Number(amount)
      .toLocaleString('en-US', {
        currency,
        style: 'currency',
      })
      .replace(/^(\D+)/, '$1 ');
  }
  return formattedAmount;
};
