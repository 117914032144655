import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    container: {
      width: 'auto',
      margin: 'auto',
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
    },
    image: {
      width: '100%',
      maxWidth: '377px',
      maxHeight: '305px',
    },
  }),
  { name: 'ErrorBoundary' },
);
