import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = { items: [] };

export default handleActions(
  {
    [actionCreators.getUserEmailsSuccess](state, action) {
      return {
        ...state,
        items: action.response.data.userEmails,
        totalNumber: action.response.data.totalNumber,
      };
    },

    [actionCreators.getUserEmailsFail]() {
      return defaultState;
    },
  },
  defaultState,
);
