import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    spacing: {
      margin: theme.spacing(1),
    },
    headerWithIcon: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      padding: `${theme.spacing(1)}px 0`,
      borderBottom: `1px solid ${theme.palette.primary.lightest}`,
    },
    lastMenuItem: {
      width: '100%',
      borderTop: `1px solid ${theme.palette.primary.lightest}`,
      padding: `${theme.spacing(1.25)}px 0`,
      margin: `0 ${theme.spacing(2)}px`,
    },
    select: {
      width: '100%',
      padding: `${theme.spacing(0.5)}px ${theme.spacing(4)}px 
        ${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    },
    selectIcon: {
      right: theme.spacing(1),
    },
  }),
  { name: 'Menus' },
);

export default useStyles;
