import { handleActions } from 'redux-actions';
import * as actions from '../actions';

const defaultState = {
  items: [],
  totalNumbers: 1,
};

export default handleActions(
  {
    [actions.getRefCodesSuccess]: (state, action) => {
      return {
        ...state,
        items: action.response.data.data,
        totalNumber: action.response.data.totalNumber,
      };
    },
  },
  defaultState,
);
