import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { isMobile, isTablet } from 'react-device-detect';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import TPErrorMessage from '../TPErrorMessage';
import TPTypography from '../TPTypography';
import TPButton from '../TPButton';
import { getTranslation } from 'utils/compositeTranslationHandler';
import formatBytes from 'utils/formatBytes';
import formatFileTypes from 'utils/formatFileTypes';
import TPTakePhotoModal from './components/TPTakePhotoModal';
import { ACCEPT_FILE_UPLOAD_TYPES } from './constants';

import useStyles from './styles';

const TPFileUploader = ({
  name,
  value,
  placeholder,
  acceptTypes,
  acceptTypesRestrictionMessage,
  onUpload,
  error,
  reservedErrorSpace = true,
  fullWidth,
  className,
  maxFiles,
  maxFilesRestrictionMessage,
  maxSize,
  maxSizeRestrictionMessage,
  disabled,
  autofocus,
  webCamEnabled = false,
  uploadFileLabel = 'Upload File',
  takePhotoLabel = 'Take a photo',
  captureButtonLabel,
  cancelButtonLabel,
  okButtonLabel,
  webcamStatusMessages,
  uploaderRef,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const handleDrop = (acF = [], rejF = []) => {
    onUpload([...acF.map((file) => ({ file })), ...rejF]);
  };
  const accept = useMemo(
    () =>
      acceptTypes
        ? acceptTypes.reduce((obj, type) => {
            return { ...obj, ...(ACCEPT_FILE_UPLOAD_TYPES[type] || {}) };
          }, {})
        : undefined,
    [acceptTypes],
  );
  const [takePhotoModalOpened, setTakePhotoModalOpened] = useState(false);
  const handleTakePhotoClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setTakePhotoModalOpened(true);
  }, []);
  const handleTakePhotoModalClose = useCallback(() => {
    setTakePhotoModalOpened(false);
  }, []);
  const handleTakePhoto = useCallback(
    (file) => {
      setTakePhotoModalOpened(false);
      onUpload([{ file }]);
    },
    [onUpload],
  );

  const disabledWidget = disabled || (maxFiles && value ? value.length >= maxFiles : false);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    maxSize,
    acceptClassNam: classes.imageAccepted,
    rejectClassName: classes.imageRejected,
    onDrop: handleDrop,
    accept,
    multiple: true,
    disabled: disabledWidget,
    autoFocus: autofocus,
  });

  useEffect(() => {
    if (open && uploaderRef) {
      uploaderRef.current = { open };
    }
  }, [uploaderRef, open]);

  return (
    <div className={className}>
      <div
        id={`file-uploader-dropzone-${name}`}
        name={name}
        className={classNames(
          classes.root,
          { [classes.fullWidth]: fullWidth },
          { [classes.fileAccepted]: isDragActive && isDragAccept },
          { [classes.fileRejected]: isDragActive && isDragReject },
        )}
        {...getRootProps()}>
        <input {...getInputProps()} />
        <TPTypography variant="h5">{placeholder}</TPTypography>
        <div>
          {acceptTypes || maxSize ? (
            <TPTypography variant="body2">
              {acceptTypes
                ? !acceptTypesRestrictionMessage
                  ? `File types: ${formatFileTypes(acceptTypes)}`
                  : typeof acceptTypesRestrictionMessage === 'string'
                  ? acceptTypesRestrictionMessage
                  : getTranslation(t, {
                      ...acceptTypesRestrictionMessage,
                      values: [
                        {
                          ...(acceptTypesRestrictionMessage.values || {}),
                          acceptTypes: formatFileTypes(acceptTypes),
                        },
                      ],
                    })
                : null}
              {acceptTypes && maxSize && '; '}
              {maxSize
                ? !maxSizeRestrictionMessage
                  ? `Maximum size: ${formatBytes(maxSize)} each`
                  : typeof maxSizeRestrictionMessage === 'string'
                  ? maxSizeRestrictionMessage
                  : getTranslation(t, {
                      ...maxSizeRestrictionMessage,
                      values: [
                        {
                          ...(maxSizeRestrictionMessage.values || {}),
                          maxSize: formatBytes(maxSize),
                        },
                      ],
                    })
                : null}
            </TPTypography>
          ) : null}
          {maxFiles ? (
            <TPTypography variant="body2">
              {!maxFilesRestrictionMessage
                ? `Maximum number of files is ${maxFiles}`
                : typeof maxFilesRestrictionMessage === 'string'
                ? maxFilesRestrictionMessage
                : getTranslation(t, {
                    ...maxFilesRestrictionMessage,
                    values: [
                      {
                        ...(maxFilesRestrictionMessage.values || {}),
                        maxFiles: maxFiles,
                      },
                    ],
                  })}
            </TPTypography>
          ) : null}
        </div>
        <div className={classes.buttonsContainer}>
          {webCamEnabled && !isMobile && !isTablet ? (
            <TPButton
              alternative
              size="large"
              onClick={handleTakePhotoClick}
              disabled={disabledWidget}>
              {takePhotoLabel}
            </TPButton>
          ) : null}
          <TPButton primary size="large" fullWidth={isMobile} disabled={disabledWidget}>
            {uploadFileLabel}
          </TPButton>
        </div>
      </div>
      <div className={classnames({ [classes.errorContainer]: reservedErrorSpace })}>
        {error && !Array.isArray(error) && <TPErrorMessage error={error} size="small" />}
      </div>
      {webCamEnabled && !isMobile && !isTablet ? (
        <TPTakePhotoModal
          value={takePhotoModalOpened}
          onTakePhoto={handleTakePhoto}
          onClose={handleTakePhotoModalClose}
          webcamStatusMessages={webcamStatusMessages}
          title={takePhotoLabel}
          captureButtonLabel={captureButtonLabel}
          cancelButtonLabel={cancelButtonLabel}
          okButtonLabel={okButtonLabel}
        />
      ) : null}
    </div>
  );
};

TPFileUploader.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  /**
   * Maximum accepted number of files The default value is 0 which means there is no limitation to how many files are accepted.
   */
  maxFiles: PropTypes.number,
  maxFilesRestrictionMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ key: PropTypes.string }),
  ]),
  /**
   * Maximum file size (in bytes)
   */
  maxSize: PropTypes.number,
  maxSizeRestrictionMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ key: PropTypes.string }),
  ]),
  /**
   * Please view all supported type in ACCEPT_FILE_UPLOAD_TYPES
   */
  acceptTypes: PropTypes.arrayOf(
    PropTypes.oneOf(['CSV', 'PDF', 'PNG', 'JPG', 'JPEG', 'XLS', 'DOC', 'DOCX']),
  ),
  acceptTypesRestrictionMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ key: PropTypes.string }),
  ]),
  placeholder: PropTypes.node,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  disabled: PropTypes.bool,
  autofocus: PropTypes.bool,
  onUpload: PropTypes.func.isRequired,
  /**
   * Reserved space to display error in 1 line
   */
  reservedErrorSpace: PropTypes.bool,
  fullWidth: PropTypes.bool,
  /**
   * Show 'Take a photo' button and all related logic
   */
  webCamEnabled: PropTypes.bool,
  /**
   * Label for File Upload button
   */
  uploadFileLabel: PropTypes.string,
  /**
   * Label for Take a photo button
   */
  takePhotoLabel: PropTypes.string,
  /**
   * Label for Capture button
   */
  captureButtonLabel: PropTypes.string,
  /**
   * Label for Cancel button
   */
  cancelButtonLabel: PropTypes.string,
  /**
   * Label for Got It button
   */
  okButtonLabel: PropTypes.string,
  /**
   * Messages for camera accessibility statuses
   */
  webcamStatusMessages: PropTypes.shape({
    denied: PropTypes.string,
    granted: PropTypes.string,
    wait: PropTypes.string,
    noCamera: PropTypes.string,
  }),
};

//Supports Field and FieldArray
//Note: If Uploader is used with TPFileList Please use the same redux component: Field or FieldArray and provide the same validation rules
const TPReduxFileUploader = ({ input, fields, meta, onUpload, ...others }) => {
  const error = meta.submitFailed && meta.error ? meta.error : null;
  const name = input?.name || fields?.name || others?.id || others?.name;
  const handleUpload = useCallback(
    (files) => {
      if (fields) {
        files.forEach((item) => {
          fields.push(item);
        });
      }
      if (input) {
        input.onChange([...(input?.value || []), ...files]);
      }
      if (onUpload) {
        onUpload(files);
      }
    },
    [onUpload, input, fields],
  );
  return (
    <TPFileUploader
      value={input?.value || fields?.getAll()}
      error={error}
      name={name}
      {...others}
      onUpload={handleUpload}
    />
  );
};

export default TPReduxFileUploader;
