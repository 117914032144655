import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = {
  questions: [],
  currentQuestion: 0,
  progressIndex: 0,
};

const quizReducerV2 = handleActions(
  {
    [actionCreators.getQuizSuccess]: (state, { response }) => ({
      ...state,
      questions: response.data,
      currentQuestion: 0,
    }),
    [actionCreators.changeQuizCurrentQuestion]: (state, { payload }) => ({
      ...state,
      currentQuestion: payload,
    }),
    [actionCreators.changeQuizProgress]: (state, { payload }) => ({
      ...state,
      progressIndex: payload,
    }),
    [actionCreators.resetQuizProgress]: (state) => ({
      ...state,
      currentQuestion: defaultState.currentQuestion,
      progressIndex: defaultState.progressIndex,
    }),
    [actionCreators.passQuizSuccess]: (state, { response }) => ({
      ...state,
      quizResult: response.data,
    }),
    [actionCreators.cleanUpQuizQuestions]: () => defaultState,
  },
  defaultState,
);

export default quizReducerV2;
