import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
    },
    disabled: {
      ...theme.thirdPartyButton.disabled,
    },
  }),
  { name: 'GoogleSignup' },
);

export default useStyles;
