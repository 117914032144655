export const toFixed = (value, precision = 2, defaultValue = 0) => {
  if (typeof value === 'string') {
    value = parseFloat(value);
  }

  if (value == null || isNaN(value) || typeof value !== 'number') {
    value = defaultValue;
  }

  return value.toFixed(precision);
};
