import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    list: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
    },
    item: {},
    errorContainer: {
      minHeight: theme.textError.root.minHeight,
    },
  }),
  { name: 'TPFileList' },
);
