import { isArraysEqual } from './array';

export const getDeepObjectValue = (obj, path) => {
  return obj && path
    ? path.split('.').reduce((o, key) => {
        const val = o && o[key];
        return val === undefined ? null : val;
      }, obj)
    : null;
};

export const hasOnlyEmptyValues = (item) => {
  if (item === undefined || item === null || item === '') {
    return true;
  }

  if (Array.isArray(item)) {
    return !item.length || item.every((value) => hasOnlyEmptyValues(value));
  }

  if (typeof item === 'object') {
    return hasOnlyEmptyValues(Object.values(item));
  }

  return false;
};

const getObjectWithoutEmpty = (obj) => {
  const objWithoutEmptyValues = {};
  for (const [key, value] of Object.entries(obj)) {
    if (!hasOnlyEmptyValues(value)) {
      objWithoutEmptyValues[key] = value;
    }
  }
  return objWithoutEmptyValues;
};

export const isObjectsEqual = (obj1, obj2) => {
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return false;
  }

  const obj1WithValues = getObjectWithoutEmpty(obj1);
  const obj2WithValues = getObjectWithoutEmpty(obj2);

  if (Object.keys(obj1WithValues).length !== Object.keys(obj2WithValues).length) {
    return false;
  }

  for (const [key, value] of Object.entries(obj1WithValues)) {
    const obj2Value = obj2WithValues[key];
    if (
      (Array.isArray(value) && !isArraysEqual(value, obj2Value)) ||
      (typeof value === 'object' && !isObjectsEqual(value, obj2Value)) ||
      value !== obj2Value
    ) {
      return false;
    }
  }

  return true;
};
