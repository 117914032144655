import React from 'react';
import PropTypes from 'prop-types';
import TPTypography from 'components/TP-UI/TPTypography';
import TPGrid from 'components/TP-UI/TPGrid';
import TPIconButton from 'components/TP-UI/TPIconButton';
import TPLink from 'components/TP-UI/TPLink';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

const CookiesBanner = ({ policyLink, onClose }) => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <TPGrid container spacing={2} alignItems="center" justifyContent="center" wrap="nowrap">
        <TPGrid item>
          <TPTypography className={classes.policyText} color="inherit">
            {t('policyBanner.text')}
            <TPLink
              link
              path={policyLink}
              className={classes.policyLinkText}
              target="_blank"
              rel="noopener noreferrer">
              {t('policyBanner.linkText')}
            </TPLink>
          </TPTypography>
        </TPGrid>
        <TPGrid item>
          <TPIconButton className={classes.closeButton} onClick={onClose} aria-label="Close">
            <CloseIcon fontSize="inherit" />
          </TPIconButton>
        </TPGrid>
      </TPGrid>
    </div>
  );
};

CookiesBanner.propTypes = {
  policyLink: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CookiesBanner;
