import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { store } from 'components/App';
import NotificationsPopover from '../components/NotificationsPopover';
import NotificationsContent from '../components/NotificationsContent';
import {
  getUserNotificationsCountRequest,
  getUserNotificationsRequest,
  markAllAsReadRequest,
  markAsReadRequest,
} from '../actions';
import {
  getNotificationItems,
  getNotificationUnreadItemsCount,
  getUserNotifications,
  getUserNotificationsCount,
  getWhatsNewItems,
  getWhatsNewUnreadItemsCount,
} from '../selectors';
import { createPendingSelector } from 'modules/api/selectors';
import { getUserId } from 'modules/auth/selectors';

const NotificationBellContainer = () => {
  const dispatch = useDispatch();
  const notificationsCount = useSelector(getUserNotificationsCount);
  const notifications = useSelector(getUserNotifications);
  const notificationItems = useSelector(getNotificationItems);
  const whatsNewItems = useSelector(getWhatsNewItems);
  const notificationUnreadItemsCount = useSelector(getNotificationUnreadItemsCount);
  const whatsNewUnreadItemsCount = useSelector(getWhatsNewUnreadItemsCount);
  const userId = useSelector(getUserId);
  const notificationsLoading = useSelector(createPendingSelector(getUserNotificationsRequest));
  const markAsReadLoading = useSelector(createPendingSelector(markAsReadRequest));
  const markAllAsReadLoading = useSelector(createPendingSelector(markAllAsReadRequest));

  useEffect(() => {
    import('../sagas/userNotificationsSaga').then((saga) => {
      store.injectSaga('UserNotificationsSaga', saga.default);
    });

    return () => {
      store.injectSaga('UserNotificationsSaga', null);
    };
  }, [dispatch]);

  useEffect(() => {
    if (userId) {
      dispatch(getUserNotificationsCountRequest(userId));
    }
  }, [dispatch, userId]);

  const handleNotificationsOpen = useCallback(
    () => dispatch(getUserNotificationsRequest({ userId })),
    [dispatch, userId],
  );

  const handleMarkAsRead = useCallback(
    (id) => {
      dispatch(markAsReadRequest({ userId, id }));
    },
    [dispatch, userId],
  );

  const handleMarkAllAsRead = useCallback(() => {
    const ids = notifications.filter((i) => !i.viewed).map((notification) => notification._id);
    dispatch(markAllAsReadRequest({ userId, ids }));
  }, [dispatch, userId, notifications]);

  const contentProps = {
    onMarkAsRead: handleMarkAsRead,
    onMarkAllAsRead: handleMarkAllAsRead,
    loading: notificationsLoading,
    markAsReadLoading,
    markAllAsReadLoading,
    notificationItems,
    whatsNewItems,
    notificationUnreadItemsCount,
    whatsNewUnreadItemsCount,
  };

  return (
    <NotificationsPopover
      onClick={handleNotificationsOpen}
      notificationsCount={notificationsCount}
      content={<NotificationsContent {...contentProps} />}
    />
  );
};

export default NotificationBellContainer;
