import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    form: {
      width: '100%',
    },
    control: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 2,
    },
    flagIcon: {
      width: 36,
      height: 18,
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'Selects' },
);

export default useStyles;
