const styles = (theme) => ({
  DatePicker: {
    border: '1px solid #DADFEC',
    borderRadius: '4px',
    color: '#36425A',
    lineHeight: '20px',
    fontSize: '16px',
    '&:focus': {
      border: '1px solid #74C3C4',
      outline: 'none',
    },
  },
  DatePickerWrapper: {
    height: '100%',
    '& .react-datepicker': {
      fontFamily: 'Lato, sans-serif',
    },
    '& > div.react-datepicker-wrapper': {
      height: '100%',
    },
    '& .react-datepicker__input-container': {
      height: '100%',
    },
    '& .react-datepicker__input-container input': {
      height: '100%',
      fontSize: '14px',
      width: '140px',
      fontFamily: 'Lato, sans-serif',
    },
    '& .react-datepicker__input-container input::placeholder': {
      color: '#ccd2de',
    },
    '& .react-datepicker__day--today': {
      backgroundColor: 'transparent',
      border: '1px solid #74C3C4',
    },
    '& .react-datepicker__day--in-selecting-range': {
      backgroundColor: '#9bcfd2',
    },
    '& .react-datepicker__day--keyboard-selected': {
      backgroundColor: '#74C3C4',
    },
    '& .react-datepicker__day--selected': {
      backgroundColor: '#74C3C4',
    },
    '& .react-datepicker__day--in-range': {
      backgroundColor: '#74C3C4',
    },
    '& .react-datepicker__close-icon::after': {
      backgroundColor: '#74C3C4',
      fontFamily: 'Lato, sans-serif',
      fontWeight: 'bold',
    },
  },
  textField: {
    '&:hover:not($disabled):not($focused):not($textFieldError) $notchedOutline': {
      borderColor: '#74C3C4 !important',
      borderWidth: '1px',
    },
    '&$textFieldFocused $notchedOutline': {
      borderColor: '#74C3C4 !important',
      borderWidth: '1px',
    },
    '&:hover:not($disabled):not($focused):not($error) $dropDownIcon': {
      color: '#327778',
    },
    '&$textFieldFocused $dropDownIcon': {
      color: '#327778',
    },
    '&$textFieldError $notchedOutline': {
      borderColor: `${theme.palette.error.main} !important`,
    },
    height: '48px',
    color: '#36425A',
    lineHeight: '20px',
    fontSize: '16px',
    fontFamily: 'Lato, sans-serif',
    textAlign: 'left',
  },
  DatePickerCustom: {
    height: 'min-content',
    position: 'relative',
    '& > div.react-datepicker-wrapper': {
      height: 'min-content',
    },
    '& .react-datepicker__input-container': {
      height: 'min-content',
    },
    '& .react-datepicker__input-container input': {
      height: '48px',
      fontSize: '16px',
      width: '200px',
      fontFamily: 'Lato, sans-serif',
      paddingLeft: '12px',
    },
    '@media (max-width:1280px)': {
      '& > div.react-datepicker-wrapper': {
        width: '100%',
      },
      '& .react-datepicker__input-container input': {
        width: '100%',
      },
    },
  },
  customLabel: {
    position: 'relative',
    top: '-26px',
    fontSize: '12px',
    right: '190px',
    color: '#74C3C4',
    fontFamily: 'Lato, sans-serif',
    backgroundColor: 'white',
    padding: '0 4px',
    '&$labelFocused': {
      color: '#74C3C4',
    },
    '&$labelError': {
      color: `${theme.palette.error.main} !important`,
    },
    '@media (max-width:1280px)': {
      top: '-58px',
      right: '-10px',
    },
  },
  errorSelect: {
    ...theme.textError.root,
    bottom: -20,
    maxHeight: 11,
    '@media (max-width:1280px)': {
      bottom: 0,
    },
  },
  errorIcon: {
    fontSize: 12,
  },
  disabled: {},
  focused: {},
  textFieldError: {
    borderColor: theme.palette.error.main,
  },
  notchedOutline: {},
  textFieldFocused: {},
  dropDownIcon: {},
  labelError: {
    color: theme.palette.error.main,
  },
  labelFocused: {},
  error: {},
});

export default styles;
