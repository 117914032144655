import { handleActions, combineActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = {};

export default handleActions(
  {
    [combineActions(actionCreators.getUserSuccess, actionCreators.updateUserDetailsSuccess)](
      state,
      action,
    ) {
      return action.response.data;
    },
    [combineActions(actionCreators.getUserFail)]() {
      return defaultState;
    },
    [actionCreators.getUserPhoneNumberSuccess](state, action) {
      return {
        ...state,
        profile: { ...state.profile, phoneNumber: action.response.data.phoneNumber },
      };
    },
    [actionCreators.editUserTradingStatus](state, action) {
      return {
        ...state,
        tradingStatus: action.payload.response?.data?.tradingStatus,
      };
    },
  },
  defaultState,
);
