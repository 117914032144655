import { createAction } from 'redux-actions';

export const postJoinWaitlistRequest = createAction('POST_JOIN_WAITLIST_REQUEST');
export const postJoinWaitlistSuccess = createAction('POST_JOIN_WAITLIST_SUCCESS');
export const postJoinWaitlistFail = createAction('POST_JOIN_WAITLIST_FAIL');

export const postDay1TransferRequest = createAction('POST_DAY1_TRANSFER_REQUEST');
export const postDay1TransferSuccess = createAction('POST_DAY1_TRANSFER_SUCCESS');
export const postDay1TransferFail = createAction('POST_DAY1_TRANSFER_FAIL');

export const getDay1TransferredUserRequest = createAction('GET_DAY1_TRANSFERRED_USER_REQUEST');
export const getDay1TransferredUserSuccess = createAction('GET_DAY1_TRANSFERRED_USER_SUCCESS');
export const getDay1TransferredUserFail = createAction('GET_DAY1_TRANSFERRED_USER_FAIL');
