import { isAuHub } from 'modules/auth/helpers/hubs';
import {
  LEVERAGES,
  CURRENCY,
  TRADING_PLATFORMS,
  TRADING_SERVERS,
  TRADING_PLATFORMS_VIEW,
  TRADING_DEMO_SERVERS,
  TRADING_SERVERS_AU,
  TRADING_DEMO_SERVERS_AU,
} from '../constants';
import { getAccountTypes, getSecretQuestions } from '../helpers';
import { QuizStatuses } from 'constants/quizStatus';

export const cardNumber = (value) =>
  value && value.replace(/\d{4}/g, (match) => match + ' ').trim();

export const formatLeverage = (value) => {
  const leverage = LEVERAGES.find((leverage) => leverage.value === value);
  return leverage ? leverage.label : null;
};

export const formatCurrency = (value) => {
  const currency = Object.values(CURRENCY).find((currency) => currency.value === value);
  return currency ? currency.label : null;
};

export const formatSecretQuestion = (value) => {
  const question = getSecretQuestions().find((question) => question.value === value);
  return question ? `${question.label}?` : null;
};

export const formatAccountType = (value) => {
  const accountType = getAccountTypes().find((type) => type.value === value);
  return accountType ? accountType.label : null;
};

export const formatTradingPlatform = (value) => {
  const tradingPlatform = TRADING_PLATFORMS.find((platform) => platform.value === value);
  return tradingPlatform ? tradingPlatform.label : null;
};

export const formatTradingPlatformView = (value) => {
  const tradingPlatform = TRADING_PLATFORMS_VIEW.find((platform) => platform.value === value);
  return tradingPlatform ? tradingPlatform.label : null;
};

export const formatTradingServer = (value, hub) => {
  const SERVERS = isAuHub(hub) ? TRADING_SERVERS_AU : TRADING_SERVERS;
  const tradingserver = SERVERS.find((platform) => platform.value === value);
  return tradingserver ? tradingserver.label : null;
};

export const formatDemoTradingServer = (value, hub) => {
  const SERVERS = isAuHub(hub) ? TRADING_DEMO_SERVERS_AU : TRADING_DEMO_SERVERS;
  const tradingserver = SERVERS.find((platform) => platform.value === value);
  return tradingserver ? tradingserver.label : null;
};

export const formatSuffixOfNumber = (number) => {
  const ones = number % 10,
    tens = number % 100;
  if (ones === 1 && tens !== 11) {
    return number + 'st';
  } else if (ones === 2 && tens !== 12) {
    return number + 'nd';
  } else if (ones === 3 && tens !== 13) {
    return number + 'rd';
  }
  return number + 'th';
};

export const trimArray = (array) => {
  return array.map((item) => {
    const keys = Object.keys(item);
    const result = {};
    keys.forEach((key) => {
      result[key] = item[key] && item[key].trim ? item[key].trim() : item[key];
    });
    return result;
  });
};

export const formatNewQuizProgress = (quizProgress) => {
  if (!quizProgress) return '';

  if (!quizProgress.isPassed) {
    if (quizProgress.attempts === 0) {
      return QuizStatuses.HAS_NOT_TRIED;
    }

    if (quizProgress.attempts === 1) {
      return QuizStatuses.ATTEMPT1;
    }

    return QuizStatuses.ATTEMPT2;
  }

  return QuizStatuses.PASSED;
};
