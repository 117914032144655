import palette from './palette';
import { fontSize, fontWeight } from '../font';

const OverridesMuiMenuItem = {
  root: {
    '&:focus:not($selected):not(&:hover)': {
      backgroundColor: palette.neutral.lightest,
    },
    '&:hover': {
      backgroundColor: palette.primary.lightest,
    },
    color: palette.text.primary,
    fontSize: fontSize._14,
    fontWeight: fontWeight.regular,
    height: 'auto',
    minHeight: 'auto',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
};

export default OverridesMuiMenuItem;
