import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    row: {
      placeItems: 'center',
    },
    primaryContainer: {
      width: '20%',
      placeItems: 'center',
    },
    text: {
      textAlign: 'center',
      marginBottom: theme.spacing(1),
    },
    secondaryContainer: {
      display: 'flex',
      height: 'fit-content',
    },
    tertiaryContainer: {
      display: 'flex',
    },
    divider: {
      bottom: '20%',
      left: '50%',
      backgroundColor: 'red',
      height: '2px',
    },
  }),
  { name: 'Dividers' },
);

export default useStyles;
