import common from './common';
import palette from './palette';
import { fontWeight, fontSize } from '../font';
import { colors } from './colors';

const OverridesMuiButton = {
  root: {
    borderRadius: common.formControl.root.borderRadius,
    padding: '7px 16px 8px',
    boxShadow: 'none',
    fontWeight: fontWeight.regular,
    textTransform: 'uppercase',
    '&:hover, &.Mui-focusVisible, &:active': {
      boxShadow: 'none',
    },
    '&$disabled': {
      opacity: '0.5',
    },
  },
  textPrimary: {
    padding: 0,
    fontWeight: fontWeight.bold,
    color: colors.gray.darker,
    '&:hover': {
      color: colors.gray.main,
      backgroundColor: 'transparent',
    },
    '&:active': {
      color: colors.gray.darker,
      backgroundColor: 'transparent',
    },
    '&$disabled': {
      opacity: '0.7',
    },
  },
  containedPrimary: {
    transition: '0.5s',
    backgroundColor: palette.primary.main,
    background: palette.primary.gradient.main,
    '&:hover': {
      background: palette.primary.gradient.light,
    },
    '&:active': {
      background: palette.primary.gradient.dark,
    },
    '&$disabled': {
      opacity: '0.2',
      color: palette.primary.contrastText,
      background: palette.primary.gradient.main,
    },
  },
  contained: {
    boxShadow: 'none',
    '&:hover, &.Mui-focusVisible, &:active': {
      boxShadow: 'none',
    },
    '&$disabled': {
      opacity: '0.2',
    },
  },
  outlinedPrimary: {
    transition: 'none',
    padding: '6px 16px 7px',
    borderColor: palette.primary.main,
    color: palette.primary.main,
    '&:hover': {
      borderColor: palette.primary.light,
      background: `-webkit-${palette.primary.gradient.light}`,
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
      color: palette.primary.gradient.light,
    },
    '&$disabled': {
      borderColor: palette.primary.main,
      color: palette.primary.main,
    },
  },
  sizeSmall: {
    padding: '6px 12px',
    fontSize: fontSize._12,
  },
  sizeLarge: {
    padding: '14px 24px',
    fontSize: fontSize._16,
    lineHeight: 1.25,
    textTransform: 'uppercase',
    fontWeight: fontWeight.regular,
  },
  textSizeLarge: {
    padding: '0 !important',
  },
  textSizeSmall: {
    padding: '0 !important',
  },
};

export default OverridesMuiButton;
