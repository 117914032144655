import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    snackbar: ({ anchorOrigin, offset }) => ({
      marginTop: anchorOrigin.vertical === 'top' ? offset : 0,
      marginBottom: anchorOrigin.vertical === 'bottom' ? offset : 0,
      ...theme.snackbar.wrapper,
    }),
  }),
  { name: 'TPSnackbar' },
);

export default useStyles;
