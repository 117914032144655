import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTheme } from '@material-ui/core';
import Notifications from 'modules/notifications';
import TPLink from 'components/TP-UI/TPLink';
import TPTypography from 'components/TP-UI/TPTypography';
import TPGrid from 'components/TP-UI/TPGrid';
import { useSelector } from 'react-redux';
import { getAllowTranslation } from '../../../geolocation/selectors';
import { useTranslation } from 'react-i18next';
import config from 'config';
import { Routes } from 'constants/routeConstants';
import SegmentTracker from 'modules/common/SegmentTracker';
import { getMarketingSiteByUserHub } from 'modules/auth/selectors';

import useStyles from './styles';

const LanguageToggleContainer = lazy(() =>
  import('modules/common/LanguageToggle/containers/LanguageToggleContainer'),
);

const LayoutNew = ({ title, welcomeTitle, welcomeDescription, children, className }) => {
  const classes = useStyles();
  const { t } = useTranslation('auth');
  const { CURRENT_HUB_LABEL, LOGO, SIGNIN_DISCLAIMER } = config;
  const allowTranslation = useSelector(getAllowTranslation);
  const marketingSiteUrl = useSelector(getMarketingSiteByUserHub);
  const logoRedirectUrl = marketingSiteUrl || {
    pathname: Routes.SIGNIN,
    state: { cleanupStore: true },
  };
  const { themeName } = useTheme();

  return (
    <section className={classNames([classes.root, className])}>
      <SegmentTracker />
      <Notifications />
      <section className={classes.content}>
        <div className={classNames({ [classes.formLayoutWrapper]: SIGNIN_DISCLAIMER })}>
          <div className={classes.formLayout}>
            <div className={classes.formWrapper}>
              {allowTranslation ? (
                <div className={classes.languageContainer}>
                  <Suspense fallback="">
                    <LanguageToggleContainer minimized />
                  </Suspense>
                </div>
              ) : null}
              <TPLink path={logoRedirectUrl} className={classes.logo}>
                <img src={LOGO[themeName].origin} alt={CURRENT_HUB_LABEL} />
              </TPLink>
              <TPTypography variant="h1" align="center">
                {title}
              </TPTypography>
              <div className={classes.form}>{children}</div>
            </div>
            <TPGrid
              container
              direction="column"
              justifyContent="flex-end"
              className={classes.formDescription}>
              <TPGrid item>
                <TPTypography variant="h4">{welcomeTitle}</TPTypography>
              </TPGrid>
              <TPGrid item>
                <TPTypography variant="body1">{welcomeDescription}</TPTypography>
              </TPGrid>
            </TPGrid>
          </div>
        </div>
        {SIGNIN_DISCLAIMER && (
          <div className={classes.disclaimer}>
            {t(SIGNIN_DISCLAIMER)
              .split('\n')
              .map((line, key) => (
                <TPTypography variant="body3" color="secondary" key={key}>
                  {line}
                </TPTypography>
              ))}
          </div>
        )}
      </section>
    </section>
  );
};

LayoutNew.propTypes = {
  title: PropTypes.string,
  welcomeTitle: PropTypes.string,
  welcomeDescription: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

export default LayoutNew;
