import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TPLoader from 'components/TP-UI/TPLoader';
import useStyles from './styles';

const TPTabPanel = ({
  content: Content,
  contentProps = {},
  panelProps = {},
  loading = false,
  selected = false,
  keepOnlySelected = true,
  children,
  ...props
}) => {
  const classes = useStyles();
  const styles = keepOnlySelected
    ? undefined
    : {
        display: selected ? 'block' : 'none',
      };

  return Content || children ? (
    <div role="tabpanel" className={panelProps?.classes?.root} hidden={!selected} {...props}>
      {!keepOnlySelected || selected ? (
        <div
          className={classNames(classes.tabPanelContent, panelProps?.classes?.content)}
          style={styles}>
          {loading ? <TPLoader /> : Content ? <Content {...contentProps} /> : children}
        </div>
      ) : null}
    </div>
  ) : null;
};

TPTabPanel.displayName = 'MuiTabPanel';
TPTabPanel.propTypes = {
  content: PropTypes.elementType,
  contentProps: PropTypes.object,
  panelProps: PropTypes.object,
  selected: PropTypes.bool,
  loading: PropTypes.bool,
  // The flag is used to render the contents of the tabs.
  // If true, then only the content of the selected tab is rendered. If false, then the contents of all tabs are rendered. By default, true
  keepOnlySelected: PropTypes.bool,
};

export default TPTabPanel;
