import React, { useEffect, useMemo, useRef, useState } from 'react';
import TPGrid from 'components/TP-UI/TPGrid';
import TPTypography from 'components/TP-UI/TPTypography';
import TPButton from 'components/TP-UI/TPButton';
import { formatCurrency } from 'utils/formatters';
import TPTextField from 'components/TP-UI/TPTextField';
import { Field, reduxForm } from 'redux-form';
import TPCheckbox from 'components/TP-UI/TPCheckbox';
import Table from 'modules/common/Table';

const TRANSACTIONS_COLUMNS = [
  {
    label: 'Transaction Number',
    dataKey: 'number',
    textStyle: 'bold',
    renderItem: (item) => (
      <Field
        component={TPCheckbox}
        name={`transactions.${item.number}.checked`}
        size="small"
        label={item.number}
      />
    ),
  },
  {
    label: 'Permitted Amount',
    dataKey: 'permitted.amount',
    width: '140px',
    renderItem: (item) => `${item.permitted.amount} ${formatCurrency(item.currency)}`,
  },
  {
    label: 'Username',
    width: '120px',
    dataKey: 'username',
  },
  {
    label: 'Withdrawal Amount',
    dataKey: 'withdrawal.country',
    width: '140px',
  },
  {
    dataKey: 'withdrawal.amount',
    width: '180px',
    renderItem: (item) => (
      <Field
        component={TPTextField}
        type="number"
        label="Withdrawal Amount"
        endAdornment={formatCurrency(item.currency)}
        reservedErrorSpace={false}
        name={`transactions.${item.number}.withdrawalAmount`}
      />
    ),
    //renderItem: (item) => `${item.withdrawal.amount} ${formatCurrency(item.widthdrawal.currency)}`,
  },
  {
    label: 'Deducted Amount',
    dataKey: 'deducted.amount',
    width: '120px',
    renderItem: (item) => `${item.deducted.amount} ${formatCurrency(item.currency)}`,
  },
];

const getTransactions = () => {
  return {
    items: [
      {
        username: 'Bruce Wayne',
        number: 'E47979fhs90090f',
        currency: 'usd',
        permitted: {
          amount: 100,
        },
        withdrawal: {
          country: 'Australia',
          amount: 0,
        },
        deducted: {
          amount: 400,
        },
      },
      {
        username: 'Bruce Wayne',
        number: 'E47979fhs99340c',
        currency: 'usd',
        permitted: {
          amount: 500,
        },
        withdrawal: {
          country: 'Australia',
          amount: 0,
        },
        deducted: {
          amount: 100,
        },
      },
    ],
    totalCount: 2,
  };
};

const TransactionsTable = ({ className, handleSubmit }) => {
  const timeoutRef = useRef();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const columns = useMemo(() => [...TRANSACTIONS_COLUMNS], []);

  useEffect(() => {
    setLoading(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setLoading(false);
      const response = getTransactions();
      setItems(response.items);
    }, 1500);
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [timeoutRef]);

  const deductedSum = useMemo(
    () =>
      `${items.reduce((sum, item) => {
        sum += item.deducted.amount;
        return sum;
      }, 0)}  ${formatCurrency(items[0]?.currency)}`,
    [items],
  );

  const Summary = () => (
    <TPGrid container justifyContent="flex-end">
      <TPGrid item>
        <TPTypography variant="body2">{deductedSum}</TPTypography>
      </TPGrid>
    </TPGrid>
  );

  return (
    <form onSubmit={handleSubmit} className={className}>
      <TPGrid container spacing={3}>
        <TPGrid item xs={12}>
          <Table
            items={items}
            columns={columns}
            loading={loading}
            itemKey="number"
            scrollable={false}
            hidePagination
            summaryComponent={Summary}
          />
        </TPGrid>
        <TPGrid item container spacing={2} justifyContent={'flex-end'}>
          <TPGrid item>
            <TPButton secondary size="large">
              I can`&lsquo;`t refund this transaction
            </TPButton>
          </TPGrid>
          <TPGrid item>
            <TPButton primary type="submit" size="large">
              Next
            </TPButton>
          </TPGrid>
        </TPGrid>
      </TPGrid>
    </form>
  );
};

export default reduxForm({
  form: 'USER_TRANSACTIONS_TABLE',
  initialValues: {},
  validate: (value) => {
    if (!value.transactions) return;

    const errors = { transactions: {} };
    Object.keys(value.transactions).forEach((key) => {
      if (value.transactions[key].checked && !value.transactions[key].withdrawalAmount) {
        errors.transactions[key] = { withdrawalAmount: 'Required' };
      }
    });
    return errors;
  },
})(TransactionsTable);
