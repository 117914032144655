import PropTypes from 'prop-types';
import React from 'react';

import TPCardAvatar from 'components/TP-UI/TPCard/components/TPCardAvatar';
import TPGrid from 'components/TP-UI/TPGrid';
import TPImage from 'components/TP-UI/TPImage';
import TPTypography from 'components/TP-UI/TPTypography';

import useStyles from './styles';

const QuizModalContent = (props) => {
  const classes = useStyles();
  const { children, imageConfig, text, title } = props;
  const { src, ...imageStyles } = imageConfig || {};

  return (
    <TPGrid alignItems="center" container direction="column" spacing={2}>
      <TPGrid item>
        <TPCardAvatar className={classes.imageContainer} primary>
          <TPImage src={src} style={imageStyles} />
        </TPCardAvatar>
      </TPGrid>
      <TPGrid item>
        <TPTypography variant="h3">{title}</TPTypography>
      </TPGrid>

      <TPGrid item>{typeof text === 'string' ? <TPTypography>{text}</TPTypography> : text}</TPGrid>
      {children && <TPGrid item>{children}</TPGrid>}
    </TPGrid>
  );
};

QuizModalContent.defaultProps = {
  imageConfig: PropTypes.shape({
    height: PropTypes.number,
    left: PropTypes.number,
    src: PropTypes.string,
    top: PropTypes.number,
    width: PropTypes.number,
  }),
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  title: PropTypes.string,
};

export default QuizModalContent;
