import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    form: {
      width: '100%',
    },
    control: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'TextFields' },
);

export default useStyles;
