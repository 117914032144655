import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      height: 'auto',
      ...theme.bottomNavigation.root,
    },
  }),
  { name: 'TPBottomNavigation' },
);

export default useStyles;
