const styles = () => ({
  button: {
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: '900',
    lineHeight: '15px',
    padding: '0px 20px',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
  },
  item: {
    display: 'flex',
    padding: '24px 17px',
    flexDirection: 'row',
    backgroundColor: '#F4F6F9',
  },
  loader: {
    alignSelf: 'center',
    width: '191px',
    textAlign: 'center',
  },
});

export default styles;
