import React, { useEffect, useCallback, useMemo, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useHistory } from 'react-router-dom';
import config from 'config';

import { closeModal } from 'components/TP-UI/TPModal/actions';
import { createPendingSelector } from 'modules/api/selectors';
import { getParams } from 'selectors';
import {
  REQUIRED_VERIFICATION_MODAL,
  SIGNIN_FORM,
  WRONG_FM_MODAL,
  SEGMENT_ANONYMOUS_ID,
} from '../constants';
import {
  signinRequest,
  preInsertLogin,
  appleSigninRequest,
  googleSigninRequest,
  signinNonfxSuccess,
} from '../actions';
import {
  getDestinationHub,
  getHideMfaToken,
  getSelectHub,
  getSelectAvailableHubs,
  getShowCaptcha,
} from '../selectors';
import * as notificationsActions from 'modules/notifications/actions';

import SignIn from '../components/SignIn';
import SelectHubModal from '../../../components/SelectHubModal';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { logout } from '../../../actions';
import { useReCaptchaV3 } from 'hooks/useReCaptchaV3';
import { LOGOUT_REASON, RETURN_URL_STORAGE_KEY } from '../../../constants';
import { Routes } from 'constants/routeConstants';
import Cookies from 'js-cookie';
import getAuthData from '../../../helpers/getAuthData';
import { getHub } from 'components/App/selectors';
import { FUSION_MARKETS_PREFIX } from 'constants/index';

const WrongFMModal = lazy(() => import('../components/WrongFMModal'));
const RequiredVerificationModal = lazy(() => import('../components/RequiredVerificationModal'));

const SignInContainer = ({ internal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { HUBS = [] } = config;
  const history = useHistory();
  const { state, search } = useLocation();
  const { cleanupStore = false } = state ?? {};
  const hub = useSelector(getHub);
  const authData = useMemo(() => {
    return getAuthData(search);
  }, [search]);

  const queryParams = useSelector(getParams);
  const isSigninPending = useSelector(createPendingSelector(signinRequest));
  const isAppleSigninPending = useSelector(createPendingSelector(appleSigninRequest));
  const isGoogleSigninPending = useSelector(createPendingSelector(googleSigninRequest));
  const destinationHub = useSelector(getDestinationHub);
  const hideMfaToken = useSelector(getHideMfaToken);
  const selectHub = useSelector(getSelectHub);
  const selectAvailableHubs = useSelector(getSelectAvailableHubs);
  const showCaptcha = useSelector(getShowCaptcha);
  const { getCaptchaV3 } = useReCaptchaV3();
  const initialValues = useMemo(() => {
    return {
      prefix: internal ? hub || FUSION_MARKETS_PREFIX : '',
    };
  }, [internal, hub]);

  //Please do not set other useEffects before it
  useEffect(() => {
    if (cleanupStore) {
      dispatch(logout({ reason: LOGOUT_REASON.SIGN_IN_CLEANUP }));
      let state = { ...history.location.state };
      delete state.cleanupStore;
      history.replace({ ...history.location, state });
    }
  }, [dispatch, cleanupStore, history]);

  /*
    SignIn after successful signUp from Fusion-market.com | NonFX website.
    Redirection path does not contain hub value
  */
  useEffect(() => {
    //check hub value because need to run it only once
    if (!hub && authData) {
      const { _id, token, refreshToken } = authData;
      dispatch(
        signinNonfxSuccess({
          token: token,
          refreshToken: refreshToken,
          user: { _id: _id, role: 'client' },
        }),
      );
      //need to cleanup params in browser if for some reason query params are not valid and user will stay on sign-in page.
      history.replace(Routes.SIGNIN);
    }
  }, [dispatch, authData, history, hub]);

  useEffect(() => {
    if (history.location.search === '?migrated') {
      history.replace(history.location.pathname);
      dispatch(
        notificationsActions.showNotificationInfo({
          key: 'migrateMyAccount:congratulationsDescription',
        }),
      );
    }
  }, [dispatch, history]);

  useEffect(() => {
    if (queryParams.email) {
      dispatch(preInsertLogin(queryParams.email));
    }
  }, [dispatch, queryParams.email]);

  const closeFMModal = useCallback(() => {
    dispatch(closeModal(WRONG_FM_MODAL));
  }, [dispatch]);

  const mapToRequestPayload = useCallback(
    async (data) => {
      const payload = { ...data, hideMfaToken };

      let returnUrlInfo = sessionStorage.getItem(RETURN_URL_STORAGE_KEY);
      returnUrlInfo = returnUrlInfo ? JSON.parse(returnUrlInfo) : null;
      if (
        returnUrlInfo &&
        matchPath(returnUrlInfo?.pathname, Routes.AUTH_EMAIL_VERIFICATION) &&
        returnUrlInfo?.search
      ) {
        const token = new URLSearchParams(returnUrlInfo.search).get('token');
        if (token) {
          payload.verificationToken = token;
        }
      }

      payload.captchaV3 = await getCaptchaV3();

      const segmentAnonymousId = Cookies.get(SEGMENT_ANONYMOUS_ID);
      if (segmentAnonymousId) {
        payload.segmentAnonymousId = segmentAnonymousId;
      }
      return payload;
    },
    [hideMfaToken, getCaptchaV3],
  );

  const redirectHub = useMemo(() => HUBS.find((hub) => hub.KEY === destinationHub), [
    HUBS,
    destinationHub,
  ]);

  const handleSubmit = useCallback(
    async (data) => {
      const requestPayload = await mapToRequestPayload(data);
      dispatch(signinRequest(requestPayload));
    },
    [dispatch, mapToRequestPayload],
  );

  const handleGoogleSigninSuccess = useCallback(
    async (data) => {
      const requestPayload = await mapToRequestPayload(data);
      dispatch(googleSigninRequest(requestPayload));
    },
    [dispatch, mapToRequestPayload],
  );

  const handleAppleSignupSuccess = useCallback(
    async (data) => {
      const requestPayload = await mapToRequestPayload(data);
      dispatch(appleSigninRequest(requestPayload));
    },
    [dispatch, mapToRequestPayload],
  );

  const isPending = isSigninPending || isAppleSigninPending || isGoogleSigninPending;

  return (
    <>
      <SignIn
        form={SIGNIN_FORM}
        isPending={isPending}
        onSubmit={handleSubmit}
        internal={internal}
        initialValues={initialValues}
        showCaptcha={showCaptcha}
        onAppleSignupSuccess={handleAppleSignupSuccess}
        onGoogleSigninSuccess={handleGoogleSigninSuccess}
      />
      <SelectHubModal
        visible={selectHub && !isPending}
        onSubmit={handleSubmit}
        loading={isPending}
        form={SIGNIN_FORM}
        showCaptcha={showCaptcha}
        buttonLabel={t('auth:buttons.logIn')}
        hubs={selectAvailableHubs}
      />
      <Suspense fallback="">
        <WrongFMModal
          modalName={WRONG_FM_MODAL}
          redirectHub={redirectHub}
          closeModal={closeFMModal}
        />
      </Suspense>
      <Suspense fallback="">
        <RequiredVerificationModal modalName={REQUIRED_VERIFICATION_MODAL} />
      </Suspense>
    </>
  );
};

export default SignInContainer;
