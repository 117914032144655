import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';
import TPTypography from 'components/TP-UI/TPTypography';
import TPImage from 'components/TP-UI/TPImage';
import { NOTIFICATION_TYPES } from '../../constants';
import Notification from '../Notification';
import useStyles from './styles';

const NotificationsTabContent = ({
  notificationType,
  items = [],
  markAsReadLoading = false,
  markAllAsReadLoading = false,
  onMarkAsRead,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('notificationCentre');
  const { images } = useTheme();

  return (
    <div className={classes.notificationsContainer}>
      {items?.length ? (
        items.map((item) => (
          <Notification
            key={item._id}
            notification={item}
            pending={markAsReadLoading || markAllAsReadLoading}
            onMarkAsRead={onMarkAsRead}
          />
        ))
      ) : (
        <div className={classes.noDataContainer}>
          <TPImage
            className={classes.noDataImage}
            src={images.common.waitingImg}
            alt="no notifications yet"
            aria-labelledby="notification-empty-state"
          />
          <TPTypography variant="body1" id="notification-empty-state" align="center">
            {notificationType === NOTIFICATION_TYPES.NOTIFICATION
              ? t('page.emptyNotifications')
              : t('page.emptyWhatsNew')}
          </TPTypography>
        </div>
      )}
    </div>
  );
};

NotificationsTabContent.propTypes = {
  notificationType: PropTypes.oneOf(Object.values(NOTIFICATION_TYPES)).isRequired,
  items: PropTypes.array,
  markAsReadLoading: PropTypes.bool,
  markAllAsReadLoading: PropTypes.bool,
  onMarkAsRead: PropTypes.func,
};

export default NotificationsTabContent;
