import { createAction } from 'redux-actions';

export const transferPageOpened = createAction('TRANSFER_PAGE_OPENED');
export const transferPageClosed = createAction('TRANSFER_PAGE_CLOSED');

export const transferAccountsShowConfirm = createAction('TRANSFER_ACCOUNTS_SHOW_CONFIRM');
export const transferAccountsConfirmed = createAction('TRANSFER_ACCOUNTS_CONFIRMED');
export const transferAccountsHideDialog = createAction('TRANSFER_ACCOUNTS_HIDE_DIALOG');

export const transferAccountsSwitch = createAction('TRANSFER_ACCOUNTS_SWITCH');

export const getConversionRateRequest = createAction('GET_CONVERSION_RATE_REQUEST');
export const getConversionRateSuccess = createAction('GET_CONVERSION_RATE_SUCCESS');
export const getConversionRateFail = createAction('GET_CONVERSION_RATE_FAIL');

export const createTransferRequest = createAction('CREATE_TRANSFER_REQUEST');
export const createTransferSuccess = createAction('CREATE_TRANSFER_SUCCESS');
export const createTransferFail = createAction('CREATE_TRANSFER_FAIL');

export const createTransferCurrencyCloudRequest = createAction(
  'CREATE_TRANSFER_CURRENCY_CLOUD_REQUEST',
);
export const createTransferCurrencyCloudSuccess = createAction(
  'CREATE_TRANSFER_CURRENCY_CLOUD_SUCCESS',
);
export const createTransferCurrencyCloudFail = createAction('CREATE_TRANSFER_CURRENCY_CLOUD_FAIL');

export const searchTransferHistoryRequest = createAction('SEARCH_TRANSFER_HISTORY_REQUEST');
export const searchTransferHistorySuccess = createAction('SEARCH_TRANSFER_HISTORY_SUCCESS');
export const searchTransferHistoryFail = createAction('SEARCH_TRANSFER_HISTORY_FAIL');

export const acyncValidateTransfer = createAction('ASYNC_VALIDATE_TRANSFER');
export const updateCurrencyFromPicker = createAction('UPDATE_CURRENCY_FROM_PICKER');
export const updateCurrencyToPicker = createAction('UPDATE_CURRENCY_TO_PICKER');

export const currencyCloudRatesRequest = createAction('CURRENCY_CLOUD_RATES_REQUEST');
export const currencyCloudRatesFail = createAction('CURRENCY_CLOUD_RATES_FAIL');
export const currencyCloudRatesSuccess = createAction('CURRENCY_CLOUD_RATES_SUCCESS');
export const transfersCurrencyCloudCancel = createAction('TRANSFERS_CURRENCY_CLOUD_CANCEL');
export const transfersCreateAccount = createAction('TRANSFERS_CREATE_ACCOUNT');
export const asyncValidateTransferCurrencyCloud = createAction(
  'ASYNC_VALIDATE_TRANSFER_CURRENCY_CLOUD',
);
export const switchCurrencyCloudWeekendRates = createAction('SWITCH_CURRENCY_CLOUD_WEEKEND_RATES');
export const transferReuseHistory = createAction('TRANSFER_REUSE_HISTORY');
