import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    spacing: {
      margin: theme.spacing(1),
    },
    logoWrapper: {
      transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      background: 'white',
      overflow: 'hidden',
    },
    minimized: {
      width: '111px',
    },
    maximized: {
      width: '228px',
    },
  }),
  { name: 'SidebarLogos' },
);

export default useStyles;
