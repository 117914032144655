import { handleActions } from 'redux-actions';
import {
  cleanupWithdrawalHistory,
  searchWithdrawHistoryFail,
  searchWithdrawHistorySuccess,
} from '../actions';
import { WITHDRAW_METHOD_LABEL } from '../../Withdraw2/constants';

const defaultState = {
  items: [],
};

export default handleActions(
  {
    [searchWithdrawHistorySuccess](state, action) {
      const withdrawals = action.response.data.withdrawals || [];

      return {
        ...state,
        items: withdrawals.map((withdrawal) => ({
          ...withdrawal,
          type: WITHDRAW_METHOD_LABEL[withdrawal.type] || withdrawal.type,
        })),
        totalNumber: action.response.data.totalNumber,
      };
    },
    [searchWithdrawHistoryFail](state) {
      return state.totalNumber !== undefined
        ? state
        : {
            ...defaultState,
            totalNumber: 0,
          };
    },
    [cleanupWithdrawalHistory]() {
      return defaultState;
    },
  },
  defaultState,
);
