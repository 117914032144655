import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  () => ({
    flagIcon: {
      width: 32,
      height: 16,
    },
  }),
  { name: 'TPFlagIcon' },
);

export default useStyles;
