import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    languageLabelContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    label: {
      marginLeft: theme.spacing(1),
      lineHeight: 1.2,
    },
    menuPaper: {
      maxHeight: 'initial',
    },
  }),
  { name: 'TPLanguageSelect' },
);
