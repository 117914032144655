import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Typography } from '@material-ui/core';
import { formatSuffixOfNumber } from 'utils/formatters';

const TableTextAndCountField = ({
  classes,
  label,
  dataLabel,
  tableItem,
  formatFunc,
  className,
}) => {
  const renderSwitch = (param) => {
    switch (param) {
      case 'account':
        return (
          <div className={classes.countContainer}>
            <span className={classes.countText}>{`${formatSuffixOfNumber(
              tableItem.approvedAccounts + 1,
            )} live account`}</span>
          </div>
        );
      case 'followUp':
        return (
          <div className={classes.countContainer}>
            <span className={classes.countText}>New Follow Up</span>
          </div>
        );
      default:
        return (
          <div className={classes.countContainer}>
            <span className={classes.countText}>New Application</span>
          </div>
        );
    }
  };

  return (
    <Fragment>
      {renderSwitch(tableItem.type)}
      <div className={classNames([classes.item, className])}>
        <Typography className={classes.label}>{label}</Typography>
        <Typography className={classes.dataText}>
          {formatFunc ? formatFunc(get(tableItem, dataLabel)) : get(tableItem, dataLabel)}
        </Typography>
      </div>
    </Fragment>
  );
};

TableTextAndCountField.propTypes = {
  label: PropTypes.string.isRequired,
  dataLabel: PropTypes.string.isRequired,
  tableItem: PropTypes.object.isRequired,
  formatFunc: PropTypes.func,
  className: PropTypes.string,
};

export default withStyles(styles)(TableTextAndCountField);
