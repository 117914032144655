import { createAction } from 'redux-actions';

export const getUserFullAccountsRequest = createAction('GET_USER_FULL_ACCOUNTS_REQUEST');
export const getUserFullAccountsSuccess = createAction('GET_USER_FULL_ACCOUNTS_SUCCESS');
export const getUserFullAccountsFail = createAction('GET_USER_FULL_ACCOUNTS_FAIL');

export const getUserLiveAccountsRequest = createAction('GET_USER_LIVE_ACCOUNTS_REQUEST');
export const getUserLiveAccountsSuccess = createAction('GET_USER_LIVE_ACCOUNTS_SUCCESS');
export const getUserLiveAccountsFail = createAction('GET_USER_LIVE_ACCOUNTS_FAIL');

export const getUserRejectedAccountsRequest = createAction('GET_USER_REJECTED_ACCOUNTS_REQUEST');
export const getUserRejectedAccountsSuccess = createAction('GET_USER_REJECTED_ACCOUNTS_SUCCESS');
export const getUserRejectedAccountsFail = createAction('GET_USER_REJECTED_ACCOUNTS_FAIL');

export const getUserDemoAccountsRequest = createAction('GET_USER_DEMO_ACCOUNTS_REQUEST');
export const getUserDemoAccountsSuccess = createAction('GET_USER_DEMO_ACCOUNTS_SUCCESS');
export const getUserDemoAccountsFail = createAction('GET_USER_DEMO_ACCOUNTS_FAIL');

export const getTradingHistoryRequest = createAction('GET_TRADING_HISTORY_REQUEST');
export const getTradingHistorySuccess = createAction('GET_TRADING_HISTORY_SUCCESS');
export const getTradingHistoryFail = createAction('GET_TRADING_HISTORY_FAIL');

export const getAccountsWithActiveTradesRequest = createAction(
  'GET_ACCOUNTS_WITH_ACTIVE_TRADES_REQUEST',
);
export const getAccountsWithActiveTradesSuccess = createAction(
  'GET_ACCOUNTS_WITH_ACTIVE_TRADES_SUCCESS',
);
export const getAccountsWithActiveTradesFail = createAction('GET_ACCOUNTS_WITH_ACTIVE_TRADES_FAIL');

export const approveUserRequest = createAction('APPROVE_USER_REQUEST');
export const approveUserSuccess = createAction('APPROVE_USER_SUCCESS');
export const approveUserFail = createAction('APPROVE_USER_FAIL');

export const sendMigrationEmailRequest = createAction('SEND_MIGRATION_EMAIL_REQUEST');
export const sendMigrationEmailSuccess = createAction('SEND_MIGRATION_EMAIL_SUCCESS');
export const sendMigrationEmailFail = createAction('SEND_MIGRATION_EMAIL_FAIL');

export const loadUserDetails = createAction('LOAD_USER_DETAILS');

export const createAccountAdminRequest = createAction('CREATE_ACCOUNT_ADMIN_REQUEST');
export const createAccountAdminSuccess = createAction('CREATE_ACCOUNT_ADMIN_SUCCESS');
export const createAccountAdminFail = createAction('CREATE_ACCOUNT_ADMIN_FAIL');

export const changePasswordAdminRequest = createAction('CHANGE_PASSWORD_ADMIN_REQUEST');
export const changePasswordAdminSuccess = createAction('CHANGE_PASSWORD_ADMIN_SUCCESS');
export const changePasswordAdminFail = createAction('CHANGE_PASSWORD_ADMIN_FAIL');

export const changeLiveAccountSettingsRequest = createAction(
  'CHANGE_LIVE_ACCOUNT_SETTINGS_REQUEST',
);
export const changeLiveAccountSettingsSuccess = createAction(
  'CHANGE_LIVE_ACCOUNT_SETTINGS_SUCCESS',
);
export const changeLiveAccountSettingsFail = createAction('CHANGE_LIVE_ACCOUNT_SETTINGS_FAIL');

export const deleteDemoAccountRequest = createAction('DELETE_DEMO_ACCOUNT_REQUEST');
export const deleteDemoAccountSuccess = createAction('DELETE_DEMO_ACCOUNT_SUCCESS');
export const deleteDemoAccountFail = createAction('DELETE_DEMO_ACCOUNT_FAIL');

export const createAffiliateRequest = createAction('CREATE_AFFILIATE_REQUEST');
export const createAffiliateSuccess = createAction('CREATE_AFFILIATE_SUCCESS');
export const createAffiliateFail = createAction('CREATE_AFFILIATE_FAIL');

export const setUserPartnerRequest = createAction('SET_USER_PARTNER_REQUEST');
export const setUserPartnerSuccess = createAction('SET_USER_PARTNER_SUCCESS');
export const setUserPartnerFail = createAction('SET_USER_PARTNER_FAIL');

export const createLpoaModalRequest = createAction('CREATE_LPOA_MODAL_REQUEST');
export const createLpoaModalSuccess = createAction('CREATE_LPOA_MODAL_SUCCESS');
export const createLpoaModalFail = createAction('CREATE_LPOA_MODAL_FAIL');

export const getUserPaymentsRequest = createAction('GET_USER_PAYMENTS_REQUEST');
export const getUserPaymentsSuccess = createAction('GET_USER_PAYMENTS_SUCCESS');
export const getUserPaymentsFail = createAction('GET_USER_PAYMENTS_FAIL');
export const clearUserPayments = createAction('CLEAR_USER_PAYMENTS');

export const putUserRecentPayments = createAction('PUT_USER_RECENT_PAYMENTS');

export const getUserEmailsRequest = createAction('GET_USER_EMAILS_REQUEST');
export const getUserEmailsSuccess = createAction('GET_USER_EMAILS_SUCCESS');
export const getUserEmailsFail = createAction('GET_USER_EMAILS_FAIL');

export const lockUserRequest = createAction('LOCK_USER_REQUEST');
export const lockUserSuccess = createAction('LOCK_USER_SUCCESS');
export const lockUserFail = createAction('LOCK_USER_FAIL');

export const unlockUserRequest = createAction('UNLOCK_USER_REQUEST');
export const unlockUserSuccess = createAction('UNLOCK_USER_SUCCESS');
export const unlockUserFail = createAction('UNLOCK_USER_FAIL');

export const editTradingStatusRequest = createAction('EDIT_TRADING_STATUS_REQUEST');
export const editTradingStatusSuccess = createAction('EDIT_TRADING_STATUS_SUCCESS');
export const editTradingStatusFail = createAction('EDIT_TRADING_STATUS_FAIL');

export const openTransferModal = createAction('OPEN_TRANSFER_MODAL');
export const closeTransferModal = createAction('CLOSE_TRANSFER_MODAL');

export const getAffiliateDataRequest = createAction('GET_AFFILIATE_DATA_REQUEST');
export const getAffiliateDataSuccess = createAction('GET_AFFILIATE_DATA_SUCCESS');
export const getAffiliateDataFail = createAction('GET_AFFILIATE_DATA_FAIL');

export const updateKycStatusRequest = createAction('UPDATE_KYC_STATUS_REQUEST');
export const updateKycStatusSuccess = createAction('UPDATE_KYC_STATUS_SUCCESS');
export const updateKycStatusFail = createAction('UPDATE_KYC_STATUS_FAIL');

export const setKycPendingRequest = createAction('SET_KYC_PENDING_REQUEST');
export const setKycPendingSuccess = createAction('SET_KYC_PENDING_SUCCESS');
export const setKycPendingFail = createAction('SET_KYC_PENDING_FAIL');

export const createCellxpertTransferRequest = createAction('CREATE_CELLXPERT_TRANSFER_REQUEST');
export const createCellxpertTransferSuccess = createAction('CREATE_CELLXPERT_TRANSFER_SUCCESS');
export const createCellxpertTransferFail = createAction('CREATE_CELLXPERT_TRANSFER_FAIL');

export const addDepositManuallyRequest = createAction('ADD_DEPOSIT_MANUALLY_REQUEST');
export const addDepositManuallySuccess = createAction('ADD_DEPOSIT_MANUALLY_SUCCESS');
export const addDepositManuallyFail = createAction('ADD_DEPOSIT_MANUALLY_FAIL');

export const addDepositRequest = createAction('ADD_DEPOSIT_REQUEST');
export const addDepositSuccess = createAction('ADD_DEPOSIT_SUCCESS');
export const addDepositFail = createAction('ADD_DEPOSIT_FAIL');

export const disableUserMfaRequest = createAction('DISABLE_USER_MFA_REQUEST');
export const disableUserMfaSuccess = createAction('DISABLE_USER_MFA_SUCCESS');
export const disableUserMfaFail = createAction('DISABLE_USER_MFA_FAIL');

export const getUserEmailFeedRequest = createAction('GET_USER_EMAIL_FEED_REQUEST');
export const getUserEmailFeedSuccess = createAction('GET_USER_EMAIL_FEED_SUCCESS');
export const getUserEmailFeedFail = createAction('GET_USER_EMAIL_FEED_FAIL');

export const getUserCycleRequest = createAction('GET_USER_CYCLE_REQUEST');
export const getUserCycleSuccess = createAction('GET_USER_CYCLE_SUCCESS');
export const getUserCycleFail = createAction('GET_USER_CYCLE_FAIL');

export const getOperationSubscriptionRequest = createAction('GET_OPERATION_SUBSCRIPTION_REQUEST');
export const getOperationSubscriptionSuccess = createAction('GET_OPERATION_SUBSCRIPTION_SUCCESS');
export const getOperationSubscriptionFail = createAction('GET_OPERATION_SUBSCRIPTION_FAIL');

export const createOperationSubscriptionRequest = createAction(
  'CREATE_OPERATION_SUBSCRIPTION_REQUEST',
);
export const createOperationSubscriptionSuccess = createAction(
  'CREATE_OPERATION_SUBSCRIPTION_SUCCESS',
);
export const createOperationSubscriptionFail = createAction('CREATE_OPERATION_SUBSCRIPTION_FAIL');

export const removeOperationSubscriptionRequest = createAction(
  'REMOVE_OPERATION_SUBSCRIPTION_REQUEST',
);
export const removeOperationSubscriptionSuccess = createAction(
  'REMOVE_OPERATION_SUBSCRIPTION_SUCCESS',
);
export const removeOperationSubscriptionFail = createAction('REMOVE_OPERATION_SUBSCRIPTION_FAIL');

export const setProUserRequest = createAction('SET_PRO_USER_REQUEST');
export const setProUserSuccess = createAction('SET_PRO_USER_SUCCESS');
export const setProUserFail = createAction('SET_PRO_USER_FAIL');

export const setAllowCopyAccountRequest = createAction('SET_ALLOW_COPY_ACCOUNT_REQUEST');
export const setAllowCopyAccountSuccess = createAction('SET_ALLOW_COPY_ACCOUNT_SUCCESS');
export const setAllowCopyAccountFail = createAction('SET_ALLOW_COPY_ACCOUNT_FAIL');

export const setWholesaleUserRequest = createAction('SET_WHOLESALE_USER_REQUEST');
export const setWholesaleUserSuccess = createAction('SET_WHOLESALE_USER_SUCCESS');
export const setWholesaleUserFail = createAction('SET_WHOLESALE_USER_FAIL');

export const setTestUserRequest = createAction('SET_TEST_USER_REQUEST');
export const setTestUserSuccess = createAction('SET_TEST_USER_SUCCESS');
export const setTestUserFail = createAction('SET_TEST_USER_FAIL');

export const setEcpUserRequest = createAction('SET_ECP_USER_REQUEST');
export const setEcpUserSuccess = createAction('SET_ECP_USER_SUCCESS');
export const setEcpUserFail = createAction('SET_ECP_USER_FAIL');

export const createIBClientRequest = createAction('CREATE_I_B_CLIENT_REQUEST');
export const createIBClientSuccess = createAction('CREATE_I_B_CLIENT_SUCCESS');
export const createIBClientFail = createAction('CREATE_I_B_CLIENT_FAIL');

export const setAllowProRequest = createAction('SET_ALLOW_PRO_REQUEST');
export const setAllowProSuccess = createAction('SET_ALLOW_PRO_SUCCESS');
export const setAllowProFail = createAction('SET_ALLOW_PRO_FAIL');

export const signinAsUserRequest = createAction('SIGNIN_AS_USER_REQUEST');
export const signinAsUserSuccess = createAction('SIGNIN_AS_USER_SUCCESS');
export const signinAsUserFail = createAction('SIGNIN_AS_USER_FAIL');

export const getPaymentCommentsRequest = createAction('GET_PAYMENT_COMMENTS_REQUEST');
export const getPaymentCommentsSuccess = createAction('GET_PAYMENT_COMMENTS_SUCCESS');
export const getPaymentCommentsFail = createAction('GET_PAYMENT_COMMENTS_FAIL');

export const getQuizCommentsRequest = createAction('GET_QUIZ_COMMENTS_REQUEST');
export const getQuizCommentsSuccess = createAction('GET_QUIZ_COMMENTS_SUCCESS');
export const getQuizCommentsFail = createAction('GET_QUIZ_COMMENTS_FAIL');

export const addPaymentCommentsRequest = createAction('ADD_PAYMENT_COMMENTS_REQUEST');
export const addPaymentCommentsSuccess = createAction('ADD_PAYMENT_COMMENTS_SUCCESS');
export const addPaymentCommentsFail = createAction('ADD_PAYMENT_COMMENTS_FAIL');

export const addQuizCommentsRequest = createAction('ADD_QUIZ_COMMENTS_REQUEST');
export const addQuizCommentsSuccess = createAction('ADD_QUIZ_COMMENTS_SUCCESS');
export const addQuizCommentsFail = createAction('ADD_QUIZ_COMMENTS_FAIL');

export const restrictUserLeverageRequest = createAction('RESTRICT_USER_LEVERAGE_REQUEST');
export const restrictUserLeverageSuccess = createAction('RESTRICT_USER_LEVERAGE_SUCCESS');
export const restrictUserLeverageFail = createAction('RESTRICT_USER_LEVERAGE_FAIL');

export const createMailchimpUserRequest = createAction('CREATE_MAILCHIMP_USER_REQUEST');
export const createMailchimpUserSuccess = createAction('CREATE_MAILCHIMP_USER_SUCCESS');
export const createMailchimpUserFail = createAction('CREATE_MAILCHIMP_USER_FAIL');

export const removeUserRequest = createAction('REMOVE_USER_REQUEST');
export const removeUserSuccess = createAction('REMOVE_USER_SUCCESS');
export const removeUserFail = createAction('REMOVE_USER_FAIL');

export const setRecentTrsVisibilityRequest = createAction('SET_RECENT_TRS_VISIBILITY_REQUEST');
export const setRecentTrsVisibilitySuccess = createAction('SET_RECENT_TRS_VISIBILITY_SUCCESS');
export const setRecentTrsVisibilityFail = createAction('SET_RECENT_TRS_VISIBILITY_FAIL');

export const emailResendRequest = createAction('EMAIL_RESEND_REQUEST');
export const emailResendSuccess = createAction('EMAIL_RESEND_SUCCESS');
export const emailResendFail = createAction('EMAIL_RESEND_FAIL');

export const getTicketsRequest = createAction('GET_TICKETS_REQUEST');
export const getTicketsSuccess = createAction('GET_TICKETS_SUCCESS');
export const getTicketsFail = createAction('GET_TICKETS_FAIL');
export const cleanupTickets = createAction('CLEANUP_TICKETS');

export const getTicketDetailsRequest = createAction('GET_TICKET_DETAILS_REQUEST');
export const getTicketDetailsSuccess = createAction('GET_TICKET_DETAILS_SUCCESS');
export const getTicketDetailsFail = createAction('GET_TICKET_DETAILS_FAIL');
export const cleanupTicketDetails = createAction('CLEANUP_TICKET_DETAILS');

export const getTicketConversationsRequest = createAction('GET_TICKET_CONVERSATIONS_REQUEST');
export const getTicketConversationsSuccess = createAction('GET_TICKET_CONVERSATIONS_SUCCESS');
export const getTicketConversationsFail = createAction('GET_TICKET_CONVERSATIONS_FAIL');
export const updateTicketConversationsCurrentPage = createAction(
  'UPDATE_TICKET_CONVERSATIONS_CURRENT_PAGE',
);
export const cleanupTicketConversations = createAction('CLEANUP_TICKET_CONVERSATIONS');

export const setAccountCreationRequest = createAction('SET_ACCOUNT_CREATION_REQUEST');
export const setAccountCreationSuccess = createAction('SET_ACCOUNT_CREATION_SUCCESS');
export const setAccountCreationFail = createAction('SET_ACCOUNT_CREATION_FAIL');

export const setUnlimitedAccountsRequest = createAction('SET_UNLIMITED_ACCOUNTS_REQUEST');
export const setUnlimitedAccountsSuccess = createAction('SET_UNLIMITED_ACCOUNTS_SUCCESS');
export const setUnlimitedAccountsFail = createAction('SET_UNLIMITED_ACCOUNTS_FAIL');

export const setUnlimitedDemoAccountsRequest = createAction('SET_UNLIMITED_DEMO_ACCOUNTS_REQUEST');
export const setUnlimitedDemoAccountsSuccess = createAction('SET_UNLIMITED_DEMO_ACCOUNTS_SUCCESS');
export const setUnlimitedDemoAccountsFail = createAction('SET_UNLIMITED_DEMO_ACCOUNTS_FAIL');

export const setManualAccountApproveRequest = createAction('SET_MANUAL_ACCOUNT_APPROVE_REQUEST');
export const setManualAccountApproveSuccess = createAction('SET_MANUAL_ACCOUNT_APPROVE_SUCCESS');
export const setManualAccountApproveFail = createAction('SET_MANUAL_ACCOUNT_APPROVE_FAIL');

export const setDemoAccountUnlimitedExpirationRequest = createAction(
  'SET_DEMO_ACCOUNT_UNLIMITED_EXPIRATION_REQUEST',
);
export const setDemoAccountUnlimitedExpirationSuccess = createAction(
  'SET_DEMO_ACCOUNT_UNLIMITED_EXPIRATION_SUCCESS',
);
export const setDemoAccountUnlimitedExpirationFail = createAction(
  'SET_DEMO_ACCOUNT_UNLIMITED_EXPIRATION_FAIL',
);

export const getUserTotalBalanceRequest = createAction('GET_USER_TOTAL_BALANCE_REQUEST');
export const getUserTotalBalanceSuccess = createAction('GET_USER_TOTAL_BALANCE_SUCCESS');
export const getUserTotalBalanceFail = createAction('GET_USER_TOTAL_BALANCE_FAIL');

export const searchQuizAnswersRequest = createAction('SEARCH_QUIZ_ANSWERS_REQUEST');
export const searchQuizAnswersSuccess = createAction('SEARCH_QUIZ_ANSWERS_SUCCESS');
export const searchQuizAnswersFail = createAction('SEARCH_QUIZ_ANSWERS_FAIL');

export const setSwapFreeUserRequest = createAction('SET_SWAP_FREE_USER_REQUEST');
export const setSwapFreeUserSuccess = createAction('SET_SWAP_FREE_USER_SUCCESS');
export const setSwapFreeUserFail = createAction('SET_SWAP_FREE_USER_FAIL');

export const getUserSecurityLogsRequest = createAction('GET_USER_SECURITY_LOGS_REQUEST');
export const getUserSecurityLogsSuccess = createAction('GET_USER_SECURITY_LOGS_SUCCESS');
export const getUserSecurityLogsFail = createAction('GET_USER_SECURITY_LOGS_FAIL');
export const cleanupUserSecurityLogs = createAction('CLEANUP_USER_SECURITY_LOGS');

export const getTradingStatusRequest = createAction('GET_TRADING_STATUS_REQUEST');
export const getTradingStatusSuccess = createAction('GET_TRADING_STATUS_SUCCESS');
export const getTradingStatusFail = createAction('GET_TRADING_STATUS_FAIL');

export const getUserPaypalRefundablePaymentsRequest = createAction(
  'GET_USER_PAYPAL_REFUNDABLE_PAYMENTS_REQUEST',
);
export const getUserPaypalRefundablePaymentsSuccess = createAction(
  'GET_USER_PAYPAL_REFUNDABLE_PAYMENTS_SUCCESS',
);
export const getUserPaypalRefundablePaymentsFail = createAction(
  'GET_USER_PAYPAL_REFUNDABLE_PAYMENTS_FAIL',
);

export const getUserPaypalEmailsRequest = createAction('GET_USER_PAYPAL_EMAILS_REQUEST');
export const getUserPaypalEmailsSuccess = createAction('GET_USER_PAYPAL_EMAILS_SUCCESS');
export const getUserPaypalEmailsFail = createAction('GET_USER_PAYPAL_EMAILS_FAIL');

export const getUnablePaypalRefundRequestsRequest = createAction(
  'GET_UNABLE_PAYPAL_REFUND_REQUESTS_REQUEST',
);
export const getUnablePaypalRefundRequestsSuccess = createAction(
  'GET_UNABLE_PAYPAL_REFUND_REQUESTS_SUCCESS',
);
export const getUnablePaypalRefundRequestsFail = createAction(
  'GET_UNABLE_PAYPAL_REFUND_REQUESTS_FAIL',
);

export const cleanupUnablePaypalRefundRequests = createAction(
  'CLEANUP_UNABLE_PAYPAL_REFUND_REQUESTS',
);

export const declineUnablePaypalRefundRequest = createAction(
  'DECLINE_UNABLE_PAYPAL_REFUND_REQUEST',
);
export const declineUnablePaypalRefundSuccess = createAction(
  'DECLINE_UNABLE_PAYPAL_REFUND_SUCCESS',
);
export const declineUnablePaypalRefundFail = createAction('DECLINE_UNABLE_PAYPAL_REFUND_FAIL');

export const approveUnablePaypalRefundRequest = createAction(
  'APPROVE_UNABLE_PAYPAL_REFUND_REQUEST',
);
export const approveUnablePaypalRefundSuccess = createAction(
  'APPROVE_UNABLE_PAYPAL_REFUND_SUCCESS',
);
export const approveUnablePaypalRefundFail = createAction('APPROVE_UNABLE_PAYPAL_REFUND_FAIL');

export const getClientSaleRequest = createAction('GET_CLIENT_SALE_REQUEST');
export const getClientSaleSuccess = createAction('GET_CLIENT_SALE_SUCCESS');
export const getClientSaleFail = createAction('GET_CLIENT_SALE_FAIL');

export const updateClientSaleProofRequest = createAction('UPDATE_CLIENT_SALE_PROOF_REQUEST');
export const updateClientSaleProofSuccess = createAction('UPDATE_CLIENT_SALE_PROOF_SUCCESS');
export const updateClientSaleProofFail = createAction('UPDATE_CLIENT_SALE_PROOF_FAIL');

export const allowTransactionalEmailsRequest = createAction('ALLOW_TRANSACTIONAL_EMAILS_REQUEST');
export const allowTransactionalEmailsSuccess = createAction('ALLOW_TRANSACTIONAL_EMAILS_SUCCESS');
export const allowTransactionalEmailsFail = createAction('ALLOW_TRANSACTIONAL_EMAILS_FAIL');
