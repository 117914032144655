import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as selectors from '../selectors';
import PropTypes from 'prop-types';
import DatePickerComponent from '../components/DatePicker';

class DatePickerContainer extends PureComponent {
  handleOnchange = (date) => {
    const {
      input: { onChange },
      selectsEnd,
    } = this.props;
    const copyDate = new Date(date);

    if (copyDate && selectsEnd) {
      copyDate.setHours(23);
      copyDate.setMinutes(59);
      copyDate.setSeconds(59);
    }
    if (!isNaN(Date.parse(date))) {
      onChange(copyDate.toISOString());
    } else if (date === null) {
      onChange('');
    }
  };

  render() {
    const {
      placeholder,
      isClearable,
      dateFormat,
      className,
      minDate,
      maxDate,
      selectsStart,
      selectsEnd,
      formStartDate,
      formEndDate,
      showTimeInput,
      customInput,
      customTitle,
      meta,
      disabled,
      classes,
    } = this.props;

    const props = {
      onChange: this.handleOnchange,
      placeholder,
      startDate: formStartDate ? new Date(formStartDate) : null,
      endDate: formEndDate ? new Date(formEndDate) : null,
      isClearable,
      dateFormat,
      className,
      minDate,
      maxDate,
      selectsStart,
      selectsEnd,
      showTimeInput,
      customInput,
      customTitle,
      meta,
      disabled,
      classes,
    };
    return <DatePickerComponent {...props} />;
  }
}

const mapStateToProps = (state, { meta: { form }, id }) => {
  return {
    formStartDate: selectors.formStartDate(state, form, id),
    formEndDate: selectors.formEndDate(state, form, id),
  };
};

DatePickerContainer.propTypes = {
  meta: PropTypes.object.isRequired,
  input: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  dateFormat: PropTypes.string,
  isClearable: PropTypes.bool,
  selectsStart: PropTypes.bool,
  selectsEnd: PropTypes.bool,
  className: PropTypes.string,
  formStartDate: PropTypes.string,
  formEndDate: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  showTimeInput: PropTypes.bool,
  disabled: PropTypes.bool,
};

DatePickerContainer.defaultProps = {
  isClearable: false,
  selectsStart: false,
  selectsEnd: false,
  disabled: false,
};

export default connect(mapStateToProps, null)(DatePickerContainer);
