import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    placeholder: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...theme.imagePlaceholder,
    },
    placeholderIcon: {
      maxWidth: '50%',
      maxHeight: '80%',
    },
  }),
  { name: 'TPImage' },
);

export default useStyles;
