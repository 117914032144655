import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    form: {
      margin: `${theme.spacing(2)}px 0 0`,
    },
    paymentButtons: {
      marginTop: theme.spacing(2),
    },
    note: {
      paddingTop: theme.spacing(2),
    },
    buttonIcon: {
      marginRight: theme.spacing(1),
    },
    button: {
      marginBottom: theme.spacing(1),
    },
    image: {
      minHeight: 100,
    },
    headerContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    customTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    primaryText: {
      flex: 1,
    },
    marker: {
      fontSize: '12px',
      color: theme.palette.error.main,
      marginRight: theme.spacing(1),
    },
    inlineList: {
      color: theme.palette.text.secondary,
      marginBottom: 0,
      padding: 0,
      display: 'flex',
      flexWrap: 'wrap',
      listStyle: 'none',
    },
    inlineListItem: {
      alignItems: 'center',
      display: 'inline-flex',
      '&:after': {
        content: '""',
        height: 3,
        width: 3,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        backgroundColor: theme.palette.text.secondary,
        borderRadius: '50%',
        display: 'inline-block',
      },
      '&:last-child:after': {
        display: 'none',
      },
    },
    logo: {
      width: 'auto',
    },
  }),
  { name: 'Cards' },
);

export default useStyles;
