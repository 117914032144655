import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  () => ({
    forgotPasswordSuccessModal: {
      width: '420px',
    },
  }),
  { name: 'ForgotPassword' },
);

export default useStyles;
