import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import useStyles from './styles';
import classnames from 'classnames';

const TPLoader = ({ color = 'primary', size = 50, className }) => {
  const classes = useStyles();

  return (
    <div className={classnames(classes.loaderContainer, className)}>
      <CircularProgress color={color} size={size} />
    </div>
  );
};

TPLoader.displayName = 'MuiLoader';
TPLoader.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
  size: PropTypes.number,
};

export default TPLoader;
