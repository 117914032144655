import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';
import isURL from 'utils/isURL';

const CustomLink = ({ classes, children, className, to, ...other }) => {
  const navLinkClassName = classNames(classes.navLink, className);
  return isURL(to) ? (
    <a href={to} className={navLinkClassName} {...other}>
      {children}
    </a>
  ) : (
    <NavLink className={navLinkClassName} to={to} {...other}>
      {children}
    </NavLink>
  );
};

CustomLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    navLink: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(CustomLink);
