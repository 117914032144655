const styles = () => ({
  countContainer: {
    position: 'absolute',
    width: '99px',
    opacity: 0.49,
    borderRadius: '0 0 6px 0',
    backgroundColor: '#DAE5FF',
  },
  countText: {
    display: 'flex',
    color: ' #888EB4',
    fontFamily: 'Lato, sans-serif',
    fontSize: '10px',
    lineHeight: '12px',
    padding: '3px 8px',
  },

  label: {
    color: '#74C3C4',
    fontFamily: 'Lato, sans-serif',
    fontSize: '12px',
    lineHeight: '15px',
  },
  dataText: {
    color: '#36425A',
    fontFamily: 'Lato, sans-serif',
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '17px',
    marginTop: '6px',
  },
  item: {
    padding: '22px 12px',
  },
});

export default styles;
