import React from 'react';
import formatFullDate from 'utils/formatFullDate';
import Status from 'modules/common/Status';
import formatPrice from 'utils/formatPrice';
import formatDaysToPassedDays from 'utils/formatDaysToPassedDays';
import TPLink from 'components/TP-UI/TPLink';
import { Routes } from 'constants/routeConstants';

export const getTableColumns = (leadManager = false) => [
  {
    label: 'Username',
    dataKey: 'userName',
    default: true,
    width: '180px',
  },
  {
    label: 'Date Of Registration',
    dataKey: 'createdAt',
    sortable: true,
    default: true,
    format: formatFullDate,
    width: '155px',
  },
  {
    label: 'Account Status',
    dataKey: 'verification.status',
    default: true,
    sortable: true,
    renderItem: (item) => <Status value={item?.verification?.status ?? ''} />,
    width: '130px',
  },
  {
    label: 'Client Risk Status',
    dataKey: 'verification.clientRiskClassification.status',
    width: '170px',
  },
  {
    label: 'Country',
    dataKey: 'profile.country',
    default: true,
    sortable: true,
    width: '120px',
  },
  {
    label: 'Demo Accounts',
    dataKey: 'demoAccounts',
    default: true,
  },
  {
    label: 'Live Accounts',
    dataKey: 'liveAccounts',
    default: true,
  },
  ...(!leadManager
    ? [
        {
          label: 'Lead Manager',
          dataKey: 'leadManager',
          width: '155px',
        },
      ]
    : []),
  {
    label: 'CXD',
    dataKey: 'cxd',
  },
  {
    label: 'RefCode',
    dataKey: 'refcode',
  },
  {
    label: 'Volume',
    dataKey: 'volume',
    sortable: true,
    format: formatPrice,
    textStyle: 'bold',
  },
  {
    label: 'Last Login',
    dataKey: 'lastLoginDays',
    sortable: true,
    format: formatDaysToPassedDays,
    width: '140px',
  },
  {
    label: 'Last Demo Created',
    dataKey: 'lastDemoDays',
    sortable: true,
    format: formatDaysToPassedDays,
    width: '140px',
  },
  {
    label: 'First Trade',
    dataKey: 'firstTradeDays',
    sortable: true,
    format: formatDaysToPassedDays,
    width: '155px',
  },
  {
    label: 'Last Trade',
    dataKey: 'lastTradeDays',
    sortable: true,
    format: formatDaysToPassedDays,
    width: '155px',
  },
  {
    label: 'Call Status',
    dataKey: 'callStatus',
    width: '170px',
    renderItem: (item) => <Status value={item.callStatus} />,
  },
  {
    width: '140px',
    textAlign: 'right',
    default: true,
    renderItem: (item) => {
      return <TPLink path={`${Routes.USER_MANAGEMENT_DETAILS}/${item._id}`}>Show Details</TPLink>;
    },
  },
];
