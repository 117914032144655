import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      '&:not(:last-child)': {
        paddingBottom: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
        ...theme.notifications.item.notLastChild,
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(1.5),
    },
    descriptionText: {
      marginBottom: theme.spacing(1.5),
    },
    image: {
      display: 'flex',
      margin: `0 auto ${theme.spacing(1.5)}px`,
      ...theme.notifications.item.image,
    },
  }),
  { name: 'Notifications' },
);
