import { WEEK_DAYS_NUMBER, MONTH_DAYS_NUMBER, YEAR_DAYS_NUMBER } from 'constants/index';
import getPassedDays from './getPassedDays';
import { getTranslation } from './compositeTranslationHandler';

const formatDateToPassedTime = (t, date) => {
  const days = Math.floor(getPassedDays(date));

  if (days < WEEK_DAYS_NUMBER) {
    return days === 0
      ? getTranslation(t, { key: 'common:dateAndTime.today' })
      : days === 1
      ? getTranslation(t, { key: 'common:dateAndTime.dayAgo' })
      : `${days} ${getTranslation(t, { key: 'common:dateAndTime.daysAgo' })}`;
  } else if (days >= WEEK_DAYS_NUMBER && days < MONTH_DAYS_NUMBER) {
    const weeks = Math.floor(days / WEEK_DAYS_NUMBER);
    return weeks === 1
      ? getTranslation(t, { key: 'common:dateAndTime.weekAgo' })
      : `${weeks} ${getTranslation(t, { key: 'common:dateAndTime.weeksAgo' })}`;
  } else if (days >= MONTH_DAYS_NUMBER && days < YEAR_DAYS_NUMBER) {
    const months = Math.floor(days / MONTH_DAYS_NUMBER);
    return months === 1
      ? getTranslation(t, { key: 'common:dateAndTime.monthAgo' })
      : `${months} ${getTranslation(t, { key: 'common:dateAndTime.monthsAgo' })}`;
  } else if (days >= YEAR_DAYS_NUMBER) {
    const years = Math.floor(days / YEAR_DAYS_NUMBER);
    return years === 1
      ? getTranslation(t, { key: 'common:dateAndTime.yearAgo' })
      : `${years} ${getTranslation(t, { key: 'common:dateAndTime.yearsAgo' })}`;
  }
};

export default formatDateToPassedTime;
