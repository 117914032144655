import api from '../index';

export const getPresetFiltersRequest = (payload) => {
  return api.get('/filters/preset-filters', { params: payload });
};

export const createPresetFiltersRequest = (payload) => {
  return api.post('/filters/preset-filters', payload);
};

export const savePresetFiltersRequest = (payload) => {
  return api.patch(`/filters/preset-filters/${payload.filter?._id}`, payload);
};

export const deletePresetFiltersRequest = (payload) => {
  return api.delete(`/filters/preset-filters/${payload.filter?._id}`);
};
