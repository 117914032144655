import { get } from 'lodash';
import { handleActions, combineActions } from 'redux-actions';

import { getVerificationChecklistQuestionsSuccess, setVerificationChecklistData } from '../actions';
import { VERIFICATION_CHECK_QUESTION_TYPES } from '../constants';
import * as actionCreators from 'modules/user/actions';

const defaultState = {
  userChecklist: [],
  verificationChecklistData: [],
  accountChecklist: [],
  isChecklistOpen: false,
};

export default handleActions(
  {
    [getVerificationChecklistQuestionsSuccess](state, action) {
      return {
        ...state,
        ...(action.payload.type === VERIFICATION_CHECK_QUESTION_TYPES.NEW_ACCOUNT
          ? { userChecklist: action.response.data }
          : { accountChecklist: action.response.data }),
      };
    },
    [combineActions(actionCreators.getUserSuccess, actionCreators.updateUserDetailsSuccess)](
      state,
      action,
    ) {
      return {
        ...state,
        ...{
          verificationChecklistData: get(
            action,
            'response.data.verification.verificationChecklist',
            [],
          ),
        },
      };
    },
    [setVerificationChecklistData](state, action) {
      return {
        ...state,
        verificationChecklistData: action.payload,
      };
    },
  },
  defaultState,
);
