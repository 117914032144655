import { handleActions } from 'redux-actions';
import get from 'lodash/get';
import {
  getCryptoDay1AssetsSuccess,
  getCryptoDay1SpotPriceSuccess,
  setCryptoDay1SelectedAccount,
  resetCryptoDay1SpotPrice,
  getCryptoDay1WalletsSuccess,
  getCryptoDay1SpotPriceRequest,
  clearCryptoDay1State,
} from '../actions';

const defaultState = {
  assets: [],
  spotPrice: null,
  walletSpotPrice: null,
  selectedAccount: {},
  wallets: null,
  defaultWalletId: null,
};

export default handleActions(
  {
    [getCryptoDay1AssetsSuccess]: (state, action) => ({
      ...state,
      assets: get(action, 'response.data.data', []),
    }),
    [getCryptoDay1SpotPriceSuccess]: (state, { response, payload }) => ({
      ...state,
      [payload.type]: get(response, 'data', null),
    }),
    [getCryptoDay1SpotPriceRequest]: (state, { payload }) => ({
      ...state,
      [payload.type]: null,
    }),
    [resetCryptoDay1SpotPrice]: (state, { payload }) => ({
      ...state,
      [payload.type]: null,
    }),
    [setCryptoDay1SelectedAccount]: (state, action) => ({
      ...state,
      selectedAccount: action.payload,
    }),
    [getCryptoDay1WalletsSuccess]: (state, { response }) => {
      const currWallets = response.data || [];
      const prevWallets = state.wallets || [];

      let defaultWalletId = state.defaultWalletId;
      if (prevWallets.length > 0) {
        const newWallet = currWallets.find(
          (wallet) => !prevWallets.some((prevWallet) => prevWallet._id === wallet._id),
        );
        if (newWallet) {
          defaultWalletId = newWallet._id;
        }
      } else if (currWallets.length === 1) {
        defaultWalletId = currWallets[0]._id;
      }

      return {
        ...state,
        wallets: currWallets,
        defaultWalletId,
      };
    },
    [clearCryptoDay1State]: (state) => ({
      ...defaultState,
      selectedAccount: state.selectedAccount,
    }),
  },
  defaultState,
);
