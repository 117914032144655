import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Routes } from 'constants/routeConstants';
import { USER_ROLE } from '../../../constants/userRoles';
import { MODULES_PERMISSION_KEY } from '../../../constants/permissions';
import { LOGOUT_REASON } from '../../auth/constants';

const PrivateRoute = ({ isAuthenticated, component, ...props }) => {
  return !isAuthenticated ? (
    <Redirect to={{ pathname: Routes.SIGNIN, state: { reason: LOGOUT_REASON.AUTH_FAIL } }} />
  ) : (
    <Route path={props.path} {...props} component={component} />
  );
};

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.object]),
  path: PropTypes.string.isRequired,
  isPrivate: PropTypes.bool,
  exact: PropTypes.bool,
  access: PropTypes.arrayOf(PropTypes.oneOf(Object.values(USER_ROLE))),
  personalPermission: PropTypes.oneOf(Object.values(MODULES_PERMISSION_KEY)),
  visible: PropTypes.bool,
};

export default PrivateRoute;
