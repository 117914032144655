import { combineActions, handleActions } from 'redux-actions';
import {
  cleanupDay1Fee,
  finishWithdrawal,
  getCryptoDay1AssetsSuccess,
  paymentsWithdrawDay1FeeFail,
  paymentsWithdrawDay1FeeSuccess,
  paymentsWithdrawDay1LimitFail,
  paymentsWithdrawDay1LimitSuccess,
  paymentsWithdrawDay1ValidateFail,
  paymentsWithdrawDay1ValidateRequest,
  paymentsWithdrawDay1ValidateSuccess,
} from '../actions';

import {
  paymentsCreateDay1UserSuccess,
  paymentsGetDay1UserFail,
  paymentsGetDay1UserSuccess,
} from '../../../actions';

const defaultState = {
  assets: [],
  limit: {},
  isValid: null,
  fee: null,
  termsConfirmed: false,
  canWithdraw: false,
};

export default handleActions(
  {
    [getCryptoDay1AssetsSuccess](state, action) {
      return {
        ...state,
        assets: action.response.data.data,
      };
    },
    [paymentsWithdrawDay1ValidateRequest](state) {
      return {
        ...state,
        isValid: null,
      };
    },
    [paymentsWithdrawDay1ValidateSuccess](state, action) {
      return {
        ...state,
        isValid: action.response.data.isValid,
      };
    },
    [paymentsWithdrawDay1ValidateFail](state) {
      return {
        ...state,
        isValid: false,
      };
    },
    [paymentsWithdrawDay1FeeSuccess](state, action) {
      return {
        ...state,
        fee: action.response.data,
      };
    },
    [paymentsWithdrawDay1FeeFail](state) {
      return {
        ...state,
        fee: defaultState.fee,
      };
    },
    [cleanupDay1Fee](state) {
      return { ...state, fee: defaultState.fee };
    },
    [combineActions(paymentsGetDay1UserSuccess, paymentsCreateDay1UserSuccess)](state) {
      return { ...state, termsConfirmed: true, canWithdraw: true };
    },
    [paymentsGetDay1UserFail](state, action) {
      const noDay1UserFound = action.error?.data?.index === '308';
      return {
        ...state,
        termsConfirmed: !noDay1UserFound,
        canWithdraw: noDay1UserFound,
      };
    },
    [paymentsWithdrawDay1LimitSuccess](state, action) {
      return {
        ...state,
        limit: action.response.data,
      };
    },
    [paymentsWithdrawDay1LimitFail](state) {
      return {
        ...state,
        limit: {},
      };
    },
    [finishWithdrawal]() {
      return defaultState;
    },
  },
  defaultState,
);
