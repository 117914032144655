import palette from './palette';

const OverridesMuiBottomNavigationAction = {
  root: {
    maxWidth: 'auto',
    padding: '16px 0',
    color: palette.primary.text,
    '&.Mui-selected': {
      padding: '16px 0',
      color: palette.primary.text,
      backgroundColor: palette.primary.lightest,
    },
  },
  label: {
    display: 'none',
  },
};

export default OverridesMuiBottomNavigationAction;
