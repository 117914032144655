import React from 'react';
import PropTypes from 'prop-types';
import TPTabBadge from '../TPTabBadge';

const TPTabLabel = ({ label, badgeContent, badgeType }) => (
  <>
    {label} {badgeContent ? <TPTabBadge value={badgeContent} type={badgeType} /> : null}
  </>
);

TPTabLabel.propTypes = {
  label: PropTypes.node,
  badgeContent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  badgeType: PropTypes.oneOf(['counter', 'badge']),
};

export default TPTabLabel;
