import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    container: {
      display: 'flex',
      flexWrap: 'nowrap',
      gap: theme.spacing(1),
      alignItems: 'flex-start',
      padding: `${theme.spacing(1.75)}px ${theme.spacing(2.5)}px`,
      ...theme.alert.root,
      minWidth: (props) => props.minWidth,
      maxWidth: (props) => props.maxWidth,
    },
    messageContent: {
      display: 'flex',
      alignItems: 'flex-start',
      flexGrow: 1,
    },
    message: {
      paddingTop: theme.spacing(0.25),
      flex: 1,
    },
    messageText: {
      color: 'inherit',
    },
    messageIcon: {
      marginRight: theme.spacing(1),
    },
    closeButton: {
      color: 'inherit',
    },
    closeIcon: {
      marginLeft: 'auto',
      ...theme.alert.icon,
    },
    success: {
      ...theme.alert.success,
    },
    error: {
      ...theme.alert.error,
    },
    warning: {
      ...theme.alert.warning,
    },
    info: {
      ...theme.alert.info,
    },
  }),
  { name: 'TPAlert' },
);

export default useStyles;
