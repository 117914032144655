import React from 'react';
import PropTypes from 'prop-types';
import MenuIcon from '@material-ui/icons/Menu';
import TPBottomNavigation from 'components/TP-UI/TPBottomNavigation';
import TPBottomNavigationAction from 'components/TP-UI/TPBottomNavigationAction';
import TPBottomNavigationActionMenu from 'components/TP-UI/TPBottomNavigationActionMenu';
import useStyles from './styles';

const BottomNavigation = ({
  value,
  selectedRoute,
  items = [],
  drawerOpen,
  onDrawerOpen,
  onDrawerClose,
}) => {
  const classes = useStyles();

  return (
    <TPBottomNavigation value={value} className={classes.root}>
      {items.map((item) => {
        return item?.items?.length ? (
          <TPBottomNavigationActionMenu
            selectedRoute={selectedRoute}
            id={item.id}
            key={item.id}
            iconComponent={item.iconComponent}
            menuItems={item.items}
          />
        ) : (
          <TPBottomNavigationAction
            selectedRoute={selectedRoute}
            id={item.id}
            key={item.id}
            iconComponent={item.iconComponent}
            path={item.path}
          />
        );
      })}
      <TPBottomNavigationAction
        expandable
        expanded={drawerOpen}
        iconComponent={MenuIcon}
        onClick={drawerOpen ? onDrawerClose : onDrawerOpen}
      />
    </TPBottomNavigation>
  );
};

BottomNavigation.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedRoute: PropTypes.string,
  items: PropTypes.array,
  drawerOpen: PropTypes.bool,
  onDrawerOpen: PropTypes.func,
  onDrawerClose: PropTypes.func,
};

export default BottomNavigation;
