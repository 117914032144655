import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    container: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'flex-start',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2),
      },
      ...theme.banner.root,
    },
    messageContent: {
      display: 'flex',
      alignItems: 'flex-start',
      flexGrow: 1,
    },
    message: {
      paddingTop: theme.spacing(0.25),
      flex: 1,
      color: 'inherit',
      '& a': {
        ...theme.link.root,
        ...theme.banner.link,
        '&:hover': {
          ...theme.link.root,
          ...theme.banner.link,
        },
      },
    },
    closeButton: {
      color: 'inherit',
    },
    closeIcon: {
      ...theme.banner.closeIcon,
    },
  }),
  { name: 'TPBanner' },
);

export default useStyles;
