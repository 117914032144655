import createAction from 'redux-actions/es/createAction';

export const auth = createAction('AUTHENTICATE');
export const authenticationFail = createAction('AUTHENTICATION_FAIL');
export const cleanupAuth = createAction('CLEANUP_AUTH');
export const logout = createAction('LOG_OUT');
//notify other modules that logout is finished and they can cleanup data
export const logoutSuccess = createAction('LOG_OUT_SUCCESS');
export const refreshToken = createAction('REFRESH_TOKEN');

export const saveReferral = createAction('SAVE_REFERRAL');

export const firstVisitFulfilled = createAction('FIRST_VISIT_FULFILLED');

export const loginSinceRejectionRequest = createAction('LOGIN_SINCE_REJECTION_REQUEST');
export const loginSinceRejectionSuccess = createAction('LOGIN_SINCE_REJECTION_SUCCESS');
export const loginSinceRejectionFail = createAction('LOGIN_SINCE_REJECTION_FAIL');

export const updateUserPhoneNumber = createAction('UPDATE_USER_PHONE_NUMBER');

export const logoutAsCurrentUser = createAction('LOG_OUT_AS_CURRENT_USER');
