import {
  SIDEBAR_WIDTH_SMALL,
  SIDEBAR_WIDTH_LARGE,
  BOTTOM_BAR_HEIGHT,
} from 'modules/menu/constants';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: '100dvh',
      width: `${SIDEBAR_WIDTH_LARGE}px`,
      boxSizing: 'content-box',
      flexShrink: 0,
      display: 'flex',
      flexDirection: 'column',
      ...theme.sidebar.root,
    },
    minimized: {
      width: `${SIDEBAR_WIDTH_SMALL}px`,
    },
    mobile: {
      height: `calc(100dvh - ${BOTTOM_BAR_HEIGHT}px)`,
      width: '100%',
      marginBottom: BOTTOM_BAR_HEIGHT,
      boxSizing: 'border-box',
    },
    navigationContainer: {
      flex: 1,
      overflowY: 'auto',
      overflowX: 'hidden',
      ...theme.sidebar.navigation,
    },
    verificationContainer: {
      padding: `${theme.spacing(3)}px ${theme.spacing(2.5)}px`,
      ...theme.sidebar.verification,
    },
    lineProgress: {
      marginTop: theme.spacing(1.5),
    },
    verificationButton: {
      marginTop: theme.spacing(1.5),
    },
    circleProgress: {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
  { name: 'Sidebar' },
);

export default useStyles;
