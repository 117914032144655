import { handleActions, combineActions } from 'redux-actions';

import * as signUpActionCreators from '../pages/SignUp/actions';
import * as actionCreators from '../actions';
import { signinNonfxSuccess } from '../pages/SignIn/actions';

export default handleActions(
  {
    [combineActions(
      signUpActionCreators.signupSuccess,
      signUpActionCreators.googleAuthSuccess,
      signUpActionCreators.appleAuthSuccess,
      signinNonfxSuccess,
    )]() {
      return true;
    },
    [actionCreators.firstVisitFulfilled]() {
      return false;
    },
  },
  false,
);
