import { all, put, takeEvery } from 'redux-saga/effects';
import { clearSubmitErrors, stopSubmit } from 'redux-form';

import * as actionCreators from '../actions';
import { LPOA_DOCUMENT_FORM, LPOA_DOCUMENT_MODAL } from '../constants';
import { closeModal } from 'components/TP-UI/TPModal/actions';
import * as notificationsActions from 'modules/notifications/actions';
import { get } from 'lodash';

function* handleSignLpoaRequest() {
  yield put(clearSubmitErrors(LPOA_DOCUMENT_FORM));
}

function* handleSignLpoaSuccess() {
  yield put(actionCreators.getLpoaRequest());
  yield put(closeModal(LPOA_DOCUMENT_MODAL));
  yield put(
    notificationsActions.showNotificationInfo('Limited Power of Attorney successfully signed'),
  );
}

function* handleSignLpoaFail(action) {
  const { message: errorMessage } = get(action, 'error.data', {});

  yield put(
    stopSubmit(LPOA_DOCUMENT_FORM, {
      _error: errorMessage || {
        key: 'backend:somethingWrongContactHelp',
      },
    }),
  );
}

function* index() {
  yield all([
    takeEvery(actionCreators.signLpoaRequest, handleSignLpoaRequest),
    takeEvery(actionCreators.signLpoaSuccess, handleSignLpoaSuccess),
    takeEvery(actionCreators.signLpoaFail, handleSignLpoaFail),
  ]);
}

export default index;
