import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isURL from 'utils/isURL';
import isMailTo from 'utils/isMailTo';

import useStyles from './styles';

const TPLink = ({
  path = '',
  size = '',
  children,
  iconComponent: IconComponent,
  iconPosition = 'start',
  iconSize = 'small',
  className,
  link,
  ...props
}) => {
  const classes = useStyles();
  const linkClassName = classNames(
    classes.root,
    {
      [classes[`${size}Size`]]: size,
    },
    className,
  );

  return link || isURL(path) || isMailTo(path) ? (
    <a href={path} className={linkClassName} {...props}>
      {IconComponent && iconPosition === 'start' ? (
        <IconComponent color="inherit" fontSize={iconSize} className={classes.iconStart} />
      ) : null}
      {children}
      {IconComponent && iconPosition === 'end' ? (
        <IconComponent color="inherit" fontSize={iconSize} className={classes.iconEnd} />
      ) : null}
    </a>
  ) : (
    <NavLink className={linkClassName} to={path} {...props}>
      {IconComponent && iconPosition === 'start' ? (
        <IconComponent color="inherit" fontSize={iconSize} className={classes.iconStart} />
      ) : null}
      {children}
      {IconComponent && iconPosition === 'end' ? (
        <IconComponent color="inherit" fontSize={iconSize} className={classes.iconEnd} />
      ) : null}
    </NavLink>
  );
};

TPLink.propTypes = {
  /**
   * Link path.
   */
  path: PropTypes.string,
  /**
   * Link font size, 'inherit' by default.
   */
  size: PropTypes.oneOf(['', 'large', 'medium', 'small']),
  /**
   * Link icon component.
   */
  iconComponent: PropTypes.elementType,
  /**
   * Position of icon relative to the main content.
   */
  iconPosition: PropTypes.oneOf(['start', 'end']),
  /**
   * If true - render tag A in any case,
   * for relative path link will be from domain root
   * (router basename will be ignored)
   */
  link: PropTypes.bool,
};

export default TPLink;
