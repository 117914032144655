import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  () => ({
    menuDivider: {
      flex: 1,
    },
  }),
  { name: 'TPPresetFilters' },
);

export default useStyles;
