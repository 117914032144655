const styles = () => ({
  label: {
    color: '#74C3C4',
    fontFamily: 'Lato, sans-serif',
    fontSize: '12px',
    lineHeight: '15px',
  },
  levelText: {
    color: '#FFFFFF',
    fontFamily: 'Lato, sans-serif',
    fontSize: '12px',
    fontWeight: 300,
    lineHeight: '15px',
    marginTop: '6px',
  },
  item: {
    padding: '22px 10px',
    height: 80,
    '@media (min-width:400px)': {
      height: 'auto',
    },
    width: 'auto',
  },
  levelContainer: {
    width: 60,
    height: 17,
    borderRadius: '5px',
    textAlign: 'center',
  },
  highLevel: {
    backgroundColor: '#F2AFAF',
  },
  mediumLevel: {
    backgroundColor: '#F2C8AF',
  },
  lowLevel: {
    backgroundColor: '#E2E8F4',
  },
});

export default styles;
