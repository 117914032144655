import { createAction } from 'redux-actions';

export const getClientSalesRequest = createAction('GET_CLIENT_SALES_REQUEST');
export const getClientSalesSuccess = createAction('GET_CLIENT_SALES_SUCCESS');
export const getClientSalesFail = createAction('GET_CLIENT_SALES_FAIL');

export const changeClientSaleStatusRequest = createAction('CHANGE_CLIENT_SALE_STATUS_REQUEST');
export const changeClientSaleStatusSuccess = createAction('CHANGE_CLIENT_SALE_STATUS_SUCCESS');
export const changeClientSaleStatusFail = createAction('CHANGE_CLIENT_SALE_STATUS_FAIL');

export const downloadClientSalesReportRequest = createAction(
  'DOWNLOAD_CLIENT_SALES_REPORT_REQUEST',
);
export const downloadClientSalesReportSuccess = createAction(
  'DOWNLOAD_CLIENT_SALES_REPORT_SUCCESS',
);
export const downloadClientSalesReportFail = createAction('DOWNLOAD_CLIENT_SALES_REPORT_FAIL');
