import { createAction } from 'redux-actions';

export const getTvSubscriptionsRequest = createAction('GET_TV_SUBSCRIPTIONS_REQUEST');
export const getTvSubscriptionsSuccess = createAction('GET_TV_SUBSCRIPTIONS_SUCCESS');
export const getTvSubscriptionsFail = createAction('GET_TV_SUBSCRIPTIONS_FAIL');

export const getTvSubscriptionSignedDocsRequest = createAction(
  'GET_TV_SUBSCRIPTION_SIGNED_DOCS_REQUEST',
);
export const getTvSubscriptionSignedDocsSuccess = createAction(
  'GET_TV_SUBSCRIPTION_SIGNED_DOCS_SUCCESS',
);
export const getTvSubscriptionSignedDocsFail = createAction('GET_TV_SUBSCRIPTION_SIGNED_DOCS_FAIL');

export const getTvSubscriptionsLotsRequest = createAction('GET_TV_SUBSCRIPTIONS_LOTS_REQUEST');
export const getTvSubscriptionsLotsSuccess = createAction('GET_TV_SUBSCRIPTIONS_LOTS_SUCCESS');
export const getTvSubscriptionsLotsFail = createAction('GET_TV_SUBSCRIPTIONS_LOTS_FAIL');

export const getTvSubscriptionHistoryRequest = createAction('GET_TV_SUBSCRIPTION_HISTORY_REQUEST');
export const getTvSubscriptionHistorySuccess = createAction('GET_TV_SUBSCRIPTION_HISTORY_SUCCESS');
export const getTvSubscriptionHistoryFail = createAction('GET_TV_SUBSCRIPTION_HISTORY_FAIL');
export const clearTvSubscriptionHistory = createAction('CLEAR_TV_SUBSCRIPTION_HISTORY');

export const postTvSubscriptionEditRequest = createAction('POST_TV_SUBSCRIPTION_EDIT_REQUEST');
export const postTvSubscriptionEditSuccess = createAction('POST_TV_SUBSCRIPTION_EDIT_SUCCESS');
export const postTvSubscriptionEditFail = createAction('POST_TV_SUBSCRIPTION_EDIT_FAIL');

export const postTvSubscriptionCancelRequest = createAction('POST_TV_SUBSCRIPTION_CANCEL_REQUEST');
export const postTvSubscriptionCancelSuccess = createAction('POST_TV_SUBSCRIPTION_CANCEL_SUCCESS');
export const postTvSubscriptionCancelFail = createAction('POST_TV_SUBSCRIPTION_CANCEL_FAIL');
