import { all, put, takeEvery, call, select, take, race } from 'redux-saga/effects';
import { stopSubmit, initialize } from 'redux-form';
import get from 'lodash/get';
import { change } from 'redux-form';
import fingerprint from '@fingerprintjs/fingerprintjs-pro';

import * as actionCreators from '../actions';
import {
  SIGNIN_FORM,
  WRONG_FM_MODAL,
  MIGRATION_ERRORS,
  SIGN_IN_STATUS,
  REQUIRED_VERIFICATION_MODAL,
} from '../constants';
import { FUSION_MARKETS_PREFIX } from '../../../../../constants';
import { Routes } from 'constants/routeConstants';
import { openModal } from 'components/TP-UI/TPModal/actions';
import { checkIfNewUserNotificationsRequest } from '../../../../userNotifications/actions';
import * as notificationsActions from 'modules/notifications/actions';
import LPOANotification from 'modules/notifications/components/Notifications/LPOANotification';
import { check } from '../../../../permissions';
import { USER_ROLE } from 'constants/userRoles';
import { HUB_CODES } from '../../../components/SelectHubModal/config';
import config from '../../../../../config';
import { getUser } from '../../../selectors';
import { RETURN_URL_STORAGE_KEY } from '../../../constants';
import { matchPath } from 'react-router-dom';
import { validateSigninNonfxSuccess, signinNonfxSuccess } from '../actions';
import {
  getUpdatedUserFail,
  getUpdatedUserRequest,
  getUpdatedUserSuccess,
} from 'modules/menu/actions';
import { push } from 'modules/reduxNavigation/actions';

export function* redirectToDashboard(action) {
  yield put(push(Routes.HOME));

  // check if there are new notifications
  const storedUser = yield select(getUser);
  const user = get(action, 'response.data.user') || storedUser;
  const userId = user._id;
  const userRole = user.role;
  const isPermissible = check(userRole, 'userNotifications:check');

  if (userId && isPermissible) {
    yield put(checkIfNewUserNotificationsRequest(userId));
  }

  const showLpoa = user.showLpoa;
  if (showLpoa) {
    yield put(notificationsActions.showNotificationMessage(LPOANotification));
  }

  if ([USER_ROLE.ADMIN, USER_ROLE.MANAGER].includes(userRole) && config.FINGERPRINT.ENABLED) {
    // create hash of user's fingerprint
    try {
      const fp = yield call(fingerprint.load, { apiKey: config.FINGERPRINT.API_KEY });
      const fpResult = yield call(fp.get);
      yield put(
        actionCreators.validateFingerprintRequest({ fingerprintVisitorId: fpResult.visitorId }),
      );
    } catch (err) {
      console.error(err);
    }
  }
}

function* handleSignInSuccess(action) {
  const status = get(action, 'response.data.status');
  let returnUrlInfo = sessionStorage.getItem(RETURN_URL_STORAGE_KEY);
  returnUrlInfo = returnUrlInfo ? JSON.parse(returnUrlInfo) : null;

  if (status === SIGN_IN_STATUS.NEED_MFA) {
    yield put(push(Routes.AUTH_MFA_INPUT));
  } else if (status === SIGN_IN_STATUS.CHANGE_PASSWORD) {
    yield put(push(Routes.AUTH_FORCE_PASSWORD_CHANGE));
  } else if (status === SIGN_IN_STATUS.REQUIRED_MFA) {
    yield put(push(Routes.AUTH_MFA_REQUIRED));
  } else if (status === SIGN_IN_STATUS.REQUIRED_VERIFICATION) {
    if (returnUrlInfo && matchPath(returnUrlInfo?.pathname, Routes.AUTH_EMAIL_VERIFICATION)) {
      sessionStorage.removeItem(RETURN_URL_STORAGE_KEY);
      yield put(push(returnUrlInfo));
    } else {
      yield put(openModal(REQUIRED_VERIFICATION_MODAL));
    }
  } else {
    yield redirectToDashboard(action);
  }
}

function* handleSignInSMSuccess(action) {
  const status = get(action, 'response.data.status');
  const user = yield select(getUser);

  if (status === SIGN_IN_STATUS.NEED_MFA) {
    yield put(push(Routes.AUTH_MFA_INPUT));
  } else if (status === SIGN_IN_STATUS.CHANGE_PASSWORD) {
    yield put(push(Routes.AUTH_FORCE_PASSWORD_CHANGE));
  } else if (status === SIGN_IN_STATUS.REQUIRED_MFA) {
    yield put(push(Routes.AUTH_MFA_REQUIRED));
  } else if (!user.mfa?.enabled && !user.mfa?.postponed) {
    yield put(push(Routes.AUTH_MFA_SETUP));
  } else {
    yield redirectToDashboard(action);
  }
}

function* handleSigninNonfxSuccess(action) {
  yield put(validateSigninNonfxSuccess(action.payload));
}

function* handleValidateSigninNonfxSuccess(action) {
  yield put(getUpdatedUserRequest(action.payload.user._id));
  const { response } = yield race({
    response: take(getUpdatedUserSuccess),
    cancel: take(getUpdatedUserFail),
  });
  if (response) {
    yield call(redirectToDashboard, action);
  }
}

function* showError(action) {
  const errorId = get(action, 'error.data.index');
  yield put(change(SIGNIN_FORM, 'captcha', ''));

  if (errorId === '155') {
    const hubs = get(action, 'error.data.hubs');
    yield put(actionCreators.selectAvailableHubs(hubs));
    yield put(actionCreators.selectHub());
    yield put(change(SIGNIN_FORM, 'prefix', get(HUB_CODES, get(hubs, '0'), FUSION_MARKETS_PREFIX)));
    return;
  }
  if (errorId === '431') {
    yield put(actionCreators.showCaptchaToken(true));
    yield put(
      stopSubmit(SIGNIN_FORM, {
        _error: { key: 'auth:signIn.captchaRequired' },
      }),
    );
    return;
  }
  const errorStatus = get(action, 'error.status', null);
  const { message: errorMessage, index: errorIndex, extraData } = get(action, 'error.data', {});
  if (errorId === 35) {
    yield put(
      stopSubmit(SIGNIN_FORM, {
        _error: { key: 'errorList:googleUserNotFound' },
      }),
    );
    return;
  }
  if (errorStatus === 400 && MIGRATION_ERRORS.includes(errorIndex)) {
    yield put(actionCreators.setDestinationHub(extraData.migrationDestination));
    yield put(openModal(WRONG_FM_MODAL));
  }

  if (get(action, 'error.status') === 429) {
    yield put(
      stopSubmit(SIGNIN_FORM, {
        _error: { key: 'auth:signInTooManyRequests' },
      }),
    );
    return;
  }

  yield put(
    stopSubmit(SIGNIN_FORM, {
      _error: errorMessage,
    }),
  );
}

function* preInsertLogin(action) {
  yield all([put(initialize(SIGNIN_FORM, { email: action.payload })), put(push(Routes.SIGNIN))]);
}

export default function* watchRequest() {
  yield all([
    takeEvery(actionCreators.signinSuccess, handleSignInSuccess),
    takeEvery(actionCreators.appleSigninSuccess, handleSignInSMSuccess),
    takeEvery(signinNonfxSuccess, handleSigninNonfxSuccess),
    takeEvery(validateSigninNonfxSuccess, handleValidateSigninNonfxSuccess),
    takeEvery(actionCreators.googleSigninSuccess, handleSignInSMSuccess),
    takeEvery(actionCreators.signinFail, showError),
    takeEvery(actionCreators.appleSigninFail, showError),
    takeEvery(actionCreators.appleSigninFail, showError),
    takeEvery(actionCreators.googleSigninFail, showError),
    takeEvery(actionCreators.preInsertLogin, preInsertLogin),
  ]);
}
