import React from 'react';
import useStyles from './styles';
import TPRadioGroup from 'components/TP-UI/TPRadioGroup';
import TPTypography from 'components/TP-UI/TPTypography';
import { Field, reduxForm } from 'redux-form';

const radioButtons = [
  { value: 'radio1', label: 'Radio 1' },
  { value: 'radio2', label: 'Radio 2' },
];

const Radio = () => {
  const classes = useStyles();

  return (
    <form>
      <div className={classes.layout}>
        <Field
          name="radioGroup1"
          buttons={radioButtons}
          legend="Radio Group"
          component={TPRadioGroup}
        />
        <br />
        <Field
          name="radioGroup2"
          buttons={radioButtons}
          disabled={true}
          row
          component={TPRadioGroup}
          size="small"
          legend="Radio Group 2"
        />
        <Field
          name="radioGroup3"
          buttons={radioButtons}
          component={TPRadioGroup}
          legend="Radio Group With Error"
          meta={{ error: { key: 'common:errors.form.isRequired' }, submitFailed: true }}
        />

        <TPTypography variant="h6" className={classes.header}>
          Radio Group without legend
        </TPTypography>
        <Field
          name="radioGroup2"
          buttons={radioButtons}
          disabled={true}
          row
          component={TPRadioGroup}
          size="small"
        />
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'TP-Radio-FORM',
  initialValues: {
    radioGroup1: 'radio1',
    radioGroup2: 'radio1',
  },
})(Radio);
