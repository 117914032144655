import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    menuItem: {
      display: 'flex',
      justifyContent: 'space-between',
      ...theme.navMenuList.item.content,
    },
    selectedMenuItem: {
      ...theme.navMenuList.accordion.selected.root,
    },
    menuItemLabel: {
      display: 'flex',
      alignItems: 'center',
    },
    iconContainer: {
      ...theme.navMenuList.item.icon,
    },
    label: {
      whiteSpace: 'normal',
    },
    nestedList: {
      padding: 0,
    },
    selectedNestedList: {
      ...theme.navMenuList.accordion.selected.root,
    },
    nestedItem: {
      ...theme.navMenuList.accordion.nestedItem.root,
    },
    nestedItemInSelectedMenu: {
      '&:hover': {
        ...theme.navMenuList.accordion.selected.nestedItem.hover,
      },
    },
    selectedNestedItem: {
      ...theme.navMenuList.accordion.nestedItem.selected,
    },
  }),
  { name: 'TPNavMenuListAccordion' },
);

export default useStyles;
