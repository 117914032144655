export const equalPasswords = (values, section) => {
  const formData = section ? values[section] : values;
  if (
    formData &&
    formData.password &&
    formData.confirmPassword &&
    formData.password !== formData.confirmPassword
  ) {
    const error = {
      confirmPassword: { key: 'common:errors.form.confirmPassword' },
    };
    return section ? { [section]: error } : error;
  }
  return {};
};

export const acceptDocuments = (formData) => {
  if (!formData.conditions) {
    return { _error: { key: 'common:errors.form.acceptDocuments' } };
  }
  return {};
};
