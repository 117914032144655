import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    counter: {
      display: 'inline-block',
      marginLeft: theme.spacing(1),
      ...theme.tabBadge.counter,
    },
    badge: {
      ...theme.tabBadge.badge,
    },
  }),
  { name: 'TPTabBadge' },
);

export default useStyles;
