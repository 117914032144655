import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../../../components/LayoutNew';
import Page from 'modules/common/Page';
import ForcePasswordChangeForm from '../ForcePasswordChangeForm';
import config from '../../../../../../config';
import TPAlert from 'components/TP-UI/TPAlert';
import TPGrid from 'components/TP-UI/TPGrid';
import PropTypes from 'prop-types';

const ForcePasswordChange = ({ onSubmit, isPending, form, validate }) => {
  const { t } = useTranslation('auth');
  const { CURRENT_HUB_LABEL } = config;

  return (
    <Page docTitle={t('forcePasswordChange.pageTitle', { company: CURRENT_HUB_LABEL })}>
      <Layout
        title={t('forcePasswordChange.title')}
        welcomeTitle={t('logInWelcome')}
        welcomeDescription={t('logInWelcomeDescription')}>
        <TPGrid container spacing={3}>
          <TPGrid item xs={12}>
            <TPAlert variant="info">{t('forcePasswordChange.caption')}</TPAlert>
          </TPGrid>
          <TPGrid item xs={12}>
            <ForcePasswordChangeForm
              form={form}
              validate={validate}
              onSubmit={onSubmit}
              isPending={isPending}
            />
          </TPGrid>
        </TPGrid>
      </Layout>
    </Page>
  );
};

ForcePasswordChange.propTypes = {
  form: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  validate: PropTypes.func,
  isPending: PropTypes.bool.isRequired,
};

export default ForcePasswordChange;
