import createAction from 'redux-actions/es/createAction';

export const signupRequest = createAction('SIGNUP_REQUEST');
export const signupSuccess = createAction('SIGNUP_SUCCESS');
export const signupFail = createAction('SIGNUP_FAIL');

export const googleAuthRequest = createAction('GOOGLE_AUTH_REQUEST');
export const googleAuthSuccess = createAction('GOOGLE_AUTH_SUCCESS');
export const googleAuthFail = createAction('GOOGLE_AUTH_FAIL');

export const appleAuthRequest = createAction('APPLE_AUTH_REQUEST');
export const appleAuthSuccess = createAction('APPLE_AUTH_SUCCESS');
export const appleAuthFail = createAction('APPLE_AUTH_FAIL');
export const appleUserExist = createAction('APPLE_USER_EXIST');

export const verifyPhone = createAction('VERIFY_PHONE');
export const verifyPhoneRequest = createAction('VERIFY_PHONE_REQUEST');
export const verifyPhoneSuccess = createAction('VERIFY_PHONE_SUCCESS');
export const verifyPhoneFail = createAction('VERIFY_PHONE_FAIL');
export const verifyPhoneReset = createAction('VERIFY_PHONE_RESET');
export const verifyPhoneCode = createAction('VERIFY_PHONE_CODE');
export const verifyPhoneCodeRequest = createAction('VERIFY_PHONE_CODE_REQUEST');
export const verifyPhoneCodeSuccess = createAction('VERIFY_PHONE_CODE_SUCCESS');
export const verifyPhoneCodeFail = createAction('VERIFY_PHONE_CODE_FAIL');
