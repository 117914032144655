import { combineReducers } from 'redux';

import referralLink from './referralLink';
import feesDiscount from './feesDiscount';
import referrals from './referrals';
import balance from './balance';
import earnings from './earnings';

const reducer = combineReducers({ referralLink, feesDiscount, referrals, balance, earnings });

export default reducer;
