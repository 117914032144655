import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    flagIcon: {
      width: 32,
      height: 'auto',
      ...theme.currency.icon,
    },
  }),
  { name: 'TPCurrency' },
);

export default useStyles;
