import palette from './palette';

const OverridesCssBaseline = {
  '@global': {
    body: {
      backgroundColor: palette.background.main,
    },
  },
};

export default OverridesCssBaseline;
