import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TPModal from 'components/TP-UI/TPModal';
import TPTypography from 'components/TP-UI/TPTypography';

const RejectionModal = ({ modalName, onClose }) => {
  const { t } = useTranslation('accounts');
  return (
    <TPModal modalName={modalName} confirmation title={t('rejectionModal.title')} onClose={onClose}>
      <section>
        <TPTypography>{t('rejectionModal.welcome')}</TPTypography>
        <TPTypography>{t('rejectionModal.content')}</TPTypography>
      </section>
    </TPModal>
  );
};

RejectionModal.propTypes = {
  modalName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RejectionModal;
