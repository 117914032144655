import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    groupLabel: {
      ...theme.formControl.groupLabel,
    },
    errorContainer: {
      minHeight: theme.textError.root.minHeight,
    },
    menuRoot: {
      zIndex: 1500,
    },
    menuPaper: {
      maxHeight: 180,
      overflow: 'auto',
    },
    menuItem: {
      whiteSpace: 'initial',
    },
    customSelect: {
      '&:before, &:after': {
        display: 'none',
      },
      '&$hiddenArrow .MuiSelect-select.MuiSelect-select': {
        paddingRight: 0,
      },
    },
    hiddenArrow: {
      '& .MuiSelect-select.MuiSelect-select': {
        paddingRight: theme.spacing(1.25),
      },
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    textFieldSelectMode: {
      cursor: 'pointer',
      '& .MuiInputBase-root, & .MuiOutlinedInput-input': {
        cursor: 'pointer',
      },
    },
  }),
  { name: 'TPAutocomplete' },
);
