import { all, put, select, takeLatest } from 'redux-saga/effects';
import {
  getIsImpersonation,
  getUserIsRejected,
  getUserRole,
  isFirstLoginSinceRejection,
} from 'modules/auth/selectors';
import { getUpdatedUserSuccess } from '../../../menu/actions';
import { openModal } from 'components/TP-UI/TPModal/actions';
import { REJECTION_MODAL } from '../constants';
import { USER_ROLE } from 'constants/userRoles';

//checked when user is logged in successfully, redirected to home,
//and api getUpdatedUserRequest since modal is located in main layout
function* checkApplicationRejection() {
  const userRole = yield select(getUserRole);
  const imPersonation = yield select(getIsImpersonation);
  const isUserRejected = yield select(getUserIsRejected);
  const firstLoginSinceRejection = yield select(isFirstLoginSinceRejection);

  if (
    userRole === USER_ROLE.CLIENT &&
    !imPersonation &&
    isUserRejected &&
    firstLoginSinceRejection
  ) {
    yield put(openModal(REJECTION_MODAL));
  }
}

export default function* watchRequest() {
  yield all([takeLatest(getUpdatedUserSuccess, checkApplicationRejection)]);
}
