import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTheme } from '@material-ui/core';
import Notifications from 'modules/notifications';
import LpoaModal from 'modules/lpoa';
import Sidebar from 'modules/menu';
import Header from 'modules/common/Header';
import ApplicationRejectionModal from 'modules/common/ApplicationRejectionModal';
import BottomNavigationContainer from 'modules/menu/containers/BottomNavigationContainer';
import classnames from 'classnames';
import Onboarding from '../../../../onboarding/containers/OnboardingContainer';
import TPDrawer from 'components/TP-UI/TPDrawer';
import TPImage from 'components/TP-UI/TPImage';
import config from 'config';
import useMobileLayout from 'hooks/useMobileLayout';
import TPScrollToTop from 'components/TP-UI/TPScrollToTop';
import AML from 'modules/aml/containers/AMLContainer';
import { useLocation } from 'react-router-dom';
import { WITHDRAWAL2_PATH, WITHDRAWAL_PATH } from 'constants/routeConstants';

import useStyles from './styles';

const { LOGO, CURRENT_HUB_LABEL } = config;

const MainLayout = ({
  children,
  title,
  drawerOpen,
  toggleDrawer,
  headerContent,
  mobileContent,
  desktopContent,
  contentClassName,
  titleClassName,
  authenticated,
  isPublic,
  userRole,
  hub,
  userCountry,
  pro,
  firstVisit,
  gradientClassName,
  breadcrumbsRoutes,
}) => {
  const classes = useStyles();
  const [openOnboarding, setOpenOnboarding] = useState(false);
  const mobile = useMobileLayout();
  const { themeName } = useTheme();

  const mainLayoutContentRef = useRef(null);
  const [scrollToTopVisible, setScrollToTopVisible] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // TODO: functionality for mobile to be added
      if (!mobile && firstVisit) {
        setOpenOnboarding(true);
      }
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [firstVisit, mobile]);

  const handleDrawerOpen = useCallback(() => toggleDrawer(true), [toggleDrawer]);
  const handleDrawerClose = useCallback(() => toggleDrawer(false), [toggleDrawer]);

  return (
    <div>
      <Notifications className={classes.notifications} />
      <LpoaModal />

      <div
        className={classNames(classes.container, {
          [classes.publicContainer]: isPublic,
          [classes.containerMobile]: mobile,
        })}>
        {!isPublic && !mobile ? <Sidebar onMinMenuClick={handleDrawerOpen} /> : null}
        <TPDrawer open={drawerOpen} onClose={handleDrawerClose} anchor={mobile ? 'bottom' : 'left'}>
          <Sidebar
            expandable={false}
            mobile={mobile}
            header={
              mobile ? (
                <Header
                  title={<TPImage alt={CURRENT_HUB_LABEL} src={LOGO[themeName].origin} />}
                  userRole={userRole}
                  mobile
                  showActions
                  onDrawerClose={handleDrawerClose}
                  titleClassName={titleClassName}
                />
              ) : null
            }
            onChange={handleDrawerClose}
          />
        </TPDrawer>
        <div
          className={classNames(classes.mainComponent, {
            [contentClassName]: true,
            [classes.publicContent]: isPublic,
            [classes.mainComponentMobile]: mobile,
            [classes.zIndex1]: gradientClassName,
          })}>
          {!isPublic ? (
            <Header
              title={title}
              userRole={userRole}
              mobile={mobile}
              breadcrumbsRoutes={breadcrumbsRoutes}
              titleClassName={titleClassName}>
              {headerContent || (mobile ? mobileContent : desktopContent)}
            </Header>
          ) : null}

          <div
            ref={mainLayoutContentRef}
            className={classNames(classes.mainLayoutContent, {
              [classes.scrollToTopActive]: scrollToTopVisible,
            })}>
            {children}
          </div>
          <div className={classes.scrollToTopContainer}>
            <TPScrollToTop
              scrollContainerRef={mainLayoutContentRef}
              onVisibilityChange={setScrollToTopVisible}
            />
          </div>
        </div>
      </div>
      <div
        className={classnames({
          [classes.hiddenContainer]: !mobile,
        })}>
        {isPublic ? null : (
          <BottomNavigationContainer
            authenticated={authenticated}
            userRole={userRole}
            hub={hub}
            userCountry={userCountry}
            pro={pro}
            drawerOpen={drawerOpen}
            onDrawerOpen={handleDrawerOpen}
            onDrawerClose={handleDrawerClose}
          />
        )}
      </div>
      <ApplicationRejectionModal />
      <Onboarding open={openOnboarding} />
      {!(pathname === WITHDRAWAL_PATH || pathname === WITHDRAWAL2_PATH) ? <AML /> : null}
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  authenticated: PropTypes.bool.isRequired,
  headerContent: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  /**
   * @deprecated - instead use a headerContent prop (HeaderButtons with correct mobile flag can be used)
   */
  mobileContent: PropTypes.object,
  /**
   * @deprecated - instead use a headerContent prop (HeaderButtons with correct mobile flag can be used)
   */
  desktopContent: PropTypes.object,
  title: PropTypes.string.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  contentClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  userRole: PropTypes.string,
  hub: PropTypes.string,
  userCountry: PropTypes.string,
  pro: PropTypes.bool,
  gradientClassName: PropTypes.string,
};

export default MainLayout;
