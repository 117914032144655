import { handleActions } from 'redux-actions';

import { getQuizInsightsSuccess, setEvidenceUploadView } from '../actions';

const initialState = {
  quizInsights: [],
  showEvidenceUploadView: false,
};

const upgradeToProReducer = handleActions(
  {
    [getQuizInsightsSuccess]: (state, action) => {
      return {
        ...state,
        quizInsights: action.response.data,
      };
    },
    [setEvidenceUploadView]: (state, action) => {
      return {
        ...state,
        showEvidenceUploadView: action.payload,
      };
    },
  },
  initialState,
);

export default upgradeToProReducer;
