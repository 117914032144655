export default function mergeUrlPaths(paths, removeParams) {
  let mergedUrl = paths
    .filter(Boolean)
    .join('/')
    .replace('//', '/');
  if (removeParams) {
    mergedUrl = mergedUrl.split(':')[0];
  }
  return mergedUrl;
}
