import { pxToRem } from '../../helpers/font';

export const htmlBodyFontSize = 14;
export const fontFamily = 'Lato, sans-serif';

export const fontWeight = {
  extraBold: 900,
  bold: 700,
  regular: 400,
  light: 300,
};

const _pxToRem = pxToRem(htmlBodyFontSize);

export const fontSize = {
  _10: _pxToRem(10),
  _12: _pxToRem(12),
  _14: _pxToRem(14),
  _16: _pxToRem(16),
  _18: _pxToRem(18),
  _20: _pxToRem(20),
  _22: _pxToRem(22),
  _24: _pxToRem(24),
  _28: _pxToRem(28),
  _32: _pxToRem(32),
  _140: _pxToRem(140),
};
