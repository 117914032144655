import React from 'react';
import { MODULES_PERMISSION_KEY } from 'constants/permissions';
import { USER_ROLE } from 'constants/userRoles';
import { lazy } from 'react';
import { Routes } from 'constants/routeConstants';
import Home from 'modules/home';
import MyAccounts from 'modules/accounts/pages/MyAccounts';
import MfaInput from 'modules/auth/pages/MfaInput';
import MfaRequired from 'modules/auth/pages/MfaRequired';
import MfaSetup from 'modules/auth/pages/MfaSetup';
import ForgotPassword from 'modules/auth/pages/ForgotPassword';
import Migration from 'modules/auth/pages/Migration';
import ForcePasswordChange from 'modules/auth/pages/ForcePasswordChange';
import SignIn from 'modules/auth/pages/SignIn';
import SignUp from 'modules/auth/pages/SignUp';
import ResetPassword from 'modules/auth/pages/ResetPassword';
import EmailVerification from 'modules/auth/pages/EmailVerification';
import Dashboard from 'modules/dashboard';
import { FUSION_MARKETS, FUSION_MARKETS_X } from 'constants/index';
import WithdrawalHistory from 'modules/payments/pages/WithdrawalHistory';

const ContactUs = lazy(() => import('modules/contactUs'));
const Discover = lazy(() => import('modules/discover/components/DiscoverPage'));
const DiscoverDashboard = lazy(() => import('modules/discover/pages/Dashboard'));
const MyFeeds = lazy(() => import('modules/discover/pages/MyFeeds'));
const MyFeedDetails = lazy(() => import('modules/discover/pages/MyFeedDetails'));
const Sentiment = lazy(() => import('modules/discover/pages/Sentiment'));
const NewsSearch = lazy(() => import('modules/discover/pages/Search/containers'));
const MigrateMyAccount = lazy(() => import('modules/migrateMyAccount'));
const MyFollowers = lazy(() => import('modules/myFollowers'));
const FusionPlusAbout = lazy(() =>
  import('modules/myFollowers/containers/FusionPlusAboutContainer'),
);
const FusionPlusInvitations = lazy(() =>
  import('modules/myFollowers/containers/FusionPlusInvitationsContainer'),
);
const AcceptFusionPlusInvitation = lazy(() =>
  import('modules/myFollowers/containers/AcceptFusionPlusInvitationContainer'),
);
const MasterAccounts = lazy(() => import('modules/myFollowers/containers/MasterAccountsContainer'));
const MyFollowersTrades = lazy(() => import('modules/myFollowers/containers/TradesContainer'));
const MyFollowersWaitlist = lazy(() =>
  import('modules/myFollowers/containers/LeaderboardContainer'),
);
const MyPerformance = lazy(() => import('modules/myPerformance'));
const UserPerformance = lazy(() => import('modules/myPerformance/containers/UserPerformance'));
const Tools = lazy(() => import('modules/tools'));
const TradingCentralEmbed = lazy(() => import('modules/tradingCentralEmbed'));
const AnalystViews = lazy(() => import('modules/analystViews'));
const CellxpertResetPassword = lazy(() => import('modules/cellxpert/containers/resetPassword'));
const JoinDayOne = lazy(() => import('modules/day1Exchange/pages/JoinDayOne'));
const Day1xSubscribtionForm = lazy(() => import('modules/day1xSubscribtion'));
const LeadJoin = lazy(() => import('modules/lead'));
const ReduceFees = lazy(() => import('modules/reduceFees'));
const TradingCalculators = lazy(() => import('modules/tradingCalculators'));
const Promotions = lazy(() => import('modules/promotions'));
const DepositFinmoContainer = lazy(() =>
  import('modules/payments/pages/Deposit/containers/DepositFinmoContainer'),
);
const DepositMonoovaContainer = lazy(() =>
  import('modules/payments/pages/Deposit/containers/DepositMonoovaContainer'),
);
const DepositSimulatePaypal = lazy(() =>
  import('modules/payments/pages/Deposit/containers/DepositSimulatePaypal'),
);
const ConfirmRealTimePayment = lazy(() =>
  import('modules/payments/pages/Deposit/containers/ConfirmRealTimePaymentContainer'),
);
const DepositCryptoDay1 = lazy(() =>
  import('modules/payments/pages/Deposit/containers/DepositCryptoDay1Container'),
);
const ConfirmPayment = lazy(() =>
  import('modules/payments/pages/Deposit/containers/ConfirmPaymentContainer'),
);
const ConfirmCurrencyCloudPaymentContainer = lazy(() =>
  import('modules/payments/pages/Deposit/containers/ConfirmCurrencyCloudPaymentContainer'),
);
const Payments = lazy(() => import('modules/payments/containers/PaymentsContainer'));
const ProfileData = lazy(() => import('modules/profile/pages/ProfileData'));
const ProfileVerification = lazy(() => import('modules/profile/pages/ProfileVerification'));
const VerificationDetails = lazy(() => import('modules/verificationManagement/pages/ViewDetails'));
const EmailManagement = lazy(() => import('modules/emailManagement'));
const ValidationRefCodes = lazy(() => import('modules/validationRefCodes'));
const VerificationManagement = lazy(() => import('modules/verificationManagement'));
const UserDetails = lazy(() => import('modules/userManagement/pages/UserDetails'));
const UserManagement = lazy(() => import('modules/userManagement'));
const PaymentDetails = lazy(() => import('modules/paymentManagement/pages/ViewDetails'));
const PaymentManagement = lazy(() => import('modules/paymentManagement'));
const SecurityLogs = lazy(() => import('modules/security/pages/SecurityLogs'));
const Settings = lazy(() => import('modules/settings'));
const PageWarning = lazy(() => import('modules/pageWarning'));
const CustomFields = lazy(() => import('modules/customFields'));
const Notifications = lazy(() => import('modules/userNotifications'));
const RiskGroup = lazy(() => import('modules/riskGroup'));
const Mt4LiveCountries = lazy(() => import('modules/mt4LiveCountries'));
const PaymentQueue = lazy(() => import('modules/paymentQueue'));
const MigrationRequests = lazy(() => import('modules/migrationRequests'));
const Operations = lazy(() => import('modules/operations'));
const TicketHistory = lazy(() =>
  import('modules/userManagement/pages/UserDetails/containers/TicketHistoryContainer'),
);
const UpgradeToPro = lazy(() => import('modules/upgradeToPro'));

const TradingCentralEmbedEconomicCalendar = () => <TradingCentralEmbed page="economicCalendar" />;
const TradingCalculatorsPublic = () => <TradingCalculators isPublic />;
const TradingCalculatorsPublicAsic = () => <TradingCalculators isPublic regulator="ASIC" />;
const TradingCentralEmbedPublicEconomicCalendar = () => (
  <TradingCentralEmbed isPublic page="economicCalendarPublic" />
);
const TradingCentralEmbedTechnicalInsight = () => <TradingCentralEmbed page="technicalInsight" />;
const Day1xSubscribtionFormPublic = () => <Day1xSubscribtionForm isPublic />;
const SignInInternal = () => <SignIn internal />;

export const getStaffRoutes = ({ isAdmin, isCountryManager }) => {
  return [
    {
      component: MigrationRequests,
      path: Routes.MIGRATION_REQUESTS,
      isPrivate: true,
      access: [USER_ROLE.ADMIN, USER_ROLE.MANAGER],
      personalPermission: MODULES_PERMISSION_KEY.SETTINGS,
      visible: true,
    },
    {
      component: UserDetails,
      path: Routes.USER_MANAGEMENT_DETAILS_ID,
      isPrivate: true,
      access: [USER_ROLE.ADMIN, USER_ROLE.MANAGER],
      visible: true,
    },
    {
      component: UserManagement,
      path: Routes.USER_MANAGEMENT,
      isPrivate: true,
      access: [USER_ROLE.ADMIN, USER_ROLE.MANAGER],
      personalPermission: MODULES_PERMISSION_KEY.USER_MANAGEMENT,
      visible: true,
    },
    {
      component: TicketHistory,
      path: Routes.TICKET_HISTORY_ID,
      isPrivate: true,
      access: [USER_ROLE.ADMIN, USER_ROLE.MANAGER],
      visible: true,
    },
    {
      component: PaymentDetails,
      path: Routes.PAYMENT_MANAGEMENT_DETAILS_ID,
      isPrivate: true,
      access: [USER_ROLE.ADMIN, USER_ROLE.MANAGER],
      visible: true,
    },
    {
      component: PaymentManagement,
      path: Routes.PAYMENT_MANAGEMENT,
      isPrivate: true,
      access: [USER_ROLE.ADMIN, USER_ROLE.MANAGER],
      personalPermission: MODULES_PERMISSION_KEY.PAYNENT_MANAGER,
      visible: true,
    },
    {
      component: EmailManagement,
      path: Routes.EMAIL_MANAGEMENT,
      isPrivate: true,
      access: [USER_ROLE.ADMIN, USER_ROLE.MANAGER],
      personalPermission: MODULES_PERMISSION_KEY.EMAIL_MANAGEMENT,
      visible: true,
    },
    {
      component: VerificationDetails,
      path: Routes.VERIFICATION_MANAGEMENT_DETAILS_ID,
      isPrivate: true,
      access: [USER_ROLE.ADMIN, USER_ROLE.MANAGER],
      personalPermission: MODULES_PERMISSION_KEY.VERIFICATION_MANAGEMENT,
      visible: true,
    },
    {
      component: VerificationManagement,
      path: Routes.VERIFICATION_MANAGEMENT,
      isPrivate: true,
      access: [USER_ROLE.ADMIN, USER_ROLE.MANAGER],
      personalPermission: MODULES_PERMISSION_KEY.VERIFICATION_MANAGEMENT,
      visible: true,
    },
    {
      component: PageWarning,
      path: Routes.PAGE_WARNING,
      isPrivate: true,
      access: [USER_ROLE.ADMIN],
      visible: true,
      personalPermission: MODULES_PERMISSION_KEY.SETTINGS,
    },
    {
      component: RiskGroup,
      path: Routes.RISK_GROUP,
      isPrivate: true,
      access: [USER_ROLE.ADMIN],
      visible: true,
      personalPermission: MODULES_PERMISSION_KEY.SETTINGS,
    },
    {
      component: Mt4LiveCountries,
      path: Routes.MT4LIVE_COUNTRIES,
      isPrivate: true,
      access: [USER_ROLE.ADMIN],
      visible: true,
      personalPermission: MODULES_PERMISSION_KEY.SETTINGS,
    },
    {
      component: PaymentQueue,
      path: Routes.PAYMENT_QUEUE,
      isPrivate: true,
      access: [USER_ROLE.ADMIN],
      visible: true,
      personalPermission: MODULES_PERMISSION_KEY.SETTINGS,
    },
    {
      component: Notifications,
      path: Routes.NOTIFICATIONS,
      isPrivate: true,
      access: [USER_ROLE.ADMIN],
      visible: true,
      personalPermission: MODULES_PERMISSION_KEY.SETTINGS,
    },
    {
      component: SecurityLogs,
      path: Routes.SECURITY_LOGS,
      isPrivate: true,
      access: [USER_ROLE.ADMIN],
      visible: true,
      personalPermission: MODULES_PERMISSION_KEY.SETTINGS,
    },
    {
      component: ValidationRefCodes,
      path: Routes.VALIDATION_REF_CODES,
      isPrivate: true,
      access: [USER_ROLE.ADMIN],
      visible: true,
      personalPermission: MODULES_PERMISSION_KEY.SETTINGS,
    },
    {
      component: Operations,
      path: Routes.OPERATIONS,
      isPrivate: true,
      access: [USER_ROLE.ADMIN, USER_ROLE.MANAGER],
      personalPermission: MODULES_PERMISSION_KEY.OPERATIONS,
      visible: true,
    },
    {
      component: Settings,
      path: Routes.MFA_SETTINGS,
      isPrivate: true,
      access: [USER_ROLE.MANAGER, USER_ROLE.ADMIN],
      personalPermission: MODULES_PERMISSION_KEY.SETTINGS,
      visible: true,
    },
    {
      component: Dashboard,
      path: Routes.DASHBOARD,
      isPrivate: true,
      access: [USER_ROLE.ADMIN, USER_ROLE.MANAGER],
      personalPermission: MODULES_PERMISSION_KEY.DASHBOARD,
      visible: isAdmin || isCountryManager,
    },
    {
      component: CustomFields,
      path: Routes.CUSTOM_FIELDS,
      isPrivate: true,
      access: [USER_ROLE.ADMIN],
      visible: true,
      personalPermission: MODULES_PERMISSION_KEY.SETTINGS,
    },
  ];
};

export const getAuthRoutes = () => {
  return [
    {
      component: ResetPassword,
      path: Routes.AUTH_RESET_PASSWORD,
      isPrivate: false,
      visible: true,
    },
    {
      component: MfaInput,
      path: Routes.AUTH_MFA_INPUT,
      isPrivate: true,
      visible: true,
    },
    {
      component: MfaRequired,
      path: Routes.AUTH_MFA_REQUIRED,
      isPrivate: true,
      visible: true,
    },
    {
      component: MfaSetup,
      path: Routes.AUTH_MFA_SETUP,
      isPrivate: true,
      visible: true,
    },
    {
      component: ForgotPassword,
      path: Routes.AUTH_FORGOT_PASSWORD,
      isPrivate: false,
      visible: true,
    },
    {
      component: Migration,
      path: Routes.AUTH_MIGRATION,
      isPrivate: false,
      visible: true,
    },
    {
      component: ForcePasswordChange,
      path: Routes.AUTH_FORCE_PASSWORD_CHANGE,
      isPrivate: true,
      visible: true,
    },
    {
      component: SignInInternal,
      path: Routes.AUTH_SIGN_IN_INTERNAL,
      isPrivate: false,
      visible: true,
    },
    {
      component: SignIn,
      path: Routes.SIGNIN,
      isPrivate: false,
      visible: true,
      exact: true,
    },
    {
      component: SignUp,
      path: Routes.SIGNUP,
      isPrivate: false,
      visible: true,
    },
    {
      component: EmailVerification,
      path: Routes.AUTH_EMAIL_VERIFICATION,
      isPrivate: true,
      visible: true,
    },
  ];
};

export const getAllRoutes = ({
  isAdmin,
  isCountryManager,
  allowCopyAccount,
  joinFusionPlus,
  allowUpgradeToPro,
  allowReduceFees,
}) => {
  return [
    ...getAuthRoutes(),
    ...getStaffRoutes({ isAdmin, isCountryManager }),
    ...getClientRoutes({
      allowCopyAccount,
      joinFusionPlus,
      allowUpgradeToPro,
      allowReduceFees,
    }),
    {
      component: Home,
      path: Routes.HOME,
      isPrivate: false,
      exact: true,
      allAuthorized: true,
      visible: true,
    },
  ];
};

export const getPaymentRoutes = () => {
  return [
    {
      component: DepositFinmoContainer,
      path: Routes.PAY_ID_FINMO_PAYMENT,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      visible: true,
    },
    {
      component: DepositSimulatePaypal,
      path: Routes.PAYPAL_SIMULATE,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      visible: true,
    },
    {
      component: DepositMonoovaContainer,
      path: Routes.PAY_ID_PAYMENT,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      visible: true,
    },
    {
      component: ConfirmRealTimePayment,
      path: Routes.CONFIRM_REALTIME_PAYMENT,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      visible: true,
    },
    {
      component: DepositCryptoDay1,
      path: Routes.CRYPTO_DAY1_DEPOSIT,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      visible: true,
    },
    {
      component: ConfirmPayment,
      path: Routes.CONFIRM_PAYMENT,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      visible: true,
    },
    {
      component: ConfirmCurrencyCloudPaymentContainer,
      path: Routes.CONFIRM_CURRENCY_CLOUD,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      visible: true,
    },
    {
      component: WithdrawalHistory,
      path: Routes.WITHDRAWAL_HISTORY,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      visible: true,
    },
    {
      component: Payments,
      path: Routes.PAYMENTS,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      visible: true,
    },
  ];
};

export const getNewsRoutes = () => {
  return [
    {
      component: DiscoverDashboard,
      path: Routes.MARKET_BUZZ_DASHBOARD_ID,
      access: [USER_ROLE.CLIENT],
      isPrivate: true,
      visible: true,
    },
    {
      component: Discover,
      path: Routes.MARKET_BUZZ,
      access: [USER_ROLE.CLIENT],
      isPrivate: true,
      visible: true,
    },
    {
      component: MyFeedDetails,
      path: Routes.MY_FEEDS_WITH_ID,
      access: [USER_ROLE.CLIENT],
      isPrivate: true,
      visible: true,
    },
    {
      component: MyFeeds,
      path: Routes.MY_FEEDS,
      access: [USER_ROLE.CLIENT],
      isPrivate: true,
      visible: true,
      exact: true,
    },
    {
      component: Sentiment,
      path: Routes.SENTIMENT,
      access: [USER_ROLE.CLIENT],
      isPrivate: true,
      visible: true,
    },
    {
      component: NewsSearch,
      path: Routes.NEWS_SEARCH,
      access: [USER_ROLE.CLIENT],
      isPrivate: true,
      visible: true,
    },
  ];
};

export const getFusionPlusRoutes = ({ allowCopyAccount, joinFusionPlus }) => {
  return [
    {
      component: FusionPlusAbout,
      path: Routes.FUSION_PLUS_ABOUT,
      access: [USER_ROLE.CLIENT],
      hub: [FUSION_MARKETS, FUSION_MARKETS_X],
      isPrivate: true,
      visible: allowCopyAccount,
    },
    {
      component: FusionPlusInvitations,
      path: Routes.FUSION_PLUS_INVITATIONS,
      access: [USER_ROLE.CLIENT],
      hub: [FUSION_MARKETS, FUSION_MARKETS_X],
      isPrivate: true,
      visible: allowCopyAccount,
    },
    {
      component: MyFollowers,
      path: Routes.MY_FOLLOWERS,
      access: [USER_ROLE.CLIENT],
      hub: [FUSION_MARKETS, FUSION_MARKETS_X],
      isPrivate: true,
      visible: allowCopyAccount,
    },
    {
      component: MasterAccounts,
      path: Routes.MASTER_ACCOUNTS,
      access: [USER_ROLE.CLIENT],
      hub: [FUSION_MARKETS, FUSION_MARKETS_X],
      isPrivate: true,
      visible: allowCopyAccount,
    },
    {
      component: MyFollowersWaitlist,
      path: Routes.MY_FOLLOWERS_WAITLIST,
      access: [USER_ROLE.CLIENT],
      hub: [FUSION_MARKETS, FUSION_MARKETS_X],
      isPrivate: true,
      visible: allowCopyAccount,
    },
    {
      component: MyFollowersTrades,
      path: Routes.MY_FOLLOWERS_TRADES,
      access: [USER_ROLE.CLIENT],
      hub: [FUSION_MARKETS, FUSION_MARKETS_X],
      isPrivate: true,
      visible: allowCopyAccount && joinFusionPlus,
    },
  ];
};

export const getClientRoutes = ({
  allowCopyAccount,
  joinFusionPlus,
  allowUpgradeToPro,
  allowReduceFees,
}) => {
  return [
    ...getPaymentRoutes(),
    {
      component: MyAccounts,
      path: Routes.MY_ACCOUNTS,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      visible: true,
    },
    {
      component: Tools,
      path: Routes.DOWNLOADS,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      visible: true,
    },
    ...getFusionPlusRoutes({ allowCopyAccount, joinFusionPlus }),
    {
      component: JoinDayOne,
      path: Routes.DAY1_EXCHANGE,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      hub: [FUSION_MARKETS, FUSION_MARKETS_X],
      visible: true,
    },
    {
      component: MyPerformance,
      path: Routes.MY_PERFORMANCE,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      visible: true,
    },
    {
      component: UserPerformance,
      path: Routes.USER_PERFORMANCE,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      visible: true,
    },
    {
      component: AcceptFusionPlusInvitation,
      path: Routes.MY_FOLLOWERS_JOIN,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      visible: true,
    },
    {
      component: TradingCentralEmbedEconomicCalendar,
      path: Routes.ECONOMIC_CALENDAR,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      visible: true,
    },
    {
      component: MigrateMyAccount,
      path: Routes.MIGRATE_MY_ACCOUNT,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      visible: false,
    },
    {
      component: UpgradeToPro,
      path: Routes.UPGRADE_TO_PRO,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      visible: allowUpgradeToPro,
    },
    {
      component: ContactUs,
      path: Routes.CONTACT_US,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      visible: true,
    },
    {
      component: ProfileVerification,
      path: Routes.PROFILE_VERIFICATION,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      visible: true,
    },
    {
      component: ProfileData,
      path: Routes.PROFILE,
      isPrivate: true,
      exact: true,
      access: [USER_ROLE.CLIENT],
      visible: true,
    },
    {
      component: TradingCalculators,
      path: Routes.TRADING_CALCULATORS,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      visible: true,
    },
    {
      component: TradingCalculatorsPublic,
      path: Routes.PUBLIC_CALCULATORS,
      isPrivate: false,
      visible: true,
    },
    {
      component: TradingCalculatorsPublicAsic,
      path: Routes.PUBLIC_CALCULATORS_ASIC,
      isPrivate: false,
      visible: true,
    },
    {
      component: TradingCentralEmbedTechnicalInsight,
      path: Routes.TECHNICAL_INSIGHT,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      visible: true,
    },
    {
      component: TradingCentralEmbedPublicEconomicCalendar,
      path: Routes.PUBLIC_ECONOMIC_CALENDAR,
      isPrivate: false,
      visible: true,
    },
    {
      component: Day1xSubscribtionFormPublic,
      path: Routes.DAY1X_SUBSCRIBTION,
      isPrivate: false,
      visible: true,
    },
    {
      component: ReduceFees,
      path: Routes.REDUCE_FEES,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      hub: [FUSION_MARKETS, FUSION_MARKETS_X],
      visible: allowReduceFees,
    },
    ...getNewsRoutes(),
    {
      component: AnalystViews,
      path: Routes.ANALYST_VIEWS,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      visible: true,
    },
    {
      component: CellxpertResetPassword,
      path: Routes.CELLXPERT_RESET_PASSWORD,
      isPrivate: false,
      visible: true,
    },
    {
      component: LeadJoin,
      path: Routes.LEAD_JOIN,
      isPrivate: false,
      visible: true,
    },
    {
      component: Promotions,
      path: Routes.PROMOTIONS,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      hub: [FUSION_MARKETS_X],
      visible: true,
    },
    {
      component: Promotions,
      path: Routes.UPGRADES,
      isPrivate: true,
      access: [USER_ROLE.CLIENT],
      hub: [FUSION_MARKETS],
      visible: true,
    },
  ];
};
