import api from 'modules/api';

export const getQuizRequest = () => api.get('/quiz');
export const passQuizRequest = (answersMap) => api.patch('/quiz', answersMap);
export const getQuizInsightsRequest = () => api.get('/quiz/quiz-insights');
export const searchQuizAnswersRequest = ({ userId, payload }) => {
  return api.get(`/quiz-answers/users/${userId}`, { params: payload });
};
export const getQuizCommentsRequest = (payload) => {
  return api.get(`/quiz-answers/quiz-comments`, { params: payload });
};

export const addQuizCommentsRequest = (payload) => {
  return api.post(`/quiz-answers/quiz-comments`, payload);
};
