import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TPTypography from 'components/TP-UI/TPTypography';
import { TPSelect } from 'components/TP-UI/TPSelect';
import classnames from 'classnames';
import { DEFAULT_PAGE_SIZE_OPTIONS } from './constants';

import useStyles from './styles';

export const TPPageSize = ({
  value = 0,
  options = DEFAULT_PAGE_SIZE_OPTIONS,
  disabled = false,
  onChange,
  className,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('');

  const displayedOptions = useMemo(
    () => options.map((pageSize) => ({ label: pageSize, value: pageSize })),
    [options],
  );

  return (
    <div className={classnames(classes.pageSizeContainer, className)}>
      <TPTypography variant="body2" color="secondary">
        {t('common:labels:show')}
      </TPTypography>
      <TPSelect
        name="pageSize"
        value={value}
        size="small"
        options={displayedOptions}
        disabled={disabled}
        onChange={onChange}
        reservedErrorSpace={false}
      />
      <TPTypography variant="body2" color="secondary">
        {t('common:labels:entries')}
      </TPTypography>
    </div>
  );
};

TPPageSize.propTypes = {
  /**
   * Number of data items per page
   */
  value: PropTypes.number,
  /**
   * List of available page sizes
   */
  options: PropTypes.arrayOf(PropTypes.number),
  disabled: PropTypes.bool,
  /**
   * Called when the page size is changed, function (pageSize)
   */
  onChange: PropTypes.func,
};

export default TPPageSize;
