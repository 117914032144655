import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      maxWidth: theme.fileListItem.root.width,
    },
    fileContainer: {
      display: 'flex',
      width: theme.fileListItem.root.width,
      height: theme.fileListItem.root.height,
      overflow: 'hidden',
      ...theme.fileListItem.fileContainer,
    },
    fileContainerError: theme.fileListItem.fileContainerError,
    fileImage: {
      width: 'auto',
      maxWidth: 'fit-content',
    },
    fileExtension: {
      textTransform: 'uppercase',
    },
    nonImageFileContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2),
      flex: 1,
      ...theme.fileListItem.nonImageFileContainer,
    },
    fileItemDescription: {
      display: 'flex',
      alignItems: 'center',
      '&  > *:first-child': {
        flex: 1,
      },
    },
    clickable: {
      cursor: 'pointer',
    },
  }),
  { name: 'TPFileItem' },
);
