import { getUrlWithParams } from 'helpers/router';
import { Routes } from 'constants/routeConstants';
import generateCSV from 'utils/generateCSV';
import { DATE_FORMAT_DDMMYYYY } from 'constants/index';
import fmDate from 'utils/fmDate';

const generateUserVolumesReport = ({ items, appURI, size, dateFrom, dateTo }) => {
  const fields = [
    {
      field: 'name',
      name: 'User Name',
      renderItem: (item) =>
        `=HYPERLINK(""${appURI +
          getUrlWithParams(Routes.USER_MANAGEMENT_DETAILS_ID, {
            id: item.userId,
          })}"", ""${item.name || item.userId}"")`,
    },
    {
      field: 'country',
      name: 'Country',
      format: (country) => country.name,
    },
    {
      field: 'volume',
      name: 'Volume',
      format: (value) => `$${value}M`,
    },
  ];

  return generateCSV(items, fields, [
    `Top ${size} users by volume traded for period: ${fmDate(dateFrom).format(
      DATE_FORMAT_DDMMYYYY,
    )} - ${fmDate(dateTo).format(DATE_FORMAT_DDMMYYYY)}`,
  ]);
};

export default generateUserVolumesReport;
