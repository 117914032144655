import development from './development';
import development_cy_x from './development_cy_x';
import production from './production';
import production_rd from './production_rd';
import prod_cy_x from './prod_cy_x';
import productionX from './productionX';
import testing_rd from './testing_rd';
import staging_cy_x from './staging_cy_x';
import dev_rd from './dev_rd';
import dev_cy_x from './dev_cy_x';

const config = {
  development,
  development_cy_x,
  dev_rd,
  dev_cy_x,
  testing_rd,
  staging_cy_x,
  production_rd,
  prod_cy_x,
  production,
  productionX,
};

export default config[process.env.REACT_APP_ENV || 'development'];
