const styles = () => ({
  sectionTextHighlight: {
    margin: '0',
    marginBottom: '6px',
    fontSize: '12px',
    color: '#74C3C4',
  },
  sectionTextGroup: {
    color: '#36425A',
    fontSize: '14px',
    fontFamily: 'Lato, sans-serif',
    margin: '0',
    minHeight: '42px',
    marginBottom: '20px',
  },
  groupWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  sectionText__bold: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  sectionText__highlighted: {
    backgroundColor: '#DAE5FF',
  },
  sectionText__bigger: {
    fontSize: '16px',
  },
  editButton: {
    height: '16px',
    marginLeft: 3,
  },
  icon: {
    width: '14px',
    height: '14px',
    color: '#4DB5B6',
    '&:hover': {
      color: '#78D5D3',
    },
    '&:active': {
      color: '#74C3C4',
    },
  },
  headerContainer: {
    display: 'flex',
    flexDirecation: 'row',
  },
  tipText: {
    fontSize: '12px',
    color: '#767C9E',
  },
});
export default styles;
