import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import 'react-datepicker/dist/react-datepicker.css';
import InputLabel from '@material-ui/core/InputLabel';
import { ErrorOutline } from '@material-ui/icons';
import { withStyles } from '@material-ui/core';
import { getTranslation } from 'utils/compositeTranslationHandler';
import styles from './styles';

const DatePickerComponent = (props) => {
  const {
    onChange,
    placeholder,
    startDate,
    endDate,
    isClearable,
    minDate,
    maxDate,
    selectsStart,
    selectsEnd,
    dateFormat,
    showTimeInput,
    classes,
    customInput,
    customTitle,
    meta,
    disabled,
  } = props;
  const { t } = useTranslation();
  return (
    <div
      className={
        customInput
          ? classNames(classes.DatePickerCustom, classes.DatePickerWrapper)
          : classes.DatePickerWrapper
      }>
      <DatePicker
        className={classNames(classes.DatePicker, {
          [classes.textFieldError]: meta.error && meta.submitFailed,
        })}
        selected={selectsEnd ? endDate : startDate}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        showTimeInput={showTimeInput}
        timeFormat="h:mm aa"
        minDate={selectsEnd ? startDate : minDate}
        maxDate={selectsStart ? endDate : maxDate}
        placeholderText={placeholder || new Date().toLocaleDateString('en-GB')}
        dateFormat={dateFormat || 'dd/MM/yyyy'}
        isClearable={isClearable}
        disabled={disabled}
      />
      {customInput ? (
        <InputLabel
          className={classNames(classes.customLabel, {
            [classes.labelError]: meta.error && meta.submitFailed,
          })}>
          {customTitle || t('common:labels.date')}
        </InputLabel>
      ) : null}
      {meta.error && meta.submitFailed && (
        <span className={classes.errorSelect}>
          <ErrorOutline className={classes.errorIcon} />
          &nbsp;
          {getTranslation(t, meta.error)}
        </span>
      )}
    </div>
  );
};

DatePickerComponent.propTypes = {
  onChange: PropTypes.func,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  dateFormat: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  isClearable: PropTypes.bool,
  classes: PropTypes.object,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  selectsStart: PropTypes.bool,
  selectsEnd: PropTypes.bool,
  showTimeInput: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(DatePickerComponent);
