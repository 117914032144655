import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';

const TPDrawer = ({ children, open, anchor = 'left', onClose, ...props }) => {
  return (
    <Drawer anchor={anchor} open={open} onClose={onClose} {...props}>
      {children}
    </Drawer>
  );
};

TPDrawer.muiName = Drawer.muiName;

TPDrawer.propTypes = {
  open: PropTypes.bool,
  anchor: PropTypes.oneOf(['bottom', 'left', 'right', 'top']),
  onClose: PropTypes.func.isRequired,
};

export default TPDrawer;
