const styles = () => ({
  label: {
    color: '#74C3C4',
    fontFamily: 'Lato, sans-serif',
    fontSize: '12px',
    lineHeight: '15px',
  },
  dataText: {
    color: '#36425A',
    fontFamily: 'Lato, sans-serif',
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '17px',
    marginTop: '6px',
  },
  item: {
    padding: '22px 10px',
    height: 80,
    '@media (min-width:400px)': {
      height: 'auto',
    },
  },
  boldText: {
    fontWeight: 'bold',
  },
});

export default styles;
