import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = { photoId: [], proof: [] };

export default handleActions(
  {
    [actionCreators.initIdVerificationForm](state, action) {
      return { ...state, photoId: action.payload };
    },
    [actionCreators.initProofVerificationForm](state, action) {
      return { ...state, proof: action.payload };
    },
  },
  defaultState,
);
