import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { BrowserRouter } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import TPGrid from 'components/TP-UI/TPGrid';
import { required } from 'utils/validation/fieldValidationRules';
import TPTextField from 'components/TP-UI/TPTextField';
import TPButton from 'components/TP-UI/TPButton';
import TPLink from 'components/TP-UI/TPLink';
import AppleSignup from '../../../../components/AppleSignup';
import PhoneVerificationContainer from '../../../../containers/PhoneVerificationContainer';
import GoogleSignup from '../../../../components/GoogleSignup';
import TPCheckbox from 'components/TP-UI/TPCheckbox';
import { useSelector } from 'react-redux';
import { getUserDocuments } from 'modules/auth/selectors';
import FormError from 'modules/common/FormError';
import { REDUX_FORM_DEFAULT_CONFIG } from 'constants/form';
import {
  CONFIRMATION_PASSWORD_VALIDATORS,
  EMAIL_VALIDATORS,
  PASSWORD_VALIDATORS,
} from 'utils/validation/fieldsValidators';

import useStyles from './styles';

const SignUpForm = ({
  handleSubmit,
  error = null,
  submitFailed,
  isPending = false,
  fsaHub,
  phoneRequired,
  onAppleSignupSuccess,
  onGoogleSignupSuccess,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('auth');
  const documents = useSelector(getUserDocuments);
  const documentLink = fsaHub ? documents.sey_terms : documents.terms;

  return (
    <form className={classes.form} onSubmit={handleSubmit} noValidate id="sign-up-form">
      <TPGrid container spacing={5} direction="column">
        <TPGrid item container spacing={3} direction="column">
          {error && submitFailed && (
            <TPGrid item>
              <FormError error={error} />
            </TPGrid>
          )}
          <TPGrid item container spacing={1} direction="column">
            <TPGrid item>
              <Field
                name="email"
                label={t('common:labels.email')}
                placeholder={t('common:labels.email')}
                type="text"
                component={TPTextField}
                autofocus
                autocomplete="new-email"
                fullWidth
                required
                validate={[required, ...EMAIL_VALIDATORS]}
              />
            </TPGrid>
            {phoneRequired && (
              <TPGrid item>
                <PhoneVerificationContainer />
              </TPGrid>
            )}
            <TPGrid item>
              <Field
                name="password"
                label={t('common:labels.createPassword')}
                placeholder={t('common:labels.createPassword')}
                autocomplete="new-password"
                confidential
                fullWidth
                component={TPTextField}
                hint={t('tipText')}
                required
                validate={[required, ...PASSWORD_VALIDATORS]}
              />
            </TPGrid>
            <TPGrid item>
              <Field
                name="confirmPassword"
                label={t('common:labels.confirmPassword')}
                placeholder={t('common:labels.confirmPassword')}
                autocomplete="off"
                confidential
                fullWidth
                component={TPTextField}
                required
                validate={[required, ...CONFIRMATION_PASSWORD_VALIDATORS]}
              />
            </TPGrid>
            <TPGrid item>
              <BrowserRouter basename="/">
                <Field
                  name="conditions"
                  component={TPCheckbox}
                  required
                  label={
                    <>
                      <Trans i18nKey="confirm18Years" ns="auth">
                        I confirm I am over 18 years and accept the
                        <TPLink target="_blank" rel="noopener noreferrer" path={documentLink}>
                          Terms and Conditions
                        </TPLink>
                      </Trans>
                    </>
                  }
                  validate={[required]}
                />
              </BrowserRouter>
            </TPGrid>
            <TPGrid item>
              <TPButton
                fullWidth
                primary
                size="large"
                type="submit"
                loading={isPending}
                id="sign-up-button">
                {t('buttons.signUp')}
              </TPButton>
            </TPGrid>
          </TPGrid>
        </TPGrid>
        <TPGrid item container spacing={2} direction="column">
          <TPGrid item>
            <GoogleSignup disabled={isPending} onSuccess={onGoogleSignupSuccess} />
          </TPGrid>
          <TPGrid item>
            <AppleSignup disabled={isPending} onSuccess={onAppleSignupSuccess} />
          </TPGrid>
        </TPGrid>
      </TPGrid>
    </form>
  );
};

SignUpForm.propTypes = {
  form: PropTypes.string,
  isPending: PropTypes.bool,
  fsaHub: PropTypes.bool,
  phoneRequired: PropTypes.bool,
  onAppleSignupSuccess: PropTypes.func.isRequired,
  onGoogleSignupSuccess: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default reduxForm({ ...REDUX_FORM_DEFAULT_CONFIG })(SignUpForm);
