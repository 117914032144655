export const RAF_TYPES = {
  ONCE_OFF_PAYMENTS: 'ONCE_OFF_PAYMENTS',
  REDUCE_COMMISSIONS: 'REDUCE_COMMISSIONS',
};

export const REFERRAL_EARNINGS_WITHDRAWAL_MODAL = 'REFERRAL_EARNINGS_WITHDRAWAL_MODAL';
export const REFERRAL_EARNINGS_WITHDRAWAL_MODAL_FORM = 'REFERRAL_EARNINGS_WITHDRAWAL_MODAL_FORM';

export const REFERRAL_STATUS = {
  NOT_TRADED: 'not_traded',
  VERIFYING: 'verifying',
};
