import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    text: {
      marginBottom: theme.spacing(1),
    },
  }),
  { name: 'TooltipContent' },
);
