import api from 'modules/api/index';

export const setProUserRequest = (payload) => {
  const { userId } = payload;
  return api.post(`/pro/${userId}/switch`, payload);
};

export const upgradeToProRequest = (payload) => {
  return api.post(`/pro/request`, payload);
};
