import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    notificationsContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      paddingRight: theme.spacing(1),
    },
    noDataContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    noDataImage: {
      margin: `${theme.spacing(2)}px 0`,
    },
  }),
  { name: 'NotificationsTabContent' },
);
