import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    googleButtonContainer: {
      padding: `0 ${theme.spacing(1)}`,
      height: '48px',
      display: 'flex',
      alignItems: 'stretch',
      ...theme.thirdPartyButton.root,
      '&:hover': {
        ...theme.thirdPartyButton.hover,
      },
    },
    googleButtonWrapper: {
      '&:hover': {
        backgroundColor: '#f8fafe', // since this color is used by google and we can't override it
      },
    },
    googleButton: {
      height: '38px',
      overflow: 'hidden',
      marginTop: '6px',
    },
    googleButtonInner: {
      marginTop: '-3px',
      height: '36px',
      overflow: 'hidden',
    },
    disabled: {
      ...theme.thirdPartyButton.disabled,
    },
  }),
  { name: 'GoogleButton' },
);
