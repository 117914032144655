import { CUSTOM_DATE_RANGE_KEY } from '../constants';
import fmDate from 'utils/fmDate';

export function getDateRangeValuesByCustomType(value) {
  const todayStart = () => fmDate().startOf('day');
  const todayEnd = () => fmDate().endOf('day');
  const formatted = (date) => date.toISOString();

  switch (value) {
    case CUSTOM_DATE_RANGE_KEY.TODAY:
    case 'custom':
      return {
        dateFrom: formatted(todayStart()),
        dateTo: formatted(todayEnd()),
      };
    case CUSTOM_DATE_RANGE_KEY.YESTERDAY:
      return {
        dateFrom: formatted(todayStart().subtract(1, 'day')),
        dateTo: formatted(todayEnd().subtract(1, 'day')),
      };
    case CUSTOM_DATE_RANGE_KEY.LAST_7_DAYS:
      return {
        dateFrom: formatted(todayStart().subtract(7, 'day')),
        dateTo: formatted(todayEnd()),
      };
    case CUSTOM_DATE_RANGE_KEY.LAST_30_DAYS:
      return {
        dateFrom: formatted(todayStart().subtract(30, 'day')),
        dateTo: formatted(todayEnd()),
      };
    case CUSTOM_DATE_RANGE_KEY.WEEK_TO_DATE:
      return {
        dateFrom: formatted(fmDate().startOf('week')),
        dateTo: formatted(todayEnd()),
      };
    case CUSTOM_DATE_RANGE_KEY.MONTH_TO_DATE:
      return {
        dateFrom: formatted(fmDate().startOf('month')),
        dateTo: formatted(todayEnd()),
      };
    case CUSTOM_DATE_RANGE_KEY.QUARTER_TO_DATE:
      return {
        dateFrom: formatted(fmDate().startOf('quarter')),
        dateTo: formatted(todayEnd()),
      };
    case CUSTOM_DATE_RANGE_KEY.YEAR_TO_DATE:
      return {
        dateFrom: formatted(fmDate().startOf('year')),
        dateTo: formatted(todayEnd()),
      };
    case CUSTOM_DATE_RANGE_KEY.PREVIOUS_WEEK:
      return {
        dateFrom: formatted(
          fmDate()
            .subtract(1, 'week')
            .startOf('week'),
        ),
        dateTo: formatted(
          fmDate()
            .subtract(1, 'week')
            .endOf('week'),
        ),
      };
    case CUSTOM_DATE_RANGE_KEY.PREVIOUS_MONTH:
      return {
        dateFrom: formatted(
          fmDate()
            .subtract(1, 'month')
            .startOf('month'),
        ),
        dateTo: formatted(
          fmDate()
            .subtract(1, 'month')
            .endOf('month'),
        ),
      };
    case CUSTOM_DATE_RANGE_KEY.PREVIOUS_QUARTER:
      return {
        dateFrom: formatted(
          fmDate()
            .subtract(1, 'quarter')
            .startOf('quarter'),
        ),
        dateTo: formatted(
          fmDate()
            .subtract(1, 'quarter')
            .endOf('quarter'),
        ),
      };
    default:
      return {
        dateFrom: formatted(todayStart()),
        dateTo: formatted(todayEnd()),
      };
  }
}
