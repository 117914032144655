import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    signupButton: {
      padding: `0 ${theme.spacing(1)}`,
      height: '48px',
      '& path': {
        fill: theme.thirdPartyButton.root.color,
      },
      '& svg': {
        fill: theme.thirdPartyButton.root.color,
      },
      '& text': {
        fontFamily: theme.thirdPartyButton.root.fontFamily,
      },
      ...theme.thirdPartyButton.root,
      '&:hover': {
        ...theme.thirdPartyButton.hover,
      },
    },
    disabled: {
      ...theme.thirdPartyButton.disabled,
    },
  }),
  { name: 'AppleSignup' },
);

export default useStyles;
