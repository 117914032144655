export default (theme) => ({
  formControlLabel: {},
  formControlLabelWithClearIcon: {
    marginRight: 0,
  },
  checkbox: {
    padding: '2px 12px',
  },
  label: {
    fontFamily: 'Lato, sans-serif',
    color: theme.palette.text.main,
    fontSize: 16,
    fontWeight: '300',
    lineHeight: '20px',
  },
  disabled: {},
  checkboxChecked: {},
  icon: {
    fontSize: 19,
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: '12px',
    margin: '5px',
  },
});
