import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    link: {
      flex: 1,
    },
    iconWithExpand: {
      marginLeft: theme.spacing(2),
    },
    iconWithoutExpand: {
      margin: `0 ${theme.spacing(2)}px`,
    },
  }),
  { name: 'TPBottomNavigationAction' },
);

export default useStyles;
