import palette from './palette';

const OverridesMuiIconButton = {
  root: {
    color: palette.primary.text,
  },
  label: {
    fontSize: 'inherit',
  },
};

export default OverridesMuiIconButton;
