import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    imageContainer: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  }),
  { name: 'QuizModal' },
);
