export const TRANSFER_ACCOUNTS_FORM = 'TRANSFER_ACCOUNTS_FORM';
export const TRANSFER_CURRENCY_CLOUD_ACCOUNTS_FORM = 'TRANSFER_CURRENCY_CLOUD_ACCOUNTS_FORM';
export const TRANSFER_SEARCH_FORM = 'TRANSFER_SEARCH_FORM';

export const DIALOG_NAME = {
  CONFIRM_TRANSFER: 'CONFIRM_TRANSFER',
};

export const CURRENCY_CLOUD_TRANSFER_QUOTE_EXPIRED_MODAL =
  'CURRENCY_CLOUD_TRANSFER_QUOTE_EXPIRED_MODAL';
export const CURRENCY_CLOUD_UNAVAILABLE_MODAL = 'CURRENCY_CLOUD_UNAVAILABLE_MODAL';
export const WEEKEND_RATES_MODAL = 'WEEKEND_RATES_MODAL';
export const UNFAVOURABLE_RATES_MODAL = 'UNFAVOURABLE_RATES_MODAL';
export const CURRENCY_CLOUD_RATES_UNAVAILABLE_MODAL = 'CURRENCY_CLOUD_RATES_UNAVAILABLE_MODAL';
export const CURRENCY_CLOUD_TRANSFER_STATES = {
  form: 'form',
  quotes: 'quotes',
  success: 'success',
};

export const CURRENCY_CLOUD_TRANSFER_ALLOWED_COUNTRIES = [
  'Austria',
  'United Kingdom of Great Britain and Northern Ireland',
  'Italy',
  'Germany',
  'France',
];

export const CURRENCY_CLOUD_MIN_VALUES = {
  GBP: 1,
  USD: 2,
  EUR: 2,
  AUD: 3,
  CAD: 3,
  HKD: 20,
  JPY: 385,
  SGD: 3,
  THB: 92,
  NZD: 4,
  CZK: 59,
  DKK: 17,
  PLN: 10,
  NOK: 27,
  SEK: 27,
  HUF: 1000,
};
