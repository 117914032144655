import { handleActions } from 'redux-actions';
import { getAvailableLocalBankPspSuccess } from '../actions';

const defaultState = [];

export default handleActions(
  {
    [getAvailableLocalBankPspSuccess](state, action) {
      return action.response.data;
    },
  },
  defaultState,
);
