import { combineActions, handleActions } from 'redux-actions';

import { selectAvailableHubs } from '../pages/SignIn/actions';

const defaultState = [];

export default handleActions(
  {
    [combineActions(selectAvailableHubs)](state, action) {
      return action.payload;
    },
  },
  defaultState,
);
