import { createSelector } from 'reselect';
import find from 'lodash/find';
import pick from 'lodash/pick';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import i18n from 'i18n';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import config from '../../../config';

import { formatAlphabeticalCountries, formatCountries } from '../helpers';
import { getCurrentLanguage } from 'modules/common/LanguageToggle/selectors';
import { getHub } from 'components/App/selectors';
import { FUSION_MARKETS_PREFIX } from 'constants/index';

export const getCountries = (state) => state.geolocation.countries;

export const getFormattedCountries = createSelector(getCountries, formatCountries);

export const getDefaultCountry = createSelector(getFormattedCountries, (countries) => {
  return process.env.REACT_APP_ENV === 'productionX'
    ? find(countries, { alpha2Code: 'TH' })
    : find(countries, { alpha2Code: 'AU' });
});

export const getDefaultPhoneCode = createSelector(getDefaultCountry, (country) =>
  country ? `+${country.callingCodes[0]}` : '',
);

export const getAlphabeticalCountries = createSelector(
  getFormattedCountries,
  formatAlphabeticalCountries,
);

export const getIpCountryCode = (state) => get(state, 'geolocation.ipCountry.countryCode');
export const getIpCountryName = (state) => get(state, 'geolocation.ipCountry.country');

export const getCurrentLocation = createSelector(
  getFormattedCountries,
  getIpCountryCode,
  getDefaultCountry,
  (countries, ipCountry, defaultCountry) => {
    const country = find(countries, { alpha2Code: ipCountry }) || defaultCountry;
    return country;
  },
);

export const getAllowTranslation = createSelector(getIpCountryCode, getHub, (ipCountry, hub) => {
  const { TRANSLATIONS_NOT_ALLOWED_COUNTRY_CODES } = config;

  return !TRANSLATIONS_NOT_ALLOWED_COUNTRY_CODES.has(ipCountry) && hub !== FUSION_MARKETS_PREFIX;
});

export const getCurrentPhoneCode = createSelector(getCurrentLocation, (country) => {
  const phoneCode = get(country, 'callingCodes.0', '');
  return phoneCode ? `+${phoneCode}` : '';
});

/* TODO TP: will be removed after all pages that use it will be redesigned */
export const getFormattedCountriesForSelect = createSelector(
  getFormattedCountries,
  getCurrentLanguage,
  (countries) => {
    const formattedCountries = countries.map((country) =>
      pick(country, ['label', 'value', 'disabled', 'favorite', 'favoriteIndex']),
    );

    const favoritesCountries = sortBy(
      filter(formattedCountries, (country) => country.favorite),
      ['favoriteIndex'],
    );
    const otherCountries = filter(formattedCountries, (country) => !country.favorite);
    const countriesList = isEmpty(formattedCountries)
      ? [{ label: i18n.t('common:constants.allCountries'), value: '' }]
      : [
          { label: i18n.t('common:constants.allCountries'), value: '' },
          {
            label: i18n.t('common:constants.favoriteCountries'),
            value: 'favorite',
            disabled: true,
          },
          ...favoritesCountries,
          { label: i18n.t('common:constants.otherCountries'), value: 'other', disabled: true },
          ...otherCountries,
        ];
    return countriesList;
  },
);

export const getGroupedCountriesForSelect = createSelector(
  getFormattedCountries,
  getCurrentLanguage,
  (countries) => {
    const formattedCountries = countries.map((country) =>
      pick(country, ['label', 'value', 'disabled', 'favorite', 'favoriteIndex']),
    );

    const favoritesCountries = sortBy(
      filter(formattedCountries, (country) => country.favorite),
      ['favoriteIndex'],
    );
    const otherCountries = filter(formattedCountries, (country) => !country.favorite);
    return isEmpty(formattedCountries)
      ? []
      : [
          {
            label: i18n.t('common:constants.favoriteCountries'),
            options: favoritesCountries,
          },
          { label: i18n.t('common:constants.otherCountries'), options: otherCountries },
        ];
  },
);
