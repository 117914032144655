import React from 'react';
import PropTypes from 'prop-types';
import Page from 'modules/common/Page';
import { useTranslation } from 'react-i18next';
import config from 'config';
import Layout from '../../../../components/LayoutNew';
import ResetPasswordForm from '../ResetPasswordForm';
import TPTypography from 'components/TP-UI/TPTypography';
import TPLink from 'components/TP-UI/TPLink';
import { Routes } from 'constants/routeConstants';
import TPGrid from 'components/TP-UI/TPGrid';

import useStyles from './styles';

const ResetPassword = ({ form, onSubmit, validate, isPending }) => {
  const classes = useStyles();
  const { t } = useTranslation('auth');
  const { CURRENT_HUB_LABEL } = config;

  return (
    <Page docTitle={t('resetPassword.documentTitle', { company: CURRENT_HUB_LABEL })}>
      <Layout
        title={t('resetPassword.title')}
        welcomeTitle={t('logInWelcome')}
        welcomeDescription={t('logInWelcomeDescription')}>
        <TPGrid container spacing={5}>
          <TPGrid item xs={12}>
            <ResetPasswordForm
              form={form}
              onSubmit={onSubmit}
              validate={validate}
              isPending={isPending}
            />
          </TPGrid>
          <TPGrid item xs={12}>
            <div className={classes.customLinkWrapper}>
              <TPTypography variant="body1" className={classes.customLinkText}>
                {t('resetPassword.rememberOldPassword')}{' '}
                <TPLink to={{ pathname: Routes.SIGNIN, state: { cleanupStore: true } }}>
                  {t('buttons.logIn')}
                </TPLink>
              </TPTypography>
            </div>
          </TPGrid>
        </TPGrid>
      </Layout>
    </Page>
  );
};

ResetPassword.propTypes = {
  form: PropTypes.string.isRequired,
  validate: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
};

export default ResetPassword;
