import { combineReducers } from 'redux';

import userAccounts from './userAccounts';
import userEmails from './userEmails';
import userPayments from './userPayments';
import userRecentPayments from './userRecentPayments';
import affiliate from './affiliate';
import userEmailFeed from './userEmailFeed';
import userTradingHistory from './userTradingHistory';
import userCycle from './userCycle';
import userAccountsWithActiveTrades from './userAccountsWithActiveTrades';
import userOperationSub from './userOperationSub';
import userPaymentComments from './userPaymentComments';
import userQuizComments from './userQuizComments';
import userTickets from './userTickets';
import userQuiz from './userQuiz';
import userBalance from './userBalance';
import userLogs from './userLogs';
import userMatchback from './userMatchback';
import clientSale from './clientSale';

const reducer = combineReducers({
  userAccounts,
  userEmails,
  userPayments,
  userRecentPayments,
  affiliate,
  userEmailFeed,
  userTradingHistory,
  userCycle,
  userAccountsWithActiveTrades,
  userOperationSub,
  userPaymentComments,
  userQuizComments,
  userTickets,
  userQuiz,
  userBalance,
  userLogs,
  userMatchback,
  clientSale,
});

export default reducer;
