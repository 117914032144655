export const CONDITION_ID_1 = 'condition1';
export const CONDITION_ID_2 = 'condition2';
export const CONDITION_ID_3 = 'condition3';
export const CONDITION_ID_4 = 'condition4';

export const CONDITION_LIST = [
  {
    id: CONDITION_ID_1,
    text: '',
  },
  {
    id: CONDITION_ID_2,
    text: '',
  },
  {
    id: CONDITION_ID_3,
    text: '',
  },
  {
    id: CONDITION_ID_4,
    text: '',
  },
];
export const CONDITION_LIST_INITIAL_VALUES = {
  [CONDITION_ID_1]: false,
  [CONDITION_ID_2]: false,
  [CONDITION_ID_3]: false,
  [CONDITION_ID_4]: false,
  sign: '',
};
export const UPGRADE_TO_PRO_FORM = 'UPGRADE_TO_PRO_FORM';
