import React from 'react';
import classnames from 'classnames';

import useStyles from './styles';

const TPCardHeader = ({ className, children }) => {
  const classes = useStyles();
  return <div className={classnames(classes.root, className)}>{children}</div>;
};

export default TPCardHeader;
