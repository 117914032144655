import { combineReducers } from 'redux';

import history from './history';
import hyperwallet from './hyperwallet';
import bankDetails from './bankDetails';
import localBank from './localBank';
import finrax from './finrax';
import currencyCloud from './currencyCloud';
import day1Withdrawal from './day1Withdrawal';

const withdraw = combineReducers({
  history,
  hyperwallet,
  bankDetails,
  localBank,
  finrax,
  currencyCloud,
  day1Withdrawal,
});

export default withdraw;
