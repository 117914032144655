import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import PrimaryButton from 'modules/common/PrimaryButton';
import SecondaryButton from 'modules/common/SecondaryButton';

const TableButtonsWithConditionField = ({
  classes,
  twoButtonsFieldProps,
  conditionLabel,
  condition,
  tableItem,
  openDeclineModal,
  openConfirmModal,
}) => {
  return (
    <div className={classes.item}>
      {condition && get(tableItem, conditionLabel) === condition ? (
        <div className={classes.buttonsContainer}>
          <SecondaryButton
            color="primary"
            label={twoButtonsFieldProps.leftButtonLabel}
            className={`${classes.button} ${classes.button__small}`}
            onClick={() => openDeclineModal(tableItem)}
          />
          <PrimaryButton
            color="primary"
            label={twoButtonsFieldProps.rightButtonLabel}
            className={`${classes.button} ${classes.button__large}`}
            onClick={() => openConfirmModal(tableItem)}
          />
        </div>
      ) : null}
    </div>
  );
};

TableButtonsWithConditionField.propTypes = {
  dataLabel: PropTypes.string,
  conditionLabel: PropTypes.string,
  condition: PropTypes.any,
  tableItem: PropTypes.object.isRequired,
  openConfirmModal: PropTypes.func.isRequired,
  openDeclineModal: PropTypes.func.isRequired,
  twoButtonsFieldProps: PropTypes.shape({
    leftButtonLabel: PropTypes.string.isRequired,
    rightButtonLabel: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(TableButtonsWithConditionField);
