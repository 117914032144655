import { combineReducers } from 'redux';

import withdrawalRequests from '../pages/WithdrawalRequests/reducers';
import paymentsHistory from '../pages/History/reducers';
import pendingPaymentsHistory from '../pages/PendingHistory/reducers';
import automatedApprovalsHistory from '../pages/AutomatedApprovals/reducers';
import withdrawalDetails from '../pages/ViewDetails/reducers';
import withdrawalComments from '../pages/ViewDetails/reducers/comments';
import withdrawalAvailableLocalBankPsp from '../pages/ViewDetails/reducers/availableLocalBankPsp';
import liveAccounts from './liveAccountsReducer';

const reducer = combineReducers({
  withdrawalRequests,
  withdrawalDetails,
  withdrawalComments,
  liveAccounts,
  paymentsHistory,
  pendingPaymentsHistory,
  automatedApprovalsHistory,
  withdrawalAvailableLocalBankPsp,
});

export default reducer;
