const formatNumber = (number = 0, digit = 2, minDigits = 0) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: digit,
    //current Balance implementation
    roundingMode: 'halfFloor',
  }).format(number);
};

export default formatNumber;
