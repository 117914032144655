import { createAction } from 'redux-actions';

export const getFullEmployeesRequest = createAction('GET_FULL_EMPLOYEES_REQUEST');
export const getFullEmployeesSuccess = createAction('GET_FULL_EMPLOYEES_SUCCESS');
export const getFullEmployeesFail = createAction('GET_FULL_EMPLOYEES_FAIL');

export const getEmployeesRequest = createAction('GET_EMPLOYEES_REQUEST');
export const getEmployeesSuccess = createAction('GET_EMPLOYEES_SUCCESS');
export const getEmployeesFail = createAction('GET_EMPLOYEES_FAIL');
export const cleanupEmployees = createAction('CLEANUP_EMPLOYEES');

export const editEmployeesPermissionsRequest = createAction('EDIT_EMPLOYEES_PERMISSIONS_REQUEST');
export const editEmployeesPermissionsSuccess = createAction('EDIT_EMPLOYEES_PERMISSIONS_SUCCESS');
export const editEmployeesPermissionsFail = createAction('EDIT_EMPLOYEES_PERMISSIONS_FAIL');

export const editEmployeesPasswordRequest = createAction('EDIT_EMPLOYEES_PASSWORD_REQUEST');
export const editEmployeesPasswordSuccess = createAction('EDIT_EMPLOYEES_PASSWORD_SUCCESS');
export const editEmployeesPasswordFail = createAction('EDIT_EMPLOYEES_PASSWORD_FAIL');

export const editEmployeesAssignedCxdRequest = createAction('EDIT_EMPLOYEES_ASSIGNED_CXD_REQUEST');
export const editEmployeesAssignedCxdSuccess = createAction('EDIT_EMPLOYEES_ASSIGNED_CXD_SUCCESS');
export const editEmployeesAssignedCxdFail = createAction('EDIT_EMPLOYEES_ASSIGNED_CXD_FAIL');

export const editEmployeesDisableMfaRequest = createAction('EDIT_EMPLOYEES_DISABLE_MFA_REQUEST');
export const editEmployeesDisableMfaSuccess = createAction('EDIT_EMPLOYEES_DISABLE_MFA_SUCCESS');
export const editEmployeesDisableMfaFail = createAction('EDIT_EMPLOYEES_DISABLE_MFA_FAIL');

export const editEmployeesRoleRequest = createAction('EDIT_EMPLOYEES_ROLE_REQUEST');
export const editEmployeesRoleSuccess = createAction('EDIT_EMPLOYEES_ROLE_SUCCESS');
export const editEmployeesRoleFail = createAction('EDIT_EMPLOYEES_ROLE_FAIL');

export const getEmailSendersRequest = createAction('GET_EMAIL_SENDERS_REQUEST');
export const getEmailSendersSuccess = createAction('GET_EMAIL_SENDERS_SUCCESS');
export const getEmailSendersFail = createAction('GET_EMAIL_SENDERS_FAIL');

export const openEditModal = createAction('OPEN_EDIT_MODAL');
export const editEmployeesSave = createAction('EDIT_EMPLOYEES_SAVE');
