import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    customLinkWrapper: {
      textAlign: 'center',
    },
    customLinkText: {
      margin: `0 0 ${theme.spacing(1)}px 0`,
    },
  }),
  { name: 'ResetPassword' },
);

export default useStyles;
