import api from 'modules/api';

export const getAccountsRequest = (payload) => {
  return api.get('/account', { params: payload });
};

export const createAccountRequest = (payload) => {
  return api.post('/account', payload);
};

export const updateAccountRequest = (payload) => {
  delete payload.accountName;
  delete payload.platform;

  return api.patch('/account', payload);
};

export const fundDemoAccountRequest = (payload) => {
  const { amount, type } = payload;
  return api.post(`/account/${payload.accountId}/fund`, { amount, type });
};

export const getLiveAccountsRequest = (value) => {
  return api.get('/account/managed-accounts', { params: { mt4Login: value } });
};

export const changeLiveAccountSettingsRequest = (payload) => {
  return api.patch(`/account/${payload._id}`, payload);
};

export const hideAccountRequest = (payload) => {
  const { accountId, isHidden } = payload;
  return api.post(`/account/${accountId}/hide`, { isHidden });
};

export const sortAccountRequest = (payload) => {
  const { accountId, indexId } = payload;
  return api.post(`/account/${accountId}/sort`, { indexId });
};

export const unArchiveAccountRequest = (payload) => {
  return api.post(`/account/unarchive`, payload);
};

export const uploadArchivingRequest = (payload) => {
  return api.post(`/account/upload-archive`, payload);
};

export const setDemoAccountUnlimitedExpirationRequest = ({ id, isLimitless }) => {
  return api.post(`/account/${id}/set-limitless`, { isLimitless });
};

export const selectAccountPlanRequest = (selectedPlan) =>
  api.patch('users/account-plan', { selectedPlan });

export const proSignRequest = ({ sign, signStamp }) =>
  api.patch('users/pro-sign', { sign, signStamp });

export const upgradeToProRequest = (isUpgradeToPro) =>
  api.patch('users/pro-upgrade', { isUpgradeToPro });
