import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    infoIcon: {
      marginRight: theme.spacing(0.5),
      verticalAlign: 'text-bottom',
    },
    minimizedInfo: {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  }),
  { name: 'InfoTip' },
);

export default useStyles;
