import { CURRENCY } from '../constants';

const MLN = 1000000;

export default (amount, currency = CURRENCY.USD.label) => {
  // to prevent application crash
  if (currency === '') {
    currency = CURRENCY.USD.label;
  }
  if (!amount) {
    amount = 0;
  }
  let formattedAmount;
  let postfix = '';
  if (Intl) {
    if (Math.abs(amount) > MLN) {
      amount = (amount / MLN).toFixed(4);
      postfix = 'M';
    }

    const formatter = new Intl.NumberFormat('en-US', {
      currency,
      style: 'currency',
      minimumFractionDigits: 0,
    });

    if (currency.toUpperCase() === CURRENCY.SGD.label) {
      formattedAmount = formatter
        .format(Math.abs(amount))
        .replace(CURRENCY.SGD.label, '')
        .trim();
      const sign = amount < 0 ? '-' : '';
      formattedAmount = sign + 'S$ ' + formattedAmount + postfix;
    } else {
      formattedAmount = formatter.format(amount).replace(/^(\D+)/, '$1\u00a0') + postfix;
    }
  } else {
    formattedAmount =
      Number(amount)
        .toLocaleString('en-US', {
          currency,
          style: 'currency',
        })
        .replace(/^(\D+)/, '$1\u00a0') + postfix;
  }
  return formattedAmount;
};
