import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    error: {
      ...theme.textError.root,
    },
    icon: {
      marginRight: theme.spacing(0.5),
    },
    mediumSize: {
      ...theme.textError.mediumSize,
    },
  }),
  { name: 'TPErrorMessage' },
);
