import { createAction } from 'redux-actions';
import { ALERT_VARIANTS } from 'components/TP-UI/TPAlert/constants';

export const SHOW_NOTIFICATION_ALERT = 'SHOW_NOTIFICATION_ALERT';
export const HIDE_NOTIFICATION_ALERT = 'HIDE_NOTIFICATION_ALERT';
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';

export const showNotificationAlert = createAction(SHOW_NOTIFICATION_ALERT);
export const hideNotificationAlert = createAction(HIDE_NOTIFICATION_ALERT);
export const resetNotifications = createAction(RESET_NOTIFICATIONS);

export const showNotificationInfo = (message, options = {}) =>
  showNotificationAlert({
    ...options,
    message,
    variant: ALERT_VARIANTS.SUCCESS,
  });

export const showNotificationMessage = (message, options = {}) =>
  showNotificationAlert({
    ...options,
    message,
    variant: ALERT_VARIANTS.INFO,
  });

export const showNotificationError = (message, options = {}) =>
  showNotificationAlert({
    ...options,
    message,
    variant: ALERT_VARIANTS.ERROR,
  });

export const removeNotification = (key) => hideNotificationAlert(key);
