import React, { useCallback } from 'react';
import TMDWarningModal from '../../components/TMDWarningModal';
import { closeModal } from 'components/TP-UI/TPModal/actions';
import { TMD_WARNING_MODAL } from '../../constants';
import { useDispatch } from 'react-redux';

const TMDWarningModalContainer = ({ onOpenQuiz }) => {
  const dispatch = useDispatch();

  const handleAccepted = useCallback(() => {
    dispatch(closeModal(TMD_WARNING_MODAL));
    onOpenQuiz();
  }, [dispatch, onOpenQuiz]);

  const handleCancel = useCallback(() => {
    dispatch(closeModal(TMD_WARNING_MODAL));
  }, [dispatch]);

  return <TMDWarningModal onAccepted={handleAccepted} onCancel={handleCancel} />;
};

export default TMDWarningModalContainer;
