import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'components/TP-UI/TPModal/actions';
import { REJECTION_MODAL } from '../constants';
import RejectionModal from '../components/RejectionModal';
import { loginSinceRejectionRequest } from 'modules/auth/actions';
import saga from '../sagas';
import { store } from 'components/App';

const RejectionModalContainer = () => {
  const dispatch = useDispatch();
  const handleClose = useCallback(() => {
    dispatch(closeModal(REJECTION_MODAL));
    dispatch(loginSinceRejectionRequest());
  }, [dispatch]);

  useEffect(() => {
    store.injectSaga('ApplicationRejectionModal', saga);

    return () => {
      store.injectSaga('ApplicationRejectionModal', null);
    };
  }, []);

  return <RejectionModal modalName={REJECTION_MODAL} onClose={handleClose} />;
};

export default RejectionModalContainer;
