import React, { useCallback, useMemo, useState } from 'react';
import TPGrid from 'components/TP-UI/TPGrid';
import TPCard from 'components/TP-UI/TPCard';
import TPCardContent from 'components/TP-UI/TPCard/components/TPCardContent';
import TPCardTitle from 'components/TP-UI/TPCard/components/TPCardTitle';
import TPCardSubtitle from 'components/TP-UI/TPCard/components/TPCardSubtitle';
import TPCardDescription from 'components/TP-UI/TPCard/components/TPCardDescription';
import useStyles from './styles';
import TPDescriptionList from 'components/TP-UI/TPDescriptionList';
import formatFullDate from '../../../utils/formatFullDate';
import TPButton from 'components/TP-UI/TPButton';
import TPCardActions from 'components/TP-UI/TPCard/components/TPCardActions';
import TPIconButton from 'components/TP-UI/TPIconButton';
import EqualizerIcon from '@material-ui/icons/EqualizerOutlined';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GetAppIcon from '@material-ui/icons/GetApp';
import DotIcon from '@material-ui/icons/FiberManualRecord';
import TPCardHeader from 'components/TP-UI/TPCard/components/TPCardHeader';
import { Field, reduxForm } from 'redux-form';
import TPTypography from 'components/TP-UI/TPTypography';
import TPSelect from 'components/TP-UI/TPSelect';
import { formatCurrency } from '../../../utils/formatters';
import TPTrending from 'components/TP-UI/TPTrending';
import TPCardAvatar from 'components/TP-UI/TPCard/components/TPCardAvatar';
import logo from 'static/images/common/tools/mt4-logo.png';
import TPCardImage from '../../../components/TP-UI/TPCard/components/TPCardImage';

import chartImg from 'static/example/6.jpg';
import TPNumberDifference from '../../../components/TP-UI/TPNumberDifference';

const profileInfo = {
  email: 'bruce.wayne@fusionmarkets.com',
  phoneNumber: '+61419881215',
  dateOfBirth: '1991-06-28T12:27:55.201Z',
  location: 'Level 7, 222 Exhibition St, Melbourne, VIC, 3000, United Kingdom',
};

const accountInfo = {
  mt4Login: '48273',
  accountType: 'Raw',
  platform: 'mt4',
  server: 'FusionMarkets-Live',
  currency: 'usd',
  leverage: '1:1000',
};

const Cards = () => {
  const classes = useStyles();
  const [selected, setSelected] = useState();
  const profile = useMemo(() => ({ ...profileInfo }), []);
  const account = useMemo(() => ({ ...accountInfo }), []);
  const accountProps = useMemo(
    () => [
      {
        dataKey: 'mt4Login',
        label: 'MT4 Login',
      },
      {
        dataKey: 'accountType',
        label: 'Account Type',
      },
      {
        dataKey: 'platform',
        label: 'Platform',
      },
      {
        dataKey: 'server',
        label: 'Server',
      },
      {
        dataKey: 'currency',
        label: 'Currency',
        format: formatCurrency,
      },
      {
        dataKey: 'leverage',
        label: 'Leverage',
      },
    ],
    [],
  );
  const profileProps = useMemo(
    () => [
      {
        dataKey: 'email',
        label: 'Email',
      },
      {
        dataKey: 'phoneNumber',
        label: 'Phone Number',
      },
      {
        dataKey: 'dateOfBirth',
        label: 'Date of Birth',
        format: formatFullDate,
      },
      {
        dataKey: 'location',
        label: 'Location',
      },
    ],
    [],
  );

  const handleCardClick = useCallback(() => {
    setSelected((val) => !val);
  }, []);

  return (
    <TPGrid container spacing={1}>
      <TPGrid item xs={12}>
        <TPCard>
          <TPCardHeader>
            <div>
              <TPCardSubtitle>Bruce Wayne 003</TPCardSubtitle>
              <TPCardTitle>100 AUD</TPCardTitle>
            </div>
            <TPCardActions noGutters>
              <TPIconButton size="medium">
                <EqualizerIcon fontSize="medium" />
              </TPIconButton>
              <TPIconButton size="medium">
                <VisibilityIcon fontSize="medium" />
              </TPIconButton>
              <TPIconButton size="medium">
                <EditIcon fontSize="medium" />
              </TPIconButton>
            </TPCardActions>
          </TPCardHeader>
          <TPCardContent divider>
            <TPDescriptionList value={account} descriptionProps={accountProps} />
          </TPCardContent>
        </TPCard>
      </TPGrid>
      <TPGrid item xs={12}>
        <TPCard>
          <TPCardHeader>
            <TPCardTitle>Profile Details</TPCardTitle>
            <TPCardActions noGutters>
              <TPIconButton size="medium">
                <EditIcon fontSize="medium" />
              </TPIconButton>
            </TPCardActions>
          </TPCardHeader>
          <TPCardContent divider>
            <TPDescriptionList value={profile} descriptionProps={profileProps} />
          </TPCardContent>
        </TPCard>
      </TPGrid>
      <TPGrid item xs={6}>
        <TPCard fullWidth>
          <TPCardContent>
            <TPCardTitle>Select the Account</TPCardTitle>
            <TPCardDescription>
              Please select the account you wish to deposit into
            </TPCardDescription>
            <form className={classes.form}>
              <Field
                component={TPSelect}
                id="account"
                name="account"
                label="Account"
                placeholder="Please select account"
                options={[]}
                noOptionsText="No accounts"
                fullWidth
                required
                hint={
                  <>
                    Current account balance:{' '}
                    <TPTypography component="span" variant="inherit" color="primary">
                      13,550 USD
                    </TPTypography>
                  </>
                }
                className={classes.control}
              />
            </form>
            <TPCardTitle>Choose a payment method</TPCardTitle>
            <TPCardActions fullWidth className={classes.paymentButtons}>
              <TPButton alternative size="large">
                Visa
              </TPButton>
              <TPButton alternative size="large">
                Pay
              </TPButton>
              <TPButton alternative size="large">
                Bank
              </TPButton>
            </TPCardActions>
          </TPCardContent>
        </TPCard>
      </TPGrid>
      <TPGrid item xs={6}>
        <TPCard fullWidth>
          <TPCardContent>
            <TPCardTitle>Select the Account</TPCardTitle>
            <TPCardDescription>
              Please select the account you wish to deposit into
            </TPCardDescription>
            <form className={classes.form}>
              <Field
                component={TPSelect}
                id="account"
                name="account"
                label="Account"
                placeholder="Please select account"
                options={[]}
                noOptionsText="No accounts"
                fullWidth
                required
                hint={
                  <>
                    Current account balance:{' '}
                    <TPTypography component="span" variant="inherit" color="primary">
                      13,550 USD
                    </TPTypography>
                  </>
                }
                className={classes.control}
              />
              <TPCardActions>
                <TPButton primary size="large">
                  Transfer
                </TPButton>
              </TPCardActions>
              <TPTypography variant="body2" color="secondary" className={classes.note}>
                <TPTypography component="span" variant="inherit" color="primary">
                  Please note:
                </TPTypography>{' '}
                The currency conversion rates that are currently streaming are live market rates and
                the final amount converted may change significantly by the time your conversion is
                completed from one currency to the other due to market conditions.
              </TPTypography>
            </form>
          </TPCardContent>
        </TPCard>
      </TPGrid>
      <TPGrid item xs={3}>
        <TPCard fullWidth>
          <TPCardHeader>
            <TPCardTitle>A reduced commission rate on your trades</TPCardTitle>
          </TPCardHeader>
          <TPCardContent divider>
            <TPTypography>
              Refer 5 friends who deposit and trade more than 5 standard lots of FX, and Fusion will
              reduce your commission rate by 5%. Refer 10 friends and you will receive 10% off your
              commission.
            </TPTypography>
            <TPCardActions fullWidth>
              <TPButton alternative size="large">
                Select
              </TPButton>
            </TPCardActions>
          </TPCardContent>
        </TPCard>
      </TPGrid>
      <TPGrid item xs={3}>
        <TPCard fullWidth>
          <TPCardHeader>
            <TPCardTitle>USD/JPY may rise 38 - 68 pips</TPCardTitle>
            <TPCardSubtitle variant="body2" color="secondary">
              5:01 PM (+03:00 UTC)
            </TPCardSubtitle>
          </TPCardHeader>
          <TPCardContent>
            <TPCardSubtitle>
              <TPTrending label="USD/JPY Intraday: rebound" value={5} />
            </TPCardSubtitle>
            <TPTypography variant="body2">
              Long positions above 125.05 with targets at 126.30 & 126.60 in extension. Alternative
              scenario: below 125.05 look for further downside with 124.85 & 124.65 as targets.
            </TPTypography>
          </TPCardContent>
        </TPCard>
      </TPGrid>
      <TPGrid item xs={3}>
        <TPCard fullWidth selected={selected} onClick={handleCardClick}>
          <TPCardHeader>
            <TPCardTitle>USD/JPY may rise 38 - 68 pips</TPCardTitle>
            <TPCardSubtitle variant="body2" color="secondary">
              5:01 PM (+03:00 UTC)
            </TPCardSubtitle>
          </TPCardHeader>
          <TPCardContent>
            <TPCardSubtitle>
              <TPTrending label="USD/JPY Intraday: rebound" value={5} />
            </TPCardSubtitle>
            <TPTypography variant="body2">
              Long positions above 125.05 with targets at 126.30 & 126.60 in extension. Alternative
              scenario: below 125.05 look for further downside with 124.85 & 124.65 as targets.
            </TPTypography>
          </TPCardContent>
        </TPCard>
      </TPGrid>
      <TPGrid item xs={6}>
        <TPCard fullWidth>
          <TPCardContent>
            <TPCardAvatar primary>
              <WhatshotIcon />
            </TPCardAvatar>
            <TPCardTitle>Fusion Goes Crypto</TPCardTitle>
            <TPTypography>
              The reason we started Fusion Markets was to make{' '}
              <TPTypography variant="inherit" bold component="span">
                low-cost trading available to everyone, everywhere
              </TPTypography>
              .
            </TPTypography>
            <TPTypography>
              As Crypto CFD providers with decades of experience in Fintech, we see the
              inefficiencies of other exchanges, and
              <TPTypography variant="inherit" bold component="span">
                know crypto traders could have it better
              </TPTypography>
              .
            </TPTypography>
          </TPCardContent>
        </TPCard>
      </TPGrid>
      <TPGrid item xs={6}>
        <TPCard fullWidth>
          <TPCardHeader>
            <div>
              <TPCardTitle>MetaTrader 4</TPCardTitle>
              <TPCardDescription>Desktop & Mobile App</TPCardDescription>
            </div>
            <TPCardAvatar src={logo} className={classes.logo} variant="square" />
          </TPCardHeader>
          <TPCardContent divider>
            <TPTypography>
              MetaTrader 4 is the industry standard in FX trading. It is fast, convenient and
              extremely stable. While many brokers offer this platform to clients, it is important
              to note that it initially comes as an empty shell. It is what the broker puts into it
              that makes it so compelling.
            </TPTypography>
            <TPCardActions fullWidth>
              <TPGrid container spacing={1}>
                <TPGrid item container xs={12} md={6}>
                  <TPGrid item xs={12}>
                    <TPButton secondary size="large" className={classes.button}>
                      <GetAppIcon className={classes.buttonIcon} /> For windows
                    </TPButton>
                  </TPGrid>
                  <TPGrid item xs={12}>
                    <TPButton secondary size="large">
                      <GetAppIcon className={classes.buttonIcon} /> For android
                    </TPButton>
                  </TPGrid>
                </TPGrid>
                <TPGrid item container xs={12} md={6}>
                  <TPGrid item xs={12}>
                    <TPButton secondary size="large" className={classes.button}>
                      <GetAppIcon className={classes.buttonIcon} /> For iphone/ipad
                    </TPButton>
                  </TPGrid>
                  <TPGrid item xs={12}>
                    <TPButton secondary size="large">
                      <GetAppIcon className={classes.buttonIcon} /> For MAC
                    </TPButton>
                  </TPGrid>
                </TPGrid>
              </TPGrid>
            </TPCardActions>
          </TPCardContent>
        </TPCard>
      </TPGrid>
      <TPGrid item xs={4}>
        <TPCard fullWidth>
          <TPCardContent>
            <TPCardImage
              className={classes.image}
              //src="https://chart-img.com/assets/img/intros.png"
              src={chartImg}
              alt="News image"
            />
            <ul className={classes.inlineList}>
              <li className={classes.inlineListItem}>1 hour ago</li>
              <li className={classes.inlineListItem}>Action Forex</li>
            </ul>
            <TPCardTitle>GBP/USD Mid-Day Outlook</TPCardTitle>
            <TPTypography>
              Daily Pivots: (S1) 1.3004; (P) 1.3047; (R1) 1.3071; More … GBP/USD’s down trend
              resumes today by breaking through 1.2971 support. Intraday bias...
            </TPTypography>
            <ul className={classes.inlineList}>
              {['RB', 'AUD/USD', 'GBP/USD', 'NZD/USD'].map((tag) => (
                <li className={classes.inlineListItem} key={tag}>
                  {tag}
                </li>
              ))}
            </ul>
          </TPCardContent>
        </TPCard>
      </TPGrid>
      <TPGrid item xs={5}>
        <TPCard fullWidth>
          <TPCardHeader>
            <div className={classes.headerContainer}>
              <TPCardTitle className={classes.customTitle}>
                <DotIcon fontSize="small" className={classes.marker} />
                GBP/USD
              </TPCardTitle>
              <TPTypography variant="h5">1.28</TPTypography>
            </div>
            <TPTypography>United Kingdom Pound - United States Dollar</TPTypography>
          </TPCardHeader>
          <TPCardContent divider>
            <TPGrid container justifyContent="space-between">
              <TPTypography>413 Publications</TPTypography>
              <TPTypography variant="body2" color="secondary">
                <TPNumberDifference value={-28} /> in the last 7 days
              </TPTypography>
            </TPGrid>
          </TPCardContent>
        </TPCard>
      </TPGrid>
    </TPGrid>
  );
};

export default reduxForm({
  form: 'TP-CARDS-FORM',
  initialValues: {
    account: '',
  },
})(Cards);
