import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import useStyles from './styles';
import useChildrenProps from 'hooks/useChildrenProps';
import classnames from 'classnames';

const TPPopoverBase = ({
  content,
  children,
  placement = 'bottom-start',
  disabled,
  trigger = 'click',
  minWidth,
  maxWidth,
  offset = '0, 8',
  enterDelay = 0,
  className,
  forwardedRef,
  onClick,
  value = false,
  ...props
}) => {
  const classes = useStyles({ minWidth, maxWidth, hoverTrigger: trigger === 'hover' });
  const anchorRef = useRef(null);
  const childrenProps = useChildrenProps(children);
  const hasFullWidthChild = childrenProps.some((props) => props?.fullWidth);
  const timer = useRef();
  const [delay, setDelay] = useState(enterDelay);

  const [open, setOpen] = useState(value);

  useEffect(() => {
    setOpen(value);
  }, [value]);

  useEffect(() => {
    if (enterDelay >= 0) {
      setDelay(enterDelay);
    }
  }, [enterDelay]);

  const handlePopoverClose = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    if (!value) {
      setOpen(false);
    }
  }, [value]);

  const handleClickAway = useCallback(
    (event) => {
      if (
        !anchorRef?.current ||
        event.target === anchorRef.current ||
        anchorRef.current.contains(event.target)
      ) {
        return false;
      }
      handlePopoverClose();
    },
    [handlePopoverClose, anchorRef],
  );

  const handlePopoverToggle = useCallback(() => {
    if (!disabled) {
      if (delay > 0) {
        timer.current = setTimeout(() => {
          setOpen((open) => !open);
        }, delay);
        if (delay && delay === enterDelay) {
          setDelay(enterDelay * 2);
        }
      } else {
        setOpen((open) => !open);
      }
    }
  }, [disabled, delay, enterDelay]);

  const handlePopoverClick = useCallback(
    (e) => {
      if (onClick) {
        onClick(e);
      }
      if (trigger === 'click') {
        handlePopoverToggle(e);
      } else {
        //need to hide/prevent open tooltip when user clicks on target element
        handlePopoverClose(e);
      }
    },
    [onClick, trigger, handlePopoverToggle, handlePopoverClose],
  );

  const triggerHandlers = useMemo(() => {
    return {
      hover: {
        onMouseEnter: handlePopoverToggle,
        onMouseLeave: handlePopoverClose,
      },
    };
  }, [handlePopoverToggle, handlePopoverClose]);
  return (
    <>
      <div
        ref={(el) => {
          if (forwardedRef) {
            forwardedRef.current = el;
          }
          anchorRef.current = el;
        }}
        onClick={handlePopoverClick}
        {...triggerHandlers[trigger]}
        className={classnames(
          classes.container,
          {
            [classes.fullWidth]: hasFullWidthChild,
            [classes.disabled]: disabled,
          },
          className,
        )}
        {...props}>
        {children}
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement={placement}
        disablePortal
        transition
        modifiers={{
          offset: {
            enabled: true,
            offset: offset,
          },
        }}
        className={classes.popper}>
        <Paper
          className={classes.root}
          style={{
            minWidth,
            maxWidth,
          }}
          elevation={6}>
          <ClickAwayListener mouseEvent="onMouseUp" onClickAway={handleClickAway}>
            <div>{typeof content === 'function' ? React.createElement(content) : content}</div>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

TPPopoverBase.propTypes = {
  content: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node, PropTypes.string]),
  children: PropTypes.node.isRequired,
  placement: PropTypes.oneOf([
    'top-start',
    'top',
    'top-end',
    'left-start',
    'left',
    'left-end',
    'right-start',
    'right',
    'right-end',
    'bottom-start',
    'bottom',
    'bottom-end',
    'auto-start',
    'auto',
    'auto-end',
  ]),
  disabled: PropTypes.bool,
  trigger: PropTypes.oneOf(['click', 'hover']),
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  offset: PropTypes.string,
};

const TPPopover = React.forwardRef((props, ref) => <TPPopoverBase {...props} forwardedRef={ref} />);

export default TPPopover;
