import { TRADING_VIEW_PLATFORM } from '../../../constants';

export const UPGRADE_ACCOUNT_FORM = 'UPGRADE_ACCOUNT_FORM';

export const SPONSORED_VPS_CONFIRMATION_MODAL = 'SPONSORED_VPS_CONFIRMATION_MODAL';

export const SPONSORED_VPS_CANCELLATION_MODAL = 'SPONSORED_VPS_CANCELLATION_MODAL';

export const MT_PLATFORMS = {
  mt4: 'mt4',
  mt4_2: 'mt4',
  mt5: 'mt5',
  tradingView: TRADING_VIEW_PLATFORM,
  ctrader: 'ctrader',
};

export const NYC_SERVER = 'nyc';

export const VPS_SERVERS = {
  nyc: [MT_PLATFORMS.mt4, MT_PLATFORMS.ctrader, MT_PLATFORMS.tradingView],
  mt5: [MT_PLATFORMS.mt5],
};

export const VPS_OPTIONS = [
  { label: 'NYC VPS', value: 'nyc' },
  { label: 'MT5 VPS', value: 'mt5' },
];

export const VPS_MAX_LOTS = 20;
