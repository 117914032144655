import eurFlag from 'static/images/common/flags/EU.svg';
import audFlag from 'static/images/common/flags/Australia.svg';
import usdFlag from 'static/images/common/flags/UnitedStates.svg';
import jpyFlag from 'static/images/common/flags/Japan.svg';
import gbpFlag from 'static/images/common/flags/UK.svg';
import sgdFlag from 'static/images/common/flags/Singapore.svg';
import thbFlag from 'static/images/common/flags/Thailand.svg';
import cadFlag from 'static/images/common/flags/Canada.svg';
import hkdFlag from 'static/images/common/flags/HongKong.svg';
import nzdFlag from 'static/images/common/flags/NewZealand.svg';
import chfFlag from 'static/images/common/flags/Switzerland.svg';
import hufFlag from 'static/images/common/flags/Hungary.svg';
import czkFlag from 'static/images/common/flags/Czechrepublic.svg';
import dkkFlag from 'static/images/common/flags/Denmark.svg';
import plnFlag from 'static/images/common/flags/Poland.svg';
import nokFlag from 'static/images/common/flags/Norway.svg';
import sekFlag from 'static/images/common/flags/Sweden.svg';
import phpFlag from 'static/images/common/flags/Philippines.svg';
import cnyFlag from 'static/images/common/flags/China.svg';
import myrFlag from 'static/images/common/flags/Malaysia.svg';
import idrFlag from 'static/images/common/flags/Indonesia.svg';
import vndFlag from 'static/images/common/flags/Vietnam.svg';
import aedFlag from 'static/images/common/flags/UnitedArabEmirates.svg';
import { domain } from '../modules/api';
import { UPLOADER_FILE_TYPE } from 'components/TP-UI/TPFileUploader/constants';

export const DATE_FORMAT_DDMMYYYY = 'DD/MM/YYYY';
export const DATE_FORMAT_YYYYMMDD = 'YYYY-MM-DD';
export const DATE_FORMAT_FULL = 'Do MMMM YYYY';
export const DATE_FORMAT_MMYY = 'MM/YY';

export const TIME_FORMAT_hhmm_a = 'hh:mm a';

export const DATE_TIME_FORMAT = 'hh:mm a DD/MM/YYYY';
export const DATE_WITH_TIME_FORMAT = 'DD/MM/YYYY hh:mm a';

export const WEEK_DAYS_NUMBER = 7;
export const MONTH_DAYS_NUMBER = 30;
export const YEAR_DAYS_NUMBER = 365;

export const REFCODE_COOKIE_NAME = 'refcode';
export const REFCODE_COOKIE_HUB = 'refhub';

export const FOLLOWER_ID_COOKIE_NAME = 'followerId';
export const FOLLOWER_HUB_COOKIE_NAME = 'followerHub';
export const REFERRAL_COOKIE_NAME = 'cxd';
export const REFERRAL_COOKIE_HUB = 'cxdHub';
export const FM_REFERRAL_COOKIE_NAME = 'referral';
export const UTM_COOKIE_NAME = 'utm';
export const LANG_COOKIE_NAME = 'lang';
export const POLICY_COOKIE_NAME = 'policy';

export const DEFAULT_LANG = 'en';

// maximum number of documents that can be uploaded, there is the sumsub limitation.
export const MAX_PHOTO_ID_FILES_NUMBER = 4;
// maximum number of documents that can be uploaded, there is the sumsub limitation.
export const MAX_POR_FILES_NUMBER = 4;

export const MAX_LARGE_IMAGE_FILE_SIZE = 1024 * 1024 * 20;
export const MAX_LARGE_TEXT_FILE_SIZE = 1024 * 1024;
export const MAX_FILES_SIZE_IN_DROPZONE = 45000000;

export const DEFAULT_AMOUNT = 10000;
export const MAX_AMOUNT = 10000;
export const MIN_AMOUNT = 1;

export const ACCOUNT_STATUSES = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  INITIAL: 'initial',
};

export const MT4_PLATFORM = 'mt4';
export const MT4_2_PLATFORM = 'mt4_2';
export const MT5_PLATFORM = 'mt5';
export const CTRADER_PLATFORM = 'ctrader';
export const TRADING_VIEW_PLATFORM = 'tradingView';

export const TRADING_PLATFORMS = [
  { value: MT4_PLATFORM, label: 'MT4' },
  // { value: 'mt4_2', label: 'MT4' },
  { value: MT5_PLATFORM, label: 'MT5' },
  { value: TRADING_VIEW_PLATFORM, label: 'TradingView' },
  { value: CTRADER_PLATFORM, label: 'cTrader' },
];

export const TRADING_PLATFORMS_VIEW = [
  { value: MT4_PLATFORM, label: 'MT4' },
  { value: MT4_2_PLATFORM, label: 'MT4' },
  { value: MT5_PLATFORM, label: 'MT5' },
  { value: CTRADER_PLATFORM, label: 'cTrader' },
  { value: TRADING_VIEW_PLATFORM, label: 'TradingView' },
];

export const TRADING_SERVERS = [
  { value: MT4_PLATFORM, label: 'FusionMarkets-Live' },
  { value: MT4_2_PLATFORM, label: 'FusionMarkets-Live 2' },
  { value: MT5_PLATFORM, label: 'FusionMarkets-Live' },
  { value: CTRADER_PLATFORM, label: 'FusionMarkets-Live' },
  { value: TRADING_VIEW_PLATFORM, label: 'FusionMarkets-Live' },
];

export const TRADING_SERVERS_AU = [
  { value: MT4_PLATFORM, label: 'FusionMarkets-Live' },
  { value: MT4_2_PLATFORM, label: 'FusionMarkets-Live 2' },
  { value: MT5_PLATFORM, label: 'FusionMarketsAU-Live' },
  { value: CTRADER_PLATFORM, label: 'FusionMarkets-Live' },
  { value: TRADING_VIEW_PLATFORM, label: 'FusionMarkets-Live' },
];

export const TRADING_DEMO_SERVERS = [
  { value: MT4_PLATFORM, label: 'FusionMarkets-Demo' },
  { value: MT4_2_PLATFORM, label: 'FusionMarkets-Demo' },
  { value: MT5_PLATFORM, label: 'FusionMarkets-Demo' },
  { value: CTRADER_PLATFORM, label: 'FusionMarkets-Demo' },
  { value: TRADING_VIEW_PLATFORM, label: 'TradingView | FusionMarkets-Demo' },
];

export const TRADING_DEMO_SERVERS_AU = [
  { value: MT4_PLATFORM, label: 'FusionMarkets-Demo' },
  { value: MT4_2_PLATFORM, label: 'FusionMarkets-Demo' },
  { value: MT5_PLATFORM, label: 'FusionMarketsAU-Demo' },
  { value: CTRADER_PLATFORM, label: 'FusionMarkets-Demo' },
  { value: TRADING_VIEW_PLATFORM, label: 'TradingView | FusionMarkets-Demo' },
];

export const TRADING_SERVERS_FOR_STAFF = [
  { value: MT4_PLATFORM, label: 'MT4 | FusionMarkets-Live' },
  { value: MT4_2_PLATFORM, label: 'MT4 | FusionMarkets-Live 2' },
  { value: MT5_PLATFORM, label: 'MT5 | FusionMarkets-Live' },
  { value: CTRADER_PLATFORM, label: 'cTrader | FusionMarkets-Live' },
  { value: TRADING_VIEW_PLATFORM, label: 'TradingView | FusionMarkets-Live' },
];

export const TRADING_SERVERS_FOR_STAFF_AU = [
  { value: MT4_PLATFORM, label: 'MT4 | FusionMarkets-Live' },
  { value: MT4_2_PLATFORM, label: 'MT4 | FusionMarkets-Live 2' },
  { value: MT5_PLATFORM, label: 'MT5 | FusionMarketsAU-Live' },
  { value: CTRADER_PLATFORM, label: 'cTrader | FusionMarkets-Live' },
  { value: TRADING_VIEW_PLATFORM, label: 'TradingView | FusionMarkets-Live' },
];

export const TRADING_DEMO_SERVERS_FOR_STAFF = [
  { value: MT4_PLATFORM, label: 'MT4 | FusionMarkets-Demo' },
  { value: MT4_2_PLATFORM, label: 'MT4 | FusionMarkets-Demo' },
  { value: MT5_PLATFORM, label: 'MT5 | FusionMarkets-Demo' },
  { value: CTRADER_PLATFORM, label: 'cTrader | FusionMarkets-Demo' },
  { value: 'tradingView', label: 'TradingView | FusionMarkets-Demo' },
];

export const TRADING_DEMO_SERVERS_FOR_STAFF_AU = [
  { value: MT4_PLATFORM, label: 'MT4 | FusionMarkets-Demo' },
  { value: MT4_2_PLATFORM, label: 'MT4 | FusionMarkets-Demo' },
  { value: MT5_PLATFORM, label: 'MT5 | FusionMarketsAU-Demo' },
  { value: CTRADER_PLATFORM, label: 'cTrader | FusionMarkets-Demo' },
  { value: 'tradingView', label: 'TradingView | FusionMarkets-Demo' },
];

export const LEVERAGES = [
  { value: '100', label: '1:100' },
  { value: '200', label: '1:200' },
  { value: '300', label: '1:300' },
  { value: '400', label: '1:400' },
  { value: '500', label: '1:500' },
];

export const CURRENCY = {
  AUD: { value: 'aud', label: 'AUD', flag: audFlag },
  CAD: { value: 'cad', label: 'CAD', flag: cadFlag },
  EUR: { value: 'eur', label: 'EUR', flag: eurFlag },
  GBP: { value: 'gbp', label: 'GBP', flag: gbpFlag },
  JPY: { value: 'jpy', label: 'JPY', flag: jpyFlag },
  SGD: { value: 'sgd', label: 'SGD', flag: sgdFlag },
  USD: { value: 'usd', label: 'USD', flag: usdFlag },
  THB: { value: 'thb', label: 'THB', flag: thbFlag },
  NZD: { value: 'nzd', label: 'NZD', flag: nzdFlag },
  HKD: { value: 'hkd', label: 'HKD', flag: hkdFlag },
  CZK: { value: 'czk', label: 'CZK', flag: czkFlag },
  DKK: { value: 'dkk', label: 'DKK', flag: dkkFlag },
  HUF: { value: 'huf', label: 'HUF', flag: hufFlag },
  PLN: { value: 'pln', label: 'PLN', flag: plnFlag },
  RON: { value: 'ron', label: 'RON' },
  SEK: { value: 'sek', label: 'SEK', flag: sekFlag },
  NOK: { value: 'nok', label: 'NOK', flag: nokFlag },
  CHF: { value: 'chf', label: 'CHF', flag: chfFlag },
  PHP: { value: 'php', label: 'PHP', flag: phpFlag },
  CNY: { value: 'cny', label: 'CNY', flag: cnyFlag },
  MYR: { value: 'myr', label: 'MYR', flag: myrFlag },
  IDR: { value: 'idr', label: 'IDR', flag: idrFlag },
  VND: { value: 'vnd', label: 'VND', flag: vndFlag },
  AED: { value: 'aed', label: 'AED', flag: aedFlag },
  TWD: { value: 'twd', label: 'TWD' },
};

const CURRENCIES_FM = [
  CURRENCY.AUD,
  //CURRENCY.CAD,
  CURRENCY.EUR,
  //CURRENCY.CHF,
  CURRENCY.GBP,
  //CURRENCY.HKD,
  CURRENCY.JPY,
  //CURRENCY.NZD,
  CURRENCY.SGD,
  CURRENCY.USD,
  //CURRENCY.THB,
];

const CURRENCIES_FMX = [
  CURRENCY.AUD,
  CURRENCY.CAD,
  CURRENCY.EUR,
  CURRENCY.GBP,
  //CURRENCY.HKD,
  CURRENCY.JPY,
  //CURRENCY.NZD,
  CURRENCY.SGD,
  CURRENCY.USD,
  CURRENCY.THB,
];

const CURRENCIES_FMX_EU = [
  ...CURRENCIES_FMX,
  // 7 new currencies
  CURRENCY.HUF,
  CURRENCY.CZK,
  CURRENCY.DKK,
  CURRENCY.PLN,
  CURRENCY.NOK,
  CURRENCY.SEK,
  CURRENCY.CHF,
];

export const CURRENCY_LIST = {
  production: CURRENCIES_FM,
  productionX: CURRENCIES_FMX,
  productionX_EU: CURRENCIES_FMX_EU,
};

export const CURRENCIES_BY_HUB = {
  FUSION_MARKETS: CURRENCY_LIST['production'],
  FUSION_MARKETS_X: CURRENCY_LIST['productionX'],
  FUSION_MARKETS_X_EU: CURRENCY_LIST['productionX_EU'],
  FUSION_MARKETS_EU: CURRENCY_LIST['productionX'],
};

export const CURRENCIES = CURRENCY_LIST['production'];

export const INPUT_DELAY = 500;

export const AUSTRALIA = 'Australia';

export const CHINA = 'China';

export const CH_COUNTRIES = ['Taiwan', 'China'];

export const SEA_COUNTRIES = ['Thailand', 'Viet Nam', 'Vietnam', 'Malaysia', 'Indonesia'];

export const MANAGED_COUNTRIES = ['China', 'Thailand'];

export const PAYMENT_TYPES = {
  DEPOSIT: 'Deposit',
  WITHDRAWAL: 'Withdrawal',
};

export const THAI_DEFAULT_CXD = '37017_0';
export const THAILAND = 'Thailand';
export const SEYCHELLES = 'Seychelles';

export const EMAIL_TEMPLATE_TYPES = {
  AUTOMATED: 'Automated',
  MANUALLY_SENT: 'Manually Sent',
};

export const ACCOUNT_SERVERS = {
  LIVE: 'live',
  DEMO: 'demo',
  REBATE: 'rebate',
  EXISTING_REBATE: 'existing',
  ATW: 'atw',
};

export const ACCOUNT_SERVERS_VALUES = [
  { value: ACCOUNT_SERVERS.LIVE, label: 'Live' },
  { value: ACCOUNT_SERVERS.DEMO, label: 'Demo' },
  { value: ACCOUNT_SERVERS.REBATE, label: 'Rebate' },
  { value: ACCOUNT_SERVERS.EXISTING_REBATE, label: 'Rebate (Existing Account)' },
];

export const languages = {
  ENGLISH: 'en',
  THAI: 'th',
  CHINESE: 'ch',
  CANTON: 'chm',
  INDONESIAN: 'id',
  JAPANESE: 'jp',
  VIETNAMESE: 'vn',
  SPANISH: 'es',
};

export const MAX_DEMO_LIFE_PERIOD_IN_DAYS = 30;

export const PAYMENT_METHODS = {
  praxis: 'Praxis',
  paymentAsia: 'Local Bank/UPOP',
  isignthis: 'Visa/Mastercard',
  bank: 'Bank Wire/Transfer',
  thaiTransfer: 'Thai Bank Transfer (recommended)',
  local: 'Local Bank Transfer (SEA only)', // ZotaPay local
  alternativeVisa: 'Alternative Visa/Mastercard channel', // ZotaPay
  zepto: 'Real Time Payments',
  currency_cloud: 'Currency Cloud',
};

export const PAYMENT_METHODS_WITHOUT_INFO = [
  PAYMENT_METHODS.local,
  PAYMENT_METHODS.paymentAsia,
  PAYMENT_METHODS.praxis,
];

export const COUNTRY_CODES_FOR_COOKIES = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'IS',
  'LI',
  'NO',
];

export const FUSION_MARKETS_X = 'FUSION_MARKETS_X';

export const FUSION_MARKETS = 'FUSION_MARKETS';

export const FUSION_MARKETS_EU = 'FUSION_MARKETS_EU';

export const FUSION_MARKETS_LABEL = 'Fusion Markets AU';

export const FUSION_MARKETS_X_LABEL = 'Fusion Markets EN';

export const FUSION_MARKETS_EU_LABEL = 'Fusion Markets EU';

export const FUSION_MARKETS_PREFIX = '/au';

export const FUSION_MARKETS_X_PREFIX = '/en';

export const FUSION_MARKETS_EU_PREFIX = '/eu';

export const MAP_HUB_BY_PREFIX = {
  [FUSION_MARKETS_PREFIX]: FUSION_MARKETS,
  [FUSION_MARKETS_X_PREFIX]: FUSION_MARKETS_X,
  [FUSION_MARKETS_EU_PREFIX]: FUSION_MARKETS_EU,
};

export const HUB_TO_REGULATOR_MAPPER = {
  [FUSION_MARKETS]: 'ASIC',
  [FUSION_MARKETS_X]: 'VFSC',
  [FUSION_MARKETS_EU]: 'CYSEC',
};

export const CURRENCY_MAPPER = {
  Australia: 'AUD',
  Canada: 'CAD',
  'United Kingdom of Great Britain and Northern Ireland': 'GBP',
  Singapore: 'SGD',
  Albania: 'EUR',
  Andorra: 'EUR',
  Armenia: 'EUR',
  Austria: 'EUR',
  Belarus: 'EUR',
  Belgium: 'EUR',
  'Bosnia and Herzegovina': 'EUR',
  Bulgaria: 'EUR',
  Switzerland: 'EUR',
  Croatia: 'EUR',
  Cyprus: 'EUR',
  'Czech Republic': 'EUR',
  Denmark: 'EUR',
  Estonia: 'EUR',
  'Faroe Islands': 'EUR',
  Finland: 'EUR',
  France: 'EUR',
  Georgia: 'EUR',
  Germany: 'EUR',
  Gibraltar: 'EUR',
  Greece: 'EUR',
  Hungary: 'EUR',
  Iceland: 'EUR',
  Ireland: 'EUR',
  Italy: 'EUR',
  Japan: 'JPY',
  Latvia: 'EUR',
  Lithuania: 'EUR',
  Luxembourg: 'EUR',
  'Macedonia (the former Yugoslav Republic of)': 'EUR',
  Malta: 'EUR',
  Monaco: 'EUR',
  Netherlands: 'EUR',
  Norway: 'EUR',
  Portugal: 'EUR',
  Romania: 'EUR',
  'San Marino': 'EUR',
  Slovakia: 'EUR',
  Slovenia: 'EUR',
  Spain: 'EUR',
  Sweden: 'EUR',
};

export const DEFAULT_CURRENCY = 'USD';

export const DROPDOWN_YES_NO = [
  { value: 'NO', label: 'NO' },
  { value: 'YES', label: 'YES' },
];

export const CELLXPERT_MANAGERS = [
  {
    label: 'Edward Ali',
    value: 128,
  },
  {
    label: 'Graham Miller',
    value: 121,
  },
  {
    label: 'Phil Horner',
    value: 81,
  },
  {
    label: 'Xavier Herrera-Burchielli',
    value: 114,
  },
];

export const UPLOAD_EMAIL_FILE_ROUTE = `${domain}email-templates/uploadFileBlob`;

export const CONTACT_US_URL = 'https://fusionmarkets.com/Contact-us';
export const PLATFORM_TYPE = {
  FINMO: 'finmo',
  ZEPTO: 'zepto',
  MONOOVA: 'monoova',
};

export const CRAZYEGG_SRC = '//script.crazyegg.com/pages/scripts/0117/5134.js';

export const PHOTO_FILE_TYPES = [
  UPLOADER_FILE_TYPE.JPG,
  UPLOADER_FILE_TYPE.JPEG,
  UPLOADER_FILE_TYPE.PNG,
  UPLOADER_FILE_TYPE.PDF,
];

export const TIME_ZONES = {
  AEST: { label: 'AEST', time: 10 },
  EET: { label: 'EET', time: 2 },
};

export const ACCOUNT_TYPES = {
  ZERO: 'ZERO',
  CLASSIC: 'Classic',
  SWAP_FREE: 'SWAP_FREE',
};

export const EU_CURRENCIES_COUNTRIES = [
  'Austria',
  'Belgium',
  'Bulgaria',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Hungary',
  'Ireland',
  'Italy',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Netherlands',
  'Norway',
  'Poland',
  'Portugal',
  'Romania',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'Switzerland',
];

export const TRADING_SERVERS_FOR_STAFF_MAPPER = {
  [FUSION_MARKETS]: {
    DEMO: TRADING_DEMO_SERVERS_FOR_STAFF_AU,
    LIVE: TRADING_SERVERS_FOR_STAFF_AU,
  },
  [FUSION_MARKETS_X]: {
    DEMO: TRADING_DEMO_SERVERS_FOR_STAFF,
    LIVE: TRADING_SERVERS_FOR_STAFF,
  },
};

export const isProdEnv = process.env.REACT_APP_ENV.includes('production');
export const isStagingEnv = process.env.REACT_APP_ENV.includes('testing');
export const isDevEnv =
  process.env.REACT_APP_ENV === 'dev_rd' || process.env.REACT_APP_ENV === 'dev_cy_x';
export const isDevelopment =
  process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'development_cy_x';

export const USA_STATES = [
  { stateName: 'Alabama', stateISOName: 'AL' },
  { stateName: 'Alaska', stateISOName: 'AK' },
  { stateName: 'American Samoa', stateISOName: 'AS' },
  { stateName: 'Arizona', stateISOName: 'AZ' },
  { stateName: 'Arkansas', stateISOName: 'AR' },
  { stateName: 'California', stateISOName: 'CA' },
  { stateName: 'Colorado', stateISOName: 'CO' },
  { stateName: 'Connecticut', stateISOName: 'CT' },
  { stateName: 'Delaware', stateISOName: 'DE' },
  { stateName: 'Washington, D.C.', stateISOName: 'DC' },
  { stateName: 'Federated States of Micronesia', stateISOName: 'FM' },
  { stateName: 'Florida', stateISOName: 'FL' },
  { stateName: 'Georgia', stateISOName: 'GA' },
  { stateName: 'Guam', stateISOName: 'GU' },
  { stateName: 'Hawaii', stateISOName: 'HI' },
  { stateName: 'Idaho', stateISOName: 'ID' },
  { stateName: 'Illinois', stateISOName: 'IL' },
  { stateName: 'Indiana', stateISOName: 'IN' },
  { stateName: 'Iowa', stateISOName: 'IA' },
  { stateName: 'Kansas', stateISOName: 'KS' },
  { stateName: 'Kentucky', stateISOName: 'KY' },
  { stateName: 'Louisiana', stateISOName: 'LA' },
  { stateName: 'Maine', stateISOName: 'ME' },
  { stateName: 'Marshall Islands', stateISOName: 'MH' },
  { stateName: 'Maryland', stateISOName: 'MD' },
  { stateName: 'Massachusetts', stateISOName: 'MA' },
  { stateName: 'Michigan', stateISOName: 'MI' },
  { stateName: 'Minnesota', stateISOName: 'MN' },
  { stateName: 'Mississippi', stateISOName: 'MS' },
  { stateName: 'Missouri', stateISOName: 'MO' },
  { stateName: 'Montana', stateISOName: 'MT' },
  { stateName: 'Nebraska', stateISOName: 'NE' },
  { stateName: 'Nevada', stateISOName: 'NV' },
  { stateName: 'New Hampshire', stateISOName: 'NH' },
  { stateName: 'New Jersey', stateISOName: 'NJ' },
  { stateName: 'New Mexico', stateISOName: 'NM' },
  { stateName: 'New York', stateISOName: 'NY' },
  { stateName: 'North Carolina', stateISOName: 'NC' },
  { stateName: 'North Dakota', stateISOName: 'ND' },
  { stateName: 'Northern Mariana Islands', stateISOName: 'MP' },
  { stateName: 'Ohio', stateISOName: 'OH' },
  { stateName: 'Oklahoma', stateISOName: 'OK' },
  { stateName: 'Oregon', stateISOName: 'OR' },
  { stateName: 'Palau', stateISOName: 'PW' },
  { stateName: 'Pennsylvania', stateISOName: 'PA' },
  { stateName: 'Puerto Rico', stateISOName: 'PR' },
  { stateName: 'Rhode Island', stateISOName: 'RI' },
  { stateName: 'South Carolina', stateISOName: 'SC' },
  { stateName: 'South Dakota', stateISOName: 'SD' },
  { stateName: 'Tennessee', stateISOName: 'TN' },
  { stateName: 'Texas', stateISOName: 'TX' },
  { stateName: 'Utah', stateISOName: 'UT' },
  { stateName: 'Vermont', stateISOName: 'VT' },
  { stateName: 'United States Virgin Islands', stateISOName: 'VI' },
  { stateName: 'Virginia', stateISOName: 'VA' },
  { stateName: 'Washington', stateISOName: 'WA' },
  { stateName: 'West Virginia', stateISOName: 'WV' },
  { stateName: 'Wisconsin', stateISOName: 'WI' },
  { stateName: 'Wyoming', stateISOName: 'WY' },
  { stateName: 'Armed Forces America', stateISOName: 'AA' },
  { stateName: 'Armed Forces Pacific', stateISOName: 'AP' },
  { stateName: 'Armed Forces Europe', stateISOName: 'AE' },
];

export const ALLOW_EU_CURRENCIES_FOR_EMAILS = new Set([
  'frostspherecapital@gmail.com', // Malaysia
  'elvistangalayuk@gmail.com', // Indonesia
]);
