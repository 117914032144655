import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = {};

export default handleActions(
  {
    [actionCreators.getUserTotalBalanceSuccess]: (state, action) => {
      return {
        ...state,
        totalBalance: action.response.data,
      };
    },
    [actionCreators.getUserTotalBalanceFail]: () => {
      return defaultState;
    },
  },
  defaultState,
);
