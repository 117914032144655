import React from 'react';
import PropTypes from 'prop-types';
import { GoogleLogin } from '@react-oauth/google';
import useStyles from './styles';

const GoogleButton = ({ onSuccess, onFailure, text }) => {
  const classes = useStyles();
  return (
    <div className={classes.googleButtonContainer}>
      <div className={classes.googleButtonWrapper}>
        <div className={classes.googleButton}>
          <div className={classes.googleButtonInner}>
            <GoogleLogin
              text={text}
              onSuccess={onSuccess}
              onError={onFailure}
              cookiePolicy="single_host_origin"
              size="large"
              //width="210"
              logo_alignment="left"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

GoogleButton.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
};

export default GoogleButton;
