import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import loader_squares from 'static/images/old/loader_squares_secondary.gif';

import styles from './styles';

const SecondaryButton = ({ classes, content, label, onClick, className, isPending, ...data }) => {
  return (
    <Button className={className} color="primary" onClick={onClick} disableTouchRipple {...data}>
      <div className={classes.buttonContent} {...data}>
        <div
          className={classNames({
            [classes.loader]: true,
            [classes.hidden]: true,
            [classes.visible]: isPending,
          })}>
          <img alt="" src={loader_squares} />
        </div>
        <div
          className={classNames({
            [classes.buttonTextContainer]: true,
            [classes.hidden]: isPending,
          })}>
          {content}
          {label}
        </div>
      </div>
    </Button>
  );
};

SecondaryButton.propTypes = {
  content: PropTypes.object,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  data: PropTypes.object,
};

export default withStyles(styles)(SecondaryButton);
