import React from 'react';
import PropTypes from 'prop-types';
import ReportProblemOutlined from '@material-ui/icons/ReportProblemOutlined';
import useStyles from './styles';
import { getTranslation } from 'utils/compositeTranslationHandler';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const TPWarningMessage = ({ warning, size, className }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <span
      className={classNames(classes.warning, className, {
        [classes.mediumSize]: size === 'medium',
      })}>
      <ReportProblemOutlined fontSize={size} color="inherit" />
      &nbsp;
      {getTranslation(t, warning) || 'Warning'}
    </span>
  );
};

TPWarningMessage.propTypes = {
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.oneOf(['small', 'medium']),
};

export default TPWarningMessage;
