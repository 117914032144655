import makeStyles from '@material-ui/core/styles/makeStyles';

//overrides small size for autocompletes to make it smaller inside datepicker
const useStyles = makeStyles(
  () => ({
    month: {
      width: '120px',
      '& .MuiInputAdornment-positionEnd': {
        marginLeft: 0,
      },
    },
    year: {
      width: '85px',
      '& .MuiInputAdornment-positionEnd': {
        marginLeft: 0,
      },
    },
    xsmallDatepickerDropdown: {
      '& .MuiOutlinedInput-marginDense.MuiOutlinedInput-adornedEnd': {
        paddingRight: '6px',
      },
      '& .MuiOutlinedInput-inputMarginDense': {
        height: 21,
        paddingLeft: '10px',
      },
      '& .MuiInputAdornment-positionEnd': {
        marginLeft: 0,
      },
    },
  }),
  { name: 'TPDatePickerHeader' },
);

export default useStyles;
