import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = [];

export default handleActions(
  {
    [actionCreators.getUserPaymentsSuccess](state, action) {
      return {
        items: action.response.data.userPayments,
        totalNumber: action.response.data.totalNumber,
      };
    },
    [actionCreators.getUserPaymentsFail]() {
      return defaultState;
    },
    [actionCreators.clearUserPayments]() {
      return defaultState;
    },
  },
  defaultState,
);
