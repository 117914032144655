import React, { useCallback, useEffect, useState } from 'react';
import Quiz from '../../../Quiz';
import { TMD_CHECK_MODAL, TMD_QUIZ_FORM, TMD_QUIZ_MODAL } from '../../constants';
import InfoTip from 'modules/common/InfoTip';
import TMDWarningModal from '../../containers/TMDWarningModalContainer';
import TMDSuccessModal from '../../containers/TMDSuccessModalContainer';
import TMDLockedModal from '../../components/TMDLockedModal';
import { useDispatch, useSelector } from 'react-redux';
import { getProgressIndex, getQuestionIndex, getTMDQuestions } from '../../selectors';
import {
  getTmdQuestionsRequest,
  lockFailedTMDUser,
  sendTmdAnswersRequest,
  setTmdCurrentQuestionIndex,
  setTmdProgressIndex,
} from '../../actions';
import { createPendingSelector } from 'modules/api/selectors';
import { openModal, closeModal } from 'components/TP-UI/TPModal/actions';
import { store } from 'components/App';
import TMDCheckModal from '../../components/TMDCheckModal';
import { reset } from 'redux-form';
import * as authSelectors from 'modules/auth/selectors';
import get from 'lodash/get';
import saga from '../../sagas';

const TMDContainer = () => {
  const questions = useSelector(getTMDQuestions);
  const dispatch = useDispatch();
  const quizLoading = useSelector(createPendingSelector(getTmdQuestionsRequest));
  const submitLoading = useSelector(createPendingSelector(sendTmdAnswersRequest));
  const user = useSelector(authSelectors.getUser);
  const currentQuestionIndex = useSelector(getQuestionIndex);
  const progressIndex = useSelector(getProgressIndex);
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    store.injectSaga('TMDSaga', saga);
    return () => {
      store.injectSaga('TMDSaga', null);
    };
  }, []);

  const handleNextTMDQuestion = useCallback(
    (next) => {
      dispatch(setTmdCurrentQuestionIndex(next + 1));
      dispatch(setTmdProgressIndex(progressIndex < next + 1 ? next + 1 : progressIndex));
    },
    [progressIndex, dispatch],
  );

  const handlePreviousQuestion = useCallback(
    (next) => {
      dispatch(setTmdCurrentQuestionIndex(next - 1));
    },
    [dispatch],
  );

  const handleFinish = useCallback(
    (answers) => {
      dispatch(setTmdProgressIndex(questions.length));
      setAnswers(answers);
      dispatch(closeModal(TMD_QUIZ_MODAL));
      dispatch(openModal(TMD_CHECK_MODAL));
    },
    [dispatch, questions.length],
  );

  const handleTMDQuizOpen = useCallback(() => {
    dispatch(getTmdQuestionsRequest());
    dispatch(openModal(TMD_QUIZ_MODAL));
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    dispatch(sendTmdAnswersRequest(answers));
  }, [answers, dispatch]);

  const handleRecheck = useCallback(() => {
    dispatch(setTmdCurrentQuestionIndex(0));
    dispatch(closeModal(TMD_CHECK_MODAL));
    dispatch(openModal(TMD_QUIZ_MODAL));
  }, [dispatch]);

  const handleCancel = useCallback(() => {
    dispatch(reset(TMD_QUIZ_FORM));
    dispatch(closeModal(TMD_QUIZ_MODAL));
    dispatch(setTmdCurrentQuestionIndex(0));
    dispatch(setTmdProgressIndex(0));
  }, [dispatch]);

  const handleRecheckCancel = useCallback(() => {
    dispatch(reset(TMD_QUIZ_FORM));
    dispatch(closeModal(TMD_CHECK_MODAL));
    dispatch(setTmdCurrentQuestionIndex(0));
    dispatch(setTmdProgressIndex(0));
  }, [dispatch]);

  useEffect(() => {
    if (get(user, 'verification.TMDAccepted.accepted', true) === false) {
      dispatch(lockFailedTMDUser());
    }
  }, [dispatch, user]);

  return (
    <>
      <Quiz
        currentQuestionIndex={currentQuestionIndex}
        form={TMD_QUIZ_FORM}
        loading={quizLoading}
        modalName={TMD_QUIZ_MODAL}
        onNext={handleNextTMDQuestion}
        onPrevious={handlePreviousQuestion}
        onSubmit={handleFinish}
        progressIndex={progressIndex}
        questions={questions}
        onCancel={handleCancel}
        title="Appropriateness Test"
        titleEndAdornment={
          <InfoTip
            iconSize="medium"
            tooltipContent="Trading Forex/CFDs is highly risky. This questionnaire will help determine your suitability to trade these types of financial instruments."
          />
        }
      />
      <TMDWarningModal onOpenQuiz={handleTMDQuizOpen} />
      <TMDSuccessModal />
      <TMDLockedModal />
      <TMDCheckModal
        loading={submitLoading}
        onSubmit={handleSubmit}
        onRecheck={handleRecheck}
        onRecheckCancel={handleRecheckCancel}
      />
    </>
  );
};

export default TMDContainer;
