import { handleActions } from 'redux-actions';
import { getTopUserVolumesFail, getTopUserVolumesSuccess } from '../actions';

const defaultState = null;

export default handleActions(
  {
    [getTopUserVolumesSuccess](state, action) {
      return action.response.data.volumes;
    },

    [getTopUserVolumesFail](state) {
      return state || [];
    },
  },
  defaultState,
);
