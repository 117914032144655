import { createAction } from 'redux-actions';

export const getPaymentsPendingHistoryRequest = createAction(
  'GET_PAYMENTS_PENDING_HISTORY_REQUEST',
);
export const getPaymentsPendingHistorySuccess = createAction(
  'GET_PAYMENTS_PENDING_HISTORY_SUCCESS',
);
export const getPaymentsPendingHistoryFail = createAction('GET_PAYMENTS_PENDING_HISTORY_FAIL');

export const getManualWithdrawApproveRequest = createAction('GET_MANUAL_WITHDRAW_APPROVE_REQEST');
export const getManualWithdrawApproveSuccess = createAction('GET_MANUAL_WITHDRAW_APPROVE_SUCCESS');
export const getManualWithdrawApproveFail = createAction('GET_MANUAL_WITHDRAW_APPROVE_FAIL');
