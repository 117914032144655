import get from 'lodash/get';
import isArray from 'lodash/isArray';

export const getFieldData = (label, dataLabel, tableItem) => {
  let fieldLabel = label;
  let fieldValue = get(tableItem, dataLabel);
  if (isArray(label) && isArray(dataLabel)) {
    const fieldValueIndex = dataLabel.findIndex((fieldData) => {
      return typeof tableItem[fieldData] === 'boolean'
        ? Boolean(tableItem[fieldData]).toString()
        : tableItem[fieldData];
    });
    fieldLabel = label[fieldValueIndex];
    fieldValue =
      typeof tableItem[dataLabel[fieldValueIndex]] === 'boolean'
        ? Boolean(tableItem[dataLabel[fieldValueIndex]]).toString()
        : tableItem[dataLabel[fieldValueIndex]];
  }
  return { fieldLabel, fieldValue };
};
