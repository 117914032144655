import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    content: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
  }),
  { name: 'TPStepContent' },
);
