import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';
import TPAlert from 'components/TP-UI/TPAlert';
import TPGrid from 'components/TP-UI/TPGrid';
import TPModal from 'components/TP-UI/TPModal';
import { PRO_QUIZ_MODAL, RETAIL_QUIZ_MODAL } from 'modules/profile/constants';

import QuizModalContent from 'modules/VerificationModalsManager/components/QuizModalContent';

import useStyles from './styles';

const PreQuizModal = (props) => {
  const { onSubmit, modalName, onCancel, title, titleEndAdornment, isProPlan, questions } = props;

  const classes = useStyles();
  const { quiz } = useTheme();
  const imageConfig = quiz.preQuizModal.avatar;

  const quizModalName = isProPlan ? PRO_QUIZ_MODAL : RETAIL_QUIZ_MODAL;

  const handleClick = useCallback(() => {
    if (onSubmit) {
      onSubmit(quizModalName);
    }
  }, [onSubmit, quizModalName]);

  const { t } = useTranslation('common');

  const buttons = useMemo(() => {
    return [
      {
        label: t('quiz:pagePlaceholder.button'),
        onClick: handleClick,
        primary: true,
        type: 'submit',
      },
      {
        label: t('common:buttons.cancel'),
        secondary: true,
        onClick: onCancel ? onCancel : () => {},
      },
    ];
  }, [t, handleClick, onCancel]);

  return (
    <TPModal
      buttons={buttons}
      modalName={modalName}
      onClose={onCancel}
      title={title}
      titleEndAdornment={titleEndAdornment}>
      <TPGrid container direction="column" spacing={3} className={classes.container}>
        <TPGrid item>
          <TPAlert variant="info">
            {isProPlan
              ? t('quiz:pro.page.note')
              : t('quiz:retail.page.note', { questionsAmount: questions?.length })}
          </TPAlert>
        </TPGrid>
        <TPGrid item>
          <QuizModalContent
            imageConfig={imageConfig}
            text={t('quiz:pagePlaceholder.text')}
            title={title}
          />
        </TPGrid>
      </TPGrid>
    </TPModal>
  );
};

PreQuizModal.propTypes = {
  isProPlan: PropTypes.bool,
  modalName: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  titleEndAdornment: PropTypes.node,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
      label: PropTypes.string,
    }),
  ),
};

export default PreQuizModal;
