import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    loading: {
      pointerEvents: 'none',
      color: 'transparent',
      cursor: 'default',
      '& .MuiButton-label > *': {
        visibility: 'hidden',
      },
      '& .MuiButton-label > $buttonProgress': {
        visibility: 'visible',
      },
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -9,
      marginLeft: -9,
    },
    progressPrimary: {
      color: theme.palette.primary.contrastText,
    },
    progressSecondary: {
      color: theme.palette.text.secondary,
    },
    progressAlternative: {
      color: theme.palette.primary.main,
    },
    progressLargeSize: {
      marginTop: -12,
      marginLeft: -12,
    },
    textNoWrap: {
      whiteSpace: 'nowrap',
    },
    iconDefault: {
      width: 18,
      marginRight: theme.spacing(0.5),
    },
    iconSmall: {
      width: 14,
      marginRight: theme.spacing(0.25),
    },
    iconLarge: {
      width: 22,
      marginRight: theme.spacing(0.75),
    },
  }),
  { name: 'TPButton' },
);
