import { colors } from './colors';
import { fontSize, fontWeight } from '../font';

const OverridesMuiInputAdornment = {
  root: {
    height: 'auto',
    color: colors.gray.main,
    fontSize: fontSize._16,
    fontWeight: fontWeight.regular,
  },
};

export default OverridesMuiInputAdornment;
