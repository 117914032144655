import React, { useCallback, useMemo } from 'react';
import TPGrid from 'components/TP-UI/TPGrid';
import TPChip from 'components/TP-UI/TPChip';
import TPSelect from 'components/TP-UI/TPSelect';
import { Field, reduxForm } from 'redux-form';
import TPTypography from 'components/TP-UI/TPTypography';
import AddAlert from '@material-ui/icons/AddAlert';
import TPLanguageSelect from 'components/TP-UI/TPLanguageSelect';
import Avatar from '@material-ui/core/Avatar';
import { CURRENCY } from '../../../constants';

import CurrencySelect from 'modules/common/CurrencySelect';

import UKImg from 'static/images/common/flags/UK.svg';
import ThailandImg from 'static/images/common/flags/Thailand.svg';

import useStyles from './styles';

const testRequire = (value) => {
  return value === false ? { key: 'common:errors.form.isRequired' } : undefined;
};

const Selects = () => {
  const classes = useStyles();

  const renderFlag = useCallback(
    (option) => (
      <Avatar variant="square" className={classes.flagIcon} alt={option.label} src={option.img} />
    ),
    [classes.flagIcon],
  );
  const renderFlagWithLabel = useCallback(
    (option) => (
      <>
        <Avatar variant="square" className={classes.flagIcon} alt={option.label} src={option.img} />{' '}
        {option.label}
      </>
    ),
    [classes.flagIcon],
  );

  const currencies = useMemo(() => Object.values(CURRENCY), []);

  return (
    <form className={classes.form}>
      <TPGrid container spacing={1}>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Single selection</TPTypography>
          <Field
            component={TPSelect}
            id="age"
            name="age"
            label="Age"
            placeholder="Please select age"
            fullWidth
            options={[
              { label: '20-30', value: '[20,30]' },
              { label: '30-40', value: '[30,40]' },
              { label: '40-50', value: '[40,50]' },
            ]}
            required
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Multiple selection, optional</TPTypography>
          <Field
            component={TPSelect}
            id="type"
            name="type"
            multiple
            label="Type"
            placeholder="Please select type"
            fullWidth
            options={[
              { label: 'All', value: '' },
              { label: 'Type 1', value: 1 },
              { label: 'Type 2', value: 2 },
            ]}
            clearable={true}
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Hint</TPTypography>
          <Field
            component={TPSelect}
            id="age"
            name="age"
            label="Age"
            placeholder="Please select age"
            fullWidth
            options={[
              { label: 'All', value: '' },
              { label: '20-30', value: '[20,30]' },
              { label: '30-40', value: '[30,40]' },
              { label: '40-50', value: '[40,50]' },
            ]}
            required
            hint="Additional information"
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">No label</TPTypography>
          <Field
            component={TPSelect}
            id="currency"
            name="currency"
            placeholder="No label"
            fullWidth
            options={[
              { label: 'USD - US Dollar', value: 1, abbr: 'USD' },
              { label: 'EUR - Euro', value: 2, abbr: 'EUR' },
            ]}
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Error</TPTypography>
          <Field
            component={TPSelect}
            id="currencyR"
            name="currencyR"
            label="Currency"
            placeholder="Please select currency"
            fullWidth
            options={[
              { label: 'USD - US Dollar', value: 1, abbr: 'USD' },
              { label: 'EUR - Euro', value: 2, abbr: 'EUR' },
            ]}
            validate={[testRequire]}
            required
            hint="Additional info"
            meta={{ error: { key: 'common:errors.form.isRequired' }, submitFailed: true }}
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Disabled</TPTypography>
          <Field
            component={TPSelect}
            id="currency"
            name="currency"
            label="Disabled"
            disabled
            placeholder="Please select currency"
            fullWidth
            options={[
              { label: 'USD - US Dollar', value: 1, abbr: 'USD' },
              { label: 'EUR - Euro', value: 2, abbr: 'EUR' },
            ]}
            hint="Additional info"
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Grouping</TPTypography>
          <Field
            component={TPSelect}
            id="country"
            name="country"
            label="Groups"
            placeholder="Please select country"
            fullWidth
            groupBy="group"
            options={[
              { label: 'Australia', value: 1, group: 'A', count: 10 },
              { label: 'Belarus', value: 2, group: 'B', count: 10 },
              { label: 'Belgium', value: 3, group: 'B', count: 5 },
            ]}
            hint="Additional info"
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Small size, clearable</TPTypography>
          <Field
            component={TPSelect}
            id="country"
            name="country"
            placeholder="small size"
            fullWidth
            size="small"
            options={[
              { label: 'Australia', value: 1, group: 'A' },
              { label: 'Belarus', value: 2, group: 'B' },
            ]}
            hint="Additional info"
            clearable={true}
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Custom selected value render</TPTypography>
          <Field
            component={TPSelect}
            id="currency"
            name="currency"
            label="Currency"
            placeholder="Please select currency"
            fullWidth
            options={[
              { label: 'USD - US Dollar', value: 1, abbr: 'USD' },
              { label: 'EUR - Euro', value: 2, abbr: 'EUR' },
            ]}
            required
            renderValue={(selected) => selected.abbr}
            hint="Additional information"
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Custom selected values render</TPTypography>
          <Field
            component={TPSelect}
            id="currencyM"
            name="currencyM"
            label="Currency multiple"
            placeholder="Please select currency"
            fullWidth
            options={[
              { label: 'USD - US Dollar', value: 1, abbr: 'USD' },
              { label: 'EUR - Euro', value: 2, abbr: 'EUR' },
            ]}
            required
            multiple
            renderValue={(selected) => selected.map((s) => s.abbr).join(', ')}
            hint="Additional information"
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Custom selected values render as chips</TPTypography>
          <Field
            component={TPSelect}
            id="currencyM"
            name="currencyM"
            label="Currency multiple chips"
            placeholder="Please select currency"
            fullWidth
            options={[
              { label: 'USD - US Dollar', value: 1, abbr: 'USD' },
              { label: 'EUR - Euro', value: 2, abbr: 'EUR' },
            ]}
            required
            multiple
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((s) => (
                  <TPChip key={s.value} size="small" label={s.abbr} />
                ))}
              </div>
            )}
            hint="Additional information"
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Custom options render</TPTypography>
          <Field
            component={TPSelect}
            id="currency"
            name="currency"
            label="Custom Option"
            placeholder="Please select currency"
            fullWidth
            options={[
              { label: 'US Dollar', value: 1, abbr: 'USD' },
              { label: 'Euro', value: 2, abbr: 'EUR' },
            ]}
            renderOption={(option) => (
              <span>
                {option.abbr} - {option.label}
              </span>
            )}
            hint="Additional info"
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Custom options render with icons</TPTypography>
          <Field
            component={TPSelect}
            id="currency"
            name="currency"
            label="Custom Option"
            placeholder="Please select currency"
            fullWidth
            options={[
              { label: 'US Dollar', value: 1, icon: 'add_circle' },
              { label: 'Euro', value: 2, icon: 'AddAlert' },
            ]}
            renderOption={(option) => (
              <>
                <AddAlert color="primary" fontSize="small" /> - {option.label}
              </>
            )}
            hint="Additional info"
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Custom variant </TPTypography>
          <Field
            component={TPSelect}
            id="country2"
            name="country2"
            variant="custom"
            options={[
              {
                label: 'Great Britain',
                value: 1,
              },
              {
                label: 'Thailand',
                value: 2,
              },
            ]}
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">
            Custom variant, custom value and option renders, hidden arrow{' '}
          </TPTypography>
          <Field
            component={TPSelect}
            id="country2"
            name="country2"
            //size="small"
            variant="custom"
            hideArrow
            options={[
              {
                label: 'Great Britain',
                value: 1,
                img: UKImg,
              },
              {
                label: 'Thailand',
                value: 2,
                img: ThailandImg,
              },
            ]}
            renderValue={renderFlag}
            renderOption={renderFlag}
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">
            Custom variant, custom value and option renders{' '}
          </TPTypography>
          <Field
            component={TPSelect}
            id="country2"
            name="country2"
            variant="custom"
            options={[
              {
                label: 'Great Britain',
                value: 1,
                img: UKImg,
              },
              {
                label: 'Thailand',
                value: 2,
                img: ThailandImg,
              },
            ]}
            renderValue={renderFlag}
            renderOption={renderFlagWithLabel}
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">TPLanguageSelect component</TPTypography>
          <Field
            component={TPLanguageSelect}
            id="language"
            name="language"
            hideArrow={false}
            className={classes.control}
            options={[
              {
                label: 'English',
                language: 'en',
                icon: UKImg,
              },
              {
                label: 'Thai',
                language: 'th',
                icon: ThailandImg,
              },
            ]}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Minimized TPLanguageSelect component</TPTypography>
          <Field
            component={TPLanguageSelect}
            id="languageMinimized"
            name="languageMinimized"
            minimized
            options={[
              {
                label: 'English',
                language: 'en',
                icon: UKImg,
              },
              {
                label: 'Thai',
                language: 'th',
                icon: ThailandImg,
              },
            ]}
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">
            CurrencySelect component (based on TPSelect)
          </TPTypography>
          <Field
            component={CurrencySelect}
            id="currencyFrom"
            name="currencyFrom"
            options={currencies}
            label="Currency"
            placeholder="Currency"
            className={classes.control}
          />
        </TPGrid>
        <TPGrid item xs={3}>
          <TPTypography variant="subtitle1">Returns object</TPTypography>
          <Field
            component={TPSelect}
            id="country3"
            name="country3"
            options={[
              {
                label: 'Great Britain',
                alpha2Code: 'GB',
                value: 1,
              },
              {
                label: 'Thailand',
                alpha2Code: 'TH',
                value: 2,
              },
            ]}
            label="Country"
            placeholder="Country"
            returnsObject
            onChange={(val) => console.log(val)}
            className={classes.control}
          />
        </TPGrid>
      </TPGrid>
    </form>
  );
};

export default reduxForm({
  form: 'TP-SELECTS-FORM',
  initialValues: {
    age: null,
    type: [],
    currency: 1,
    currencyM: [1],
    currencyR: null,
    country: null,
    country2: 1,
    country3: null,
    language: 'en',
    languageMinimized: 'en',
    currencyFrom: null,
  },
})(Selects);
