import config from 'config';
import { FUSION_MARKETS_X, HUB_TO_REGULATOR_MAPPER } from 'constants/index';
const { MARKETING_SITE_URLS } = config;

export const getMarketingSiteUrl = (hub) => {
  const defaultRegulator = HUB_TO_REGULATOR_MAPPER[FUSION_MARKETS_X];
  const regulator = HUB_TO_REGULATOR_MAPPER[hub];

  return MARKETING_SITE_URLS[regulator] || MARKETING_SITE_URLS[defaultRegulator];
};
