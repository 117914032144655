import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import TPLink from 'components/TP-UI/TPLink';
import TPTypography from 'components/TP-UI/TPTypography';
import { getUserDocuments } from 'modules/auth/selectors';

const CheckboxLabel = () => {
  const documents = useSelector(getUserDocuments);

  return (
    <BrowserRouter basename="/">
      <TPTypography variant="body1">
        <Trans i18nKey={`verification.accountSettings.checkboxLabelQuiz`} ns="profile">
          I’m electronically signing your
          <TPLink target="_blank" rel="noopener noreferrer" path={documents.pds} size="medium">
            Product Disclosure Statement
          </TPLink>
        </Trans>
      </TPTypography>
    </BrowserRouter>
  );
};

export default CheckboxLabel;
