const styles = (theme) => ({
  label: {
    color: theme.tableTextField.labelColor,
    fontFamily: 'Lato, sans-serif',
    fontSize: '12px',
    lineHeight: '15px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  dataText: {
    color: theme.palette.text.main,
    fontFamily: 'Lato, sans-serif',
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '17px',
    marginTop: '6px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    alignItems: 'center',
  },
  item: {
    padding: '22px 10px',
    height: 80,
    '@media (min-width:400px)': {
      height: 'auto',
    },
  },
  dataIconContainer: {
    minWidth: 20,
    maxWidth: 20,
    height: 20,
    borderRadius: 50,
    marginRight: 5,
    overflow: 'hidden',
    position: 'relative',
  },
  dataIcon: {
    height: 20,
    transform: 'scale(1)',
    objectPosition: 'center',
    objectFit: 'cover',
    width: 20,
  },
  loader: {
    margin: '6px auto',
  },
  swiftRequiresValidation: {
    color: 'red',
  },
});

export default styles;
