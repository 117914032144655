import { createAction } from 'redux-actions';

export const paymentsDepositIsignthisRequest = createAction('PAYMENTS_DEPOSIT_ISIGNTHIS_REQUEST');
export const paymentsDepositIsignthisSuccess = createAction('PAYMENTS_DEPOSIT_ISIGNTHIS_SUCCESS');
export const paymentsDepositIsignthisFail = createAction('PAYMENTS_DEPOSIT_ISIGNTHIS_FAIL');

export const paymentsDepositZotapayRequest = createAction('PAYMENTS_DEPOSIT_ZOTAPAY_REQUEST');
export const paymentsDepositZotapaySuccess = createAction('PAYMENTS_DEPOSIT_ZOTAPAY_SUCCESS');
export const paymentsDepositZotapayFail = createAction('PAYMENTS_DEPOSIT_ZOTAPAY_FAIL');

export const paymentsDepositSmartwayRequest = createAction('PAYMENTS_DEPOSIT_SMARTWAY_REQUEST');
export const paymentsDepositSmartwaySuccess = createAction('PAYMENTS_DEPOSIT_SMARTWAY_SUCCESS');
export const paymentsDepositSmartwayFail = createAction('PAYMENTS_DEPOSIT_SMARTWAY_FAIL');

export const paymentsDepositCurrencyCloudRequest = createAction(
  'PAYMENTS_DEPOSIT_CURRENCY_CLOUD_REQUEST',
);
export const paymentsDepositCurrencyCloudFail = createAction(
  'PAYMENTS_DEPOSIT_CURRENCY_CLOUD_FAIL',
);
export const paymentsDepositCurrencyCloudSuccess = createAction(
  'PAYMENTS_DEPOSIT_CURRENCY_CLOUD_SUCCESS',
);
export const paymentsDepositCurrencyCloud = createAction('PAYMENTS_DEPOSIT_CURRENCY_CLOUD');

export const paymentsDepositBankTransfer = createAction('PAYMENTS_DEPOSIT_BANK_TRANSFER');
export const paymentsDepositBankTransferReset = createAction(
  'PAYMENTS_DEPOSIT_BANK_TRANSFER_RESET',
);

export const depositCryptoDay1 = createAction('DEPOSIT_CRYPTO_DAY1');

export const getCryptoDay1AssetsRequest = createAction('GET_CRYPTO_DAY1_ASSETS_REQUEST');
export const getCryptoDay1AssetsSuccess = createAction('GET_CRYPTO_DAY1_ASSETS_SUCCESS');
export const getCryptoDay1AssetsFail = createAction('GET_CRYPTO_DAY1_ASSETS_FAIL');

export const getCryptoDay1SpotPriceRequest = createAction(
  'GET_CRYPTO_DAY1_SPOT_PRICE_REQUEST',
  (payload) => payload,
);
export const getCryptoDay1SpotPriceSuccess = createAction('GET_CRYPTO_DAY1_SPOT_PRICE_SUCCESS');
export const getCryptoDay1SpotPriceFail = createAction('GET_CRYPTO_DAY1_SPOT_PRICE_FAIL');
export const resetCryptoDay1SpotPrice = createAction(
  'RESET_CRYPTO_DAY1_SPOT_PRICE',
  (payload) => payload,
);

export const generateCryptoDay1WalletRequest = createAction('GENERATE_CRYPTO_DAY1_WALLET_REQUEST');
export const generateCryptoDay1WalletSuccess = createAction('GENERATE_CRYPTO_DAY1_WALLET_SUCCESS');
export const generateCryptoDay1WalletFail = createAction('GENERATE_CRYPTO_DAY1_WALLET_FAIL');

export const getCryptoDay1WalletsRequest = createAction('GET_CRYPTO_DAY1_WALLETS_REQUEST');
export const getCryptoDay1WalletsSuccess = createAction('GET_CRYPTO_DAY1_WALLETS_SUCCESS');
export const getCryptoDay1WalletsFail = createAction('GET_CRYPTO_DAY1_WALLETS_FAIL');

export const updateCryptoDay1WalletRequest = createAction('UPDATE_CRYPTO_DAY1_WALLET_REQUEST');
export const updateCryptoDay1WalletSuccess = createAction('UPDATE_CRYPTO_DAY1_WALLET_SUCCESS');
export const updateCryptoDay1WalletFail = createAction('UPDATE_CRYPTO_DAY1_WALLET_FAIL');

export const setCryptoDay1SelectedAccount = createAction('SET_CRYPTO_DAY1_SELECTED_ACCOUNT');

export const clearCryptoDay1State = createAction('CLEAR_CRYPTO_DAY1_STATE');

export const paymentsDepositPaymentAsiaRequest = createAction(
  'PAYMENTS_DEPOSIT_PAYMENT_ASIA_REQUEST',
);
export const paymentsDepositPaymentAsiaSuccess = createAction(
  'PAYMENTS_DEPOSIT_PAYMENT_ASIA_SUCCESS',
);
export const paymentsDepositPaymentAsiaFail = createAction('PAYMENTS_DEPOSIT_PAYMENT_ASIA_FAIL');

export const getBankInfoRequest = createAction('GET_BANK_INFO_REQUEST');
export const getBankInfoSuccess = createAction('GET_BANK_INFO_SUCCESS');
export const getBankInfoFail = createAction('GET_BANK_INFO_FAIL');

export const searchDepositHistoryRequest = createAction('SEARCH_DEPOSIT_HISTORY_REQUEST');
export const searchDepositHistorySuccess = createAction('SEARCH_DEPOSIT_HISTORY_SUCCESS');
export const searchDepositHistoryFail = createAction('SEARCH_DEPOSIT_HISTORY_FAIL');

export const confirmPaymentInit = createAction('CONFIRM_PAYMENT_INIT');

export const getConversionRateDepositRequest = createAction('GET_CONVERSION_RATE_DEPOSIT_REQUEST');
export const getConversionRateDepositSuccess = createAction('GET_CONVERSION_RATE_DEPOSIT_SUCCESS');
export const getConversionRateDepositFail = createAction('GET_CONVERSION_RATE_DEPOSIT_FAIL');

export const paymentsDepositPraxisRequest = createAction('PAYMENTS_DEPOSIT_PRAXIS_REQUEST');
export const paymentsDepositPraxisSuccess = createAction('PAYMENTS_DEPOSIT_PRAXIS_SUCCESS');
export const paymentsDepositPraxisFail = createAction('PAYMENTS_DEPOSIT_PRAXIS_FAIL');

export const paymentsDepositZepto = createAction('PAYMENTS_DEPOSIT_ZEPTO');

export const paymentsDepositZeptoRequest = createAction('PAYMENTS_DEPOSIT_ZEPTO_REQUEST');
export const paymentsDepositZeptoSuccess = createAction('PAYMENTS_DEPOSIT_ZEPTO_SUCCESS');
export const paymentsDepositZeptoFail = createAction('PAYMENTS_DEPOSIT_ZEPTO_FAIL');

export const paymentsDepositMonoova = createAction('PAYMENTS_DEPOSIT_MONOOVA');
export const paymentsDepositMonoovaRequest = createAction('PAYMENTS_DEPOSIT_MONOOVA_REQUEST');
export const paymentsDepositMonoovaSuccess = createAction('PAYMENTS_DEPOSIT_MONOOVA_SUCCESS');
export const paymentsDepositMonoovaFail = createAction('PAYMENTS_DEPOSIT_MONOOVA_FAIL');

export const paymentsDepositFinmo = createAction('PAYMENTS_DEPOSIT_FINMO');

export const paymentsDepositFinmoRequest = createAction('PAYMENTS_DEPOSIT_FINMO_REQUEST');
export const paymentsDepositFinmoSuccess = createAction('PAYMENTS_DEPOSIT_FINMO_SUCCESS');
export const paymentsDepositFinmoFail = createAction('PAYMENTS_DEPOSIT_FINMO_FAIL');

export const paymentsDepositFinmoSimulateRequest = createAction(
  'PAYMENTS_DEPOSIT_FINMO_SIMULATE_REQUEST',
);
export const paymentsDepositFinmoSimulateSuccess = createAction(
  'PAYMENTS_DEPOSIT_FINMO_SIMULATE_SUCCESS',
);
export const paymentsDepositFinmoSimulateFail = createAction(
  'PAYMENTS_DEPOSIT_FINMO_SIMULATE_FAIL',
);

export const openPaymentsDepositFinmoSimulate = createAction(
  'OPEN_PAYMENTS_DEPOSIT_FINMO_SIMULATE',
);

export const paymentsDepositZeptoSimulateRequest = createAction(
  'PAYMENTS_DEPOSIT_ZEPTO_SIMULATE_REQUEST',
);
export const paymentsDepositZeptoSimulateSuccess = createAction(
  'PAYMENTS_DEPOSIT_ZEPTO_SIMULATE_SUCCESS',
);
export const paymentsDepositZeptoSimulateFail = createAction(
  'PAYMENTS_DEPOSIT_ZEPTO_SIMULATE_FAIL',
);

export const openPaymentsDepositZeptoSimulate = createAction(
  'OPEN_PAYMENTS_DEPOSIT_ZEPTO_SIMULATE',
);
