import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    '@global': {
      '*': {
        boxSizing: 'border-box',
      },
      html: {
        fontFamily: theme.font.fontFamily,
        fontSize: theme.font.htmlBodyFontSize,
      },
      body: {
        margin: 0,
        padding: 0,
      },
      'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  }),
  { name: 'AppCssBaseline' },
);
