import common from './common';

const OverridesMuiOutlinedInput = {
  root: {
    borderRadius: common.formControl.root.borderRadius,
    fontSize: common.formControl.root.fontSize,
    lineHeight: common.formControl.root.lineHeight,
    '&:hover $notchedOutline': {
      borderColor: common.formControl.hover.borderColor,
    },
    '&$focused $notchedOutline': {
      borderWidth: common.formControl.focus.borderWidth,
      borderColor: common.formControl.focus.borderColor,
    },
  },
  input: {
    paddingTop: common.formControl.root.paddingTop,
    paddingBottom: common.formControl.root.paddingBottom,
    height: common.formControl.root.height,
    borderRadius: common.formControl.root.borderRadius, //override autocomplete style
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '&::placeholder': {
      ...common.formControl.placeholder,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  marginDense: {
    '&$adornedStart': {
      paddingLeft: 10,
    },
    '&$adornedEnd': {
      paddingRight: 10,
    },
  },
  inputMarginDense: {
    ...common.formControl.smallSize,
  },
  multiline: {
    paddingTop: common.formControl.root.paddingTop,
    paddingBottom: common.formControl.root.paddingBottom,
    minHeight: common.formControl.root.height,
  },
  inputMultiline: {
    whiteSpace: 'pre-wrap',
    textOverflow: 'initial',
    overflow: 'initial',
    borderRadius: 0,
  },
};

export default OverridesMuiOutlinedInput;
