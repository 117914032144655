import React, { useCallback, useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import useMatchUrl from '../../../hooks/navigation/useMatchUrl';
import useDefaultTabPath from './hooks/useDefaultTabPath';
import mergeUrlPaths from 'utils/mergeUrlPaths';
import { Redirect, Route, Switch } from 'react-router-dom';
import TPTabPanel from 'components/TP-UI/TPTabPanel';
import useSelectedTab from './hooks/useSelectedTab';
import TPTabLabel from './components/TPTabLabel';
import { TPSelect } from 'components/TP-UI/TPSelect';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTabs from './hooks/useTabs';

const TPTabs = ({
  tabs = [],
  value,
  nav = false,
  loading = false,
  autofocus = false,
  keepOnlySelected = true,
  className,
  onChange,
  disabled = false,
}) => {
  const isSmallScreenSize = !useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const currentBaseUrl = useMatchUrl();
  const displayedTabs = useTabs(tabs, nav);
  const defaultPath = useDefaultTabPath(displayedTabs);
  const Tag = nav ? Switch : React.Fragment;

  const [selectedTab, setSelectedTab] = useSelectedTab(displayedTabs, nav);

  const handleSelectChange = useCallback(
    (newValue) => {
      setSelectedTab(newValue);
      if (onChange) {
        onChange(tabs[newValue].key || newValue);
      }
    },
    [onChange, tabs, setSelectedTab],
  );

  const handleChange = useCallback(
    (event, newValue) => {
      setSelectedTab(newValue);
      if (onChange) {
        onChange(tabs[newValue].key || newValue);
      }
    },
    [onChange, tabs, setSelectedTab],
  );

  useEffect(() => {
    if (value !== null && value !== undefined && tabs.length) {
      const index = tabs.findIndex((tab) => tab.key === value);
      if (index === -1) {
        handleSelectChange(0);
      } else {
        setSelectedTab(index >= 0 ? index : 0);
      }
    }
  }, [value, tabs, setSelectedTab, handleSelectChange]);

  useEffect(() => {
    //url is start point to render selected nav tab
    if (value === undefined && selectedTab !== false && nav && tabs.length) {
      const val = tabs[selectedTab].key || selectedTab;
      if (value !== val) {
        handleSelectChange(selectedTab);
      }
    }
  }, [value, tabs, nav, selectedTab, handleSelectChange]);

  return (
    <div className={className}>
      {isSmallScreenSize ? (
        <TPSelect
          disabled={disabled}
          name="tabs"
          fullWidth
          value={selectedTab}
          optionValue="itemKey"
          options={displayedTabs}
          renderValue={(tab) => <TPTabLabel {...tab} />}
          renderOption={(tab) => <TPTabLabel {...tab} />}
          onChange={handleSelectChange}
          autofocus={autofocus}
          reservedErrorSpace={false}
        />
      ) : (
        <Tabs disabled={disabled} onChange={handleChange} value={selectedTab}>
          {displayedTabs.map((tab, index) => {
            return (
              <Tab
                className={tab.className}
                disabled={tab.disabled || disabled}
                key={index}
                disableRipple={true}
                label={<TPTabLabel {...tab} />}
                id={`tab-${index}`}
                aria-controls={`tabpanel-${index}`}
                component={tab.component}
                to={tab.to || null}
              />
            );
          })}
        </Tabs>
      )}
      <Tag>
        {displayedTabs.map(
          ({ content, contentProps = {}, panelProps = {}, loading: tabLoading, path }, index) => {
            const routePath = mergeUrlPaths([currentBaseUrl, path]);
            const paths = ['/en', '/au', ''].map((hub) => hub + routePath);
            const tabPanelProps = {
              id: `tabpanel-${index}`,
              'aria-labelledby': `tab-${index}`,
              key: index,
              content,
              contentProps,
              panelProps,
              loading: loading || tabLoading,
              selected: selectedTab === index,
              keepOnlySelected: keepOnlySelected,
            };
            return nav ? (
              <Route path={paths} exact key={index}>
                <TPTabPanel {...tabPanelProps} />
              </Route>
            ) : (
              <TPTabPanel {...tabPanelProps} />
            );
          },
        )}
        {nav ? <Redirect to={mergeUrlPaths([currentBaseUrl, defaultPath], true)} /> : null}
      </Tag>
    </div>
  );
};

TPTabs.muiName = Tabs.muiName;
TPTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]).isRequired,
      path: PropTypes.string,
      disabled: PropTypes.bool,
      className: PropTypes.string,
      badgeContent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      content: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.elementType]),
      contentProps: PropTypes.object,
      key: PropTypes.string,
    }),
  ).isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  autofocus: PropTypes.bool,
  nav: PropTypes.bool,
  keepOnlySelected: PropTypes.bool,
  className: PropTypes.string,
  //size: PropTypes.oneOf(['medium', 'small']),
  onChange: PropTypes.func,
};

/**
 * Be sure that you set default state for tabs, since there is no error handling for tabs (weird).
 * This wrapper was added for cases when it's radio buttons and used in forms, but it looks like tabs.
 */
export const TPReduxTabs = ({ input, ...others }) => {
  const { onChange } = input;
  return <TPTabs {...input} {...others} onChange={onChange} />;
};

export default TPTabs;
