import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = {
  items: null,
  total: undefined,
  query: null,
};

export default handleActions(
  {
    [actionCreators.getPendingWithdrawalsSuccess](state, action) {
      return {
        ...state,
        items: !action.payload.persistStoreData ? action.response.data.withdrawals : state.items,
        total: !action.payload.persistStoreData ? action.response.data.totalNumber : state.total,
      };
    },
    [actionCreators.setPendingWithdrawalsQuery](state, action) {
      return {
        ...state,
        query: action.payload,
      };
    },

    [actionCreators.getPendingWithdrawalsFail]() {
      return defaultState;
    },
  },
  defaultState,
);
