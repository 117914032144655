import { typographyFonts } from '../typographyFonts';
import { fontFamily, fontWeight, htmlBodyFontSize } from '../font';

export const typography = {
  fontFamily: fontFamily,
  htmlFontSize: htmlBodyFontSize,
  fontWeightLight: fontWeight.light,
  fontWeightRegular: fontWeight.regular,
  fontWeightMedium: fontWeight.bold,
  h1: typographyFonts.h1,
  h2: typographyFonts.h2,
  h3: typographyFonts.h3,
  h4: typographyFonts.h4,
  h5: typographyFonts.h5,
  h6: typographyFonts.h6,
  subtitle1: typographyFonts.subtitle1,
  subtitle2: typographyFonts.subtitle2,
  body1: typographyFonts.body1,
  body2: typographyFonts.body2,
  body3: typographyFonts.body3, //custom variant
  button: typographyFonts.button,
};

export const OverridesTypography = {
  gutterBottom: { marginBottom: '0.5em' },
};
