export default () => ({
  root: {
    borderRadius: '4px 4px 0 0',
    backgroundColor: '#EFF2F7',
    padding: 10,
    zIndex: 2,
  },
  button: {
    height: 30,
    marginLeft: 15,
  },
  datePickerWrapper: {
    height: 32,
  },
  settingsIcon: {
    color: '#c2c2c5',
    cursor: 'pointer',
  },
  customSelector: {
    marginRight: 15,
  },
  statsCustomization: {},
  additionalFilter: {
    marginLeft: 20,
  },
  icon: {
    color: '#c2c2c5',
    cursor: 'pointer',
  },
});
