import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import PrimaryButton from 'modules/common/PrimaryButton';
import SecondaryButton from 'modules/common/SecondaryButton';

const TableTwoButtonsField = ({
  classes,
  approveLabel,
  declineLabel,
  openDeclineModal,
  openConfirmModal,
  tableItem,
}) => {
  const approveLabelValue =
    typeof approveLabel === 'function' ? approveLabel(tableItem) : approveLabel;

  const declineLabelValue =
    typeof declineLabel === 'function' ? declineLabel(tableItem) : declineLabel;

  return (
    <div className={classes.item}>
      <SecondaryButton
        color="primary"
        label={declineLabelValue}
        className={classes.button}
        onClick={() => openDeclineModal(tableItem)}
      />
      <PrimaryButton
        color="primary"
        label={approveLabelValue}
        className={classes.button}
        onClick={() => openConfirmModal(tableItem)}
      />
    </div>
  );
};

TableTwoButtonsField.propTypes = {
  approveLabel: PropTypes.string.isRequired,
  declineLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  openDeclineModal: PropTypes.func.isRequired,
  openConfirmModal: PropTypes.func.isRequired,
  tableItem: PropTypes.object.isRequired,
};

export default withStyles(styles)(TableTwoButtonsField);
