import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    container: {
      padding: theme.spacing(3),
      minHeight: 435,
      [theme.breakpoints.down('xs')]: {
        minHeight: 'auto',
      },
    },
    image: {
      width: '100%',
      maxWidth: 200,
      maxHeight: 200,
    },
  }),
  { name: 'NoMatches' },
);
