import { handleActions } from 'redux-actions';
import {
  paymentsCreateDay1UserFail,
  paymentsCreateDay1UserSuccess,
  paymentsGetDay1UserSuccess,
} from '../actions';

const defaultState = {
  user: null,
};

export default handleActions(
  {
    [paymentsGetDay1UserSuccess](state, action) {
      return {
        ...state,
        user: action.response.data,
      };
    },
    [paymentsCreateDay1UserSuccess](state, action) {
      return {
        ...state,
        user: action.response.data,
      };
    },
    [paymentsCreateDay1UserFail](state) {
      return {
        ...state,
        user: null,
      };
    },
  },
  defaultState,
);
