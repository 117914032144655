import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    trendingContainer: {
      display: 'inline-flex',
    },
    up: {
      ...theme.numberDifference.up,
    },
    down: {
      ...theme.numberDifference.down,
    },
    flat: {
      ...theme.numberDifference.flat,
    },
    smallSize: {
      ...theme.numberDifference.smallSize,
    },
    mediumSize: {
      ...theme.numberDifference.mediumSize,
    },
    largeSize: {
      ...theme.numberDifference.largeSize,
    },
    startAdornment: {
      paddingRight: theme.spacing(1),
    },
    endAdornment: {
      paddingLeft: theme.spacing(1),
    },
  }),
  { name: 'TPTrending' },
);
