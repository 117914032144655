import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import TPTrendingIcon from '../TPTrendingIcon';

import useStyles from './styles';

const TPTrending = ({
  value,
  label = '',
  hideIcon = false,
  iconPosition = 'end',
  size = '',
  className,
}) => {
  const classes = useStyles();
  const trending =
    value > 0
      ? { sign: '+', value: 'up' }
      : value < 0
      ? { sign: '-', value: 'down' }
      : { value: 'flat' };
  return value !== undefined && value !== null ? (
    <span
      className={classnames(
        classes.trendingContainer,
        { [classes[trending.value]]: trending?.value, [classes[`${size}Size`]]: size },
        className,
      )}>
      {!hideIcon && iconPosition === 'start' ? (
        <span className={classes.startAdornment}>
          <TPTrendingIcon trending={trending.value} />
        </span>
      ) : null}
      {label}
      {!hideIcon && iconPosition === 'end' ? (
        <span className={classes.endAdornment}>
          <TPTrendingIcon trending={trending.value} />
        </span>
      ) : null}
    </span>
  ) : null;
};

TPTrending.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.node,
  hideIcon: PropTypes.bool,
  iconPosition: PropTypes.oneOf(['start', 'end']),
  size: PropTypes.oneOf(['', 'large', 'medium', 'small']),
};

export default TPTrending;
