import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import NotificationsLink from '../components/NotificationsLink';
import { Routes } from 'constants/routeConstants';
import { getPendingNotificationsCount } from '../selectors';
import { getPendingNotificationsCountRequest } from '../actions';

const InternalNotificationBellContainer = ({ onClick }) => {
  const dispatch = useDispatch();
  const notificationsCount = useSelector(getPendingNotificationsCount);

  useEffect(() => {
    dispatch(getPendingNotificationsCountRequest());
  }, [dispatch]);

  return (
    <NotificationsLink
      notificationsCount={notificationsCount}
      path={Routes.VERIFICATION_MANAGEMENT}
      onClick={onClick}
    />
  );
};

InternalNotificationBellContainer.propTypes = {
  onClick: PropTypes.func,
};

export default InternalNotificationBellContainer;
