const OverridesMuiStep = {
  horizontal: {
    paddingLeft: 4,
    paddingRight: 4,
  },
  vertical: {
    paddingTop: 4,
    paddingBottom: 4,
  },
};

export default OverridesMuiStep;
