import { handleActions } from 'redux-actions';

import * as actionCreators from '../actions';

const defaultState = {
  conversionRate: 0,
  limitsConversionRate: 1,
  availableBanks: [],
};

export default handleActions(
  {
    [actionCreators.currencyCalculatorSuccess](state, action) {
      return { ...state, conversionRate: action.response.data.conversionRate };
    },
    [actionCreators.limitsCurrencyCalculatorSuccess](state, action) {
      return { ...state, limitsConversionRate: action.response.data.conversionRate };
    },
    [actionCreators.currencyCalculatorReset](state) {
      return { ...state, conversionRate: 0 };
    },
    [actionCreators.getAvailableLocalBanksSuccess](state, action) {
      return { ...state, availableBanks: action.response.data };
    },
  },
  defaultState,
);
