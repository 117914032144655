import {
  getIsAuthenticated,
  getIsFirstVisit,
  getUserRole,
  getUserHub,
  getUserCountry,
  getUserProStatus,
} from 'modules/auth/selectors';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MainLayout from '../components/MainLayout';
import SegmentTracker from '../../SegmentTracker';

const MainLayoutContainer = ({
  title,
  headerContent,
  mobileContent,
  desktopContent,
  contentClassName,
  gradientClassName,
  titleClassName,
  isPublic,
  children,
  breadcrumbsRoutes,
}) => {
  const authenticated = useSelector(getIsAuthenticated);
  const firstVisit = useSelector(getIsFirstVisit);
  const userRole = useSelector(getUserRole);
  const hub = useSelector(getUserHub);
  const userCountry = useSelector(getUserCountry);
  const pro = useSelector(getUserProStatus);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleToggleDrawer = useCallback(() => {
    setDrawerOpen((drawerOpen) => !drawerOpen);
  }, [setDrawerOpen]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      <MainLayout
        title={title}
        headerContent={headerContent}
        mobileContent={mobileContent}
        desktopContent={desktopContent}
        drawerOpen={drawerOpen}
        contentClassName={contentClassName}
        gradientClassName={gradientClassName}
        titleClassName={titleClassName}
        authenticated={authenticated}
        isPublic={isPublic}
        toggleDrawer={handleToggleDrawer}
        userRole={userRole}
        hub={hub}
        userCountry={userCountry}
        pro={pro}
        firstVisit={firstVisit}
        breadcrumbsRoutes={breadcrumbsRoutes}>
        {children}
      </MainLayout>
      <SegmentTracker />
    </>
  );
};

export default MainLayoutContainer;
