import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    container: {
      backgroundColor: theme.palette.primary.lightest,
      color: theme.palette.text.disabled,
    },
  }),
  { name: 'Loaders' },
);
