import React, { useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getClientRect } from '../../utils';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import TPGrid from 'components/TP-UI/TPGrid';
import TPButton from 'components/TP-UI/TPButton';
import TPTypography from 'components/TP-UI/TPTypography';
import TPPopoverBase from 'components/TP-UI/TPPopoverBase';
import Backdrop from '@material-ui/core/Backdrop';
import useStyles from './styles';

const Step = ({ stepData, allSteps, anchorEl, onSkip, onNext, onFinish, onBack }) => {
  const classes = useStyles();
  const { t } = useTranslation('onboarding');

  const spotlightRef = useRef(null);
  const { id, messageKey, messageKeys } = stepData;
  const open = Boolean(anchorEl);
  const popoverId = `onboarding-step-${id}`;
  const isLastStep = id === allSteps;
  const isSmallScreenSize = !useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const anchorPosition = useMemo(() => {
    const { anchorPos } = stepData;
    return isSmallScreenSize && !anchorPos
      ? {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          transformOrigin: { vertical: 'bottom', horizontal: 'center' },
        }
      : {
          anchorOrigin: {
            vertical: anchorPos?.anchor?.vertical || 'center',
            horizontal: anchorPos?.anchor?.horizontal || 'right',
          },
          transformOrigin: {
            vertical: anchorPos?.transform?.vertical || 'center',
            horizontal: anchorPos?.transform?.horizontal || 'left',
          },
        };
  }, [isSmallScreenSize, stepData]);

  useEffect(() => {
    if (anchorEl != null) {
      const { top, left, width, height } = getClientRect(anchorEl);
      const spotlightElement = spotlightRef.current;
      if (spotlightElement) {
        spotlightElement.style.top = `${top}px`;
        spotlightElement.style.left = `${left}px`;
        spotlightElement.style.width = `${width}px`;
        spotlightElement.style.height = `${height}px`;
      }
    }
  }, [anchorEl]);

  return (
    <>
      <Backdrop className={classes.backdrop} open={open}>
        <div ref={spotlightRef} className={classes.spotlight} />
      </Backdrop>
      {anchorEl ? (
        <TPPopoverBase
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          PaperProps={{ className: classes.popoverPaper }}
          {...anchorPosition}>
          <TPGrid container direction="column" className={classes.content} spacing={1}>
            <TPGrid item container justifyContent="space-between" alignItems="center" spacing={1}>
              <TPGrid item>
                <TPTypography variant="body2" color="secondary">{`${id}/${allSteps}`}</TPTypography>
              </TPGrid>
              {!isLastStep && (
                <TPGrid item>
                  <TPButton secondary size="medium" onClick={onSkip} className={classes.btn}>
                    {t('buttons.skip')}
                  </TPButton>
                </TPGrid>
              )}
            </TPGrid>
            <TPGrid item>
              {messageKey && <TPTypography>{t(messageKey)}</TPTypography>}
              {messageKeys && (
                <ul className={classes.list}>
                  {messageKeys.map((key, index) => (
                    <li key={`tradeIdeas-${index}`} className={classes.listItem}>
                      {t(key)}
                    </li>
                  ))}
                </ul>
              )}
            </TPGrid>
            <TPGrid
              item
              container
              spacing={1}
              alignItems="center"
              justifyContent="flex-end"
              className={classes.btnRow}>
              <TPGrid item>
                {id !== 1 && (
                  <TPButton secondary size="medium" onClick={onBack}>
                    {t('buttons.back')}
                  </TPButton>
                )}
              </TPGrid>
              <TPGrid item>
                <TPButton primary size="medium" onClick={isLastStep ? onFinish : onNext}>
                  {t(`buttons.${isLastStep ? 'finish' : 'next'}`)}
                </TPButton>
              </TPGrid>
            </TPGrid>
          </TPGrid>
        </TPPopoverBase>
      ) : null}
    </>
  );
};

Step.propTypes = {
  stepData: PropTypes.shape({
    id: PropTypes.number,
    messageKey: PropTypes.string,
    messageKeys: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  allSteps: PropTypes.number.isRequired,
  anchorEl: PropTypes.shape({
    current: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  }),
  onSkip: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default Step;
