import React from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'modules/common/Page';
import MainLayout from 'modules/common/MainLayout/components/MainLayout';
import { WITHDRAWAL2_PATH } from 'constants/routeConstants';
import WithdrawHistoryLayout from '../../containers/WithdrawHistoryLayoutContainer';

const WithdrawalHistory = () => {
  const { t } = useTranslation('payments');

  return (
    <Page docTitle={t('page.withdrawalHistory')}>
      <MainLayout
        title={t('page.withdrawalHistory')}
        breadcrumbsRoutes={[
          { label: t('page.title'), path: WITHDRAWAL2_PATH },
          {
            label: t('page.withdrawalHistory'),
            path: '',
          },
        ]}>
        <WithdrawHistoryLayout />
      </MainLayout>
    </Page>
  );
};

export default WithdrawalHistory;
