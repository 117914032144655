import { FUSION_MARKETS, FUSION_MARKETS_X, FUSION_MARKETS_EU } from 'constants/index';

export const isAuHub = (hub) => {
  return hub === FUSION_MARKETS;
};

export const isEnHub = (hub) => {
  return hub === FUSION_MARKETS_X;
};

export const isEuHub = (hub) => {
  return hub === FUSION_MARKETS_EU;
};
