import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      maxWidth: 120,
    },
    fileContainer: {
      display: 'flex',
      width: '100%',
      height: 120,
      overflow: 'hidden',
      borderRadius: 10,
      border: `1px solid ${theme.palette.primary.lightest}`,
    },
    fileImage: {
      width: 'auto',
      maxWidth: 'fit-content',
    },
    fileExtension: {
      textTransform: 'uppercase',
    },
    nonImageFileContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.primary.lightest,
      padding: theme.spacing(2),
      flex: 1,
    },
  }),
  { name: 'TPSliderItem' },
);
