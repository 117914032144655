import createAction from 'redux-actions/es/createAction';

export const verificationPageOpened = createAction('VERIFICATION_PAGE_OPENED');

export const verificationKeepCurrentStep = createAction('VERIFICATION_KEEP_CURRENT_STEP');

export const updatePersonalDetailsRequest = createAction('UPDATE_PERSONAL_DETAILS_REQUEST');
export const updatePersonalDetailsSuccess = createAction('UPDATE_PERSONAL_DETAILS_SUCCESS');
export const updatePersonalDetailsFail = createAction('UPDATE_PERSONAL_DETAILS_FAIL');

export const classicVerificationUpdateRequest = createAction('CLASSIC_VERIFICATION_UPDATE_REQUEST');
export const classicVerificationUpdateSuccess = createAction('CLASSIC_VERIFICATION_UPDATE_SUCCESS');
export const classicVerificationUpdateFail = createAction('CLASSIC_VERIFICATION_UPDATE_FAIL');
export const verificationStepIdDuplicate = createAction('VERIFICATION_STEP_ID_DUPLICATE');
export const duplicateIdDifferentSteps = createAction('DUPLICATE_ID_DIFFERENT_STEPS');

export const classicVerificationSecondStepUpdateRequest = createAction(
  'CLASSIC_VERIFICATION_SECOND_STEP_UPDATE_REQUEST',
);
export const classicVerificationSecondStepUpdateSuccess = createAction(
  'CLASSIC_VERIFICATION_SECOND_STEP_UPDATE_SUCCESS',
);
export const classicVerificationSecondStepUpdateFail = createAction(
  'CLASSIC_VERIFICATION_SECOND_STEP_UPDATE_FAIL',
);

export const settingsVerificationUpdateRequest = createAction(
  'SETTINGS_VERIFICATION_UPDATE_REQUEST',
);
export const settingsVerificationUpdateSuccess = createAction(
  'SETTINGS_VERIFICATION_UPDATE_SUCCESS',
);
export const settingsVerificationUpdateFail = createAction('SETTINGS_VERIFICATION_UPDATE_FAIL');

export const getVerificationUserRequest = createAction('GET_VERIFICATION_USER_REQUEST');
export const getVerificationUserSuccess = createAction('GET_VERIFICATION_USER_SUCCESS');
export const getVerificationUserFail = createAction('GET_VERIFICATION_USER_FAIL');

export const initializePersonalDetails = createAction('INITIALIZE_PERSONAL_DETAILS');
export const initializeSettingsVerification = createAction('INITIALIZE_SETTINGS_VERIFICATION');
export const initializeClassicVerification = createAction('INITIALIZE_CLASSIC_VERIFICATION');
export const initializeClassicVerificationSecondStep = createAction(
  'INITIALIZE_CLASSIC_VERIFICATION_SECOND_STEP',
);

export const setNextStep = createAction('SET_NEXT_STEP');
export const changeStepConfirmed = createAction('CHANGE_STEP_CONFIRMED');
export const changeStepRejected = createAction('CHANGE_STEP_REJECTED');
export const changeStepForce = createAction('CHANGE_STEP_FORCE');

export const uploadRearSideClose = createAction('UPLOAD_REAR_SIDE_CLOSE');
export const uploadRearSideOpen = createAction('UPLOAD_REAR_SIDE_OPEN');

export const updateUserStateRequest = createAction('UPDATE_USER_STATE_REQUEST');
export const updateUserStateSuccess = createAction('UPDATE_USER_STATE_SUCCESS');
export const updateUserStateFail = createAction('UPDATE_USER_STATE_FAIL');

export const updateUserAddressLineRequest = createAction('UPDATE_USER_ADDRESS_LINE_REQUEST');
export const updateUserAddressLineSuccess = createAction('UPDATE_USER_ADDRESS_LINE_SUCCESS');
export const updateUserAddressLineFail = createAction('UPDATE_USER_ADDRESS_LINE_FAIL');
