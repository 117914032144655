import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TPModal from 'components/TP-UI/TPModal';
import { TMD_WARNING_MODAL } from '../../constants';

const TMDWarningModal = ({ onAccepted, onCancel }) => {
  const buttons = useMemo(
    () => [
      { label: 'Go To Questionnaire', primary: true, onClick: onAccepted },
      { label: 'Not Now', secondary: true, onClick: onCancel },
    ],
    [onAccepted, onCancel],
  );

  return (
    <TPModal
      modalName={TMD_WARNING_MODAL}
      onClose={onCancel}
      title="Appropriateness Test"
      text="Please ensure you read the questions carefully. Your ability to continue with Fusion will depend on whether you’re able to correctly answer the next 3 questions."
      buttons={buttons}
    />
  );
};

TMDWarningModal.propTypes = {
  onAccepted: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default TMDWarningModal;
