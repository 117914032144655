import React, { useEffect } from 'react';
import { Field, getFormValues, change } from 'redux-form';
import TPGrid from 'components/TP-UI/TPGrid';
import TPRadioGroup from 'components/TP-UI/TPRadioGroup';
import TPTypography from 'components/TP-UI/TPTypography';
import FormError from 'modules/common/FormError';
import { required } from 'utils/validation/fieldValidationRules';
import { useDispatch, useSelector } from 'react-redux';
import TPSelect from 'components/TP-UI/TPSelect';
import TPTextField from 'components/TP-UI/TPTextField';
import PropTypes from 'prop-types';

const EmploymentStatusForm = ({
  questionId,
  questionLabel,
  answers,
  form,
  error,
  submitFailed,
}) => {
  const formValues = useSelector(getFormValues(form));
  const dispatch = useDispatch();
  const selectedAnswer = formValues[questionId]
    ? answers.find((answer) => answer.value === formValues[questionId])
    : null;

  useEffect(() => {
    // cleanup nested fields if new answer doesn't have any
    if (selectedAnswer && !selectedAnswer.questions) {
      const nestedFields = answers.reduce((acc, answer) => {
        if (answer.questions?.length) {
          acc = [...acc, ...answer.questions.map(({ _id }) => _id)];
        }
        return acc;
      }, []);
      if (nestedFields.length) {
        nestedFields.forEach((field) => {
          dispatch(change(form, field, undefined));
        });
      }
    }
  }, [answers, dispatch, form, selectedAnswer]);

  return (
    <TPGrid container spacing={3}>
      {error && submitFailed && (
        <TPGrid item xs={12}>
          <FormError error={error} />
        </TPGrid>
      )}
      <TPGrid item container spacing={1}>
        <TPGrid item xs={12}>
          <TPTypography variant="body1">{questionLabel}</TPTypography>
        </TPGrid>
        <TPGrid item xs={12}>
          <Field
            buttons={answers}
            component={TPRadioGroup}
            name={questionId}
            validate={[required]}
          />
        </TPGrid>
        <TPGrid item container xs={12}>
          {selectedAnswer?.questions?.length ? (
            <>
              {selectedAnswer.questions.map((question) => (
                <TPGrid key={question._id} item xs={12}>
                  <Field
                    name={question._id}
                    component={TPSelect}
                    label={question.label}
                    options={question.answers}
                    fullWidth
                    required
                    validate={[required]}
                    placeholder={question.label}
                  />
                </TPGrid>
              ))}
              {Object.values(formValues).includes('other') ? (
                <TPGrid item xs={12}>
                  <Field
                    name="other"
                    label="Industry"
                    validate={[required]}
                    required
                    component={TPTextField}
                    fullWidth
                  />
                </TPGrid>
              ) : null}
            </>
          ) : null}
        </TPGrid>
      </TPGrid>
    </TPGrid>
  );
};

EmploymentStatusForm.propTypes = {
  questionId: PropTypes.string.isRequired,
  questionLabel: PropTypes.string.isRequired,
  answers: PropTypes.array.isRequired,
  form: PropTypes.string.isRequired,
};

export default EmploymentStatusForm;
