import { combineActions, handleActions } from 'redux-actions';

import * as actionCreators from '../actions';
import { VerificationStatuses } from 'constants/verificationStatuses';
import { setNextStep } from '../actions';

const defaultState = {
  activeStep: 0,
  newStep: 0,
  keepCurrentStep: false,
};

export default handleActions(
  {
    [actionCreators.verificationPageOpened]() {
      return { ...defaultState };
    },
    [combineActions(
      actionCreators.getVerificationUserSuccess,
      actionCreators.updatePersonalDetailsSuccess,
      actionCreators.settingsVerificationUpdateSuccess,
      actionCreators.classicVerificationUpdateSuccess,
      actionCreators.classicVerificationSecondStepUpdateSuccess,
    )](state, action) {
      let newState = {
        ...state,
      };
      const wasUserRejected =
        action.response.data.verification.status === VerificationStatuses.REJECTED;
      if (!state.keepCurrentStep && !wasUserRejected) {
        newState.activeStep = action.response.data.verification.activeStep;
      } else if (!state.keepCurrentStep && wasUserRejected) {
        newState.activeStep = 2;
      }
      return newState;
    },
    [setNextStep](state) {
      return { ...state, activeStep: state.activeStep + 1 };
    },
    [combineActions(actionCreators.verificationKeepCurrentStep)](state, action) {
      return {
        ...state,
        keepCurrentStep: action.payload,
      };
    },
    [combineActions(actionCreators.changeStepConfirmed)](state) {
      return {
        ...state,
        activeStep: state.newStep,
      };
    },
    [combineActions(actionCreators.changeStepForce)](state, action) {
      return {
        ...state,
        activeStep: action.payload,
      };
    },
  },
  defaultState,
);
