import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { store } from 'components/App';
import { postErrorRequest } from '../actions';

import ErrorBoundary from 'modules/errorBoundary/components/ErrorBoundary';

class ErrorBoundaryContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, showContactSupportButton: false };
    this.observer = null;
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidMount() {
    this.observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList' && window.fcWidget) {
          window.fcWidget.on('widget:loaded', () => {
            this.setState({ showContactSupportButton: true });
          });
          this.observer.disconnect();
        }
      }
    });

    this.observer.observe(document.body, { childList: true, subtree: true });
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
    if (window.fcWidget) {
      window.fcWidget.off('widget:loaded');
    }
  }

  componentDidCatch(error, errorInfo) {
    const state = store.getState();
    if (get(state, 'geolocation.countries')) {
      delete state.geolocation.countries;
    }

    const errorData = {
      error: error.message || error,
      stack: error.stack,
      errorInfo,
      state,
      url: window.location.href,
      userAgent: window.navigator.userAgent,
    };
    this.props.postErrorRequest(errorData);
  }

  handleContactSupportClick = () => {
    if (window.fcWidget) {
      window.fcWidget.open();
    }
  };

  render() {
    const props = {
      onContactSupportClick: this.handleContactSupportClick,
      showContactSupportButton: this.state.showContactSupportButton,
    };

    if (this.state.hasError) {
      return <ErrorBoundary {...props} />;
    }

    return this.props.children;
  }
}

ErrorBoundaryContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  postErrorRequest: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  postErrorRequest: bindActionCreators(postErrorRequest, dispatch),
});

export default connect(null, mapDispatchToProps)(ErrorBoundaryContainer);
