import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import TPGrid from 'components/TP-UI/TPGrid';
import TPTypography from 'components/TP-UI/TPTypography';
import useStyles from './styles';
import TPPagination from 'components/TP-UI/TPPagination';
import formatFullDate from '../../../utils/formatFullDate';
import formatPrice from '../../../utils/formatPrice';
import { Routes } from '../../../constants/routeConstants';
import TPLink from 'components/TP-UI/TPLink';
import clients from './clients.json';
import TPButton from 'components/TP-UI/TPButton';
import Status from '../../common/Status';
import { Status as PaymentStatus } from '../../../constants/paymentStatus';
import { useTranslation } from 'react-i18next';
import TransactionsTable from './components/TransactionsTable';
import Table from 'modules/common/Table';
import TPSearchTextField from 'components/TP-UI/TPSearchTextField';
import TPDatePicker from 'components/TP-UI/TPDatePicker';
import TPSelect from 'components/TP-UI/TPSelect';
import { CALL_STATUS_RANGES } from 'modules/userManagement/constants';
import { LAST_CONTACT_RANGES } from 'modules/userManagement/pages/FollowUps/constants';
import { ACCOUNT_STATUSES } from '../../../constants';

const FILTER_FIELDS = [
  {
    name: 'search',
    component: TPSearchTextField,
    placeholder: 'Search',
    width: '250px',
  },
  {
    name: 'dateFrom',
    component: TPDatePicker,
    clearable: false,
    placeholder: 'Date from',
    width: '125px',
  },
  {
    name: 'dateTo',
    component: TPDatePicker,
    clearable: false,
    placeholder: 'Date to',
    width: '125px',
  },
  {
    name: 'callStatus',
    component: TPSelect,
    placeholder: 'Call Status(s)',
    options: CALL_STATUS_RANGES.slice(2),
    multiple: true,
    width: '270px',
  },
  {
    name: 'lastContact',
    component: TPSelect,
    placeholder: 'Last Contact(s)',
    options: LAST_CONTACT_RANGES.slice(2),
    multiple: true,
  },
  {
    name: 'status',
    component: TPSelect,
    placeholder: 'Account status(s)',
    options: Object.entries(ACCOUNT_STATUSES).map(([key, value]) => ({ label: key, value: value })),
    multiple: true,
  },
];

const TABLE_FILTERS_FORM = 'TABLE_FILTERS_FORM';

const TABLE_FILTERS_INITAL_VALUES = {
  search: '',
  dateFrom: new Date().toISOString(),
  dateTo: new Date().toISOString(),
  callStatus: [],
  lastContact: [],
  status: [],
};

const FILTER_FIELDS_2 = [
  {
    name: 'search',
    component: TPSearchTextField,
    placeholder: 'Search',
  },
  {
    name: 'callStatus',
    component: TPSelect,
    placeholder: 'Call Status(s)',
    options: CALL_STATUS_RANGES.slice(2),
    multiple: true,
    width: '270px',
  },
];

const TABLE_FILTERS_FORM_2 = 'TABLE_FILTERS_FORM_2';

const TABLE_FILTERS_INITAL_VALUES_2 = {
  search: 'Bla',
  callStatus: [],
};

const FILE_ACTIONS = [
  {
    label: 'Generate XSLT',
    onClick: () => {
      console.log('generate func');
    },
  },
];

const COLUMNS = [
  {
    label: 'Username',
    dataKey: 'email',
    default: true,
    sortable: true,
  },
  {
    label: 'Date Of Registration',
    dataKey: 'createdAt',
    default: true,
    width: '140px',
    format: formatFullDate,
    sortable: true,
  },
  {
    label: 'Account Status',
    width: '120px',
    default: true,
    dataKey: 'verification.status',
    //filterName: 'status', //?? for filtration
    renderItem: (item) => <Status value={item.verification.status} />,
  },
  {
    label: 'Country',
    dataKey: 'profile.country',
    default: true,
    width: '170px',
  },
  {
    label: 'Demo Accounts',
    dataKey: 'demoAccounts',
    default: true,
    width: '120px',
    format: (value) => value.map((i) => i.mt4login).join(', '),
  },
  {
    label: 'Live Accounts',
    dataKey: 'liveAccounts',
    default: true,
    width: '120px',
    format: (value) => value.map((i) => i.mt4login).join(', '),
  },
  {
    label: 'Lead Manager',
    dataKey: 'leadId',
    width: '150px',
    //filterName: 'leadId',
  },
  {
    label: 'Total Balance',
    dataKey: 'totalBalance',
    width: '100px',
    textStyle: 'bold',
    //filterName: 'balance', boldText,
    format: formatPrice,
  },
  {
    label: 'CXD',
    dataKey: 'cxd',
    //filterName: 'cxd',
  },
  // {
  //   label: 'RefCode',
  //   dataKey: 'refcode',
  //   //filterName: 'cxd',
  // },
  {
    label: 'Volume',
    dataKey: 'volume',
    textStyle: 'bold',
    //filterName: 'volume', boldText
    format: formatPrice,
  },
  {
    label: 'Last Login',
    dataKey: 'lastLoginDays',
    width: '120px',
    filterName: 'lastLogin',
    format: (value) => `${value} days ago`,
  },
  {
    label: 'Last Demo Created',
    dataKey: 'lastDemoDays',
    width: '120px',
    filterName: 'lastDemo',
    format: (value) => `${value} days ago`,
  },
  {
    label: 'First Trade',
    dataKey: 'firstTradeDays',
    width: '120px',
    //filterName: 'firstTrade',
    format: (value) => `${value} days ago`,
  },
  // {
  //   label: 'Last Trade',
  //   dataKey: 'lastTradeDays',
  //   //filterName: 'lastTrade',
  //   format: (value) => `${value} days ago`,
  // },
  {
    label: 'Call Status',
    dataKey: 'callStatus',
    width: '150px',
    renderItem: (item) => <Status value={item.callStatus || ''} />,
    //filterName: 'callStatus', status field
  },
  {
    label: 'Comments',
    dataKey: 'comments',
    width: '220px',
    renderItem: (item) => (
      <TPTypography variant="body2" truncated lineClamp={2}>
        {item.comments}
      </TPTypography>
    ),
  },
];

const getItems = ({ offset, size }) => {
  return {
    items: clients.clients.slice(offset * size, (offset + 1) * size),
    totalCount: clients.totalNumber,
  };
};

const Tables = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [page, setPage] = useState();
  const [pageSize, setPageSize] = useState();
  const [sorting, setSorting] = useState({ sortBy: 'createdAt', sortAsc: false });
  const timeoutRef = useRef();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ currentPage: 0, pageSize: 10, total: 0 });
  const [items, setItems] = useState([]);
  const filters = useMemo(
    () => ({
      fields: FILTER_FIELDS,
      form: TABLE_FILTERS_FORM,
      initialValues: TABLE_FILTERS_INITAL_VALUES,
    }),
    [],
  );
  const filters2 = useMemo(
    () => ({
      fields: FILTER_FIELDS_2,
      form: TABLE_FILTERS_FORM_2,
      initialValues: TABLE_FILTERS_INITAL_VALUES_2,
    }),
    [],
  );
  const columns = useMemo(
    () => [
      ...COLUMNS.slice(0, 3),
      {
        label: t('labels.status'),
        renderItem: (item) => (
          <Status
            value={item.verification.status}
            tooltips={{
              [PaymentStatus.PENDING]: t('payments:withdraw.tooltips.statuses.pending'),
              [PaymentStatus.TOCONFIRM]: t('payments:withdraw.tooltips.statuses.toConfirm'),
              [PaymentStatus.APPROVED]: t('payments:withdraw.tooltips.statuses.success'),
            }}
          />
        ),
      },
      ...COLUMNS.slice(4),
      {
        width: '270px',
        textAlign: 'right',
        default: true,
        renderItem: (item) => {
          const showApproveButtons = item.verification.status === 'new';
          return showApproveButtons ? (
            <div className={classes.buttonsContainer}>
              <TPButton secondary>Decline</TPButton>
              <TPButton className={classes.button} primary>
                Approve
              </TPButton>
            </div>
          ) : (
            <TPLink path={`${Routes.USER_MANAGEMENT_DETAILS}/${item._id}`}>
              <TPButton className={classes.button} alternative>
                Show details
              </TPButton>
            </TPLink>
          );
        },
      },
    ],
    [t, classes.buttonsContainer, classes.button],
  );
  useEffect(() => {
    const options = { offset: pagination.currentPage, size: pagination.pageSize };
    setLoading(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setLoading(false);
      const response = getItems(options);
      setItems(response.items);
      const newPagination = { ...pagination, total: response.totalCount };
      if (JSON.stringify(newPagination) !== JSON.stringify(pagination)) {
        setPagination((val) => ({ ...val, total: response.totalCount }));
      }
    }, 1500);
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [pagination, timeoutRef]);

  const handleChange = useCallback((page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    setLoading(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  const handleUserTableChange = useCallback(({ page, pageSize, sortBy, sortAsc, ...filters }) => {
    console.log('filters', filters);
    setPagination((val) => ({ ...val, currentPage: page, pageSize }));
    setSorting((val) => ({ ...val, sortBy, sortAsc }));
  }, []);

  const Summary = () => <TPTypography variant="body2">Some summary info</TPTypography>;

  const handleSubmit = useCallback((val) => {
    console.log(val);
  }, []);

  return (
    <TPGrid container spacing={5}>
      <TPGrid item container>
        <TPGrid item xs={12}>
          <TPTypography variant="h4" className={classes.spacing}>
            Pagination
          </TPTypography>
          <TPPagination
            value={page}
            pageSize={pageSize}
            total={200}
            disabled={loading}
            onChange={handleChange}
            className={classes.spacing}
          />
        </TPGrid>
      </TPGrid>
      <TPGrid item container>
        <TPGrid item xs={12}>
          <TPTypography variant="h4">Table with pagination, columns customization</TPTypography>
          <TPTypography variant="body2" color="secondary">
            Some description here
          </TPTypography>
          <Table
            pagination={pagination}
            items={items}
            filters={filters}
            columns={columns}
            loading={loading}
            {...sorting}
            onChange={handleUserTableChange}
            itemKey="_id"
            scrollable
            allowColumnsSettings
            className={classes.table}
            summaryComponent={Summary}
          />
        </TPGrid>
      </TPGrid>
      <TPGrid item container>
        <TPGrid item xs={8}>
          <TPTypography variant="h4">
            Table with form and without pagination, filters, sorting and column settings
          </TPTypography>
          <TransactionsTable className={classes.table} onSubmit={handleSubmit} />
        </TPGrid>
      </TPGrid>
      <TPGrid item container>
        <TPGrid item xs={12} md={8}>
          <TPTypography variant="h4">Table no matches</TPTypography>
          <Table
            className={classes.table}
            pagination={pagination}
            items={[]}
            filters={filters2}
            columns={columns}
            loading={loading}
            fileActions={FILE_ACTIONS}
            allowColumnsSettings
            onChange={handleUserTableChange}
            itemKey="_id"
            scrollable={true}
          />
        </TPGrid>
      </TPGrid>
    </TPGrid>
  );
};

export default Tables;
