import { push } from 'modules/reduxNavigation/actions';
import { put, select, take, takeEvery } from 'redux-saga/effects';

import { openModal } from 'components/TP-UI/TPModal/actions';
import { ACCOUNT_PLANS } from 'constants/accountPlans';
import { Routes } from 'constants/routeConstants';
import verificationModalNames from 'modules/VerificationModalsManager/constants/verificationModalNames';
import {
  getQuizInsightsRequest,
  getQuizInsightsSuccess,
  startUpgradeToPro,
  upgradeToProRequest,
  upgradeToProSuccess,
  setEvidenceUploadView,
} from '../actions';
import { getIsAllowedUpgradeToPro, getUser } from 'modules/auth/selectors';

function* upgradeToProWorker() {
  const { isPro, proQuizProgress, selectedPlan, proSign } = yield select(getUser);
  const isAllowedUpgradeToPro = yield select(getIsAllowedUpgradeToPro);

  if (!isAllowedUpgradeToPro) {
    return yield put(push(Routes.MY_ACCOUNTS));
  }

  if (proQuizProgress.isPassed && !proSign) {
    yield put(getQuizInsightsRequest());
    yield take(getQuizInsightsSuccess);
    return;
  }

  if (!isPro && proQuizProgress.isPassed && selectedPlan === ACCOUNT_PLANS.PRO && proSign) {
    return yield put(setEvidenceUploadView(true));
  }

  if (proQuizProgress.isBlocked) {
    return yield put(openModal(verificationModalNames.UPGRADE_BLOCKED_MODAL));
  }

  yield put(upgradeToProRequest(true));

  yield take(upgradeToProSuccess);
}

function* upgradeToProWatcher() {
  yield takeEvery(startUpgradeToPro, upgradeToProWorker);
}

export default upgradeToProWatcher;
