import React from 'react';
import classNames from 'classnames';
import TPTypography from 'components/TP-UI/TPTypography';

import useStyles from './styles';

const JoinPlusTerms = () => {
  const classes = useStyles();

  return (
    <section>
      <TPTypography gutterBottom>
        These terms and conditions contain important information, including disclaimers and
        limitations of liability.
      </TPTypography>
      <section className={classes.section}>
        <TPTypography variant="h5" gutterBottom>
          Definitions and Interpretation
        </TPTypography>
        <TPTypography gutterBottom>
          In this Agreement, unless the context otherwise requires or implies, the following
          expressions have the meanings set opposite each of them:
        </TPTypography>
        <TPTypography gutterBottom>
          <TPTypography bold component="span">
            Applicable Law
          </TPTypography>{' '}
          means the Corporations Act 2001 (Cth), and any other statute, statutory instrument or
          general law that is applicable to a party in connection with these Terms;
        </TPTypography>
        <TPTypography gutterBottom>
          <TPTypography bold component="span">
            Application Form
          </TPTypography>{' '}
          means the application form to become a Customer made available on the Website by Fusion,
          from time to time;
        </TPTypography>
        <TPTypography gutterBottom>
          <TPTypography bold component="span">
            Customer
          </TPTypography>{' '}
          means the person, firm, or company that has requested any Services. This can be further
          defined as either a Lead Copier or a Trade Copier;
        </TPTypography>
        <TPTypography gutterBottom>
          <TPTypography bold component="span">
            Lead Copier
          </TPTypography>{' '}
          means the Customer whose trades and strategies are being copied. This individual or entity
          makes the trading decisions that other Customers, using Trade Copier, will follow;
        </TPTypography>
        <TPTypography gutterBottom>
          <TPTypography bold component="span">
            Trade Copier
          </TPTypography>{' '}
          means the Customer who copies the trades made by the Lead Copier;
        </TPTypography>
        <TPTypography gutterBottom>
          <TPTypography bold component="span">
            Credit Reporting Body
          </TPTypography>{' '}
          the meaning given to this term in the<em> Privacy Act 1988 (Cth)</em>;
        </TPTypography>
        <TPTypography gutterBottom>
          <TPTypography bold component="span">
            Force Majeure Event
          </TPTypography>{' '}
          means any act, event or cause including:
        </TPTypography>
        <ol type="a" className={classes.ol}>
          <li>
            <TPTypography gutterBottom>
              an act of God, peril of the sea, accident of navigation, war, sabotage, riot, act of
              terrorism, insurrection, civil commotion, national emergency (whether in fact or law),
              martial law, fire, lightning, flood, cyclone, earthquake, landslide, storm or other
              adverse weather conditions, explosion, power shortage, strike or other labour
              difficulty (whether or not involving employees of the party concerned), epidemic,
              quarantine, radiation or radioactive contamination;
            </TPTypography>
          </li>
          <li>
            <TPTypography gutterBottom>
              an action or inaction of a Government Agency, including expropriation, restraint,
              prohibition, intervention, requisition, requirement, direction or embargo by
              legislation, regulation, decree or other legally enforceable order; or
            </TPTypography>
          </li>
          <li>
            <TPTypography gutterBottom>
              breakdown of plant, machinery or equipment or shortages of labour, transportation,
              fuel, power or plant, machinery, equipment or material,
            </TPTypography>
          </li>
        </ol>
        <TPTypography gutterBottom>
          To the extent that the act, event or cause directly or indirectly results in a party being
          prevented from or delayed in performing one or more of its obligations under these Terms
          and that act, event or cause is beyond the reasonable control of that party;
        </TPTypography>
        <TPTypography gutterBottom>
          <TPTypography bold component="span">
            Fusion{' '}
          </TPTypography>
          means Gleneagle Securities Pty Ltd T/A Fusion Markets EN;
        </TPTypography>
        <TPTypography gutterBottom>
          <TPTypography bold component="span">
            Personal Information
          </TPTypography>{' '}
          means your name, address, date of birth, credit card details, Fusion login details, broker
          login details and any other information you have entered into the Service about yourself
          which the Service identifies as &quot;personal information&quot;;
        </TPTypography>
        <TPTypography gutterBottom>
          <TPTypography bold component="span">
            Portfolio
          </TPTypography>{' '}
          means a portfolio of investments available through the Third Party Trading Platform;
        </TPTypography>
        <TPTypography gutterBottom>
          <TPTypography bold component="span">
            Profile Information
          </TPTypography>{' '}
          means the information stored in your profile on the Website, including your name, your
          Portfolio, your trading activity and such other information you have entered into the
          Website about yourself;
        </TPTypography>
        <TPTypography gutterBottom>
          <TPTypography bold component="span">
            Server{' '}
          </TPTypography>
          means an internet-connected server, virtual or otherwise, provided by Fusion for the
          Customer&apos;s use;
        </TPTypography>
        <TPTypography gutterBottom>
          <TPTypography bold component="span">
            Services{' '}
          </TPTypography>
          means any services supplied or to be supplied by Fusion, including but not limited to the
          provision of online trading services in respect of foreign exchange products and
          derivatives through a Third Party Trading Platform;
        </TPTypography>
        <TPTypography gutterBottom>
          <TPTypography bold component="span">
            Third Party Information
          </TPTypography>{' '}
          means information supplied by another Customer or third party and incorporated into the
          Website or Service, including:
        </TPTypography>
        <ol type="a" className={classes.ol}>
          <li>
            <TPTypography gutterBottom>
              Current Price and historical price information in relation to investments available
              through the Third Party Trading Platform;
            </TPTypography>
          </li>
          <li>
            <TPTypography gutterBottom>content provided by other Customers;</TPTypography>
          </li>
        </ol>
        <TPTypography gutterBottom>
          <TPTypography bold component="span">
            Third Party Trading Platform
          </TPTypography>{' '}
          means a trading platform operated by a third party which is selected by Fusion through
          which the Services are provided;
        </TPTypography>
        <TPTypography gutterBottom>
          <TPTypography bold component="span">
            Trade Copy{' '}
          </TPTypography>
          means the service provided by us as described in clause 11.
        </TPTypography>
        <TPTypography gutterBottom>
          <TPTypography bold component="span">
            Website{' '}
          </TPTypography>
          means the website in which Fusion provides its Services;
        </TPTypography>
        <TPTypography gutterBottom>
          <TPTypography bold component="span">
            Your Content
          </TPTypography>{' '}
          means the content uploaded, posted, linked to, stored, communicated, sent or transmitted
          on the Website or via the Services.
        </TPTypography>
      </section>
      <section className={classes.section}>
        <TPTypography variant="h5">PART A: OVERVIEW AND MEMBERSHIP</TPTypography>
        <ol className={classes.ol}>
          <li className={classes.header}>
            <TPTypography variant="h6">Customer acceptance</TPTypography>
          </li>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>
                This agreement, governed by these terms and conditions (
                <TPTypography bold component="span">
                  Terms
                </TPTypography>
                ), is made by and between Gleneagle Securities Pty Ltd T/A Fusion Markets (referred
                to as{' '}
                <TPTypography bold component="span">
                  Fusion
                </TPTypography>
                ,{' '}
                <TPTypography bold component="span">
                  we
                </TPTypography>
                ,{' '}
                <TPTypography bold component="span">
                  us
                </TPTypography>
                ,{' '}
                <TPTypography bold component="span">
                  our
                </TPTypography>
                ) and the Customer (referred to as{' '}
                <TPTypography bold component="span">
                  you
                </TPTypography>{' '}
                or{' '}
                <TPTypography bold component="span">
                  your
                </TPTypography>
                ).
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                Subject to the terms and conditions of this agreement, Fusion agrees to provide the
                Services.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                The Customer agrees to use the Services in good faith in accordance with these Terms
                and Applicable Laws.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                The Customer agrees to be bound by these Terms by using, browsing or accessing any
                part of the Services. If you do not accept these Terms, you must not use the
                Services.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                The Customer agrees to comply with any policies, procedures, operating rules and
                directions of Fusion from time to time in relation to the operation of the Services,
                your access to the Fusion Services and the manner of performance of your obligations
                under these Terms.
              </TPTypography>
            </li>
          </ol>
          <li className={classes.header}>
            <TPTypography variant="h6">Amendments to Services and Terms</TPTypography>
          </li>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>
                The Services are subject to change at any time without notice and may contain
                errors.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                Fusion may from time to time review and update these Terms, including to take
                account of new laws, regulations, products or technology. Your use of the Fusion
                Services will be governed by the most recent Terms posted on the Website. By
                continuing to use the Services, you agree to be bound by the most recent Terms which
                will be made available on the Website. It is your responsibility to check the
                Website regularly for updated versions of the Terms.
              </TPTypography>
            </li>
          </ol>
          <li className={classes.header}>
            <TPTypography variant="h6">Entire agreement</TPTypography>
            <TPTypography gutterBottom>
              This agreement is intended to cover all Services provided by Fusion and received by
              the customer. This agreement contains the entire understanding between Fusion and
              Customer with respect to the Services described in the Order and supersedes all prior
              agreements, understandings, negotiations and discussions, whether oral or written,
              between Fusion and the Customer. The Customer acknowledges and agrees that its entire
              right relating to Fusion Services are as set forth in this agreement.
            </TPTypography>
          </li>
          <li className={classes.header}>
            <TPTypography variant="h6">Term</TPTypography>
          </li>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>
                This Agreement commences when we provide Services to you.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                Unless terminated by Fusion or in accordance with clause 10 of this agreement, this
                agreement will automatically continue.
              </TPTypography>
            </li>
          </ol>
          <li className={classes.header}>
            <TPTypography variant="h6">Termination of Agreement</TPTypography>
          </li>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>
                If the Customer breaches any of its obligations under this agreement or the
                Acceptable Use Policy, Fusion may terminate this agreement at any time and without
                prior notice.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                The Customer may terminate this agreement by clearly communicating the request to
                Fusion via email.
              </TPTypography>
            </li>
          </ol>
          <li className={classes.header}>
            <TPTypography variant="h6">AML/CTF Obligations</TPTypography>
          </li>
          <TPTypography gutterBottom>The Customer acknowledges and agrees:</TPTypography>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>
                in order for Fusion to meet its applicable{' '}
                <em>Anti-Money Laundering and Counter-Terrorism Financing Act 2006</em> associated
                Rules (AML/CTF obligations), Fusion is required to verify the identity of its
                customers and obtain certain information about their beneficial owners;
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                Fusion may at any time request further information from you to verify your identity
                and/or the source of monies credited or to be credited to your Fusion account and
                you agree to meet Fusion&apos;s request. If you do not provide Fusion with the
                information as requested, or there is a delay in you providing this information to
                Fusion, Fusion may not be able to open your Fusion account, or may suspend your
                access to the Fusion Services.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                Fusion may disclose your Personal Information to a Credit Reporting Body to verify
                your identity and obtain an assessment of whether the information you have provided
                us with matches the information provided by the Credit Reporting Body.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                Fusion is not liable for any loss incurred by you as a result of any action of
                Fusion which either delays a Fusion account being opened or results in an
                Application being declined, when these actions are necessary for Fusion to comply
                with its AML/CTF obligations; and
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                Fusion may require further information from you from time to time in order to meet
                its AML/CTF obligations and you agree to provide Fusion with whatever additional
                information is reasonably required in order for, Fusion to meet its AML/CTF
                obligations.
              </TPTypography>
            </li>
          </ol>
        </ol>
      </section>
      <section className={classes.section}>
        <TPTypography variant="h5">PART B: FUSION COPY TRADING</TPTypography>
        <ol className={classes.ol} start="7">
          <li className={classes.header}>
            <TPTypography variant="h6">Trading Service</TPTypography>
          </li>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>
                We may assume the authenticity of any instructions given or purportedly given
                through a Third Party Trading Platform by you or any person claiming to be your
                representative. We are not obliged to enquire into any authenticity of instructions
                received through the Third Party Trading Platform and you authorise us to act upon
                any instructions it reasonably believes to be authentic.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                We reserve the right at all times to decline to accept your instructions through the
                Third Party Trading Platform without explanation or prior notice for reasons
                including where:
              </TPTypography>
            </li>
            <ol type="i" className={classes.ol}>
              <li>
                <TPTypography gutterBottom>
                  your instructions are ambiguous, incomplete or unclear;
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  your instructions would result in a breach of the law;
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  it is not possible to place an order on the market.
                </TPTypography>
              </li>
            </ol>
            <li>
              <TPTypography gutterBottom>
                We will inform you of any instructions which are declined as soon as reasonably
                practicable.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                We will use our best endeavours to pass on accepted instructions, but we do not
                guarantee that your instructions will be wholly or partially executed or will be
                executed by a certain time, due to reasons including if:
              </TPTypography>
            </li>
            <ol type="i" className={classes.ol}>
              <li>
                <TPTypography gutterBottom>
                  your instructions are contrary to this Agreement or the terms applicable to the
                  Third Party Trading Platform;
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  the trade is unexecuted due to any act or omission of the Third Party Trading
                  Platform;
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  your instructions are ambiguous or incomplete;
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  your instructions would result in a breach of the law; or
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>you have insufficient funds available.</TPTypography>
              </li>
            </ol>
          </ol>
          <li className={classes.header}>
            <TPTypography variant="h6">Fees and Payment</TPTypography>
          </li>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>
                The Customer agrees to pay Fusion, without limitation, for the Services they use.
                This may include an agreed minimum monthly trading volume fee, a flat monthly fee,
                or any applicable performance fees or spread markups, as determined by the Lead
                Copier’s setting or any other agreed arrangement.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>In respect of the Trade Copying Service:</TPTypography>
            </li>
            <ol type="i" className={classes.ol}>
              <li>
                <TPTypography gutterBottom>
                  you may also be required to pay a fee in relation to any Portfolios which you
                  choose to follow and/or copy. The fee may be based on a flat fee or calculated
                  based on any returns you generate in connection with the Trade Copying Service.
                  This fee may be wholly or partially retained by us or passed on to the Lead
                  Copier.
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  Fusion reserves the right to charge the Customer standard hourly rates to cover
                  the resolution of excessive or unusual problems or complaints in relation to
                  Fusion Copy Trading trades.
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  Performance fees will generally be processed the next business day.
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  The equity on the Trade Copier’s account must be sufficient in order for the
                  performance fees to be processed and paid to the Lead Copier.
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  If the equity is insufficient within the Trade Copier’s account, Fusion will not
                  be liable to cover these costs. An attempt will be made to process Performance
                  Fees again when equity becomes available in the Trade Copier’s account.
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  If this Agreement is terminated while there are outstanding performance fees owed
                  to the Lead Copier, these fees will still be processed as usual, unless otherwise
                  communicated.
                </TPTypography>
              </li>
            </ol>
          </ol>
          <li className={classes.header}>
            <TPTypography variant="h6">Cancellation Policy</TPTypography>
          </li>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>
                We accept cancellation on any Service up until the Renewal Date, which occurs every
                30 days from the date that the Services were originally purchased (&quot;
                <TPTypography bold component="span">
                  Renewal Date
                </TPTypography>
                &quot;). Fees are charged to you on the Renewal Date and in accordance with clause 8
                even if no trading volume has been made.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                Any cancellation requests must be received via email or a cancellation through the
                member&apos;s area before the renewal invoice is generated. Any cancellation
                requests received after this time will not be processed until the following month.
                There are no partial refunds or credits for early cancellations.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                Upon cancellation of your account and termination of these Terms, you will no longer
                have access to the Services, other than those portions of the Website which are
                publicly available to all Customers. Any such use of the Website by you will
                continue to be subject to these Terms.
              </TPTypography>
            </li>
          </ol>
          <li className={classes.header}>
            <TPTypography variant="h6">Refund Policy</TPTypography>
          </li>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>
                All payments to Fusion are final and non-refundable unless otherwise stated. Any
                refunds on products that are eligible for a refund are at the discretion of Fusion.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                Your obligation to pay fees continues through the end of your membership period
                during which you cancel your membership.
              </TPTypography>
            </li>
          </ol>
          <li className={classes.header}>
            <TPTypography variant="h6">Trade Copying</TPTypography>
          </li>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>
                A Trade Copying service is a copying system operated through Fusion Markets that
                allows investors to automatically copy the trades of your preferred trader in
                real-time.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                You may choose to use our Trade Copying Service while you are a member.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                The Trade Copying Service Solution provides the following functionality:
              </TPTypography>
            </li>
            <ol type="i" className={classes.ol}>
              <li>
                <TPTypography gutterBottom>
                  Other members can choose to publish their trading activity.
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  You can choose to follow and copy the trading activity.
                </TPTypography>
              </li>
            </ol>
            <li>
              <TPTypography gutterBottom>
                Foreign exchange products and derivatives (such as contracts for difference or CFDs)
                are highly leveraged products. They may not be suitable for you as they carry a high
                degree of risk to your capital, and you can lose more than your investment. You
                should ensure you understand all of the risks. The Trade Copier services provided
                are not a recommendation from us to buy or sell, but rather a set of tools to enable
                you to adopt a specified analysis method. This information should only be used by
                investors who are aware of the risk inherent with trading.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                By using the Trade Copying service, the Customer acknowledges and accepts the
                following responsibilities:
              </TPTypography>
              <ol className={classNames(classes.ol, classes.dot)}>
                <li>
                  <TPTypography gutterBottom>
                    Trade Copying does not provide any guarantees, either for performance or profit.
                    Customers acknowledge that all trades carry inherent risks and past performance
                    does not guarantee future results.
                  </TPTypography>
                </li>
              </ol>
              <TPTypography variant="h6" className={classes.header}>
                Trade Copier:
              </TPTypography>
              <ol className={classNames(classes.ol, classes.dot)}>
                <li>
                  <TPTypography gutterBottom>
                    You are fully responsible for monitoring and managing your account while using
                    the Trade Copying service.
                  </TPTypography>
                </li>
                <li>
                  <TPTypography gutterBottom>
                    You must decide whether to continue copying trades or stop at any time.
                  </TPTypography>
                </li>
                <li>
                  <TPTypography gutterBottom>
                    If you no longer wish to follow a Lead Copier, it is your responsibility to
                    request disconnection from the service.
                  </TPTypography>
                </li>
                <li>
                  <TPTypography gutterBottom>
                    You are responsible for adjusting your risk setting according to your own
                    discretion and risk tolerance.
                  </TPTypography>
                </li>
                <li>
                  <TPTypography gutterBottom>
                    You acknowledge that you understand the potential risks and losses associated
                    with copying trades.
                  </TPTypography>
                </li>
              </ol>
              <TPTypography variant="h6" className={classes.header}>
                Lead Copier:
              </TPTypography>
              <ol className={classNames(classes.ol, classes.dot)}>
                <li>
                  <TPTypography gutterBottom>
                    You are fully responsible for all trades generated by the Trade Copier.
                  </TPTypography>
                </li>
                <li>
                  <TPTypography gutterBottom>
                    You maintain complete control over the trading strategies and decisions shared
                    with others, including the ability to modify trades, risk settings, or turn off
                    the Trade Copier at any time.
                  </TPTypography>
                </li>
                <li>
                  <TPTypography gutterBottom>
                    You acknowledge that the Trade Copier follows your trades at their own risk, and
                    you cannot guarantee profits or protection from losses.
                  </TPTypography>
                </li>
              </ol>
            </li>

            <li>
              <TPTypography gutterBottom>
                Please be aware that while the demo environment is similar to the live environment
                there will be differences in the way trades are processed and executed.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                Fusion does not take responsibility for any electronic or technological errors that
                occur while using the Trade Copying Service. In particular, Fusion is not
                responsible for any costs or losses, including, but not limited to, those incurred
                as a result of the use of the Trade Copying Service.
              </TPTypography>
            </li>
          </ol>
        </ol>
      </section>
      <section className={classes.section}>
        <TPTypography variant="h5">PART C: TECHNICAL DETAILS</TPTypography>
        <ol className={classes.ol} start="12">
          <li className={classes.header}>
            <TPTypography variant="h6">Data storage</TPTypography>
          </li>
          <TPTypography gutterBottom>
            Data stored on Fusion systems is not guaranteed to be backed up. It is recommended that
            Customers keep an independent copy of all data.
          </TPTypography>
          <li className={classes.header}>
            <TPTypography variant="h6">
              Fusion Administrative Login and Management Software
            </TPTypography>
          </li>
          <TPTypography gutterBottom>
            To facilitate network/server management, inventory and related activities, all Fusion
            Servers include a Fusion administrative account and password and/or a Fusion daemon. All
            reasonable precautions are taken by Fusion to maintain the security of these tools and
            the privacy of client data. Customers must not tamper, hinder, delete, or in any way
            change the functioning of these tools. To do so intentionally or otherwise is grounds
            for the immediate suspension of Customer&apos;s hosting account.
          </TPTypography>
          <li className={classes.header}>
            <TPTypography variant="h6">Temporary Service Suspension</TPTypography>
          </li>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>
                The Customer agrees that it may be necessary for Fusion to temporarily suspend
                Services for technical reasons or to maintain the Fusion network, the equipment or
                any other facilities, the timing of which will be as determined by Fusion.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                Such suspension of the Services will not be an interruption of the Fusion Services
                for the purpose of calculating network availability or the Customer&apos;s
                entitlement to credit for network interruption.
              </TPTypography>
            </li>
          </ol>
          <li className={classes.header}>
            <TPTypography variant="h6">Emergency Service Suspension</TPTypography>
          </li>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>
                Fusion may interrupt the Services at any time for any duration of time, without
                penalty or liability for any claim by the Customer, where necessary to prevent
                improper or unlawful use of Fusion Services or network. Such suspension of Services
                will not be an interruption for the purpose of calculating network availability or
                the Customer&apos;s entitlement to credit for network interruption.
              </TPTypography>
            </li>
          </ol>
          <li className={classes.header}>
            <TPTypography variant="h6">Compromised or Hacked Servern</TPTypography>
          </li>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>
                A compromised or hacked server is a serious threat to our network. Fusion, at its
                sole discretion, will take any and all measures to prevent a compromised server from
                doing additional damage to its own system and files or to the rest of the network.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                If you believe your system may have been hacked, immediately report the situation to
                Fusion technical support. Fusion will examine your system and may implement
                additional monitoring of your system.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                If a Fusion system administrator believes a server on our network is compromised,
                Fusion will:
              </TPTypography>
            </li>
            <ol type="i" className={classes.ol}>
              <li>
                <TPTypography gutterBottom>disconnect the server from the network.</TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>contact the Customer.</TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  provide evidence of the server being hacked.
                </TPTypography>
              </li>
            </ol>
          </ol>
          <li className={classes.header}>
            <TPTypography variant="h6">Fusion Administrative Account and Software</TPTypography>
          </li>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>
                For the purposes of Network/Server maintenance, inventory and related activities,
                Fusion staff and management retain the right to administrative access to any and all
                Servers on their network. To facilitate this, all Fusion Servers include a Fusion
                administrative account and/or daemon designed for these purposes. See the Acceptable
                Use Policy (AUP) for more details.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                Fusion makes no warranty or representation, either express or implied, with respect
                to this software, the hardware, or documentation, including their quality,
                performance, merchantability, or fitness for a particular purpose.
              </TPTypography>
            </li>
          </ol>
          <li className={classes.header}>
            <TPTypography variant="h6">Software Products</TPTypography>
          </li>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>
                Because software is inherently complex and may not be completely free of errors, you
                are advised to verify your work. In no event will Fusion be liable for direct,
                indirect, special, incidental, or consequential damages arising out of the use of or
                inability to use the software, hardware, or documentation, even if advised of the
                possibility of such damages.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                In particular, Fusion is not responsible for any costs, including, but not limited
                to, those incurred as a result of the use of the software, loss of data, the costs
                of recovering such software or data, the cost of substitute software, claims by
                third parties, or for other similar costs.
              </TPTypography>
            </li>
          </ol>
        </ol>
      </section>
      <section className={classes.section}>
        <TPTypography variant="h5">PART D: GENERAL TERMS AND DEFINITIONS</TPTypography>
        <ol className={classes.ol} start="19">
          <li className={classes.header}>
            <TPTypography variant="h6">Portfolio information</TPTypography>
          </li>
          <TPTypography gutterBottom>
            You consent to Fusion disclosing to other Customers information regarding the current
            and historical performance of your portfolio.
          </TPTypography>
          <li className={classes.header}>
            <TPTypography variant="h6">Third Party Trading Platform</TPTypography>
          </li>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>You acknowledge and agree that:</TPTypography>
            </li>
            <ol type="i" className={classes.ol}>
              <li>
                <TPTypography gutterBottom>
                  we are not responsible for the Third Party Trading Platform;
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  we do not recommend or endorse any investments in or available through the Third
                  Party Trading Platform, and we assume no responsibility or liability in relation
                  to the quality of such investments;
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  you authorise the Third Party Trading Platform to provide information about your
                  Portfolio to us.
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  in addition to being bound by these Terms, you are bound by any terms and
                  conditions of the Third Party Trading Platform;
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  it is your responsibility to assess and research each trade and Investment and
                  determine its suitability for your purposes.
                </TPTypography>
              </li>
            </ol>
            <li>
              <TPTypography gutterBottom>
                To the greatest extent permissible by law, you further acknowledge and agree that we
                are not responsible (whether in contract, tort or otherwise) for any loss or damage
                caused or suffered by you to the extent that the loss or damage results from:
              </TPTypography>
            </li>
            <ol type="i" className={classes.ol}>
              <li>
                <TPTypography gutterBottom>
                  any defect in our computer systems, or any delay, fault, failure in or loss of
                  access to the Third Party Trading Platform;
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  telecommunications failure, delay, or interruption of or defective network or
                  internet connections or services by a third party, including any defect in the
                  computer systems of the Third Party Trading Platform;
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  your incorrect operation of the Third Party Trading Platform;
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  inaccuracy, error or omission in any material, data or information provided by you
                  or any other third party through the Australian Trade Service;
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  actions of third parties in respect of the Third Party Trading Platform; or
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  any delay, interruption, omission, failure, error or fault in the execution of
                  your instructions provided through the Trading Platform other than as a direct
                  result of our fraud, wilful default or negligence.
                </TPTypography>
              </li>
            </ol>
          </ol>
          <li className={classes.header}>
            <TPTypography variant="h6">Third Party Information</TPTypography>
          </li>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>
                The Services incorporate Third Party Information.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                Fusion is not responsible for the Third Party Information and makes no
                representation as to the accuracy, reliability, timeliness or appropriateness of the
                Third Party Information. You acknowledge that Current Price information may be
                delayed by up to 24 hours or longer in extraordinary circumstances.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                Any recommendations or statements of opinion contained in the Third Party
                Information are those of the relevant third party and are not adopted by or
                attributable to Fusion.
              </TPTypography>
            </li>
          </ol>
          <li className={classes.header}>
            <TPTypography variant="h6">No Recommendations</TPTypography>
          </li>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>
                You acknowledge that Fusion is not authorised to, and does not through the Fusion
                Services, provide personal financial product advice (within the meaning of the
                Corporations Act).
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                All information provided on the Fusion Services is, unless otherwise indicated,
                factual information and does not involve any recommendation or statement of opinion
                by Fusion or any third party. Such information does not take into account your
                objectives, financial situation or needs and you should consider obtaining
                professional financial advice before making any investment decisions.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                References to the performance of your Portfolio are to the past performance of that
                Portfolio. Past performance should not be taken as an indication of future
                performance.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>Tools in the Service for:</TPTypography>
            </li>
            <ol type="i" className={classes.ol}>
              <li>
                <TPTypography gutterBottom>
                  comparing the relative performance of Portfolios;
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  displaying Portfolios with particular characteristics; and
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  displaying Portfolios of other Customers on the basis of particular Profile
                  Information, present factual information only and do not convey any recommendation
                  or statement of opinion by Fusion that a Portfolio is generally suitable for
                  Customers or is suitable for you.
                </TPTypography>
              </li>
            </ol>
          </ol>
          <li className={classes.header}>
            <TPTypography variant="h6">Disclosure</TPTypography>
          </li>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>
                You acknowledge that you have been provided with access to our Product Disclosure
                Statement through the Website at:{' '}
                <a href="https://fusionmarkets.com">https://fusionmarkets.com</a>.
              </TPTypography>
            </li>
          </ol>
          <li className={classes.header}>
            <TPTypography variant="h6">
              Disclaimer of Warranties and Limitation of Liability
            </TPTypography>
          </li>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>
                Customer agrees to not rely on any representation, description, illustration or
                specification that is not expressly stated in these Terms.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                Nothing in these Terms excludes, restricts or modifies any consumer guarantee, right
                or remedy conferred on you by Schedule 2 to the Competition and Consumer Act 2010 (
                <TPTypography bold component="span">
                  ACL
                </TPTypography>
                ), or any other Applicable Law, that cannot be excluded, restricted or modified by
                agreement.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                To the extent permitted by law (including the ACL), Fusion excludes all warranties,
                whether express or implied (not including any consumer guarantees under the ACL),
                including any warranties or representations concerning availability of the Fusion
                Services, quality, completeness, accuracy, suitability, acceptability or fitness for
                purpose in relation to the Fusion services including content, all links to or from
                the Fusion services and the goods and services advertised or accessible using the
                Fusion services. Subject to the consumer guarantees provided for in consumer
                protection legislation (including the ACL), we do not warrant that you will have
                continuous access to the Fusion services.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                We will not be liable in the event that the website is unavailable to you or due to
                computer downtime attributable to malfunctions, upgrades, preventative or remedial
                maintenance activities or interruption in telecommunications supply.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                Except where Fusion fails to meet a consumer guarantee under the ACL and
                notwithstanding any other provision of these Terms, Fusion will not be liable to you
                for:
              </TPTypography>
            </li>
            <ol type="i" className={classes.ol}>
              <li>
                <TPTypography gutterBottom>
                  any damage, loss or expense resulting from or caused by:
                </TPTypography>
              </li>
              <ol type="A" className={classes.ol}>
                <li>
                  <TPTypography gutterBottom>
                    any act of Fusion which was carried out in good faith (notwithstanding that such
                    act may have been in breach of these Terms or negligent);
                  </TPTypography>
                </li>
                <li>
                  <TPTypography gutterBottom>
                    your investment decisions and any acquisitions and sales of investments you
                    make, regardless of whether they were made in light of content available on the
                    Fusion services;
                  </TPTypography>
                </li>
                <li>
                  <TPTypography gutterBottom>
                    fluctuations in the value of investments;
                  </TPTypography>
                </li>
                <li>
                  <TPTypography gutterBottom>any act or omission of any third party;</TPTypography>
                </li>
                <li>
                  <TPTypography gutterBottom>
                    any inaccurate or incorrect Third Party Information;
                  </TPTypography>
                </li>
                <li>
                  <TPTypography gutterBottom>
                    any inaccurate or incorrect information in Your Content or otherwise provided by
                    you;
                  </TPTypography>
                </li>
                <li>
                  <TPTypography gutterBottom>
                    any event or circumstance beyond Fusion&apos;s reasonable control including,
                    without limitation, a Force Majeure Event;
                  </TPTypography>
                </li>
                <li>
                  <TPTypography gutterBottom>
                    any breach of these Terms, negligence, default, fraud or dishonesty by you;
                  </TPTypography>
                </li>
              </ol>
              <li>
                <TPTypography gutterBottom>
                  any direct or indirect lost profit, loss of opportunity, incidental, consequential
                  or special damages, howsoever the loss is caused and regardless of whether it was
                  foreseeable or not,
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  any unauthorised or illegal access or interference with the Customer&apos;s
                  server/network unless such access or interference is caused by the intentional
                  unlawful acts of Fusion, its agents or employees.
                </TPTypography>
              </li>
            </ol>
            <li>
              <TPTypography gutterBottom>
                If, despite the foregoing limitations, Fusion or any of its shareholders, directors,
                officers, employees or representatives should become liable to Customer or any other
                person in connection with this agreement for any reason, then the maximum aggregate
                liability of Fusion, its members, shareholders, directors, officers, employees and
                representatives for all such things and to all such parties will be the amount of
                any fees received from you in the preceding [12] months in the aggregate of all
                claims.
              </TPTypography>
            </li>
          </ol>
          <li className={classes.header}>
            <TPTypography variant="h6">Indemnity</TPTypography>
          </li>
          <TPTypography gutterBottom>
            Customer agrees to indemnify Fusion and its members, shareholders, directors, officers,
            employees, agents, contractors, and representatives from and against all damages,
            losses, costs and expenses (including actual legal fees and costs), fines and
            liabilities incurred by or awarded, asserted or claimed against Fusion or any of its
            members, shareholders, directors, officers, employees and representatives in connection
            with any of the following:
          </TPTypography>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>
                Customer&apos;s negligence, activities or omissions, or
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                breaches of its obligations under this Agreement, including claims brought by a
                person using; or relying upon any advice, communication or publication produced and
                distributed by Customer.
              </TPTypography>
            </li>
          </ol>
          <li className={classes.header}>
            <TPTypography variant="h6">Intellectual Property</TPTypography>
          </li>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>
                All intellectual property rights (including copyright and patents) in the Fusion
                Services and all components of them are owned or licensed by us unless otherwise
                indicated. You must not copy, modify or transmit any part of the Fusion Services or
                any content except as permitted in these Terms.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                The Fusion Services contain trademarks, logos, service names and trade names of
                Fusion or third parties that may be registered or otherwise protected by law. These
                include the Fusion logo. You are not permitted to use any trademarks, logos, service
                names or trade names appearing on the Fusion Services.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                We grant you a non-exclusive and non-transferable licence to use the Website. You
                may not download (other than page caching) or modify the Website.
              </TPTypography>
            </li>
          </ol>
          <li className={classes.header}>
            <TPTypography variant="h6">Your Content</TPTypography>
          </li>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>
                Subject to certain restrictions and limits outlined in this clause and clause 29.2,
                the Website and Services may allow you to upload, post, link to, store, communicate,
                send or transmit content (
                <TPTypography bold component="span">
                  Your Content
                </TPTypography>
                ) on the Website.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                You grant to us a non-exclusive, royalty-free, perpetual, irrevocable and fully
                sub-licensable right to use, modify, reproduce, adapt, communicate, display, perform
                and distribute Your Content in relation to and only for the purpose of our operation
                of the Website and Services.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                You represent and warrant that Your Content does not infringe any third party
                intellectual property rights and that you own or otherwise control all of the rights
                to the content or that you have obtained all necessary authorisations and consents
                to post, link to, store or communicate Your Content and to grant us the rights in
                clause 28(b), that Your Content is accurate, that the use of Your Content does not
                violate these Terms and will not cause any loss or injury to any person. You agree
                to be solely responsible for any of Your Content that you post to the Website and
                through the Services.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                Fusion has the right, but not the obligation, to monitor any Content (including Your
                Content) made available on the Website or Services. We reserve the right, in our
                absolute discretion, to block, modify or remove any Content (including Your Content)
                without notice, and will not be liable in any way for possible consequences of such
                actions. If you have a complaint regarding any Content, our sole obligation will be
                to review any written complaint notified to us and, if we see fit, in our sole
                discretion, to modify or remove the particular Content.
              </TPTypography>
            </li>
          </ol>
          <li className={classes.header}>
            <TPTypography variant="h6">Acceptable Use Policy</TPTypography>
          </li>
          <ol className={classes.ol}>
            <li className={classes.header}>
              <TPTypography variant="h6">28.1 AUP requirements</TPTypography>
            </li>
            <ol type="a" className={classes.ol}>
              <li>
                <TPTypography gutterBottom>
                  Customers shall at all times comply with and help ensure that they comply with the
                  terms of the current version of the AUP. Customer agrees that Fusion may amend the
                  terms of the AUP from time to time by posting a new or different version of the
                  AUP.
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  For existing customers, changes shall come into effect one month after they are
                  published on the Fusion website.
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  This AUP is not exhaustive. Fusion has the right to refuse service to anyone at
                  any time without warning or prior notice. No refunds of fees paid will be made if
                  account termination is due to a violation of the AUP as outlined above.
                </TPTypography>
              </li>
              <li>
                <TPTypography gutterBottom>
                  This policy recognises the fundamental fact that no one owns or controls the
                  Internet. Fusion cannot monitor or control all the activities of our Customers. We
                  do not intend to actively screen, review, censor, edit or take responsibility for
                  the activities or content of our Customers. The Customer, not Fusion, assumes all
                  responsibility relating to their Internet activities including, but not limited
                  to:
                </TPTypography>
              </li>
              <ol type="i" className={classes.ol}>
                <li>
                  <TPTypography gutterBottom>Aspects of the Customer&apos;s business.</TPTypography>
                </li>
                <li>
                  <TPTypography gutterBottom>
                    Content and data provided by or through a Customer for use with the Services.
                  </TPTypography>
                </li>
                <li>
                  <TPTypography gutterBottom>
                    Decisions about Customer&apos;s computer and communications systems needed to
                    access the Services.
                  </TPTypography>
                </li>
                <li>
                  <TPTypography gutterBottom>
                    Results obtained from using Fusion Services.
                  </TPTypography>
                </li>
                <li>
                  <TPTypography gutterBottom>
                    Compliance with all applicable laws and governmental regulations regarding
                    Customer&apos;s business or use of the Services.
                  </TPTypography>
                </li>
                <li>
                  <TPTypography gutterBottom>
                    Compliance with this AUP by the Customer.
                  </TPTypography>
                </li>
              </ol>
              <li>
                <TPTypography gutterBottom>
                  Activities conducted on the Internet are subject to many of the same laws and
                  regulations applicable to the offline environment. Customers must exercise a high
                  degree of judgement and responsibility with respect to their use of the Services,
                  including the responsibility to comply with this AUP. Customers will violate this
                  Policy when they or their affiliates engage in any of the following activities
                  outlined in clause 29.2 below.
                </TPTypography>
              </li>
            </ol>
            <li className={classes.header}>
              <TPTypography variant="h6">28.2 Prohibitions under the AUP</TPTypography>
            </li>
            <ol type="a" className={classes.ol}>
              <li>
                <TPTypography gutterBottom>Network and Other Abuse</TPTypography>
              </li>
              <ol type="i" className={classes.ol}>
                <li>
                  <TPTypography gutterBottom>
                    Using the Fusion network or other Fusion resources in any way that adversely
                    affects other Fusion Customers is strictly prohibited. This includes but is not
                    limited to:
                  </TPTypography>
                </li>
                <ol type="A" className={classes.ol}>
                  <li>
                    <TPTypography gutterBottom>
                      Gaining or attempting to gain unauthorised access to servers or services. Such
                      attempts include &apos;Internet scamming&apos; (tricking other people into
                      releasing their passwords), password robbery, security hole scanning, port
                      scanning, probing, monitoring or testing for system or network
                      vulnerabilities.
                    </TPTypography>
                  </li>
                  <li>
                    <TPTypography gutterBottom>
                      Introducing viruses, Trojan horses, trap doors, back doors, Easter eggs,
                      worms, time bombs, packet bombs, cancel bots or other computer programming
                      routines that are intended to damage, detrimentally interfere with,
                      surreptitiously intercept or expropriate any system, data or personal
                      information.
                    </TPTypography>
                  </li>
                  <li>
                    <TPTypography gutterBottom>
                      Intentionally omitting, deleting, forging or misrepresenting transmission
                      information, including headers, return addressing information and IP
                      addresses. Using IP addresses which were not assigned to them by Fusion.
                    </TPTypography>
                  </li>
                  <li>
                    <TPTypography gutterBottom>
                      Maintaining an Open Email Relay/Open Data Relay or allowing any data to be
                      sent through one&apos;s server by an unrelated third party, including, but is
                      not limited to, via open email gateways and open proxy servers.
                    </TPTypography>
                  </li>
                </ol>
              </ol>
              <li>
                <TPTypography gutterBottom>Illegal Content</TPTypography>
              </li>
              <ol type="i" className={classes.ol}>
                <li>
                  <TPTypography gutterBottom>
                    Accounts may be terminated that include content or which have links to content
                    that:
                  </TPTypography>{' '}
                </li>
                <ol type="A" className={classes.ol}>
                  <li>
                    <TPTypography gutterBottom>
                      Is unlawful or is considered offensive by the web community.
                    </TPTypography>
                  </li>
                  <li>
                    <TPTypography gutterBottom>
                      Promotes injury or physical harm against any group or individual.
                    </TPTypography>
                  </li>
                  <li>
                    <TPTypography gutterBottom>
                      Promotes or teaches illegal activities.
                    </TPTypography>
                  </li>
                  <li>
                    <TPTypography gutterBottom>
                      Exploits or depicts children in a negative/sexual way.
                    </TPTypography>
                  </li>
                  <li>
                    <TPTypography gutterBottom>
                      Infringes on copyright, patents, trademarks, trade secrets, or other
                      intellectual property including pirated computer programs, cracker utilities,
                      warez and software serial numbers or registration codes.
                    </TPTypography>
                  </li>
                  <li>
                    <TPTypography gutterBottom>
                      Violates any law, statute, ordinance or regulation governing the
                      Customer&apos;s business or activities, including without limitation the laws
                      and regulations governing export control, unfair competition, false
                      advertising, consumer protection, issuance or sale of securities, trade in
                      firearms, privacy, data transfer and telecommunications.
                    </TPTypography>
                  </li>
                </ol>
              </ol>
              <li>
                <TPTypography gutterBottom>SPAM or Unsolicited Commercial Email</TPTypography>
              </li>
              <ol type="a" className={classes.ol}>
                <li>
                  <TPTypography gutterBottom>
                    Fusion has zero tolerance for the sending of SPAM or Unsolicited Commercial
                    Email (
                    <TPTypography bold component="span">
                      UCE
                    </TPTypography>
                    ) over our network. Fusion Customers cannot send UCE and cannot host sites or
                    information advertised in UCE. Customers must take all reasonable precautions to
                    secure their servers and sites against SPAM exploits (e.g. open email relays and
                    insecure cgi scripts).
                  </TPTypography>
                </li>
                <li>
                  <TPTypography gutterBottom>
                    Violating our UCE or SPAM policy will result in penalties. Upon detection or
                    notification of a violation of our UCE policy, Fusion will initiate an immediate
                    investigation. During this time, outgoing mail may be suspended from the
                    offending IP address to prevent further violations. If a Customer is found to be
                    in violation of our UCE policy, Fusion will impose penalties and/or, at its
                    discretion, restrict or terminate the offending account and the Customer&apos;s
                    access to our network. Repeated or serious violations may result in the
                    immediate termination of the account. SPAM is a very serious matter. Please do
                    not SPAM and take all precautions against SPAM exploits.
                  </TPTypography>
                </li>
              </ol>
            </ol>
          </ol>
          <li className={classes.header}>
            <TPTypography variant="h6">Registration and Login</TPTypography>
          </li>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>
                Certain parts of the Fusion Services are only accessible to Customers who have
                &ldquo;logged in&rdquo; or paid the relevant fee.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                You represent and warrant that you are the individual identified in the Application
                Form, you have all necessary authorities in respect of your Portfolio and that all
                information you have supplied to us in connection with your use of the Fusion
                Services is true and correct.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                You represent that you do not hold an Australian Financial Services (
                <TPTypography bold component="span">
                  AFS
                </TPTypography>
                ) Licence and are not an authorised representative of an AFS Licence holder.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                When using the Fusion Services, you are responsible for maintaining the
                confidentiality of your login details and for restricting access by third parties to
                your account. You agree to be liable if your login details are used by an
                unauthorised person and are fully responsible for all activities that are conducted
                under your membership. If you believe that any of your login details have been
                compromised, lost or misplaced, you must contact us immediately by email at
                help@fusionmarkets.com. You must exercise particular caution when accessing the
                Fusion Services using the Software from a public or shared computer or mobile device
                so that others are not able to view or record your login details or other Personal
                Information.
              </TPTypography>
            </li>
          </ol>
          <li className={classes.header}>
            <TPTypography variant="h6">Severability</TPTypography>
          </li>
          <TPTypography gutterBottom>
            Each provision of these Terms is severable from the others and no severance of a
            provision will affect any other provision.
          </TPTypography>
          <li className={classes.header}>
            <TPTypography variant="h6">Contacting Fusion</TPTypography>
          </li>
          <TPTypography gutterBottom>
            If you have questions about the Fusion Services, these Terms or the Privacy Policy,
            please contact Fusion by emailing help@fusionmarkets.com.
          </TPTypography>
          <li className={classes.header}>
            <TPTypography variant="h6">Governing Law</TPTypography>
          </li>
          <ol type="a" className={classes.ol}>
            <li>
              <TPTypography gutterBottom>
                The Terms of this Agreement are governed by and must be construed in accordance with
                the law of the state of New South Wales, Australia. The Customer submits to the
                exclusive jurisdiction of the courts of that State and the Commonwealth of Australia
                in respect of all matters arising out of or relating to these Terms, their
                performance and subject matter.
              </TPTypography>
            </li>
            <li>
              <TPTypography gutterBottom>
                If any provision of this agreement is held by any competent authority to be
                unlawful, invalid or unenforceable in whole or in part then the provision shall be
                deemed to be severable from the remaining provisions and shall not affect their
                validity or enforceability.
              </TPTypography>
            </li>
          </ol>
        </ol>
      </section>
    </section>
  );
};

export default JoinPlusTerms;
