import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      width: '100%',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      justifyContent: 'space-between',
      ...theme.pageHeader.root,
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: '0 1 auto',
    },
    breadcrumbsContainer: {
      minHeight: '20px',
    },
    secondaryContent: {
      display: 'flex',
      alignItems: 'flex-start',
      flex: 1,
      justifyContent: 'flex-end',
    },
    miniSecondaryContent: {
      '& $childrenContainer': {
        gap: theme.spacing(2),
      },
      '& $actionsContainer': {
        gap: theme.spacing(2),
      },
    },
    childrenContainer: {
      display: 'flex',
      flex: 1,
      gap: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      marginLeft: `${theme.spacing(3)}px`,
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
      alignItems: 'center',
      '& > *': {
        whiteSpace: 'nowrap',
      },
    },
    actionsContainer: {
      display: 'flex',
      margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px ${theme.spacing(3)}px`,
      alignItems: 'center',
      flex: '0 1 auto',
      justifyContent: 'end',
      gap: `${theme.spacing(3.5)}px ${theme.spacing(2)}px`,
      flexWrap: 'wrap',
    },
  }),
  { name: 'TPPageHeader' },
);

export default useStyles;
