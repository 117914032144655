import { handleActions, combineActions } from 'redux-actions';

import * as actions from '../actions';
import * as employeesActions from 'modules/userManagement/pages/Employees/actions';

import * as userDetailsActions from 'modules/userManagement/pages/UserDetails/actions';
import * as followUpsActions from 'modules/userManagement/pages/FollowUps/actions';
import * as emailTemplatesActions from 'modules/emailManagement/pages/EmailTemplates/actions';
import * as emailHistoryActions from 'modules/emailManagement/pages/EmailHistory/actions';
import * as withdrawalRequestsActions from 'modules/paymentManagement/pages/WithdrawalRequests/actions';
import * as paymentHistoryActions from 'modules/paymentManagement/pages/History/actions';
import * as paymentPendingHistoryActions from 'modules/paymentManagement/pages/PendingHistory/actions';
import * as paymentAutomatedApprovalsActions from 'modules/paymentManagement/pages/AutomatedApprovals/actions';
import * as verificationRequestsActions from 'modules/verificationManagement/pages/Requests/actions';
import * as FusionPlusUsersActions from 'modules/userManagement/pages/FusionPlusUsers/actions';
import * as paymentQueueActions from 'modules/paymentQueue/actions';
import getTotalNumbersLabel from '../helpers/getTotalNumbersLabel';
import { getSecurityLogsSuccess, getSecurityLogsFail } from 'modules/security/actions';

const defaultState = {};

export default handleActions(
  {
    [combineActions(
      employeesActions.getEmployeesSuccess,
      emailTemplatesActions.getEmailTemplatesSuccess,
      emailHistoryActions.getEmailHistorySuccess,
      withdrawalRequestsActions.getPendingWithdrawalsSuccess,
      paymentHistoryActions.getPaymentsHistorySuccess,
      paymentPendingHistoryActions.getPaymentsPendingHistorySuccess,
      paymentAutomatedApprovalsActions.getAutomatedApprovalsSuccess,
      verificationRequestsActions.getPendingAccountsSuccess,
      verificationRequestsActions.getPendingVerificationsSuccess,
      userDetailsActions.getUserEmailsSuccess,
      FusionPlusUsersActions.getFusionPlusUsersSuccess,
      paymentQueueActions.paymentQueueProcessSuccess,
      paymentQueueActions.paymentQueuePendingSuccess,
      userDetailsActions.getUserPaymentsSuccess,
      followUpsActions.getFollowUpsSuccess,
      getSecurityLogsSuccess,
    )](state, action) {
      const response = action.response.data;
      const totalNumbersLabel = getTotalNumbersLabel(response);
      return { ...state, [totalNumbersLabel]: response.totalNumber };
    },
    [combineActions(
      emailTemplatesActions.getEmailTemplatesFail,
      emailHistoryActions.getEmailHistoryFail,
      paymentPendingHistoryActions.getPaymentsPendingHistoryFail,
      withdrawalRequestsActions.getPendingWithdrawalsFail,
      paymentHistoryActions.getPaymentsHistoryFail,
      paymentAutomatedApprovalsActions.getAutomatedApprovalsFail,
      verificationRequestsActions.getPendingAccountsFail,
      verificationRequestsActions.getPendingVerificationsFail,
      paymentQueueActions.paymentQueueProcessFail,
      paymentQueueActions.paymentQueuePendingFail,
      userDetailsActions.getUserEmailsFail,
      userDetailsActions.getUserPaymentsFail,
      followUpsActions.getFollowUpsFail,
      getSecurityLogsFail,
    )](state) {
      return state;
    },
    [actions.resetPagination]() {
      return defaultState;
    },
  },
  defaultState,
);
