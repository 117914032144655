import { combineActions, handleActions } from 'redux-actions';

import * as actionCreators from '../actions';
const defaultState = null;

export default handleActions(
  {
    [combineActions(actionCreators.searchDepositHistorySuccess)](state, action) {
      return action.response.data;
    },
  },
  defaultState,
);
