import React from 'react';
import PropTypes from 'prop-types';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import useStyles from './styles';
import { getTranslation } from 'utils/compositeTranslationHandler';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const TPErrorMessageBase = ({ error, size, className, forwardedRef }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  return (
    <span
      ref={forwardedRef}
      className={classNames(classes.error, className, { [classes.mediumSize]: size === 'medium' })}>
      <ErrorOutline className={classes.icon} fontSize={size} color="error" />
      {getTranslation(t, error) || 'Error'}
    </span>
  );
};

TPErrorMessageBase.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.oneOf(['small', 'medium']),
};

const TPErrorMessage = React.forwardRef((props, ref) => (
  <TPErrorMessageBase {...props} forwardedRef={ref} />
));

export default TPErrorMessage;
