const styles = () => ({
  invisibleScroll: {
    display: 'none',
  },
  itemsContainer: {
    width: '100%',
  },
});

export default styles;
