import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    errorContainer: {
      // Must be the same style as for TPTextField
      minHeight: theme.textError.root.minHeight,
    },
  }),
  { name: 'TPCheckboxGroup' },
);

export default useStyles;
