import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(
  () => ({
    image: {
      width: '100%',
      maxWidth: '150px',
      maxHeight: '170px',
    },
  }),
  { name: 'NoDataAvailable' },
);
